import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { TrackDeliveryRepositoryInterface } from "@/data/persistences/repositories/contracts/TrackDeliveryRepositoryInterface";
import { TrackDeliveryMapper } from "@/data/persistences/mappers/TrackDeliveryMapper";
import { TrackDeliveryEntities } from "@/domain/entities/TrackDelivery";
import { TrackDeliveryListRequest } from "@/data/payload/api/TrackDeliveryApiRequest";

export class TrackDeliveryApiRepository
  implements TrackDeliveryRepositoryInterface {
  private service: ApiService;
  private mapper: TrackDeliveryMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: TrackDeliveryMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async getDataTrackList(
    path: string,
    filter: TrackDeliveryListRequest
  ): Promise<TrackDeliveryEntities> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getDataTrackList(path, filter)
    );

    return this.mapper.convertTrackDeliverListListFromApi(res);
  }
}
