import { Pagination } from "@/domain/entities/Pagination";

export class HistoryPoinEntities {
  pagination = new Pagination(1, 30);
  data: HistoryPoinData[] = [];
  constructor(fields?: Partial<HistoryPoinEntities>) {
    Object.assign(this, fields);
  }
}

export class HistoryPoinData {
  id = "";
  invoiceNumber = "";
  activity = "";
  amount = 0;
  transactionDate = "";
  note = "";
  expiredDate = "";

  constructor(fields?: Partial<HistoryPoinData>) {
    Object.assign(this, fields);
  }
}

export class HistorySaldoEntities {
  pagination = new Pagination(1, 30);
  data: HistorySaldoData[] = [];
  constructor(fields?: Partial<HistorySaldoEntities>) {
    Object.assign(this, fields);
  }
}

export class HistorySaldoData {
  id = "";
  shipmentId = "";
  saldoType = "";
  activity = "";
  amount = 0;
  transactionDate = "";
  note = "";
  accountBank = "";
  accountName = "";
  accountNumber = "";
  refNumber = "";
  status = "";

  constructor(fields?: Partial<HistorySaldoData>) {
    Object.assign(this, fields);
  }
}
