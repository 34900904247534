import formatPriceRP from "./FormatPriceRP";

const formatPriceIDR = (price: number, currency: "IDR" | "MYR" = "IDR") => {
  if (currency === "IDR") {
    if (!price) return "0 IDR";
    const result = price.toLocaleString("id");
    return `${result} IDR`;
  }
  return formatPriceRP(price, currency);
};
export default formatPriceIDR;
