export class CollectibleItemEntities {
  data: CollectibleItemData;

  constructor(data: CollectibleItemData) {
    this.data = data;
  }
}

export class CollectibleItemData {
  gold: CollectibleItemCard[] = [];
  hologram: CollectibleItemCard[] = [];
  silver: CollectibleItemCard[] = [];

  constructor(fields?: Partial<CollectibleItemData>) {
    Object.assign(this, fields);
  }
}

export class CollectibleItemCard {
  grade = "";
  imageId = 0;
  imageUrl = "";
  total = 0;
  claimStatus = false;

  constructor(fields?: Partial<CollectibleItemCard>) {
    Object.assign(this, fields);
  }
}
