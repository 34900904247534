import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { getToken } from "@/app/infrastructures/misc/Cookies";
import parsingLinkGcpToAws from "@/app/infrastructures/misc/common-library/ParsingLinkGcpToAws";

const downloadFile = (
  url: string,
  filename: string,
  extension: string,
  isAuth?: boolean,
  timeout = 60000
): any => {
  MainAppController.showLoading();
  const headers = new Headers();
  const auth = {
    token: `Bearer ${getToken()}`,
    baseUrl: process.env.VUE_APP_GENESIS_API_URL
  };
  headers.append("authorization", auth.token);

  // https://stackoverflow.com/questions/46946380/fetch-api-request-timeout
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject({ response: { status: 504 } });
    }, timeout);

    // call api
    const parsedUrl = parsingLinkGcpToAws(url);
    fetch(parsedUrl, isAuth ? { headers } : undefined)
      .then(async (response: any) => {
        if (response.ok) {
          resolve(response.blob());
        } else {
          response.data = await response.json();
          reject({ response: response });
        }
      })
      .catch((error: any) => {
        clearTimeout(timer);
        reject(error);
      });
  })
    .then((blob: any) => {
      const newUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = newUrl;
      anchor.download = `${filename}${extension}`;
      anchor.click();
      window.URL.revokeObjectURL(newUrl);
    })
    .catch((error: any) => {
      console.log("There has been a problem with your fetch operation:", error);
      throw error;
    })
    .finally(() => {
      MainAppController.closeLoading();
    });
};
export default downloadFile;
