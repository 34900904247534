import { injectable } from "tsyringe";
import { DetailBonusKomisi } from "@/domain/entities/ProgressiveCommissionDashboard";
import { BonusKomisiDetailRequest } from "@/data/payload/api/ProgressiveCommissionDashboardRequest";
import { ProgressiveCommissionDashboardApiRepository } from "@/app/infrastructures/repositories/api/ProgressiveCommissionDashboardApiRepository";

@injectable()
export class ProgressiveCommissionDashboardPresenter {
  private repository: ProgressiveCommissionDashboardApiRepository;

  constructor(repo: ProgressiveCommissionDashboardApiRepository) {
    this.repository = repo;
  }

  public getBonusKomisiDetail(
    params: BonusKomisiDetailRequest
  ): Promise<DetailBonusKomisi> {
    return this.repository.getBonusKomisiDetail(params);
  }
}
