import { AxiosResponse } from "axios";
import {
  RefundConfigurationData,
  ConfigurableRefund,
  RefundOriginCityExcludeDetail
} from "@/domain/entities/RefundConfiguration";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class RefundConfigurationMapper {
  public convertDetailRefundConfigurationFromApi(
    res: AxiosResponse
  ): RefundConfigurationData {
    const {
      data: { data }
    } = res;

    const configurableRefundResp = data.configurable_refund || [];
    const configurableRefundData = configurableRefundResp
      .map((item: any) => {
        return new ConfigurableRefund({
          refundId: item.refund_id,
          refundConfigurablePriceId: item.refund_configurable_price_id,
          refundSttLastStatus: item.refund_stt_last_status,
          refundAmount: item.refund_amount.toLocaleString("id"),
          refundPenalty: item.refund_penalty.toLocaleString("id"),
          refundQuantifier: item.refund_quantifier,
          refundAmountInsurance: item.refund_amount_insurance,
          refundOriginCityExclude: item.refund_origin_city_exclude,
          refundOriginCityExcludeDetail: item.refund_origin_city_exclude_detail
            .length
            ? item.refund_origin_city_exclude_detail.map(
                (item: any) =>
                  new RefundOriginCityExcludeDetail({
                    name: item.name,
                    code: item.code,
                    freeTradeZone: item.free_trade_zone
                  })
              )
            : [],
          refundRemarks: item.refund_remarks,
          expand: !!item.refund_remarks,
          remarks: data.configurable_refund
            .map((itemChild: any) => {
              if (
                itemChild.refund_stt_last_status === item.refund_stt_last_status
              ) {
                return new ConfigurableRefund({
                  refundId: itemChild.refund_id,
                  refundConfigurablePriceId:
                    itemChild.refund_configurable_price_id,
                  refundSttLastStatus: itemChild.refund_stt_last_status,
                  refundAmount: itemChild.refund_amount.toLocaleString("id"),
                  refundPenalty: itemChild.refund_penalty.toLocaleString("id"),
                  refundQuantifier: itemChild.refund_quantifier,
                  refundAmountInsurance: itemChild.refund_amount_insurance,
                  refundOriginCityExclude: itemChild.refund_origin_city_exclude,
                  refundOriginCityExcludeDetail: itemChild
                    .refund_origin_city_exclude_detail.length
                    ? itemChild.refund_origin_city_exclude_detail.map(
                        (origin: any) =>
                          new RefundOriginCityExcludeDetail({
                            name: origin.name,
                            code: origin.code,
                            freeTradeZone: origin.free_trade_zone
                          })
                      )
                    : [],
                  refundRemarks: itemChild.refund_remarks,
                  expand: false,
                  remarks: []
                });
              }
            })
            .filter((item?: ConfigurableRefund) => typeof item !== "undefined"
            ) as ConfigurableRefund[]
        });
      })
      .reduce((prev: ConfigurableRefund[], current: ConfigurableRefund) => {
        const obj = prev.find(
          item => item.refundSttLastStatus === current.refundSttLastStatus
        );

        if (!obj) {
          return prev.concat([current]);
        }

        return prev;
      }, []);

    configurableRefundData.push(
      configurableRefundData.splice(
        configurableRefundData.indexOf(
          configurableRefundData.find(
            (item: ConfigurableRefund) =>
              item.refundSttLastStatus === "REJECTED"
          ) as ConfigurableRefund
        ),
        1
      )[0]
    );

    return new RefundConfigurationData({
      configurablePriceId: data.configurable_price_id,
      configurablePriceName: data.configurable_price_name,
      configurablePriceType: data.configurable_price_type,
      configurablePriceDescription: data.configurable_price_description,
      configurablePriceStatus: data.configurable_price_status,
      configurablePriceCreatedAt: data.configurable_price_created_at,
      configurablePriceCreatedBy: data.configurable_price_created_by,
      configurablePriceUpdatedAt: data.configurable_price_updated_at,
      configurablePriceUpdatedBy: data.configurable_price_updated_by,
      configurableRefund: configurableRefundData
    });
  }

  public convertPayloadData(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
