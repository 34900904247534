import { injectable } from "tsyringe";
import { LastBalanceApiRepository } from "@/app/infrastructures/repositories/api/LastBalanceApiRepository";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";
import { LastBalanceEntities } from "@/domain/entities/LastBalance";
import { RequestListLastBalance } from "@/data/payload/api/LastBalanceApiRequest";
import { BalanceData } from "@/domain/entities/Balance";
import { RequestListClientManagement } from "@/data/payload/api/ClientManagementApiRequest";

@injectable()
export class LastBalancePresenter {
  private repository: LastBalanceApiRepository;

  constructor(repository: LastBalanceApiRepository) {
    this.repository = repository;
  }

  public getListPartner(
    params: RequestListPartner
  ): Promise<LastBalanceEntities> {
    return this.repository.getListPartner(params);
  }

  public getListClientManagement(
    params: RequestListClientManagement
  ): Promise<LastBalanceEntities> {
    return this.repository.getListClientManagement(params);
  }

  public getListLastBalance(
    params: RequestListLastBalance
  ): Promise<BalanceData[]> {
    return this.repository.getListLastBalance(params);
  }
}
