import { createI18n } from "vue-i18n";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my"

export default createI18n({
  legacy: false,
  locale: "id",
  fallbackLocale: "id",
  globalInjection: true,
  messages: {
    en,
    id,
    my
  }
});
