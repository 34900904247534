import { CustomProcessApiRepository } from "@/app/infrastructures/repositories/api/CustomProcessApiRepository";
import { RequestListCustomProcess } from "@/data/payload/api/CustomProcessApiRequest";
import { UpdateStatusRequestInterface } from "@/data/payload/contracts/CustomProcessRequest";
import {
  ResponseUpdate,
  CustomProcessSttData,
  CustomProcessEntities
} from "@/domain/entities/CustomProcess";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { injectable } from "tsyringe";

@injectable()
export class CustomProcessPresenter {
  private repository: CustomProcessApiRepository;

  constructor(repo: CustomProcessApiRepository) {
    this.repository = repo;
  }

  public async getCustomProcessList(
    params: RequestListCustomProcess
  ): Promise<CustomProcessEntities> {
    return await this.repository.getListCustomProcess(params);
  }

  public updateStatus(
    payload: UpdateStatusRequestInterface
  ): Promise<ResponseUpdate> {
    return this.repository.updateStatus(payload);
  }

  public patchReverseDestination(
    id: any,
    payload: UpdateStatusRequestInterface
  ): Promise<ResponsePayloadV2> {
    return this.repository.patchReverseDestination(id, payload);
  }

  public getCustomProcessSttDetail(
    sttNo: string,
    customProcessStatus: string,
    partnerId?: number
  ): Promise<CustomProcessSttData> {
    return this.repository.getCustomProcessSttDetail(
      sttNo,
      customProcessStatus,
      partnerId
    );
  }

  public getDetailCustomProcess(id: number) {
    return this.repository.getDetailCustomProcess(id);
  }

  public getCustomProcessReasonList() {
    return this.repository.getCustomProcessReasonList();
  }
}
