import { Pagination } from "./Pagination";

export class SubconsoleEntities {
  pagination: Pagination;
  data: SubconsoleData[];

  constructor(pagination: Pagination, data: SubconsoleData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class SubconsoleData {
  id: number;
  name: string;
  address: string;
  balance: number;
  code: string;
  lat: number;
  long: number;
  type: string;
  createdAt: Date;

  constructor(
    id: number,
    name: string,
    address: string,
    balance: number,
    code: string,
    lat: number,
    long: number,
    type: string,
    createdAt: Date
  ) {
    this.id = id;
    this.name = name;
    this.address = address;
    this.balance = balance;
    this.code = code;
    this.lat = lat;
    this.long = long;
    this.type = type;
    this.createdAt = createdAt;
  }
}
