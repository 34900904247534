import { CLIENT } from "@/app/infrastructures/misc/RolePermission";
import { FlagsPermissionClient } from "@/feature-flags/flags-permission-client";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

const Client = () =>
  import(
    /* webpackChunkName: "Client" */ "@/app/ui/views/sales/config-client/tab.vue"
  );
const ClientRegistration = () =>
  import(
    /* webpackChunkName: "Client" */ "@/app/ui/views/sales/config-client/list-client-registration.vue"
  );
const ClientManagement = () =>
  import(
    /* webpackChunkName: "Client" */ "@/app/ui/views/sales/config-client/list-client-management.vue"
  );
const AddClient = () =>
  import(
    /* webpackChunkName: "Client" */ "@/app/ui/views/sales/config-client/client-registration-add.vue"
  );
const DetailClient = () =>
  import(
    /* webpackChunkName: "Client" */ "@/app/ui/views/sales/config-client/detail-client-registration.vue"
  );
const ClientManagementDetail = () =>
  import(
    /* webpackChunkName: "Client" */ "@/app/ui/views/sales/config-client/detail-client-management.vue"
  );

const EditClientBranch = () =>
    import(
      /* webpackChunkName: "Client" */ "@/app/ui/views/sales/clients-v2/global-components/form-edit-branch.vue"
    );
    
const EditClientParent = () =>
      import(
        /* webpackChunkName: "Client" */ "@/app/ui/views/sales/clients-v2/global-components/form-edit-parent.vue"
      );

export default [
  {
    path: "/client",
    component: Layout,
    name: "client",
    meta: {
      children: false,
      icon: "client-registration",
      title: "Client Registration & Management",
      name: "Pendaftaran & manajemen klien",
      isFreeze: true,
      permission: CLIENT.ENABLE
    },
    children: [
      {
        path: "",
        name: "client",
        redirect: "/client/client-registration",
        component: Client,
        meta: {
          header: true,
          icon: "client-registration",
          title: "Client",
          name: "Client",
          permission: CLIENT.ENABLE
        },
        children: [
          {
            path: "client-registration",
            name: "client-registration",
            component: ClientRegistration
          },
          {
            path: "client-management",
            name: "client-management",
            component: ClientManagement
          }
        ]
      }
    ]
  },
  {
    path: "/client/client-registration",
    component: Layout,
    name: "add-client-management-parent",
    meta: {
      hidden: true,
      parent: "Client Registration"
    },
    children: [
      {
        path: "add-client-parent",
        name: "add-client-registration-parent",
        component: AddClient,
        meta: {
          header: true,
          permission: CLIENT.REGISTRATION_CREATE,
          title: "Client | Create",
          name: "Buat Induk Client",
          form: "parent",
          before: "/client/client-registration"
        }
      }
    ]
  },
  {
    path: "/client/client-registration",
    component: Layout,
    name: "add-client-management-branch",
    meta: {
      hidden: true,
      parent: "Klien"
    },
    children: [
      {
        path: "add-client-branch",
        name: "add-client-registration-branch",
        component: AddClient,
        meta: {
          header: true,
          permission: CLIENT.REGISTRATION_CREATE,
          title: "Klien | Create",
          name: "Buat Klien Cabang",
          form: "branch",
          before: "/client/client-registration"
        }
      }
    ]
  },
  {
    path: "/client",
    component: Layout,
    name: "edit-client-branch",
    meta: {
      hidden: false,
      parent: "Klien",
    },
    children: [
      {
        path: ":id/edit-client-branch/:params",
        name: "edit-client-branch",
        component: EditClientBranch,
        meta: {
          header: true,
          customPath: true,
          permission: FlagsPermissionClient.client_v2_enable,
          title: "",
          name: "Ubah Klien Cabang",
          form: "edit-client-branch",
        }
      }
    ]
  },
  {
    path: "/client",
    component: Layout,
    name: "edit-client-parent",
    meta: {
      hidden: false,
      parent: "Klien",
    },
    children: [
      {
        path: ":id/edit-client-parent/:params",
        name: "edit-client-parent",
        component: EditClientParent,
        meta: {
          header: true,
          customPath: true,
          permission: FlagsPermissionClient.client_v2_enable,
          title: "",
          name: "Ubah Klien Induk",
          form: "edit-client-parent",
        }
      }
    ]
  },
  {
    path: "/client/client-registration",
    component: Layout,
    name: "detail-client-registration",
    meta: {
      hidden: true,
      parent: "Client Registration"
    },
    children: [
      {
        path: ":id",
        name: "detail-client-registration",
        component: DetailClient,
        meta: {
          title: "Client | Detail",
          header: true,
          permission: CLIENT.REGISTRATION_DETAIL,
          form: "registration",
        }
      }
    ]
  },
  {
    path: "/client/client-management",
    component: Layout,
    name: "detail-client-management",
    meta: {
      hidden: true,
      parent: "Client Management",
      isFreeze: true
    },
    children: [
      {
        path: ":id",
        name: "detail-client-management",
        component: ClientManagementDetail,
        meta: {
          header: true,
          permission: CLIENT.MANAGEMENT_DETAIL,
          title: "Client Management | Detail",
          form: "management",
        }
      }
    ]
  }
];
