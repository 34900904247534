import { ANNOUNCEMENT } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const AnnouncementDetail = () =>
  import(
    /* webpackChunkName: "Announcement" */ "@/app/ui/views/announcement/component/detail.vue"
  );
const AnnouncementEdit = () =>
  import(
    /* webpackChunkName: "Announcement" */ "@/app/ui/views/announcement/component/edit.vue"
  );
const AnnouncementAdd = () =>
  import(
    /* webpackChunkName: "Announcement" */ "@/app/ui/views/announcement/component/add.vue"
  );

export default [
  {
    path: "/admin/announcement",
    component: Layout,
    name: "detail-announcement",
    meta: {
      hidden: true,
      parent: "Pengumuman",
      permission: ANNOUNCEMENT.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-announcement",
        component: AnnouncementDetail,
        meta: {
          header: true,
          title: "Pengumuman | Detail"
        }
      }
    ]
  },
  {
    path: "/admin/announcement",
    component: Layout,
    name: "edit-announcement",
    meta: {
      hidden: true,
      parent: "Pengumuman",
      permission: ANNOUNCEMENT.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-announcement",
        component: AnnouncementEdit,
        meta: {
          header: true,
          title: "Pengumuman | Edit",
          name: "Edit",
          before: "/admin/announcement/:id"
        }
      }
    ]
  },
  {
    path: "/admin/announcement",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Pengumuman"
    },
    children: [
      {
        path: "add",
        name: "add-announcement",
        component: AnnouncementAdd,
        meta: {
          header: true,
          permission: ANNOUNCEMENT.CREATE,
          title: "Pengumuman | Create",
          name: "Buat Pengumuman Baru",
          before: "/admin/announcement"
        }
      }
    ]
  }
];
