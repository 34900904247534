import { injectable } from "tsyringe";
import { MessagingServiceConfigurationApiRepository } from "@/app/infrastructures/repositories/api/MessagingServiceConfigurationApiRepository";
import {
  MessageDictionary,
  MessagingServiceConfigurationList,
  MessagingServiceConfigurationListData,
  TemplateWhatsappPremium
} from "@/domain/entities/MessagingServiceConfiguration";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  AddMessagingServiceConfigurationRequest,
  MessagingServiceConfigurationRequest
} from "@/data/payload/contracts/MessagingServiceConfigurationRequest";
import { RequestMessagingServiceConfigurationList } from "@/data/payload/api/MessagingServiceConfigurationApiRequest";

@injectable()
export class MessagingServiceConfigurationPresenter {
  private repository: MessagingServiceConfigurationApiRepository;

  constructor(repo: MessagingServiceConfigurationApiRepository) {
    this.repository = repo;
  }

  getMessagingServiceConfigurationList(
    params: RequestMessagingServiceConfigurationList
  ): Promise<MessagingServiceConfigurationList> {
    return this.repository.getMessagingServiceConfigurationList(params);
  }

  getMessagingServiceConfigurationDetail(
    id: number
  ): Promise<MessagingServiceConfigurationListData> {
    return this.repository.getMessagingServiceConfigurationDetail(id);
  }

  public async createMessagingConfig(
    payload: AddMessagingServiceConfigurationRequest
  ): Promise<ResponsePayload> {
    return this.repository.createMessagingConfig(payload);
  }

  getMessageDictionary(): Promise<MessageDictionary> {
    return this.repository.getMessageDictionary();
  }

  public async patchMessagingConfig(
    payload: MessagingServiceConfigurationRequest
  ): Promise<ResponsePayload> {
    return this.repository.patchMessagingConfig(payload);
  }

  getTemplateWhatsappPremium(params: any): Promise<TemplateWhatsappPremium[]> {
    return this.repository.getTemplateWhatsappPremium(params);
  }
}
