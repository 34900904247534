import { StiDestScApiRepository } from "@/app/infrastructures/repositories/api/StiDestScApiRepository";
import { injectable } from "tsyringe";
import { ChangeStiDestScRequestInterface } from "@/data/payload/contracts/StiDestScRequest";
import {
  StiDestScEntities,
  PrintDataList,
  DetailSttDestScInformation
} from "@/domain/entities/StiDestSc";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

@injectable()
export class StiDestScPresenter {
  private repository: StiDestScApiRepository;

  constructor(repository: StiDestScApiRepository) {
    this.repository = repository;
  }

  public getListStiDestSc(
    page: number,
    limit: number,
    startDate: string,
    endDate: string,
    search: string
  ): Promise<StiDestScEntities> {
    return this.repository.getListStiDestSc(
      page,
      limit,
      startDate,
      endDate,
      search
    );
  }

  public getPrintDataList(id: number): Promise<PrintDataList> {
    return this.repository.getPrintDataList(id);
  }

  public getDetailSttStiDestSc(
    sttOrBagNumber: string
  ): Promise<DetailSttDestScInformation[]> {
    return this.repository.getDetailSttStiDestSc(sttOrBagNumber);
  }

  public async generateStiDestSc(
    payload: ChangeStiDestScRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.generateStiDestSC(payload);
  }
}
