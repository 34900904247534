import { COURIER_MANAGEMENT } from "@/app/infrastructures/misc/RolePermission";
import { FlagsPermissionCourierManagement } from "@/feature-flags/flags-permission-courier-management";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const List = () =>
  import(
    /* webpackChunkName: "CourierManagement" */ "@/app/ui/views/courier-management/index.vue"
  );
  const CourierManagementList = () =>
  import(
    /* webpackChunkName: "CourierManagement" */ "@/app/ui/views/courier-management/courier-management-list/index.vue"
  );
  const CourierManagementApproval = () =>
  import(
    /* webpackChunkName: "CourierManagement" */ "@/app/ui/views/courier-management/courier-management-approval/index.vue"
  );

const Form = () =>
  import(
    /* webpackChunkName: "CourierManagement" */ "@/app/ui/views/courier-management/form/index.vue"
  );

export default [
  {
    path: "/courier-management",
    component: Layout,
    name: "courier-management",
    meta: {
      icon: "courier",
      children: false,
      title: "Manajemen Kurir",
      name: "Manajemen Kurir",
      permission: [
        FlagsPermissionCourierManagement.permission_courier_management_enable,
        FlagsPermissionCourierManagement.permission_courier_management_approval
      ]
    },
    children: [
      {
        path: "/courier-management",
        name: "Manajemen Kurir",
        redirect: 
        FlagsPermissionCourierManagement.permission_courier_management_enable.isEnabled() ? "/courier-management/courier-list" : FlagsPermissionCourierManagement.permission_courier_management_approval.isEnabled() ? "/courier-management/courier-list-approval" : "",
        component: List,
        meta: {
          header: true
        },
        children: [
          {
            path: "courier-list",
            name: "courier-list",
            component: CourierManagementList,
            meta: {
              title: "Daftar Kurir",
              name: "Manajemen Kurir",
              permission: FlagsPermissionCourierManagement.permission_courier_management_enable
            }
          },
          {
            path: "courier-list-approval",
            name: "courier-list-approval",
            component: CourierManagementApproval,
            meta: {
              title: "Daftar Kurir Approval",
              name: "Manajemen Kurir",
              permission:
              FlagsPermissionCourierManagement.permission_courier_management_approval
            }
          }
        ]
      }
    ]
  },
  {
    path: "/courier-management",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Manajemen Kurir"
    },
    children: [
      {
        path: "add",
        name: "courier-management-add",
        component: Form,
        meta: {
          header: true,
          title: "Manajemen Kurir | Tambah",
          name: "Tambah Kurir",
          before: "/courier-management",
          permission: COURIER_MANAGEMENT.CREATE
        }
      }
    ]
  },
  {
    path: "/courier-management",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Manajemen Kurir"
    },
    children: [
      {
        path: ":id",
        name: "courier-management-detail",
        component: Form,
        meta: {
          header: true,
          title: "Manajemen Kurir | Detail",
          permission: COURIER_MANAGEMENT.DETAIL
        }
      }
    ]
  },
  {
    path: "/courier-management",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Detail Manajemen Kurir"
    },
    children: [
      {
        path: ":id/edit",
        name: "courier-management-edit",
        component: Form,
        meta: {
          header: true,
          title: "Manajemen Kurir | Edit",
          permission: COURIER_MANAGEMENT.EDIT,
          name: "Edit",
          before: "/courier-management/:id"
        }
      }
    ]
  }
];
