import { FlagsPermissionConfigFakeDexIndication } from "@/feature-flags/flags-permission-config-fake-dex-indication";
import { FlagsPermissionConfigQuotaTicket } from "@/feature-flags/flags-permission-config-quota-ticket";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

const ConfigFakeDexIndicationList = () =>
  import(
    /* webpackChunkName: "ConfigFakeDexIndicationList" */ "@/app/ui/views/customer-service/config-fake-dex-indication/index.vue"
  );
const ConfigQuotaTicketList = () =>
  import(
    /* webpackChunkName: "ConfigQuotaTicketList" */ "@/app/ui/views/customer-service/config-quota-ticket/index.vue"
  );
import ConfigFakeDexIndicationModules from "./config-fake-dex-indication";
import ConfigQuotaTicketModules from "./config-quota-ticket";

export default [
  {
    path: "/customer-service",
    redirect: "/customer-service/config-fake-dex-indication",
    component: Layout,
    name: "customer-service",
    meta: {
      children: true,
      icon: "customer-service",
      title: "Customer Service",
      name: "Customer Service",
      permission: [
        FlagsPermissionConfigFakeDexIndication.permission_config_fake_dex_indication_enable,
        FlagsPermissionConfigQuotaTicket.permission_config_quota_ticket_enable
      ]
    },
    children: [
      {
        path: "config-quota-ticket",
        name: "config-quota-ticket-list",
        component: ConfigQuotaTicketList,
        meta: {
          header: true,
          name: "Konfigurasi Kuota Tiket CS",
          title: "Konfigurasi Kuota Tiket CS",
          icon: "notebook",
          permission:
            FlagsPermissionConfigQuotaTicket.permission_config_quota_ticket_enable
        }
      },
      {
        path: "config-fake-dex-indication",
        name: "config-fake-dex-indication-list",
        component: ConfigFakeDexIndicationList,
        meta: {
          header: true,
          name: "Automasi Indikasi DEX Palsu",
          title: "Automasi Indikasi DEX Palsu",
          icon: "notebook-slash",
          permission:
            FlagsPermissionConfigFakeDexIndication.permission_config_fake_dex_indication_enable
        }
      }
    ]
  },
  ...ConfigQuotaTicketModules,
  ...ConfigFakeDexIndicationModules
];
