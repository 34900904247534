import { PRICING_CONFIGURABLE_PRICE } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const DetailHeavyWeightSurcharge = () =>
  import(
    /* webpackChunkName: "ConfigPrice" */ "@/app/ui/views/pricing/config-price/heavyweight-surcharge/detail.vue"
  );
const EditHeavyWeightSurcharge = () =>
  import(
    /* webpackChunkName: "ConfigPrice" */ "@/app/ui/views/pricing/config-price/heavyweight-surcharge/edit.vue"
  );
const ConfigPriceDetailInsurance = () =>
  import(
    /* webpackChunkName: "ConfigPrice" */ "@/app/ui/views/pricing/config-price/insurance/detail.vue"
  );
const EditInsurance = () =>
  import(
    /* webpackChunkName: "ConfigPrice" */ "@/app/ui/views/pricing/config-price/insurance/edit.vue"
  );
const ConfigPriceDetailWoodpacking = () =>
  import(
    /* webpackChunkName: "ConfigPrice" */ "@/app/ui/views/pricing/config-price/woodpacking/detail-woodpacking.vue"
  );
const ConfigPriceDetailWoodpackingEdit = () =>
  import(
    /* webpackChunkName: "ConfigPrice" */ "@/app/ui/views/pricing/config-price/woodpacking/edit-woodpacking.vue"
  );
const ConfigPriceDetailAdjustmentPenalty = () =>
  import(
    /* webpackChunkName: "ConfigPrice" */ "@/app/ui/views/pricing/config-price/adjustment-penalty/detail.vue"
  );
const EditRefundConfiguration = () =>
  import(
    /* webpackChunkName: "ConfigPrice" */ "@/app/ui/views/pricing/config-price/refund-configuration/edit.vue"
  );
const ConfigPriceDetailRefundConfig = () =>
  import(
    /* webpackChunkName: "ConfigPrice" */ "@/app/ui/views/pricing/config-price/refund-configuration/detail.vue"
  );
const ConfigPriceDetailAdjustmentPenaltyEdit = () =>
  import(
    /* webpackChunkName: "ConfigPrice" */ "@/app/ui/views/pricing/config-price/adjustment-penalty/edit.vue"
  );
const ConfigPriceDetailForwardBookingCommission = () =>
  import(
    /* webpackChunkName: "ConfigPrice" */ "@/app/ui/views/pricing/config-price/forward-booking-commission/forward-booking-commission-detail/index.vue"
  );
const ConfigPriceEditForwardBookingCommission = () =>
  import(
    /* webpackChunkName: "ConfigPrice" */ "@/app/ui/views/pricing/config-price/forward-booking-commission/forward-booking-commission-edit/index.vue"
  );
const ConfigPriceDetailCod = () =>
  import(
    /* webpackChunkName: "ConfigPrice" */ "@/app/ui/views/pricing/config-price/cod/index.vue"
  );
const DetailRTSHQ = () =>
  import(
    /* webpackChunkName: "ConfigPrice" */ "@/app/ui/views/pricing/config-price/config-rtshq/detail.vue"
  );

const ConfigPriceEditRTSHQ = () =>
  import(
    /* webpackChunkName: "ConfigPrice" */ "@/app/ui/views/pricing/config-price/config-rtshq/edit.vue"
  );

export default [
  {
    path: "/tarif/configurable-price/heavyweight",
    redirect: "/tarif/configurable-price",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Configurable Price",
      permission: PRICING_CONFIGURABLE_PRICE.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-heavyweight-surcharge",
        component: DetailHeavyWeightSurcharge,
        meta: {
          header: true,
          title: "Configurable Price | Detail"
        }
      }
    ]
  },
  {
    path: "/tarif/configurable-price/heavyweight",
    redirect: "/tarif/configurable-price",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Configurable Price",
      permission: PRICING_CONFIGURABLE_PRICE.ENABLE
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-heavyweight-surcharge",
        component: EditHeavyWeightSurcharge,
        meta: {
          header: true,
          title: "Configurable Price | Edit",
          name: "Edit",
          before: "/tarif/configurable-price/heavyweight/:id"
        }
      }
    ]
  },
  {
    path: "/tarif/configurable-price/insurance",
    redirect: "/tarif/configurable-price",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Configurable Price",
      permission: PRICING_CONFIGURABLE_PRICE.ENABLE
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-insurance",
        component: EditInsurance,
        meta: {
          header: true,
          title: "Configurable Price | Edit",
          name: "Edit",
          before: "/tarif/configurable-price/insurance/:id"
        }
      }
    ]
  },
  {
    path: "/tarif/configurable-price/insurance",
    redirect: "/tarif/configurable-price",
    component: Layout,
    name: "detail-configurable-price-insurance",
    meta: {
      icon: "configurable-price",
      children: false,
      hidden: true,
      parent: "Configurable Price",
      permission: PRICING_CONFIGURABLE_PRICE.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-configurable-price-insurance",
        component: ConfigPriceDetailInsurance,
        meta: {
          header: true,
          title: "Configurable Price | Detail"
        }
      }
    ]
  },
  {
    path: "/tarif/configurable-price/woodpacking",
    redirect: "/tarif/configurable-price",
    component: Layout,
    name: "detail-configurable-price-woodpacking",
    meta: {
      icon: "configurable-price",
      children: false,
      hidden: true,
      parent: "Configurable Price",
      permission: PRICING_CONFIGURABLE_PRICE.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-configurable-price-woodpacking",
        component: ConfigPriceDetailWoodpacking,
        meta: {
          header: true,
          title: "Configurable Price | Detail"
        }
      }
    ]
  },
  {
    path: "/tarif/configurable-price/woodpacking",
    component: Layout,
    name: "edit-configurable-price",
    meta: {
      icon: "configurable-price",
      children: false,
      hidden: true,
      parent: "Configurable Price",
      permission: PRICING_CONFIGURABLE_PRICE.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-configurable-price",
        component: ConfigPriceDetailWoodpackingEdit,
        meta: {
          header: true,
          title: "Configurable Price | Edit",
          name: "Edit",
          before: "/tarif/configurable-price/woodpacking/:id"
        }
      }
    ]
  },
  {
    path: "/tarif/configurable-price",
    component: Layout,
    name: "detail-configurable-price-adjustment-penalty",
    meta: {
      icon: "configurable-price",
      children: false,
      hidden: true,
      parent: "Configurable Price",
      permission: PRICING_CONFIGURABLE_PRICE.DETAIL
    },
    children: [
      {
        path: "adjustment-penalty/:id",
        name: "detail-configurable-price",
        component: ConfigPriceDetailAdjustmentPenalty,
        meta: {
          header: true,
          title: "Configurable Price | Detail"
        }
      }
    ]
  },
  {
    path: "/tarif/configurable-price",
    component: Layout,
    name: "edit-configurable-price-adjustment-penalty",
    meta: {
      icon: "configurable-price",
      children: false,
      hidden: true,
      parent: "Configurable Price",
      permission: PRICING_CONFIGURABLE_PRICE.EDIT
    },
    children: [
      {
        path: "adjustment-penalty/:id/edit",
        name: "edit-configurable-price-adjustment-penalty",
        component: ConfigPriceDetailAdjustmentPenaltyEdit,
        meta: {
          header: true,
          title: "Configurable Price | Edit",
          name: "Edit",
          before: "/tarif/configurable-price/adjustment-penalty/:id"
        }
      }
    ]
  },
  // refund configuration
  {
    path: "/tarif/configurable-price/refund-config",
    redirect: "/tarif/configurable-price",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Configurable Price",
      permission: PRICING_CONFIGURABLE_PRICE.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-refund-config",
        component: ConfigPriceDetailRefundConfig,
        meta: {
          header: true,
          title: "Configurable Price | Detail"
        }
      }
    ]
  },
  {
    path: "/tarif/configurable-price/refund-config",
    redirect: "/tarif/configurable-price",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Configurable Price",
      permission: PRICING_CONFIGURABLE_PRICE.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-refund-config",
        component: EditRefundConfiguration,
        meta: {
          header: true,
          title: "Configurable Price | Edit",
          name: "edit",
          before: "/tarif/configurable-price/refund-config/:id"
        }
      }
    ]
  },
  // forward booking commission
  {
    path: "/tarif/configurable-price/forward-booking-commission",
    redirect: "/tarif/configurable-price",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Configurable Price",
      permission: PRICING_CONFIGURABLE_PRICE.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-config-forward-booking-commission",
        component: ConfigPriceDetailForwardBookingCommission,
        meta: {
          header: true,
          title: "Configurable Price | Detail"
        }
      }
    ]
  },
  {
    path: "/tarif/configurable-price/forward-booking-commission",
    redirect: "/tarif/configurable-price",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Configurable Price",
      permission: PRICING_CONFIGURABLE_PRICE.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-forward-booking-commission",
        component: ConfigPriceEditForwardBookingCommission,
        meta: {
          header: true,
          title: "Configurable Price | Edit",
          name: "edit",
          before: "/tarif/configurable-price/forward-booking-commission/:id"
        }
      }
    ]
  },
  // cod
  {
    path: "/tarif/configurable-price/cod",
    redirect: "/tarif/configurable-price",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Configurable Price",
      permission: PRICING_CONFIGURABLE_PRICE.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-config-cod",
        component: ConfigPriceDetailCod,
        meta: {
          header: true,
          title: "Configurable Price | Detail"
        }
      }
    ]
  },
  {
    path: "/tarif/configurable-price/cod",
    redirect: "/tarif/configurable-price",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Configurable Price",
      permission: PRICING_CONFIGURABLE_PRICE.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-cod",
        component: ConfigPriceDetailCod,
        meta: {
          header: true,
          title: "Configurable Price | Edit",
          name: "edit",
          before: "/tarif/configurable-price/cod/:id"
        }
      }
    ]
  },
  {
    path: "/tarif/configurable-price/rtshq",
    redirect: "/tarif/configurable-price",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Configurable Price",
      permission: PRICING_CONFIGURABLE_PRICE.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-rtshq",
        component: ConfigPriceEditRTSHQ,
        meta: {
          header: true,
          title: "Configurable Price | Edit",
          name: "Konfigurasi RTSHQ",
          before: "/tarif/configurable-price/rtshq/:id"
        }
      }
    ]
  },
  {
    path: "/tarif/configurable-price/rtshq",
    redirect: "/tarif/configurable-price",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Configurable Price",
      permission: PRICING_CONFIGURABLE_PRICE.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-rtshq",
        component: DetailRTSHQ,
        meta: {
          header: true,
          title: "Configurable Price | Detail RTSHQ"
        }
      }
    ]
  }
];
