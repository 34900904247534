import { AccountController } from "@/app/ui/controllers/AccountController";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";

const checkEnablePaymentQris = (type: string) => {
  const enablePaymentQris = FlagsMedusa.config_enable_payment_qris.isEnabled();
  let idWhiteList: any = "";
  if (type === "booking") {
    idWhiteList = FlagsMedusa.config_id_whitelist_payment_qris_booking.getValue();
  }
  if (type === "tagihan") {
    idWhiteList = FlagsMedusa.config_id_whitelist_payment_qris_tagihan.getValue();
  }
  const accountPosId = AccountController.accountData.account_type_detail.id.toString();
  if (!enablePaymentQris) return false;
  if (!idWhiteList) return false;
  if (idWhiteList.toLowerCase() === "all") return true;
  return idWhiteList.split(",").includes(accountPosId);
};
export default checkEnablePaymentQris;
