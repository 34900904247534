import { injectable } from "tsyringe";
import { ServiceDelayApiRepository } from "@/app/infrastructures/repositories/api/ServiceDelayApiRepository";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ServiceDelayRequestInterface } from "@/data/payload/contracts/ServiceDelayRequest";

@injectable()
export class ServiceDelayPresenter {
  private repository: ServiceDelayApiRepository;

  constructor(repository: ServiceDelayApiRepository) {
    this.repository = repository;
  }

  public getList(params: ApiRequestList): Promise<ResponsePayloadV2> {
    return this.repository.getList(params);
  }

  public getDetail(id: number): Promise<ResponsePayloadV2> {
    return this.repository.getDetail(id);
  }

  public create(
    payload: ServiceDelayRequestInterface
  ): Promise<ResponsePayloadV2> {
    return this.repository.create(payload);
  }

  public edit(
    payload: ServiceDelayRequestInterface
  ): Promise<ResponsePayloadV2> {
    return this.repository.edit(payload);
  }
}
