import { injectable } from "tsyringe";
import { CodConfigurationApiRepository } from "@/app/infrastructures/repositories/api/CodConfigurationApiRepository";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { DetailCodConfigurationRetail } from "@/domain/entities/CodConfiguration";
import { SubmitCodConfigurationApiRequest } from "@/data/payload/api/CodConfigurationApiRequest";
@injectable()
export class CodConfigurationPresenter {
  private repository: CodConfigurationApiRepository;

  constructor(repository: CodConfigurationApiRepository) {
    this.repository = repository;
  }

  public getList(params: ApiRequestList): Promise<ResponsePayloadV2> {
    return this.repository.getList(params);
  }

  public getDetailCodConfiguration(id: number): Promise<DetailCodConfigurationRetail> {
    return this.repository.getDetailCodConfiguration(id);
  }
  
  editCodConfiguration(
    payload: SubmitCodConfigurationApiRequest
  ): Promise<ResponsePayloadV2> {
    return this.repository.editCodConfiguration(payload);
  }
}
