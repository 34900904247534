import { CodIncomeMapper } from "@/data/persistences/mappers/CodIncomeMapper";
import { CodIncomeEntities } from "@/domain/entities/CodIncome";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { CodIncomeRepositoryInterface } from "@/data/persistences/repositories/contracts/CodIncomeRepositoryInterface";

export class CodIncomeApiRepository implements CodIncomeRepositoryInterface {
  private service: ApiService;
  private mapper: CodIncomeMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: CodIncomeMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getCodIncomeList(
    page: number,
    limit: number,
    type: string,
    startDate: string,
    endDate: string,
    search: string
  ): Promise<CodIncomeEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListCodIncome(
        page,
        limit,
        type,
        startDate,
        endDate,
        search
      ),
      {}
    );
    return this.mapper.convertCodIncomeDataFromApi(resp);
  }

  public async getExportCodTransaction(
    page: number,
    type: string,
    startDate: string,
    endDate: string
  ): Promise<string> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getExportCodTransaction(page, type, startDate, endDate),
      {}
    );
    return this.mapper.convertExportData(resp);
  }
}
