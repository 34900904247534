import { BulkBookingCommissionCreate } from "@/data/payload/api/BulkBookingCommissionApiRequest";
import { BulkBookingCommissionRequestInterface } from "@/data/payload/contracts/BulkBookingCommissionRequest";
import { BulkBookingCommissionMapper } from "@/data/persistences/mappers/BulkBookingCommissionMapper";
import { BulkBookingCommissionRepositoryInterface } from "@/data/persistences/repositories/contracts/BulkBookingCommssionRepositoryInterface";
import {
  BulkBookingCommissionDetail,
  BulkBookingCommissionEntities
} from "@/domain/entities/BulkBookingCommission";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class BulkBookingCommissionApiRepository
  implements BulkBookingCommissionRepositoryInterface {
  private service: ApiService;
  private mapper: BulkBookingCommissionMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: BulkBookingCommissionMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async createBulkBookingCommission(
    payload: BulkBookingCommissionRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivatePostWithFormData(
      "post",
      this.endpoints.createBulkBookingCommission(),
      {},
      payload as BulkBookingCommissionCreate
    );
    return this.mapper.convertBulkBookingCreateDataFromApi(resp);
  }

  public async getBulkBookingCommissionList(
    page: number,
    limit: number,
    search: string,
    status: string
  ): Promise<BulkBookingCommissionEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getBulkBookingCommissionList(page, limit, search, status),
      {}
    );

    return this.mapper.convertBulkBokingCommissionDataFromApi(resp);
  }

  public async getBulkBookingCommissionDetail(
    id: number
  ): Promise<BulkBookingCommissionDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getBulkBookingCommissionDetail(id),
      {}
    );
    return this.mapper.convertDataBulkBookingCommissionDetailFromApi(resp);
  }
}
