import { VehicleApiRepository } from "@/app/infrastructures/repositories/api/VehicleApiRepository";
import { VehicleRequestInterface } from "@/data/payload/contracts/VehicleRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Vehicles } from "@/domain/entities/Vehicle";
import { injectable } from "tsyringe";

@injectable()
export class VehiclePresenter {
  private repository: VehicleApiRepository;

  constructor(repository: VehicleApiRepository) {
    this.repository = repository;
  }

  public createNewVehicle(
    payload: VehicleRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.createNewVehicle(payload);
  }

  public getVehicles(search: string): Promise<Vehicles> {
    return this.repository.getVehicles(search);
  }
}
