/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionTopUpAndCashback = {
  permission_finance_top_up_fee_enable: new Rox.Flag(false),
  permission_finance_cashback_enable: new Rox.Flag(false)
};

export function initFlagsPermissionTopUpAndCashback() {
  // register flags with namespaces for better grouping
  Rox.register("featureTopUpAndCashback", FlagsPermissionTopUpAndCashback);
}
