export class DetailBonusKomisi {
  pcrId = 0;
  pcrConfigurableProgressiveCommissionId = 0;
  pcrConfigurableProgressiveCommissionName = "";
  pcrPeriodStartDate = "";
  pcrPeriodEndDate = "";
  pcrPosId = 0;
  pcrPosCode = "";
  pcrPosName = "";
  pcrPos3lc = "";
  pcrPosAddress = "";
  pcrTotalRevenue = 0;
  pcrTotalAmountCalculated = "";
  pcrTotalStt = 0;
  pcrTotalCommissionAdditional = 0;
  pcrCreatedAt = "";
  pcrUpdatedAt = "";
  pcrCommissionAdditionalTier: CommissionTier[] = [];

  constructor(fields?: Partial<DetailBonusKomisi>) {
    Object.assign(this, fields);
  }
}

export class CommissionTier {
  commissionTierConfigDetailId = 0;
  commissionTierId = 0;
  commissionTierName = "";
  commissionTierLimit = 0;
  commissionTierLatestAmountCalculated = 0;
  commissionTierAmountCalculated = 0;
  commissionTierAmount = 0;

  constructor(fields?: Partial<CommissionTier>) {
    Object.assign(this, fields);
  }
}
