import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import moment from "moment";
import { configDates } from "./modules/Date";

const formatDateTrackingHistory = (
  date: any,
  timezone: any,
  showTimezone = true,
  international = false
) => {
  const locales = "ID";
  const formattedDate = new Date(date);

  let utcOffset = "0";
  if (timezone) {
    utcOffset = timezone.replace("GMT+", "+0").replace(":", "");
  }
  let newDate = moment(formattedDate)
    .utcOffset(utcOffset)
    .format("DD MMMM YYYY, HH:mm");
  const tzName = configDates(timezone)[locales.toLowerCase()];
  if (tzName) {
    newDate =
      (international
        ? dateToDateString(newDate, false, false, "en")
        : newDate) + ` ${showTimezone ? tzName : ""}`;
  }
  return newDate;
};
export default formatDateTrackingHistory;
