import { PRICING_PRODUCT } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const AddProduct = () =>
  import(
    /* webpackChunkName: "Product" */ "@/app/ui/views/pricing/product/component/add.vue"
  );
const DetailProduct = () =>
  import(
    /* webpackChunkName: "Product" */ "@/app/ui/views/pricing/product/component/detail-product.vue"
  );
const EditProduct = () =>
  import(
    /* webpackChunkName: "Product" */ "@/app/ui/views/pricing/product/component/edit.vue"
  );

export default [
  {
    path: "/master-data/product",
    component: Layout,
    name: "product-add",
    meta: {
      icon: "product",
      children: false,
      hidden: true,
      parent: "Product",
      permission: PRICING_PRODUCT.CREATE
    },
    children: [
      {
        path: "/master-data/product/add",
        name: "add-product",
        component: AddProduct,
        meta: {
          header: true,
          title: "Product | Create",
          name: "Buat Produk Baru",
          before: "/master-data/product"
        }
      }
    ]
  },
  {
    path: "/master-data/product",
    component: Layout,
    name: "detail-product",
    meta: {
      title: "Product | Detail",
      hidden: true,
      parent: "Product",
      permission: PRICING_PRODUCT.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-product",
        component: DetailProduct,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/master-data/product",
    component: Layout,
    name: "edit-product",
    meta: {
      icon: "product",
      children: false,
      hidden: true,
      parent: "Product",
      permission: PRICING_PRODUCT.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-product",
        component: EditProduct,
        meta: {
          header: true,
          title: "Product | Edit",
          name: "Edit",
          before: "/master-data/product/:id"
        }
      }
    ]
  }
];
