export const configDates = (timezone: string): any => {
  switch (timezone) {
    case "+00:00":
    case "GMT+0000":
    case "GMT+0:00":
      return { id: "UTC" };
    case "+07:00":
    case "GMT+0700":
    case "GMT+7:00":
    case "GMT+07:00":  
      return { id: "WIB" };
    case "+08:00":
    case "GMT+0800":
    case "GMT+8:00":
    case "GMT+08:00":  
      return { id: "WITA", my: "MYT" };
    case "+09:00":
    case "GMT+0900":
    case "GMT+9:00":
    case "GMT+09:00":  
      return { id: "WIT" };

    default:
      return { id: "", my: "" };
  }
};

export const configDay = (day: string) => {
  const result: any = {
    "monday": "Senin",
    "tuesday": "Selasa",
    "wednesday": "Rabu",
    "thursday": "Kamis",
    "friday": "Jumat",
    "saturday": "Sabtu",
    "sunday": "Minggu",
    "": "",
  }
  return result[day];
}

export const getIndonesianDay = (dateString: string) => {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Use Intl.DateTimeFormat with the 'id-ID' locale to get the day of the week in Indonesian
  const dayFormatter = new Intl.DateTimeFormat('id-ID', { weekday: 'long' });

  // Format the date to get the day
  return dayFormatter.format(date);
}

export const calculateHourDifference = (inputDate: string, inputTime: string) => {
  // Combine the input date and time into a single string
  const inputDateTimeString = `${inputDate}T${inputTime}:00`;
  
  // Parse the input date and time into a Date object
  const inputDateTime = new Date(inputDateTimeString);
  
  // Get the current date and time
  const currentDateTime = new Date();
  
  // Calculate the difference in milliseconds
  const diffInMilliseconds = inputDateTime.getTime() - currentDateTime.getTime();
  
  // Convert the difference from milliseconds to hours
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  
  return diffInHours;
}
