import { TrackingApiRepository } from "@/app/infrastructures/repositories/api/TrackingApiRepository";
import { TrackingSttData } from "@/domain/entities/TrackingStt";
import { injectable } from "tsyringe";
import { TrackingCargoData } from "@/domain/entities/TrackingCargo";

@injectable()
export class TrackingPresenter {
  private repository: TrackingApiRepository;

  constructor(repository: TrackingApiRepository) {
    this.repository = repository;
  }

  public getTrackingStt(
    sttNo: string,
    cache: boolean
  ): Promise<TrackingSttData> {
    return this.repository.getTrackingStt(sttNo, cache);
  }

  public getTrackingCargo(cargoNo: string): Promise<TrackingCargoData> {
    return this.repository.getTrackingCargo(cargoNo);
  }
}
