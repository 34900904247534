import { AxiosResponse } from "axios";
import {
  ConsolidatorEntities,
  ConsolidatorData
} from "@/domain/entities/Consolidator";
import { Pagination } from "@/domain/entities/Pagination";

export class ConsolidatorMapper {
  public convertConsolidatorDataFromApi(
    res: AxiosResponse<any>
  ): ConsolidatorEntities {
    const { data } = res;

    const consolidator: ConsolidatorData[] = [];
    if (data.data.length === 0) {
      return new ConsolidatorEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        consolidator.push(
          new ConsolidatorData(
            item.id,
            item.name,
            item.address,
            item.balance,
            item.code,
            item.lat,
            item.long,
            item.type,
            item.created_at,
            item.updated_at,
            item.parent_id,
            item.parent_name,
            item.parent_type,
            item.contract_end,
            item.contract_status,
            item.is_banned,
            item.is_registry,
            item.partner_location,
            item.partner_location_list
          )
        );
      });
    }
    return new ConsolidatorEntities(
      new Pagination(data.meta.page, data.meta.limit),
      consolidator
    );
  }
}
