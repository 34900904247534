/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionSaldo = {
  permission_saldo_enable: new Rox.Flag(false),
  permission_saldo_withdraw_view_detail: new Rox.Flag(false),
  permission_saldo_withdraw_request_detail: new Rox.Flag(false),
  permission_saldo_balance_mutation: new Rox.Flag(false),
  permission_saldo_balance_mutation_nett: new Rox.Flag(false),
  permission_saldo_hold_balance_history: new Rox.Flag(false)
};

export function initFlagsPermissionSaldo() {
  // register flags with namespaces for better grouping
  Rox.register("featureSaldo", FlagsPermissionSaldo);
}
