import { BOOKING_COMMISSION } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const BulkBookingCommissionCreate = () =>
  import(
    /* webpackChunkName: "CommissionConfig" */ "@/app/ui/views/commission-configuration/bulk-booking-commission/component/add.vue"
  );
const BulkBookingCommissionDetail = () =>
  import(
    /* webpackChunkName: "CommissionConfig" */ "@/app/ui/views/commission-configuration/bulk-booking-commission/detail/index.vue"
  );

export default [
  {
    path: "/tarif/commission-configuration/bulk-booking",
    component: Layout,
    name: "bulk-booking-commission-detail",
    meta: {
      title: "Bulk Booking Commission | Detail",
      hidden: true,
      parent: "Konfigurasi Komisi",
      permission: BOOKING_COMMISSION.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "bulk-booking-commission-detail",
        component: BulkBookingCommissionDetail,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/tarif/commission-configuration/bulk-booking",
    component: Layout,
    name: "bulk-book-commision-create",
    meta: {
      title: "Bulk Booking Commision | Upload",
      hidden: true,
      parent: "Konfigurasi Komisi",
      permission: BOOKING_COMMISSION.CREATE
    },
    children: [
      {
        path: "create",
        name: "bulk-book-commision-create",
        component: BulkBookingCommissionCreate,
        meta: {
          header: true,
          title: "Bulk Booking Commision | Upload",
          name: "Upload Berkas",
          before: "/tarif/commission-configuration/bulk-booking"
        }
      }
    ]
  }
];
