/* eslint-disable @typescript-eslint/camelcase */
import { injectable } from "tsyringe";
import { UserApiRepository } from "@/app/infrastructures/repositories/api/UserApiRepository";
import {
  UserEntities,
  UserDetailData,
  ReferenceEntities,
  RoleEntities
} from "@/domain/entities/User";
import {
  EditUserRequestInterface,
  AddUserRequestInterface
} from "@/data/payload/contracts/UserRequest";
import {
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";
import { RequestListUser } from "@/data/payload/api/UserApiRequest";

@injectable()
export class UserPresenter {
  private repository: UserApiRepository;

  constructor(repository: UserApiRepository) {
    this.repository = repository;
  }

  public getUserList(params: RequestListUser): Promise<UserEntities> {
    return this.repository.getUserList(params);
  }

  public getDetailUser(id: any): Promise<UserDetailData> {
    return this.repository.getDetailUser(id);
  }

  public editUser(payload: EditUserRequestInterface): Promise<ResponsePayload> {
    return this.repository.editUser(payload);
  }

  public getReference(
    account_type: any,
    search: string
  ): Promise<ReferenceEntities[]> {
    return this.repository.getReference(account_type, search);
  }

  public getRole(account_type: any): Promise<RoleEntities[]> {
    return this.repository.getRole(account_type);
  }

  public addUser(payload: AddUserRequestInterface): Promise<ResponsePayload> {
    return this.repository.addUser(payload);
  }

  public editRole(
    payload: EditUserRequestInterface
  ): Promise<ResponsePayloadV2> {
    return this.repository.editRole(payload);
  }
}
