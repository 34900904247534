import { Pagination } from "./Pagination";

export class DeliveryTieringPolicyEntities {
  pagination: Pagination;
  data: DeliveryTieringPolicyData[];
  constructor(pagination: Pagination, data: DeliveryTieringPolicyData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class DeliveryTieringPolicyData {
  id: number;
  product: string;
  description: string;
  status: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  commissions: CommissionsDtpol[];
  constructor(
    id: number,
    product: string,
    description: string,
    status: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    commissions: CommissionsDtpol[]
  ) {
    this.id = id;
    this.product = product;
    this.description = description;
    this.status = status;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.updatedAt = updatedAt;
    this.updatedBy = updatedBy;
    this.commissions = commissions;
  }
}

export class CommissionsDtpol {
  id: number;
  productId: number;
  tier: number;
  publishRateCommission: any;
  shippingSurchargeCommission: any;
  codCommission: any;
  createdAt: string;
  updatedAt: string;
  constructor(
    id: number,
    productId: number,
    tier: number,
    publishRateCommission: any,
    shippingSurchargeCommission: any,
    codCommission: any,
    createdAt: string,
    updatedAt: string
  ) {
    this.id = id;
    this.productId = productId;
    this.tier = tier;
    this.publishRateCommission = publishRateCommission;
    this.shippingSurchargeCommission = shippingSurchargeCommission;
    this.codCommission = codCommission;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
