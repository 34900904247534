export class AuthEntities {
  token = "";
  type = "";
  expiredAt = "";
  constructor(fields?: Partial<AuthEntities>) {
    Object.assign(this, fields);
  }
}

export class TokenVerificationAccountEntities {
  token = "";
  constructor(fields?: Partial<TokenVerificationAccountEntities>) {
    Object.assign(this, fields);
  }
}

export class Credential {
  username = "";
  email = "";
  password = ""
  constructor(fields?: Partial<Credential>) {
    Object.assign(this, fields);
  }
}
