import { ChangeStiDestScRequestInterface } from "@/data/payload/contracts/StiDestScRequest";
import { StiDestScMapper } from "@/data/persistences/mappers/StiDestScMapper";
import { StiDestScRepositoryInterface } from "@/data/persistences/repositories/contracts/StiDestScRepositoryInterface";
import {
  StiDestScEntities,
  PrintDataList,
  DetailSttDestScInformation
} from "@/domain/entities/StiDestSc";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { GenerateApiRequest } from "@/data/payload/api/StiDestScApiRequest";

export class StiDestScApiRepository implements StiDestScRepositoryInterface {
  private service: ApiService;
  private mapper: StiDestScMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: StiDestScMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getListStiDestSc(
    page: number,
    limit: number,
    startDate: string,
    endDate: string,
    search: string
  ): Promise<StiDestScEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListStiDestSc(page, limit, startDate, endDate, search),
      {}
    );
    return this.mapper.convertStiDestScDataFromApi(resp);
  }

  public async getPrintDataList(id: number): Promise<PrintDataList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getPrintDataSTIDestSC(id),
      {}
    );
    return this.mapper.convertPrintDataListFromApi(resp);
  }

  public async getDetailSttStiDestSc(
    sttOrBagNumber: string
  ): Promise<DetailSttDestScInformation[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailSttStiDestSc(sttOrBagNumber),
      {}
    );
    return this.mapper.convertDetailSttInformationFromApi(resp);
  }

  public async generateStiDestSC(
    payload: ChangeStiDestScRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.generateStiDestSc(),
      undefined,
      payload as GenerateApiRequest
    );
    return this.mapper.convertGenerateDataFromApi(resp);
  }
}
