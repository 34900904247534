import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { DashboardMapper } from "@/data/persistences/mappers/DashboardMapper";
import {
  CodDashboardDetailCourier,
  CutOffConsoleData,
  DashboardConsoleData,
  DashboardDetailList,
  DataCorporateDashboard,
  DetailCourierPerStatusCodDashboardData,
  ListCourierPartnerCodDashboard,
  SummaryCodDashboardData,
  ReferralAffiliate,
  SummaryCourierPartnerCodDashboard,
} from "@/domain/entities/Dashboard";
import { DashboardRepositoryInterface } from "@/data/persistences/repositories/contracts/DashboardRepositoryInterface";
import {
  DashboardConsoleRequest,
  DashboardDetailListRequest,
  DashboardDownloadRequest,
  CodDashboardRequest,
  DownloadSummaryCodRequest,
  CodDashboardCourierDetailRequest,
  CorporateDashboardRequest,
  CourierDailyDepositRequest,
} from "@/data/payload/api/DashboardRequests";
import {
  RequestList,
  ResponsePayload,
  ResponsePayloadV2,
} from "@/domain/entities/ResponsePayload";
import downloadFile from "@/app/infrastructures/misc/common-library/DownloadFile";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Constants";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { FlagsDashboardDeliveryCOD } from "@/feature-flags/flags-dashboard-delivery-cod";

export class DashboardApiRepository implements DashboardRepositoryInterface {
  private service: ApiService;
  private mapper: DashboardMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: DashboardMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  async getSummarryPOSCodDashboard(
    type: string,
    date: any
  ): Promise<SummaryCourierPartnerCodDashboard> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getSummaryCodCourierPartner(type, date)
    );
    return this.mapper.convertSummaryCourierDashboardCodDashboard(res);
  }

  async getConsoleData(
    params: DashboardConsoleRequest
  ): Promise<DashboardConsoleData> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getDashboardConsole(params)
    );

    return this.mapper.convertDashboardConsoleDataFromApi(res);
  }

  async getCutOffConsoleOutgoingStt(): Promise<CutOffConsoleData> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getCutOffConsoleOutgoingStt()
    );

    return this.mapper.convertDashboardCutOffOutgoingDataFromApi(res);
  }

  async getDashboardDetailList(
    params: DashboardDetailListRequest
  ): Promise<DashboardDetailList> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getDashboardDetailList(params)
    );
    return this.mapper.convertDashboardDetailListFromApi(res);
  }

  async getDownloadDashboard(
    params: DashboardDownloadRequest
  ): Promise<ResponsePayload> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getDownloadDashboard(params)
    );
    return this.mapper.convertDashboardDownloadDataFromApi(res);
  }

  async getListPosFollowUpDashboard(
    params: RequestList
  ): Promise<ResponsePayloadV2> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getPosFollowUpList(params)
    );
    return this.mapper.convertDashboardCutOffPosFollowUpDataFromApi(res);
  }

  public async getDownloadDetailPOS(
    params: DashboardDetailListRequest
  ): Promise<any> {
    return downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.getDownloadPOSDashboard(params)}`,
      `${params.status}_${params.posCode}`,
      ".xlsx",
      true
    );
  }

  public async getListCourierPartnerCodDashboard(
    params: CodDashboardRequest
  ): Promise<ListCourierPartnerCodDashboard> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getDashboardDelivery(
        "/list/cod",
        params.toQueryString(),
        FlagsDashboardDeliveryCOD.version_hydra_dashboard_delivery.getValue()
      )
    );
    return this.mapper.convertListCourierPartnerCodDashboardFromApi(resp);
  }

  public async getListCourierPartnerCodDashboardV2(
    params: CodDashboardRequest
  ): Promise<ListCourierPartnerCodDashboard> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getDashboardDelivery(
        "/list/cod",
        params.toQueryString(),
        FlagsDashboardDeliveryCOD.version_hydra_dashboard_delivery.getValue()
      )
    );
    return this.mapper.convertListCourierPartnerCodDashboardFromApiV2(resp);
  }

  public async getListCourierPartnerCodDashboardV3(
    params: CodDashboardRequest
  ): Promise<ListCourierPartnerCodDashboard> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getDashboardDelivery(
        "/list/cod",
        params.toQueryString(),
        "v3"
      )
    );
    return this.mapper.convertListCourierPartnerCodDashboardFromApiV3(resp);
  }

  public async getSummaryDownload(
    type: string,
    filter: DownloadSummaryCodRequest
  ): Promise<any> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSummaryDownload(type, filter),
      {}
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async getDetailCourierDownloadCodDashboardData(
    driverPhone: string,
    filter: CodDashboardCourierDetailRequest,
    isPic = false
  ): Promise<any> {
    const endpointDownload = isPic
      ? this.endpoints.getDownloadPicData
      : this.endpoints.getDownloadCourierData;
    const resp = await this.service.invoke(
      "get",
      endpointDownload(driverPhone, filter),
      {}
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async getSummaryCodDashboardData(
    path: string,
    params: CodDashboardRequest
  ): Promise<SummaryCodDashboardData> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getDashboardDelivery(path, params.toQueryString())
    );
    return this.mapper.convertSummaryCodDashboardDataFromApi(resp);
  }

  public async postCourierDailyDeposit(
    type: string,
    payload: CourierDailyDepositRequest
  ): Promise<ResponsePayload> {
    const path =
      type === "courier" ? "/deposit/courier/daily" : "/deposit/pic/daily";
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.getDashboardDelivery(
        path,
        "",
        FlagsDashboardDeliveryCOD.version_hydra_dashboard_delivery.getValue()
      ),
      undefined,
      payload
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async postCourierDailyDepositV3(
    type: string,
    payload: CourierDailyDepositRequest
  ): Promise<ResponsePayload> {
    const path =
      type === "courier" ? "/deposit/courier/daily" : "/deposit/pic/daily";
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.getDashboardDelivery(path, "", "v3"),
      undefined,
      payload
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async getDetailCourierPerStatusCodDashboardData(
    params: CodDashboardCourierDetailRequest
  ): Promise<DetailCourierPerStatusCodDashboardData[]> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getDetailCourierPerStatusCodDashboard(params)
    );
    return this.mapper.convertDetailCourierPerStatusCodDashboardDataFromApi(
      resp
    );
  }

  public async getCourierDetailData(
    params: CodDashboardCourierDetailRequest
  ): Promise<CodDashboardDetailCourier> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getDetailCourierData(params.toQueryString(), params.type)
    );
    return this.mapper.convertDetailCourierPartnerCodDashboardFromApi(resp);
  }

  public async getCorporateDashboardData(
    path: string,
    params: CorporateDashboardRequest
  ): Promise<DataCorporateDashboard> {
    let url = this.endpoints.getCorporateDashboard(
      path,
      params.toQueryString()
    );
    if (IS_SENDER_ACCOUNT) {
      url = this.endpoints.getSenderDashboard(path, params.toQueryString());
    } else if (AccountController.accountData.isPosAccount) {
      url = this.endpoints.getPosPerformanceDashboard(
        path,
        params.toQueryString()
      );
    }
    const resp = await this.service.invoke("GET", url);
    return this.mapper.convertDataCorporateDashboardFromApi(resp);
  }

  public async getReferralAffiliate(): Promise<ReferralAffiliate> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getReferralAffiliate()
    );

    return this.mapper.convertDataReferralAffiliateFromApi(resp);
  }
}
