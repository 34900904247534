import { BookingCommissionRepositoryInterface } from "@/data/persistences/repositories/contracts/BookingCommissionRepositoryInterface";
import {
  BookingCommissionDetail,
  BookingCommissionEntities
} from "@/domain/entities/BookingCommission";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { BookingCommissionMapper } from "@/data/persistences/mappers/BookingCommissionMapper";
import { BookingCommissionRequest } from "@/data/payload/contracts/BookingCommissionRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  AddBookingCommissionRequest,
  EditBookingCommissionRequest,
  RequestBookingCommissionList
} from "@/data/payload/api/BookingCommissionApiRequest";

export class BookingCommissionApiRepository
  implements BookingCommissionRepositoryInterface {
  private service: ApiService;
  private mapper: BookingCommissionMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: BookingCommissionMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  public async getBookingCommissionList(
    params: RequestBookingCommissionList
  ): Promise<BookingCommissionEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getBookingCommissionList(params),
      {}
    );
    return this.mapper.convertBokingCommissionDataFromApi(resp);
  }

  public async getBookingCommissionDetail(
    id: number
  ): Promise<BookingCommissionDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getBookingCommissionDetail(id),
      {}
    );
    return this.mapper.convertDataBookingCommissionDetailFromApi(resp);
  }

  public async addBookingCommission(
    payload: BookingCommissionRequest
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivate(
      "POST",
      this.endpoints.bookingCommissionEndpoint(),
      undefined,
      payload as AddBookingCommissionRequest
    );
    return this.mapper.convertPostDataFromApi(resp);
  }

  public async editBookingCommission(
    payload: BookingCommissionRequest
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivate(
      "PATCH",
      this.endpoints.bookingCommissionEndpoint(),
      undefined,
      payload as EditBookingCommissionRequest
    );
    return this.mapper.convertPostDataFromApi(resp);
  }
}
