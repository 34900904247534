import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import { PaginationV2 } from "@/domain/entities/Pagination";
import { ConfigFakeDexIndicationDetail } from "@/domain/entities/ConfigFakeDexIndication";

export class ConfigFakeDexIndicationMapper {
  public convertListDataFromApi(result: AxiosResponse<any>): ResponsePayloadV2 {
    const { data } = result;

    return new ResponsePayloadV2({
      success: data.success,
      message: data.message,
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.limit,
        totalData: data.meta.total_records
      }),
      data: data.data.length
        ? data.data.map(
          (item: any) =>
            new ConfigFakeDexIndicationDetail({
              dfdcId: item.dfdc_id,
              dfdcName: item.dfdc_name,
              dfdcDescription: item.dfdc_description,
              dfdcCreatedAt: item.dfdc_created_at,
              dfdcCreatedBy: item.dfdc_created_by,
              dfdcUpdatedAt: item.dfdc_updated_at,
              dfdcUpdatedBy: item.dfdc_updated_by,
              dfdcStatus: item.dfdc_status
            })
        )
        : []
    });
  }

  public convertDetailDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = result;
    return new ResponsePayloadV2({
      success: data.success,
      message: data.message,
      data: new ConfigFakeDexIndicationDetail({
        dfdcId: data.dfdc_id,
        dfdcName: data.dfdc_name,
        dfdcDescription: data.dfdc_description,
        dfdcCreatedAt: data.dfdc_created_at,
        dfdcCreatedBy: data.dfdc_created_by,
        dfdcUpdatedAt: data.dfdc_updated_at,
        dfdcUpdatedBy: data.dfdc_updated_by,
        dfdcStatus: data.dfdc_status,
        dfdcType: data.dfdc_type,
        dfdcReasonCode: data.dfdc_reason_code,
        dfdcReasonCategory: data.dfdc_reason_category,
        dfdcParamStartTime: data.dfdc_param_start_time,
        dfdcParamEndTime: data.dfdc_param_end_time,
        dfdcParamExcludeWeekendHoliday: data.dfdc_param_exclude_weekend_holiday,
        dfdcOfficeStartTime: data.dfdc_office_start_time,
        dfdcOfficeEndTime: data.dfdc_office_end_time,
        dfdcOfficeExcludeWeekendHoliday: data.dfdc_office_exclude_weekend_holiday,
        dfdcGenesis: data.dfdc_genesis,
        dfdcDriverApp: data.dfdc_driver_app,
      })
    });
  }

  public convertEditConfigFromApi(result: AxiosResponse): ResponsePayloadV2 {
    const { data } = result;
    return new ResponsePayloadV2({
      success: data.success,
      code: data.code,
      message: data.message
    });
  }
}


