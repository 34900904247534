/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionTopUpApproval = {
  permission_topup_manual_approval_enable : new Rox.Flag(false),
  permission_topup_manual_approval_list_view : new Rox.Flag(false),
  permission_topup_manual_approval_detail_view : new Rox.Flag(false),
  permission_topup_manual_approval_approval : new Rox.Flag(false)
};

export function initFlagsPermissionTopUpApproval() {
  // register flags with namespaces for better grouping
  Rox.register("featureTopUpApproval", FlagsPermissionTopUpApproval);
}
