import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { BulkBookingMapper } from "@/data/persistences/mappers/BulkBookingMapper";
import { BulkBookingRepositoryInterface } from "@/data/persistences/repositories/contracts/BulkBookingRepositoryInterface";
import {
  BulkBookingEntities,
  BulkBookingResiEntities,
  BulkBookingSuccess,
  ClientBookingEntities,
  ClientBookingTemplateDetail
} from "@/domain/entities/BulkBooking";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { BulkBookingUploadRequestInterface } from "@/data/payload/contracts/BulkBookingRequest";
import { downloadFile } from "../../misc/Utils";
import {
  RequestBulkBooking,
  EditClientTemplateConfiguration,
  CreateClientTemplateConfiguration
} from "@/data/payload/api/BulkApiRequest";
import { ClientBookingListParams } from "@/data/payload/api/BulkBookingApiRequest";

export class BulkBookingApiRepository
  implements BulkBookingRepositoryInterface {
  private service: ApiService;
  private mapper: BulkBookingMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: BulkBookingMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  async getSuccessListBooked(id: number): Promise<BulkBookingSuccess> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSuccessListBooked(id),
      {}
    );
    return this.mapper.convertSuccessDataFromApi(resp);
  }

  async downloadFailedFile(id: number): Promise<void> {
    downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.downloadFailedFileBulkBooking(id)}`,
      `${id}_bulk_booking_failed`,
      ".xlsx"
    );
  }

  async getListBulkBooking(
    params: RequestBulkBooking
  ): Promise<BulkBookingEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListBulkBooking(params),
      {}
    );
    return this.mapper.convertListBulkBookingFromApi(resp);
  }

  public async uploadBulkBooking(
    payload: BulkBookingUploadRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invokePostWithFormData(
      "post",
      this.endpoints.addBulkUploadRetailPrice(),
      {},
      payload
    );
    return this.mapper.convertUploadBulkBookingFromApi(response);
  }

  public async getListBulkBookingResi(
    id: number
  ): Promise<BulkBookingResiEntities> {
    const response = await this.service.invoke(
      "get",
      this.endpoints.getListBulkBookingResi(id),
      {}
    );
    return this.mapper.convertBulkBookingResiFromApi(response);
  }

  public async getDetailClientBooking(
    id: number
  ): Promise<ClientBookingTemplateDetail> {
    const response = await this.service.invoke(
      "get",
      this.endpoints.getDetailClientBookingTemplate(id),
      {}
    );
    return this.mapper.convertDetailClientBookingFromApi(response);
  }

  async getListClientBooking(
    params: ClientBookingListParams
  ): Promise<ClientBookingEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListClientBookingTemplate(params),
      {}
    );
    return this.mapper.convertListClientBookingFromApi(resp);
  }

  async editClientTemplateConfiguration(
    id: number,
    payload: EditClientTemplateConfiguration
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "put",
      this.endpoints.editClientTemplateConfiguration(id),
      undefined,
      payload as EditClientTemplateConfiguration
    );

    return this.mapper.convertEditClientTemplateConfigurationToMapper(resp);
  }

  async createClientTemplateConfiguration(
    payload: CreateClientTemplateConfiguration
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.createClientTemplateConfiguration(),
      undefined,
      payload as CreateClientTemplateConfiguration
    );

    return this.mapper.convertCreateClientTemplateConfigurationToMapper(resp);
  }
}
