import {
  DetailForwardBookingCommission,
  ConfigurablePrice
} from "@/domain/entities/ForwardBookingCommission";
import { AxiosResponse } from "axios";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class ForwardBookingCommissionMapper {
  public convertDetailForwardBookingCommissionFromApi(
    res: AxiosResponse<any>
  ): DetailForwardBookingCommission {
    const {
      data: { data }
    } = res;
    return new DetailForwardBookingCommission({
      id: data.configurable_price_id,
      configurablePriceName: data.configurable_price_name,
      configurablePriceType: data.configurable_price_type,
      configurablePriceDescription: data.configurable_price_description,
      configurablePriceStatus: data.configurable_price_status,
      configurablePriceCreatedAt: data.configurable_price_created_at,
      configurablePriceCreatedBy: data.configurable_price_created_by,
      configurablePriceUpdatedAt: data.configurable_price_updated_at,
      configurablePriceUpdatedBy: data.configurable_price_updated_by,
      configurablePriceForwardBookingCommission: new ConfigurablePrice({
        commissionId: data.configurable_forward_booking_commission.cfbc_id,
        commission: data.configurable_forward_booking_commission.cfbc_commission_amount.toLocaleString(
          "id"
        ),
        commissionReference:
          data.configurable_forward_booking_commission
            .cfbc_commission_reference,
        commissionType:
          data.configurable_forward_booking_commission.cfbc_commission_type,
        commissionParameter:
          data.configurable_forward_booking_commission.cfbc_quantifier,
        commissionAppliedTo:
          data.configurable_forward_booking_commission.cfbc_applied_to,
        commissionPrefix:
          data.configurable_forward_booking_commission.cfbc_shipment_prefix
      })
    });
  }

  public convertPutDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
