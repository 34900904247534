import { IntegrationMonitoringApiRepository } from "@/app/infrastructures/repositories/api/IntegrationMonitoringApiRepository";
import {
  ElexysFailedBookingEntities,
  LogSttData,
  RequestApiElexysFailedBookingList,
  RequestDownloadElexysFailedBooking
} from "@/domain/entities/IntegrationMonitoring";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { injectable } from "tsyringe";
import { RetryBookingElexysRequestInterface } from "@/data/payload/contracts/RetryBookingElexysRequest";

@injectable()
export class IntegrationMonitoringPresenter {
  private repository: IntegrationMonitoringApiRepository;

  constructor(repo: IntegrationMonitoringApiRepository) {
    this.repository = repo;
  }

  public async getElexysFailedBookingList(
    params: RequestApiElexysFailedBookingList
  ): Promise<ElexysFailedBookingEntities> {
    return await this.repository.getElexysFailedBookingList(params);
  }
  public async downloadElexysFailedBooking(
    params: RequestDownloadElexysFailedBooking
  ): Promise<any> {
    return await this.repository.downloadElexysFailedBooking(params);
  }

  public async getLogStt(sttNo: string): Promise<LogSttData> {
    return await this.repository.getLogStt(sttNo);
  }

  public async retryBookingElexys(
    params: RetryBookingElexysRequestInterface
  ): Promise<ResponsePayload> {
    return await this.repository.retryElexysBooking(params);
  }
}
