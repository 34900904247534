import {
  TopUpVerificationDetail,
  TopUpVerificationEntities
} from "@/domain/entities/TopupVerification";
import { TopUpVerificationApiRepository } from "@/app/infrastructures/repositories/api/TopUpVerificationApiRepository";
import { TopUpVerificationRequest } from "@/data/payload/contracts/TopUpVerificationRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class TopUpVerificationPresenter {
  private repository: TopUpVerificationApiRepository;

  constructor(repository: TopUpVerificationApiRepository) {
    this.repository = repository;
  }

  public getTopUpVerificationDetail(
    id: number
  ): Promise<TopUpVerificationDetail> {
    return this.repository.getTopUpVerificationDetail(id);
  }

  public getTopUpVerificationList(
    page: number,
    limit: number,
    search: string,
    partner: string,
    status: string,
    bank: string,
    verifiedBy: string
  ): Promise<TopUpVerificationEntities> {
    return this.repository.getTopUpVerificationList(
      page,
      limit,
      search,
      partner,
      status,
      bank,
      verifiedBy
    );
  }

  public getListBankName(): Promise<string[]> {
    return this.repository.getListBankName();
  }

  public getListVerifier(): Promise<Array<any>> {
    return this.repository.getListVerifier();
  }

  public verifyTopUpVerification(
    payload: TopUpVerificationRequest
  ): Promise<ResponsePayload> {
    return this.repository.verifyTopUpVerification(payload);
  }

  public rejectTopUpVerification(
    payload: TopUpVerificationRequest
  ): Promise<ResponsePayload> {
    return this.repository.rejectTopUpVerification(payload);
  }
}
