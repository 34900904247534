import { ALLOWED } from "@/app/infrastructures/misc/RolePermission";
const UniversalPrintPage = () =>
  import(
    /* webpackChunkName: "UniversalPrint" */ "@/app/ui/views/universal-print/index-v2.vue"
  );

export default [
  {
    path: "/print/stt",
    name: "universal-print",
    component: UniversalPrintPage,
    meta: {
      hidden: true,
      noAuth: true,
      permission: ALLOWED
    }
  }
];
