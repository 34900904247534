import { PosApiRepository } from "@/app/infrastructures/repositories/api/PosApiRepository";
import { injectable } from "tsyringe";
import { PosEntities } from "@/domain/entities/Pos";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";

@injectable()
export class PosPresenter {
  private repository: PosApiRepository;

  constructor(repository: PosApiRepository) {
    this.repository = repository;
  }

  public getListPos(params: RequestListPartner): Promise<PosEntities> {
    return this.repository.getListPos(params);
  }
}
