/* eslint-disable @typescript-eslint/camelcase */
import {
  ShipmentBookingDetail,
  ShipmentBookingEntities
} from "@/domain/entities/ShipmentBooking";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import { ShipmentBookingPresenter } from "../presenters/ShipmentBookingPresenter";
import {
  AddShipmentBooking,
  ShipmentInstantBooking
} from "@/data/payload/api/ShipmentBookingRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { BookingController } from "./BookingController";
import {
  ShipmentId,
  ShipmentIdList,
  ShipmentIdSTT
} from "@/domain/entities/Booking";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";
import {
  CLIENT_ERROR,
  INTERNAL_SERVER_ERROR,
  INTERNET_ERROR,
  SPECIAL_ERROR
} from "@/app/infrastructures/misc/Utils";
import { LocationController } from "./LocationController";
import { RequestListBooking } from "@/data/payload/api/BookingRequest";
import { ERROR_SPESIFICATION } from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { Discount } from "@/domain/entities/PromoConfiguration";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { AddBookingCorporate } from "@/data/payload/api/BookingRequest";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";

export interface ShipmentBookingState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  shipmentBookingData: ShipmentBookingEntities;
  shipmentBookingDetail: ShipmentBookingDetail;
  isError: boolean;
  isErrorCause: string;
  messageSuccess: string;
  showModalSuccess: boolean;
  showModalShipmentBooking: boolean;
  sttId: number;
  detailBookingShipment: ShipmentId;
  errorSpesificByRate: boolean;
  titleErrorspesification: string;
  isErrorEmbargo: boolean;
  discount: Discount;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "shipment_booking_stt"
})
class ShipmentBookingStore extends VuexModule implements ShipmentBookingState {
  public isLoading = false;
  public isLoadingDetail = false;
  public detailBookingShipment = new ShipmentId();

  public indexElement = 0;
  public selectedUniqueId = 0;
  public shipmentBookingData = new ShipmentBookingEntities(
    new Pagination(1, 20),
    []
  );
  public shipmentBookingDetail = new ShipmentBookingDetail();
  public search = "";
  public page = 1;
  public limit = 10;
  public advanceFilterData = {
    periodeStart: "",
    periodeEnd: "",
    originCode: "",
    destinationCode: "",
    clientPartnerId: "",
    status: "",
    product: [],
    COD: false,
    PAD: false,
    DFOD: false,
    asuransi: [],
    phoneReceiver: "",
    phoneSender: "",
    nestedStatus: [] as string[]
  };
  public isError = false;
  public isErrorCause = "";
  public firstRequest = true;
  public messageSuccess = "";
  public showModalSuccess = false;
  public showModalShipmentBooking = false;
  public sttId = 0;
  public destination = "";
  public errorSpesificByRate = false;
  public titleErrorspesification = "";
  public errorDestination = false;
  public isErrorEmbargo = false;
  public isVendorNinja = false;
  public discount = new Discount();
  public sttAssessmentStatus = "";

  @Action
  public getShipmentBookingList(params: RequestListBooking) {
    this.setLoading(true);
    const presenter = container.resolve(ShipmentBookingPresenter);
    presenter
      .getBookingList(params)
      .then((res: ShipmentBookingEntities) => {
        this.setShipmentBookingList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public getBookingListCBP(params: RequestListBooking) {
    const presenter = container.resolve(ShipmentBookingPresenter);
    return presenter.getBookingListCBP(params);
  }

  @Action
  public getManifestCBP(params: RequestListBooking) {
    const presenter = container.resolve(ShipmentBookingPresenter);
    return presenter.getManifestCBP(params);
  }

  @Action
  public getBookingDetail(params: { id: number; isAuth: boolean }) {
    this.setLoading(true);
    const presenter = container.resolve(ShipmentBookingPresenter);
    return presenter
      .getBookingDetail(params.id, true, params.isAuth)
      .then((res: ShipmentBookingDetail) => {
        this.setShipmentBookingDetail(res);
        this.setError(false);
        this.setErrorCause("");
        return res;
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
        return new ShipmentBookingDetail();
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public getEditBookingDetail(params: { id: number; isAuth: boolean }) {
    this.setLoadingDetail(true);
    const presenter = container.resolve(ShipmentBookingPresenter);
    return presenter
      .getBookingDetail(params.id, true, params.isAuth)
      .then(async (res: ShipmentBookingDetail) => {
        await LocationController.getDetailDistrictByDistrictCode({
          districtCode: res.sttDestinationDistrictId
        });
        const destinationDistrictDetail =
          LocationController.destinationDistrict;
        this.setShipmentBookingDetail({
          ...res,
          sttSenderPhone: res.sttSenderPhone.replace(/\+/g, ""),
          sttRecipientPhone: res.sttRecipientPhone.replace(/\+/g, ""),
          sttDestinationAddress:
            destinationDistrictDetail.id === 0 ? "" : destinationDistrictDetail
        });
        this.setVendorNinja(destinationDistrictDetail);
        BookingController.getExistSender({
          customer: res.sttSenderName,
          phone: res.sttSenderPhone,
          address: res.sttSenderAddress
        });

        BookingController.getExistReceiver({
          customer: res.sttRecipientName,
          phone: res.sttRecipientPhone,
          address: res.sttRecipientAddress
        });
        this.setError(false);
        this.setErrorCause("");
        this.setLoadingDetail(false);
      })
      .catch((error: any) => {
        this.setLoadingDetail(false);
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      });
  }

  @Action
  public async fetchShipmentBooking() {
    await this.setFirstRequest(false);
    this.getShipmentBookingList(
      new RequestListBooking({
        cache: true,
        search: this.search,
        page: this.shipmentBookingData.pagination.page,
        isTotalData: true,
        limit: this.shipmentBookingData.pagination.limit,
        bookedStart: this.advanceFilterData.periodeStart
          ? new Date(this.advanceFilterData.periodeStart)?.toLocaleDateString(
              "fr-CA"
            )
          : "",
        bookedEnd: this.advanceFilterData.periodeEnd
          ? new Date(this.advanceFilterData.periodeEnd).toLocaleDateString(
              "fr-CA"
            )
          : "",
        originDistrictId: this.advanceFilterData.originCode,
        destinationDistrictId: this.advanceFilterData.destinationCode,
        clientPartnerId: this.advanceFilterData.clientPartnerId,
        productType: this.advanceFilterData.product?.toString().toUpperCase(),
        status: this.advanceFilterData.nestedStatus
          ?.map(status => status.toUpperCase())
          .join(","),
        isCod: this.advanceFilterData.COD?.toString(),
        isPad: this.advanceFilterData.PAD ?? "",
        isDfod: this.advanceFilterData.DFOD,
        insuranceType: this.advanceFilterData.asuransi?.toString(),
        version: "2",
        paymentStatus: "",
        isPaidUnpaid: false,
        senderPhoneNumber: this.advanceFilterData.phoneSender,
        recipientPhoneNumber: this.advanceFilterData.phoneReceiver,
        sttAssessmentStatus: this.sttAssessmentStatus
      })
    );
  }

  @Action
  public async initShipmentBooking(senderPhone: string) {
    await this.setFirstPage();
    await this.setFirstRequest(true);
    await this.setAdvanceFilterData({
      periodeStart: "",
      periodeEnd: "",
      originCode: "",
      destinationCode: "",
      clientPartnerId: "",
      status: "",
      product: [],
      COD: false,
      PAD: false,
      DFOD: false,
      asuransi: [],
      phoneSender: senderPhone,
      nestedStatus: []
    });
    await this.setSearch("");
    await this.setSttAssessmentStatus("");
    this.getShipmentBookingList(
      new RequestListBooking({
        cache: true,
        search: this.search,
        page: this.shipmentBookingData.pagination.page,
        isTotalData: true,
        limit: this.shipmentBookingData.pagination.limit,
        bookedStart: this.advanceFilterData.periodeStart
          ? new Date(this.advanceFilterData.periodeStart).toLocaleDateString(
              "fr-CA"
            )
          : "",
        bookedEnd: this.advanceFilterData.periodeEnd
          ? new Date(this.advanceFilterData.periodeEnd).toLocaleDateString(
              "fr-CA"
            )
          : "",
        originDistrictCode: this.advanceFilterData.originCode,
        destinationDistrictCode: this.advanceFilterData.destinationCode,
        clientPartnerId: this.advanceFilterData.clientPartnerId,
        productType: this.advanceFilterData.product.toString().toUpperCase(),
        status: this.advanceFilterData.nestedStatus
          .map(status => status.toUpperCase())
          .join(","),
        isCod: this.advanceFilterData.COD.toString(),
        isPad: this.advanceFilterData.PAD ?? "",
        isDfod: this.advanceFilterData.DFOD,
        insuranceType: this.advanceFilterData.asuransi.toString(),
        version: "2",
        paymentStatus: "",
        isPaidUnpaid: false,
        senderPhoneNumber: this.advanceFilterData.phoneSender
      })
    );
  }

  @Action
  public async addShipmentBooking(params: {
    sttManual: string;
    referenceExternal: string;
    productName: string;
    originCity: string;
    originDistrict: string;
    destinationCity: string;
    destinationDistrict: string;
    phoneSender: string;
    phoneReceiver: string;
    sender: string;
    receiver: string;
    addressSender: string;
    addressReceiver: string;
    addressReceiverType: string;
    commodityCode: any;
    insurance: string;
    statusGoods: string;
    estimationPrice: any;
    taxNumber: string;
    isWoodpack: boolean;
    isCOD: boolean;
    isDFOD: boolean;
    codHandling: string;
    isSaveSender: boolean;
    isSaveReceiver: boolean;
    sttPieces: Array<any>;
    isDO: boolean;
    isMixpack: boolean;
    codAmount: number;
    codFee: number;
    shipmentPackageId: number;
    shipmentId: string;
    sttPiecePerPack: any;
    sttNextCommodity: string;
    discountFavoritePercentage: number;
    clientId?: number;
    otherShipperTicketCode?: any;
    isHidePopupErrorMessage?: boolean;
    isHidePopupSuccessMessage?: boolean;
    isHideLoading?: boolean;
    postalCodeDestination?: string;
    sttWeightAttachFiles: Array<string>;
    sttRecipientAddressType: string;
    goodsNames?: Array<string>;
    sttAttachFiles?: Array<string>;
    isFromBooking?: boolean;
  }) {
    this.setShowModalShipmentBooking(false);
    if (!params.isHideLoading) {
      MainAppController.showLoading();
    }
    MainAppController.closeErrorMessage();

    // kurir rekomendasi prefix TKP01
    if (params.referenceExternal.startsWith("TKP01")) {
      await this.bookingCourierRecommendation(params);
      return;
    }
    const presenter = container.resolve(ShipmentBookingPresenter);
    const payload = new AddShipmentBooking(
      params.sttManual,
      params.referenceExternal,
      params.productName,
      params.originCity,
      params.originDistrict,
      params.destinationCity,
      params.destinationDistrict,
      params.phoneSender,
      params.phoneReceiver,
      params.sender,
      params.receiver,
      params.addressSender,
      params.addressReceiver,
      params.commodityCode,
      params.insurance,
      params.statusGoods,
      params.estimationPrice,
      params.taxNumber,
      params.isWoodpack,
      params.isCOD,
      params.isDFOD,
      params.codHandling,
      params.isSaveSender,
      params.isSaveReceiver,
      params.sttPieces,
      params.isDO,
      params.isMixpack,
      params.codAmount,
      params.codFee,
      params.shipmentPackageId,
      params.shipmentId,
      params.sttPiecePerPack,
      params.sttNextCommodity,
      params.discountFavoritePercentage,
      BookingController.estimationPrice.dataElexys,
      params.postalCodeDestination,
      params.sttWeightAttachFiles,
      params.sttRecipientAddressType,
      params.goodsNames,
      params.sttAttachFiles
    );
    return presenter
      .addShipmentBooking(payload)
      .then((res: ResponsePayload) => {
        if (!params.isHidePopupSuccessMessage) {
          this.setMessageSuccess(
            `Pengiriman berhasil terbooking dengan STT "${res.sttNumber}"`
          );
          this.setShowModalSuccess(true);
        }
        this.setError(false);
        this.setErrorCause("");
        this.setDiscount({
          isDiscount: res.isDiscount,
          totalTariff: res.totalDiscount + res.totalAfterDiscount,
          totalAfterDiscount: res.totalAfterDiscount,
          totalBeforeDiscount: res.totalBeforeDiscount
        });
        if (
          (params.shipmentId.startsWith("AP") ||
            params.shipmentId.startsWith("AS")) &&
          !params.isFromBooking
        ) {
          const tempShipmentList = BookingController.temporaryShipmentIdList;
          tempShipmentList.unshift(
            new ShipmentId({
              shipmentChargeableWeight:
                payload.sttPieces[0].stt_piece_gross_weight,
              STT: new ShipmentIdSTT({
                shipmentAlgoId: params.shipmentId,
                sttId: res.sttId,
                sttNo: res.sttNumber,
                sttProductType: params.productName,
                sttTotalAmount: res.totalAfterDiscount,
                sttPieces: params.sttPieces,
                sttChargeableWeight: res.chargeableWeight
              })
            })
          );
          BookingController.setTemporaryShipmentIdList(tempShipmentList);
        } else {
          BookingController.setTemporaryShipmentIdList(
            BookingController.temporaryShipmentIdList.map(shipment => {
              if (shipment.uniqueId === this.selectedUniqueId) {
                return {
                  ...shipment,
                  STT: new ShipmentIdSTT({
                    shipmentAlgoId: params.shipmentId,
                    sttId: res.sttId,
                    sttNo: res.sttNumber,
                    sttProductType: params.productName,
                    sttTotalAmount: res.totalAfterDiscount,
                    sttPieces: params.sttPieces,
                    sttChargeableWeight: res.chargeableWeight
                  })
                };
              }
              return shipment;
            })
          );
        }
        return res;
      })
      .catch((err: any) => {
        if (!params.isHidePopupErrorMessage) {
          if (err.response) {
            if (err.response.status < 500) {
              const checkErrorSpesification = ERROR_SPESIFICATION(
                err.response.data.message.en
              );
              const dispatchError = () => {
                MainAppController.showErrorMessage(
                  new ErrorMessageEntities({
                    title: checkErrorSpesification.statusError
                      ? checkErrorSpesification.headerError
                      : "Booking Gagal !",
                    message: checkErrorSpesification.statusError
                      ? checkErrorSpesification.errorMsg
                      : err.response.data.message.id,
                    type: checkErrorSpesification.statusError
                      ? SPECIAL_ERROR
                      : CLIENT_ERROR,
                    onClose: () => {
                      dataLayer("shipment_booking_button_clicked", {}, [
                        "timestamp",
                        "userType",
                        "userId"
                      ]);
                      MainAppController.closeErrorMessage();
                    }
                  })
                );
              };
              const message = err.response?.data?.message?.en || "";
              const isTKPAlreadyBooked = message.includes(
                "external reference number is already in use"
              );
              if (this.showModalDetailBookingShipment) {
                BookingController.setGuardBookingSubmit(false);
                dispatchError();
                return;
              }
              if (!isTKPAlreadyBooked) {
                BookingController.setGuardBookingSubmit(false);
                this.setShowModalDetailBookingShipment(true);
                return;
              }
              dispatchError();
              this.setErrorDestination(
                checkErrorSpesification?.errorDestination || false
              );
              this.setErrorEmbargo(
                checkErrorSpesification?.isErrorEmbargo || false
              );
              BookingController.setGuardBookingSubmit(false);
              return false;
            }
            MainAppController.showErrorMessage(
              new ErrorMessageEntities({
                type: INTERNAL_SERVER_ERROR,
                onTryAgain: () => this.addShipmentBooking(params)
              })
            );
            BookingController.setGuardBookingSubmit(false);
            return false;
          }
          MainAppController.showErrorMessage(
            new ErrorMessageEntities({
              type: INTERNET_ERROR,
              onTryAgain: () => this.addShipmentBooking(params)
            })
          );
          BookingController.setGuardBookingSubmit(false);
          return false;
        }

        BookingController.setGuardBookingSubmit(false);
        return err?.response?.data;
      })
      .finally(() => {
        this.setShowModalShipmentBooking(false);
        if (!params.isHideLoading) {
          MainAppController.closeLoading();
        }
      });
  }

  @Action
  public async bookingCourierRecommendation(params: {
    sttManual: string;
    referenceExternal: string;
    productName: string;
    originCity: string;
    originDistrict: string;
    destinationCity: string;
    destinationDistrict: string;
    phoneSender: string;
    phoneReceiver: string;
    sender: string;
    receiver: string;
    addressSender: string;
    addressReceiver: string;
    addressReceiverType: string;
    commodityCode: string;
    insurance: string;
    statusGoods: string;
    estimationPrice: string;
    taxNumber: string;
    isWoodpack: boolean;
    isCOD: boolean;
    isSaveSender: boolean;
    isSaveReceiver: boolean;
    sttPieces: Array<any>;
    isDO: boolean;
    isMixpack: boolean;
    codAmount: number;
    codFee: number;
    shipmentPackageId: number;
    shipmentId: string;
    sttPiecePerPack: any;
    sttNextCommodity: string;
    clientId?: number;
    otherShipperTicketCode?: any[];
    postalCodeDestination?: any;
    goodsNames?: Array<string>;
    sttAttachFiles?: Array<string>;
    sttWeightAttachFiles?: Array<string>;
  }) {
    const originCity = FlagsMedusa.client_id_courrier_recommendation_config.getValue();
    const res = await BookingController.addBookingCorporate({
      payload: new AddBookingCorporate({
        sttClient: Number(originCity),
        sttNo: "",
        sttShipmentId: "",
        sttNoRefExternal: params.referenceExternal,
        sttTaxNumber: params.taxNumber,
        sttGoodsEstimatePrice: Number(params.estimationPrice),
        sttGoodsStatus: params.statusGoods,
        sttOriginCityId: params.originCity,
        sttOriginDistrictId: params.originDistrict,
        sttDestinationCityId: params.destinationCity,
        sttDestinationDistrictId: params.destinationDistrict,
        sttSenderName: params.sender,
        sttSenderAddress: params.addressSender,
        sttSenderPhone: params.phoneSender,
        sttRecipientName: params.receiver,
        sttRecipientAddress: params.addressReceiver,
        sttRecipientAddressType: params.addressReceiverType,
        sttRecipientPhone: params.phoneReceiver,
        sttInsuranceType: params.insurance,
        sttProductType: params.productName,
        sttCommodityCode: params.commodityCode,
        sttClientSttId: 0,
        sttVendorSttId: 0,
        sttIsCod: params.isCOD,
        sttIsWoodpacking: params.isWoodpack,
        sttPieces: params.sttPieces,
        sttPiecePerPack: params.sttPiecePerPack,
        sttNextCommodity: params.sttNextCommodity,
        sttCodAmount: params.codAmount,
        postalCodeDestination: params.postalCodeDestination,
        otherShipperTicketCode: params.otherShipperTicketCode,
        sttWeightAttachFiles: params.sttWeightAttachFiles,
        goodsNames: params.goodsNames,
        sttAttachFiles: params.sttAttachFiles
      }),
      onCloseError: () => {
        dataLayer("shipment_booking_button_clicked", {}, [
          "timestamp",
          "userType",
          "userId"
        ]);
        this.setShowModalDetailBookingShipment(true);
      }
    });
    BookingController.setShowModalSuccess(false);
    if (res) {
      BookingController.setTemporaryShipmentIdList(
        BookingController.temporaryShipmentIdList.map(shipment => {
          if (shipment.uniqueId === this.selectedUniqueId) {
            return {
              ...shipment,
              STT: new ShipmentIdSTT({
                sttId: BookingController.sttId,
                sttNo: BookingController.sttNumber,
                sttChargeableWeight: BookingController.chargeableWeight
              })
            };
          }
          return shipment;
        })
      );

      this.setMessageSuccess(BookingController.messageSuccess);
      this.setSTTID(BookingController.sttId);
      this.setShowModalSuccess(true);
      return true;
    }
    return false;
  }

  @Action
  public bookingAndPrintSavePdf(params: {
    sttManual: string;
    referenceExternal: string;
    productName: string;
    originCity: string;
    originDistrict: string;
    destinationCity: string;
    destinationDistrict: string;
    phoneSender: string;
    phoneReceiver: string;
    sender: string;
    receiver: string;
    addressSender: string;
    addressReceiver: string;
    addressReceiverType: string;
    commodityCode: string;
    insurance: string;
    statusGoods: string;
    estimationPrice: string;
    taxNumber: string;
    isWoodpack: boolean;
    isCOD: boolean;
    isDFOD: boolean;
    codHandling: string;
    isSaveSender: boolean;
    isSaveReceiver: boolean;
    sttPieces: Array<any>;
    isDO: boolean;
    isMixpack: boolean;
    codAmount: number;
    codFee: number;
    shipmentPackageId: number;
    shipmentId: string;
    sttPiecePerPack: any;
    sttNextCommodity: string;
    discountFavoritePercentage: number;
    otherShipperTicketCode?: any;
    postalCodeDestination?: string;
    sttWeightAttachFiles: Array<string>;
    sttRecipientAddressType: string;
    isFromBooking?: boolean;
  }) {
    this.setShowModalShipmentBooking(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();

    // kurir rekomendasi prefix TKP01
    if (params.referenceExternal.startsWith("TKP01")) {
      return this.bookingCourierRecommendation(params);
    }

    const presenter = container.resolve(ShipmentBookingPresenter);
    const payload = new AddShipmentBooking(
      params.sttManual,
      params.referenceExternal,
      params.productName,
      params.originCity,
      params.originDistrict,
      params.destinationCity,
      params.destinationDistrict,
      params.phoneSender,
      params.phoneReceiver,
      params.sender,
      params.receiver,
      params.addressSender,
      params.addressReceiver,
      params.commodityCode,
      params.insurance,
      params.statusGoods,
      params.estimationPrice,
      params.taxNumber,
      params.isWoodpack,
      params.isCOD,
      params.isDFOD,
      params.codHandling,
      params.isSaveSender,
      params.isSaveReceiver,
      params.sttPieces,
      params.isDO,
      params.isMixpack,
      params.codAmount,
      params.codFee,
      params.shipmentPackageId,
      params.shipmentId,
      params.sttPiecePerPack,
      params.sttNextCommodity,
      params.discountFavoritePercentage,
      BookingController.estimationPrice.dataElexys,
      params.postalCodeDestination,
      params.sttWeightAttachFiles,
      params.sttRecipientAddressType
    );
    return presenter
      .addShipmentBooking(payload)
      .then((res: ResponsePayload) => {
        this.setMessageSuccess(
          `Pengiriman berhasil terbooking dengan STT "${res.sttNumber}"`
        );
        this.setSTTID(res.sttId);
        this.setShowModalSuccess(true);
        this.setError(false);
        this.setErrorCause("");
        this.setDiscount({
          isDiscount: res.isDiscount,
          totalTariff: res.totalDiscount + res.totalAfterDiscount,
          totalAfterDiscount: res.totalAfterDiscount,
          totalBeforeDiscount: res.totalBeforeDiscount
        });
        if (
          (params.shipmentId.startsWith("AP") ||
            params.shipmentId.startsWith("AS")) &&
          !params.isFromBooking
        ) {
          const tempShipmentList = BookingController.temporaryShipmentIdList;
          tempShipmentList.unshift(
            new ShipmentId({
              shipmentChargeableWeight:
                payload.sttPieces[0].stt_piece_gross_weight,
              STT: new ShipmentIdSTT({
                shipmentAlgoId: params.shipmentId,
                sttId: res.sttId,
                sttNo: res.sttNumber,
                sttProductType: params.productName,
                sttTotalAmount: res.totalAfterDiscount,
                sttPieces: params.sttPieces,
                sttChargeableWeight: res.chargeableWeight
              })
            })
          );
          BookingController.setTemporaryShipmentIdList(tempShipmentList);
        } else {
          BookingController.setTemporaryShipmentIdList(
            BookingController.temporaryShipmentIdList.map(shipment => {
              if (shipment.uniqueId === this.selectedUniqueId) {
                return {
                  ...shipment,
                  STT: new ShipmentIdSTT({
                    shipmentAlgoId: params.shipmentId,
                    sttId: res.sttId,
                    sttNo: res.sttNumber,
                    sttProductType: params.productName,
                    sttTotalAmount: res.totalAfterDiscount,
                    sttPieces: params.sttPieces,
                    sttChargeableWeight: res.chargeableWeight
                  })
                };
              }
              return shipment;
            })
          );
        }
        return res;
      })
      .catch(async (err: any) => {
        if (err.response) {
          if (err.response.status < 500) {
            const checkErrorSpesification = ERROR_SPESIFICATION(
              err.response.data.message.en
            );
            const dispatchError = () => {
              MainAppController.showErrorMessage(
                new ErrorMessageEntities({
                  title: checkErrorSpesification.statusError
                    ? checkErrorSpesification.headerError
                    : "Booking Gagal !",
                  message: checkErrorSpesification.statusError
                    ? checkErrorSpesification.errorMsg
                    : err.response.data.message.id,
                  type: checkErrorSpesification.statusError
                    ? SPECIAL_ERROR
                    : CLIENT_ERROR,
                  onClose: () => {
                    dataLayer("shipment_booking_button_clicked", {}, [
                      "timestamp",
                      "userType",
                      "userId"
                    ]);
                    MainAppController.closeErrorMessage();
                  }
                })
              );
            };
            const message = err.response?.data?.message?.en || "";
            const isTKPAlreadyBooked = message.includes(
              "external reference number is already in use"
            );
            if (this.showModalDetailBookingShipment) {
              BookingController.setGuardBookingSubmit(false);
              dispatchError();
              return;
            }
            if (!isTKPAlreadyBooked) {
              BookingController.setGuardBookingSubmit(false);
              this.setShowModalDetailBookingShipment(true);
              return;
            }
            dispatchError();
            this.setErrorDestination(
              checkErrorSpesification?.errorDestination || false
            );
            this.setErrorEmbargo(
              checkErrorSpesification?.isErrorEmbargo || false
            );
            BookingController.setGuardBookingSubmit(false);
            return false;
          }
          MainAppController.showErrorMessage(
            new ErrorMessageEntities({
              type: INTERNAL_SERVER_ERROR,
              onTryAgain: () => this.bookingAndPrintSavePdf(params)
            })
          );
          BookingController.setGuardBookingSubmit(false);
          return false;
        }
        MainAppController.showErrorMessage(
          new ErrorMessageEntities({
            type: INTERNET_ERROR,
            onTryAgain: () => this.bookingAndPrintSavePdf(params)
          })
        );
        BookingController.setGuardBookingSubmit(false);
        return false;
      })
      .finally(() => {
        this.setShowModalShipmentBooking(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public resetDetail() {
    this.setShipmentBookingDetail(new ShipmentBookingDetail());
  }

  @Action
  public async setFirstPage() {
    this.shipmentBookingData.pagination.page = 1;
  }

  @Action
  public async searchAction(value: string) {
    await this.setSearch(value);
    await this.setFirstPage();
    await this.fetchShipmentBooking();
  }

  @Mutation
  public setErrorDestination(val: boolean) {
    this.errorDestination = val;
  }

  @Action
  public async clear() {
    await this.searchAction("");
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setLoadingDetail(isLoading: boolean) {
    this.isLoadingDetail = isLoading;
  }

  @Mutation
  private setShipmentBookingList(data: ShipmentBookingEntities) {
    this.shipmentBookingData = data;
  }

  @Mutation
  private setShipmentBookingDetail(data: ShipmentBookingDetail) {
    this.shipmentBookingDetail = data;
  }

  @Mutation
  private async setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  public async setAdvanceFilterData(value: any) {
    this.advanceFilterData = value;
  }

  @Mutation
  public setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  public setErrorCause(str: string) {
    this.isErrorCause = str;
  }

  @Mutation
  public async setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Mutation
  public async nextPage() {
    if (this.page >= 1) {
      this.page = Number(this.page) + 1;
    }
  }

  @Mutation
  public async prevPage() {
    this.page = Number(this.page) - 1;
  }

  @Mutation
  public async setPage(page: number) {
    this.page = page;
  }

  @Mutation
  private setMessageSuccess(key: string) {
    this.messageSuccess = key;
  }

  @Mutation
  public setShowModalSuccess(value: boolean) {
    this.showModalSuccess = value;
  }

  @Mutation
  public setShowModalShipmentBooking(value: boolean) {
    this.showModalShipmentBooking = value;
  }

  @Mutation
  public setSTTID(value: number) {
    this.sttId = value;
  }

  @Action
  public handleError() {
    this.setError(false);
  }

  // booking stt add new destination
  public bookingAddShipmentDestination = false;

  @Mutation
  public setBookingAddShipmentDestination(value: boolean) {
    this.bookingAddShipmentDestination = value;
  }

  @Mutation
  public setDetailBookingShipment(detail: ShipmentId) {
    this.detailBookingShipment = detail;
  }

  public showModalDetailBookingShipment = false;

  @Mutation
  public setShowModalDetailBookingShipment(value: boolean) {
    this.showModalDetailBookingShipment = value;
  }

  @Mutation
  public setIndexElement(index: number) {
    this.indexElement = index;
  }

  @Mutation
  public setSelectedUniqueId(uniqueId: number) {
    this.selectedUniqueId = uniqueId;
  }

  @Mutation
  public setNameSenderToEdit(value: string) {
    this.shipmentBookingDetail.sttSenderName = value;
  }

  @Mutation
  public setNameRecipientToEdit(value: string) {
    this.shipmentBookingDetail.sttRecipientName = value;
  }

  @Mutation
  public setSenderPhoneToEdit(value: string) {
    this.shipmentBookingDetail.sttSenderPhone = value;
  }

  @Mutation
  public setSenderPhoneCodeToEdit(value: string) {
    this.shipmentBookingDetail.sttSenderPhoneCode = value;
  }

  @Mutation
  public setSenderPhoneFlagToEdit(value: string) {
    this.shipmentBookingDetail.sttSenderPhoneFlag = value;
  }

  @Mutation
  public setReceiverPhoneToEdit(value: string) {
    this.shipmentBookingDetail.sttRecipientPhone = value;
  }

  @Mutation
  public setReceiverPhoneCodeToEdit(value: string) {
    this.shipmentBookingDetail.sttRecipientPhoneCode = value;
  }

  @Mutation
  public setReceiverPhoneFlagToEdit(value: string) {
    this.shipmentBookingDetail.sttRecipientPhoneFlag = value;
  }

  @Mutation
  public setErrorSpesificRate(status: boolean) {
    this.errorSpesificByRate = status;
  }

  @Mutation
  public setTitleErrorspesification(val: string) {
    this.titleErrorspesification = val;
  }

  @Mutation
  public setErrorEmbargo(status: boolean) {
    this.isErrorEmbargo = status;
  }

  @Action
  public checkErrorRoute(err: string) {
    this.setTitleErrorspesification("Harga Tidak Ditemukan !");
    return (
      err.includes("destination forward rate price") ||
      err.includes("origin forward rate price") ||
      err.includes("shipping surcharge and publish rate price")
    );
  }

  @Action
  public checkErrorEmbargo(err: string) {
    this.setTitleErrorspesification("Wilayah Embargo !");
    return err.includes("unacceptable due to an embargo");
  }

  @Mutation
  public setVendorNinja(destination: any) {
    this.isVendorNinja =
      (destination?.vendorCode?.toLowerCase() === "ninja" ||
        destination?.vendorCode?.toLowerCase() === "nx") &&
      (destination?.type?.toLowerCase() === "vendor" ||
        destination?.type?.toLowerCase() === "vendor_lanjutan");
  }

  @Action
  public async addInstantBooking(params: ShipmentInstantBooking) {
    const presenter = container.resolve(ShipmentBookingPresenter);
    return presenter.addInstantBooking(params);
  }

  @Mutation
  public setDiscount(discount: Discount) {
    this.discount = discount;
  }
  @Mutation
  public setSttPiecesToInitialValue() {
    const initialValue = JSON.parse(localStorage.getItem("list-kg") || "{}");
    this.shipmentBookingDetail.SttPieces = initialValue;
  }
  @Mutation
  public setWeightAttachFileInitialEdit() {
    this.shipmentBookingDetail.sttWeightAttachFiles = [];
  }

  @Action
  public async selectSttAssessmentStatusAction(value: string) {
    await this.setSttAssessmentStatus(value);
    await this.setFirstPage();
    await this.fetchShipmentBooking();
  }

  @Mutation
  private async setSttAssessmentStatus(value: string) {
    this.sttAssessmentStatus = value;
  }
}

export const ShipmentBookingController = getModule(ShipmentBookingStore);
