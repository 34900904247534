import ApiServiceAlgo from "@/app/infrastructures/services/ApiServiceAlgo";
import { LoginAlgoApiRequest } from "@/data/payload/api/AuthAlgoApiRequest";
import { LoginAlgoRequestInterface } from "@/data/payload/contracts/AuthAlgoRequest";
import { AuthAlgoEntities } from "@/domain/entities/AuthAlgo";
import { AuthAlgoMapper } from "@/data/persistences/mappers/AuthAlgoMapper";
import { AuthAlgoRepositoryInterface } from "@/data/persistences/repositories/contracts/AuthAlgoRepositoryInterface";
import { Endpoints } from "@/app/infrastructures/misc/Endpoints";

export class AuthAlgoApiRepository implements AuthAlgoRepositoryInterface {
  private service: ApiServiceAlgo;
  private mapper: AuthAlgoMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiServiceAlgo,
    mapper: AuthAlgoMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async loginAlgo(
    payload: LoginAlgoRequestInterface
  ): Promise<AuthAlgoEntities> {
    const resp = await this.service.invokeAlgo(
      "post",
      this.endpoints.loginAlgo(),
      undefined,
      payload as LoginAlgoApiRequest
    );
    return this.mapper.convertAuthDataFromApi(resp);
  }
}
