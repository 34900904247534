import { injectable } from "tsyringe";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ConfigFakeDexIndicationApiRepository } from "@/app/infrastructures/repositories/api/ConfigFakeDexIndicationApiRepository";
import { EditConfigFakeDexIndicationRequest } from "@/data/payload/api/ConfigFakeDexIndicationRequest";

@injectable()
export class ConfigFakeDexIndicationPresenter {
  private repository: ConfigFakeDexIndicationApiRepository;

  constructor(repository: ConfigFakeDexIndicationApiRepository) {
    this.repository = repository;
  }

  public getList(params: ApiRequestList): Promise<ResponsePayloadV2> {
    return this.repository.getList(params);
  }

  public getDetail(id: any): Promise<ResponsePayloadV2> {
    return this.repository.getDetail(id);
  }

  public editConfig(payload: EditConfigFakeDexIndicationRequest, id: any): Promise<ResponsePayloadV2> {
    return this.repository.editConfig(payload, id);
  }
}
