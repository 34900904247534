import { injectable } from "tsyringe";
import { LoginAlgoRequestInterface } from "@/data/payload/contracts/AuthAlgoRequest";
import { AuthAlgoApiRepository } from "@/app/infrastructures/repositories/api/AuthAlgoApiRepository";
import { AuthAlgoEntities } from "@/domain/entities/AuthAlgo";

@injectable()
export class AuthAlgoPresenter {
  private repository: AuthAlgoApiRepository;

  constructor(repository: AuthAlgoApiRepository) {
    this.repository = repository;
  }

  public loginAlgo(
    payload: LoginAlgoRequestInterface
  ): Promise<AuthAlgoEntities> {
    return this.repository.loginAlgo(payload);
  }
}
