/* eslint-disable @typescript-eslint/camelcase */
export class BulkEntities {
  message: string;
  bulkData: Array<BulkListData>;
  pagination: Pagination;
  constructor(
    message: string,
    bulkData: Array<BulkListData>,
    pagination: Pagination
  ) {
    this.message = message;
    this.bulkData = bulkData;
    this.pagination = pagination;
  }
}

export class BulkListData {
  id: number;
  activityName: string;
  activityType: string;
  status: string;
  createdBy: string;
  createdAt: string;
  archive_file: string;
  failed_file: string;
  dataProcessed: number;
  totalData: number;
  constructor(
    id: number,
    activityName: string,
    activityType: string,
    status: string,
    createdBy: string,
    createdAt: string,
    archive_file: string,
    failed_file: string,
    dataProcessed: number,
    totalData: number
  ) {
    this.id = id;
    this.activityName = activityName;
    this.activityType = activityType;
    this.status = status;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.archive_file = archive_file;
    this.failed_file = failed_file;
    this.dataProcessed = dataProcessed;
    this.totalData = totalData;
  }
}

export class Pagination {
  page: number;
  limit: number;
  constructor(page: number, limit: number) {
    this.page = page;
    this.limit = limit;
  }
}

export class BulkDetail {
  id: number;
  activity_name: string;
  archive_type: string;
  status: string;
  created_name: string;
  created_email: string;
  name_version: string;
  periode: string;
  archive_file: string;
  rate_active_date: string;
  archive_file_name: string;
  failed_file: string;
  failed_file_name: string;
  created_at: string;
  dataProcessed: number;
  totalData: number;
  clientName?: string;
  constructor(
    id: number,
    activity_name: string,
    archive_type: string,
    status: string,
    created_name: string,
    created_email: string,
    name_version: string,
    periode: string,
    archive_file: string,
    rate_active_date: string,
    archive_file_name: string,
    failed_file: string,
    failed_file_name: string,
    created_at: string,
    dataProcessed: number,
    totalData: number,
    clientName?: string
  ) {
    this.id = id;
    this.activity_name = activity_name;
    this.archive_type = archive_type;
    this.status = status;
    this.created_name = created_name;
    this.created_email = created_email;
    this.name_version = name_version;
    this.periode = periode;
    this.archive_file = archive_file;
    this.rate_active_date = rate_active_date;
    this.archive_file_name = archive_file_name;
    this.failed_file = failed_file;
    this.failed_file_name = failed_file_name;
    this.created_at = created_at;
    this.dataProcessed = dataProcessed;
    this.totalData = totalData;
    this.clientName = clientName;
  }
}

export class VersionNameData {
  rateVersionId: string;
  rateVersionName: string;
  rateVersionStartDate: string;
  rateVersionEndDate: string;
  rateVersionCreatedAt: string;
  constructor(
    rate_version_id: string,
    rate_version_name: string,
    rate_version_start_date: string,
    rate_version_end_date: string,
    rate_version_created_at: string
  ) {
    this.rateVersionId = rate_version_id;
    this.rateVersionName = rate_version_name;
    this.rateVersionStartDate = rate_version_start_date;
    this.rateVersionEndDate = rate_version_end_date;
    this.rateVersionCreatedAt = rate_version_created_at;
  }
}
