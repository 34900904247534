import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import {
  StiDestScEntities,
  StiDestScData,
  PrintDataList,
  StiDestDetail,
  DetailSttDestScInformation,
  DetailPieces,
  STTPiece
} from "@/domain/entities/StiDestSc";
import { Pagination } from "@/domain/entities/Pagination";

export class StiDestScMapper {
  public convertStiDestScDataFromApi(
    res: AxiosResponse<any>
  ): StiDestScEntities {
    const { data } = res;

    const stidestsc: StiDestScData[] = [];
    if (data.data.length === 0) {
      return new StiDestScEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        stidestsc.push(
          new StiDestScData(
            item.sti_dest_sc_id,
            item.sti_dest_sc_cargo_no,
            item.sti_dest_sc_cargo_type,
            item.sti_dest_sc_vehicle_no,
            item.sti_dest_sc_arrival_city_code,
            item.sti_dest_sc_arrival_city_name,
            item.sti_dest_sc_total_stt,
            item.sti_dest_sc_total_piece,
            item.sti_dest_sc_total_gross_weight,
            item.sti_dest_sc_total_volume_weight,
            item.sti_dest_sc_created_at,
            item.sti_dest_sc_created_name
          )
        );
      });
    }
    return new StiDestScEntities(
      new Pagination(data.meta.page, data.meta.limit),
      stidestsc
    );
  }

  public convertPrintDataListFromApi(result: AxiosResponse): PrintDataList {
    const {
      data: { data }
    } = result;

    return new PrintDataList(
      data.sti_dest_partner_id,
      data.sti_dest_partner_name,
      data.sti_dest_city_name,
      data.sti_dest_city_code,
      data.sti_dest_total_pieces,
      data.sti_dest_total_stt,
      data.sti_dest_total_gross_weight,
      data.sti_dest_total_volume_weight,
      data.sti_dest_created_at,
      data.sti_dest_cargo_type,
      data.sti_dest_cargo_no,
      data.stt.map(
        (item: any) =>
          new StiDestDetail(
            item.no,
            item.bag_no,
            item.stt_no,
            item.stt_piece_id,
            item.piece.map(
              (item: any) =>
                new STTPiece(
                  item.stt_piece_gross_weight,
                  item.stt_piece_id,
                  item.stt_piece_last_status_id,
                  item.stt_piece_no,
                  item.stt_piece_volume_weight
                )
            ),
            item.stt_product_type,
            item.stt_total_piece,
            item.stt_gross_weight,
            item.stt_volume_weight,
            item.stt_last_status_id,
            item.note,
            item.stt_commodity_name,
            item.stt_elexys_no,
            item.stt_origin_city_id,
            item.stt_origin_city_name,
            item.stt_destination_city_id,
            item.stt_destination_city_name
          )
      )
    );
  }

  public convertDetailSttInformationFromApi(
    res: AxiosResponse
  ): DetailSttDestScInformation[] {
    const {
      data: { data }
    } = res;
    const listOfDetailStt: Array<DetailSttDestScInformation> = [];
    if (data[0].stt !== null) {
      for (const index in data) {
        if (data[index].piece !== null) {
          listOfDetailStt.push(
            new DetailSttDestScInformation(
              data[index].is_allow_update_status,
              data[index].error_message,
              data[index].stt.stt_no,
              data[index].stt.bag_no,
              data[index].stt.cargo_no,
              data[index].stt.transit
                ? "transit"
                : data[index].stt.mis_route
                ? "mis-route"
                : "sti-dest",
              data[index].stt.piece.map((item: any) => {
                return new DetailPieces(
                  data[index].stt.stt_no,
                  `${data[index].stt.stt_no}-${item.stt_piece_no}`,
                  item.stt_piece_no,
                  `${data[index].stt.stt_destination_city_id} - ${data[index].stt.stt_destination_city_name}`,
                  data[index].stt.stt_product_type,
                  data[index].stt.stt_wood_packing === "Yes" ? "Ya" : "Tidak",
                  data[index].stt.stt_commodity_name,
                  data[index].stt.stt_cod_amount,
                  item.stt_piece_gross_weight,
                  item.stt_piece_volume_weight
                );
              }),
              data[index].stt.stt_product_type,
              data[index].stt.stt_commodity_name,
              data[index].stt.stt_total_piece,
              data[index].stt.stt_gross_weight,
              data[index].stt.stt_volume_weight,
              data[index].stt.stt_origin_city_id,
              data[index].stt.stt_destination_city_id,
              data[index].is_paid,
              data[index].stt.stt_elexys_no
            )
          );
        }
      }
      return listOfDetailStt;
    } else {
      return [
        new DetailSttDestScInformation(
          false,
          data[0].error_message,
          "",
          "",
          "",
          "",
          [],
          "",
          "",
          0,
          0,
          0,
          "",
          "",
          false
        )
      ];
    }
  }

  public convertGenerateDataFromApi(result: AxiosResponse): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: data.data
    });
  }
}
