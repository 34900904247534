const validatePhoneNumberAdvanced = (value: string) => {
  value = value.replace(/[^0-9 +-]/g, "");

  const formatPhoneNumber = new RegExp(
    /^(\+62|62|0)[\s-]?8[1-9]{1}\d{1}[\s-]?\d{2}[\s-]?\d{2,10}$/gm
  );

  return formatPhoneNumber.test(value);
};
export default validatePhoneNumberAdvanced;
