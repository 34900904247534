import { AxiosResponse } from "axios";
import {
  CheckInquiry,
  PaymentMethod,
  PaymentResponses
} from "@/domain/entities/Payment";

export class PaymentMapper {
  public convertCheckInquiryFromApi(res: AxiosResponse<any>): CheckInquiry {
    const {
      data: { data }
    } = res;
    return new CheckInquiry({
      transactionStatus: data.transaction_status,
      TransactionReason: data.transaction_reason
    });
  }

  public convertPostDataFromApi(result: AxiosResponse<any>): PaymentResponses {
    const { data } = result;
    return new PaymentResponses({
      qrImage: data.data.pc_qr_image,
      qrString: data.data.pc_qr_string,
      transactionId: data.data.pc_transaction_id,
      amount: data.data.pc_amount_before_discount,
      amountAfterDiscount: data.data.pc_amount,
      isPromo: data.data.pc_is_promo,
      transactionGenesisId: data.data.pc_transaction_genesis_id,
      expTime: data.data.pm_exp_time_seconds || 300
    });
  }

  public convertPaymentMethodDataFromApi(
    result: AxiosResponse
  ): PaymentMethod[] {
    const {
      data: { data }
    } = result;

    return data.map((pm: any) => {
      return new PaymentMethod({
        name: pm.pm_code,
        status: pm.pm_status,
        title: pm.pm_name,
        logo: `logo-${pm.pm_code}`,
        color: pm.pm_code.includes("linkaja") ? "#E82529" : "",
        maxAmount: pm.pm_nominal_transaction_limit,
        expTime: pm.pm_exp_time_seconds || 300
      });
    });
  }
}
