import { CourierManagementMapper } from "@/data/persistences/mappers/CourierManagementMapper";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { CourierManagementRepositoryInterface } from "@/data/persistences/repositories/contracts/CourierManagementRepositoryInterface";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import {
  ApiRequestCourierManagementCreate,
  ApiRequestCourierManagementList,
  ApiRequestCourierManagementListHistory,
  ApiRequestCourierVehicleList
} from "@/data/payload/api/CourierManagementApiRequest";
import {
  BankDetail,
  CourierManagementDetail,
} from "@/domain/entities/CourierManagement";
import { ClientPartnerOptions } from "@/domain/entities/CnManifest";
import { UpdateStatusCourierRequestInterface } from "@/data/payload/contracts/CourierManagementRequest";

export class CourierManagementApiRepository
  implements CourierManagementRepositoryInterface {
  private service: ApiService;
  private mapper: CourierManagementMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: CourierManagementMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getList(
    params: ApiRequestCourierManagementList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getCourierManagementList(params),
      {}
    );
    return this.mapper.convertListDataFromApi(resp);
  }

  public async getListApproval(
    params: ApiRequestCourierManagementList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getCourierManagementApprovalList(params),
      {}
    );
    return this.mapper.convertListApprovalDataFromApi(resp);
  }

  public async getListHistoryCourier(
    params: ApiRequestCourierManagementListHistory
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getCourierManagementApprovalListHistory(params, params.courierId),
      {}
    );
    return this.mapper.convertListApprovaHistorylDataFromApi(resp);
  }

  public async downloadFile(
    params: ApiRequestCourierManagementList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.downloadCourierManagementList(params),
      {}
    );
    return this.mapper.convertDownloadListDataFromApi(resp);
  }

  public async getDetail(id: number): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getCourierManagementDetail(id),
      {}
    );
    return this.mapper.convertDetailDataFromApi(resp);
  }

  public async createCourier(
    payload: ApiRequestCourierManagementCreate
  ): Promise<boolean> {
    await this.service.invoke(
      "POST",
      this.endpoints.createCourierManagement(),
      undefined,
      payload
    );
    return true;
  }
  public async editCourier(
    payload: ApiRequestCourierManagementCreate
  ): Promise<boolean> {
    await this.service.invoke(
      "PUT",
      this.endpoints.getCourierManagementDetail(payload.payload.courierId),
      undefined,
      payload
    );
    return true;
  }
  public async approveRejectCourier(
    payload: UpdateStatusCourierRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      "PATCH",
      this.endpoints.updateStatusCourierManagement(),
      undefined,
      payload
    );
    return true;
  }
  public async getListVehicle(
    params: ApiRequestCourierVehicleList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getCourierVehicleList(params)
    );

    return this.mapper.convertVehicleListFromApi(resp);
  };
}
