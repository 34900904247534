import { injectable } from "tsyringe";
import { UpdateDeliveryApiRepository } from "@/app/infrastructures/repositories/api/UpdateDeliveryApiRepository";
import {
  CreateUpdateDeliveryResponse,
  SttValidation,
  UpdateDelEntities,
  PrintData
} from "@/domain/entities/UpdateDelivery";
import { UpdateDeliveryRequest } from "@/data/payload/contracts/UpdateDeliveryRequest";

@injectable()
export class UpdateDeliveryPresenter {
  private repository: UpdateDeliveryApiRepository;

  constructor(repository: UpdateDeliveryApiRepository) {
    this.repository = repository;
  }

  public getUpdateDelList(
    page: number,
    limit: number,
    search: string,
    startDate: string,
    endDate: string
  ): Promise<UpdateDelEntities> {
    return this.repository.getUpdateDelList(
      page,
      limit,
      search,
      startDate,
      endDate
    );
  }
  public getSttValidationUpdateDelivery(sttNo: string): Promise<SttValidation> {
    return this.repository.getSttValidationUpdateDelivery(sttNo);
  }

  public createUpdateDelivery(
    payload: UpdateDeliveryRequest
  ): Promise<CreateUpdateDeliveryResponse> {
    return this.repository.createUpdateDelivery(payload);
  }

  public printDelivery(id: number): Promise<PrintData> {
    return this.repository.printDelivery(id);
  }
}
