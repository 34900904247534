export class Vehicles {
  data: Vehicle[];
  constructor(data: Vehicle[]) {
    this.data = data;
  }
}

export class Vehicle {
  vehicleId: number;
  vehicleLicensePlate: string;
  vehiclePosId: number;
  constructor(
    vehicleId: number,
    vehicleLicensePlate: string,
    vehiclePosId: number
  ) {
    this.vehicleId = vehicleId;
    this.vehicleLicensePlate = vehicleLicensePlate;
    this.vehiclePosId = vehiclePosId;
  }
}
