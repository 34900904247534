import {
  ElexysFailedBookingData,
  ElexysFailedBookingEntities,
  LogSttColumns,
  LogSttData
} from "@/domain/entities/IntegrationMonitoring";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class IntegrationMonitoringMapper {
  public convertElexysFailedBookingListFromApi(
    res: AxiosResponse<any>
  ): ElexysFailedBookingEntities {
    const { data } = res;

    return new ElexysFailedBookingEntities({
      pagination: new Pagination(data.meta.page, data.meta.limit),
      data: data.data.length
        ? data.data.map(
            (item: any) =>
              new ElexysFailedBookingData({
                id: item.id,
                suffix: item.suffix,
                sttElexysNumber: item.elexys_stt_no,
                elexysRequest: item.elexys_request,
                failedReasons: item.failed_reason,
                latestRetryStatus:
                  item.latest_retry_status.toLowerCase() === "onprogress"
                    ? "On Progress"
                    : item.latest_retry_status,
                firstFailedAt: item.first_failed_at,
                updatedAt: item.updated_at
              })
          )
        : []
    });
  }

  public convertLogSttDataFromApi(res: AxiosResponse<any>): LogSttData {
    const { data } = res;

    return new LogSttData(
      data.data.stt_no,
      data.data.partnerlog_hydra?.map(
        (item: any) =>
          new LogSttColumns(
            item.id,
            item.action,
            item.ref_id,
            item.request,
            item.response
          )
      ) || [],
      data.data.partnerlog_gober?.map(
        (item: any) =>
          new LogSttColumns(
            item.id,
            item.action,
            item.ref_id,
            item.request,
            item.response
          )
      ) || []
    );
  }

  public convertResponseBookingElexysFromApi(
    res: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
