import { ConfigBucketStorage } from "@/feature-flags/flags-config-bucket-storage";

const parsingLinkGcpToAws = (url: string) => {
  if (!url) return url;
  const regexMatchBucketGcp = /^https:\/\/storage\.googleapis\.com\//gm;
  if (!url.match(regexMatchBucketGcp)) return url;
  if (!ConfigBucketStorage.config_migrate_bucket.isEnabled()) return url;
  const urlSplit = url.split("/");
  const bucketName = urlSplit[3];
  if (bucketName.includes("algo")) return url;
  const urlLink = urlSplit.slice(4).join("/");
  return `https://${bucketName}.s3.ap-southeast-1.amazonaws.com/${urlLink}`;
};
export default parsingLinkGcpToAws;
