import { Pagination } from "./Pagination";
import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class BalanceTransactionEntities {
  pagination: Pagination;
  data: BalanceTransactionData[];
  constructor(pagination: Pagination, data: BalanceTransactionData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class BalanceTransactionData {
  pgTrxId = 0;
  pgTrxSttNo = "";
  pgTrxNo = "";
  pgTrxType = "";
  pgTrxActivity = "";
  pgTrxAmount = 0;
  pgTrxLastBalance = 0;
  pgTrxLastBalanceCod = 0;
  pgTrxActorId = 0;
  pgTrxActorType = "";
  pgTrxBeneficiaryName = "";
  pgTrxBeneficiaryAccount = "";
  pgTrxBeneficiaryBank = "";
  pgTrxSessionId = 0;
  pgTrxRefNo = "";
  pgTrxStatus = "";
  pgTrxVa = "";
  pgTrxCreatedAt = "";
  pgTrxNote = "";
  pgTrxVaPartnerName = "";

  constructor(fields?: Partial<BalanceTransactionData>) {
    Object.assign(this, fields);
  }
}

export class RequestListBalanceTransaction {
  page = 1;
  limit = 20;
  startDate = "";
  endDate = "";
  type = "";
  actorId = 0;
  actorType = "";
  isTotalData = false;
  apiVersion = "";

  constructor(fields?: Partial<RequestListBalanceTransaction>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class BalanceTransactionType {
  name = "";
  value = "";

  constructor(fields?: Partial<BalanceTransactionType>) {
    Object.assign(this, fields);
  }
}
export class TransactionTypeGober {
  balanceClient = [] as BalanceTransactionType[];
  balanceCodClient = [] as BalanceTransactionType[];
  balanceInternal = [] as BalanceTransactionType[];
  balancePos = [] as BalanceTransactionType[];

  constructor(fields?: Partial<TransactionTypeGober>) {
    Object.assign(this, fields);
  }
}
