import { Pagination, PaginationV2 } from "@/domain/entities/Pagination";

export class ShipmentBookingEntities {
  pagination: Pagination;
  data: ShipmentBooking[];
  constructor(pagination: Pagination, data: ShipmentBooking[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ShipmentBooking {
  sttId: number;
  sttNo: string;
  sttElexysNo?: string;
  sttShipmentId: string;
  sttRecipientAddress: string;
  sttCommodityName: string;
  sttClientId: number;
  sttPosId: number;
  sttIsCod: boolean;
  sttIsDfod: boolean;
  sttProductType: string;
  sttChargeAbleWeight: number;
  sttTotalPiece: number;
  sttLastStatusId: string;
  sttLastStatusIdDescription: string;
  sttWarningStatus: string;
  sttWarningStatusDescription: string;
  sttCounter: number;
  sttInsuranceType: string;
  sttOriginCity: string;
  sttDestinationCity: string;
  sttBookedName: string;
  sttBilledTo: string;
  sttCreatedName: string;
  sttCreatedAt: string;
  sttUpdatedAt: string;
  sttPaymentStatus?: string;
  sttPaymentDate?: string;
  sttOriginCityName?: string;
  sttDestinationCityName?: string;
  sttUpdateBy?: string;
  sttProcessState?: string;
  sttIsPad?: boolean;
  totalSttDex?: number;
  dexCodrejReason?: string;
  sttAssessmentStatus?: string;
  constructor(
    sttId: number,
    sttNo: string,
    sttElexysNo: string,
    sttShipmentId: string,
    sttRecipientAddress: string,
    sttCommodityName: string,
    sttClientId: number,
    sttPosId: number,
    sttIsCod: boolean,
    sttIsDfod: boolean,
    sttProductType: string,
    sttChargeAbleWeight: number,
    sttTotalPiece: number,
    sttLastStatusId: string,
    sttLastStatusIdDescription: string,
    sttWarningStatus: string,
    sttWarningStatusDescription: string,
    sttCounter: number,
    sttInsuranceType: string,
    sttOriginCity: string,
    sttDestinationCity: string,
    sttBookedName: string,
    sttBilledTo: string,
    sttCreatedName: string,
    sttCreatedAt: string,
    sttUpdatedAt: string,
    sttPaymentStatus?: string,
    sttPaymentDate?: string,
    sttOriginCityName?: string,
    sttDestinationCityName?: string,
    sttUpdateBy?: string,
    sttProcessState?: string,
    sttIsPad?: boolean,
    totalSttDex?: number,
    dexCodrejReason?: string,
    sttAssessmentStatus?: string
  ) {
    this.sttId = sttId;
    this.sttNo = sttNo;
    this.sttElexysNo = sttElexysNo;
    this.sttShipmentId = sttShipmentId;
    this.sttRecipientAddress = sttRecipientAddress;
    this.sttCommodityName = sttCommodityName;
    this.sttClientId = sttClientId;
    this.sttPosId = sttPosId;
    this.sttIsCod = sttIsCod;
    this.sttIsDfod = sttIsDfod;
    this.sttIsPad = sttIsPad;
    this.sttProductType = sttProductType;
    this.sttChargeAbleWeight = sttChargeAbleWeight;
    this.sttTotalPiece = sttTotalPiece;
    this.sttLastStatusId = sttLastStatusId;
    this.sttLastStatusIdDescription = sttLastStatusIdDescription;
    this.sttWarningStatus = sttWarningStatus;
    this.sttWarningStatusDescription = sttWarningStatusDescription;
    this.sttCounter = sttCounter;
    this.sttInsuranceType = sttInsuranceType;
    this.sttOriginCity = sttOriginCity;
    this.sttDestinationCity = sttDestinationCity;
    this.sttBookedName = sttBookedName;
    this.sttBilledTo = sttBilledTo;
    this.sttCreatedName = sttCreatedName;
    this.sttCreatedAt = sttCreatedAt;
    this.sttUpdatedAt = sttUpdatedAt;
    this.sttPaymentStatus = sttPaymentStatus;
    this.sttPaymentDate = sttPaymentDate;
    this.sttOriginCityName = sttOriginCityName;
    this.sttDestinationCityName = sttDestinationCityName;
    this.sttUpdateBy = sttUpdateBy;
    this.sttProcessState = sttProcessState;
    this.totalSttDex = totalSttDex;
    this.dexCodrejReason = dexCodrejReason;
    this.sttAssessmentStatus = sttAssessmentStatus;
  }
}

export class CBPPickup {
  sttId = 0;
  sttNo = "";
  sttBilledTo = "";
  sttShipmentId = "";
  sttRecipientAddress = "";
  sttOriginCity = "";
  sttDestinationCity = "";
  sttLastStatusId = "";
  sttLastStatusIdDescription = "";
  sttCreatedName = "";
  sttCreatedAt = "";
  sttUpdatedAt = "";
  sttClientId = 0;
  sttGrossWeight = 0;
  sttChargeAbleWeight = 0;
  sttProductType = "";
  sttCommodityCode = "";
  sttCommodityName = "";
  sttTotalPiece = 0;
  pmcStatus = "";
  sttPosId = 0;
  sttIsCod = false;
  sttIsDfod = false;
  sttIsPad = false;
  sttWarningStatus = "";
  sttWarningStatusDescription = "";
  sttCounter = 0;
  sttInsuranceType = "";
  sttBookedName = "";
  constructor(fields?: Partial<CBPPickup>) {
    Object.assign(this, fields);
  }
}

export class CBPPickupManifest {
  posName = "";
  posOrigin = "";
  pickupDate = "";
  clientName = "";
  clientOrigin = "";
  totalSTT = 0;
  totalPieces = 0;
  totalGrossWeight = 0;
  data: CBPPickup[] = [new CBPPickup()];
  constructor(fields?: Partial<CBPPickupManifest>) {
    Object.assign(this, fields);
  }
}
export class CBPPickupListEntities {
  pagination = new PaginationV2();
  data: CBPPickup[] = [new CBPPickup()];
  constructor(fields?: Partial<CBPPickupListEntities>) {
    Object.assign(this, fields);
  }
}
export class SttPieces {
  pieceId: number;
  grossWeight: number;
  dimension: any;
  volumeWeight: number;
  convertVolumeWeight: string;
  isDimensionDisabled: boolean;
  errorHws: boolean;
  constructor(
    pieceId: number,
    grossWeight: number,
    dimension: any,
    volumeWeight: number,
    convertVolumeWeight: string,
    isDimensionDisabled: boolean,
    errorHws: boolean
  ) {
    this.pieceId = pieceId;
    this.grossWeight = grossWeight;
    this.dimension = dimension;
    this.volumeWeight = volumeWeight;
    this.convertVolumeWeight = convertVolumeWeight;
    this.isDimensionDisabled = isDimensionDisabled;
    this.errorHws = errorHws;
  }
}

export class SttReasonMapping {
  reasonCode = "";
  reasonCreatedAt = "";
  reasonDescription = "";
  reasonExternalCode = "";
  reasonId = 0;
  reasonTitle = "";
  reasonUpdatedAt = "";

  constructor(fields?: Partial<SttReasonMapping>) {
    Object.assign(this, fields);
  }
}

export class SttClient {
  clientId = 0;
  clientCode = "";
  clientName = "";
  clientPhone = "";
  clientEmail = "";
  clientTaxNumber = "";
  clientStatus = "";
  clientAddress = "";
  clientPartnerId = 0;
  clientIsCod = false;
  clientIsMixpack = false;
  clientIsDo = false;
  clientIsBanned = false;
  clientCodConfigAmount = "";

  constructor(fields?: Partial<SttClient>) {
    Object.assign(this, fields);
  }
}

export class ShipmentBookingDetail {
  sttId = 0;
  sttNo = "";
  sttElexysNo? = "";
  sttShipmentId = "";
  sttOriginAddress = "";
  sttDestinationAddress: any;
  sttNoRefExternal = "";
  sttSenderName = "";
  sttSenderPhone = "";
  sttSenderPhoneCode = "";
  sttSenderPhoneFlag = "";
  sttSenderAddress = "";
  sttRecipientName = "";
  sttRecipientPhone = "";
  sttRecipientPhoneCode = "";
  sttRecipientPhoneFlag = "";
  sttRecipientAddress = "";
  sttRecipientAddressType = "";
  sttCommodityName = "";
  sttProductTypeName = "";
  sttTotalPiece = 0;
  sttTotalChargeAbleWeight = "";
  sttGoodsEstimatePrice = "";
  sttGoodsEstimatePriceForDetail = 0;
  sttTaxNumber = "";
  sttInsuranceName = "";
  sttInsuranceNameDefault = "";
  sttBookedBy = "";
  sttBilledTo = "";
  sttSource = "";
  sttCod = "";
  sttReturnCod = "";
  sttShipmentPrice = "";
  sttShippingSurchargeRate = "";
  sttDocumentSurchargeRate = "";
  sttCommoditySurchargeRate = "";
  sttHeavyWeightSurchargeRate = "";
  sttInsuranceRate = "";
  sttWoodPackingRate = "";
  sttTaxRate = "";
  sttCityRate = "";
  sttForwardRate = "";
  sttTotalTariff = "";
  sttLastStatus = "";
  sttLastStatusCounter = 0;
  sttLastStatusDescription = "";
  sttWarningStatus = "";
  sttWarningStatusDescription = "";
  sttCreatedAt = "";
  sttCreatedBy = "";
  sttUpdatedAt = "";
  sttUpdatedBy = "";
  SttPiecesInitial: SttPieces[] = [];
  SttPieces: SttPieces[] = [];
  sttSenderStatus = "";
  sttWoodpackingRate = 0;
  sttColumnDisabled = false;
  sttIsWoodPacking = false;
  sttIsWoodPackingKeeped = false;
  sttIsCod = false;
  sttIsDfod = false;
  codHandling = "";
  sttIsPad = false;
  sttCodType: string[] = [];
  sttOriginCityId = "";
  sttOriginDistrictId = "";
  sttDestinationCityId = "";
  sttDestinationDistrictId = "";
  sttCommodityCode = "";
  sttCommodityId = 0;
  sttIsAllowEdit = false;
  sttIsAllowCancel = false;
  sttClient: SttClient = new SttClient();
  sttPiecePerPack: any;
  sttNextCommodity = "";
  sttCodAmount = 0;
  sttCodAmountForDetail = 0;
  sttCodFee = 0;
  sttPadFee = 0;
  sttBookedById = 0;
  sttBookedByType = "";
  sttBookedByCode = "";
  sttBookedForId = 0;
  sttBookedForType = "";
  sttBookedForCode = "";
  sttShipmentSource = "";
  sttOriginCityName = "";
  sttOriginCityTransit = "";
  sttDestinationCityTransit = "";
  sttDestinationDistrictName = "";
  sttDestinationCityName = "";
  sttDistrictUrsaCode = "";
  sttDistrictType = "";
  sttDestinationDistrictType = "";
  sttInformationDistrictType = "";
  sttIsDo = false;
  sttTotalAmount = 0;
  sttBmTaxRate = "";
  sttPpnTaxRate = "";
  sttPphTaxRate = "";
  sttPublishRate = 0;
  sttOriginDistrictRate = 0;
  sttDestinationDistrictRate = 0;
  sttGrossWeight = 0;
  sttVolumeWeight = 0;
  sttChargeableWeight = 0;
  estimateSla = "";
  sttTotalSurcharge = 0;
  sttAmount = 0;
  sttPieces: Array<any> = [];
  sttVendorCode = "";
  sttVendorName = "";
  sttVendorRefferenceNo = "";
  otherShipperTicketCode: Array<any> = [];
  sttBookedForName = "";
  totalTariff = 0;
  totalRate = 0;
  sttReasonMapping: SttReasonMapping = new SttReasonMapping();
  isDangerousGoods = false;
  postalCodeDestination = "";
  checkoutPaymentMethod = "";
  lastStatusSttReturn = "";
  sttIsPromo = false;
  sttTotalDiscount = 0;
  sttTotalTariffAfterDiscount = 0;
  sttReturnFee = 0;
  isReverseJourney = false;
  sttReverseJourneySttNo = "";
  sttReverseJourneyShipmentId = "";
  sttReverseJourneyCodHandling = false;
  sttAttachFilesInitial: string[] = [];
  sttCommodityDetailInitial = "";
  sttWeightAttachFiles: string[] = [];
  sttAttachFiles: string[] = [];
  sttCommodityDetail = "";
  sttPiecesGrossWeight: number[] = [];
  sttPiecesVolumeWeight: number[] = [];
  listDiscount: any = [];
  defaultDimensions: DefaultDimensions[] = [];
  discountFavoritePercentage = 0;
  sttBookedByCountry = "";
  refundRemarks = "";
  sttRecipientEmail = "";
  sttKtpImage = "";
  sttTaxImage = "";
  goodsNames: any = [];
  constructor(fields?: Partial<ShipmentBookingDetail>) {
    Object.assign(this, fields);
  }
}

export class DefaultDimensions {
  lengthOfDimension = 0;
  widthOfDimension = 0;
  heightOfDimension = 0;

  constructor(fields?: Partial<DefaultDimensions>) {
    Object.assign(this, fields);
  }
}

export class SttManualValidation {
  isAllowBooking: boolean;
  errorMessage: string;
  constructor(isAllowBooking: boolean, errorMessage: string) {
    this.isAllowBooking = isAllowBooking;
    this.errorMessage = errorMessage;
  }
}

export class ShipmentPrefix {
  prefix = "";
  name = "";
  constructor(fields?: Partial<ShipmentPrefix>) {
    Object.assign(this, fields);
  }
}

export class RequestEstimationPrice {
  originId = "";
  destinationId = "";
  commodityId = 0;
  productType = "";
  accountType = "";
  accountRefId = 0;
  goodsPrice = 0;
  insuranceType = "";
  isWoodpacking = false;
  isHaveTaxId = false;
  pieces: Array<any> = [];
  isCod = false;
  codAmount = 0;
  constructor(fields?: Partial<RequestEstimationPrice>) {
    Object.assign(this, fields);
  }
}
