import { BALANCE_MINUS_PENALTY } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const Detail = () =>
  import(
    /* webpackChunkName: "BalanceMinus" */ "@/app/ui/views/finance/balance-minus-penalty/form.vue"
  );
const Edit = () =>
  import(
    /* webpackChunkName: "BalanceMinus" */ "@/app/ui/views/finance/balance-minus-penalty/form.vue"
  );

export default [
  {
    path: "/finance/balance-minus-penalty",
    component: Layout,
    name: "detail-balance",
    meta: {
      hidden: true,
      parent: "Denda Saldo Minus"
    },
    children: [
      {
        path: ":id",
        name: "detail-balance-minus-penalty",
        component: Detail,
        meta: {
          title: "Denda Saldo Minus | Detail",
          header: true,
          permission: BALANCE_MINUS_PENALTY.DETAIL
        }
      }
    ]
  },
  {
    path: "/finance/balance-minus-penalty",
    component: Layout,
    name: "edit-balance",
    meta: {
      hidden: true,
      parent: "Denda Saldo Minus"
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-balance-minus-penalty",
        component: Edit,
        meta: {
          header: true,
          title: "Denda Saldo Minus | Edit",
          name: "Edit",
          before: "/finance/balance-minus-penalty/:id",
          permission: BALANCE_MINUS_PENALTY.EDIT
        }
      }
    ]
  }
];
