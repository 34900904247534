import { ReadyToCargoApiRepository } from "@/app/infrastructures/repositories/api/ReadyToCargoRepository";
import { ScanReadyToCargoInterface, UpdateReadyToCargoInterface } from "@/data/payload/contracts/ReadyToCargoRequest";
import {
  LogReadyToCargoListEntities,
  ReadyToCargo,
  ReadyToCargoListEntities,
  RequestLogReadyToCargoList,
  RequestReadyToCargoList,
  ResponseScanReadyToCargo,
  ResponseUpdateReadyToCargo,
} from "@/domain/entities/ReadyToCargo";
import { injectable } from "tsyringe";

@injectable()
export class ReadyToCargoPresenter {
  private repository: ReadyToCargoApiRepository;

  constructor(repository: ReadyToCargoApiRepository) {
    this.repository = repository;
  }

  public getReadyToCargoList(
    params: RequestReadyToCargoList
  ): Promise<ReadyToCargoListEntities> {
    return this.repository.getReadyToCargoList(params);
  }

  public getLogReadyToCargoList(
    params: RequestLogReadyToCargoList
  ): Promise<LogReadyToCargoListEntities> {
    return this.repository.getLogReadyTocargoList(params);
  }

  public getDetailReadyToCargo(id: number): Promise<ReadyToCargo> {
    return this.repository.getDetailReadyToCargo(id);
  }

  public scanReadyToCargo(
    payload: ScanReadyToCargoInterface
  ): Promise<ResponseScanReadyToCargo> {
    return this.repository.scanReadyToCargo(payload);
  }

  public updateReadyToCargo(
    payload: UpdateReadyToCargoInterface
  ): Promise<ResponseUpdateReadyToCargo> {
    return this.repository.updateReadyToCargo(payload);
  }
}
