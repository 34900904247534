import {
  ScanReadyToCargoInterface,
  UpdateReadyToCargoInterface
} from "@/data/payload/contracts/ReadyToCargoRequest";
import { ReadyToCargoMapper } from "@/data/persistences/mappers/ReadyToCargoMapper";
import { ReadyToCargoRepositoryInterface } from "@/data/persistences/repositories/contracts/ReadyToCargoRepositoryInterface";
import {
  RequestReadyToCargoList,
  ReadyToCargoListEntities,
  ReadyToCargo,
  ScanReadyToCargoRequest,
  ResponseScanReadyToCargo,
  ResponseUpdateReadyToCargo,
  UpdateReadyToCargoRequest,
  LogReadyToCargoListEntities,
  RequestLogReadyToCargoList
} from "@/domain/entities/ReadyToCargo";
import { ReadyToCargoEndpoint } from "../../endpoints/hydra/ReadyToCargoEndpoint";
import ApiService from "../../services/ApiService";

export class ReadyToCargoApiRepository
  implements ReadyToCargoRepositoryInterface {
  private service: ApiService;
  private mapper: ReadyToCargoMapper;
  private endpoints: ReadyToCargoEndpoint;

  constructor(
    service: ApiService,
    mapper: ReadyToCargoMapper,
    endpoints: ReadyToCargoEndpoint
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  public async getLogReadyTocargoList(params: RequestLogReadyToCargoList): Promise<LogReadyToCargoListEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getLogReadyToCargoList(params),
      {}
    );
    return this.mapper.convertListLogRTCDataFromApi(resp);
  }
  public async getDetailReadyToCargo(id: number): Promise<ReadyToCargo> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailReadyToCargo(id),
      {}
    );
    return this.mapper.convertDetailDataFromApi(resp);
  }
  public async getReadyToCargoList(
    params: RequestReadyToCargoList
  ): Promise<ReadyToCargoListEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getReadyToCargoList(params),
      {}
    );
    return this.mapper.convertListDataFromApi(resp);
  }

  public async scanReadyToCargo(
    payload: ScanReadyToCargoInterface
  ): Promise<ResponseScanReadyToCargo> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.scanReadyToCargo(),
      undefined,
      payload as ScanReadyToCargoRequest
    );
    return this.mapper.convertScanDataFromApi(resp);
  }

  public async updateReadyToCargo(
    payload: UpdateReadyToCargoInterface
  ): Promise<ResponseUpdateReadyToCargo> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.updateReadyToCargo(),
      undefined,
      payload as UpdateReadyToCargoRequest
    );
    return this.mapper.convertUpdateDataFromApi(resp);
  }
}
