import { injectable } from "tsyringe";

import { CommoditySurchargeApiRepository } from "@/app/infrastructures/repositories/api/CommoditySurchargeApiRepository";
import {
  CommoditySurcharge,
  CommoditySurchargeList
} from "@/domain/entities/CommoditySurcharge";
import {
  ChangeCommoditySurchargeRequestInterface,
  EditCommoditySurchargeRequestInterface
} from "@/data/payload/contracts/CommoditySurchargeRequest";
import { ResponsePayload, ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import {
  AddCommoditySurchargeApiRequest,
  EditCommoditySurchargeApiRequest
} from "@/data/payload/api/CommoditySurchargeApiRequest";
@injectable()
export class CommoditySurchargePresenter {
  private repository: CommoditySurchargeApiRepository;

  constructor(repo: CommoditySurchargeApiRepository) {
    this.repository = repo;
  }

  public async getCommoditySurchargeList(
    search: string,
    status: string,
    page: number,
    limit: number
  ): Promise<CommoditySurchargeList> {
    return await this.repository.getCommoditySurchargeList(
      search,
      status,
      page,
      limit
    );
  }

  // public async getDetailCommodity(id: number): Promise<CommoditySurcharge> {
  public async getDetailCommoditySurcharge(
    id: number
  ): Promise<CommoditySurcharge> {
    return await this.repository.getDetailCommoditySurcharge(id);
  }

  public async editCommoditySurcharge(
    payload: EditCommoditySurchargeRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editCommoditySurcharge(payload);
  }

  public formSurcharge(params: {
    method: any,
    payload: AddCommoditySurchargeApiRequest
  }): Promise<ResponsePayloadV2> {
    return this.repository.formSurcharge(params);
  }
}
