import { Pagination } from "./Pagination";

export class ClientPartnerOptions {
  id = 0;
  name = "";
  code = "";
  type = "";
  contractStatus = "";
  partnerExternalCode = "";
  cityCode = "";
  cityName = "";
  districtCode = "";
  partnerIsCodDelivery = false;
  constructor(fields?: Partial<ClientPartnerOptions>) {
    Object.assign(this, fields);
  }
}

export class DestinationCityOptions {
  id: number;
  name: string;
  code: string;
  constructor(id: number, name: string, code: string) {
    this.id = id;
    this.name = name;
    this.code = code;
  }
}

export class SttNumbersOptions {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class CnManifestEntities {
  pagination: Pagination;
  data: CnManifestData[];
  constructor(pagination: Pagination, data: CnManifestData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class CnManifestData {
  sttId = 0;
  sttNo = "";
  sttTotalPiece = "";
  sttDestinationCity = "";
  sttChargeAbleWeight = 0;
  sttProductType = "";
  sttVolumeWeight = 0;
  sttRecipientName = "";
  sttRecipientAddress = "";
  sttRecipientPhone = "";
  sttCommodityName = "";
  sttGoodsEstimatePrice = 0;
  sttHsCode = "";
  sttTaxNumber = "";
  sttTaxStamp = 0;
  sttValueAddedTax = 0;
  sttIncomeTax = 0;
  sttTotalGoodTax = 0;
  sttTaxImposition = "";
  sttLastStatus = "";
  sttLastStatusDescription = "";
  sttBookedBy = "";
  sttCreatedAt = "";
  sttUpdatedAt = "";
  sttTotalAmount = 0;
  sttBookedByCountry = "";

  constructor(fields?: Partial<CnManifestData>) {
    Object.assign(this, fields);
  }
}
