import { AccountController } from "@/app/ui/controllers/AccountController";

export const cargoRect = (product: string) => {
  const locale = AccountController.accountData.countryCode === "my";
  let rect = 0;
  switch (product) {
    case "ship":
      rect = locale ? 21 : 25;
      break;
    case "truck":
      rect = 25;
      break;
    case "train":
      rect = locale ? 24 : 27;
      break;
    case "plane":
      rect = 29;
      break;
    default:
      break;
  }
  return rect;
};

export const productRect = (product: string) => {
  let rect = 0;
  switch (product) {
    case "REGULAR":
      rect = 18;
      break;
    case "EXPRESS":
      rect = 18;
      break;
    default:
      break;
  }
  return rect;
};

export const cargoTypeData = (type: string) => {
  let styles = {
    label: "",
    icon: ""
  };

  switch (type) {
    case "truck":
      styles = {
        label: "Truk",
        icon: "cargo-truck"
      };
      break;
    case "train":
      styles = {
        label: "Kereta",
        icon: "cargo-train"
      };
      break;
    case "plane":
      styles = {
        label: "Pesawat",
        icon: "cargo-plane"
      };
      break;
    case "ship":
      styles = {
        label: "Kapal",
        icon: "ship"
      };
      break;

    default:
      break;
  }
  return styles;
};

export const layoutTransitPlaneLabel = (transitLength: number) => {
  const topLineHeight: any = {
    0: 78,
    1: 78,
    2: 76,
    3: 73,
    4: 70
  }

  const topTransitHeight: any = {
    0: 82,
    1: 82,
    2: 79,
    3: 76,
    4: 73
  }

  const fontTransitList: any = {
    0: 12,
    1: 12,
    2: 10,
    3: 10,
    4: 8
  }

  const topCargoNumber: any = {
    0: 75,
    1: 75,
    2: 73,
    3: 72,
    4: 68.5
  }

  const fontCargoNumber: any = {
    0: 32,
    1: 32,
    2: 32,
    3: 32,
    4: 20
  }
  
  return {
    topLineHeight: topLineHeight[transitLength],
    fontTransitList: fontTransitList[transitLength],
    topTransitHeight: topTransitHeight[transitLength],
    topCargoNumber: topCargoNumber[transitLength],
    fontCargoNumber: fontCargoNumber[transitLength]
  }
}
