const whatsapp = (phoneNumber: string, predefineMessage = "") => {
  const phoneNumberWhatsappFormat = phoneNumber.replace(/^(\+62|0)/g, "62");
  window.open(
    `https://wa.me/${phoneNumberWhatsappFormat}?text=${encodeURIComponent(
      predefineMessage
    )}`,
    "_blank"
  );
};
export default whatsapp;
