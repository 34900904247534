import { DeliveryApiRequest } from "@/data/payload/api/DeliveryApiRequest";
import { UpdateDeliveryInterface } from "@/data/payload/contracts/DeliveryRequest";
import { DeliveryManifestMapper } from "@/data/persistences/mappers/DeliveryManifestMapper";
import { DeliveryManifestRepositoryInterface } from "@/data/persistences/repositories/contracts/DeliveryManifestRepositoryInterface";
import {
  DeliveryManifestEntities,
  DeliveryManifestPdfData,
  DetailSttDeliveryManifest
} from "@/domain/entities/DeliveryManifest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class DeliveryManifestApiRepository
  implements DeliveryManifestRepositoryInterface {
  private service: ApiService;
  private mapper: DeliveryManifestMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: DeliveryManifestMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  public async updateDelivery(
    payload: UpdateDeliveryInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.updateDeliveryManifest(),
      {},
      payload as DeliveryApiRequest
    );
    return this.mapper.convertResponseUpdateDelivery(resp);
  }

  public async getDeliveryManifest(
    page: number,
    limit: number,
    startDate: string,
    endDate: string,
    search: string
  ): Promise<DeliveryManifestEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDeliveryManifest(
        page,
        limit,
        startDate,
        endDate,
        search
      ),
      {}
    );
    return this.mapper.convertDeliveryManifestDataFromApi(resp);
  }
  public async getDetailDeliveryStt(
    sttOrBagNumber: string
  ): Promise<DetailSttDeliveryManifest[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailDeliveryStt(sttOrBagNumber),
      {}
    );
    return this.mapper.convertDetailSttInformationFromApi(resp);
  }

  public async getDeliveryManifestPdfData(
    id: number
  ): Promise<DeliveryManifestPdfData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDeliveryManifestPdfData(id),
      {}
    );
    return this.mapper.convertDeliveryManifestPdfDataFromApi(resp);
  }
}
