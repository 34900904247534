/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionOutgoingShipmentClearance = {
  permission_clearance_enable: new Rox.Flag(false),
  permission_clearance_detail_view: new Rox.Flag(false),
};

export function initFlagsPermissionOutgoingShipmentClearance() {
  // register flags with namespaces for better grouping
  Rox.register(
    "featureOutgoingShipmentClearance",
    FlagsPermissionOutgoingShipmentClearance
  );
}
