import { PosClientMapper } from "@/data/persistences/mappers/PosClientMapper";
import { PosClientRepositoryInterface } from "@/data/persistences/repositories/contracts/PosClientRepositoryInteface";
import { PosClientList } from "@/domain/entities/PosClient";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class PosClientApiRepository implements PosClientRepositoryInterface {
  private service: ApiService;
  private mapper: PosClientMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: PosClientMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getListPosClient(search: string): Promise<PosClientList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getPosClientList(search),
      {}
    );
    return this.mapper.converPosClientFromApi(resp);
  }
}
