/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionPodDex = {
    pod_dex_enable: new Rox.Flag(false),
    pod_dex_update: new Rox.Flag(false),
};

export function initFlagsPermissionPodDex() {
    // register flags with namespaces for better grouping
    Rox.register("featurePodDex", FlagsPermissionPodDex);
}
