import { BulkDownloadApiRepository } from "@/app/infrastructures/repositories/api/BulkDownloadApiRepository";
import { injectable } from "tsyringe";

@injectable()
export class BulkDownloadPresenter {
  private repository: BulkDownloadApiRepository;

  constructor(repo: BulkDownloadApiRepository) {
    this.repository = repo;
  }

  public getExistingData(service: string, type: string): Promise<string> {
    return this.repository.getExistingData(service, type);
  }
}
