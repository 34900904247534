import { ExchangeRateApiRepository } from "@/app/infrastructures/repositories/api/ExchangeRateRepositoryApi";
import {
  ExchangeRateEntities,
  ExchangeRateData
} from "@/domain/entities/ExchangeRate";
import { injectable } from "tsyringe";
import {
  AddExchangeRateRequestInterface,
  EditExchangeRateRequestInterface
} from "@/data/payload/contracts/ExchangeRateRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

@injectable()
export class ExchangeRatePresenter {
  private repository: ExchangeRateApiRepository;

  constructor(repository: ExchangeRateApiRepository) {
    this.repository = repository;
  }

  public getListExchangeRate(
    from: string,
    to: string,
    status: string,
    page: number,
    limit: number
  ): Promise<ExchangeRateEntities> {
    return this.repository.getList(from, to, status, page, limit);
  }

  public getDetailExchangeRate(id: number): Promise<ExchangeRateData> {
    return this.repository.getDetailExchange(id);
  }

  public addExchangeRate(
    payload: AddExchangeRateRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addNewExchangeRate(payload);
  }

  public editExchangeRate(
    payload: EditExchangeRateRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editExchangeRate(payload);
  }
}
