import { AxiosResponse } from "axios";
import {
  TrackDeliveryData,
  TrackDeliveryEntities
} from "@/domain/entities/TrackDelivery";
import { Pagination } from "@/domain/entities/Pagination";

export class TrackDeliveryMapper {
  public convertTrackDeliverListListFromApi(
    res: AxiosResponse<any>
  ): TrackDeliveryEntities {
    const { data } = res;
    return new TrackDeliveryEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      data.data
        ? data.data.map((data: any) => {
            return new TrackDeliveryData({
              sttId: data.stt_id,
              noResi: data.stt_no,
              claimNo: data.claim_number,
              shipmentId: data.stt_shipment_id,
              product: data.stt_product_type,
              chargeableWeight: data.stt_chargeable_weight,
              totalAmount: data.stt_total_amount,
              consigneName: data.stt_recipient_name,
              consigneAddress: `${
                data.stt_destination_city_name
              }(${data.stt_destination_city_id ??
                data.stt_destination_city_code})`,
              status: data.stt_last_status_id,
              isCod: data.stt_is_cod,
              isClaimAble: data.stt_is_claimable,
              isClaimed: data.stt_is_claimed,
              statusPerformance:
                data.stt_sla_ontime_status === "meet_sla"
                  ? "Tepat Waktu"
                  : "Terlambat",
              isPromoRetail: data.stt_is_promo_retail,
              totalDiscount: data.stt_total_discount,
              totalTariffAfterDiscount: data.stt_total_tariff_after_discount,
              totalTariffBeforeDiscount: data.stt_total_tariff_before_discount
            });
          })
        : []
    );
  }
}
