const formatDecimal = (value: string) => {
  return value
    ? value
        .toString()
        .replace(/[^0-9,.]/g, "")
        .replace(/[,.]+/g, ",")
        .replace(/,(\d+),/g, ",$1")
    : value.toString();
};
export default formatDecimal;
