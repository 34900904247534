import { SUPPORT_POS_FAVORITE } from "@/app/infrastructures/misc/RolePermission";
const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

const MemberTabs = () =>
  import(
    /* webpackChunkName: "POSFav" */ "@/app/ui/views/pos-favorite/member-tab/index.vue"
  );
const OutStandingTabs = () =>
  import(
    /* webpackChunkName: "POSFav" */ "@/app/ui/views/pos-favorite/outstanding-member-tab/index.vue"
  );
const PosFavourite = () =>
  import(
    /* webpackChunkName: "POSFav" */ "@/app/ui/views/pos-favorite/index.vue"
  );
export default [
  {
    path: "/pos-favorite",
    component: Layout,
    name: "pos-favorite",
    redirect: "/pos-favorite",
    meta: {
      icon: "support-pos-favourite",
      children: false,
      header: true,
      title: "Pos Langganan",
      name: "Pos Langganan",
      gtmEventName: "pos_langganan_menu",
      gtmEventCustoms: {},
      gtmEventOption: ["userId", "userType", "timestamp"],
      permission: SUPPORT_POS_FAVORITE.ENABLE
    },
    children: [
      {
        path: "/pos-favorite",
        name: "pos-favorite",
        redirect: "/pos-favorite/member",
        component: PosFavourite,
        meta: {
          header: true,
          isFreeze: true
        },
        children: [
          {
            path: "member",
            name: "member",
            component: MemberTabs
          },
          {
            path: "outstanding-member",
            name: "outstanding-member",
            component: OutStandingTabs
          }
        ]
      }
    ]
  }
];
