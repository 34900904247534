const convertPhoneNumber = (value: string) => {
  if (value.startsWith("62")) {
    return value;
  }
  if (value.startsWith("0")) {
    return "62" + value.substring(1);
  }
  return "62" + value;
};
export default convertPhoneNumber;
