export class Pagination {
  page: number;
  limit: number;
  totalData?: number;
  allTotalData?: number;
  constructor(
    page: number,
    limit: number,
    totalData?: number,
    allTotalData?: number
  ) {
    this.page = page;
    this.limit = limit;
    this.totalData = totalData;
    this.allTotalData = allTotalData;
  }
}

export class PaginationV2 {
  page = 1;
  limit = 20;
  totalData? = 0;
  allTotalData? = 0;

  constructor(fields?: Partial<PaginationV2>) {
    Object.assign(this, fields);
  }
}
