import { FlagsPermissionManagementCustomer } from "@/feature-flags/flags-permission-management-customer";
import { title } from "process";

const Layout = () =>
    import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const ManagementCustomer = () =>
  import(
    /* webpackChunkName: "ManagementCustomer" */ "@/app/ui/views/management-customer/index.vue"
);
const AddManagementCustomer = () =>
  import(
    /* webpackChunkName: "UserManagement" */ "@/app/ui/views/management-customer/add/index.vue"
  );
const EditManagementCustomer = () =>
  import(
    /* webpackChunkName: "UserManagement" */ "@/app/ui/views/management-customer/edit/index.vue"
  );
const DetailManagementCustomer = () =>
  import(
    /* webpackChunkName: "UserManagement" */ "@/app/ui/views/management-customer/detail/index.vue"
  );

export default [
  {
    path: "/management-customer",
    component: Layout,
    redirect: "/management-customer",
    name: "management-customer",
    meta: {
      icon: "user-management",
      children: false,
      title: "Manajemen Pelanggan",
      name: "Manajemen Pelanggan",
      permission: FlagsPermissionManagementCustomer.management_customer_enable
    },
    children: [
      {
        path: "/management-customer",
        name: "management-customer",
        component: ManagementCustomer,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/management-customer",
    component: Layout,
    name: "management-customer-add",
    meta: {
      icon: "route",
      children: false,
      hidden: true,
      parent: "Manajemen Pelanggan",
      permission: FlagsPermissionManagementCustomer.management_customer_create
    },
    children: [
      {
        path: "/management-customer/add",
        name: "add-customer",
        component: AddManagementCustomer,
        meta: {
          header: true,
          title: "Manajemen Pelanggan | Create",
          name: "Tambah pelanggan",
          before: "/management-customer"
        }
      }
    ]
  },
  {
    path: "/management-customer",
    component: Layout,
    name: "management-customer-edit",
    meta: {
      icon: "route",
      children: false,
      hidden: true,
      parent: "Manajemen Pelanggan",
      permission: FlagsPermissionManagementCustomer.management_customer_edit
    },
    children: [
      {
        path: "/management-customer/edit",
        name: "edit-customer",
        component: EditManagementCustomer,
        meta: {
          header: true,
          title: "Manajemen Pelanggan | Edit",
          name: "Ubah pelanggan",
          before: "/management-customer"
        }
      }
    ]
  },
  {
    path: "/management-customer",
    component: Layout,
    name: "management-customer-detail",
    meta: {
      title: "Manajemen Pelanggan | Detail",
      hidden: true,
      parent: "Manajemen Pelanggan",
      permission: FlagsPermissionManagementCustomer.management_customer_detail
    },
    children: [
      {
        path: ":id",
        name: "detail-customer",
        component: DetailManagementCustomer,
        meta: {
          header: true,
          customPath: true,
          title: "",
          name: "Detail Pelanggan"
        }
      }
    ]
  },
];