/* eslint-disable @typescript-eslint/camelcase */
import {
  EditRequestInterface,
  EditCountryRequestInterface
} from "../contracts/LocationRequest";
import { toFormDataUtils } from "@/app/infrastructures/misc/Utils";
import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class EditCityApiRequest implements EditRequestInterface {
  private is_active: string;
  private free_trade_zone: string;
  private is_sunday_delivery: string;

  constructor(
    is_active: string,
    free_trade_zone: string,
    is_sunday_delivery: string
  ) {
    this.is_active = is_active;
    this.free_trade_zone = free_trade_zone;
    this.is_sunday_delivery = is_sunday_delivery;
  }

  public toJSON() {
    const is_active = this.is_active;
    const free_trade_zone = this.free_trade_zone;
    const is_sunday_delivery = this.is_sunday_delivery;
    return JSON.stringify({
      is_active,
      free_trade_zone,
      is_sunday_delivery
    });
  }
}

export class EditCountryApiRequest implements EditCountryRequestInterface {
  private status: string;

  constructor(status: string) {
    this.status = status;
  }

  public toFormData() {
    const data = {
      status: this.status
    };
    const res = toFormDataUtils(data);
    return res;
  }
}

export class EditDistrictApiRequest implements EditRequestInterface {
  private type: string;
  private ursa_code: string;
  private status: string;
  private is_cod: string;

  constructor(type: string, ursa_code: string, status: string, is_cod: string) {
    this.type = type;
    this.ursa_code = ursa_code;
    this.status = status;
    this.is_cod = is_cod;
  }

  public toJSON() {
    return JSON.stringify({
      type: this.type,
      ursa_code: this.ursa_code,
      status: this.status,
      is_cod: this.is_cod
    });
  }
}

export class DistrictClientRequest {
  clientId = 0
  constructor(fields?: Partial<DistrictClientRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class RegionListRequest {
  search = ""
  status = ""
  page = 1
  limit = 10
  constructor(fields?: Partial<RegionListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
