import Axios, { AxiosInstance, AxiosResponse, Method } from "axios";
import qs from "qs";
import { injectable } from "tsyringe";
import { Endpoints } from "../misc/Endpoints";
import { getTokenAlgo, removeAuthCredentialAlgo } from "../misc/Cookies";
import { ApiServiceAlgoInterface } from "@/data/infrastructures/ApiServiceAlgoInterface";

@injectable()
export default class ApiServiceAlgo implements ApiServiceAlgoInterface {
  public client: AxiosInstance;
  constructor() {
    this.client = Axios.create({
      baseURL: Endpoints.baseUrlAlgo,
      timeout: 50000
    });
    if (process.env.NODE_ENV || process.env.VUE_APP_ALGO_API_URL) {
      this.client.interceptors.response.use(
        resp => {
          return resp;
        },
        error => {
          if (error.response.status === 401) {
            removeAuthCredentialAlgo();
          }
          return Promise.reject(error);
        }
      );
    }
  }

  public async invokeAlgo(
    method: Method = "get",
    url = "",
    params: any = {},
    payload: any = null,
    headers: Map<string, string> = new Map()
  ): Promise<AxiosResponse<any>> {
    // content-type application/json
    this.client.defaults.headers["Content-Type"] =
      method.toLowerCase() === "get" ? "" : "application/json";
    // set custom header if any
    headers.forEach((value: string, key: string) => {
      this.client.defaults.headers.common[key] = value;
    });

    // set auth bearer
    this.client.defaults.headers.Authorization = `Bearer ${getTokenAlgo()}`;
    return await this.client.request({
      url,
      params,
      paramsSerializer: par => qs.stringify(par, { encode: false }),
      data: payload ? payload.toJSON() : null,
      method
    });
  }

  public async invokePostWithFormDataAlgo<T>(
    method: Method = "post",
    url = "",
    params: any = {},
    payload: any = null
  ): Promise<AxiosResponse<any>> {
    // set common header
    return await this.client.request({
      url,
      params,
      paramsSerializer: par => qs.stringify(par, { encode: false }),
      data: payload.toFormData(),
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getTokenAlgo()}`
      },
      method
    });
  }
}
