import { TransportApiRepository } from "@/app/infrastructures/repositories/api/TransportApiRepository";
import {
  TransportEntities,
  TransportData,
  VendorData
} from "@/domain/entities/Transport";
import { injectable } from "tsyringe";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { EditTransportRequestInterface } from "@/data/payload/contracts/TransportRequest";

@injectable()
export class TransportPresenter {
  private repository: TransportApiRepository;

  constructor(repo: TransportApiRepository) {
    this.repository = repo;
  }

  public getTransportList(
    search: string,
    type: string,
    status: string,
    page: number,
    limit: number
  ): Promise<TransportEntities> {
    return this.repository.getTransportList(search, type, status, page, limit);
  }

  public getTransportDetail(id: number): Promise<TransportData> {
    return this.repository.getDetailTransport(id);
  }

  public editTransport(
    payload: EditTransportRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editTransport(payload);
  }

  public getVendorDetail(id: number): Promise<VendorData> {
    return this.repository.getDetailVendor(id);
  }
}
