import { formatInputMoney } from "@/app/infrastructures/misc/Utils";
import moment from "moment";
import { OptionsClass } from "./MainApp";
import { Pagination } from "./Pagination";
import { CityData } from "@/domain/entities/Location";
import { Commodity } from "@/domain/entities/Commodity";
import findKey from "lodash/findKey";
import { VendorData } from "@/domain/entities/Vendor";
import { FlightDetail } from "./NGen";

export class CargoConfigEntities {
  pagination: Pagination;
  data: CargoConfigListData[];
  constructor(pagination: Pagination, data: CargoConfigListData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class CargoConfigListData {
  id = "";
  configName = "";
  configCode = "";
  configTransport = "";
  configStatus = "";
  clientName = "";
  clientId = 0;
  constructor(fields?: Partial<CargoConfigListData>) {
    Object.assign(this, fields);
  }
}

const mapTransportation: any = {
  PLANE: "Pesawat",
  TRAIN: "Kereta",
  SHIP: "Kapal",
  TRUCK: "Truk"
};

export class SchedulePlane {
  idParent = 0;
  ctId = 0;
  startTime = "";
  endTime = "";
  flightDay: OptionsClass | string = "";
  flightNo = "";
  departureDate = "";
  departureTime = "";
  arrivalDate = "";
  arrivalTime = "";
  isTransit = false;
  isError = false;
  orderTransit = 1;
  transit = [] as SchedulePlane[];
  key = 0;
  status = "ACTIVE";
  description = "";
  airportOrigin: any = "";
  airportDestination: any = "";
  dayTransit: any = 0;
  flightTreshold = 0;

  constructor(fields?: Partial<SchedulePlane>) {
    Object.assign(this, fields);
  }
}

export class CargoConfigurationFlightTransitDetail {
  cargoFlightNumber = "";
  cargoFlightDay = "";
  cargoFlightDepartureTime = "";
  cargoFlightDepartureHour: any = "";
  cargoFlightArrivalTime = "";
  cargoFlightArrivalHour: any = "";
  cargoFlightOrigin = "";
  cargoFlightDestination = "";
  cargoFlightDayTransit = 0;
  cargoFlightTreshold = 0;
  constructor(fields?: Partial<CargoConfigurationFlightTransitDetail>) {
    Object.assign(this, fields);
  }
}
export class CargoConfigurationFlightDetail {
  cargoFlightNumber = "";
  cargoFlightDay: OptionsClass | string = "";
  cargoFlightStartTime = "";
  cargoFlightEndTime = "";
  cargoFlightDepartureTime = "";
  cargoFlightDepartureHour = "";
  cargoFlightArrivalTime = "";
  cargoFlightArrivalHour = "";
  cargoFlightOrigin = "";
  cargoFlightDestination = "";
  cargoFlightDayTransit = 0;
  cargoFlightTreshold = 0;

  cargoFlightTransit = [] as CargoConfigurationFlightTransitDetail[];

  constructor(fields?: Partial<CargoConfigurationFlightDetail>) {
    Object.assign(this, fields);
  }
}

export class RequestFormCargoConfiguration {
  cargoConfigurationId = 0;
  cargoConfigurationOrigin = "";
  cargoConfigurationOriginName = "";
  cargoConfigurationDestination = "";
  cargoConfigurationDestinationName = "";
  cargoConfigurationModeTransport = "";
  cargoConfigurationComodityCode = "";
  cargoConfigurationComodityName = "";
  cargoConfigurationProductType = "";
  cargoConfigurationMaxPieceLimit = 0;
  cargoConfigurationMinimumWeight = 0;
  cargoConfigurationMaximumWeight = 0;
  cargoConfigurationMinimumVolume = 0;
  cargoConfigurationMaximumVolume = 0;
  cargoConfigurationStatus: boolean | string = false;
  cargoConfigurationVendorCode = "";
  cargoConfigurationVendorName = "";
  cargoFlight = [] as CargoConfigurationFlightDetail[];
  cargoConfigurationCutOffTime = [] as CutOffTimeConfigCargo[];
  cargoConfigurationClientParentId = 0;
  cargoConfigurationClientParentName = "";
  cargoConfigurationClientBranch: any = []

  constructor(fields?: Partial<RequestFormCargoConfiguration>) {
    Object.assign(this, fields);
  }
}

export class CutOffTimeConfigCargo {
  ctId = 0;
  ctCcId = 0;
  ctTime = "";
  ctFlight: any = {};
  ctFlightDay = "";
  ctFlightNumber = "";
  ctStatus = "ACTIVE";
  isError = false;
  key = 0;
  constructor(fields?: Partial<CutOffTimeConfigCargo>) {
    Object.assign(this, fields);
  }

  formatTime?(time: string) {
    const timeData = time.split(":");
    return new Date(
      new Date().setHours(+timeData[0], +timeData[1], 0, 0)
    ).toString();
  }
}

export class SchedulePlaneCargoDetail {
  id = 0;
  flightNumber = "";
  flightDay: OptionsClass | string = "";
  flightDepartureDate = "";
  flightArrivalDate = "";
  flightDepartureHourOnly = "";
  flightArrivalHourOnly = "";
  flightAirportOrigin = "";
  flightAirportOriginName = "";
  flightAirportDestination = "";
  flightAirportDestinationName = "";
  flightDayTransit = 0;
  flightTreshold = 0;
  constructor(fields?: Partial<SchedulePlaneCargoDetail>) {
    Object.assign(this, fields);
  }

  get formattedFlightDepartureDate(): string {
    return this.flightDepartureDate
      ? moment(this.flightDepartureDate).format("DD MMM YYYY")
      : "-";
  }
  get flightDepartureHour(): string {
    return this.flightDepartureDate
      ? moment(this.flightDepartureDate).format("HH:mm")
      : "-";
  }
  get formattedFlightArrivalDate(): string {
    return this.flightArrivalDate
      ? moment(this.flightArrivalDate).format("DD MMM YYYY")
      : "-";
  }
  get flightArrivalHour(): string {
    return this.flightArrivalDate
      ? moment(this.flightArrivalDate).format("HH:mm")
      : "-";
  }
}

export class SchedulePlaneCargo {
  id = 0;
  timeStart = "";
  timeEnd = "";
  schedule = [] as SchedulePlaneCargoDetail[];
  constructor(fields?: Partial<SchedulePlaneCargo>) {
    Object.assign(this, fields);
  }

  get formatTimeStart(): string {
    return moment(this.timeStart).format("HH:mm");
  }
  get formatTimeEnd(): string {
    return moment(this.timeEnd).format("HH:mm");
  }
}

export class ClientBranch {
  clientBranchId = 0;
  clientBranchName = "";
  constructor(fields?: Partial<ClientBranch>) {
    Object.assign(this, fields);
  }
}

export class CargoConfiguration {
  id = 0;
  originCity: any = "";
  destinationCity: any = "";
  transportationType: OptionsClass | string = new OptionsClass({
    name: "",
    value: ""
  });
  vendor: VendorData | string = "";
  schedulePlane = [] as SchedulePlaneCargo[];
  schedulePlaneForm = [] as SchedulePlane[];
  commodities = [] as Array<Commodity>;
  products = [] as Array<string>;
  maximumPieces = "";
  minimumWeight = "";
  maximumWeight = "";
  minimumVolume = "";
  maximumVolume = "";
  status = false;
  createdAt = "";
  createdBy = "";
  updatedAt = "";
  updatedBy = "";
  clientParentId = 0;
  clientParentName = "";
  clientBranch = [] as ClientBranch[];
  cargoConfigurationCutOffTime = [
    new CutOffTimeConfigCargo()
  ] as CutOffTimeConfigCargo[];

  constructor(fields?: Partial<CargoConfiguration>) {
    Object.assign(this, fields);
  }
  joinCommoditiesName(delimiter = ", "): string {
    return this.commodities
      .map((commodity: Commodity) => commodity.commodity_name)
      .join(delimiter);
  }
  joinCommoditiesCode(delimiter = ", "): string {
    return this.commodities
      .map((commodity: Commodity) => commodity.commodity_code)
      .join(delimiter);
  }
  joinProducts(delimiter = ", "): string {
    return this.products.join(delimiter);
  }
  get transportationName(): string {
    const key =
      typeof this.transportationType === "string"
        ? this.transportationType
        : this.transportationType.value;
    if (!mapTransportation[key]) return key;
    return mapTransportation[key];
  }
  getTransportationName(name: string): string {
    if (!mapTransportation[name]) return name;
    return mapTransportation[name];
  }
  getTransportationKey(name: string): string {
    const result = findKey(
      mapTransportation,
      transport => transport.toLowerCase() === name.toLowerCase()
    );
    if (result) return result;
    return name;
  }
}

export class CargoListRecomendations {
  transportationType: OptionsClass | string = new OptionsClass();
  flightData: FlightDetail = new FlightDetail();

  constructor(fields?: Partial<CargoListRecomendations>) {
    Object.assign(this, fields);
  }
}
