/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsDashboardDeliveryCOD = {
  version_hydra_dashboard_delivery: new Rox.RoxString("v2"),
};

export function initFlagsDashboardDeliveryCOD() {
  // register flags with namespaces for better grouping
  Rox.register("DashboardDeliveryCOD", FlagsDashboardDeliveryCOD);
}
