import { FINANCE_MANUAL_DEDUCT_ADD_SALDO } from "@/app/infrastructures/misc/RolePermission";
const DetailManualDeductAddSaldo = () =>
  import(
    /* webpackChunkName: "DeductManual" */ "@/app/ui/views/finance/manual-deduct-add-saldo/components/detail.vue"
  );

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const AddDeductSaldo = () =>
  import(
    /* webpackChunkName: "DeducManual" */ "@/app/ui/views/finance/manual-deduct-add-saldo/components/add.vue"
  );

export default [
  {
    path: "/finance/manual-deduct",
    component: Layout,
    name: "create-manual-deduct",
    meta: {
      hidden: true,
      parent: "Manual Credit Debit",
      permission: FINANCE_MANUAL_DEDUCT_ADD_SALDO.CREATE
    },
    children: [
      {
        path: "create",
        name: "create-manual-deduct",
        component: AddDeductSaldo,
        meta: {
          header: true,
          title: "Manual Credit Debit | Create ",
          name: "Buat Transaksi",
          before: "/finance/manual-deduct"
        }
      }
    ]
  },
  {
    path: "/finance/manual-deduct",
    name: "detail-manual-deduct",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Manual Credit Debit",
      permission: FINANCE_MANUAL_DEDUCT_ADD_SALDO.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-manual-deduct",
        component: DetailManualDeductAddSaldo,
        meta: {
          title: "Manual Credit Debit | Detail",
          header: true
        }
      }
    ]
  }
];
