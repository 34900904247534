/* eslint-disable @typescript-eslint/camelcase */
import { Pagination } from "./Pagination";

export class RouteEntities {
  success: boolean;
  code: number;
  message: string;
  meta: Pagination;
  data: RouteData[];

  constructor(
    success: boolean,
    code: number,
    message: string,
    meta: Pagination,
    data: RouteData[]
  ) {
    this.success = success;
    this.code = code;
    this.message = message;
    this.meta = meta;
    this.data = data;
  }
}

export class RouteData {
  id: number;
  route_name: string;
  route_code: string;
  origin_location?: string;
  destination?: string;
  route_count_option: number;
  route_status: string;
  route_type: string;
  created_at?: string;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;
  route_option?: RouteOptions[];
  slaCityToCity?: SlaCityToCity[];

  constructor(
    id: number,
    route_name: string,
    route_code: string,
    route_count_option: number,
    route_status: string,
    route_type: string,
    origin_location?: string,
    destination?: string,
    created_at?: string,
    created_by?: string,
    updated_at?: string,
    updated_by?: string,
    route_option?: RouteOptions[],
    slaCityToCity?: SlaCityToCity[]
  ) {
    this.id = id;
    this.route_name = route_name;
    this.route_code = route_code;
    this.origin_location = origin_location;
    this.destination = destination;
    this.route_count_option = route_count_option;
    this.route_status = route_status;
    this.route_type = route_type;
    this.created_at = created_at;
    this.created_by = created_by;
    this.updated_at = updated_at;
    this.updated_by = updated_by;
    this.route_option = route_option;
    this.slaCityToCity = slaCityToCity;
  }
}

export class RouteOptions {
  group_option_name: string;
  option_status: boolean;
  option: OptionsDetail[];
  is_default: boolean;
  option_product_type: string[];

  constructor(
    group_option_name: string,
    option_status: boolean,
    option: OptionsDetail[],
    is_default: boolean,
    option_product_type: string[]
  ) {
    this.group_option_name = group_option_name;
    this.option_status = option_status;
    this.option = option;
    this.is_default = is_default;
    this.option_product_type = option_product_type;
  }
}

export class OptionsDetail {
  option_name: string;
  option_type: string;
  option_transport: string;
  option_vendor_name: string;
  option_transport_min_sla: number;
  option_transport_max_sla: number;

  constructor(
    option_name: string,
    option_type: string,
    option_transport: string,
    option_vendor_name: string,
    option_transport_min_sla: number,
    option_transport_max_sla: number
  ) {
    this.option_name = option_name;
    this.option_type = option_type;
    this.option_transport = option_transport;
    this.option_vendor_name = option_vendor_name;
    this.option_transport_min_sla = option_transport_min_sla;
    this.option_transport_max_sla = option_transport_max_sla;
  }
}

export class SlaCityToCity {
  productName = "";
  minSla = 0;
  maxSla = 0;

  constructor(fields?: Partial<SlaCityToCity>) {
    Object.assign(this, fields);
  }
}
