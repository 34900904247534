import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  OptionsDetail,
  RouteData,
  RouteEntities,
  RouteOptions,
  SlaCityToCity
} from "@/domain/entities/Route";
import { AxiosResponse } from "axios";

export class RouteDataMapper {
  public convertRouteFromApi(result: AxiosResponse<any>): RouteEntities {
    const { data } = result;
    if (!data) return new RouteEntities(false, 0, "", new Pagination(0, 0), []);
    else {
      return new RouteEntities(
        data.success,
        data.code,
        data.message,
        new Pagination(data.meta.page, data.meta.limit),
        data.data
          ? data.data.map((item: any) => {
              return new RouteData(
                item.route_id,
                item.route_name,
                item.route_code,
                item.route_option,
                item.route_status,
                item.route_type
              );
            })
          : []
      );
    }
  }

  public convertDetailRouteDataFromApi(result: AxiosResponse<any>): RouteData {
    const {
      data: { data }
    } = result;
    return new RouteData(
      data.route_id,
      data.route_name,
      data.route_code,
      data.route_count_option,
      data.route_status,
      data.route_type,
      data.origin_location,
      data.destination,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      data.route_option
        ? data.route_option.map((item: any) => {
            return new RouteOptions(
              item.group_option_name,
              !!item.option_status.match(/^active$/gi),
              item.option
                ? item.option.map((e: OptionsDetail) => {
                    return new OptionsDetail(
                      e.option_name,
                      e.option_type,
                      e.option_transport,
                      e.option_vendor_name,
                      e.option_transport_min_sla,
                      e.option_transport_max_sla
                    );
                  })
                : [],
              item.is_default,
              item.option_product_type
            );
          })
        : [],
      data.sla_city_to_city && data.sla_city_to_city.length
        ? data.sla_city_to_city.map(
            (sla: any) =>
              new SlaCityToCity({
                productName: sla.product_name,
                minSla: sla.min_sla,
                maxSla: sla.max_sla
              })
          )
        : []
    );
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
