/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionConfigFakeDexIndication = {
  permission_config_fake_dex_indication_enable: new Rox.Flag(false)
};

export function initFlagsPermissionFlagsPermissionConfigFakeDexIndication() {
  // register flags with namespaces for better grouping
  Rox.register(
    "featureConfigFakeDexIndication",
    FlagsPermissionConfigFakeDexIndication
  );
}
