import { PosClientApiRepository } from "@/app/infrastructures/repositories/api/PosClientApiRepository";
import { PosClientList } from "@/domain/entities/PosClient";
import { injectable } from "tsyringe";

@injectable()
export class PosClientPresenter {
  private repository: PosClientApiRepository;

  constructor(repo: PosClientApiRepository) {
    this.repository = repo;
  }

  public getPosClientList(search: string): Promise<PosClientList> {
    return this.repository.getListPosClient(search);
  }
}
