import { HubMapper } from "@/data/persistences/mappers/HubMapper";
import { HubRepositoryInterface } from "@/data/persistences/repositories/contracts/HubRepositoryInterface";
import { HubList, RequestHubList } from "@/domain/entities/Hub";
import { HubEndpoint } from "../../endpoints/horde/HubEndpoint";
import ApiService from "../../services/ApiService";

export class HubApiRepository implements HubRepositoryInterface {
    private readonly service: ApiService;
    private readonly mapper: HubMapper;
    private readonly endpoints: HubEndpoint;

    constructor(
        service: ApiService,
        mapper: HubMapper,
        endpoints: HubEndpoint
    ) {
        this.service = service;
        this.mapper = mapper;
        this.endpoints = endpoints;
    }
    public async getHubList(params: RequestHubList): Promise<HubList> {
        const resp = await this.service.invoke(
            "get",
            this.endpoints.getHubList(params),
            {}
        );
        return this.mapper.convertHubListFromApi(resp);
    }

}