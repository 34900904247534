import { FlagsPermissionPickup } from "@/feature-flags/flag-pickup";
const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

const SchedulePickupList = () =>
  import(
    /* webpackChunkName: "STTBooking" */ "@/app/ui/views/pickup/schedule/list/index.vue"
  );

const SchedulePickupDetail = () =>
  import(
    /* webpackChunkName: "Detail" */ "@/app/ui/views/pickup/schedule/detail/index.vue"
  );

const SchedulePickupForm = () =>
  import(
    /* webpackChunkName: "Form" */ "@/app/ui/views/pickup/components/schedule-config-form/index.vue"
  );

const PickupList = () =>
  import(
    /* webpackChunkName: "STTBooking" */ "@/app/ui/views/pickup/list-pickup/list/index.vue"
  );


const PickupDetail = () =>
  import(
    /* webpackChunkName: "Detail" */ "@/app/ui/views/pickup/list-pickup/detail/index.vue"
  );

const PickupForm = () =>
  import(
    /* webpackChunkName: "Form" */ "@/app/ui/views/pickup/components/pickup-form/index.vue"
  );

export default [
  {
    path: "/pickup",
    component: Layout,
    name: "pickup-schedule",
    redirect: "/pickup/schedule-list",
    meta: {
      icon: "pickup-manifest",
      children: true,
      title: "Pick Up",
      name: "Pick Up",
      permission: [
        FlagsPermissionPickup.permission_pickup_scheduled_pickup_enable
      ]
    },
    children: [
      {
        path: "list-pickup",
        name: "list-pickup",
        component: PickupList,
        meta: {
          header: true,
          icon: "clipboard-check",
          title: "Daftar Pick Up",
          name: "Daftar Pick Up",
          permission:
            FlagsPermissionPickup.permission_pickup_enable
        }
      },
      {
        path: "schedule-list",
        name: "schedule-list",
        component: SchedulePickupList,
        meta: {
          header: true,
          icon: "pickup-schedule-list",
          title: "Konfigurasi Jadwal",
          name: "Konfigurasi Jadwal",
          permission:
            FlagsPermissionPickup.permission_pickup_scheduled_pickup_enable
        }
      }
    ]
  },
  {
    path: "/pickup/scheduled-pickup",
    component: Layout,
    name: "pickup-scheduled-detail",
    meta: {
      title: "Konfigurasi Jadwal | Detail",
      hidden: true,
      parent: "Konfigurasi Jadwal",
      "parent-path": "/pickup/schedule-list",
      permission:
        FlagsPermissionPickup.permission_pickup_scheduled_pickup_enable
    },
    children: [
      {
        path: ":id",
        name: "pickup-scheduled-detail",
        component: SchedulePickupDetail,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/pickup/scheduled-pickup",
    name: "pickup-scheduled-create",
    component: Layout,
    redirect: "/pickup/scheduled-pickup/create",
    meta: {
      hidden: true,
      title: "Buat konfigurasi baru",
      parent: "Konfigurasi Jadwal",
      "parent-path": "/pickup/schedule-list",
      permission:
        FlagsPermissionPickup.permission_pickup_scheduled_pickup_create
    },
    children: [
      {
        path: "create",
        name: "pickup-scheduled-create",
        component: SchedulePickupForm,
        meta: {
          header: true,
          name: "Buat konfigurasi baru",
          before: "/pickup/schedule/list"
        }
      }
    ]
  },
  {
    path: "/pickup/scheduled-pickup",
    name: "pickup-scheduled-edit",
    redirect: "/pickup/scheduled-pickup/edit",
    component: Layout,
    meta: {
      hidden: true,
      title: "Ubah konfigurasi",
      parent: "Konfigurasi Jadwal",
      "parent-path": "/pickup/schedule-list",
      permission: FlagsPermissionPickup.permission_pickup_scheduled_pickup_edit
    },
    children: [
      {
        path: ":id/edit",
        name: "pickup-scheduled-edit",
        component: SchedulePickupForm,
        meta: {
          header: true,
          name: "Ubah konfigurasi",
          before: "/pickup/scheduled-pickup/:id"
        }
      }
    ]
  },
  {
    path: "/pickup/list-pickup",
    component: Layout,
    name: "pickup-detail",
    meta: {
      title: "Daftar Pick Up | Detail",
      hidden: true,
      parent: "Daftar Pick Up",
      "parent-path": "/pickup/list-pickup",
      permission:
        FlagsPermissionPickup.permission_pickup_enable
    },
    children: [
      {
        path: ":id",
        name: "pickup-detail",
        component: PickupDetail,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/pickup/list-pickup",
    name: "pickup-create",
    component: Layout,
    redirect: "/pickup/list-pickup/create",
    meta: {
      hidden: true,
      title: "Buat Pick Up baru",
      parent: "Daftar Pick Up",
      "parent-path": "/pickup/list-pickup",
      permission:
        FlagsPermissionPickup.permission_pickup_list_pickup_create
    },
    children: [
      {
        path: "create",
        name: "pickup-list-create",
        component: PickupForm,
        meta: {
          header: true,
          title: "Pickup | Create ",
          name: "Buat Pick Up baru",
          before: "/pickup/list-pickup",
          formType: "manual"
        }
      }
    ]
  },
];
