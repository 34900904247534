import { GoodsTaxApiRepository } from "@/app/infrastructures/repositories/api/GoodsTaxApiRepository";
import {
  AddGoodsTaxRequestInterface,
  EditGoodsTaxRequestInterface
} from "@/data/payload/contracts/GoodsTaxRequest";
import { GoodsTax, GoodsTaxList } from "@/domain/entities/GoodsTax";
import { injectable } from "tsyringe";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { CityEntities } from "@/domain/entities/Location";

@injectable()
export class GoodsTaxPresenter {
  private repository: GoodsTaxApiRepository;

  constructor(repository: GoodsTaxApiRepository) {
    this.repository = repository;
  }

  public async getGoodsTaxList(
    cityCode: string,
    status: string,
    page: number,
    limit: number,
    commodityCode: string
  ): Promise<GoodsTaxList> {
    return await this.repository.getGoodsTaxList(
      cityCode,
      status,
      page,
      limit,
      commodityCode
    );
  }

  public async addGoodsTax(
    payload: AddGoodsTaxRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addGoodsTax(payload);
  }

  public async editGoodsTax(
    payload: EditGoodsTaxRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editGoodsTax(payload);
  }

  public getCityList(
    search: string,
    status: string,
    page: number,
    limit: number,
    freeTradeZone: string
  ): Promise<CityEntities> {
    return this.repository.getCityList(
      search,
      status,
      page,
      limit,
      freeTradeZone
    );
  }

  public async getDetailGoodsTax(id: number): Promise<GoodsTax> {
    return await this.repository.getDetailGoodsTax(id);
  }
}
