import {
  ExchangeRateData,
  ExchangeRateEntities
} from "@/domain/entities/ExchangeRate";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class ExchangeRateDataMapper {
  public convertExchangeRateFromApi(
    result: AxiosResponse<any>
  ): ExchangeRateEntities {
    const { data } = result;
    return new ExchangeRateEntities(
      new Pagination(data.meta.page, data.meta.limit),
      data.data.length === 0
        ? []
        : data.data.map((item: any) => {
            return new ExchangeRateData(
              item.exchange_rate_id,
              item.exchange_rate_from,
              item.exchange_rate_to,
              item.exchange_rate,
              item.exchange_rate_status,
              item.exchange_rate_start_date,
              item.exchange_rate_end_date,
              item.exchange_rate_created_at,
              item.exchange_rate_updated_at,
              item.exchange_rate_created_by,
              item.exchange_rate_updated_by
            );
          })
    );
  }

  public convertDetailExchangeRateFromApi(
    result: AxiosResponse<any>
  ): ExchangeRateData {
    const { data } = result;
    return new ExchangeRateData(
      data.data.exchange_rate_id,
      data.data.exchange_rate_from,
      data.data.exchange_rate_to,
      data.data.exchange_rate,
      data.data.exchange_rate_status,
      data.data.exchange_rate_start_date,
      data.data.exchange_rate_end_date,
      data.data.exchange_rate_created_at,
      data.data.exchange_rate_updated_at,
      data.data.exchange_rate_created_by,
      data.data.exchange_rate_updated_by
    );
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
