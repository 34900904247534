import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import {
  ConfigPriceCodDetail,
  ConfigurableCod
} from "@/domain/entities/ConfigPriceCod";

export class ConfigPriceCodMapper {
  public convertDetailDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = result;

    return new ResponsePayloadV2({
      data: new ConfigPriceCodDetail({
        configurablePriceId: data.configurable_price_id,
        configurablePriceName: data.configurable_price_name,
        configurablePriceType: data.configurable_price_type,
        configurablePriceDescription: data.configurable_price_description,
        isConfigurablePriceStatus: !!data.configurable_price_status.match(
          /^active$/gi
        ),
        configurablePriceStatus: data.configurable_price_status,
        configurablePriceCreatedAt: data.configurable_price_created_at,
        configurablePriceCreatedBy: data.configurable_price_created_by,
        configurablePriceUpdatedAt: data.configurable_price_updated_at,
        configurablePriceUpdatedBy: data.configurable_price_updated_by,
        configurableCod: data.configurable_cod.length
          ? data.configurable_cod.map(
              (item: any) =>
                new ConfigurableCod({
                  ccodId: item.ccod_id,
                  ccodCpid: item.ccod_cpid,
                  ccodName: item.ccod_name,
                  ccodTypeCalculation: item.ccod_type_calculation,
                  ccodCodType: item.ccod_cod_type,
                  ccodReferences: item.ccod_references,
                  ccodFee: item.ccod_fee
                })
            )
          : []
      })
    });
  }

  public convertFormDataFromApi(result: AxiosResponse<any>): ResponsePayloadV2 {
    const {
      data: { data }
    } = result;

    return new ResponsePayloadV2({ data });
  }
}
