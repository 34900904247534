import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class UniversalMapper {
  public convertPayloadRebuttalDexData(
    res: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const { data } = res;
    return new ResponsePayloadV2({
      success: data.success,
      code: data.code,
      message: data.message.id
    });
  }
}
