import { App } from "vue";

const register = (app: App<Element>): void => {
  app.directive("click-outside", {
    beforeMount: (el: any, binding: any) => {
      const ourClickEventHandler = (event: any) => {
        if (!el.contains(event.target) && el !== event.target) {
          binding.value(event);
        }
      };
      el.__vueClickEventHandler__ = ourClickEventHandler;
      document.addEventListener("click", ourClickEventHandler);
    },
    unmounted: (el: any) => {
      document.removeEventListener("click", el.__vueClickEventHandler__);
    }
  });
};

export default {
  register
};
