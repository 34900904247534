import { ReadyToCargoApiRepository } from "../../repositories/api/ReadyToCargoRepository";
import { ReadyToCargoMapper } from "@/data/persistences/mappers/ReadyToCargoMapper";
import { container } from "tsyringe";
import ApiService from "../../services/ApiService";
import { ReadyToCargoPresenter } from "@/app/ui/presenters/ReadyToCargoPresenter";
import { ReadyToCargoEndpoint } from "../../endpoints/hydra/ReadyToCargoEndpoint";

export class ReadyToCargoComponent {
  public static init() {
    container.register<ReadyToCargoApiRepository>(ReadyToCargoApiRepository, {
      useFactory: d => {
        return new ReadyToCargoApiRepository(
          d.resolve(ApiService),
          d.resolve(ReadyToCargoMapper),
          d.resolve(ReadyToCargoEndpoint)
        );
      }
    });
    container.register<ReadyToCargoMapper>(ReadyToCargoMapper, {
      useClass: ReadyToCargoMapper
    });
    container.register<ReadyToCargoPresenter>(ReadyToCargoPresenter, {
      useFactory: d => {
        return new ReadyToCargoPresenter(d.resolve(ReadyToCargoApiRepository));
      }
    });
  }
}
