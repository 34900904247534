/* eslint-disable @typescript-eslint/camelcase */
import { ListSttMapper } from "@/data/persistences/mappers/ListSttMapper";
import { ListSttRepositoryInterface } from "@/data/persistences/repositories/contracts/ListSttRepositoryInterface";
import { BagListEntities, ListSttEntities } from "@/domain/entities/ListStt";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class ListSttApiRepository implements ListSttRepositoryInterface {
  private service: ApiService;
  private mapper: ListSttMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: ListSttMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  async getListStt(
    page: number,
    limit: number,
    destination_city_id: number,
    user_type: string,
    booked_by: string,
    from_date: string,
    end_date: string,
    stt_number: string,
    booked_type: string
  ): Promise<ListSttEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListStt(
        page,
        limit,
        destination_city_id,
        user_type,
        booked_by,
        from_date,
        end_date,
        stt_number,
        booked_type
      ),
      {}
    );
    const mapper =
      process.env.VUE_APP_MIGRATE_FROM_ELEXYS === "true"
        ? (resp: AxiosResponse) =>
            this.mapper.convertListSttDataFromApiElexys(resp)
        : (resp: AxiosResponse) => this.mapper.convertListSttDataFromApi(resp);
    return mapper(resp);
  }
  async getListBag(bagNumber: string): Promise<BagListEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListBaggingBulkPrint(bagNumber),
      {}
    );
    return this.mapper.convertListBagDataFromApi(resp);
  }
}
