import { injectable } from "tsyringe";
import { HeavyWeightSurchargeApiRepository } from "@/app/infrastructures/repositories/api/HeavyWeightSurchargeApiRepository";
import { HeavyWeightSurchargeData } from "@/domain/entities/HeavyWeightSurcharge";
import { EditHeavyWeightSurchargeRequestInterface } from "@/data/payload/contracts/HeavyWeightSurcharge";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

@injectable()
export class HeavyWeightSurchargePresenter {
  private repository: HeavyWeightSurchargeApiRepository;

  constructor(repo: HeavyWeightSurchargeApiRepository) {
    this.repository = repo;
  }

  public getDetailHeavyWeightSurcharge(
    id: number
  ): Promise<HeavyWeightSurchargeData> {
    return this.repository.getDetailHeavyWeightSurcharge(id);
  }

  public editHeavyWeightSurcharge(
    payload: EditHeavyWeightSurchargeRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editHeavyWeightSurcharge(payload);
  }
}
