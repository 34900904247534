import { AxiosResponse } from "axios";
import {
  AdjustmentPenaltyData,
  ConfigurableSttAdjustment
} from "@/domain/entities/AdjustmentPenalty";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class AdjustmentPenaltyMapper {
  public convertDetailAdjusmentDetail(
    res: AxiosResponse<any>
  ): AdjustmentPenaltyData {
    const { data } = res;

    return new AdjustmentPenaltyData(
      data.data.configurable_price_id,
      data.data.configurable_price_name,
      data.data.configurable_price_type,
      data.data.configurable_price_description,
      data.data.configurable_price_status,
      data.data.configurable_price_created_at,
      data.data.configurable_price_created_by,
      data.data.configurable_price_updated_at,
      data.data.configurable_price_updated_by,
      new ConfigurableSttAdjustment(
        data.data.configurable_stt_adjustment.stt_adjustment_id,
        data.data.configurable_stt_adjustment.stt_adjustment_configurable_id,
        data.data.configurable_stt_adjustment.penalty_adjusted_by,
        data.data.configurable_stt_adjustment.penalty_apply_for,
        data.data.configurable_stt_adjustment.penalty_adjusted_by
          .split(",")
          .map((item: any) => {
            return {
              name: item,
              value: item
            };
          })
      )
    );
  }

  public convertPayloadData(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
