import { Pagination } from "./Pagination";

export class UpdateDelEntities {
  pagination: Pagination;
  data: UpdateDelData[];
  constructor(pagination: Pagination, data: UpdateDelData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class UpdateDelData {
  id = 0;
  deliveryTotalStt = 0;
  deliveryTotalPiece = 0;
  deliveryDriverName = "";
  deliveryDriverPhone = "";
  deliveryDeliveredBy = "";
  deliveryCreatedAt = "";
  deliveryCreatedBy = "";
  constructor(fields?: Partial<UpdateDelData>) {
    Object.assign(this, fields);
  }
}
export class SttPieceDetail {
  sttPieceId = 0;
  sttPieceGrossWeight = 0;
  sttPieceVolumeWeight = 0;
  sttPieceNo = 0;
  sttPieceLastStatus = "";
  constructor(fields?: Partial<SttPieceDetail>) {
    Object.assign(this, fields);
  }
}

export class SttDetail {
  sttId = 0;
  sttNo = "";
  sttAssessmentStatus = ""
  sttProductType = "";
  sttTotalPiece = 0;
  sttDestinationCityCode = "";
  sttDestinationCityName = "";
  sttOriginCityCode = "";
  sttOriginCityName = "";
  sttIsWoodPacking = false;
  sttCommodityCode = "";
  sttCommodityName = "";
  sttGrossWeight = 0;
  sttVolumeWeight = 0;
  sttChargeAbleWeight = 0;
  sttCodAmount = 0;
  sttLastStatus = "";
  sttDeliveryAttempt = 0;
  sttRecipientName = "";
  sttRecipientPhone = "";
  sttRecipientAddress = "";
  sttPieces = [new SttPieceDetail()];
  sttNoElexys = "";
  sttNoRefExternal? = "";
  constructor(fields?: Partial<SttDetail>) {
    Object.assign(this, fields);
  }
}

export class SttValidation {
  isAllowUpdateStatus = false;
  errorMessage = "";
  isPaid = false;
  sttDetail = new SttDetail();
  constructor(fields?: Partial<SttValidation>) {
    Object.assign(this, fields);
  }
}

export class CreateUpdateDeliveryResponse {
  delId: number;
  totalSuccess: number;
  totalFailed: number;
  sttFailed: string[];
  constructor(
    delId: number,
    totalSuccess: number,
    totalFailed: number,
    sttFailed: string[]
  ) {
    this.delId = delId;
    this.totalSuccess = totalSuccess;
    this.totalFailed = totalFailed;
    this.sttFailed = sttFailed;
  }
}

export class PrintData {
  deliveryMasterId = 0;
  deliveryMasterTotalStt = 0;
  deliveryMasterTotalPiece = 0;
  deliveryMasterTotalGrossWeight = 0;
  deliveryMasterTotalVolumeWeight = 0;
  deliveryMasterPartnerName = "";
  deliveryMasterPartnerType = "";
  deliveryMasterDriverPhone = "";
  deliveryMasterDriverName = "";
  deliveryMasterDestinationCityCode = "";
  deliveryMasterDestinationCityName = "";
  deliveryMasterCreatedAt = "";
  stt = [] as SttDelivery[];

  constructor(fields?: Partial<PrintData>) {
    Object.assign(this, fields);
  }
}

export class SttDelivery {
  sttNo = "";
  sttNoElexys? = "";
  productType = "";
  totalPiece = 0;
  recipientName = "";
  recipientAddress = "";
  recipientPhone = "";
  codAmount = 0;
  origin = "";
  destination = "";
  constructor(fields?: Partial<SttDelivery>) {
    Object.assign(this, fields);
  }
}
