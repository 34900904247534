import { Pagination } from "./Pagination";

export class PromoConfigurationList {
  pagination: Pagination;
  data: PromoConfigurationListData[];
  constructor(pagination: Pagination, data: PromoConfigurationListData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class PromoConfigurationListData {
  promoConfigurationId = 0;
  promoConfigurationName = "";
  promoConfigurationStartDate = "";
  promoConfigurationEndDate = "";
  promoConfigurationUpdatedName = "";
  promoConfigurationUpdatedAt = "";
  promoConfigurationStatus = "";
  promoConfigurationCategory = "";
  promoConfigurationDiscountNominal = 0;
  promoConfigurationAppliedTo:any = [];
  promoConfigurationAppliedShipments: any = [];

  constructor(fields?: Partial<PromoConfigurationListData>) {
    Object.assign(this, fields);
  }
}

export class PromoConfiguration {
  promoConfigurationId = 0;
  promoConfigurationName = "";
  promoConfigurationType = "";
  promoConfigurationOrigin = "";
  promoConfigurationDestination = "";
  promoConfigurationProductType = "";
  promoConfigurationMinimalGrossWeight = 0;
  promoConfigurationMaximalGrossWeight = 0;
  promoConfigurationStartDate = "";
  promoConfigurationEndDate = "";
  promoConfigurationDiscountType = "";
  promoConfigurationPromoDiscount = 0;
  promoConfigurationMaximalPromo = 0;
  promoConfigurationParameterCalculation = "";
  promoConfigurationAppliedTo = "";
  promoConfigurationCreatedAt = "";
  promoConfigurationCreatedBy = 0;
  promoConfigurationCreatedName = "";
  promoConfigurationUpdatedAt = "";
  promoConfigurationUpdatedBy = 0;
  promoConfigurationUpdatedName = "";
  promoConfigurationStatus = "";
  pdcPromoReferenceWeight = "";
  promoConfigurationAppliedList = [] as string[];
  promoConfigurationShipmentOption = [] as PromoConfigurationShipmentOption[];

  constructor(fields?: Partial<PromoConfiguration>) {
    Object.assign(this, fields);
  }
}

export class PromoConfigurationShipmentOption {
  name = "";
  value = false;

  constructor(fields?: Partial<PromoConfigurationShipmentOption>) {
    Object.assign(this, fields);
  }
}

export class Discount {
  isDiscount = false;
  totalTariff = 0;
  totalAfterDiscount = 0;
  totalBeforeDiscount = 0;

  constructor(fields?: Partial<Discount>) {
    Object.assign(this, fields);
  }
}