export class RefundConfigurationData {
  configurablePriceId = 0;
  configurablePriceName = "";
  configurablePriceType = "";
  configurablePriceDescription = "";
  configurablePriceStatus = "";
  configurablePriceCreatedAt = "";
  configurablePriceCreatedBy = "";
  configurablePriceUpdatedAt = "";
  configurablePriceUpdatedBy = "";
  configurableRefund = [] as ConfigurableRefund[];
  constructor(fields?: Partial<RefundConfigurationData>) {
    Object.assign(this, fields);
  }
}

export class ConfigurableRefund {
  refundId = 0;
  refundConfigurablePriceId = 0;
  refundSttLastStatus = "";
  refundAmount = 0;
  refundPenalty = 0;
  refundQuantifier = "";
  refundAmountInsurance = 0;
  refundOriginCityExclude = "";
  refundOriginCityExcludeDetail = [] as RefundOriginCityExcludeDetail[];
  refundRemarks = "";
  expand = false;
  remarks: ConfigurableRefund[] = [];
  constructor(fields?: Partial<ConfigurableRefund>) {
    Object.assign(this, fields);
  }
}

export class RefundOriginCityExcludeDetail {
  name = "";
  code = "";
  freeTradeZone = "";
  constructor(fields?: Partial<RefundOriginCityExcludeDetail>) {
    Object.assign(this, fields);
  }
}
