import { ShortlandData, ShortlandEntities } from "@/domain/entities/Shortland";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";

export class ShortlandMapper {
  public convertListShortlandListDataFromApi(
    res: AxiosResponse<any>
  ): ShortlandEntities {
    const { data } = res;
    if (!data.success)
      new ShortlandEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    return new ShortlandEntities(
      new Pagination(data.meta.page, data.meta.limit),
      data.data.length > 0
        ? data.data.map(
            (item: any) =>
              new ShortlandData(
                item.cargo_no,
                item.cargo_type,
                item.stt_no,
                item.stt_piece_no,
                item.stt_id,
                item.stt_piece_id,
                item.arrival_city_code,
                item.arrival_city_name,
                item.commodity_code,
                item.commodity_name,
                item.stt_last_status,
                item.gross_weight,
                item.volume_weight,
                item.stt_total_piece,
                item.stt_no_elexys || ""
              )
          )
        : []
    );
  }
}
