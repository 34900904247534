import { PaymentApiRepository } from "@/app/infrastructures/repositories/api/PaymentApiRepository";
import { ValidatePaymentRequestInterface } from "@/data/payload/contracts/PaymentRequest";
import {
  CheckInquiry,
  PaymentMethod,
  PaymentResponses
} from "@/domain/entities/Payment";
import { RequestListPaymentMethod } from "@/data/payload/api/PaymentApiRequest";

export class PaymentPresenter {
  private repository: PaymentApiRepository;

  constructor(repository: PaymentApiRepository) {
    this.repository = repository;
  }

  public validatePayment(
    payload: ValidatePaymentRequestInterface
  ): Promise<PaymentResponses> {
    return this.repository.validatePayment(payload);
  }

  public checkInquiry(id: string): Promise<CheckInquiry> {
    return this.repository.checkInquiry(id);
  }

  public getListPaymentMethod(
    params: RequestListPaymentMethod
  ): Promise<PaymentMethod[]> {
    return this.repository.getListPaymentMethod(params);
  }
}
