import { Pagination } from "./Pagination";

export class BaseRatesEntities {
  pagination: Pagination;
  data: BaseRatesData[];
  constructor(pagination: Pagination, data: BaseRatesData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class BaseRatesData {
  id: number;
  rateVersionName: string;
  rateVersionStartDate: string;
  rateVersionEndDate: number;
  rateVersionCreatedAt: string;
  rateVersionUpdatedAt: string;
  rateVersionStatus: string;
  totalLegRate: string;
  constructor(
    id: number,
    rateVersionName: string,
    rateVersionStartDate: string,
    rateVersionEndDate: number,
    rateVersionCreatedAt: string,
    rateVersionUpdatedAt: string,
    rateVersionStatus: string,
    totalLegRate: string
  ) {
    this.id = id;
    this.rateVersionName = rateVersionName;
    this.rateVersionStartDate = rateVersionStartDate;
    this.rateVersionEndDate = rateVersionEndDate;
    this.rateVersionCreatedAt = rateVersionCreatedAt;
    this.rateVersionUpdatedAt = rateVersionUpdatedAt;
    this.rateVersionStatus = rateVersionStatus;
    this.totalLegRate = totalLegRate;
  }
}

export class RatesDetail {
  rateVersionId: number;
  rateVersionName: string;
  rateVersionAppliedTo: any;
  rateVersionTotalRoute: number;
  rateVersionFiledUpload: string;
  rateVersionStatus: string;
  rateVersiondUpdatedHistory: UpdateHistory[];
  rateVersionStartDate: string;
  rateVersionEndDate: string;
  rateVersionCreatedAt: string;
  rateVersiondUpdatedAt: string;
  rateVersionCreatedBy: string;
  rateVersiondUpdatedBy: string;
  rateVersionCurrency:string;

  constructor(
    rateVersionId: number,
    rateVersionName: string,
    rateVersionAppliedTo: any,
    rateVersionTotalRoute: number,
    rateVersionFiledUpload: string,
    rateVersionStatus: string,
    rateVersiondUpdatedHistory: UpdateHistory[],
    rateVersionStartDate: string,
    rateVersionEndDate: string,
    rateVersionCreatedAt: string,
    rateVersiondUpdatedAt: string,
    rateVersionCreatedBy: string,
    rateVersiondUpdatedBy: string,
    rateVersionCurrency:string,
  ) {
    this.rateVersionId = rateVersionId;
    this.rateVersionName = rateVersionName;
    this.rateVersionAppliedTo = rateVersionAppliedTo;
    this.rateVersionTotalRoute = rateVersionTotalRoute;
    this.rateVersionFiledUpload = rateVersionFiledUpload;
    this.rateVersionStatus = rateVersionStatus;
    this.rateVersiondUpdatedHistory = rateVersiondUpdatedHistory;
    this.rateVersionStartDate = rateVersionStartDate;
    this.rateVersionEndDate = rateVersionEndDate;
    this.rateVersionCreatedAt = rateVersionCreatedAt;
    this.rateVersiondUpdatedAt = rateVersiondUpdatedAt;
    this.rateVersionCreatedBy = rateVersionCreatedBy;
    this.rateVersiondUpdatedBy = rateVersiondUpdatedBy;
    this.rateVersionCurrency = rateVersionCurrency;
  }
}

export class UpdateHistory {
  id: string;
  action: string;
  accountName: string;
  versionName: string;
  createdAt: string;

  constructor(
    id: string,
    action: string,
    accountName: string,
    versionName: string,
    createdAt: string
  ) {
    this.id = id;
    this.action = action;
    this.accountName = accountName;
    this.versionName = versionName;
    this.createdAt = createdAt;
  }
}
