export class UnreadData {
  unread: number;
  unreadNotifications: NotificationData[];
  constructor(unread: number, unreadNotifications: NotificationData[]) {
    this.unread = unread;
    this.unreadNotifications = unreadNotifications;
  }
}

export class NotificationsEntities {
  primaryNotifications: NotificationData[];
  notifications: NotificationData[];
  constructor(
    primaryNotifications: NotificationData[],
    notifications: NotificationData[]
  ) {
    this.primaryNotifications = primaryNotifications;
    this.notifications = notifications;
  }
}

export class NotificationData {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  title: string;
  status: string;
  description: string;
  attachment: string;
  bannerImage: string;
  pinned: string;
  createdBy: number;
  announcementTarget: AnnouncementTarget[];
  releasedAt: string;
  read: boolean;

  constructor(
    id: number,
    createdAt: string,
    updatedAt: string,
    deletedAt: string,
    title: string,
    status: string,
    description: string,
    attachment: string,
    bannerImage: string,
    pinned: string,
    createdBy: number,
    announcementTarget: AnnouncementTarget[],
    releasedAt: string,
    read: boolean
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.deletedAt = deletedAt;
    this.title = title;
    this.status = status;
    this.description = description;
    this.attachment = attachment;
    this.bannerImage = bannerImage;
    this.pinned = pinned;
    this.createdBy = createdBy;
    this.announcementTarget = announcementTarget;
    this.releasedAt = releasedAt;
    this.read = read;
  }
}

export class AnnouncementTarget {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  announcementId: number;
  userTarget: string;
  constructor(
    id: number,
    createdAt: string,
    updatedAt: string,
    deletedAt: string,
    announcementId: number,
    userTarget: string
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.deletedAt = deletedAt;
    this.announcementId = announcementId;
    this.userTarget = userTarget;
  }
}
