import { Pagination } from "@/domain/entities/Pagination";

export class TopUpVerificationEntities {
  pagination: Pagination;
  data: TopUpVerificationListData[];
  constructor(pagination: Pagination, data: TopUpVerificationListData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class TopUpVerificationListData {
  id = "";
  genesisTransactionId = "";
  partnerName = "";
  status = "";
  requestAmount = 0;
  verificationAmount = 0;
  approvalAmount = 0;
  bankNameSender = "";
  accountNameSender = "";
  accountNumberSender = "";
  bankNameReceiver = "";
  accountNameReceiver = "";
  accountNumberReceiver = "";
  approvalDate = new Date().toISOString();
  approvalBy = "";
  transactionDate = new Date().toISOString();
  transactionBy = "";
  verificationDate = new Date().toISOString();
  verificationBy = "";
  note = "";
  constructor(fields?: Partial<TopUpVerificationListData>) {
    Object.assign(this, fields);
  }
}

export class TopUpVerificationDetail {
  id = "";
  genesisTransactionId = "";
  senderBankName = "";
  senderBankAccountName = "";
  senderBankAccountNumber = "";
  recipientBankName = "";
  recipientBankAccountName = "";
  recipientBankAccountNumber = "";
  amount = 0;
  amountVerified = 0;
  amountApproved = 0;
  status = "";
  reasonRejected = "";
  fileOfProof = "";
  actorType = "";
  actorName = "";
  actorCode = "";
  createdAt = new Date().toISOString();
  createdBy = "";
  updatedAt = new Date().toISOString();
  updatedBy = "";
  verifiedAt = new Date().toISOString();
  verifiedBy = "";
  approvedAt = new Date().toISOString();
  approvedBy = "";

  constructor(fields?: Partial<TopUpVerificationDetail>) {
    Object.assign(this, fields);
  }
}
