/* eslint-disable @typescript-eslint/camelcase */
import { BankAccountRequestInterface } from "../contracts/BeneficiaryRequest";

export class ValidateBankAccountApiRequest
  implements BankAccountRequestInterface {
  bank_name: string;
  account_number: string;
  constructor(bank_name: string, account_number: string) {
    this.bank_name = bank_name;
    this.account_number = account_number;
  }

  toJSON() {
    return JSON.stringify({
      bank_name: this.bank_name,
      account_number: this.account_number
    });
  }
}
