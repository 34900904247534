/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionBalanceTransaction = {
  finance_balance_transaction_enable: new Rox.Flag(false),
  finance_balance_transaction_nett_enable: new Rox.Flag(false)
};

export function initFlagsPermissionBalanceTransaction() {
  // register flags with namespaces for better grouping
  Rox.register("featureBalanceTransaction", FlagsPermissionBalanceTransaction);
}
