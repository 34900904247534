import { TransportDataMapper } from "@/data/persistences/mappers/TransportDataMapper";
import { TransportRepositoryInterface } from "@/data/persistences/repositories/contracts/TransportRepositoryInterface";
import {
  TransportEntities,
  TransportData,
  VendorData
} from "@/domain/entities/Transport";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { EditTransportRequestInterface } from "@/data/payload/contracts/TransportRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { EditTransportApiRequest } from "@/data/payload/api/TransportApiRequest";

export class TransportApiRepository implements TransportRepositoryInterface {
  private service: ApiService;
  private mapper: TransportDataMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: TransportDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getTransportList(
    search: string,
    type: string,
    status: string,
    page: number,
    limit: number
  ): Promise<TransportEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListTransport(search, type, status, page, limit),
      {}
    );
    return this.mapper.convertTransportDataFromApi(resp);
  }

  public async getDetailTransport(id: number): Promise<TransportData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getTransportDetail(id),
      {}
    );
    return this.mapper.convertDetailTransportDataFromApi(resp);
  }

  public async editTransport(
    payload: EditTransportRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "patch",
      this.endpoints.editTransport(),
      undefined,
      payload as EditTransportApiRequest
    );
    return this.mapper.convertPayloadData(resp);
  }

  public async getDetailVendor(id: number): Promise<VendorData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailVendor(id),
      {}
    );
    return this.mapper.convertVendorStatusFromApi(resp);
  }
}
