import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { UpdateDeliveryRepositoryInterface } from "@/data/persistences/repositories/contracts/UpdateDeliveryRepositoryInterface";
import { UpdateDeliveryMapper } from "@/data/persistences/mappers/UpdateDeliveryMapper";
import {
  CreateUpdateDeliveryResponse,
  SttValidation,
  UpdateDelEntities,
  PrintData
} from "@/domain/entities/UpdateDelivery";
import { UpdateDeliveryRequest } from "@/data/payload/contracts/UpdateDeliveryRequest";
import { CreateUpdateDelivery } from "@/data/payload/api/UpdateDeliveryReqeust";

export class UpdateDeliveryApiRepository
  implements UpdateDeliveryRepositoryInterface {
  private service: ApiService;
  private mapper: UpdateDeliveryMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: UpdateDeliveryMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getUpdateDelList(
    page: number,
    limit: number,
    search: string,
    startDate: string,
    endDate: string
  ): Promise<UpdateDelEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getUpdateDelList(page, limit, search, startDate, endDate),
      {}
    );
    return this.mapper.convertUpdateDelListFromApi(resp);
  }

  public async getSttValidationUpdateDelivery(
    sttNo: string
  ): Promise<SttValidation> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSttValidationUpdateDel(sttNo),
      {}
    );
    return this.mapper.convertSttValidationFromApi(resp);
  }

  public async createUpdateDelivery(
    payload: UpdateDeliveryRequest
  ): Promise<CreateUpdateDeliveryResponse> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.createUpdateDelivery(),
      {},
      payload as CreateUpdateDelivery
    );
    return this.mapper.convertUpdateDeliveryCreateFromApi(resp);
  }

  public async printDelivery(id: number): Promise<PrintData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.printDelivery(id),
      {}
    );
    return this.mapper.convertPrintDataListFromApi(resp);
  }
}
