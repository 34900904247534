/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsDashboardCod = {
  feature_api_version_dashboard_delivery_detail_courier_cod: new Rox.RoxString(
    "v2"
  ),
  feature_api_version_dashboard_delivery_summary_courier_cod: new Rox.RoxString(
    "v2"
  ),
  permission_show_tab_cod_dashboard: new Rox.Flag(false),
  permission_detail_cod_dashboard: new Rox.Flag(false)
};

export function initFlagsDashboardCod() {
  // register flags with namespaces for better grouping
  Rox.register("featureDashboardCod", FlagsDashboardCod);
}
