import { container } from "tsyringe";
import FirebaseService from "@/app/infrastructures/services/FirebaseService";
import { FirebaseApiRepository } from "@/app/infrastructures/repositories/api/FirebaseApiRepository";
import { FirebasePresenter } from "@/app/ui/presenters/FirebasePresenter";

export class FirebaseComponent {
  public static init() {
    container.register<FirebaseApiRepository>(FirebaseApiRepository, {
      useFactory: d => {
        return new FirebaseApiRepository(d.resolve(FirebaseService));
      }
    });
    container.register<FirebasePresenter>(FirebasePresenter, {
      useFactory: d => {
        return new FirebasePresenter(d.resolve(FirebaseApiRepository));
      }
    });
  }
}