import { ROUTE_ROUTE_MANAGEMENT } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const DetailRouteManagement = () =>
  import(
    /* webpackChunkName: "Route" */ "@/app/ui/views/route/route-management/component/detail.vue"
  );
const EditRouteManagement = () =>
  import(
    /* webpackChunkName: "Route" */ "@/app/ui/views/route/route-management/component/edit.vue"
  );

export default [
  {
    path: "/network/route-management",
    component: Layout,
    name: "detail-route-management",
    meta: {
      hidden: true,
      parent: "Route Management",
      permission: ROUTE_ROUTE_MANAGEMENT.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-route-management",
        component: DetailRouteManagement,
        meta: {
          header: true,
          title: "Route Management | Detail"
        }
      }
    ]
  },
  {
    path: "/network/route-management",
    component: Layout,
    name: "edit-route-management",
    meta: {
      icon: "route",
      children: false,
      hidden: true,
      parent: "Route Management",
      permission: ROUTE_ROUTE_MANAGEMENT.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-route-management",
        component: EditRouteManagement,
        meta: {
          header: true,
          title: "Route Management | Edit",
          name: "Edit",
          before: "/network/route-management/:id"
        }
      }
    ]
  }
];
