import { Pagination } from "@/domain/entities/Pagination";

export class BankAccountEntities {
  pagination: Pagination;
  data: BankAccountData[];
  constructor(pagination: Pagination, data: BankAccountData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class BankAccountData {
  id: number;
  label: string;
  name: string;
  bankLogo: number;
  accountNumber: string;
  accountName: string;
  accountType: string;
  accountEmail: string;
  paymentProcedure: string;
  actorId: number;
  actorType: string;
  createdAt: string;
  updatedAt: string;
  constructor(
    id: number,
    label: string,
    name: string,
    bankLogo: number,
    accountNumber: string,
    accountName: string,
    accountType: string,
    accountEmail: string,
    paymentProcedure: string,
    actorId: number,
    actorType: string,
    createdAt: string,
    updatedAt: string
  ) {
    this.id = id;
    this.label = label;
    this.name = name;
    this.bankLogo = bankLogo;
    this.accountNumber = accountNumber;
    this.accountName = accountName;
    this.accountType = accountType;
    this.accountEmail = accountEmail;
    this.paymentProcedure = paymentProcedure;
    this.actorId = actorId;
    this.actorType = actorType;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
