/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const ConfigBucketStorage = {
  config_bucket_dev_genesis_announcement: new Rox.RoxString(
    "https://storage.googleapis.com/dev-genesis-announcement"
  ),
  config_bucket_dev_genesis: new Rox.RoxString(
    "https://storage.googleapis.com/dev-genesis"
  ),
  config_bucket_stg_genesis: new Rox.RoxString(
    "https://storage.googleapis.com/stg-genesis"
  ),
  config_bucket_prd_genesis: new Rox.RoxString(
    "https://storage.googleapis.com/prd-genesis"
  ),
  config_bucket_bank_logo: new Rox.RoxString(
    process.env.VUE_APP_LOGO_BANK_URL ||
      "https://storage.googleapis.com/dev-genesis/bank-logo/"
  ),
  config_migrate_bucket: new Rox.Flag(false)
};

export function initConfigBucketStorage() {
  // register flags with namespaces for better grouping
  Rox.register("configBucketStorage", ConfigBucketStorage);
}
