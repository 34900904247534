import moment from "moment";

interface Time {
  date: string;
  isSecond?: boolean;
}
const formatTimeCustom = ({ date, isSecond = true }: Time) => {
  if (isSecond) return moment(date).format("HH:mm:ss");
  return moment(date).format("HH:mm");
};
export default formatTimeCustom;
