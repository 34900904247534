import { FlagsPermissionConfigQuotaTicket } from "@/feature-flags/flags-permission-config-quota-ticket";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

const ConfigFakeDexIndicationForm = () =>
  import(
    /* webpackChunkName: "ConfigFakeDexIndicationForm" */ "@/app/ui/views/customer-service/config-quota-ticket/form.vue"
  );

export default [
  {
    path: "/customer-service/config-quota-ticket",
    component: Layout,
    name: "config-quota-ticket-detail",
    meta: {
      title: "Konfigurasi Kuota Tiket CS | Detail",
      hidden: true,
      parent: "Konfigurasi Kuota Tiket CS",
      permission:
        FlagsPermissionConfigQuotaTicket.permission_config_quota_ticket_enable
    },
    children: [
      {
        path: ":id",
        name: "config-quota-ticket-detail",
        component: ConfigFakeDexIndicationForm,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/customer-service/config-quota-ticket",
    component: Layout,
    name: "config-quota-ticket-edit",
    meta: {
      title: "Konfigurasi Kuota Tiket CS | Edit",
      hidden: true,
      parent: "Konfigurasi Kuota Tiket CS",
      permission:
        FlagsPermissionConfigQuotaTicket.permission_config_quota_ticket_enable
    },
    children: [
      {
        path: ":id/edit",
        name: "config-quota-ticket-edit",
        component: ConfigFakeDexIndicationForm,
        meta: {
          header: true,
          title: "Konfigurasi Kuota Tiket CS | Edit",
          name: "Edit Konfigurasi",
          before: "/customer-service/config-quota-ticket/:id"
        }
      }
    ]
  }
];
