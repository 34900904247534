/* eslint-disable @typescript-eslint/camelcase */
import { RequestListBooking } from "@/data/payload/api/BookingRequest";
import { STTPaidUnpaidMapper } from "@/data/persistences/mappers/STTPaidUnpaid";
import { STTPaidUnpaidRepositoryInterface } from "@/data/persistences/repositories/contracts/STTPaidUnpaidRepositoryInterface";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { RequestSttPaidUnread, TotalSTTPaidUnread } from "@/domain/entities/STTPaidUnpaid";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class STTPaidUnpaidApiRepository
  implements STTPaidUnpaidRepositoryInterface {
  private service: ApiService;
  private mapper: STTPaidUnpaidMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: STTPaidUnpaidMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getTotalSTTPaidUnread(params: RequestSttPaidUnread): Promise<TotalSTTPaidUnread> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.totalSTTPaidUnread(params),
      {}
    );
    return this.mapper.convertTotalSTTPaidUnreadFromApi(resp);
  }

  public async setLastOpenSttPaidUnread(): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.totalSTTPaidUnread(),
      {}
    );
    return this.mapper.convertLastOpenSTTPaidUnreadFromApi(resp);
  }

  public async getDownloadStt(
    params: RequestListBooking
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDownloadStt(params),
      {}
    );
    return this.mapper.convertLastOpenSTTPaidUnreadFromApi(resp);
  }
}
