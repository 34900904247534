import { Pagination } from "@/domain/entities/Pagination";

export class BaggingEntities {
  pagination: Pagination;
  data: BaggingData[];

  constructor(pagination: Pagination, data: BaggingData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class BaggingData {
  bagId = 0;
  bagCode = "";
  bagPartnerId = 0;
  bagPartnerCode = "";
  bagPartnerName = "";
  bagOriginCityCode = "";
  bagOriginCityName = "";
  bagOriginTransitCityCode = "";
  bagOriginTransitCityName = "";
  bagDestinationCityCode = "";
  bagDestinationCityName = "";
  bagTotalStt = 0;
  bagTotalGrossWeight = 0;
  bagTotalVolumeWeight = 0;
  bagTotalChargeableWeight = 0;
  bagTotalPiece = 0;
  bagCreatedAt = "";
  bagCreatedBy = 0;
  bagCreatedName = "";
  bagCustomGrossWeight = 0;
  bagCustomVolumeWeight = 0;
  userType = "";
  isAbleRTC = false;
  isCheckboxAlreadyRTC = false;
  isAlreadyRTC = false;
  isCargo = false;
  isRtcProcess = false;
  constructor(fields?: Partial<BaggingData>) {
    Object.assign(this, fields);
  }
}

export class SttPiece {
  sttPieceId: number;
  sttPieceGrossWeight: number;
  sttPieceVolumeWeight: number;
  sttPieceNo: number;
  sttPieceLastStatusId: string;
  constructor(
    sttPieceId: number,
    sttPieceGrossWeight: number,
    sttPieceVolumeWeight: number,
    sttPieceNo: number,
    sttPieceLastStatusId: string
  ) {
    this.sttPieceId = sttPieceId;
    this.sttPieceGrossWeight = sttPieceGrossWeight;
    this.sttPieceVolumeWeight = sttPieceVolumeWeight;
    this.sttPieceNo = sttPieceNo;
    this.sttPieceLastStatusId = sttPieceLastStatusId;
  }
}

export class SttResult {
  sttId = "";
  sttNo = "";
  sttProductType = "";
  sttTotalPiece = "";
  sttDestinationCityId = "";
  sttOriginCityId = "";
  sttDestinationCityName = "";
  sttWoodPacking = "";
  sttCommodityCode = "";
  sttCommodityName = "";
  sttGrossWeight = 0;
  sttVolumeWeight = 0;
  sttChargeableWeight = 0;
  sttCodAmount = 0;
  sttLastStatusId = "";
  bookedAt = "";
  sttPiece = [] as SttPiece[];
  paymentStatus = "";
  sttNumberElexys = "";
  sttNoRefExternal = "";
  bagVendorNo = "";
  isDangerousGoods = false;

  constructor(fields?: Partial<SttResult>) {
    Object.assign(this, fields);
  }
}

export class BaggingDataDetail {
  bagNumber = "";
  partnerName = "";
  originCity = "";
  destinationCity = "";
  partnerType = "";
  baggingDate: Date = new Date();
  baggingCreatedBy = "";
  totalStt = 0;
  totalGrossWeight = 0;
  totalBagGrossWeight = 0;
  totalBagChargeableWeight = 0;
  totalPiece = 0;
  totalVolumeWeight = 0;
  totalBagVolumeWeight = 0;
  sttResult = [] as SttResult[];
  partnerId = 0;
  isPaid = false;
  bagVendorNo = "";
  bagCustomLength = 30;
  bagCustomHeight = 10;
  bagCustomWidth = 20;
  constructor(fields?: Partial<BaggingDataDetail>) {
    Object.assign(this, fields);
  }
}
export class BaggingDetail {
  sttNumber = "";
  sttNumberPieces = "";
  destinationCity = "";
  productType = "";
  woodPacking = "";
  commodity = "";
  cod = 0;
  piecesNumber = "";
  grossWeight = 0;
  volumeWeight = 0;
  piece = 0;
  ppl = "";
  lastStatus = "";
  constructor(fields?: Partial<BaggingDetail>) {
    Object.assign(this, fields);
  }
}

export class SttNumberDetail {
  sttId = 0;
  sttNo = "";
  sttProductType = "";
  sttTotalPiece = 0;
  sttDestinationCityId = "";
  sttDestinationCityName = "";
  sttWoodPacking = "";
  sttCommodityCode = "";
  sttCommodityName = "";
  sttCommodityGroupCode = "";
  sttCommodityGroupName = "";
  sttGrossWeight = 0;
  sttVolumeWeight = 0;
  sttChargeableWeight = 0;
  sttCodAmount = 0;
  sttLastStatusId = "";
  bookedAt = "";
  piece: SttPieceDetail[] = [];
  sttNumberElexys? = "";
  paymentStatus? = "";
  sttNoRefExternal? = "";
  bagVendorNo? = "";
  isDangerousGoods = false;
  sttAssessmentStatus = "";

  constructor(fields?: Partial<SttNumberDetail>) {
    Object.assign(this, fields);
  }
}
export class RegionBaggingGrouping {
  baggingRegionOriginId = "";
  baggingRegionOriginName = "";
  baggingRegionDestinationId = "";
  baggingRegionDestinationName = "";
  constructor(fields?: Partial<RegionBaggingGrouping>) {
    Object.assign(this, fields);
  }
}

export class BaggingSttList {
  data: SttNumberBaggingDetail[];
  constructor(data: SttNumberBaggingDetail[]) {
    this.data = data;
  }
}

export class SttNumberBaggingDetail {
  isAllowUpdateStatus = false;
  errorMessage = "";
  stt: SttNumberDetail = new SttNumberDetail();
  regionBaggingGrouping: RegionBaggingGrouping = new RegionBaggingGrouping();
  isPaid?: boolean;
  constructor(fields?: Partial<SttNumberBaggingDetail>) {
    Object.assign(this, fields);
  }
}

export class STTToBeGenerate {
  sttNumber: string;
  sttPieces: SttNumberDetail[];

  constructor(sttNumber: string, sttPieces: SttNumberDetail[]) {
    this.sttNumber = sttNumber;
    this.sttPieces = sttPieces;
  }
}

export class SttPieceDetail {
  sttPieceId: number;
  sttPieceGrossWeight: number;
  sttPieceVolumeWeight: number;
  sttPieceNo: number;
  sttPieceLastStatusId: string;
  sttNumberElexys?: string;
  constructor(
    sttPieceId: number,
    sttPieceGrossWeight: number,
    sttPieceVolumeWeight: number,
    sttPieceNo: number,
    sttPieceLastStatusId: string,
    sttNumberElexys?: string
  ) {
    this.sttPieceId = sttPieceId;
    this.sttPieceGrossWeight = sttPieceGrossWeight;
    this.sttPieceVolumeWeight = sttPieceVolumeWeight;
    this.sttPieceNo = sttPieceNo;
    this.sttPieceLastStatusId = sttPieceLastStatusId;
    this.sttNumberElexys = sttNumberElexys;
  }
}

export class CreateBaggingResponse {
  bagId: number;
  bagCode: string;
  totalSttSuccess: number;
  totalSttFailed: number;
  sttFailed: Array<string>;

  constructor(
    bagId: number,
    bagCode: string,
    totalSttSuccess: number,
    totalSttFailed: number,
    sttFailed: Array<string>
  ) {
    this.bagId = bagId;
    this.bagCode = bagCode;
    this.totalSttSuccess = totalSttSuccess;
    this.totalSttFailed = totalSttFailed;
    this.sttFailed = sttFailed;
  }
}

export class ResponseUpdate {
  bagCode: string;
  bagId: number;
  totalSttSuccess: number;
  totalSttFailed: number;
  sttFailed: Array<string>;
  constructor(
    bagCode: string,
    bagId: number,
    totalSttSuccess: number,
    totalSttFailed: number,
    sttFailed: Array<string>
  ) {
    this.bagCode = bagCode;
    this.bagId = bagId;
    this.totalSttSuccess = totalSttSuccess;
    this.totalSttFailed = totalSttFailed;
    this.sttFailed = sttFailed;
  }
}

export class PayloadCreateBagging {
  destinationCityCode = "";
  customGrossWeight = 0;
  customLength = 0;
  customWidth = 0;
  customHeight = 0;
  productType = "";
  commodityGroupCode = "";
  isRtc = false;
  bagStt: Array<string>= [];
  hubId = 0;
  hubName = "";
  hubOriginCity = "";
  hubDistrictCode = "";
  constructor(fields?: Partial<PayloadCreateBagging>) {
    Object.assign(this, fields);
  }
}

export class PayloadUpdateReadyToCargo {
  bagList: any = [];
  constructor(fields?: Partial<PayloadUpdateReadyToCargo>) {
    Object.assign(this, fields);
  }
}

