import {
  formatDateWithoutTime,
  formatTimeNumber
} from "@/app/infrastructures/misc/Utils";

/* eslint-disable @typescript-eslint/camelcase */
export const gtmCargoData = (params: {
  type: string;
  isAuto?: boolean;
  gtmData?: any;
}) => {
  const { type: cargoType, isAuto, gtmData } = params;

  const type = `${cargoType}${isAuto ? "-auto" : ""}`;
  let result: any;

  switch (type) {
    case "truck":
      result = {
        type: {
          eventName: "cargo_type_truck",
          property: {
            cargo_truck: "Truk (booking manual)"
          }
        },
        detailView: {
          eventName: "page_view_truck",
          property: {
            detail_cargo_form: ""
          }
        },
        inputSttSuccess: {
          eventName: "input_STT_success_truck",
          property: {
            success_message: "STT berhasil di input"
          }
        },
        inputSttFailed: {
          eventName: "input_STT_failed_truck",
          property: {
            error_message: "STT gagal di input"
          }
        },
        deleteSttSuccess: {
          eventName: "cargotruck_delete_STT",
          property: {
            success_message: "STT berhasil di hapus"
          }
        },
        bookingCargoSuccess: {
          eventName: "booking_cargotruck_success",
          property: {
            success_message: "Cargo berhasil dibuat",
            cargo_number: gtmData?.cargoNumber
          }
        },
        bookingCargoSuccessPDF: {
          eventName: "booking_cargotruck_success_PDF",
          property: {
            cargo_number: gtmData?.cargoNumber
          }
        },
        bookingCargoSuccessLabel: {
          eventName: "booking_cargotruck_success_label",
          property: {
            cargo_number: gtmData?.cargoNumber
          }
        },
        bookingCargoFailed: {
          eventName: "booking_cargotruck_failed",
          property: {
            error_message: "Cargo gagal dibuat"
          }
        }
      };
      break;
    case "train":
      result = {
        type: {
          eventName: "cargo_type_train",
          property: {
            cargo_train: "Kereta (booking manual)"
          }
        },
        detailView: {
          eventName: "page_view_train",
          property: {
            detail_cargo_form: ""
          }
        },
        inputSttSuccess: {
          eventName: "input_STT_success_train",
          property: {
            success_message: "STT berhasil di input"
          }
        },
        inputSttFailed: {
          eventName: "input_STT_failed_train",
          property: {
            error_message: "STT gagal di input"
          }
        },
        deleteSttSuccess: {
          eventName: "cargotrain_delete_STT",
          property: {
            success_message: "STT berhasil di hapus"
          }
        },
        bookingCargoSuccess: {
          eventName: "booking_cargotrain_success",
          property: {
            success_message: "Cargo berhasil dibuat",
            cargo_number: gtmData?.cargoNumber
          }
        },
        bookingCargoSuccessPDF: {
          eventName: "booking_cargotrain_success_PDF",
          property: {
            cargo_number: gtmData?.cargoNumber
          }
        },
        bookingCargoSuccessLabel: {
          eventName: "booking_cargotrain_success_label",
          property: {
            cargo_number: gtmData?.cargoNumber
          }
        },
        bookingCargoFailed: {
          eventName: "booking_cargotrain_failed",
          property: {
            error_message: "Cargo gagal dibuat"
          }
        }
      };
      break;
    case "plane": // plane manual
      result = {
        type: {
          eventName: "cargo_type_plane",
          property: {
            cargo_plane_manual: "Pesawat (Booking Manual)"
          }
        },
        detailViewAwb: {
          eventName: "page_view_awb",
          property: {
            input_awb: ""
          }
        },
        awbValid: {
          eventName: "awb_valid",
          property: { success_message: "" }
        },
        awbInvalid: {
          eventName: "awb_invalid",
          property: {
            error_message: ""
          }
        },
        detailViewNgen: {
          eventName: "page_view_detail_ngen",
          property: { detail_cargo_ngen: "" }
        },
        detailViewInputManual: {
          eventName: "page_view_input",
          property: { detail_cargo_user: "" }
        },
        inputSttSuccess: {
          eventName: "input_STT_success_plane",
          property: {
            success_message: "STT berhasil di input"
          }
        },
        inputSttFailed: {
          eventName: "input_STT_failed_plane",
          property: {
            error_message: "STT gagal di input"
          }
        },
        deleteSttSuccess: {
          eventName: "cargoplane_delete_STT",
          property: {
            success_message: "STT berhasil di hapus"
          }
        },
        bookingCargoSuccess: {
          eventName: "booking_cargoplane_success",
          property: {
            success_message: "Cargo berhasil dibuat",
            cargo_number: gtmData?.cargoNumber,
            flight_number: gtmData?.flightNumber
          }
        },
        bookingCargoSuccessPDF: {
          eventName: "booking_cargoplane_success_PDF",
          property: {
            cargo_number: gtmData?.cargoNumber,
            flight_number: gtmData?.flightNumber
          }
        },
        bookingCargoSuccessLabel: {
          eventName: "booking_cargoplane_success_label",
          property: {
            cargo_number: gtmData?.cargoNumber,
            flight_number: gtmData?.flightNumber
          }
        },
        bookingCargoFailed: {
          eventName: "booking_cargoplane_failed",
          property: {
            error_message: "Cargo gagal dibuat"
          }
        }
      };
      break;
    case "plane-auto":
      result = {
        type: {
          eventName: "cargo_type_cargoauto",
          property: {
            cargo_plane_auto: "Pesawat (Booking Auto)"
          }
        },
        popupView: {
          eventName: "popup_view_cargoauto",
          property: {
            pop_up_form: ""
          }
        },
        planeSchedule: {
          eventName: "flight_schedule",
          property: { total_flight: gtmData?.totalFlights }
        },
        changeAirport: {
          eventName: "change_airport",
          property: {
            total_flight_change: gtmData?.totalFlights
          }
        },
        chooseFlight: {
          eventName: "choose_flight",
          property: {
            flight_number: gtmData?.flightNumber,
            date_of_departure: formatDateWithoutTime(
              gtmData?.departureDateTime
            ),
            date_of_arrival: formatDateWithoutTime(gtmData?.arrivalDateTime),
            time_of_departure: formatTimeNumber(gtmData?.departureDateTime),
            time_of_arrival: formatTimeNumber(gtmData?.arrivalDateTime)
          }
        },
        clickFlightList: {
          eventName: "flight",
          property: {
            date_flight: formatDateWithoutTime(gtmData?.departureDateTime),
            flight_number: gtmData?.flightNumber
          }
        },
        detailView: {
          eventName: "page_view_cargoauto",
          property: {
            detail_cargo_form: ""
          }
        },
        inputSttSuccess: {
          eventName: "input_STT_success_cargoauto",
          property: {
            success_message: "STT berhasil di input"
          }
        },
        inputSttFailed: {
          eventName: "input_STT_failed_cargoauto",
          property: {
            error_message: "STT gagal di input"
          }
        },
        deleteSttSuccess: {
          eventName: "cargoauto_delete_STT",
          property: {
            success_message: "STT berhasil di hapus"
          }
        },
        bookingCargoSuccess: {
          eventName: "booking_cargoauto_success",
          property: {
            success_message: "Cargo berhasil dibuat",
            cargo_number: gtmData?.cargoNumber,
            flight_number: gtmData?.flightNumber
          }
        },
        bookingCargoSuccessPDF: {
          eventName: "booking_cargoauto_success_PDF",
          property: {
            cargo_number: gtmData?.cargoNumber,
            flight_number: gtmData?.flightNumber
          }
        },
        bookingCargoSuccessLabel: {
          eventName: "booking_cargoauto_success_label",
          property: {
            cargo_number: gtmData?.cargoNumber,
            flight_number: gtmData?.flightNumber
          }
        },
        bookingCargoFailed: {
          eventName: "booking_cargoauto_failed",
          property: {
            error_message: "Cargo gagal dibuat"
          }
        }
      };
      break;
    case "ship":
      result = {
        type: {
          eventName: "cargo_type_ship",
          property: {
            cargo_ship: "Kereta (booking manual)"
          }
        },
        detailView: {
          eventName: "page_view_ship",
          property: {
            detail_cargo_form: ""
          }
        },
        inputSttSuccess: {
          eventName: "input_STT_success_ship",
          property: {
            success_message: "STT berhasil di input"
          }
        },
        inputSttFailed: {
          eventName: "input_STT_failed_ship",
          property: {
            error_message: "STT gagal di input"
          }
        },
        deleteSttSuccess: {
          eventName: "cargoship_delete_STT",
          property: {
            success_message: "STT berhasil di hapus"
          }
        },
        bookingCargoSuccess: {
          eventName: "booking_cargoship_success",
          property: {
            success_message: "Cargo berhasil dibuat",
            cargo_number: gtmData?.cargoNumber
          }
        },
        bookingCargoSuccessPDF: {
          eventName: "booking_cargoship_success_PDF",
          property: {
            cargo_number: gtmData?.cargoNumber
          }
        },
        bookingCargoSuccessLabel: {
          eventName: "booking_cargoship_success_label",
          property: {
            cargo_number: gtmData?.cargoNumber
          }
        },
        bookingCargoFailed: {
          eventName: "booking_cargoship_failed",
          property: {
            error_message: "Cargo gagal dibuat"
          }
        }
      };
      break;
    default:
      break;
  }

  return result;
};
