import { PickupManifestApiRepository } from "@/app/infrastructures/repositories/api/PickupManifestApiRepository";
import { injectable } from "tsyringe";
import {
  UpdatePickupRequestInterface,
  AddDriverRequestInterface
} from "@/data/payload/contracts/PickupManifestRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  PickupManifestEntities,
  Drivers,
  STTPiecesDetail,
  PrintDataList,
  DetailSTT,
  SttListChecklistEntities
} from "@/domain/entities/PickupManifest";

@injectable()
export class PickupManifestPresenter {
  private repository: PickupManifestApiRepository;

  constructor(repository: PickupManifestApiRepository) {
    this.repository = repository;
  }

  public addDriver(
    payload: AddDriverRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addDriver(payload);
  }

  public getListPickupManifest(
    page: number,
    limit: number,
    startDate: string,
    endDate: string
  ): Promise<PickupManifestEntities> {
    return this.repository.getListPickupManifest(
      page,
      limit,
      startDate,
      endDate
    );
  }
  public getDrivers(): Promise<Drivers[]> {
    return this.repository.getDrivers();
  }

  public getDetailSTTPieces(sttPiece: string): Promise<STTPiecesDetail> {
    return this.repository.getDetailSTTPieces(sttPiece);
  }

  public validationScanSTTPiece(sttPiece: string): Promise<ResponsePayload> {
    return this.repository.validationScanSTTPiece(sttPiece);
  }

  public getPrintDataList(id: number): Promise<PrintDataList> {
    return this.repository.getPrintDataList(id);
  }

  public updatePickup(
    payload: UpdatePickupRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.updatePickup(payload);
  }

  public getDetailStt(sttNumber: string): Promise<DetailSTT> {
    return this.repository.getDetailStt(sttNumber);
  }

  public getSttListChecklist(
    page: number,
    limit: number,
    status: string,
    date: string,
    sortBy: string,
    productType: string,
    cache: boolean
  ): Promise<SttListChecklistEntities> {
    return this.repository.getSttListChecklist(
      page,
      limit,
      status,
      date,
      sortBy,
      productType,
      cache
    );
  }
}
