import {
  CreateInstantBookingSessionApiRequest,
  SaveUserConsentInstantBookingApiRequest,
  UpdateSessionInstantBookingApiRequest
} from "@/data/payload/api/InstantBookingApiRequest";
import { InstantBookingMapper } from "@/data/persistences/mappers/InstantBookingMapper";
import { InstantBookingRepositoryInterface } from "@/data/persistences/repositories/contracts/InstantBookingRepositoryInterface";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { DetailInstantBookingData } from "@/domain/entities/InstantBooking";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import {
  InstantBookingRequestInterface,
  CreateInstantBookingSessionRequestInteface
} from "@/data/payload/contracts/InstantBookingRequest";

export class InstantBookingApiRepository
  implements InstantBookingRepositoryInterface {
  private service: ApiService;
  private mapper: InstantBookingMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: InstantBookingMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async getDetail(): Promise<DetailInstantBookingData> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getDetailInstantBooking()
    );

    return this.mapper.convertDetailInstantBookingFromApi(res);
  }

  public async createInstantBookingSession(
    payload: CreateInstantBookingSessionRequestInteface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.createInstantBookingSession(),
      undefined,
      payload as CreateInstantBookingSessionApiRequest
    );
    return this.mapper.convertCreateInstantBookingSessionFromApi(resp);
  }

  async updateSession(
    payload: InstantBookingRequestInterface
  ): Promise<ResponsePayload> {
    const res = await this.service.invoke(
      "PUT",
      this.endpoints.updateSessionInstantBooking(),
      undefined,
      payload as UpdateSessionInstantBookingApiRequest
    );
    return this.mapper.convertPayloadData(res);
  }

  async saveUserConsent(
    payload: SaveUserConsentInstantBookingApiRequest
  ): Promise<ResponsePayload> {
    const res = await this.service.invoke(
      "POST",
      this.endpoints.saveUserConsent(),
      undefined,
      payload
    );

    return this.mapper.convertPayloadData(res);
  }
}
