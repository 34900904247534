import { TopupFeeConfigurationPresenter } from "@/app/ui/presenters/TopupFeeConfigurationPresenter";
import { TopupFeeConfigurationMapper } from "@/data/persistences/mappers/TopupFeeConfigurationMapper";
import { container } from "tsyringe";
import { Endpoints } from "../../misc/Endpoints";
import { TopupFeeConfigurationApiRepository } from "../../repositories/api/TopupFeeConfigurationApiRepository";
import ApiService from "../../services/ApiService";

export class TopupFeeConfigurationComponent {
    public static init() {
        container.register<TopupFeeConfigurationApiRepository>(TopupFeeConfigurationApiRepository, {
            useFactory: d => {
                return new TopupFeeConfigurationApiRepository(
                    d.resolve(ApiService),
                    d.resolve(TopupFeeConfigurationMapper),
                    d.resolve(Endpoints)
                );
            }
        });
        container.register<TopupFeeConfigurationMapper>(TopupFeeConfigurationMapper, {
            useClass: TopupFeeConfigurationMapper
        });
        container.register<TopupFeeConfigurationPresenter>(TopupFeeConfigurationPresenter, {
            useFactory: d => {
                return new TopupFeeConfigurationPresenter(d.resolve(TopupFeeConfigurationApiRepository));
            }
        });
    }
}
