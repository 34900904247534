/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagPermissionUrgentDeliveryManagement = {
    permission_urgent_delivery_management_pod_enable: new Rox.Flag(false),
    permission_urgent_delivery_management_rts_enable: new Rox.Flag(false),
    permission_urgent_delivery_management_hal_enable: new Rox.Flag(false)
};

export function initFlagPermissionUrgentDeliveryManagement() {
  Rox.register("featureUrgentDeliveryManagement", FlagPermissionUrgentDeliveryManagement);
}
