import { injectable } from "tsyringe";
import { UrgentDeliveryManagementRepositoryInterface } from "@/data/persistences/repositories/contracts/UrgentDeliveryManagementRepositoryInterface";
import { UrgentDeliveryManagementApiRepository } from "@/app/infrastructures/repositories/api/UrgentDeliveryManagementApiRepository";
import {
  UrgentDeliveryManagementPODListRequest,
  UrgentDeliveryManagementPODList,
  UrgentDeliveryManagementRTSListRequest,
  UrgentDeliveryManagementRTSList,
  UrgentDeliveryManagementHALListRequest,
  UrgentDeliveryManagementHALList,
  UrgentDeliveryManagementPODListRequestDownload,
  UrgentDeliveryManagementRTSListRequestDownload,
  UrgentDeliveryManagementHALListRequestDownload,
  UrgentDeliveryManagementTakenByRecipientList,
} from "@/domain/entities/UrgentDeliveryManagement";

@injectable()
export class UrgentDeliveryManagementPresenter
  implements UrgentDeliveryManagementRepositoryInterface {
  private repository: UrgentDeliveryManagementApiRepository;

  constructor(repository: UrgentDeliveryManagementApiRepository) {
    this.repository = repository;
  }

  async getPODList(
    params: UrgentDeliveryManagementPODListRequest
  ): Promise<UrgentDeliveryManagementPODList> {
    return this.repository.getPODList(params);
  }

  async downloadUrgentPODList(
    params: UrgentDeliveryManagementPODListRequestDownload
  ): Promise<void> {
    return this.repository.downloadUrgentPODList(params);
  }

  async getRTSList(
    params: UrgentDeliveryManagementRTSListRequest
  ): Promise<UrgentDeliveryManagementRTSList> {
    return this.repository.getRTSList(params);
  }

  async downloadUrgentRTSList(
    params: UrgentDeliveryManagementRTSListRequestDownload
  ): Promise<void> {
    return this.repository.downloadUrgentRTSList(params);
  }

  async getHALList(
    params: UrgentDeliveryManagementHALListRequest
  ): Promise<UrgentDeliveryManagementHALList> {
    return this.repository.getHALList(params);
  }

  public getUrgentDeliveryManagementTakenRecipient(
    params: UrgentDeliveryManagementHALListRequest
  ): Promise<UrgentDeliveryManagementTakenByRecipientList> {
    return this.repository.getUrgentDeliveryManagementTakenRecipient(params);
  }

  async downloadUrgentHALList(
    params: UrgentDeliveryManagementHALListRequestDownload
  ): Promise<void> {
    return this.repository.downloadUrgentHALList(params);
  }
}
