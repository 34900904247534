import {
  AuthEntities,
  TokenVerificationAccountEntities
} from "@/domain/entities/Auth";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class AuthDataMapper {
  public convertAuthDataFromApi(result: AxiosResponse<any>): AuthEntities {
    const { data } = result;
    return new AuthEntities({
      token:  data.data.token,
      type: data.data.type,
      expiredAt: data.data.expired_at
    })
  }

  public convertForgotPasswordDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertResetPasswordDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertEditPasswordDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertLogoutDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertVerificationAcountDataFromApi(
    result: AxiosResponse<any>
  ): TokenVerificationAccountEntities {
    const { data } = result;
    return new TokenVerificationAccountEntities({ token: data.data.token });
  }
}
