import { AxiosResponse } from "axios";
import {
  CollectibleItemCard,
  CollectibleItemData,
  CollectibleItemEntities
} from "@/domain/entities/CollectibleCard";

export class CollectibleCardMapper {
  public convertDataFromApi(result: AxiosResponse): CollectibleItemEntities {
    const { data } = result;
    const golds: CollectibleItemCard[] = [];
    const holograms: CollectibleItemCard[] = [];
    const silvers: CollectibleItemCard[] = [];
    const goldData = data.data.gold || []
    const silverData = data.data.silver || []
    const hologramData = data.data.hologram || []

    goldData.forEach((item: any) => {
        return golds.push(
          new CollectibleItemCard({
            grade: item.grade,
            imageId: item.image_id,
            imageUrl: item.image_url,
            total: item.total,
            claimStatus: item.claim_status
          })
        );
      });

      hologramData.forEach((item: any) => {
        return holograms.push(
          new CollectibleItemCard({
            grade: item.grade,
            imageId: item.image_id,
            imageUrl: item.image_url,
            total: item.total,
            claimStatus: item.claim_status
          })
        );
      });

      silverData.forEach((item: any) => {
        return silvers.push(
          new CollectibleItemCard({
            grade: item.grade,
            imageId: item.image_id,
            imageUrl: item.image_url,
            total: item.total,
            claimStatus: item.claim_status
          })
        );
      });

    const collectibleItemData = new CollectibleItemData({
      gold: golds,
      hologram: holograms,
      silver: silvers
    });
    return new CollectibleItemEntities(collectibleItemData);
  }

  public convertDetailCollectibleCardFromApi(res: AxiosResponse): CollectibleItemCard {
    const { data } = res;

    const cardData = data.data;

    return new CollectibleItemCard({
      grade: cardData.grade,
      imageId: cardData.image_id,
      imageUrl:cardData.image_url,
      total: cardData.total,
      claimStatus: cardData.claim_status
    });
  }
}
