import {
  CommissionsDtpol,
  DeliveryTieringPolicyData,
  DeliveryTieringPolicyEntities
} from "@/domain/entities/DeliveryTieringPolicy";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class DeliveryTieringPolicyMapper {
  public convertDeliveryTieringPolicyListFromApi(
    result: AxiosResponse<any>
  ): DeliveryTieringPolicyEntities {
    const { data } = result;

    if (!data.data.length)
      return new DeliveryTieringPolicyEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    else
      return new DeliveryTieringPolicyEntities(
        new Pagination(data.meta.page, data.meta.limit),
        data.data.map(
          (item: any) =>
            new DeliveryTieringPolicyData(
              item.id,
              item.name,
              item.description,
              "",
              "",
              "",
              item.updated_at,
              item.updated_name,
              []
            )
        )
      );
  }

  public convertDeliveryTieringPolicyDetailFromApi(
    result: AxiosResponse<any>
  ): DeliveryTieringPolicyData {
    const {
      data: { data }
    } = result;

    return new DeliveryTieringPolicyData(
      data.dtpol_product_id,
      data.dtpol_product_name,
      data.dtpol_product_description,
      data.dtpol_product_commission_status,
      data.dtpol_product_created_at,
      data.dtpol_product_created_by,
      data.dtpol_product_updated_at,
      data.dtpol_product_updated_name,
      data.dtpol_product_detail.length
        ? data.dtpol_product_detail.map(
            (item: any) =>
              new CommissionsDtpol(
                item.dtpol_product_id,
                item.dtpol_product_detail_dtpol_product_id,
                item.dtpol_product_detail_tier,
                item.dtpol_product_detail_publish_rate_commission,
                item.dtpol_product_detail_shipping_surcharge_commission,
                item.dtpol_product_detail_cod_commission,
                item.dtpol_product_detail_created_at,
                item.dtpol_product_detail_updated_at
              )
          )
        : []
    );
  }

  public convertDeliveryTieringPolicyEditFromApi(
    result: AxiosResponse
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: data.data
    });
  }
}
