/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionBulkUpload = {
  permission_bulk_upload_add_promo_discount: new Rox.Flag(false),
  permission_bulk_upload_update_promo_discount: new Rox.Flag(false),
  permission_bulk_upload_update_custom_status: new Rox.Flag(false),
  permission_bulk_upload_district_vendor: new Rox.Flag(false),
  permission_bulk_upload_international_document: new Rox.Flag(false),
  config_max_file_district: new Rox.RoxNumber(25000),
  permission_bulk_upload_type_update_retail_base_rate: new Rox.Flag(false)
};

export function initFlagsPermissionBulkUpload() {
  // register flags with namespaces for better grouping
  Rox.register("featureBulkUpload", FlagsPermissionBulkUpload);
}
