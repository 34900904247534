import { FeatureFlagDetail } from "@/domain/entities/Account";

const isUrlUpdateVersion = (keyName: string, defaultVersion = "v1") => {
  const flagData = JSON.parse(localStorage.getItem("FeatureFlag") || "[]");

  if (flagData && flagData.length) {
    const result = flagData.find((key: FeatureFlagDetail) =>
      key.keyName.includes(keyName)
    );
    if (!result) {
      return "v1";
    }

    return result.keyValue ? result.keyName.substr(0, 2) : defaultVersion;
  }
  return "v1";
};
export default isUrlUpdateVersion;
