import { prop } from "vue-class-component";

export const PAYMENT_STATE = {
  LOADING_STATE: "loading",
  SUCCESS_STATE: "success",
  FAILED_STATE: "failed",
  EXPIRED_STATE: "expired",
  ACTIVE_STATE: "active",
  RELOAD_STATE: "reload"
};

export const PAYMENT_METHOD = {
  QR_INDONESIAN_STANDARD: "qr_indonesian_standard",
  CASH: "cash"
};
export const MAX_AMOUNT_QRIS = 5000000;
export const isBillExceedMaxAmount = function(bill: number) {
  return bill > MAX_AMOUNT_QRIS;
};
export const BILL_TYPE_BOOKING = "booking";
export const BILL_TYPE_TAGIHAN = "tagihan";

export class PaymentProps {
  textHeader = prop<string>({
    default: "Pembuatan Booking Berhasil",
    type: String
  });
  textSubHeader = prop<string>({
    default: "",
    type: String
  });
  billType = prop<string>({
    default: BILL_TYPE_BOOKING,
    type: String
  });
}
