import { CodConfigurationMapper } from "@/data/persistences/mappers/CodConfigurationMapper";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { CodConfigurationRepositoryInterface } from "@/data/persistences/repositories/contracts/CodConfigurationRepositoryInterface";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { DetailCodConfigurationRetail } from "@/domain/entities/CodConfiguration";
import { SubmitCodConfigurationApiRequest } from "@/data/payload/api/CodConfigurationApiRequest";

export class CodConfigurationApiRepository
  implements CodConfigurationRepositoryInterface {
  private service: ApiService;
  private mapper: CodConfigurationMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: CodConfigurationMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getList(params: ApiRequestList): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getCodConfigurationList(params),
      {}
    );
    return this.mapper.convertListDataFromApi(resp);
  }

  public async getDetailCodConfiguration(id: number): Promise<DetailCodConfigurationRetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getCodConfigurationDetail(id),
      {}
    );
    return this.mapper.convertDetailCodConfigurationRetailFromApi(resp);
  }

  public async editCodConfiguration(
    payload: SubmitCodConfigurationApiRequest
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "PATCH",
      this.endpoints.getCodConfigurationDetail(payload.configId),
      {},
      payload
    );
    return this.mapper.convertEditCodConfigurationDataFromApi(resp);
  }
}
