import { TopupFeeConfigurationApiRepository } from "@/app/infrastructures/repositories/api/TopupFeeConfigurationApiRepository";
import { TopupFeeConfigRequest } from "@/data/payload/api/TopupFeeConfigRequest";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { RequestTopupFeeConfigurationList, TopupFeeConfigurationList } from "@/domain/entities/TopupFeeConfiguration";
import { injectable } from "tsyringe";


@injectable()
export class TopupFeeConfigurationPresenter {
    private repository: TopupFeeConfigurationApiRepository;

    constructor(repository: TopupFeeConfigurationApiRepository) {
        this.repository = repository;
    }

    public getDetailTopupFeeConfiguration(id: any): Promise<ResponsePayloadV2> {
        return this.repository.getDetailTopupFeeConfiguration(id);
    }

    public getActiveTopupFeeConfig(id: any): Promise<ResponsePayloadV2> {
        return this.repository.getActiveTopupFeeConfig(id);
    }

    public getTopupFeeConfigurationList(params: RequestTopupFeeConfigurationList): Promise<TopupFeeConfigurationList> {
        return this.repository.getTopupFeeConfigurationList(params);
    }

    public saveConfig(params: TopupFeeConfigRequest): Promise<ResponsePayloadV2> {
        return this.repository.saveConfig(params);
    }

    public updateConfig(id: any, params: TopupFeeConfigRequest): Promise<ResponsePayloadV2> {
        return this.repository.updateConfig(id, params);
    }
}