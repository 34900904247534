import { ConfigPriceCodMapper } from "@/data/persistences/mappers/ConfigPriceCodMapper";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ConfigPriceCodRepositoryInterface } from "@/data/persistences/repositories/contracts/ConfigPriceCodRepositoryInterface";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ConfigPriceCodRequestInterface } from "@/data/payload/contracts/ConfigPriceCodRequest";
import { ConfigPriceCodApiRequest } from "@/data/payload/api/ConfigPriceCodApiRequest";
import {
  ConfigPriceCodDetail,
  ConfigurableCod
} from "@/domain/entities/ConfigPriceCod";

export class ConfigPriceCodApiRepository
  implements ConfigPriceCodRepositoryInterface {
  private service: ApiService;
  private mapper: ConfigPriceCodMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: ConfigPriceCodMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getDetail(id: number): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getConfigPriceCodDetail(id),
      {}
    );
    return this.mapper.convertDetailDataFromApi(resp);
  }

  public async edit(
    payload: ConfigPriceCodRequestInterface
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "PUT",
      this.endpoints.editConfigurablePrice(),
      undefined,
      payload as ConfigPriceCodApiRequest
    );
    return this.mapper.convertFormDataFromApi(resp);
  }
}
