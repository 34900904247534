import { Pagination } from "@/domain/entities/Pagination";
import {
  CATEGORY_CLAIM,
  STATUS_CLAIM
} from "@/app/ui/views/claim/claim-constants";
import normalizePhoneNumber from "@/app/infrastructures/misc/common-library/NormalizePhoneNumber";

export class ClaimEntities {
  pagination: Pagination;
  data: ClaimData[];
  constructor(pagination: Pagination, data: ClaimData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ClaimData {
  noResi = "";
  shipmentId = "";
  sttId = 0;
  productType = "";
  category = "";
  status = "";
  statusDate = "";

  constructor(fields?: Partial<ClaimData>) {
    Object.assign(this, fields);
  }

  get statusName() {
    return (
      STATUS_CLAIM.find((e: any) => e.value === this.status.toLowerCase())
        ?.name || this.status
    );
  }
  get categoryName() {
    return (
      CATEGORY_CLAIM.find((e: any) => e.value === this.category.toLowerCase())
        ?.name || this.category
    );
  }
}
export class ClaimHistoryData {
  claimStatus = "";
  claimCreatedAt = "";
  claimDescription = "";
  constructor(fields?: Partial<ClaimHistoryData>) {
    Object.assign(this, fields);
  }
}

export class SttPiecesData {
  sttPiecesLength = 0;
  sttPiecesWidth = 0;
  sttPiecesHeigth = 0;
  sttPiecesGrossWeight = 0;
  sttPiecesVolumeWeight = 0;
  sttPiecesNo = 0;
  sttPiecesLastStatus = "";
  constructor(fields?: Partial<SttPiecesData>) {
    Object.assign(this, fields);
  }
}
export class ClaimDetailData {
  sttId = 0;
  sttNo = "";
  sttShipmentId = "";
  sttClientId = 0;
  sttPosId = 0;
  sttTaxNumber = "";
  sttGoodsEstimatePrice = 0;
  sttGoodsStatus = "";
  sttTotalAmount = 0;
  sttNoRefExternal = "";
  sttSource = "";
  sttOriginCityId = "";
  sttDestinationId = "";
  sttOriginDistrictId = "";
  sttDestinationDistrictId = "";
  sttSenderName = "";
  sttSenderPhone = "";
  sttSenderAddress = "";
  sttRecipientName = "";
  sttRecipientPhone = "";
  sttRecipientAddress = "";
  sttRecipientAddressType = "";
  sttProductType = "";
  sttOriginDistrictRate = 0;
  sttDestinationDistrictRate = 0;
  sttPublichRate = 0;
  sttShippingSurchargeRate = 0;
  sttHeavyweightSurchargeRate = 0;
  sttCommoditySurchargeRate = 0;
  sttDocumentSurchargeRate = 0;
  sttInsuranceRate = 0;
  sttBmTaxRate = 0;
  sttPpnTaxRate = 0;
  sttPphTaxRate = 0;
  sttGrossWeight = 0;
  sttVolumeWeight = 0;
  sttChargeableWeight = 0;
  sttCommodityCode = "";
  sttInsuranceType = "";
  sttTotalPiece = 0;
  sttWarningStatus = "";
  sttCounter = 0;
  sttLastStatusId = "";
  sttClientSttId = "";
  sttVendorId = "";
  sttBilledTo = "";
  sttCodAmount = 0;
  sttCodFee = 0;
  sttIsCod = false;
  sttIsDo = false;
  sttCreatedAt = "";
  sttCreatedBy = "";
  sttUpdatedAt = "";
  sttUpdatedBy = "";
  sttCommodityName = "";
  sttOriginCityName = "";
  sttDestinationCityName = "";
  sttOriginDistrictName = "";
  sttDestinationDistrictName = "";
  sttBookedFor = "";
  sttCityRate = 0;
  sttForwardRate = 0;
  sttPieces: SttPiecesData[] = [];
  claimHistory: ClaimHistoryData[] = [];
  constructor(fields?: Partial<ClaimDetailData>) {
    Object.assign(this, fields);
  }

  get normalizedSenderPhone() {
    return normalizePhoneNumber(this.sttSenderPhone);
  }
  get normalizedRecipientPhone() {
    return normalizePhoneNumber(this.sttSenderPhone);
  }
}

export class RequestCreateClaim {
  packageId = "";
  customerEmail = "";
  claimType = "";
  customerPhoneNumber = "";
  claimerType = "";
  bankAccountImage = "";
  ktpImage = "";
  invoiceImage = "";
  packageImage1 = "";
  packageImage2 = "";
  packageImage3 = "";
  packageImage4 = "";
  packageImage5 = "";
  packageImage6 = "";
  constructor(fields?: Partial<RequestCreateClaim>) {
    Object.assign(this, fields);
  }
}
