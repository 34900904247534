import { AccountDataMapper } from "@/data/persistences/mappers/AccountMapper";
import {
  AccountEntities,
  DashboardDeliveryPos,
  ValidationAccountData
} from "@/domain/entities/Account";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { AccountRepositoryInterface } from "@/data/persistences/repositories/contracts/AccountRepositoryInterface";
import {
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";
import {
  EditEmailAccountRequest,
  UnpairTokenRequest,
  PairTokenRequest,
  DashboardDeliveryRequest,
  PostCsAccountApiRequest
} from "@/data/payload/api/AccountRequest";
import { AccountRequestInterface } from "@/data/payload/contracts/AccountRequest";

export class AccountApiRepository implements AccountRepositoryInterface {
  private service: ApiService;
  private mapper: AccountDataMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: AccountDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async unpairToken(
    payload: AccountRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.unpairToken(),
      {},
      payload as UnpairTokenRequest
    );
    return this.mapper.convertResponseUnpairTokenFromApi(resp);
  }

  public async getProfile(
    isLocation?: boolean,
    cache?: boolean
  ): Promise<AccountEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getProfile(isLocation, cache),
      {}
    );
    return this.mapper.convertAccountDataFromApi(resp);
  }

  public async pairToken(
    payload: AccountRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.pairToken(),
      {},
      payload as PairTokenRequest
    );
    return this.mapper.convertResponsePairTokenFromApi(resp);
  }

  public async getDashboardDelivery(
    params: DashboardDeliveryRequest
  ): Promise<DashboardDeliveryPos> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDashboardDelivery("", params.toQueryString()),
      {}
    );
    return this.mapper.convertDashboardDeliveryPosDataFromApi(resp);
  }

  public async editEmailAccount(
    payload: AccountRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "put",
      this.endpoints.editEmailAccount(),
      {},
      payload as EditEmailAccountRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async getValidationAccount(): Promise<ValidationAccountData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getValidationAccount(),
      {}
    );
    return this.mapper.convertValidationAccountDataFromApi(resp);
  }

  public async getAccountStatus(accountId: number): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getAccountStatus(accountId),
      {}
    );
    return this.mapper.convertAccountStatusFromApi(resp);
  }

  public async postCsAccountLog(
    payload: AccountRequestInterface
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.postCsAccountLog(),
      undefined,
      payload as PostCsAccountApiRequest
    );
    return this.mapper.convertFormDataFromApi(resp);
  }
}
