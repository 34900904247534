import { injectable } from "tsyringe";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ConfigPriceCodRequestInterface } from "@/data/payload/contracts/ConfigPriceCodRequest";
import { ConfigPriceCodApiRepository } from "@/app/infrastructures/repositories/api/ConfigPriceCodApiRepository";

@injectable()
export class ConfigPriceCodPresenter {
  private repository: ConfigPriceCodApiRepository;

  constructor(repository: ConfigPriceCodApiRepository) {
    this.repository = repository;
  }

  public getDetail(id: number): Promise<ResponsePayloadV2> {
    return this.repository.getDetail(id);
  }

  public edit(
    payload: ConfigPriceCodRequestInterface
  ): Promise<ResponsePayloadV2> {
    return this.repository.edit(payload);
  }
}
