import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { RefundConfigurationRepositoryInterface } from "@/data/persistences/repositories/contracts/RefundConfigurationRepositoryInterface";
import { RefundConfigurationMapper } from "@/data/persistences/mappers/RefundConfigurationMapper";
import { RefundConfigurationData } from "@/domain/entities/RefundConfiguration";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { RefundConfigurationRequestInterface } from "@/data/payload/contracts/RefundConfiguration";
import { RefundConfigurationApiRequest } from "@/data/payload/api/RefundConfigurationApiRequest";

export class RefundConfigurationApiRepository
  implements RefundConfigurationRepositoryInterface {
  private service: ApiService;
  private mapper: RefundConfigurationMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: RefundConfigurationMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getDetailRefundConfiguration(
    id: number
  ): Promise<RefundConfigurationData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailRefundConfiguration(id),
      {}
    );
    return this.mapper.convertDetailRefundConfigurationFromApi(resp);
  }

  public async editRefundConfiguration(
    payload: RefundConfigurationRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivate(
      "put",
      this.endpoints.editConfigurablePrice(),
      undefined,
      payload as RefundConfigurationApiRequest
    );
    return this.mapper.convertPayloadData(resp);
  }
}
