/* eslint-disable @typescript-eslint/camelcase */
import {
  VuexModule,
  Module,
  Action,
  getModule,
  Mutation
} from "vuex-module-decorators";
import store from "@/store";
import { MainAppController } from "./MainAppController";
import { container } from "tsyringe";
import { ReportPresenter } from "../presenters/ReportPresenter";
import {
  ReportApiRequest,
  ReportCheckData,
  ReportListDownload,
  ReportListDownloadRequest,
  LastSyncSttReport
} from "@/domain/entities/Report";
import { Pagination } from "@/domain/entities/Pagination";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

@Module({ namespaced: true, dynamic: true, store, name: "report" })
class ReportStore extends VuexModule {
  isLoading = false;
  isOpenDownloadRequest = false;
  listDownloadReport = new ReportListDownload(new Pagination(1, 20), []);
  lastSync = new LastSyncSttReport();
  lastSyncHasClicked = false;
  errorLastSync = false;

  @Action
  getDownloadReport(params: ReportApiRequest) {
    MainAppController.showLoading();
    const presenter = container.resolve(ReportPresenter);
    return presenter
      .getDownloadReport(params)
      .then((res: ReportCheckData) => {
        if (params.async) {
          this.onGetListDownloadReport();
          this.setOpenDownloadRequest(true);
        }
        return res;
      })
      .catch(err => {
        if (params.async) {
          MainAppController.showErrorMessage(
            parsingErrorResponse(err, "Generate Laporan Gagal !", () =>
              this.getDownloadReport(params)
            )
          );
        }
        return new ReportCheckData({
          code: err.response.data.code,
          message: err.response.data.message.id
        });
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  onGetListDownloadReport() {
    this.getListDownloadReport(
      new ReportListDownloadRequest({
        page: this.listDownloadReport.pagination.page,
        limit: this.listDownloadReport.pagination.limit
      })
    );
  }

  @Action
  getListDownloadReport(params: ReportListDownloadRequest) {
    this.setLoading(true);
    const presenter = container.resolve(ReportPresenter);
    return presenter
      .getListDownloadReport(params)
      .then(res => {
        this.setListDownloadReport(res);
      })
      .catch(() => {
        this.setListDownloadReport(
          new ReportListDownload(new Pagination(1, 20), [])
        );
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  async getLastSyncSttReport() {
    this.setLoading(true);
    const presenter = container.resolve(ReportPresenter);
    return presenter
      .getLastSyncSttReport()
      .then((res: LastSyncSttReport) => {
        this.setLastSyncReportStt(res);
        this.setErrorLastSync(false);
      })
      .then(() => this.setLastSyncHasClicked(true))
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            err,
            "Gagal memuat data sync terakhir pada<br />Laporan STT !",
            () => this.getLastSyncSttReport()
          )
        );
        this.setLastSyncHasClicked(false);
        this.setErrorLastSync(true);
      })
      .finally(() => this.setLoading(false));
  }

  @Mutation
  setLastSyncReportStt(lastSync: LastSyncSttReport) {
    this.lastSync = lastSync;
  }

  @Mutation
  setLastSyncHasClicked(status: boolean) {
    this.lastSyncHasClicked = status;
  }

  @Mutation
  setErrorLastSync(status: boolean) {
    this.errorLastSync = status;
  }

  @Mutation
  setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  setListDownloadReport(val: ReportListDownload) {
    this.listDownloadReport = val;
  }

  @Mutation
  setOpenDownloadRequest(val: boolean) {
    this.isOpenDownloadRequest = val;
  }
}

export const ReportController = getModule(ReportStore);
