import { DeliveryTieringPolicyApiRepository } from "@/app/infrastructures/repositories/api/DeliveryTieringPolicyApiRepository";
import { DeliveryTieringPolicyRequest } from "@/data/payload/contracts/DeliveryTieringPolicyRequest";
import {
  DeliveryTieringPolicyData,
  DeliveryTieringPolicyEntities
} from "@/domain/entities/DeliveryTieringPolicy";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { injectable } from "tsyringe";

@injectable()
export class DeliveryTieringPolicyPresenter {
  private repository: DeliveryTieringPolicyApiRepository;

  constructor(repository: DeliveryTieringPolicyApiRepository) {
    this.repository = repository;
  }

  public getDeliveryTieringPolicyList(
    page: number,
    limit: number,
    search: string
  ): Promise<DeliveryTieringPolicyEntities> {
    return this.repository.getDeliveryTieringPolicyList(page, limit, search);
  }

  public getDeliveryTieringPolicyDetail(
    id: number
  ): Promise<DeliveryTieringPolicyData> {
    return this.repository.getDeliveryTieringPolicyDetail(id);
  }

  public editDeliveryTieringPolicyDetail(
    payload: DeliveryTieringPolicyRequest
  ): Promise<ResponsePayload> {
    return this.repository.editDeliveryTieringPolicyDetail(payload);
  }
}
