import {
  AnnouncementTarget,
  NotificationData,
  NotificationsEntities,
  UnreadData
} from "@/domain/entities/Notification";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import parsingLinkGcpToAws from "@/app/infrastructures/misc/common-library/ParsingLinkGcpToAws";

export class NotificationDataMapper {
  public convertUnreadDataFromApi(result: AxiosResponse<any>): UnreadData {
    const {
      data: { data }
    } = result;
    const res: Array<NotificationData> = [];
    if (!data) return new UnreadData(0, []);
    else {
      data.unread_notifications.map((item: any) => {
        res.push(
          new NotificationData(
            item.ID,
            item.CreatedAt,
            item.UpdatedAt,
            item.DeletedAt,
            item.name,
            item.status,
            item.description,
            parsingLinkGcpToAws(item.attachment),
            parsingLinkGcpToAws(item.banner_image),
            item.pinned,
            item.created_by,
            item.announcement_target.map((e: any) => {
              new AnnouncementTarget(
                e.ID,
                e.CreatedAt,
                e.UpdatedAt,
                e.DeletedAt,
                e.announcement_id,
                e.userTarget
              );
            }),
            item.released_at,
            item.read
          )
        );
      });
      return new UnreadData(data.unread, res);
    }
  }

  public convertNotificationDataFromApi(
    result: AxiosResponse<any>
  ): NotificationsEntities {
    const {
      data: { data }
    } = result;
    const primaryData: Array<NotificationData> = [];
    const arrData: Array<NotificationData> = [];
    if (!data) return new NotificationsEntities([], []);
    else {
      data.primary_notifications.map((e: any) => {
        primaryData.push(
          new NotificationData(
            e.id,
            e.created_at,
            e.updated_at,
            e.deleted_at,
            e.name,
            e.status,
            e.description,
            parsingLinkGcpToAws(e.attachment),
            parsingLinkGcpToAws(e.banner_image),
            e.pinned,
            e.created_by,
            e.announcement_target,
            e.released_at,
            e.read
          )
        );
      });
      data.notifications.map((e: any) => {
        arrData.push(
          new NotificationData(
            e.id,
            e.created_at,
            e.updated_at,
            e.deleted_at,
            e.name,
            e.status,
            e.description,
            parsingLinkGcpToAws(e.attachment),
            parsingLinkGcpToAws(e.banner_image),
            e.pinned,
            e.created_by,
            e.announcement_target,
            e.released_at,
            e.read
          )
        );
      });
    }
    return new NotificationsEntities(primaryData, arrData);
  }

  public convertDetailNotificationDataFromApi(
    result: AxiosResponse<any>
  ): NotificationData {
    const {
      data: { data }
    } = result;
    return new NotificationData(
      data.id,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.name,
      data.status,
      data.description,
      parsingLinkGcpToAws(data.attachment),
      parsingLinkGcpToAws(data.banner_image),
      data.pinned,
      data.created_by,
      data.announcement_target.map((e: any) => {
        new AnnouncementTarget(0, "", "", "", 0, e.user_target);
      }),
      data.released_at,
      data.read
    );
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
