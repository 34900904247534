import { COD_RETAIL_CONFIGURATION } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const DetailCODRetailConfiguration = () =>
  import(
    /* webpackChunkName: "CODRetailConfig" */ "@/app/ui/views/pricing/cod-retail-configuration/component/detail.vue"
  );
const EditCODRetailConfiguration = () =>
  import(
    /* webpackChunkName: "CODRetailConfig" */ "@/app/ui/views/pricing/cod-retail-configuration/component/edit.vue"
  );

export default [
  {
    path: "/tarif/konfigurasi-cod-retail",
    component: Layout,
    name: "cod-retail-configuration-detail",
    meta: {
      title: "Konfigurasi COD | Detail",
      hidden: true,
      parent: "Konfigurasi COD",
      permission: COD_RETAIL_CONFIGURATION.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-cod-retail-configuration",
        component: DetailCODRetailConfiguration,
        meta: {
          header: true,
          isAuth: true
        }
      }
    ]
  },
  {
    path: "/tarif/konfigurasi-cod-retail",
    component: Layout,
    name: "cod-retail-configuration-edit",
    meta: {
      title: "Konfigurasi COD | Edit",
      hidden: true,
      parent: "Konfigurasi COD",
      permission: COD_RETAIL_CONFIGURATION.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-cod-retail-configuration",
        component: EditCODRetailConfiguration,
        meta: {
          header: true,
          title: "Konfigurasi COD | Edit",
          name: "Ubah COD Retail",
          before: "/tarif/cod/:id",
          isAuth: true
        }
      }
    ]
  }
];
