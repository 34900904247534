/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionClient = {
  permission_client_enable: new Rox.Flag(false),
  permission_client_management_detail_view: new Rox.Flag(false),
  permission_client_management_detail_edit: new Rox.Flag(false),
  permission_client_management_add: new Rox.Flag(false),
  permission_client_management_detail_renewal_approval: new Rox.Flag(false),
  permission_client_registration_detail_view: new Rox.Flag(false),
  permission_client_registration_detail_edit: new Rox.Flag(false),
  permission_client_registration_add: new Rox.Flag(false),
  permission_client_registration_detail_renewal_approval: new Rox.Flag(false),
  permission_client_management_renew_rate: new Rox.Flag(true),
  permission_client_management_update_live_rate: new Rox.Flag(true),
  client_v2_enable: new Rox.Flag(false),
};

export function initFlagsPermissionClient() {
  // register flags with namespaces for better grouping
  Rox.register("featureClient", FlagsPermissionClient);
}
