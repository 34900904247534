import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { TopupFeeConfigRequestInterface } from "../contracts/TopupFeeConfigRequest";

export class TopupFeeConfigRequest implements TopupFeeConfigRequestInterface {
    ctufAccountType = "";
    ctufIsAllPos = false;
    ctufPartnerIds: Array<number> = [];
    ctufTopUpFee: any = 0;
    ctufStatus = ""
    constructor(fields?: Partial<TopupFeeConfigRequest>) {
        Object.assign(this, fields);
    }
    toJSON(): string {
        return JSON.stringify(ConvertObjectCamelToSnakeCase(this))
    }
}