import { Pagination } from "./Pagination";
import moment from "moment";

export class ProgressiveCommissionList {
  pagination: Pagination;
  data: ProgressiveCommissionListData[];
  constructor(pagination: Pagination, data: ProgressiveCommissionListData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ProgressiveCommissionListData {
  cpcId = 0;
  cpcName = "";
  cpcStartDate = "";
  cpcEndDate = "";
  cpcAppliedTo = "";
  cpcTermCondition = "";
  cpcBookingSource = "";
  cpcShipmentPrefix = "";
  cpcReference = "";
  cpcCreatedAt = "";
  cpcCreatedBy = 0;
  cpcUpdatedAt = "";
  cpcUpdatedBy = 0;
  cpcDetail: ProgressiveCommissionListTier[] = [];
  cpcCreatedName = "";
  cpcStatus = "";

  constructor(fields?: Partial<ProgressiveCommissionListData>) {
    Object.assign(this, fields);
  }
}

export class ProgressiveCommissionListTier {
  cpcdId = 0;
  cpcdMilestoneId = 0;
  cpcdMilestoneName = "";
  cpcdMilestoneLimit = 0;
  cpcdCommissionAdditional = 0;
  cpcdCpcId = 0;

  constructor(fields?: Partial<ProgressiveCommissionListTier>) {
    Object.assign(this, fields);
  }
}

export class ProgressiveCommissionDetail {
  programId = 0;
  startDate = "";
  endDate = "";
  programApplied = "";
  programUsedFor: any[] = [];
  programPrefix = [];
  parameterCalculation = [];
  mileStones: ProgressiveCommissionMilestone[] = [];
  termCondition = "";
  createdAt = "";
  createdBy = "";
  updatedAt = "";
  updatedBy = "";
  status = "";
  termAndCondition = "";

  constructor(fields?: Partial<ProgressiveCommissionDetail>) {
    Object.assign(this, fields);
  }
}

export class ProgressiveCommissionMilestone {
  id = 0;
  limit = 0;
  commission = 0;

  constructor(fields?: Partial<ProgressiveCommissionMilestone>) {
    Object.assign(this, fields);
  }
}

export class FormProgressiveCommissionConfiguration {
  id = 0;
  startDate: any = null;
  endDate: any = null;
  termsAndConditions = "";
  commissionAppliedTo: string[] = [];
  commissionShipmentPrefix: string[] = [];
  commissionParameter = {
    name: "",
    value: ""
  };
  milestones: Array<any> = [
    {
      idMilestone: 0,
      milestone: "",
      commission: ""
    }
  ];

  constructor(fields?: Partial<FormProgressiveCommissionConfiguration>) {
    Object.assign(this, fields);
  }

  parsedPrefix(): string {
    return this.commissionShipmentPrefix.join(",");
  }
  parsedAppliedTo(): string {
    return this.commissionAppliedTo.join(",");
  }
  parsedStartDateToString(): string {
    return moment(this.startDate).format("YYYY-MM-DD");
  }
  parsedEndDateToString(): string {
    return moment(this.endDate).format("YYYY-MM-DD");
  }
}
