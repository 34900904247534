import {
    RequestCustomerList
} from "@/domain/entities/Customer";

export class CustomerManagementEndpoints {
    getCustomerList(params: RequestCustomerList) {
        return `/horde/v2/customer?${params.toQueryString()}`;
    }

    addCustomer(): string {
      return `/horde/v1/customer`;
    }

    getCustomerDetail(id: number): string {
        return `/horde/v1/customer/${id}`;
    }

    editCustomer(id: number): string {
        return `/horde/v1/customer/${id}`;
      }

    deleteCustomer(id: number): string {
    return `/horde/v1/customer/${id}`;
    }
}