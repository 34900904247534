import { FlagsPermissionTopUpAndCashback } from "@/feature-flags/flags-permission-topup-and-cashback";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const CreateCashbackConfiguration = () =>
  import(
    /* webpackChunkName: "cashback" */ "@/app/ui/views/finance/top-up-and-cashback/cashback/create/index.vue"
  );
const DetailCashbackConfiguration = () =>
  import(
    /* webpackChunkName: "cashback" */ "@/app/ui/views/finance/top-up-and-cashback/cashback/detail/index.vue"
  );
const EditCashbackConfiguration = () =>
  import(
    /* webpackChunkName: "cashback" */ "@/app/ui/views/finance/top-up-and-cashback/cashback/edit/index.vue"
  );
export default [
  {
    path: "/finance/top-up-and-cashback/cashback",
    component: Layout,
    name: "create-cashback-configuration",
    meta: {
      hidden: true,
      parent: "Top Up & Cashback",
      permission:
        FlagsPermissionTopUpAndCashback.permission_finance_cashback_enable,
    },
    children: [
      {
        path: "create",
        name: "create-cashback-configuration",
        component: CreateCashbackConfiguration,
        meta: {
          header: true,
          title: "Cashback Configuration | Create ",
          name: "Buat Konfigurasi Cashback",
          before: "/finance/top-up-and-cashback/cashback",
        },
      },
    ],
  },
  {
    path: "/finance/top-up-and-cashback/cashback",
    name: "detail-cashback-configuration",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Top Up & Cashback",
      permission:
        FlagsPermissionTopUpAndCashback.permission_finance_cashback_enable,
    },
    children: [
      {
        path: ":id",
        name: "detail-cashback-configuration",
        component: DetailCashbackConfiguration,
        meta: {
          title: "Detail Cashback",
          name: "Detail Cashback",
          header: true,
          customPath: true,
        },
      },
    ],
  },
  {
    path: "/finance/top-up-and-cashback/cashback",
    component: Layout,
    name: "edit-cashback",
    meta: {
      hidden: true,
      parent: "Top Up & Cashback",
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-cashback",
        component: EditCashbackConfiguration,
        meta: {
          header: true,
          title: "Ubah Konfigurasi Cashback",
          pathBefore: "Detail Cashback",
          name: "Ubah Konfigurasi Cashback",
          before: "/finance/top-up-and-cashback/cashback/:id",
          customPath: true,
          permission:
            FlagsPermissionTopUpAndCashback.permission_finance_cashback_enable,
        },
      },
    ],
  },
];
