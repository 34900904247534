import {
  BulkBookingCommissionCreateResponse,
  BulkBookingCommissionData,
  BulkBookingCommissionDetail,
  BulkBookingCommissionEntities,
  BulkBookingCommissionCalculationTier
} from "@/domain/entities/BulkBookingCommission";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class BulkBookingCommissionMapper {
  public convertBulkBookingCreateDataFromApi(
    res: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: new BulkBookingCommissionCreateResponse(
        data.data.failed_row,
        data.data.success_row,
        data.data.bulk_id,
        data.data.booking_commission_id
      )
    });
  }

  public convertDataBulkBookingCommissionDetailFromApi(
    result: AxiosResponse
  ): BulkBookingCommissionDetail {
    const {
      data: { data }
    } = result;
    return new BulkBookingCommissionDetail({
      commissionId: data.bc_id,
      commissionCode: data.bc_code,
      commissionBookingSource: data.bc_booking_source,
      commissionFor: data.bc_applied_to,
      commissionShipmentId: data.bc_shipment_prefix,
      commissionTierBase: data.bc_tier_base,
      commissionPriceTierReference: data.bc_tier_price_reference,
      commissionPriceReference: data.bc_commission_reference,
      commissionType: data.bc_commission_type,
      commissionArchiveType: data.bc_bulk.archive_type.replace("-", " "),
      commissionActivityName: data.bc_bulk.activity_name,
      commissionDescription: data.bc_bulk.description,
      commissionStatus: data.bc_bulk.status,
      successFileName: data.bc_bulk.archive_file,
      successFile: data.bc_bulk.archive_file,
      failedFileName: data.bc_bulk.error_log_file_name,
      failedFile: data.bc_bulk.error_log_file,
      commissionQuantifier: data.bc_commission_quantifier,
      commissionCalculationTier: data.bc_tier_bases.map((item: any) => {
        return new BulkBookingCommissionCalculationTier({
          startWeight: item.bc_tier_base_start?.toLocaleString("id"),
          endWeight: item.bc_tier_base_end?.toLocaleString("id"),
          commissionAmount: item.bc_tier_base_commission_amount?.toLocaleString(
            "id"
          ),
          commissionAmountBranch: item.bc_tier_base_branch_commission_amount?.toLocaleString(
            "id"
          ),
          commissionAmountPickup: item.bc_tier_base_pickup_commission_amount?.toLocaleString(
            "id"
          ),
          commissionAmountPickupPublishRate: item.bc_tier_base_pickup_publish_rate_commission_amount?.toLocaleString(
            "id"
          )
        });
      }),
      commissionUpdatedBy: data.bc_updated_name,
      commissionUpdatedAt: data.bc_updated_at,
      commissionCreatedBy: data.bc_created_name,
      commissionCreatedAt: data.bc_created_at
    });
  }

  public convertBulkBokingCommissionDataFromApi(
    res: AxiosResponse<any>
  ): BulkBookingCommissionEntities {
    const { data } = res;
    if (data.data.length === 0) {
      return new BulkBookingCommissionEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      return new BulkBookingCommissionEntities(
        new Pagination(data.meta.page, data.meta.limit),
        data.data.map(
          (item: any) =>
            new BulkBookingCommissionData(
              item.bc_id,
              item.bc_bulk_id,
              item.bc_activity_name,
              item.bc_archive_type.replace("-", " "),
              item.bc_created_at,
              item.bc_created_name,
              item.bc_bulk_status,
              item.bc_archive_file,
              item.bc_archive_file_name,
              item.bc_error_log_file,
              item.bc_error_log_file_name
            )
        )
      );
    }
  }
}
