import {
  ShipmentBookingDetail,
  SttClient,
  SttPieces,
  SttReasonMapping
} from "@/domain/entities/ShipmentBooking";
import { AxiosResponse } from "axios";
import {
  formatInputMoney,
  removeNewLineWhitespace
} from "@/app/infrastructures/misc/Utils";
import { codePhoneCountry } from "@/app/ui/views/shipment/booking/booking-utils";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";

export class UniversalPrintMapper {
  public convertDetailDataFromApi(
    result: AxiosResponse
  ): ShipmentBookingDetail {
    const {
      data: { data }
    } = result;
    const totalRates =
      data.stt_shipping_surcharge_rate +
      data.stt_publish_rate +
      data.stt_destination_district_rate +
      data.stt_origin_district_rate;
    const totalTariff =
      totalRates +
      data.stt_insurance_rate +
      data.stt_total_surcharge +
      data.stt_cod_fee;
    const sender = codePhoneCountry(data.stt_sender_phone);
    const recipient = codePhoneCountry(data.stt_recipient_phone);

    return new ShipmentBookingDetail({
      sttId: data.stt_id,
      sttNo: data.stt_no,
      sttShipmentId: data.stt_shipment_id,
      sttOriginAddress: data.stt_origin_address,
      sttDestinationAddress: data.stt_destination_address,
      sttNoRefExternal: data.stt_no_ref_external,
      sttSenderName: data.stt_sender_name,
      sttSenderPhone: sender.number,
      sttSenderPhoneCode: sender.code,
      sttSenderPhoneFlag: sender.flag,
      sttSenderAddress: removeNewLineWhitespace(data.stt_sender_address, " "),
      sttRecipientName: data.stt_recipient_name,
      sttRecipientPhone: recipient.number,
      sttRecipientPhoneCode: recipient.code,
      sttRecipientPhoneFlag: recipient.flag,
      sttRecipientAddress: removeNewLineWhitespace(
        data.stt_recipient_address,
        " "
      ),
      sttRecipientAddressType: data.stt_recipient_address_type,
      sttCommodityName: data.stt_commodity_name
        ? data.stt_commodity_name
        : FlagsMedusa.config_commodity_general_others_name.getValue(),
      sttCommodityId: data.stt_commodity_id
        ? data.stt_commodity_id
        : FlagsMedusa.config_commodity_general_others_id.getValue(),
      sttCommodityCode: data.stt_commodity_code
        ? data.stt_commodity_code
        : FlagsMedusa.config_commodity_general_others_code.getValue(),
      sttProductTypeName: data.stt_product_type_name,
      sttTotalPiece: data.stt_total_piece,
      sttTotalChargeAbleWeight: data.stt_total_chargeable_weight,
      sttGoodsEstimatePrice: formatInputMoney(
        data.stt_goods_estimate_price ? data.stt_goods_estimate_price : 0
      ),
      sttTaxNumber: data.stt_tax_number,
      sttInsuranceName: data.stt_insurance_name,
      sttBookedBy: data.stt_booked_by,
      sttBilledTo: data.stt_billed_to,
      sttSource: data.stt_source,
      sttCod: data.stt_is_cod ? "Ya" : "Tidak",
      sttReturnCod: data.stt_is_do ? "Ya" : "Tidak",
      sttShipmentPrice: data.stt_shipment_price,
      sttShippingSurchargeRate: data.stt_shipping_surcharge_rate,
      sttDocumentSurchargeRate: data.stt_document_surcharge_rate,
      sttHeavyWeightSurchargeRate: data.stt_heavyweight_surcharge_rate,
      sttInsuranceRate: data.stt_insurance_rate,
      sttWoodPackingRate: data.stt_woodpacking_rate,
      sttTaxRate: data.stt_tax_rate,
      sttCityRate: data.stt_city_rate,
      sttForwardRate: data.stt_forward_rate,
      sttTotalTariff: data.stt_total_tariff,
      sttLastStatus: data.stt_last_status,
      sttLastStatusCounter: data.stt_last_status_counter,
      sttLastStatusDescription: data.stt_last_status_description.replace(
        /\n/g,
        "<br />"
      ),
      sttWarningStatus: data.stt_warning_status,
      sttWarningStatusDescription: data.stt_warning_status_description,
      sttCreatedAt: data.stt_created_at,
      sttCreatedBy: data.stt_created_by,
      sttUpdatedAt: data.stt_updated_at,
      sttUpdatedBy: data.stt_updated_by,
      SttPieces: data.stt_piece.map(
        (item: any) =>
          new SttPieces(
            item.stt_piece_id,
            item.stt_piece_gross_weight,
            {
              length: item.stt_piece_length,
              width: item.stt_piece_width,
              height: item.stt_piece_height
            },
            item.stt_piece_volume_weight,
            "1",
            false, 
            false
          ),
      ),
      sttSenderStatus: data.stt_sender_status
        ? data.stt_sender_status
        : "ecommerce",
      sttWoodpackingRate: data.stt_woodpacking_rate,
      sttColumnDisabled: data.stt_source === "customer-apps",
      sttIsWoodPacking: data.stt_woodpacking_rate > 0,
      sttIsCod: data.stt_is_cod,
      sttOriginCityId: data.stt_origin_city_id,
      sttOriginDistrictId: data.stt_origin_district_id,
      sttDestinationCityId: data.stt_destination_city_id,
      sttDestinationDistrictId: data.stt_destination_district_id,
      sttIsAllowEdit: data.stt_is_allow_edit,
      sttIsAllowCancel: data.stt_is_allow_cancel,
      sttClient: data.stt_client_id
        ? new SttClient({
            clientId: data.stt_client_id,
            clientName: data.stt_booked_for_name
          })
        : new SttClient(),
      sttPiecePerPack: data.stt_piece_per_pack,
      sttNextCommodity: data.stt_next_commodity,
      sttCodAmount: formatInputMoney(data.stt_cod_amount),
      sttCodFee: formatInputMoney(data.stt_cod_fee),
      sttBookedById: data.stt_booked_by_id,
      sttBookedByType: data.stt_booked_by_type,
      sttBookedByCode: data.stt_booked_by_code,
      sttBookedForId: data.stt_booked_for_id,
      sttBookedForType: data.stt_booked_by_type,
      sttBookedForCode: data.stt_booked_for_code,
      sttReasonMapping: new SttReasonMapping({
        reasonCode: data.stt_reason_mapping?.reason_code,
        reasonCreatedAt: data.stt_reason_mapping?.reason_created_at,
        reasonDescription: data.stt_reason_mapping?.reason_description,
        reasonExternalCode: data.stt_reason_mapping?.reason_external_code,
        reasonId: data.stt_reason_mapping?.reason_id,
        reasonTitle: data.stt_reason_mapping?.reason_title,
        reasonUpdatedAt: data.stt_reason_mapping?.reason_updated_at
      }),
      sttShipmentSource: data.stt_shipment_source,
      sttOriginCityName: data.stt_origin_city_name,
      sttOriginCityTransit: data.stt_origin_city_transit,
      sttDestinationCityTransit: data.stt_destination_city_transit,
      sttDestinationDistrictName: data.stt_destination_district_name,
      sttDistrictUrsaCode: data.stt_district_ursa_code,
      sttDistrictType: data.stt_district_type,
      sttDestinationDistrictType: data.stt_destination_district_type,
      sttInformationDistrictType: data.stt_information_district_type,
      sttIsDo: data.stt_is_do,
      sttTotalAmount: data.stt_total_amount,
      sttBmTaxRate: data.stt_bm_tax_rate,
      sttPpnTaxRate: data.stt_ppn_tax_rate,
      sttPphTaxRate: data.stt_pph_tax_rate,
      sttPublishRate: data.stt_publish_rate,
      sttOriginDistrictRate: data.stt_origin_district_rate,
      sttDestinationDistrictRate: data.stt_destination_district_rate,
      sttGrossWeight: data.stt_gross_weight,
      sttVolumeWeight: data.stt_volume_weight,
      sttChargeableWeight: data.sttChargeableWeight,
      estimateSla: data.estimate_sla,
      sttTotalSurcharge: data.stt_total_surcharge,
      sttPieces: data.stt_pieces,
      sttVendorCode: data.stt_vendor_code,
      sttVendorName: data.stt_vendor_name,
      sttVendorRefferenceNo: data.stt_vendor_reference_no,
      otherShipperTicketCode: data.otherShipperTicketCode,
      sttBookedForName: data.stt_booked_for_name,
      totalTariff: totalTariff,
      totalRate: totalRates,
      isDangerousGoods: data.is_dangerous_goods
    });
  }
}
