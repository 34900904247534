/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionOnProcessstt = {
    on_process_stt_enable: new Rox.Flag(false),
    on_process_stt_list_view: new Rox.Flag(false),
    on_process_stt_detail_cancel: new Rox.Flag(false),
    on_process_stt_detail_view: new Rox.Flag(false),
    on_process_stt_detail_edit: new Rox.Flag(false)
};

export function initFlagsPermissionOnProcessstt() {
    // register flags with namespaces for better grouping
    Rox.register("featureOnProcessStt", FlagsPermissionOnProcessstt);
}
