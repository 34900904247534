/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionCodBalance = {
  permission_cod_balance_enable: new Rox.Flag(false),
  permission_cod_balance_withdraw_view_detail: new Rox.Flag(false),
  permission_cod_balance_withdraw_request_detail: new Rox.Flag(false)
};

export function initFlagsPermissionCodBalance() {
  // register flags with namespaces for better grouping
  Rox.register("featureCodBalance", FlagsPermissionCodBalance);
}
