/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagPermissionUrgentDeliveryRequests = {
  permission_urgent_delivery_requests_enable: new Rox.Flag(false)
};

export function initFlagPermissionUrgentDeliveryRequests() {
  Rox.register("featureUrgentDelivery", FlagPermissionUrgentDeliveryRequests);
}
