/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import { LocationPresenter } from "@/app/ui/presenters/LocationPresenter";
import {
  CityEntities,
  CityData,
  CountryEntities,
  CountryData,
  DistrictEntities,
  DistrictData,
  UrsaCodeData,
  RegionEntities,
  ZipCodeEntities
} from "@/domain/entities/Location";
import { Pagination } from "@/domain/entities/Pagination";
import {
  EditCityApiRequest,
  EditCountryApiRequest,
  EditDistrictApiRequest,
  DistrictClientRequest
} from "@/data/payload/api/LocationRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { ApiRequestList } from "@/domain/entities/MainApp";
export interface LocationState {
  cityData: CityEntities;
  cityDetail: CityData;
  countryData: CountryEntities;
  countryDetail: CountryData;
  districtData: DistrictEntities;
  districtDetail: DistrictData;
  districtDetailTariff: DistrictData;
  isLoading: boolean;
  isLoadingDetailCountry: boolean;
  isLoadingDetailCity: boolean;
  isLoadingDetail: boolean;
  isLoadingCountry: boolean;
  isLoadingDistrict: boolean;
  isError: boolean;
  isErrorEdit: boolean;
  isErrorCauseCountry: string;
  isErrorCauseCity: string;
  isErrorCauseDistrict: string;
  message: string;
  type: string;
  isRoute: boolean;
  isFreeTradeZone: boolean;
  cacheRoute: boolean;
  cacheFreeTradeZone: boolean;
  openModal: boolean;
  isCod: boolean;
  cacheCod: boolean;
  ursaCode: string;
  cacheUrsaCode: string;
  code: number;
  cacheType: string;
  openModalSuccess: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "location" })
class LocationStore extends VuexModule implements LocationState {
  public cityData = new CityEntities(new Pagination(1, 10), []);
  public cityDetail = new CityData({});
  public countryData = new CountryEntities(new Pagination(1, 10), []);
  public countryDetail = new CountryData(0, "", "", "", "", "", "", "", "");
  public districtData = new DistrictEntities({
    pagination: new Pagination(1, 10),
    districtData: []
  });
  public districtDetail = new DistrictData();
  public districtDetailTariff = new DistrictData();
  public destinationDistrict = new DistrictData();
  public regionData = new RegionEntities();
  public zipCodeData = new ZipCodeEntities(new Pagination(1, 10), []);
  public isLoading = false;
  public isLoadingDetailCountry = false;
  public isLoadingDetailCity = false;
  public isLoadingDetail = false;
  public isLoadingCountry = false;
  public isLoadingDistrict = false;
  public isLoadingRegion = false;
  public isLoadingZipCode = false;
  public isError = false;
  public isErrorEdit = false;
  public isErrorCauseCountry = "";
  public isErrorCauseCity = "";
  public isErrorCauseDistrict = "";
  public isErrorCauseRegion = "";
  public isErrorCauseZipCode = "";
  public SearchCity = "";
  public SearchCountry = "";
  public SearchDistrict = "";
  public SearchRegion = "";
  public statusDistrict = "";
  public statusCountry = "";
  public statusCity = "";
  public statusRegion = "";
  public pageCity = 1;
  public pageCountry = 1;
  public pageDistrict = 1;
  public pageRegion = 1;
  public limit = 10;
  public message = "";
  public type = "";
  public isRoute = false;
  public isFreeTradeZone = false;
  public cacheRoute = false;
  public cacheFreeTradeZone = false;
  public openModal = false;
  public isCod = false;
  public cacheCod = false;
  public ursaCode = "";
  public cacheUrsaCode = "";
  public code = 0;
  public cacheType = "";
  public openModalSuccess = false;
  public isSundayDelivery = false;
  public areaType = "";

  @Action
  public getCityList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
    freeTradeZone?: string;
    newEndpoint?: boolean;
  }) {
    this.setLoadingList(true);
    const presenter = container.resolve(LocationPresenter);
    return presenter
      .getCityList(
        new ApiRequestList({
          ...params
        })
      )
      .then((res: CityEntities) => {
        this.setCityData(res);
        this.setError(false);
        this.setErrorCauseCity("");
        return res;
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCauseCity(error.response ? "server" : "internet");
        return new CityEntities(new Pagination(1, 10), []);
      })
      .finally(() => {
        this.setLoadingList(false);
      });
  }

  @Action
  public getDetailCity(params: { id: any }) {
    this.setLoadingDetailCity(true);
    const presenter = container.resolve(LocationPresenter);
    presenter
      .getDetailCity(params.id)
      .then((res: CityData) => {
        this.setDetailCity(res);
        this.setRoute(res.isActive.toLowerCase() === "active");
        this.setFreeTradeZone(res.freeTradeZone.toLowerCase() === "yes");
        this.setCacheRoute(res.isActive.toLowerCase() === "active");
        this.setCacheFreeTradeZone(res.freeTradeZone.toLowerCase() === "yes");
        this.setIsSundayDelivery(res.sundayDelivery === "1");
        this.setError(false);
        this.setErrorCauseCity("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCauseCity(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingDetailCity(false);
      });
  }

  @Action
  public getCountryList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
  }) {
    this.setLoadingCountry(true);
    const presenter = container.resolve(LocationPresenter);
    presenter
      .getCountryList(params.search, params.status, params.page, params.limit)
      .then((res: CountryEntities) => {
        this.setCountryData(res);
        this.setError(false);
        this.setErrorCauseCountry("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCauseCountry(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingCountry(false);
      });
  }

  @Action
  public getDetailCountry(params: { id: any }) {
    this.setLoadingDetailCountry(true);
    const presenter = container.resolve(LocationPresenter);
    presenter
      .getDetailCountry(params.id)
      .then((res: CountryData) => {
        this.setDetailCountry(res);
        this.setRoute(res.status.toLowerCase() === "active");
        this.setCacheRoute(res.status.toLowerCase() === "active");
        this.setError(false);
        this.setErrorCauseCountry("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCauseCountry(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingDetailCountry(false);
      });
  }

  @Action
  public getDistrictList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
    excludeCountry?: string;
    type?: string;
    cache?: boolean;
    cityCode?: string;
  }) {
    this.setLoadingDistrict(true);
    this.onResetDistrictData();
    const presenter = container.resolve(LocationPresenter);
    return presenter
      .getDistrictList(
        params.search,
        params.status,
        params.page,
        params.limit,
        params.excludeCountry || "",
        params.type || "",
        params.cache || false,
        params.cityCode
      )
      .then((res: DistrictEntities) => {
        this.setDistrictData(res);
        this.setError(false);
        this.setErrorCauseDistrict("");
        return res.districtData;
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCauseDistrict(error.response ? "server" : "internet");
        return [];
      })
      .finally(() => {
        this.setLoadingDistrict(false);
      });
  }

  @Action
  public getDetailDistrict(params: { id: any; notSaveDetail?: boolean }) {
    this.setLoadingDetail(true);
    MainAppController.showLoading();
    const presenter = container.resolve(LocationPresenter);
    return presenter
      .getDetailDistrict(params.id)
      .then((res: DistrictData) => {
        if (!params.notSaveDetail) {
          this.setDetailDistrict(res);
          this.setUrsaCode(res.ursaCode);
          this.setCacheUrsaCode(res.ursaCode);
          this.setType(res.type);
          this.setAreaType(res.areaType);
          this.setCacheType(res.type);
          this.setRoute(res.status.toLowerCase() === "active");
          this.setCacheRoute(res.status.toLowerCase() === "active");
          this.setCod(res.isCod.toLowerCase() === "yes");
          this.setCacheCod(res.isCod.toLowerCase() === "yes");
          this.setError(false);
          this.setErrorCauseDistrict("");
        }
        return res;
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCauseDistrict(error.response ? "server" : "internet");
        return new DistrictData();
      })
      .finally(() => {
        this.setLoadingDetail(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public async getDetailDistrictTariff(params: { id: any; notSaveDetail?: boolean }) {
    this.setLoadingDetail(true);
    MainAppController.showLoading();
    const presenter = container.resolve(LocationPresenter);
    return presenter
      .getDetailDistrict(params.id)
      .then((res: DistrictData) => {
        if (!params.notSaveDetail) {
          this.setDetailDistrictTariff(res);
          this.setError(false);
          this.setErrorCauseDistrict("");
        }
        return res;
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCauseDistrict(error.response ? "server" : "internet");
        return new DistrictData();
      })
      .finally(() => {
        this.setLoadingDetail(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public getDetailDistrictByDistrictCode(params: { districtCode: string }) {
    this.setLoadingDetail(true);
    this.setEmptyDestinationDistrict();
    const presenter = container.resolve(LocationPresenter);
    return presenter
      .getDetailDistrictByDistrictCode(params.districtCode)
      .then((res: DistrictData) => {
        this.setDestinationDistrict(res);
        this.setError(false);
        this.setErrorCauseDistrict("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCauseDistrict(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingDetail(false);
      });
  }

  @Action
  public getRegionList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
  }) {
    this.setLoadingRegion(true);
    const presenter = container.resolve(LocationPresenter);
    presenter
      .getRegionList(params.search, params.status, params.page, params.limit)
      .then((res: RegionEntities) => {
        this.setRegionData(res);
        this.setPageRegion(res.pagination.page);
        this.setError(false);
        this.setErrorCauseRegion("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCauseRegion(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingRegion(false);
      });
  }

  @Action
  public refreshES() {
    const presenter = container.resolve(LocationPresenter);
    presenter
      .refreshES()
      .then()
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.setLoadingDetail(false);
      });
  }

  @Action
  public async setNextPageCity() {
    await this.nextPageCity();
    this.getCityList({
      search: this.SearchCity,
      status: this.statusCity,
      page: this.pageCity,
      limit: 10
    });
  }

  @Action
  public async setPrevPageCity() {
    if (this.pageCity !== 1) {
      await this.prevPageCity();
      this.getCityList({
        search: this.SearchCity,
        status: this.statusCity,
        page: this.pageCity,
        limit: 10
      });
    }
  }

  @Action
  public setFirstCityPage() {
    this.setFirstPageCity();
  }

  @Action
  public searchCity(value: string) {
    if (value.length > 2 || !value) {
      this.setSearchCity(value);
      this.getCityList({
        search: value,
        status: this.statusCity,
        page: 1,
        limit: 10
      });
    }
  }

  @Action
  public clear() {
    this.searchCity("");
    this.getCityList({
      search: "",
      status: this.statusCity,
      page: 1,
      limit: 10
    });
  }

  @Action
  public selectStatusCity(value: string) {
    this.selectStatus(value);
    this.getCityList({
      search: this.SearchCity,
      status: this.statusCity,
      page: 1,
      limit: 10
    });
  }

  @Action
  public async setNextPageCountry() {
    await this.nextPageCountry();
    this.getCountryList({
      search: this.SearchCountry,
      status: this.statusCountry,
      page: this.pageCountry,
      limit: 10
    });
  }

  @Action
  public async setPrevPageCountry() {
    if (this.pageCountry !== 1) {
      await this.prevPageCountry();
      this.getCountryList({
        search: this.SearchCountry,
        status: this.statusCountry,
        page: this.pageCountry,
        limit: 10
      });
    }
  }

  @Action
  public setFirstCountryPage() {
    this.setFirstPageCountry();
  }

  @Action
  public searchCountry(value: string) {
    this.setSearchCountry(value);
    this.getCountryList({
      search: value,
      status: this.statusCountry,
      page: 1,
      limit: 10
    });
  }

  @Action
  public clearSearchCountry() {
    this.searchCountry("");
    this.getCountryList({
      search: "",
      status: this.statusCountry,
      page: 1,
      limit: 10
    });
  }

  @Action
  public selectStatusCountry(value: string) {
    this.selectStatusCountries(value);
    this.getCountryList({
      search: this.SearchCountry,
      status: this.statusCountry,
      page: 1,
      limit: 10
    });
  }

  @Action
  public async setNextPageDistrict() {
    await this.nextPageDistrict();
    this.getDistrictList({
      search: this.SearchDistrict,
      status: this.statusDistrict,
      page: this.pageDistrict,
      limit: 20,
      cache: false
    });
  }

  @Action
  public async setPrevPageDistrict() {
    if (this.pageDistrict !== 1) {
      await this.prevPageDistrict();
      this.getDistrictList({
        search: this.SearchDistrict,
        status: this.statusDistrict,
        page: this.pageDistrict,
        limit: 20,
        cache: false
      });
    }
  }

  @Action
  public setFirstDistrictPage() {
    this.setFirstPageDistrict();
  }

  @Action
  public searchDistrict(value: string) {
    this.setSearchDistrict(value);
    this.getDistrictList({
      search: value,
      status: this.statusDistrict,
      page: 1,
      limit: 20,
      cache: false
    });
  }

  @Action
  public clearSearchDistrict() {
    this.searchDistrict("");
  }

  @Action
  public selectStatusDistrict(value: string) {
    this.setSelectStatusDistrict(value);
    this.getDistrictList({
      search: this.SearchDistrict,
      status: this.statusDistrict,
      page: 1,
      limit: 20,
      cache: false
    });
  }

  @Action
  public selectStatusRegion(value: string) {
    this.setSelectStatusRegion(value);
    this.getRegionList({
      search: this.SearchRegion,
      status: this.statusRegion,
      page: 1,
      limit: 10
    });
  }

  @Action
  public clearSearchRegion() {
    this.searchRegion("");
    this.getRegionList({
      search: "",
      status: this.statusRegion,
      page: 1,
      limit: 10
    });
  }

  @Action
  public searchRegion(value: string) {
    this.setSearchRegion(value);
    this.getRegionList({
      search: value,
      status: this.statusRegion,
      page: 1,
      limit: 10
    });
  }

  @Action
  public async setNextPageRegion() {
    await this.nextPageRegion();
    this.getRegionList({
      search: this.SearchRegion,
      status: this.statusRegion,
      page: this.pageRegion,
      limit: 10
    });
  }

  @Action
  public async setPrevPageRegion() {
    if (this.pageRegion !== 1) {
      await this.prevPageRegion();
      this.getRegionList({
        search: this.SearchRegion,
        status: this.statusRegion,
        page: this.pageRegion,
        limit: 10
      });
    }
  }

  @Action
  public _onEditCity(params: {
    id: number;
    isActive: string;
    freeTradeZone: string;
    sundayDelivery: string;
  }) {
    this.setOpenModal(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(LocationPresenter);
    presenter
      .editCity(
        params.id,
        new EditCityApiRequest(
          params.isActive,
          params.freeTradeZone,
          params.sundayDelivery
        )
      )
      .then(() => {
        this.setOpenModalSuccess(true);
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Perubahan Gagal !", () =>
            this._onEditCity(params)
          )
        );
        this.setOpenModalSuccess(false);
      })
      .finally(() => {
        this.setOpenModal(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public _onEditCountry(params: { id: number; isActive: string }) {
    this.setOpenModal(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(LocationPresenter);
    presenter
      .editCountry(params.id, new EditCountryApiRequest(params.isActive))
      .then(() => {
        this.setOpenModalSuccess(true);
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Perubahan Gagal !", () =>
            this._onEditCountry(params)
          )
        );
        this.setOpenModalSuccess(false);
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public _onGetUrsaCode(params: { value: string }) {
    this.setUrsaCode(params.value);
    this.setLoading(true);
    const presenter = container.resolve(LocationPresenter);
    presenter
      .getUrsaCode(this.ursaCode)
      .then((res: UrsaCodeData) => {
        this.setCode(res.code);
      })
      .catch((error: any) => {
        this.setCode(error.response.data.code);
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _onEditDistrict(params: {
    id: number;
    type: string;
    ursaCode: string;
    status: string;
    isCod: string;
  }) {
    this.setOpenModal(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(LocationPresenter);
    presenter
      .editDistrict(
        params.id,
        new EditDistrictApiRequest(
          params.type,
          params.ursaCode,
          params.status,
          params.isCod
        )
      )
      .then(() => {
        this.setOpenModalSuccess(true);
        this.setErrorEdit(false);
        this.setErrorCauseDistrict("");
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Perubahan Gagal !", () =>
            this._onEditDistrict(params)
          )
        );
        this.setOpenModalSuccess(false);
      })
      .finally(() => {
        this.setOpenModal(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public setPageCityAction(value: number) {
    this.setPageCity(value);
    this.getCityList({
      search: this.SearchCity,
      status: this.statusCity,
      page: value,
      limit: 10
    });
  }

  @Action
  public setPageCountryAction(value: number) {
    this.setPageCountry(value);
    this.getCountryList({
      search: this.SearchCountry,
      status: this.statusCountry,
      page: value,
      limit: 10
    });
  }

  @Action
  public setPageDistrictAction(value: number) {
    this.setPageDistrict(value);
    this.getDistrictList({
      search: this.SearchDistrict,
      status: this.statusDistrict,
      page: value,
      limit: 20,
      cache: false
    });
  }

  @Action
  public setPageRegionAction(value: number) {
    this.setPageRegion(value);
    this.getRegionList({
      search: this.SearchRegion,
      status: this.statusRegion,
      page: value,
      limit: 10
    });
  }

  @Action
  public setEmptyDestinationDistrict() {
    this.setDestinationDistrict(new DistrictData());
  }

  @Action
  public getDistrictClient(params: {
    id: number | string;
    args: DistrictClientRequest;
  }) {
    const presenter = container.resolve(LocationPresenter);
    return presenter.getDistrictClient(params);
  }

  @Action
  public getZipCodeList(params: {
    search: string;
    page: number;
    limit: number;
  }) {
    this.setLoadingZipCode(true);
    const presenter = container.resolve(LocationPresenter);
    return presenter
      .getDistricZipCodeList(
        params.search,
        params.page,
        params.limit
      )
      .then((res: ZipCodeEntities) => {
        this.setZipCodeData(res);
        this.setError(false);
        this.setErrorCauseZipCode("");
        return res;
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCauseZipCode(error.response ? "server" : "internet");
        return new ZipCodeEntities(new Pagination(1, 10), []);
      })
      .finally(() => {
        this.setLoadingZipCode(false);
      });
  }

  @Mutation
  public setOpenModalSuccess(value: boolean) {
    this.openModalSuccess = value;
  }

  @Mutation
  public setLoadingList(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  public setLoadingDetailCountry(isLoading: boolean) {
    this.isLoadingDetailCountry = isLoading;
  }

  @Mutation
  public setLoadingDetailCity(isLoading: boolean) {
    this.isLoadingDetailCity = isLoading;
  }

  @Mutation
  public setLoadingDetail(isLoading: boolean) {
    this.isLoadingDetail = isLoading;
  }

  @Mutation
  public async setCityData(res: CityEntities) {
    this.cityData = res;
  }

  @Mutation
  public async setDetailCity(res: CityData) {
    this.cityDetail = res;
  }

  @Mutation
  public async setDetailCountry(res: CountryData) {
    this.countryDetail = res;
  }

  @Mutation
  public setDetailDistrict(res: DistrictData) {
    this.districtDetail = res;
  }

  @Mutation
  public setDetailDistrictTariff(res: DistrictData) {
    this.districtDetailTariff = res;
  }

  @Mutation
  public setDetailDistrictByDistrictCode(res: DistrictData) {
    this.districtDetail = res;
  }

  @Mutation
  public async setCountryData(res: CountryEntities) {
    this.countryData = res;
  }

  @Mutation
  public async setDistrictData(res: DistrictEntities) {
    this.districtData = res;
  }

  @Mutation
  public async setRegionData(res: RegionEntities) {
    this.regionData = res;
  }

  @Mutation
  public async setZipCodeData(res: ZipCodeEntities) {
    this.zipCodeData = res;
  }

  @Mutation
  public async onResetDistrictData() {
    this.districtData = new DistrictEntities({
      pagination: new Pagination(1, 10),
      districtData: []
    });
  }

  @Mutation
  public setDestinationDistrict(res: DistrictData) {
    this.destinationDistrict = res;
  }

  @Mutation
  public async nextPageCity() {
    if (this.pageCity >= 1) {
      this.pageCity = Number(this.pageCity) + 1;
    }
  }

  @Mutation
  public async prevPageCity() {
    this.pageCity = Number(this.pageCity) - 1;
  }

  @Mutation
  public async setFirstPageCity() {
    this.pageCity = 1;
  }

  @Mutation
  public async setPageCity(value: number) {
    this.pageCity = value;
  }

  @Mutation
  public async nextPageCountry() {
    if (this.pageCountry >= 1) {
      this.pageCountry = Number(this.pageCountry) + 1;
    }
  }

  @Mutation
  public async prevPageCountry() {
    this.pageCountry = Number(this.pageCountry) - 1;
  }

  @Mutation
  public async setFirstPageCountry() {
    this.pageCountry = 1;
  }

  @Mutation
  public async setPageCountry(value: number) {
    this.pageCountry = value;
  }

  @Mutation
  public async nextPageDistrict() {
    if (this.pageDistrict >= 1) {
      this.pageDistrict = Number(this.pageDistrict) + 1;
    }
  }

  @Mutation
  public async prevPageDistrict() {
    this.pageDistrict = Number(this.pageDistrict) - 1;
  }

  @Mutation
  public async setFirstPageDistrict() {
    this.pageDistrict = 1;
  }

  @Mutation
  public async setPageDistrict(value: number) {
    this.pageDistrict = value;
  }

  @Mutation
  public async setPageRegion(value: number) {
    this.pageRegion = value;
  }

  @Mutation
  public async nextPageRegion() {
    if (this.pageRegion >= 1) {
      this.pageRegion = Number(this.pageRegion) + 1;
    }
  }

  @Mutation
  public async prevPageRegion() {
    this.pageRegion = Number(this.pageRegion) - 1;
  }

  @Mutation
  public async setFirstPageRegion() {
    this.pageRegion = 1;
  }

  @Mutation
  public async setSearchCity(value: string) {
    this.SearchCity = value;
  }

  @Mutation
  public async selectStatus(value: string) {
    this.statusCity = value;
  }

  @Mutation
  public async setSearchCountry(value: string) {
    this.SearchCountry = value;
  }

  @Mutation
  public async selectStatusCountries(value: string) {
    this.statusCountry = value;
  }

  @Mutation
  public async setSearchDistrict(value: string) {
    this.SearchDistrict = value;
  }

  @Mutation
  public async setSelectStatusDistrict(value: string) {
    this.statusDistrict = value;
  }

  @Mutation
  public async setSearchRegion(value: string) {
    this.SearchRegion = value;
  }

  @Mutation
  public async setSelectStatusRegion(value: string) {
    this.statusRegion = value;
  }

  @Mutation
  public setLoading(bool: boolean) {
    this.isLoading = bool;
  }

  @Mutation
  public setError(bool: boolean) {
    this.isError = bool;
  }

  @Mutation
  public setErrorEdit(bool: boolean) {
    this.isErrorEdit = bool;
  }

  @Mutation
  public setMessage(value: string) {
    this.message = value;
  }

  @Mutation
  public setType(value: string) {
    this.type = value;
  }

  @Mutation
  public setAreaType(value: string) {
    this.areaType = value;
  }

  @Mutation
  public setLoadingCountry(bool: boolean) {
    this.isLoadingCountry = bool;
  }

  @Mutation
  public setLoadingDistrict(bool: boolean) {
    this.isLoadingDistrict = bool;
  }

  @Mutation
  public setLoadingRegion(bool: boolean) {
    this.isLoadingRegion = bool;
  }

  @Mutation
  public setLoadingZipCode(bool: boolean) {
    this.isLoadingZipCode = bool;
  }

  @Action
  public handleError() {
    this.setErrorEdit(false);
  }

  @Mutation
  public setRoute(value: boolean) {
    this.isRoute = value;
  }

  @Mutation
  public setFreeTradeZone(value: boolean) {
    this.isFreeTradeZone = value;
  }

  @Mutation
  public setIsSundayDelivery(value: boolean) {
    this.isSundayDelivery = value;
  }

  @Mutation
  public setCacheRoute(value: boolean) {
    this.cacheRoute = value;
  }

  @Mutation
  public setCacheFreeTradeZone(value: boolean) {
    this.cacheFreeTradeZone = value;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setCod(value: boolean) {
    this.isCod = value;
  }

  @Mutation
  public setCacheCod(value: boolean) {
    this.cacheCod = value;
  }

  @Mutation
  public setUrsaCode(value: string) {
    this.ursaCode = value;
  }

  @Mutation
  public setCacheUrsaCode(value: string) {
    this.cacheUrsaCode = value;
  }

  @Mutation
  public setCode(value: number) {
    this.code = value;
  }

  @Mutation
  public setCacheType(value: string) {
    this.cacheType = value;
  }

  @Mutation
  public setErrorCauseCountry(value: string) {
    this.isErrorCauseCountry = value;
  }

  @Mutation
  public setErrorCauseCity(value: string) {
    this.isErrorCauseCity = value;
  }

  @Mutation
  public setErrorCauseDistrict(value: string) {
    this.isErrorCauseDistrict = value;
  }

  @Mutation
  public setErrorCauseRegion(value: string) {
    this.isErrorCauseRegion = value;
  }

  @Mutation
  public setErrorCauseZipCode(value: string) {
    this.isErrorCauseZipCode = value;
  }

  // Origin State
  public originCityOptionsData: Array<any> = [];
  public isLoadingOriginCity = false;

  // Handle Origin City on Embargo
  @Mutation
  public setLoadingOriginCity(value: boolean) {
    this.isLoadingOriginCity = value;
  }

  @Mutation
  public setDefaultOriginCity() {
    this.originCityOptionsData = [];
  }

  @Action
  public getCityOriginList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
  }) {
    this.setLoadingOriginCity(true);
    this.setDefaultOriginCity();
    const presenter = container.resolve(LocationPresenter);
    presenter
      .getCityList(
        new ApiRequestList({
          ...params
        })
      )
      .then((res: CityEntities) => {
        this.setOptionsOriginCityData(res);
        this.setError(false);
      })
      .catch(() => {
        this.setError(true);
      })
      .finally(() => {
        this.setLoadingOriginCity(false);
      });
  }

  @Mutation
  public setOptionsOriginCityData(data: CityEntities) {
    for (const item of data.cityData) {
      this.originCityOptionsData.push({
        name: `${item.name} (${item.code})`,
        code: item.code,
        isActive: false
      });
    }
  }

  // Destination State
  public destinationCityOptionsData: Array<any> = [];
  public isLoadingDestinationCity = false;

  // Handle Destination City on Embargo
  @Mutation
  public setLoadingDestinationCity(value: boolean) {
    this.isLoadingDestinationCity = value;
  }

  @Mutation
  public setDefaultDestinationCity() {
    this.destinationCityOptionsData = [];
  }

  @Action
  public getCityDestinationList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
  }) {
    this.setLoadingDestinationCity(true);
    this.setDefaultDestinationCity();
    const presenter = container.resolve(LocationPresenter);
    presenter
      .getCityList(
        new ApiRequestList({
          ...params
        })
      )
      .then((res: CityEntities) => {
        this.setOptionsDestinationCityData(res);
        this.setError(false);
      })
      .catch(() => {
        this.setError(true);
      })
      .finally(() => {
        this.setLoadingDestinationCity(false);
      });
  }

  @Mutation
  public setOptionsDestinationCityData(data: CityEntities) {
    for (const item of data.cityData) {
      this.destinationCityOptionsData.push({
        name: `${item.name} (${item.code})`,
        code: item.code,
        isActive: false
      });
    }
  }
}

export const LocationController = getModule(LocationStore);
