import {
  BulkBookingSuccess,
  BookedSttSuccess,
  BulkBookingEntities,
  BulkBookingData,
  BulkBookingResiEntities,
  BulkBookingResiData,
  ClientBookingTemplateDetail,
  HeaderMapping,
  ClientBookingEntities,
  ClientBookingListData
} from "@/domain/entities/BulkBooking";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class BulkBookingMapper {
  public convertSuccessDataFromApi(
    res: AxiosResponse<any>
  ): BulkBookingSuccess {
    const {
      data: { data }
    } = res;
    return new BulkBookingSuccess(
      data.bulk_id,
      data.stt_success.map(
        (stt: any) =>
          new BookedSttSuccess(
            stt.manual_stt_number,
            stt.origin_district_code,
            stt.destination_district_code,
            stt.shipper_name,
            stt.shipper_address,
            stt.shipper_phone,
            stt.consignee_name,
            stt.consignee_address,
            stt.consignee_phone,
            stt.total_pieces,
            stt.total_weight,
            stt.commodity_code,
            stt.product_type,
            stt.insurance_service_type,
            Number(stt.value_of_goods),
            stt.external_number,
            stt.cod,
            stt.woodpacking,
            Number(stt.ppn),
            Number(stt.pph),
            Number(stt.shipping_rate),
            Number(stt.insurance_rate),
            Number(stt.total_surcharge),
            Number(stt.total_amount),
            Number(stt.bm)
          )
      )
    );
  }

  public convertFailedExcelFromApi(result: AxiosResponse): string {
    try {
      const { data } = result;
      return data;
    } catch (e) {
      return "";
    }
  }

  public convertBulkBookingResiFromApi(
    res: AxiosResponse<any>
  ): BulkBookingResiEntities {
    const { data } = res;
    const bulkBookingResiData: BulkBookingResiData[] = [] || null;
    if (data.data === []) {
      [];
    } else {
      data.data.forEach((item: any) => {
        bulkBookingResiData.push(
          new BulkBookingResiData(item.stt_no, item.stt_id)
        );
      });
    }

    return new BulkBookingResiEntities(bulkBookingResiData);
  }

  public convertUploadBulkBookingFromApi(
    result: AxiosResponse
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.data.bulk_booking_status
    });
  }

  public convertListBulkBookingFromApi(
    result: AxiosResponse
  ): BulkBookingEntities {
    const { data } = result;
    return new BulkBookingEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      data.data.length
        ? data.data.map(
            (item: any) =>
              new BulkBookingData(
                item.upload_id,
                item.activity_name,
                item.archive_type.toLowerCase() === "stt"
                  ? "Bulk Booking"
                  : item.archive_type,
                item.created_name,
                item.created_code,
                item.created_at,
                item.created_by,
                item.total_stt ? item.total_stt : 0,
                item.total_stt_success ? item.total_stt_success : 0,
                item.status,
                item.archive_file,
                item.failed_file
              )
          )
        : []
    );
  }

  public convertListClientBookingFromApi(
    result: AxiosResponse
  ): ClientBookingEntities {
    const { data } = result;
    return new ClientBookingEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      data.data.length
        ? data.data.map((item: any) => {
            return new ClientBookingListData({
              id: item.config_id,
              accountName: item.account_ref_name,
              accountId: item.account_ref_code,
              createdBy: item.created_name,
              createdAt: item.created_at,
              updatedBy: item.updated_name,
              updatedAt: item.updated_at
            });
          })
        : []
    );
  }

  public convertDetailClientBookingFromApi(
    result: AxiosResponse
  ): ClientBookingTemplateDetail {
    const { data } = result;
    return new ClientBookingTemplateDetail({
      id: data.data.id_config,
      accountRefId: data.data.account_ref_id,
      accountRefType: data.data.account_ref_type,
      accountRefName: data.data.account_ref_name,
      accountRefCode: data.data.account_ref_code,
      createdAt: data.data.created_at,
      createdBy: data.data.created_by_name,
      updatedAt: data.data.updated_at,
      updatedBy: data.data.updated_by_name,
      headerMapping: data.data.header_mapping.map((item: any) => {
        return new HeaderMapping({
          genesisHeader: item.genesis_header,
          sourceHeader: item.source_header,
          activitySelect: false
        });
      })
    });
  }

  public convertEditClientTemplateConfigurationToMapper(
    result: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertCreateClientTemplateConfigurationToMapper(
    result: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
