import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "loader rounded-full border-2 border-t-2 h-4 w-4 mx-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("button", {
    id: _ctx.id,
    type: "button",
    class: 
      `text-white flex flex-row items-center gap-x-2 justify-center ${
        _ctx.isDisabled
          ? 'bg-gray-lp-600 border-gray-lp-600 cursor-not-allowed'
          : 'bg-red-lp-100 border-red-lp-100'
      } border text-12px xxl:text-14px py-1.5 xxl:py-3 font-medium rounded-md focus:outline-none ${_ctx.custom}`
    ,
    onClick: _cache[1] || (_cache[1] = ($event: any) => (!_ctx.isDisabled && _ctx.onClick())),
    disabled: _ctx.isDisabled || _ctx.isLoading
  }, [
    (_ctx.iconLeft && !_ctx.isLoading)
      ? (_openBlock(), _createBlock("img", {
          key: 0,
          src: require(`@/app/ui/assets/svg/${_ctx.iconLeft}.svg`),
          class: "w-5",
          alt: _ctx.iconLeft
        }, null, 8, ["src", "alt"]))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock("span", {
          key: 2,
          class: `${_ctx.fontBold ? 'font-bold' : 'font-medium'}`
        }, _toDisplayString(_ctx.title), 3))
      : _createCommentVNode("", true),
    (_ctx.icon && !_ctx.isLoading)
      ? (_openBlock(), _createBlock("img", {
          key: 3,
          src: require(`@/app/ui/assets/svg/${_ctx.icon}.svg`),
          class: "w-5",
          alt: _ctx.icon
        }, null, 8, ["src", "alt"]))
      : _createCommentVNode("", true)
  ], 10, ["id", "disabled"]))
}