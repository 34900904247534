import { Pagination } from "./Pagination";

export class AnnouncementEntities {
  pagination: Pagination;
  data: AnnouncementData[];
  constructor(pagination: Pagination, data: AnnouncementData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class AnnouncementData {
  id: number;
  name: string;
  description: string;
  status: string;
  attachment: string;
  bannerImage: string;
  createdName: string;
  createdBy: number;
  announcementTarget: TargetDetail[];
  releasedAt: string;
  pinned: boolean;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;

  constructor(
    id: number,
    name: string,
    description: string,
    status: string,
    attachment: string,
    bannerImage: string,
    createdName: string,
    createdBy: number,
    announcementTarget: TargetDetail[],
    releasedAt: string,
    pinned: boolean,
    createdAt: string,
    updatedAt: string,
    updatedBy: string
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.status = status;
    this.attachment = attachment;
    this.bannerImage = bannerImage;
    this.createdName = createdName;
    this.createdBy = createdBy;
    this.announcementTarget = announcementTarget;
    this.releasedAt = releasedAt;
    this.pinned = pinned;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.updatedBy = updatedBy;
  }
}

export class TargetDetail {
  userTarget: string;
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  announcementId?: string;

  constructor(
    userTarget: string,
    id?: string,
    createdAt?: string,
    updatedAt?: string,
    deletedAt?: string,
    announcementId?: string
  ) {
    this.userTarget = userTarget;
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.deletedAt = deletedAt;
    this.announcementId = announcementId;
  }
}
