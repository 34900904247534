import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";

const sikatCampaignRules = (
  x: number,
  y: number,
  grossWeight: any,
  volumeWeight: any
) => {
  let total;
  const volumetricWeight = FlagsMedusa.config_volumetric_weight_sikat.getValue()
  if (x <= Number(volumetricWeight) || x > y) {
    const split = grossWeight.split(",");
    let result = x - Number(split[0]);
    result = Number(Number.parseFloat(result.toString()).toFixed(2));
    if (x < 1) {
      total = 1;
    } else {
      total = result >= 0.31 ? Math.ceil(x) - 0.5 : Math.floor(x);
    }
  } else {
    const split = volumeWeight.split(",");
    let result = y - Number(split[0]);
    result = Number(Number.parseFloat(result.toString()).toFixed(2));
    if (y < 1) {
      total = 1;
    } else {
      total = result >= 0.31 ? Math.ceil(y) - 0.5 : Math.floor(y);
    }
  }
  return total;
};
export default sikatCampaignRules;
