import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";

export class ConfigFakeDexIndicationDetail {
  dfdcId: any = "";
  dfdcName = "";
  dfdcDescription = "";
  dfdcCreatedAt = "";
  dfdcCreatedBy = "";
  dfdcUpdatedAt = "";
  dfdcUpdatedBy = "";
  dfdcStatus = "";
  dfdcType = "";
  dfdcReasonCode = "";
  dfdcReasonCategory = "";
  dfdcParamStartTime = "";
  dfdcParamEndTime = "";
  dfdcParamExcludeWeekendHoliday = false;
  dfdcOfficeStartTime = "";
  dfdcOfficeEndTime = "";
  dfdcOfficeExcludeWeekendHoliday = false;
  dfdcGenesis = false;
  dfdcDriverApp = false;
  dfdcTypeAddress = "Kantor";
  dfdcTypeSecondAddress = "Rumah";

  constructor(fields?: Partial<ConfigFakeDexIndicationDetail>) {
    Object.assign(this, fields);
  }

  formatDate?(date: string): string {
    return date ? formatDate(date) : "";
  }

  get formatCreatedAt() {
    return formatDate(this.dfdcCreatedAt);
  }

  get formatUpdatedAt() {
    return formatDate(this.dfdcUpdatedAt);
  }
}
