import { PROMO_CONFIGURATION } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const PromoConfigurationForm = () =>
  import(
    /* webpackChunkName: "PromoConfig" */ "@/app/ui/views/promo-configuration/promo-configuration-form/index.vue"
  );
const PromoConfigurationDetail = () =>
  import(
    /* webpackChunkName: "PromoConfig" */ "@/app/ui/views/promo-configuration/promo-configuration-detail/index.vue"
  );

export default [
  {
    path: "/tarif/promo-configuration",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Promo Diskon",
      permission: PROMO_CONFIGURATION.CREATE
    },
    children: [
      {
        path: "add",
        name: "add-promo-configuration",
        component: PromoConfigurationForm,
        meta: {
          header: true,
          title: "Buat Promo Diskon Baru",
          name: "Buat Promo Diskon Baru",
          formType: "add",
          before: "/tarif/promo-configuration"
        }
      }
    ]
  },
  {
    path: "/tarif/promo-configuration",
    redirect: "/tarif/promo-configuration",
    component: Layout,
    name: "detail-promo-configuration",
    meta: {
      icon: "promo-configuration",
      children: false,
      hidden: true,
      parent: "Promo Diskon",
      permission: PROMO_CONFIGURATION.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-promo-configuration",
        component: PromoConfigurationDetail,
        meta: {
          header: true,
          title: "Promo Diskon | Detail"
        }
      }
    ]
  },
  {
    path: "/tarif/promo-configuration",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Promo Diskon",
      permission: PROMO_CONFIGURATION.EDIT
    },
    children: [
      {
        path: "edit/:id",
        name: "edit-promo-configuration",
        component: PromoConfigurationForm,
        meta: {
          header: true,
          title: "Edit Promo",
          name: "Edit Promo",
          formType: "edit",
          before: "/tarif/promo-configuration/:id"
        }
      }
    ]
  }
];
