import downloadFromBlob from "@/app/infrastructures/misc/common-library/DownloadFromBlob";

const downloadCsvFromCsvContent = (params: {
  fileName: string;
  csvContent: any;
}) => {
  const blob = new Blob([params.csvContent], {
    type: "text/csv;charset=utf-8;"
  });
  downloadFromBlob(blob, params.fileName);
};
export default downloadCsvFromCsvContent;
