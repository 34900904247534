import { injectable } from "tsyringe";
import { CargoConfigurationApiRepository } from "@/app/infrastructures/repositories/api/CargoConfigurationApiRepository";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { CargoConfigurationRequestInterface } from "@/data/payload/contracts/CargoConfigurationRequest";
import {
  CargoConfigEntities,
  CargoConfiguration
} from "@/domain/entities/CargoConfiguration";
import { CargoConfigListRequest } from "@/data/payload/api/CargoConfigurationApiRequest";
import { CargoRecomendationsListRequest } from "@/data/payload/api/CargoApiRequest";

@injectable()
export class CargoConfigurationPresenter {
  private repository: CargoConfigurationApiRepository;

  constructor(repository: CargoConfigurationApiRepository) {
    this.repository = repository;
  }

  public getDetail(id: number): Promise<CargoConfiguration> {
    return this.repository.getDetail(id);
  }

  public getList(params: CargoConfigListRequest): Promise<CargoConfigEntities> {
    return this.repository.getList(params);
  }

  public edit(
    payload: CargoConfigurationRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.edit(payload);
  }

  public delete(id: number): Promise<ResponsePayload> {
    return this.repository.deleteCargoConfiguration(id);
  }

  addCargoConfiguration(
    payload: CargoConfigurationRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addCargoConfiguration(payload);
  }

  public getListCargoRecomendation(
    params: CargoRecomendationsListRequest
  ): Promise<CargoConfiguration[]> {
    return this.repository.getListCargoRecomendation(params);
  }
}
