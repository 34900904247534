import { GoodsTax, GoodsTaxList } from "@/domain/entities/GoodsTax";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { CityData, CityEntities } from "@/domain/entities/Location";

export class GoodsTaxMapper {
  public convertGoodsTaxDataFromApi(res: AxiosResponse<any>): GoodsTaxList {
    const { data } = res;
    const goodsTax: GoodsTax[] = [];
    data.data.map((item: any) => {
      goodsTax.push(
        new GoodsTax(
          item.goods_tax_id,
          item.goods_tax_origin_city_name,
          item.goods_tax_commodity_name !== ""
            ? item.goods_tax_commodity_name
            : "ALL",
          item.goods_tax_commodity_code,
          item.goods_tax_deminimus,
          item.goods_tax_cover_charge,
          item.goods_tax_ppn,
          item.goods_tax_pph,
          item.goods_tax_start_date,
          item.goods_tax_end_date,
          item.goods_tax_status === "inactive"
            ? "expired"
            : item.goods_tax_status
        )
      );
    });
    return new GoodsTaxList(
      new Pagination(data.meta.page, data.meta.limit),
      goodsTax
    );
  }

  public convertPayloadData(res: AxiosResponse<any>): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertCityDataFromApi(result: AxiosResponse<any>): CityEntities {
    const { data } = result;

    const cityListData: CityData[] = [] || null;
    if (data.data === [] || data.data === null) {
      [];
    } else {
      data.data.forEach((item: any) => {
        cityListData.push(
          new CityData({
            id: item.id,
            name: item.name,
            isActive: item.is_active,
            code: item.code,
            timezone: item.timezone,
            freeTradeZone: item.free_trade_zone,
            countryId: item.country_id,
            originTransit: item.origin_transit_name,
            destinationTransit: item.destination_transit_name,
            createdBy: item.created_by,
            updatedBy: item.updated_by,
            createdAt: item.created_at,
            updateAt: item.updated_at,
            originCodeTransit: item.origin_transit,
            destinationCodeTransit: item.destination_transit
          })
        );
      });
    }

    const pagination = new Pagination(data.meta.page, data.meta.limit);

    return new CityEntities(pagination, cityListData);
  }
  public convertDetailGoodsTaxFromApi(res: AxiosResponse<any>): GoodsTax {
    const { data } = res;
    return new GoodsTax(
      data.data.goods_tax_id,
      data.data.goods_tax_origin_city_name,
      data.data.goods_tax_commodity_name !== ""
        ? data.data.goods_tax_commodity_name
        : "ALL",
      data.data.goods_tax_commodity_code,
      data.data.goods_tax_deminimus,
      data.data.goods_tax_cover_charge,
      data.data.goods_tax_ppn,
      data.data.goods_tax_pph,
      data.data.goods_tax_start_date,
      data.data.goods_tax_end_date,
      data.data.goods_tax_status,
      data.data.goods_tax_created_at,
      data.data.goods_tax_created_name,
      data.data.goods_tax_updated_at,
      data.data.goods_tax_updated_name,
      data.data.goods_tax_origin_city_code.split(",").map(
        (code: string, index: number) =>
          new CityData({
            name: data.data.goods_tax_origin_city_name.split(",")[index],
            code: code
          })
      )
    );
  }
}
