import { NotificationApiRepository } from "@/app/infrastructures/repositories/api/NotificationApiRepository";
import { ChangeRequestInterface } from "@/data/payload/contracts/NotificationRequest";
import {
  NotificationData,
  NotificationsEntities,
  UnreadData
} from "@/domain/entities/Notification";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { injectable } from "tsyringe";

@injectable()
export class NotificationPresenter {
  private repository: NotificationApiRepository;

  constructor(repository: NotificationApiRepository) {
    this.repository = repository;
  }

  public getUnread(cache: boolean): Promise<UnreadData> {
    return this.repository.getUnread(cache);
  }

  public getAnnouncement(
    page: number,
    limit: number,
    status: string,
    search: string,
    isPinned: number,
    sort: string,
    cache: boolean
  ): Promise<NotificationsEntities> {
    return this.repository.getAnnouncement(
      page,
      limit,
      status,
      search,
      isPinned,
      sort,
      cache
    );
  }

  public detailNotification(id: number): Promise<NotificationData> {
    return this.repository.getDetail(id);
  }

  public readNotification(
    payload: ChangeRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.readNotification(payload);
  }
}
