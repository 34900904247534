import ApiServiceAlgo from "@/app/infrastructures/services/ApiServiceAlgo";
import {
  PosFavoriteSubscriberEntities,
  PosFavoriteSubscriberOutstandingEntities,
  PosFavouriteDefaultDiscount
} from "@/domain/entities/PosFavorite";
import { PosFavoriteMapper } from "@/data/persistences/mappers/PosFavoriteMapper";
import { PosFavoriteRepositoryInterface } from "@/data/persistences/repositories/contracts/PosFavoriteRepositoryInterface";
import { Endpoints } from "@/app/infrastructures/misc/Endpoints";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  EditMemberDiscountRequestInterface,
  EditDefaultDiscountRequestInterface
} from "@/data/payload/contracts/PosFavoriteRequest";
import {
  EditMemberDiscountApiRequest,
  EditDefaultDiscountApiRequest
} from "@/data/payload/api/PosFavoriteApiRequest";

export class PosFavoriteApiRepository
  implements PosFavoriteRepositoryInterface {
  private service: ApiServiceAlgo;
  private mapper: PosFavoriteMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiServiceAlgo,
    mapper: PosFavoriteMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async getListPosFavoriteSubscriber(
    agentCode: string,
    phoneNumber: string,
    page: number,
    limit: number
  ): Promise<PosFavoriteSubscriberEntities> {
    const resp = await this.service.invokeAlgo(
      "get",
      this.endpoints.getPosFavoriteSubscriberList(
        agentCode,
        phoneNumber,
        page,
        limit
      ),
      {}
    );
    return this.mapper.convertListPosFavoriteFromApi(resp);
  }

  async editMemberDiscount(
    payload: EditMemberDiscountRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokeAlgo(
      "post",
      this.endpoints.editMemberDiscount(),
      undefined,
      payload as EditMemberDiscountApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  async getListPosFavoriteSubscriberOutstanding(
    agentCode: string,
    phoneNumber: string,
    page: number,
    limit: number,
    status: string
  ): Promise<PosFavoriteSubscriberOutstandingEntities> {
    const resp = await this.service.invokeAlgo(
      "get",
      this.endpoints.getPosFavoriteSubscriberOutstandingList(
        agentCode,
        phoneNumber,
        page,
        limit,
        status
      ),
      {}
    );
    return this.mapper.convertListPosFavoriteSubscriberOutstandingFromApi(resp);
  }

  async editDefaultDiscount(
    payload: EditDefaultDiscountRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokeAlgo(
      "post",
      this.endpoints.editDefaultDiscount(),
      undefined,
      payload as EditDefaultDiscountApiRequest
    );

    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async getDetailDefaultDiscount(
    agentCode: string
  ): Promise<PosFavouriteDefaultDiscount> {
    const resp = await this.service.invokeAlgo(
      "get",
      this.endpoints.getDetailDefaultDiscount(agentCode),
      {}
    );
    return this.mapper.getDetailDefaultDiscountDataFromApi(resp);
  }
}
