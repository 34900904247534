/* eslint-disable @typescript-eslint/camelcase */

import { BULK_UPLOAD } from "@/app/infrastructures/misc/RolePermission";
import { FlagsPermissionBulkUpload } from "@/feature-flags/bulk-upload";

const AGENT_CODE_POS_ALGO = "CGK-0833-165";
const MAX_STT_DELIVERY_MANIFEST_UPDATE = 50;
const CARGO_SPECIAL_TYPE = "plane";
const MAX_CARGO_FOR_PLANE = 15;
const INTERNAL_SERVER_ERROR = "internal-server-error";
const CLIENT_ERROR = "client-error";
const INTERNET_ERROR = "internet-error";
const SPECIAL_ERROR = "special-error";
const ERROR_TOKEN_NGEN = "error-token-ngen";
const FAILED_ERROR = "failed_error";
const NGEN_BOOKING = {
  PRODUCT_NAME: "EXPRESS PORT TO PORT",
  E_CARGO_UNIQUE_ID: "",
  AWB_NO: "",
  AWB_PREFIX: "990",
  SHC: ""
};
const IS_SENDER_ACCOUNT = process.env.VUE_APP_IS_SENDER === "true";
const IS_ENABLE_CLAIM =
  process.env.VUE_APP_IS_ENABLE_CLAIM_PENGIRIMAN_AKTIF === "true" &&
  process.env.VUE_APP_IS_ENABLE_CLAIM_RIWAYAT_PENGIRIMAN === "true";
const IS_ENABLE_CLAIM_PENGIRIMAN_AKTIF =
  process.env.VUE_APP_IS_ENABLE_CLAIM_PENGIRIMAN_AKTIF === "true";
const IS_ENABLE_CLAIM_RIWAYAT_PENGIRIMAN =
  process.env.VUE_APP_IS_ENABLE_CLAIM_RIWAYAT_PENGIRIMAN === "true";
const IS_HIDE_INSURANCE_PREMIUM = true;
const BulkUploadType = [
  { name: "Pilih Tipe Berkas", value: "", permission: "" },
  {
    name: "Location Coverage",
    value: "location-coverage",
    permission: BULK_UPLOAD.LOCATION_COVERAGE
  },
  { name: "Role", value: "role", permission: BULK_UPLOAD.ROLE },
  {
    name: "Route Mapping",
    value: "route-mapping",
    permission: BULK_UPLOAD.ROUTE_MAPPING
  },
  { name: "Account", value: "account", permission: BULK_UPLOAD.ACCOUNT },
  { name: "Rate", value: "rate", permission: BULK_UPLOAD.RATE },
  {
    name: "Option Rate",
    value: "option-rate",
    permission: BULK_UPLOAD.OPTION_RATE
  },
  { name: "Vendor", value: "vendor", permission: BULK_UPLOAD.VENDOR },
  { name: "Route Leg", value: "route-leg", permission: BULK_UPLOAD.ROUTE_LEG },
  { name: "Leg", value: "leg", permission: BULK_UPLOAD.LEG },
  { name: "Route", value: "route", permission: BULK_UPLOAD.ROUTE },
  {
    name: "Create Retail Base Rate",
    value: "retail-rate",
    permission: BULK_UPLOAD.CREATE_RETAIL
  },
  {
    name: "Update Retail Base Rate",
    value: "update-retail-rate",
    permission:
      FlagsPermissionBulkUpload.permission_bulk_upload_type_update_retail_base_rate
  },
  {
    name: "Transport Vendor",
    value: "transport",
    permission: BULK_UPLOAD.TRANSPORT_VENDOR
  },
  { name: "Commodity", value: "commodity", permission: BULK_UPLOAD.COMMODITY },
  {
    name: "Create Commodity Surcharge",
    value: "create-commodity-surcharge",
    permission: BULK_UPLOAD.COMMODITY_SURCHARGE
  },
  {
    name: "Update Commodity Surcharge",
    value: "update-commodity-surcharge",
    permission: BULK_UPLOAD.COMMODITY_SURCHARGE
  },
  {
    name: "Add mapping location Client to Lionparcel",
    value: "client-location",
    permission: BULK_UPLOAD.ADD_MAPPING_LOCATION
  },
  {
    name: "Update mapping location Client to Lionparcel",
    value: "client-location-update",
    permission: BULK_UPLOAD.UPDATE_MAPPING
  },
  {
    name: "Bagging Grouping",
    value: "bagging_group_location",
    permission: BULK_UPLOAD.BAGGING_GROUPING_LOCATION
  },
  {
    name: "Update SLA District",
    value: "estimate_sla_district",
    permission: BULK_UPLOAD.ESTIMATE_SLA_DISTRICT
  },
  {
    name: "Create SLA City",
    value: "estimate_sla",
    permission: BULK_UPLOAD.SLA_CITY
  },
  {
    name: "Update SLA City",
    value: "update_estimate_sla_city",
    permission: BULK_UPLOAD.UPDATE_ESTIMATE_SLA_CITY
  },
  {
    name: "Add Country",
    value: "add-country",
    permission: BULK_UPLOAD.ADD_COUNTRY
  },
  {
    name: "Update Country",
    value: "update-country",
    permission: BULK_UPLOAD.UPDATE_COUNTRY
  },
  {
    name: "Add City",
    value: "add-city",
    permission: BULK_UPLOAD.ADD_CITY
  },
  {
    name: "Update City",
    value: "update-city",
    permission: BULK_UPLOAD.UPDATE_CITY
  },
  {
    name: "Add District",
    value: "add-district",
    permission: BULK_UPLOAD.ADD_DISTRICT
  },
  {
    name: "Update District",
    value: "update-district",
    permission: BULK_UPLOAD.UPDATE_DISTRICT
  },
  {
    name: "Add Embargo",
    value: "add-embargo",
    permission: BULK_UPLOAD.ADD_EMBARGO
  },
  {
    name: "Update Embargo",
    value: "update-embargo",
    permission: BULK_UPLOAD.UPDATE_EMBARGO
  },
  {
    name: "Config Kargo",
    value: "cargo-config",
    permission: BULK_UPLOAD.CARGO_CONFIGURATION
  },
  {
    name: "Add Promo Discount",
    value: "add-promo-discount",
    permission:
      FlagsPermissionBulkUpload.permission_bulk_upload_add_promo_discount
  },
  {
    name: "Update Promo Discount",
    value: "update-promo-discount",
    permission:
      FlagsPermissionBulkUpload.permission_bulk_upload_update_promo_discount
  },
  {
    name: "AWB Reserve",
    value: "awb-reserve",
    permission: BULK_UPLOAD.AWB_RESERVE
  },
  {
    name: "Region",
    value: "region",
    permission: BULK_UPLOAD.REGION
  },
  {
    name: "Region City",
    value: "region-city",
    permission: BULK_UPLOAD.REGION_CITY
  },
  {
    name: "Kargo Grouping",
    value: "rtc_city_group",
    permission: BULK_UPLOAD.RTC_CITY_GROUP
  },
  {
    name: "Update Commodity",
    value: "update-commodity",
    permission: BULK_UPLOAD.UPDATE_COMMODITY
  },
  {
    name: "Update Custom Status",
    value: "update-custom-status",
    permission:
      FlagsPermissionBulkUpload.permission_bulk_upload_update_custom_status
  },
  {
    name: "District Code Vendor Interpack",
    value: "district-code-vendor-interpack",
    permission: FlagsPermissionBulkUpload.permission_bulk_upload_district_vendor
  },
  {
    name: "Syarat dokumen internasional",
    value: "international-document-config",
    permission: FlagsPermissionBulkUpload.permission_bulk_upload_international_document
  }
];
const reportTypeToTitle: any = {
  stt: "STT",
  "manual-stt": "STT Manual",
  fpr: "FPR",
  transaksi: "Transaksi",
  "MIS-ROUTE": "Misroute",
  shortland: "Shortland",
  "stt-scrap-report": "Scrap",
  "shipment-report": "Pengiriman",
  "shipment-outgoing": "Pengiriman Keluar (Kota Asal)",
  "sti-dest-report": "Pengiriman Masuk (Kota Tujuan)",
  cancel: "Pembatalan STT",
  top_up: "Top Up",
  "dtpol-fee-report": "Komisi Pengiriman",
  "cancel-stt": "Pembatalan STT",
  "tier-x": "Komisi Pengiriman Tier X",
  "kejarcuan-report": "Kejarcuan",
  "simple-stt": "STT Client",
  "mutation-saldo-report": "Mutasi Saldo",
  hold_balance_history: "Saldo Tertahan"
};
const dayOfWeekList = [
  { name: "Senin", value: "monday", id: 1 },
  { name: "Selasa", value: "tuesday", id: 2 },
  { name: "Rabu", value: "wednesday", id: 3 },
  { name: "Kamis", value: "thursday", id: 4 },
  { name: "Jumat", value: "friday", id: 5 },
  { name: "Sabtu", value: "saturday", id: 6 },
  { name: "Minggu", value: "sunday", id: 7 },
  { name: "", value: "", id: 8 }
];
const optionFlightBreak = [
  { name: "Hari Ini", value: 0 },
  { name: "1 Hari", value: 1 },
  { name: "2 Hari", value: 2 },
  { name: "3 Hari", value: 3 }
];
const optionFlightBreakTransit = [
  { name: "Transit Hari Ini", value: 0 },
  { name: "1 Hari Transit", value: 1 },
  { name: "2 Hari Transit", value: 2 },
  { name: "3 Hari Transit", value: 3 }
];
const BADGE_STYLES = {
  success: "text-green-lp-400 bg-green-lp-500",
  failed: "text-red-lp-100 bg-red-lp-1300"
};

const optionTaxTransactionCode = [
  {
    name: "01 Kepada Pihak yang Bukan Pemungut PPN (Customer Biasa)",
    value: "01 Kepada Pihak yang Bukan Pemungut PPN (Customer Biasa)"
  },
  {
    name: "02 Kepada Pemungunt Bendahara (Dinas Kepemerintahan)",
    value: "02 Kepada Pemungunt Bendahara (Dinas Kepemerintahan)"
  },
  {
    name: "03 Kepada Pemungut Bendaharawan (BUMN)",
    value: "03 Kepada Pemungut Bendaharawan (BUMN)"
  },
  {
    name: "04 DPP Nilai Lain (PPN 1%)",
    value: "04 DPP Nilai Lain (PPN 1%)"
  },
  {
    name: "05 Besaran Tertentu",
    value: "05 Besaran Tertentu"
  },
  {
    name: "06 Penyerahan Lainnya (Turis Asing)",
    value: "06 Penyerahan Lainnya (Turis Asing)"
  },
  {
    name:
      "07 Penyerahan yang PPN-nya Tidak Dipungut (Kawasan ekonomi Khusus/ Batam)",
    value:
      "07 Penyerahan yang PPN-nya Tidak Dipungut (Kawasan ekonomi Khusus/ Batam)"
  },
  {
    name: "08 Penyerahan yang PPN-nya Dibebaskan (Impor Barang Tertentu)",
    value: "08 Penyerahan yang PPN-nya Dibebaskan (Impor Barang Tertentu)"
  },
  {
    name: "09 Penyerahan Aktiva (Pasal 16D UU PPN)",
    value: "09 Penyerahan Aktiva (Pasal 16D UU PPN)"
  }
];

const optionClientCategory = [
  {
    name: "Business to Business",
    value: "B2B"
  },
  {
    name: "Business to Customer",
    value: "B2C"
  },
  {
    name: "Customer to Customer",
    value: "C2C"
  },
  {
    name: "Small Medium Business",
    value: "SMB"
  }
];

const optionCompanySize = [
  {
    name: "Small",
    value: "small"
  },
  {
    name: "Medium",
    value: "medium"
  },
  {
    name: "Big",
    value: "big"
  },
  {
    name: "Micro",
    value: "micro"
  }
];

const optionRangeTimePayment = [
  {
    name: " 7 Hari",
    value: "07"
  },
  {
    name: "14 Hari",
    value: "14"
  },
  {
    name: "30 Hari",
    value: "30"
  },
  {
    name: "40 Hari",
    value: "40"
  },
  {
    name: "45 Hari",
    value: "45"
  },
  {
    name: "60 Hari",
    value: "60"
  }
];

const optionBillingSchedule = [
  {
    name: "Daily",
    value: "daily"
  },
  {
    name: "Weekly",
    value: "weekly"
  },
  {
    name: "Bi-Weekly",
    value: "bi-weekly"
  },
  {
    name: "Monthly",
    value: "monthly"
  }
];

export {
  AGENT_CODE_POS_ALGO,
  MAX_CARGO_FOR_PLANE,
  MAX_STT_DELIVERY_MANIFEST_UPDATE,
  CARGO_SPECIAL_TYPE,
  INTERNET_ERROR,
  CLIENT_ERROR,
  SPECIAL_ERROR,
  ERROR_TOKEN_NGEN,
  FAILED_ERROR,
  NGEN_BOOKING,
  INTERNAL_SERVER_ERROR,
  IS_ENABLE_CLAIM,
  IS_ENABLE_CLAIM_PENGIRIMAN_AKTIF,
  IS_ENABLE_CLAIM_RIWAYAT_PENGIRIMAN,
  IS_HIDE_INSURANCE_PREMIUM,
  IS_SENDER_ACCOUNT,
  BULK_UPLOAD,
  BulkUploadType,
  reportTypeToTitle,
  dayOfWeekList,
  optionFlightBreak,
  optionFlightBreakTransit,
  BADGE_STYLES,
  optionTaxTransactionCode,
  optionClientCategory,
  optionCompanySize,
  optionRangeTimePayment,
  optionBillingSchedule
};
