import {
  ALLOWED,
  PARTNER_MANAGEMENT
} from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

const Partner = () =>
  import(
    /* webpackChunkName: "PartnerManagement" */ "@/app/ui/views/sales/partner-management/index.vue"
  );
const PartnerConsolidator = () =>
  import(
    /* webpackChunkName: "PartnerManagement" */ "@/app/ui/views/sales/partner-management/consolidator/index.vue"
  );
const PartnerSubConsolidator = () =>
  import(
    /* webpackChunkName: "PartnerManagement" */ "@/app/ui/views/sales/partner-management/sub-consolidator/index.vue"
  );
const PartnerPos = () =>
  import(
    /* webpackChunkName: "PartnerManagement" */ "@/app/ui/views/sales/partner-management/pos/index.vue"
  );
const PartnerHub = () =>
  import(
    /* webpackChunkName: "PartnerManagement" */ "@/app/ui/views/sales/partner-management/hub/index.vue"
  );
const AddPartnerPage = () =>
  import(
    /* webpackChunkName: "PartnerManagement" */ "@/app/ui/views/sales/partner-management/add.vue"
  );
const UpdatePartnerPage = () =>
  import(
    /* webpackChunkName: "PartnerManagement" */ "@/app/ui/views/sales/partner-management/update.vue"
  );
const DetailPartner = () =>
  import(
    /* webpackChunkName: "PartnerManagement" */ "@/app/ui/views/sales/partner-management/detail.vue"
  );
const DetailHub = () =>
  import(
    /* webpackChunkName: "PartnerManagement" */ "@/app/ui/views/sales/partner-management/hub/detail.vue"
  );
const FormHub = () =>
  import(
    /* webpackChunkName: "PartnerManagement" */ "@/app/ui/views/sales/partner-management/hub/component/form-hub.vue"
  );

export default [
  {
    path: "/partner",
    component: Layout,
    name: "partner",
    meta: {
      children: false,
      icon: "partner-management",
      title: "Partner Management",
      name: "Partner Management",
      permission: PARTNER_MANAGEMENT.ENABLE,
      isFreeze: true
    },
    children: [
      {
        path: "",
        name: "partner",
        redirect: "/partner/consolidator",
        component: Partner,
        meta: {
          header: true,
          icon: "partner-management",
          title: "Partner Management",
          name: "Partner Management",
          permission: PARTNER_MANAGEMENT.ENABLE,
          isFreeze: true
        },
        children: [
          {
            path: "consolidator",
            name: "consolidator",
            component: PartnerConsolidator
          },
          {
            path: "sub-consolidator",
            name: "sub-consolidator",
            component: PartnerSubConsolidator
          },
          {
            path: "pos",
            name: "pos",
            component: PartnerPos
          },
          {
            path: "hub",
            name: "hub",
            component: PartnerHub
          }
        ]
      }
    ]
  },
  {
    path: "/partner/consolidator",
    component: Layout,
    name: "detail-partner-consolidator",
    meta: {
      title: "Partner Management | Detail",
      hidden: true,
      parent: "Partner Management",
      isFreeze: true,
      permission: PARTNER_MANAGEMENT.DETAIL
    },
    children: [
      {
        path: "detail/:id",
        name: "detail-partner-consolidator",
        component: DetailPartner,
        meta: {
          header: true,
          title: "Detail Partner",
          name: "Detail Partner",
          before: "/partner/consolidator",
          type: "consolidator"
        }
      }
    ]
  },
  {
    path: "/partner/sub-consolidator",
    component: Layout,
    name: "detail-partner-sub-consolidator",
    redirect: "/partner/consolidator",
    meta: {
      title: "Partner Management | Detail",
      hidden: true,
      parent: "Partner Management",
      isFreeze: true,
      permission: PARTNER_MANAGEMENT.DETAIL
    },
    children: [
      {
        path: "detail/:id",
        name: "detail-partner-sub-consolidator",
        component: DetailPartner,
        meta: {
          header: true,
          title: "Detail Partner",
          name: "Detail Partner",
          before: "/partner/sub-consolidator",
          type: "sub-consolidator"
        }
      }
    ]
  },
  {
    path: "/partner/pos",
    component: Layout,
    name: "detail-partner-pos",
    meta: {
      title: "Partner Management | Detail",
      hidden: true,
      parent: "Partner Management",
      isFreeze: true,
      permission: PARTNER_MANAGEMENT.DETAIL
    },
    children: [
      {
        path: "detail/:id",
        name: "detail-partner-pos",
        component: DetailPartner,
        meta: {
          header: true,
          title: "Detail Partner",
          name: "Detail Partner",
          before: "/partner/pos",
          type: "pos"
        }
      }
    ]
  },
  {
    path: "/partner/consolidator",
    component: Layout,
    name: "add-partner-consolidator",
    meta: {
      children: false,
      hidden: true,
      parent: "Partner Management",
      permission: PARTNER_MANAGEMENT.CREATE
    },
    children: [
      {
        path: "/partner/consolidator/add",
        name: "add-partner-consolidator",
        component: AddPartnerPage,
        meta: {
          header: true,
          title: "Consolidator | Create",
          form: "consolidator",
          name: "Buat Consolidator",
          before: "/partner/consolidator"
        }
      }
    ]
  },
  {
    path: "/partner/sub-consolidator",
    component: Layout,
    name: "add-partner-sub-consolidator",
    meta: {
      children: false,
      hidden: true,
      parent: "Partner Management",
      permission: PARTNER_MANAGEMENT.CREATE
    },
    children: [
      {
        path: "/partner/sub-consolidator/add",
        name: "add-partner-sub-consolidator",
        component: AddPartnerPage,
        meta: {
          header: true,
          title: "Sub Consolidator | Create",
          form: "sub-consolidator",
          name: "Buat Sub Consolidator",
          before: "/partner/sub-consolidator"
        }
      }
    ]
  },
  {
    path: "/partner/pos",
    component: Layout,
    name: "add-partner-pos",
    meta: {
      children: false,
      hidden: true,
      parent: "Partner Management",
      permission: PARTNER_MANAGEMENT.CREATE
    },
    children: [
      {
        path: "/partner/pos/add",
        name: "add-partner-pos",
        component: AddPartnerPage,
        meta: {
          header: true,
          title: "POS | Create",
          form: "pos",
          name: "Buat POS",
          before: "/partner/pos"
        }
      }
    ]
  },
  {
    path: "/partner/hub",
    component: Layout,
    name: "add-partner-hub",
    meta: {
      children: false,
      hidden: true,
      parent: "Partner Management",
      permission: PARTNER_MANAGEMENT.CREATE
    },
    children: [
      {
        path: "/partner/hub/add",
        name: "add-partner-hub",
        component: FormHub,
        meta: {
          header: true,
          title: "Hub | Create",
          form: "hub",
          name: "Buat Hub",
          before: "/partner/hub"
        }
      }
    ]
  },
  {
    path: "/partner/hub",
    component: Layout,
    name: "detail-partner-hub",
    meta: {
      hidden: true,
      parent: "Partner Management",
      permission: PARTNER_MANAGEMENT.DETAIL
    },
    children: [
      {
        path: "detail/:id",
        name: "detail-partner-hub",
        component: DetailHub,
        meta: {
          header: true,
          title: "Detail Hub",
          name: "Detail Hub",
          before: "/partner/hub"
        }
      }
    ]
  },
  {
    path: "/partner/hub",
    component: Layout,
    name: "edit-partner-hub",
    meta: {
      children: false,
      hidden: true,
      parent: "Partner Management",
      permission: ALLOWED
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-partner-hub",
        component: FormHub,
        meta: {
          header: true,
          title: "Hub | Edit",
          form: "hub",
          name: "Ubah Hub",
          before: "/partner/hub/detail/:id"
        }
      }
    ]
  },
  {
    path: "/partner/consolidator",
    component: Layout,
    name: "edit-partner-consolidator",
    meta: {
      children: false,
      hidden: true,
      parent: "Partner Management",
      permission: PARTNER_MANAGEMENT.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-partner-consolidator",
        component: UpdatePartnerPage,
        meta: {
          title: "Consolidator | Edit",
          form: "consolidator",
          name: "Ubah Consolidator",
          before: "/partner/consolidator/detail/:id"
        }
      }
    ]
  },
  {
    path: "/partner/sub-consolidator",
    component: Layout,
    name: "edit-partner-sub-consolidator",
    meta: {
      children: false,
      hidden: true,
      parent: "Partner Management",
      permission: PARTNER_MANAGEMENT.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-partner-sub-consolidator",
        component: UpdatePartnerPage,
        meta: {
          title: "Sub Consolidator | Edit",
          form: "sub-consolidator",
          name: "Ubah Sub Consolidator",
          before: "/partner/sub-consolidator/detail/:id"
        }
      }
    ]
  },
  {
    path: "/partner/pos",
    component: Layout,
    name: "edit-partner-pos",
    meta: {
      children: false,
      hidden: true,
      parent: "Partner Management",
      permission: PARTNER_MANAGEMENT.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-partner-pos",
        component: UpdatePartnerPage,
        meta: {
          title: "POS | Edit",
          form: "pos",
          name: "Ubah POS",
          before: "/partner/pos/detail/:id"
        }
      }
    ]
  }
];
