/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionCbp = {
    cbp_print_manifest: new Rox.Flag(false),
    cbp_enable: new Rox.Flag(false),
};

export function initFlagsPermissionCbp() {
  // register flags with namespaces for better grouping
  Rox.register("cbpFeature", FlagsPermissionCbp);
}
