/* eslint-disable @typescript-eslint/camelcase */

import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  DeliveryVendor,
  VendorData,
  VendorEntities
} from "@/domain/entities/Vendor";
import { AxiosResponse } from "axios";

export class VendorDataMapper {
  public convertVendorDataFromApi(res: AxiosResponse<any>): VendorEntities {
    const { data } = res;

    const vendors: VendorData[] = [];
    if (data.data.length === 0) {
      return new VendorEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        vendors.push(
          new VendorData({
            id: item.id,
            code: item.code,
            name: item.name,
            email: item.email,
            phone_number: item.phone_number,
            address: item.address,
            status: item.status
          })
        );
      });
    }
    return new VendorEntities(
      new Pagination(data.meta.page, data.meta.limit),
      vendors
    );
  }

  public convertDetailVendorDataFromApi(res: AxiosResponse<any>): VendorData {
    const {
      data: { data }
    } = res;
    return new VendorData({
      id: data.id,
      code: data.code,
      name: data.name,
      email: data.email,
      phone_number: data.phone_number,
      address: data.address,
      status: data.status,
      contract_end: data.contract_end,
      contract_start: data.contract_start,
      tax_number: data.tax_number,
      created_at: data.created_at,
      created_by: data.created_by,
      updated_at: data.updated_at,
      updated_by: data.updated_by
    });
  }

  public convertPayloadData(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertDeliveryVendorDataFromApi(
    result: AxiosResponse
  ): DeliveryVendor[] {
    const { data } = result;
    return data.data.map((item: any) => {
      return new DeliveryVendor({
        deliveryVendorId: item.delivery_vendor_id,
        deliveryVendorName: item.delivery_vendor_name
      });
    });
  }
}
