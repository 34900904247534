import { VehicleRequest } from "@/data/payload/api/VehicleRequest";
import { VehicleRequestInterface } from "@/data/payload/contracts/VehicleRequest";
import { VehicleDataMapper } from "@/data/persistences/mappers/VehicleMapper";
import { VehicleRepositoryInterface } from "@/data/persistences/repositories/contracts/VehicleRepositoryInterface";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Vehicles } from "@/domain/entities/Vehicle";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class VehicleApiRepository implements VehicleRepositoryInterface {
  private service: ApiService;
  private mapper: VehicleDataMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: VehicleDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getVehicles(search: string): Promise<Vehicles> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getVehicles(search),
      {}
    );
    return this.mapper.convertVehiclesDataFromApi(resp);
  }

  public async createNewVehicle(
    vehicle: VehicleRequestInterface
  ): Promise<ResponsePayload> {
    const req = await this.service.invoke(
      "post",
      this.endpoints.createNewVehicle(),
      undefined,
      vehicle as VehicleRequest
    );
    return this.mapper.convertPayloadData(req);
  }
}
