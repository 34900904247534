import { ROUTE_TRANSPORT } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const DetailTransport = () =>
  import(
    /* webpackChunkName: "Transport" */ "@/app/ui/views/route/transport-management/component/detail.vue"
  );
const EditTransport = () =>
  import(
    /* webpackChunkName: "Transport" */ "@/app/ui/views/route/transport-management/component/edit.vue"
  );

export default [
  {
    path: "/network/transport-management",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Transportation Management",
      permission: ROUTE_TRANSPORT.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-transport",
        component: DetailTransport,
        meta: {
          header: true,
          title: "Transportation Management | Detail"
        }
      }
    ]
  },
  {
    path: "/network/transport-management",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Transportation Management",
      permission: ROUTE_TRANSPORT.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-transport",
        component: EditTransport,
        meta: {
          header: true,
          title: "Transportation Management | Ubah",
          name: "Ubah",
          before: "/network/transport-management/:id"
        }
      }
    ]
  }
];
