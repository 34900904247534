import { Pagination } from "./Pagination";

export class CodIncomeEntities {
  pagination: Pagination;
  data: CodIncomeData[];
  constructor(pagination: Pagination, data: CodIncomeData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class CodIncomeData {
  genesisTransactionId: string;
  type: string;
  activity: string;
  amountTransaction: number;
  dateTransaction: string;
  transactionMadeBy: string;
  bankName: string;
  bankAccountNumber: string;
  bankAccountName: string;
  genesisReferenceId: string;
  sttNumber: string;
  status: string;
  note: string;
  sttElexysNumber: string;
  lastBalance: number;
  constructor(
    genesisTransactionId: string,
    type: string,
    activity: string,
    amountTransaction: number,
    dateTransaction: string,
    transactionMadeBy: string,
    bankName: string,
    bankAccountNumber: string,
    bankAccountName: string,
    genesisReferenceId: string,
    sttNumber: string,
    status: string,
    note: string,
    sttElexysNumber: string,
    lastBalance: number
  ) {
    this.genesisTransactionId = genesisTransactionId;
    this.type = type;
    this.activity = activity;
    this.amountTransaction = amountTransaction;
    this.dateTransaction = dateTransaction;
    this.transactionMadeBy = transactionMadeBy;
    this.bankName = bankName;
    this.bankAccountNumber = bankAccountNumber;
    this.bankAccountName = bankAccountName;
    this.genesisReferenceId = genesisReferenceId;
    this.sttNumber = sttNumber;
    this.status = status;
    this.note = note;
    this.sttElexysNumber = sttElexysNumber;
    this.lastBalance = lastBalance;
  }
}
