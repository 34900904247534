import {
  ApproveTopupApproval,
  RejectTopupApproval
} from "@/data/payload/api/TopupApprovalApiRequest";
import { TopupApprovalRequestInterface } from "@/data/payload/contracts/TopupApprovalRequest";
import { TopupApprovalMapper } from "@/data/persistences/mappers/TopupApprovalMapper";
import { TopupApprovalRepositoryInterface } from "@/data/persistences/repositories/contracts/TopupApprovalRepositoryInterface";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  ActorData,
  TopupApprovalData,
  TopupApprovalListEntities
} from "@/domain/entities/TopupApproval";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class TopupApprovalApiRepository
  implements TopupApprovalRepositoryInterface {
  private service: ApiService;
  private mapper: TopupApprovalMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: TopupApprovalMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getListTopupApproval(
    page: number,
    limit: number,
    search: string,
    partnerName: string,
    status: string,
    bankName: string,
    verifiedBy: string
  ): Promise<TopupApprovalListEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListTopupApproval(
        page,
        limit,
        search,
        partnerName,
        status,
        bankName,
        verifiedBy
      ),
      {}
    );
    return this.mapper.convertTopupApprovalListFromApi(resp);
  }

  public async getActorVerifiedList(): Promise<ActorData[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getActorVerifiedList(),
      {}
    );
    return this.mapper.convertDataActorVerifiedListFromApi(resp);
  }

  public async getDetail(id: number): Promise<TopupApprovalData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getTopupApprovalDetail(id),
      {}
    );
    return this.mapper.convertDataDetailTopUpApprovalFromApi(resp);
  }

  public async approveTopupApproval(
    payload: TopupApprovalRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivate(
      "post",
      this.endpoints.approveTopupApproval(),
      {},
      payload as ApproveTopupApproval
    );
    return this.mapper.convertApproveRejectTopupApprovalFromApi(resp);
  }

  public async rejectTopupApproval(
    payload: TopupApprovalRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivate(
      "post",
      this.endpoints.rejectTopupApproval(),
      {},
      payload as RejectTopupApproval
    );
    return this.mapper.convertApproveRejectTopupApprovalFromApi(resp);
  }
}
