import {
  BalanceTransactionEntities,
  RequestListBalanceTransaction,
  TransactionTypeGober
} from "@/domain/entities/BalanceTransaction";
import { BalanceTransactionApiRepository } from "@/app/infrastructures/repositories/api/BalanceTransactionApiRepository";
import { injectable } from "tsyringe";

@injectable()
export class BalanceTransactionPresenter {
  private repository: BalanceTransactionApiRepository;

  constructor(repository: BalanceTransactionApiRepository) {
    this.repository = repository;
  }

  public getListBalanceTransaction(
    params: RequestListBalanceTransaction
  ): Promise<BalanceTransactionEntities> {
    return this.repository.getListBalanceTransaction(params);
  }

  public getBalanceTransactionType(apiVersion: string): Promise<TransactionTypeGober> {
    return this.repository.getBalanceTransactionType(apiVersion);
  }
}
