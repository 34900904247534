import { ReportApiRepository } from "@/app/infrastructures/repositories/api/ReportApiRepository";
import {
  ReportApiRequest,
  ReportCheckData,
  ReportListDownload,
  ReportListDownloadRequest,
  LastSyncSttReport
} from "@/domain/entities/Report";
import { injectable } from "tsyringe";

@injectable()
export class ReportPresenter {
  private repository: ReportApiRepository;

  constructor(repo: ReportApiRepository) {
    this.repository = repo;
  }

  public async getDownloadReport(
    params: ReportApiRequest
  ): Promise<ReportCheckData> {
    return await this.repository.getDownloadReport(params);
  }

  public async getListDownloadReport(
    params: ReportListDownloadRequest
  ): Promise<ReportListDownload> {
    return await this.repository.getListDownloadReport(params);
  }

  public async getLastSyncSttReport(): Promise<LastSyncSttReport> {
    return await this.repository.getLastSyncSttReport();
  }
}
