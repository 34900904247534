import {
  RequestDexAssessmentL1,
  RequestDexAssessmentSummary
} from "@/domain/entities/DexAssessment";
import { ApiRequestList } from "@/domain/entities/MainApp";

export class DexAssessmentEndpoint {
  getListDexAssessment(params: RequestDexAssessmentL1) {
    return `/hydra/v1/dex-assessment?${params.toQueryString()}`;
  }
  getSummaryDexAssessment(params: RequestDexAssessmentSummary) {
    return `/hydra/v1/dex-assessment/summary?${params.toQueryString()}`;
  }
  assignDexAssessment() {
    return "/hydra/v1/dex-assessment/assign";
  }
  detailDexAssessment(id: any) {
    return `/hydra/v1/dex-assessment/${id}`;
  }
  submitTicketDexAssessment(id: any) {
    return `/hydra/v1/dex-assessment/${id}`;
  }
  getDownloadReport(params: ApiRequestList) {
    return `/hydra/v1/dex-assessment/download?${params.toQueryString()}`;
  }
  getSummaryTicket(params: ApiRequestList) {
    return `/hydra/v1/dex-assessment/dashboard/summary-ticket-queue-assign?${params.toQueryString()}`;
  }
  getSummaryTotalCs(params: ApiRequestList) {
    return `/hydra/v1/dex-assessment/dashboard/summary-total-cs?${params.toQueryString()}`;
  }
  getAgentList(params: ApiRequestList) {
    return `/hydra/v1/dex-assessment/dashboard/summary-list-cs?${params.toQueryString()}`;
  }
  getSummaryTicketPercentageDisputeL2(params: ApiRequestList) {
    return `/hydra/v1/dex-assessment/dashboard/summary-ticket-dispute?${params.toQueryString()}`;
  }
  getSummaryTicketPercentage(params: ApiRequestList) {
    return `/hydra/v1/dex-assessment/dashboard/summary-ticket?${params.toQueryString()}`;
  }
  getSummaryFake(params: ApiRequestList) {
    return `/hydra/v1/dex-assessment/dashboard/summary-fake-dex?${params.toQueryString()}`;
  }
  getSummaryTicketBasedRangeTime(params: ApiRequestList) {
    return `/hydra/v1/dex-assessment/dashboard/summary-total-ticket-by-time?${params.toQueryString()}`;
  }
  getSummaryTopDex(params: ApiRequestList) {
    return `/hydra/v1/dex-assessment/dashboard/summary-top-dex${
      params.isDispute ? "-dispute" : ""
    }?${params.toQueryString()}`;
  }
  getSummaryBacklog(params: ApiRequestList) {
    return `/hydra/v1/dex-assessment/dashboard/summary-backlog-ticket?${params.toQueryString()}`;
  }
  getSummaryAttempt(params: ApiRequestList) {
    return `/hydra/v1/dex-assessment/dashboard/summary-dex-attempt?${params.toQueryString()}`;
  }
  getSummaryByReason(params: ApiRequestList) {
    return `hydra/v1/dex-assessment/dashboard/summary-ticket-by-reason?${params.toQueryString()}`;
  }
  getTotalTicketPerDay(params: ApiRequestList) {
    return `hydra/v1/dex-assessment/dashboard/summary-total-ticket-per-day?${params.toQueryString()}`;
  }
  getBacklogAgent(params: ApiRequestList) {
    return `hydra/v1/dex-assessment/dashboard/summary-backlog-ticket-per-cs?${params.toQueryString()}`;
  }
  getDownload(params: ApiRequestList) {
    return `/hydra/v1/dex-assessment/dashboard/${
      params.endpoint
    }?${params.toQueryString()}`;
  }
  getDashboardConsole(params: ApiRequestList) {
    return `/hydra/v1/dex-assessment-dashboard-consolidator/${
      params.endpoint
    }?${params.toQueryString()}`;
  }
}
