import { Pagination } from "./Pagination";

export class BookingCommissionDetail {
  commissionId = 0;
  commissionCode = "";
  commissionBookingSource: any = "";
  commissionFor: any = "";
  commissionShipmentId: Array<any> = [];
  commissionOriginCity: any = "";
  commissionDestinationCity: any = "";
  commissionProduct: any = "";
  commissionCommodity: any = "";
  commissionTierBase: any = "";
  commissionPriceTierReference: any = "";
  commissionPriceReference: any = "";
  commissionType: any = "";
  commissionQuantifier: any = "";
  commissionStatus: any = "";
  commissionCreatedAt = new Date().toISOString();
  commissionCreatedBy = "";
  commissionUpdatedAt = new Date().toISOString();
  commissionUpdatedBy = "";
  commissionCalculationTier: Array<BookingCommissionCalculationTier> = [];
  constructor(fields?: Partial<BookingCommissionDetail>) {
    for (let i = 0; i < 5; i++) {
      this.commissionCalculationTier.push(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        new BookingCommissionCalculationTier({
          tier: i + 1
        })
      );
    }
    Object.assign(this, fields);
  }
}

export class BookingCommissionCalculationTier {
  tier = 0;
  startAmount = "";
  endAmount = "";
  commissionAmount = "";
  commissionAmountBranch = "";
  commissionAmountPickup = "";
  commissionAmountPickupPublishRate = "";
  constructor(fields?: Partial<BookingCommissionCalculationTier>) {
    Object.assign(this, fields);
  }
}

export class BookingCommissionEntities {
  pagination: Pagination;
  data: BookingCommissionData[];

  constructor(pagination: Pagination, data: BookingCommissionData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class BookingCommissionData {
  bcId: number;
  bcCode: string;
  bcBulkId: number;
  bcTierBase: string;
  bcTierPriceReference: number;
  bcCommissionReference: number;
  bcCommissionType: string;
  bcCommissionQuantifier: string;
  bcBookingSource: string;
  bcAppliedTo: string;
  bcShipmentPrefix: string;
  bcDetailStatus: string;
  bcCreatedBy: number;
  bcCreatedName: string;
  bcCreatedAt: string;
  bcUpdatedBy: string;
  bcUpdatedName: string;
  bcUpdatedAt: string;
  bcDetailId: number;
  bcDetailBcId: number;
  bcDetailOriginCityId: string;
  bcDetailOriginCityName: string;
  bcDetailDestinationCityId: string;
  bcDetailDestinationCityName: string;
  bcDetailProductName: string;
  bcDetailCommodityCode: string;
  bcDetailCommodityName: string;

  constructor(
    bcId: number,
    bcCode: string,
    bcBulkId: number,
    bcTierBase: string,
    bcTierPriceReference: number,
    bcCommissionReference: number,
    bcCommissionType: string,
    bcCommissionQuantifier: string,
    bcBookingSource: string,
    bcAppliedTo: string,
    bcShipmentPrefix: string,
    bcDetailStatus: string,
    bcCreatedBy: number,
    bcCreatedName: string,
    bcCreatedAt: string,
    bcUpdatedBy: string,
    bcUpdatedName: string,
    bcUpdatedAt: string,
    bcDetailId: number,
    bcDetailBcId: number,
    bcDetailOriginCityId: string,
    bcDetailOriginCityName: string,
    bcDetailDestinationCityId: string,
    bcDetailDestinationCityName: string,
    bcDetailProductName: string,
    bcDetailCommodityCode: string,
    bcDetailCommodityName: string
  ) {
    this.bcId = bcId;
    this.bcCode = bcCode;
    this.bcBulkId = bcBulkId;
    this.bcTierBase = bcTierBase;
    this.bcTierPriceReference = bcTierPriceReference;
    this.bcCommissionReference = bcCommissionReference;
    this.bcCommissionType = bcCommissionType;
    this.bcCommissionQuantifier = bcCommissionQuantifier;
    this.bcBookingSource = bcBookingSource;
    this.bcAppliedTo = bcAppliedTo;
    this.bcShipmentPrefix = bcShipmentPrefix;
    this.bcDetailStatus = bcDetailStatus;
    this.bcCreatedBy = bcCreatedBy;
    this.bcCreatedName = bcCreatedName;
    this.bcCreatedAt = bcCreatedAt;
    this.bcUpdatedBy = bcUpdatedBy;
    this.bcUpdatedName = bcUpdatedName;
    this.bcUpdatedAt = bcUpdatedAt;
    this.bcDetailId = bcDetailId;
    this.bcDetailBcId = bcDetailBcId;
    this.bcDetailOriginCityId = bcDetailOriginCityId;
    this.bcDetailOriginCityName = bcDetailOriginCityName;
    this.bcDetailDestinationCityId = bcDetailDestinationCityId;
    this.bcDetailDestinationCityName = bcDetailDestinationCityName;
    this.bcDetailProductName = bcDetailProductName;
    this.bcDetailCommodityCode = bcDetailCommodityCode;
    this.bcDetailCommodityName = bcDetailCommodityName;
  }
}
