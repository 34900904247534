import {
  BagListData,
  BagListEntities,
  ListSttData,
  ListSttEntities
} from "@/domain/entities/ListStt";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";


const formatInsuranceName = (isInsurance: boolean, insuranceName: string) =>
  isInsurance ? insuranceName?.replace(/insurance/i, "").trim() : "-";

export class ListSttMapper {
  public convertListSttDataFromApi(res: AxiosResponse<any>): ListSttEntities {
    const { data } = res;

    const listStt: ListSttData[] = [];
    if (data.data.length === 0) {
      return new ListSttEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        listStt.push(
          new ListSttData({
            sttId: item.stt_id,
            sttNo: item.stt_no,
            sttTotalPiece: item.stt_total_piece,
            sttDestinationDistrictName: item.stt_destination_district_name,
            sttDestinationCity: item.stt_destination_city,
            sttDestinationCityCode: item.stt_destination_city_code,
            sttChargeableWeight: item.stt_chargeable_weight,
            sttProductType: item.stt_product_type,
            sttVolumeWeight: item.stt_volume_weight,
            sttSenderName: item.stt_sender_name,
            sttRecipientName: item.stt_recipient_name,
            sttRecipientAddress: item.stt_recipient_address,
            sttIsInsurance: item.stt_is_insurance,
            sttInsuranceName: formatInsuranceName(item.stt_is_insurance, item.stt_insurance_name),
            sttIsCod: item.stt_is_cod,
            sttCodFee: item.stt_cod_fee,
            sttTotalAmount: item.stt_total_amount,
            sttTotalSurcharge: item.stt_total_surcharge,
            sttTotalInsurance: item.stt_total_insurance,
            sttShippingSurchargeRate: item.stt_shipping_surchage_rate,
            sttGoodsEstimationPrice: item.stt_goods_estimation_price,
            createdAt: item.stt_created_at,
            updatedAt: item.stt_updated_at,
            sttShippingSurchageRate: item.stt_shipping_surchage_rate,
            sttGoodsEstimatePrice: item.stt_goods_estimate_price,
            sttRecipientPhone: item.stt_recipient_phone,
            sttBmTaxRate: item.stt_bm_tax_rate,
            sttPpnTaxRate: item.stt_ppn_tax_rate,
            sttPphTaxRate: item.stt_pph_tax_rate,
            sttTotalGoodTax: item.stt_total_good_tax,
            sttCommodityType: item.stt_commodity_type,
            sttBookedName: item.stt_booked_name,
            sttBookedBy: item.stt_booked_by,
            sttLastStatusId: item.stt_last_status_id,
            sttLastStatusIdDescription: item.stt_last_status_id_description,
            sttIsGoodTax: item.stt_is_good_tax,
            sttDocumentSurchargeRate: item.stt_document_surcharge_rate,
            sttCommoditySurchargeRate: item.stt_commodity_surcharge_rate,
            sttHeavyweightSurchargeRate: item.stt_heavyweight_surcharge_rate,
            sttWoodpackingRate: item.stt_woodpacking_rate,
            sttCodAmount: item.stt_cod_amount,
            sttPublishRate: item.stt_publish_rate,
            sttOriginDistrictRate: item.stt_origin_district_rate,
            sttDestinationDistrictRate: item.stt_destination_district_rate,
            sttShipmentId: item.stt_shipment_id,
            sttIsPromo: item.stt_is_promo,
            sttTotalAmountAfterDiscount: item.stt_total_tariff_after_discount,
            sttTotalAmountBeforeDiscount: item.stt_total_tariff_before_discount,
            sttTotalDiscount: item.stt_total_discount,
            sttClientId: item.stt_client_id,
            sttForwardRate:
              item.stt_origin_district_rate + item.stt_destination_district_rate
          })
        );
      });
    }
    return new ListSttEntities(
      new Pagination(data.meta.page, data.meta.limit),
      listStt
    );
  }

  public convertListSttDataFromApiElexys(
    res: AxiosResponse<any>
  ): ListSttEntities {
    const { data } = res;

    const listStt: ListSttData[] = [];
    if (data.data.length === 0) {
      return new ListSttEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        const elexysData = !!item.stt_elexys && item.stt_elexys;
        if (elexysData && elexysData.se_elexys_stt_no) {
          listStt.push(
            new ListSttData({
              sttId: item.stt_id,
              sttNo: item.stt_no,
              sttTotalPiece: item.stt_total_piece,
              sttDestinationDistrictName: item.stt_destination_district_name,
              sttDestinationCity: item.stt_destination_city,
              sttDestinationCityCode: item.stt_destination_city_code,
              sttChargeableWeight: item.stt_chargeable_weight,
              sttProductType: item.stt_product_type,
              sttVolumeWeight: item.stt_volume_weight,
              sttSenderName: item.stt_sender_name,
              sttRecipientName: item.stt_recipient_name,
              sttRecipientAddress: item.stt_recipient_address,
              sttIsInsurance: item.stt_is_insurance,
              sttInsuranceName: formatInsuranceName(item.stt_is_insurance, item.stt_insurance_name),
              sttIsCod: item.stt_is_cod,
              sttCodFee: item.stt_cod_fee,
              sttTotalAmount: item.stt_total_amount,
              sttTotalSurcharge: item.stt_total_surcharge,
              sttTotalInsurance: item.stt_total_insurance,
              sttShippingSurchargeRate: item.stt_shipping_surchage_rate,
              sttGoodsEstimationPrice: item.stt_goods_estimation_price,
              createdAt: item.stt_created_at,
              updatedAt: item.stt_updated_at,
              sttShippingSurchageRate: item.stt_shipping_surchage_rate,
              sttGoodsEstimatePrice: item.stt_goods_estimate_price,
              sttRecipientPhone: item.stt_recipient_phone,
              sttBmTaxRate: item.stt_bm_tax_rate,
              sttPpnTaxRate: item.stt_ppn_tax_rate,
              sttPphTaxRate: item.stt_pph_tax_rate,
              sttTotalGoodTax: item.stt_total_good_tax,
              sttCommodityType: item.stt_commodity_type,
              sttBookedName: item.stt_booked_name,
              sttBookedBy: item.stt_booked_by,
              sttLastStatusId: item.stt_last_status_id,
              sttLastStatusIdDescription: item.stt_last_status_id_description,
              sttIsGoodTax: item.stt_is_good_tax,
              sttDocumentSurchargeRate: item.stt_document_surcharge_rate,
              sttCommoditySurchargeRate: item.stt_commodity_surcharge_rate,
              sttHeavyweightSurchargeRate: item.stt_heavyweight_surcharge_rate,
              sttWoodpackingRate: item.stt_woodpacking_rate,
              sttCodAmount: item.stt_cod_amount,
              sttPublishRate: item.stt_publish_rate,
              sttOriginDistrictRate: item.stt_origin_district_rate,
              sttDestinationDistrictRate: item.stt_destination_district_rate,
              sttShipmentId: item.stt_shipment_id,
              sttIsPromo: item.stt_is_promo,
              sttTotalAmountAfterDiscount: item.stt_total_tariff_after_discount,
              sttTotalAmountBeforeDiscount:
                item.stt_total_tariff_before_discount,
              sttTotalDiscount: item.stt_total_discount,
              sttClientId: item.stt_client_id,
              sttForwardRate:
                item.stt_origin_district_rate +
                item.stt_destination_district_rate
            })
          );
        } else {
          listStt.push(
            new ListSttData({
              sttId: item.stt_id,
              sttNo: item.stt_no,
              sttTotalPiece: item.stt_total_piece,
              sttDestinationDistrictName: item.stt_destination_district_name,
              sttDestinationCity: item.stt_destination_city,
              sttDestinationCityCode: item.stt_destination_city_code,
              sttChargeableWeight: item.stt_chargeable_weight,
              sttProductType: item.stt_product_type,
              sttVolumeWeight: item.stt_volume_weight,
              sttSenderName: item.stt_sender_name,
              sttRecipientName: item.stt_recipient_name,
              sttRecipientAddress: item.stt_recipient_address,
              sttIsInsurance: item.stt_is_insurance,
              sttInsuranceName: formatInsuranceName(item.stt_is_insurance, item.stt_insurance_name),
              sttIsCod: item.stt_is_cod,
              sttCodFee: item.stt_cod_fee,
              sttTotalAmount: item.stt_total_amount,
              sttTotalSurcharge: item.stt_total_surcharge,
              sttTotalInsurance: item.stt_total_insurance,
              sttShippingSurchargeRate: item.stt_shipping_surchage_rate,
              sttGoodsEstimationPrice: item.stt_goods_estimation_price,
              createdAt: item.stt_created_at,
              updatedAt: item.stt_updated_at,
              sttShippingSurchageRate: item.stt_shipping_surchage_rate,
              sttGoodsEstimatePrice: item.stt_goods_estimate_price,
              sttRecipientPhone: item.stt_recipient_phone,
              sttBmTaxRate: item.stt_bm_tax_rate,
              sttPpnTaxRate: item.stt_ppn_tax_rate,
              sttPphTaxRate: item.stt_pph_tax_rate,
              sttTotalGoodTax: item.stt_total_good_tax,
              sttCommodityType: item.stt_commodity_type,
              sttBookedName: item.stt_booked_name,
              sttBookedBy: item.stt_booked_by,
              sttLastStatusId: item.stt_last_status_id,
              sttLastStatusIdDescription: item.stt_last_status_id_description,
              sttIsGoodTax: item.stt_is_good_tax,
              sttDocumentSurchargeRate: item.stt_document_surcharge_rate,
              sttCommoditySurchargeRate: item.stt_commodity_surcharge_rate,
              sttHeavyweightSurchargeRate: item.stt_heavyweight_surcharge_rate,
              sttWoodpackingRate: item.stt_woodpacking_rate,
              sttCodAmount: item.stt_cod_amount,
              sttPublishRate: item.stt_publish_rate,
              sttOriginDistrictRate: item.stt_origin_district_rate,
              sttDestinationDistrictRate: item.stt_destination_district_rate,
              sttShipmentId: item.stt_shipment_id,
              sttIsPromo: item.stt_is_promo,
              sttTotalAmountAfterDiscount: item.stt_total_tariff_after_discount,
              sttTotalAmountBeforeDiscount:
                item.stt_total_tariff_before_discount,
              sttTotalDiscount: item.stt_total_discount,
              sttClientId: item.stt_client_id,
              sttForwardRate:
                item.stt_origin_district_rate +
                item.stt_destination_district_rate
            })
          );
        }
      });
    }
    return new ListSttEntities(
      new Pagination(data.meta.page, data.meta.limit),
      listStt
    );
  }

  public convertListBagDataFromApi(res: AxiosResponse<any>): BagListEntities {
    const { data } = res;

    const listBag: BagListData[] = [];
    data.data.map((item: any) => {
      listBag.push(
        new BagListData({
          bagId: item.bag_id,
          bagCode: item.bag_code,
          bagPartnerId: item.bag_partner_id,
          bagPartnerCode: item.bag_partner_code,
          bagPartnerName: item.bag_partner_name,
          bagOriginCityCode: item.bag_origin_city_code,
          bagOriginCityName: item.bag_origin_city_name,
          bagOriginTransitCityCode: item.bag_origin_transit_city_code,
          bagOriginTransitCityName: item.bag_origin_transit_city_name,
          bagDestinationCityCode: item.bag_destination_city_code,
          bagDestinationCityName: item.bag_destination_city_name,
          bagTotalStt: item.bag_total_stt,
          bagTotalGrossWeight: item.bag_total_gross_weight,
          bagTotalChargeableWeight: item.bag_custom_chargeable_weight,
          bagTotalVolumeWeight: item.bag_total_volume_weight,
          bagTotalPiece: item.bag_total_piece,
          bagStatusUpdatedAt: item.bag_status_updated_at,
          bagCreatedAt: item.bag_created_at,
          bagCreatedBy: item.bag_created_by,
          bagCreatedName: item.bag_created_name,
          bagUpdatedAt: item.bag_updated_at,
          bagUpdatedName: item.bag_updated_name,
          bagCustomGrossWeight: item.bag_custom_gross_weight,
          bagCustomVolumeWeight: item.bag_custom_volume_weight,
          bagCustomLength: item.bag_custom_length,
          bagCustomWidth: item.bag_custom_width,
          bagCustomHeight: item.bag_custom_height,
          bagProductType: item.bag_product_type,
          bagHubId: item.bag_hub_id,
          bagHubName: item.bag_hub_name,
          bagCommodityGroup: item.BagCommodityGroup
        })
      );
    });
    return new BagListEntities(listBag);
  }
}
