import { USER_MANAGEMENT } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const UserManagement = () =>
  import(
    /* webpackChunkName: "UserManagement" */ "@/app/ui/views/user-management/index.vue"
  );
const DetailUserManagement = () =>
  import(
    /* webpackChunkName: "UserManagement" */ "@/app/ui/views/user-management/component/detail-user.vue"
  );
const EditUserManagement = () =>
  import(
    /* webpackChunkName: "UserManagement" */ "@/app/ui/views/user-management/component/edit.vue"
  );
const AddUserManagement = () =>
  import(
    /* webpackChunkName: "UserManagement" */ "@/app/ui/views/user-management/component/add.vue"
  );

export default [
  {
    path: "/user-management",
    component: Layout,
    redirect: "/user-management",
    name: "user-management",
    meta: {
      icon: "user-management",
      children: false,
      title: "User Management",
      name: "User Management",
      permission: USER_MANAGEMENT.ENABLE
    },
    children: [
      {
        path: "/user-management",
        name: "user-management",
        component: UserManagement,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/user-management",
    component: Layout,
    name: "user-management-detail",
    meta: {
      title: "User Management | Detail",
      hidden: true,
      parent: "User Management",
      permission: USER_MANAGEMENT.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-user",
        component: DetailUserManagement,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/user-management",
    component: Layout,
    name: "edit-user-management",
    meta: {
      children: false,
      hidden: true,
      parent: "User Management",
      permission: USER_MANAGEMENT.EDIT
    },
    children: [
      {
        path: "/user-management/edit/:id",
        name: "edit-user",
        component: EditUserManagement,
        meta: {
          header: true,
          title: "User Management | Edit",
          name: "Edit",
          before: "/user-management/:id"
        }
      }
    ]
  },
  {
    path: "/user-management",
    component: Layout,
    name: "user-management-add",
    meta: {
      icon: "route",
      children: false,
      hidden: true,
      parent: "User Management",
      permission: USER_MANAGEMENT.CREATE
    },
    children: [
      {
        path: "/user-management/add",
        name: "add-user",
        component: AddUserManagement,
        meta: {
          header: true,
          title: "User Management | Create",
          name: "Buat User",
          before: "/user-management"
        }
      }
    ]
  }
];
