import {
  CARGO,
  READY_TO_CARGO
} from "@/app/infrastructures/misc/RolePermission";

import CargoModules from "./cargo";
import { flags } from "@/feature-flags";
const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

// Cargo
const Cargo = () =>
  import(/* webpackChunkName: "Cargo" */ "@/app/ui/views/cargo/index.vue");
const TabReadyToCargo = () =>
  import(
    /* webpackChunkName: "RTCargo" */ "@/app/ui/views/cargo/ready-to-cargo/tab-ready-to-cargo.vue"
  );
const TabProcessReadyToCargo = () =>
  import(
    /* webpackChunkName: "RTCargo" */ "@/app/ui/views/cargo/ready-to-cargo/tab-process-ready-to-cargo.vue"
  );
const ReadyToCargo = () =>
  import(
    /* webpackChunkName: "RTCargo" */ "@/app/ui/views/cargo/ready-to-cargo/index.vue"
  );

const bookingSuccess = () =>
  import(
    /* webpackChunkName: "Cargo" */ "@/app/ui/views/cargo/tab-booking-success.vue"
  );
const bookingInProgress = () =>
  import(
    /* webpackChunkName: "Cargo" */ "@/app/ui/views/cargo/tab-booking-in-progress.vue"
  );
const bookingCanceled = () =>
  import(
    /* webpackChunkName: "Cargo" */ "@/app/ui/views/cargo/tab-booking-canceled.vue"
  );
export default [
  {
    path: "",
    redirect: "/dashboard",
    component: Layout,
    name: "cargo",
    meta: {
      header: true,
      children: true,
      icon: "cargo",
      title: "Kargo - Booking Kargo",
      name: "Kargo",
      permission: [CARGO.ENABLE, READY_TO_CARGO.ENABLE]
    },
    children: [
      {
        path: "cargo",
        name: "booking-cargo",
        redirect: "/cargo/booking-success",
        component: Cargo,
        meta: {
          header: true,
          name: "Booking Kargo",
          icon: "booking-cargo",
          isFreeze: true,
          permission: [CARGO.ENABLE, flags.permission_single_menu_cargo_enable]
        },
        children: [
          {
            path: "booking-success",
            name: "booking-success",
            component: bookingSuccess,
            meta: {
              header: true
            }
          },
          {
            path: "booking-in-progress",
            name: "booking-in-progress",
            component: bookingInProgress,
            meta: {
              header: true
            }
          },
          {
            path: "booking-canceled",
            name: "booking-canceled",
            component: bookingCanceled,
            meta: {
              header: true
            }
          }
        ]
      },
      {
        path: "siap-kargo",
        name: "siap-kargo",
        redirect: "/siap-kargo/proses-siap-kargo",
        component: ReadyToCargo,
        meta: {
          header: true,
          name: "Siap DiKargo",
          icon: "ready-to-cargo",
          permission: READY_TO_CARGO.LIST,
        },
        children: [
          {
            path: "proses-siap-kargo",
            name: "proses-siap-kargo",
            component: TabProcessReadyToCargo,
            meta: {
              header: true
            }
          },
          {
            path: "list-siap-kargo",
            name: "list-siap-kargo",
            component: TabReadyToCargo,
            meta: {
              header: true
            }
          },

        ]
      },
    ]
  },
  ...CargoModules
];
