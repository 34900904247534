import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  ErrorMessageEntities,
  ModalMessageEntities
} from "@/domain/entities/MainApp";

export interface MainAppState {
  errorMessageModal: boolean;
  errorMessageEntities: ErrorMessageEntities;
  messageModal: boolean;
  messageEntities: ModalMessageEntities;
  loadingModal: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "app-layout" })
class MainAppStore extends VuexModule implements MainAppState {
  // error message
  errorMessageModal = false;
  responseModal = false;
  errorMessageEntities = new ErrorMessageEntities();
  @Action
  showErrorMessage(params: ErrorMessageEntities) {
    this.setErrorMessageEntities(params);
    this.setOpenErrorMessage(true);
  }
  @Action
  closeErrorMessage() {
    this.setErrorMessageEntities(new ErrorMessageEntities());
    this.setOpenErrorMessage(false);
  }
  @Mutation
  setOpenErrorMessage(bool: boolean) {
    this.errorMessageModal = bool;
  }
  @Mutation
  showResponseModal() {
    this.responseModal = true;
  }
  @Mutation
  closeResponseModal() {
    this.responseModal = false;
  }
  @Mutation
  setErrorMessageEntities(error: ErrorMessageEntities) {
    this.errorMessageEntities = error;
  }

  // modal message
  messageModal = false;
  messageEntities = new ModalMessageEntities();
  @Action
  showMessageModal(params: ModalMessageEntities) {
    this.setMessageEntities(params);
    this.setOpenMessageModal(true);
  }
  @Action
  closeMessageModal() {
    this.setMessageEntities(new ModalMessageEntities());
    this.setOpenMessageModal(false);
  }
  @Mutation
  setOpenMessageModal(bool: boolean) {
    this.messageModal = bool;
  }
  @Mutation
  setMessageEntities(data: ModalMessageEntities) {
    this.messageEntities = data;
  }

  // loading
  loadingModal = false;
  @Action
  showLoading() {
    this.setLoadingModal(true);
  }
  @Action
  closeLoading() {
    this.setLoadingModal(false);
  }
  @Mutation
  setLoadingModal(bool: boolean) {
    this.loadingModal = bool;
  }

  // snackbar
  snackbar = false;
  snackbarMessage = "";
  snackbarPositive = true;
  snackbarTimeout = 0;
  snackbarAutoClose = false;
  @Mutation
  setSnackbar(bool: boolean) {
    this.snackbar = bool;
  }
  @Mutation
  setSnackbarMessage(val: string) {
    this.snackbarMessage = val;
  }
  @Mutation
  setSnackbarPositivity(val: boolean) {
    this.snackbarPositive = val;
  }
  @Mutation
  setSnackbarTimeout(val: number) {
    this.snackbarTimeout = val;
  }
  @Mutation
  setAutoClose(val: boolean) {
    this.snackbarAutoClose = val;
  }

  // screen size
  public screenSize = window.screen;
}

export const MainAppController = getModule(MainAppStore);
