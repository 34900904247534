import { injectable } from "tsyringe";
import { EmailApiRepository } from "@/app/infrastructures/repositories/api/EmailApiRepository";
import { EmailRequestInterface } from "@/data/payload/contracts/EmailRequest";

@injectable()
export class EmailPresenter {
  private repository: EmailApiRepository;

  constructor(repository: EmailApiRepository) {
    this.repository = repository;
  }

  public sendPrintReceiptEmail(payload: EmailRequestInterface) {
    return this.repository.sendPrintReceiptEmail(payload);
  }
}
