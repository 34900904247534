import { RequestListPickupList } from "@/domain/entities/ListPickup";

export class ListPickupEndpoints {
  getListPickupList(params: RequestListPickupList) {
    return `/hydra/v1/pickup-corporate?${params.toQueryString()}`;
  }

  getListPickupDetail(id: any) {
    return `/hydra/v1/pickup-corporate/${id}`;
  }

  cancelPickup() {
    return `/hydra/v1/pickup-corporate/cancel`;
  }

  createPickup() {
    return `/hydra/v1/pickup-corporate`;
  }
}
