import { LocationSearchEntities } from "@/domain/entities/LocationSearch";
import { LocationSearchMapper } from "@/data/persistences/mappers/LocationSearchMapper";
import { LocationSearchRepositoryInterface } from "@/data/persistences/repositories/contracts/LocationSearchRepositoryInterface";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class LocationSearchApiRepository
  implements LocationSearchRepositoryInterface {
  private service: ApiService;
  private mapper: LocationSearchMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: LocationSearchMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  public async getLocationSearchList(
    page: number,
    limit: number,
    search: string
  ): Promise<LocationSearchEntities> {
    const res = await this.service.invoke(
      "get",
      this.endpoints.getLocationSearchList(page, limit, search),
      {}
    );
    return this.mapper.convertLocationSearchFromApi(res);
  }
}
