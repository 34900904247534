import {
  GetScheduleListRequestInterface,
  TrackingRequestInterface,
  PrintAWBRequestInterface
} from "../contracts/NGenRequest";

export class GetScheduleListApiRequest
  implements GetScheduleListRequestInterface {
  Origin = "";
  Destination = "";
  FlightDate = "";
  GrossWeight = "";
  VolumeWeight = "";
  ETD = "";
  ProductSno = "";
  IsRootStation = 0;
  FlightNo = "";

  constructor(fields: Partial<GetScheduleListApiRequest>) {
    Object.assign(this, fields);
  }
  toJSON(): string {
    return JSON.stringify({
      Origin: this.Origin,
      Destination: this.Destination,
      FlightDate: this.FlightDate,
      GrossWeight: this.GrossWeight,
      VolumeWeight: this.VolumeWeight,
      ETD: this.ETD,
      ProductSno: this.ProductSno,
      IsRootStation: this.IsRootStation,
      FlightNo: this.FlightNo
    });
  }
}

export class TrackingCargoHistoryNgenApiRequest
  implements TrackingRequestInterface {
  AWB: string;
  constructor(AWB: string) {
    this.AWB = AWB;
  }
  toJSON(): string {
    return JSON.stringify({
      AWB: this.AWB
    });
  }
}

export class TrackingCargoByBookingRequestIdNgenApiRequest
  implements TrackingRequestInterface {
  BookingRequestID: any;
  constructor(BookingRequestID: any) {
    this.BookingRequestID = BookingRequestID;
  }
  toJSON(): string {
    return JSON.stringify({
      BookingRequestID: [this.BookingRequestID]
    });
  }
}

export class PrintAWBApiRequest implements PrintAWBRequestInterface {
  private AWBNo: number;

  constructor(AWBNo: number) {
    this.AWBNo = AWBNo;
  }

  toJSON(): string {
    return JSON.stringify({
      AWBNo: this.AWBNo
    });
  }
}
