import { STIApiRepository } from "@/app/infrastructures/repositories/api/STIApiRepository";
import { injectable } from "tsyringe";
import {
  DetailSttPiecesSTI,
  STIEntities,
  STIListBookedEntities,
  STIReverseScanDetail,
  STIReverseScanGenerate,
  STIReverseScanTemporary,
  STISTTDueEntities,
  STISTTDueSummary,
  StiManifestData,
} from "@/domain/entities/STI";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { StiRequestInterface } from "@/data/payload/contracts/StiApiRequest";
import {
  STIReverseScanTemporaryListRequest,
  StiListRequest,
  GenerateSTIReverseScanRequest,
  STIReverseScanManifestRequest,
  STISTTDueSummaryListRequest,
  STISTTDueListRequest,
} from "@/data/payload/api/StiApiRequest";
import { STIRepositoryInterface } from "@/data/persistences/repositories/contracts/STIRepositoryInterface";

@injectable()
export class STIPresenter implements STIRepositoryInterface {
  private repository: STIApiRepository;

  constructor(repository: STIApiRepository) {
    this.repository = repository;
  }

  public getListSTI(
    params: StiListRequest,
    version = "v1"
  ): Promise<STIEntities> {
    return this.repository.getListSTI(params, version);
  }

  public validationScanSTTPiece(sttPiece: string): Promise<ResponsePayload> {
    return this.repository.validationScanSTTPiece(sttPiece);
  }

  public getDetailSttPieceNumberSti(
    sttNumber: string
  ): Promise<DetailSttPiecesSTI> {
    return this.repository.getDetailSttPieces(sttNumber);
  }

  public getDetailSttPieceNumberStiLilo(
    bagNo: string
  ): Promise<DetailSttPiecesSTI> {
    return this.repository.getDetailSttPiecesLilo(bagNo);
  }

  public async generateSti(
    payload: StiRequestInterface
  ): Promise<ResponsePayload> {
    return await this.repository.generateSti(payload);
  }

  public getStiManifestData(id: number, version = "v1"): Promise<StiManifestData> {
    return this.repository.getStiManifestData(id, version);
  }

  public async downloadSti(
    id: number,
    version = "v1",
    noSti = false
  ): Promise<any> {
    return this.repository.downloadSti(id, version, noSti);
  }

  public async getDetailSttPieces(
    sttPieceNumber: string
  ): Promise<DetailSttPiecesSTI> {
    return this.repository.getDetailSttPieces(sttPieceNumber);
  }

  public async getListSTIReverseScanTemporary(
    params: STIReverseScanTemporaryListRequest
  ): Promise<STIReverseScanTemporary[]> {
    return this.repository.getListSTIReverseScanTemporary(params);
  }

  public async generateSTIReverseScan(
    payload: GenerateSTIReverseScanRequest
  ): Promise<STIReverseScanGenerate> {
    return this.repository.generateSTIReverseScan(payload);
  }

  public async getDetailSTIReverseScan(
    id: number
  ): Promise<STIReverseScanDetail> {
    return this.repository.getDetailSTIReverseScan(id);
  }

  public async stiProcessReverse(
    payload: STIReverseScanManifestRequest
  ): Promise<ResponsePayload> {
    return this.repository.stiProcessReverse(payload);
  }

  public async getSTISTTDueSummary(
    params: STISTTDueSummaryListRequest
  ): Promise<STISTTDueSummary> {
    return this.repository.getSTISTTDueSummary(params);
  }

  public async getBookedList(): Promise<STIListBookedEntities> {
    return this.repository.getBookedList();
  }

  public async getSTTDueList(
    params: STISTTDueListRequest
  ): Promise<STISTTDueEntities> {
    return this.repository.getSTTDueList(params);
  }

  public async downloadSttDue(params: STISTTDueListRequest): Promise<any> {
    return this.repository.downloadSttDue(params);
  }
}
