import { Pagination } from "./Pagination";

export class LegEntities {
  pagination: Pagination;
  data: LegData[];
  constructor(pagination: Pagination, data: LegData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class LegData {
  id = 0;
  legName = "";
  legCode = "";
  legOrigin = "";
  legDestination = "";
  legType = "";
  status = "";
  minSla: any = "";
  maxSla: any = "";
  createdAt = "";
  updatedAt = "";
  createdBy = "";
  updatedBy = "";
  createdName = "";
  updatedName = "";
  vendorName = "";
  transport = "";
  transportId = 0;
  constructor(fields?: Partial<LegData>) {
    Object.assign(this, fields);
  }
}

export class TransportData {
  status: string;
  constructor(status: string) {
    this.status = status;
  }
}
