    /* eslint-disable @typescript-eslint/camelcase */

import {
  ShipmentBooking,
  ShipmentBookingDetail,
  SttClient,
  SttPieces,
  SttManualValidation,
  ShipmentPrefix,
  SttReasonMapping,
  DefaultDimensions,
  CBPPickupListEntities,
  CBPPickup,
  CBPPickupManifest
} from "@/domain/entities/ShipmentBooking";
import { DetailDiscount } from "@/domain/entities/Tariff";
import { Pagination, PaginationV2 } from "@/domain/entities/Pagination";
import { Entities, ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  formatInputMoney,
  removeNewLineWhitespace
} from "@/app/infrastructures/misc/Utils";
import {
  codePhoneCountry,
  MAX_WEIGHT_SHIPMENT,
  prefixDisableDimensionC1
} from "@/app/ui/views/shipment/booking/booking-utils";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { FlagsFeatureShipmentC1 } from "@/feature-flags/flags-shipment-c1";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ManualBooking } from "@/domain/entities/Booking";
export class ShipmentBookingMapper {
  public convertShipmentBookingFromApi(result: AxiosResponse): Entities {
    const isMigratingFromElexys =
      process.env.VUE_APP_MIGRATE_FROM_ELEXYS === "true";
    const { data } = result;
    return new Entities(
      data.success,
      data.code,
      data.message,
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      data.data.map((item: any) => {
        return new ShipmentBooking(
          item.stt_id,
          item.stt_no,
          isMigratingFromElexys && item.stt_elexys?.se_elexys_stt_no
            ? item.stt_elexys?.se_elexys_stt_no
            : "",
          item.stt_shipment_id,
          item.stt_recipient_address,
          item.stt_commodity_name,
          item.stt_client_id,
          item.stt_pos_id,
          item.stt_is_cod,
          item.stt_is_dfod,
          item.stt_product_type,
          isMigratingFromElexys && item.stt_elexys?.se_chargeable_weight
            ? item.stt_elexys?.se_chargeable_weight
            : item.stt_chargeable_weight,
          item.stt_total_piece,
          item.stt_last_status_id,
          item.stt_last_status_id_description.replace(/\n/g, "<br />"),
          item.stt_warning_status,
          item.stt_warning_status_description,
          item.stt_counter,
          item.stt_insurance_type.replace("Insurance ", ""),
          item.stt_origin_city,
          item.stt_destination_city,
          item.stt_booked_name,
          item.stt_billed_to,
          item.stt_created_name,
          item.stt_created_at,
          item.stt_updated_at,
          item.stt_payment_status,
          item.stt_payment_date_at,
          item.stt_origin_city_name,
          item.stt_destination_city_name,
          item.stt_updated_by_name,
          item.process_state,
          item.stt_is_pad,
          item.total_stt_dex || 0,
          item.dex_codrej_reason || "",
          item.stt_assessment_status
        );
      })
    );
  }

  public convertCBPPickupListEntities(result: AxiosResponse): CBPPickupListEntities {
    const { data } = result;
    return new CBPPickupListEntities({
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.limit,
        totalData: data.meta.total_records
    }), 
      data: data.data?.length ? data.data?.map((item: any) => new CBPPickup(
      {
        sttId: item.stt_id,
        sttNo: item.stt_no,
        sttBilledTo: item.stt_billed_to,
        sttShipmentId: item.stt_shipment_id,
        sttRecipientAddress: item.stt_recipient_address,
        sttOriginCity: item.stt_origin_city,
        sttDestinationCity: item.stt_destination_city,
        sttLastStatusId: item.stt_last_status_id,
        sttCreatedName: item.stt_created_name,
        sttCreatedAt: item.stt_created_at,
        sttUpdatedAt: item.stt_updated_at,
        sttClientId: item.stt_client_id,
        sttChargeAbleWeight: item.stt_chargeable_weight,
        sttProductType: item.stt_product_type,
        sttCommodityCode: item.stt_commodity_code,
        sttCommodityName: item.stt_commodity_name,
        sttTotalPiece: item.stt_total_piece,
        sttPosId: item.stt_pos_id,
        sttIsCod: item.stt_is_cod,
        sttIsDfod: item.stt_is_dfod,
        sttIsPad: item.stt_is_pad,
        sttCounter: item.stt_counter,
        sttBookedName: item.stt_booked_name,
        pmcStatus: item.pmc_status,
      }
    )) : []})
  }


  public convertDetailShipmentBookingFromApi(
    result: AxiosResponse
  ): ShipmentBookingDetail {
    const {
      data: { data }
    } = result;
    const sender = codePhoneCountry(data.stt_sender_phone);
    const recipient = codePhoneCountry(data.stt_recipient_phone);

    const isCod = { is: data.stt_cod === "Yes", label: "COD" };
    const isPad = { is: data.stt_pad === "Yes", label: "PAD" };
    const sttCodType: string[] = [];
    [isCod, isPad].forEach((e: any) => {
      if (e.is) {
        sttCodType.push(e.label);
      }
    });

    const isPromo = data.stt_is_promo && data.list_discount?.length > 0;

    let lengthOfDiscount = 0;
    let shipmentChargeDiscount = 0;
    let cityRateDiscount = 0;
    let forwardRateDiscount = 0;

    if (data.list_discount) {
      lengthOfDiscount = data.list_discount?.length - 1;

      shipmentChargeDiscount =
        data.list_discount[lengthOfDiscount]?.tariff.publish_rate +
        data.list_discount[lengthOfDiscount]?.tariff.shipping_surcharge_rate +
        data.list_discount[lengthOfDiscount]?.tariff.origin_district_rate +
        data.list_discount[lengthOfDiscount]?.tariff.destination_district_rate;

      cityRateDiscount =
        data.list_discount[lengthOfDiscount]?.tariff.publish_rate +
        data.list_discount[lengthOfDiscount]?.tariff.shipping_surcharge_rate;

      forwardRateDiscount =
        data.list_discount[lengthOfDiscount]?.tariff.origin_district_rate +
        data.list_discount[lengthOfDiscount]?.tariff.destination_district_rate;
    }

    return new ShipmentBookingDetail({
      sttId: data.stt_id,
      sttNo: data.stt_no,
      sttShipmentId: data.stt_shipment_id,
      sttOriginAddress: data.stt_origin_address,
      sttDestinationAddress: data.stt_destination_address,
      sttNoRefExternal: data.stt_no_ref_external,
      sttSenderName: data.stt_sender_name,
      sttSenderPhone: sender.number,
      sttSenderPhoneCode: sender.code,
      sttSenderPhoneFlag: sender.flag,
      sttSenderAddress: removeNewLineWhitespace(data.stt_sender_address, " "),
      sttRecipientName: data.stt_recipient_name,
      sttRecipientPhone: recipient.number,
      sttRecipientPhoneCode: recipient.code,
      sttRecipientPhoneFlag: recipient.flag,
      sttRecipientAddress: removeNewLineWhitespace(
        data.stt_recipient_address,
        " "
      ),
      sttRecipientAddressType:
        !data.stt_recipient_address_type &&
        (data.last_status_stt_return === "RTS" ||
          data.last_status_stt_return === "RSTHQ" ||
          data.last_status_stt_return === "REROUTE")
          ? "home"
          : data.stt_recipient_address_type,
      sttCommodityName: data.stt_commodity_name
        ? data.stt_commodity_name
        : FlagsMedusa.config_commodity_general_others_name.getValue(),
      sttCommodityId: data.stt_commodity_id
        ? data.stt_commodity_id
        : FlagsMedusa.config_commodity_general_others_id.getValue(),
      sttCommodityCode: data.stt_commodity_code
        ? data.stt_commodity_code
        : FlagsMedusa.config_commodity_general_others_code.getValue(),
      sttProductTypeName: data.stt_product_type_name,
      sttTotalPiece: data.stt_total_piece,
      sttTotalChargeAbleWeight: data.stt_total_chargeable_weight,
      sttGoodsEstimatePrice: formatInputMoney(
        data.stt_goods_estimate_price ? data.stt_goods_estimate_price : 0
      ),
      sttGoodsEstimatePriceForDetail: data.stt_goods_estimate_price ? data.stt_goods_estimate_price : 0,
      sttTaxNumber: data.stt_tax_number,
      sttInsuranceName: data.stt_insurance_name,
      sttInsuranceNameDefault: data.stt_insurance_name,
      sttBookedBy: data.stt_booked_by,
      sttBilledTo: data.stt_billed_to,
      sttSource: data.stt_source,
      sttCod: data.stt_cod,
      codHandling: data.cod_handling,
      sttReturnCod: data.stt_return_do,
      sttShipmentPrice: isPromo
        ? shipmentChargeDiscount
        : data.stt_shipment_price,
      sttShippingSurchargeRate: data.stt_shipping_surcharge_rate,
      sttDocumentSurchargeRate: isPromo
        ? data.list_discount[lengthOfDiscount]?.tariff.document_surcharge
        : data.stt_document_surcharge_rate,
      sttCommoditySurchargeRate: isPromo
        ? data.list_discount[lengthOfDiscount]?.tariff.commodity_surcharge
        : data.stt_commodity_surcharge_rate,
      sttHeavyWeightSurchargeRate: isPromo
        ? data.list_discount[lengthOfDiscount]?.tariff.heavy_weight_surcharge
        : data.stt_heavyweight_surcharge_rate,
      sttInsuranceRate: isPromo
        ? data.list_discount[lengthOfDiscount]?.tariff.insurance_rates
        : data.stt_insurance_rate,
      sttWoodPackingRate: data.stt_woodpacking_rate,
      sttTaxRate: data.stt_tax_rate,
      sttCityRate: isPromo ? cityRateDiscount : data.stt_city_rate,
      sttForwardRate: isPromo ? forwardRateDiscount : data.stt_forward_rate,
      sttTotalTariff: data.stt_total_tariff,
      sttLastStatus: data.stt_last_status,
      sttLastStatusCounter: data.stt_last_status_counter,
      sttLastStatusDescription: data.stt_last_status_description.replace(
        /\n/g,
        "<br />"
      ),
      sttWarningStatus: data.stt_warning_status,
      sttWarningStatusDescription: data.stt_warning_status_description,
      sttCreatedAt: data.stt_created_at,
      sttCreatedBy: data.stt_created_by,
      sttUpdatedAt: data.stt_updated_at,
      sttUpdatedBy: data.stt_updated_by,
      SttPieces: data.stt_piece.map(
        (item: any) =>
          new SttPieces(
            item.stt_piece_id,
            item.stt_piece_gross_weight,
            {
              length: item.stt_piece_length,
              width: item.stt_piece_width,
              height: item.stt_piece_height
            },
            item.stt_piece_volume_weight,
            "1",
            data.stt_shipment_id &&
            !(
              (AccountController.accountData.isInternalAccount ||
                prefixDisableDimensionC1(
                  String(AccountController.accountData.account_id)
                )) &&
              new RegExp(
                FlagsFeatureShipmentC1.feature_shipment_c1.getValue(),
                "i"
              ).test(data.stt_shipment_id)
            )
              ? item.stt_piece_gross_weight < MAX_WEIGHT_SHIPMENT
              : false,
            false,
          ),
      ),
      SttPiecesInitial: data.stt_piece.map(
        (item: any) =>
          new SttPieces(
            item.stt_piece_id,
            item.stt_piece_gross_weight,
            {
              length: item.stt_piece_length,
              width: item.stt_piece_width,
              height: item.stt_piece_height
            },
            item.stt_piece_volume_weight,
            "1",
            data.stt_shipment_id &&
            !(
              (AccountController.accountData.isInternalAccount ||
                prefixDisableDimensionC1(
                  String(AccountController.accountData.account_id)
                )) &&
              new RegExp(
                FlagsFeatureShipmentC1.feature_shipment_c1.getValue(),
                "i"
              ).test(data.stt_shipment_id)
            )
              ? item.stt_piece_gross_weight < MAX_WEIGHT_SHIPMENT
              : false,
            false
          )
      ),
      sttSenderStatus: data.stt_sender_status
        ? data.stt_sender_status
        : "ecommerce",
      sttWoodpackingRate: data.stt_woodpacking_rate,
      sttColumnDisabled: data.stt_source === "customer-apps",
      sttIsWoodPacking: data.stt_is_woodpacking,
      sttIsWoodPackingKeeped: data.stt_is_woodpacking,
      sttIsCod: isCod.is,
      sttIsPad: isPad.is,
      sttCodType,
      sttOriginCityId: data.stt_origin_city_id,
      sttOriginDistrictId: data.stt_origin_district_id,
      sttDestinationCityId: data.stt_destination_city_id,
      sttDestinationDistrictId: data.stt_destination_district_id,
      sttDestinationDistrictName: data.stt_destination_district_name,
      sttIsAllowEdit: data.stt_is_allow_edit,
      sttIsAllowCancel: data.stt_is_allow_cancel,
      sttClient: data.stt_client
        ? new SttClient({
            clientId: data.stt_client.client_id,
            clientCode: data.stt_client.client_code,
            clientName: data.stt_client.client_name,
            clientPhone: data.stt_client.client_phone,
            clientEmail: data.stt_client.client_email,
            clientTaxNumber: data.stt_client.client_tax_number,
            clientStatus: data.stt_client.client_status,
            clientAddress: data.stt_client.client_address,
            clientPartnerId: data.stt_client.client_partner_id,
            clientIsCod: data.stt_client.client_is_cod,
            clientIsDo: data.stt_client.client_is_do,
            clientIsMixpack: data.stt_client.client_is_mixpack,
            clientIsBanned: data.stt_client.client_is_banned,
            clientCodConfigAmount: data.stt_client.client_cod_config_amount
          })
        : new SttClient(),
      sttPiecePerPack: data.stt_piece_per_pack,
      sttNextCommodity: data.stt_next_commodity,
      sttCodAmount: formatInputMoney(data.stt_cod_amount),
      sttCodAmountForDetail: data.stt_cod_amount,
      sttCodFee: isPromo
        ? data.list_discount[lengthOfDiscount]?.tariff.cod_fee
        : data.stt_cod_fee,
      sttPadFee: data.stt_pad_fee,
      sttBookedById: data.stt_booked_by_id,
      sttBookedByType: data.stt_booked_by_type,
      sttBookedByCode: data.stt_booked_by_code,
      sttBookedForId: data.stt_booked_for_id,
      sttBookedForType: data.stt_booked_for_type,
      sttBookedForCode: data.stt_booked_for_code,
      sttReasonMapping: new SttReasonMapping({
        reasonCode: data.stt_reason_mapping?.reason_code,
        reasonCreatedAt: data.stt_reason_mapping?.reason_created_at,
        reasonDescription:
          AccountController.accountData.account_type_detail.countryCode.toLowerCase() ===
            "my"
            ? data.stt_reason_mapping?.reason_description_en
            : data.stt_reason_mapping?.reason_description,
        reasonExternalCode: data.stt_reason_mapping?.reason_external_code,
        reasonId: data.stt_reason_mapping?.reason_id,
        reasonTitle: data.stt_reason_mapping?.reason_title,
        reasonUpdatedAt: data.stt_reason_mapping?.reason_updated_at
      }),
      checkoutPaymentMethod: data.checkout_payment_method,
      postalCodeDestination: data.postal_code_destination,
      lastStatusSttReturn: data.last_status_stt_return,
      sttIsPromo: data.stt_is_promo,
      sttTotalDiscount: data.stt_total_discount,
      sttTotalTariffAfterDiscount: data.stt_total_tariff_after_discount,
      sttReturnFee: data.stt_return_fee,
      isReverseJourney: data.detail_stt_reverse_journey,
      sttReverseJourneySttNo:
        data.detail_stt_reverse_journey?.root_reverse_stt_no ?? "",
      sttReverseJourneyShipmentId:
        data.detail_stt_reverse_journey?.root_reverse_stt_shipment_id ?? "",
      sttReverseJourneyCodHandling: !!data.detail_stt_reverse_journey
        ?.cod_handling,
      sttAttachFilesInitial: data.stt_attach_files,
      sttCommodityDetailInitial: data.stt_commodity_detail,
      sttWeightAttachFiles: data.stt_weight_attach_files || [],
      sttAttachFiles: data.stt_attach_files,
      sttCommodityDetail: data.stt_commodity_detail,
      sttPiecesGrossWeight: data.stt_piece.map(
        (item: any) => item.stt_piece_gross_weight
      ),
      sttPiecesVolumeWeight: data.stt_piece.map(
        (item: any) => item.stt_piece_volume_weight
      ),
      sttIsDfod: data.stt_is_dfod,
      discountFavoritePercentage: data.stt_discount_favorite_percentage,
      listDiscount: data.list_discount?.length
        ? data.list_discount
            .map((el: any) => {
              return new DetailDiscount({
                pdcName: el.config.pdc_name,
                pdcPromoDiscount: el.config.pdc_promo_discount,
                pdcCategory: el.config.pdc_category,
                pdcEndDate: el.config.pdc_end_date,
                pdcDiscountAmount: el.tariff.discount_amount
              });
            })
            .filter(
              (item: DetailDiscount) =>
                item.pdcCategory === "tactical" ||
                item.pdcCategory === "campaign"
            )
        : [],
      defaultDimensions: data.stt_piece.map(
        (item: any) => new DefaultDimensions({
          lengthOfDimension: item.stt_piece_length,
          widthOfDimension: item.stt_piece_width,
          heightOfDimension: item.stt_piece_height
        })
      ),
      sttBookedByCountry: data.stt_booked_by_country,
      refundRemarks: data.refund_remarks || "",
      sttRecipientEmail : data.stt_recipient_email || "",
      sttKtpImage : data.stt_ktp_image,
      sttTaxImage : data.stt_tax_image,
      goodsNames: data.goods_names
    });
  }

  public convertDetailShipmentBookingFromApiElexys(
    result: AxiosResponse
  ): ShipmentBookingDetail {
    const {
      data: { data }
    } = result;
    const sender = codePhoneCountry(data.stt_sender_phone);
    const recipient = codePhoneCountry(data.stt_recipient_phone);

    const isCod = { is: data.stt_cod === "Yes", label: "COD" };
    const isPad = { is: data.stt_pad === "Yes", label: "PAD" };
    const sttCodType: string[] = [];
    [isCod, isPad].forEach((e: any) => {
      if (e.is) {
        sttCodType.push(e.label);
      }
    });
    const isPromo = data.stt_is_promo && data.list_discount?.length > 0;

    let lengthOfDiscount = 0;
    let shipmentChargeDiscount = 0;
    let cityRateDiscount = 0;
    let forwardRateDiscount = 0;
    if (data.list_discount) {
      lengthOfDiscount = data.list_discount?.length - 1;
      shipmentChargeDiscount =
        data.list_discount[lengthOfDiscount]?.tariff.publish_rate +
        data.list_discount[lengthOfDiscount]?.tariff.shipping_surcharge_rate +
        data.list_discount[lengthOfDiscount]?.tariff.origin_district_rate +
        data.list_discount[lengthOfDiscount]?.tariff.destination_district_rate;

      cityRateDiscount =
        data.list_discount[lengthOfDiscount]?.tariff.publish_rate +
        data.list_discount[lengthOfDiscount]?.tariff.shipping_surcharge_rate;

      forwardRateDiscount =
        data.list_discount[lengthOfDiscount]?.tariff.origin_district_rate +
        data.list_discount[lengthOfDiscount]?.tariff.destination_district_rate;
    }

    return new ShipmentBookingDetail({
      sttId: data.stt_id,
      sttNo: data.stt_no,
      sttShipmentId: data.stt_shipment_id,
      sttOriginAddress: data.stt_origin_address,
      sttDestinationAddress: data.stt_destination_address,
      sttDestinationCityName: data.stt_destination_city_name,
      sttNoRefExternal: data.stt_no_ref_external,
      sttSenderName: data.stt_sender_name,
      sttSenderPhone: sender.number,
      sttSenderPhoneCode: sender.code,
      sttSenderPhoneFlag: sender.flag,
      sttSenderAddress: removeNewLineWhitespace(data.stt_sender_address, " "),
      sttRecipientName: data.stt_recipient_name,
      sttRecipientPhone: recipient.number,
      sttRecipientPhoneCode: recipient.code,
      sttRecipientPhoneFlag: recipient.flag,
      sttRecipientAddress: removeNewLineWhitespace(
        data.stt_recipient_address,
        " "
      ),
      sttRecipientAddressType:
        !data.stt_recipient_address_type &&
        (data.last_status_stt_return === "RTS" ||
          data.last_status_stt_return === "RSTHQ" ||
          data.last_status_stt_return === "REROUTE")
          ? "home"
          : data.stt_recipient_address_type,
      sttCommodityName: data.stt_commodity_name
        ? data.stt_commodity_name
        : FlagsMedusa.config_commodity_general_others_name.getValue(),
      sttCommodityId: data.stt_commodity_id
        ? data.stt_commodity_id
        : FlagsMedusa.config_commodity_general_others_id.getValue(),
      sttCommodityCode: data.stt_commodity_code
        ? data.stt_commodity_code
        : FlagsMedusa.config_commodity_general_others_code.getValue(),
      sttProductTypeName: data.stt_product_type_name,
      sttTotalPiece: data.stt_total_piece,
      sttTotalChargeAbleWeight: data.stt_total_chargeable_weight,
      sttGoodsEstimatePrice: formatInputMoney(
        data.stt_goods_estimate_price ? data.stt_goods_estimate_price : 0
      ),
      sttGoodsEstimatePriceForDetail: data.stt_goods_estimate_price ? data.stt_goods_estimate_price : 0,
      sttTaxNumber: data.stt_tax_number,
      sttInsuranceName: data.stt_insurance_name,
      sttInsuranceNameDefault: data.stt_insurance_name,
      sttBookedBy: data.stt_booked_by,
      sttBilledTo: data.stt_billed_to,
      sttSource: data.stt_source,
      sttCod: data.stt_cod,
      codHandling: data.cod_handling,
      sttReturnCod: data.stt_return_do,
      sttShipmentPrice: isPromo
        ? shipmentChargeDiscount
        : data.stt_shipment_price,
      sttShippingSurchargeRate: data.stt_shipping_surcharge_rate,
      sttDocumentSurchargeRate: isPromo
        ? data.list_discount[lengthOfDiscount]?.tariff.document_surcharge
        : data.stt_document_surcharge_rate,
      sttCommoditySurchargeRate: isPromo
        ? data.list_discount[lengthOfDiscount]?.tariff.commodity_surcharge
        : data.stt_commodity_surcharge_rate,
      sttHeavyWeightSurchargeRate: isPromo
        ? data.list_discount[lengthOfDiscount]?.tariff.heavy_weight_surcharge
        : data.stt_heavyweight_surcharge_rate,
      sttInsuranceRate: isPromo
        ? data.list_discount[lengthOfDiscount]?.tariff.insurance_rates
        : data.stt_insurance_rate,
      sttWoodPackingRate: data.stt_woodpacking_rate,
      sttTaxRate: data.stt_tax_rate,
      sttCityRate: isPromo ? cityRateDiscount : data.stt_city_rate,
      sttForwardRate: isPromo ? forwardRateDiscount : data.stt_forward_rate,
      sttTotalTariff: data.stt_total_tariff,
      sttLastStatus: data.stt_last_status,
      sttLastStatusCounter: data.stt_last_status_counter,
      sttLastStatusDescription: data.stt_last_status_description.replace(
        /\n/g,
        "<br />"
      ),
      sttWarningStatus: data.stt_warning_status,
      sttWarningStatusDescription: data.stt_warning_status_description,
      sttCreatedAt: data.stt_created_at,
      sttCreatedBy: data.stt_created_by,
      sttUpdatedAt: data.stt_updated_at,
      sttUpdatedBy: data.stt_updated_by,
      SttPieces:  data.stt_piece.map(
        (item: any) =>
          new SttPieces(
            item.stt_piece_id,
            item.stt_piece_gross_weight,
            {
              length: item.stt_piece_length,
              width: item.stt_piece_width,
              height: item.stt_piece_height
            },
            item.stt_piece_volume_weight,
            "1",
            data.stt_shipment_id &&
            !(
              (AccountController.accountData.isInternalAccount ||
                prefixDisableDimensionC1(
                  String(AccountController.accountData.account_id)
                )) &&
              new RegExp(
                FlagsFeatureShipmentC1.feature_shipment_c1.getValue(),
                "i"
              ).test(data.stt_shipment_id)
            )
              ? item.stt_piece_gross_weight < MAX_WEIGHT_SHIPMENT
              : false,
              false,
          )
      ),
      SttPiecesInitial:  data.stt_piece.map(
        (item: any) =>
          new SttPieces(
            item.stt_piece_id,
            item.stt_piece_gross_weight,
            {
              length: item.stt_piece_length,
              width: item.stt_piece_width,
              height: item.stt_piece_height
            },
            item.stt_piece_volume_weight,
            "1",
            data.stt_shipment_id &&
            !(
              (AccountController.accountData.isInternalAccount ||
                prefixDisableDimensionC1(
                  String(AccountController.accountData.account_id)
                )) &&
              new RegExp(
                FlagsFeatureShipmentC1.feature_shipment_c1.getValue(),
                "i"
              ).test(data.stt_shipment_id)
            )
              ? item.stt_piece_gross_weight < MAX_WEIGHT_SHIPMENT
              : false,
              false
          )
      ), 
      sttSenderStatus: data.stt_sender_status
        ? data.stt_sender_status
        : "ecommerce",
      sttWoodpackingRate: data.stt_woodpacking_rate,
      sttColumnDisabled: data.stt_source === "customer-apps",
      sttIsWoodPacking: data.stt_is_woodpacking,
      sttIsWoodPackingKeeped: data.stt_is_woodpacking,
      sttIsCod: isCod.is,
      sttIsPad: isPad.is,
      sttCodType,
      sttOriginCityId: data.stt_origin_city_id,
      sttOriginDistrictId: data.stt_origin_district_id,
      sttDestinationCityId: data.stt_destination_city_id,
      sttDestinationDistrictId: data.stt_destination_district_id,
      sttDestinationDistrictName: data.stt_destination_district_name,
      sttIsAllowEdit: data.stt_is_allow_edit,
      sttIsAllowCancel: data.stt_is_allow_cancel,
      sttClient: data.stt_client
        ? new SttClient({
            clientId: data.stt_client.client_id,
            clientCode: data.stt_client.client_code,
            clientName: data.stt_client.client_name,
            clientPhone: data.stt_client.client_phone,
            clientEmail: data.stt_client.client_email,
            clientTaxNumber: data.stt_client.client_tax_number,
            clientStatus: data.stt_client.client_status,
            clientAddress: data.stt_client.client_address,
            clientPartnerId: data.stt_client.client_partner_id,
            clientIsCod: data.stt_client.client_is_cod,
            clientIsDo: data.stt_client.client_is_do,
            clientIsMixpack: data.stt_client.client_is_mixpack,
            clientIsBanned: data.stt_client.client_is_banned,
            clientCodConfigAmount: data.stt_client.client_cod_config_amount
          })
        : new SttClient(),
      sttPiecePerPack: data.stt_piece_per_pack,
      sttNextCommodity: data.stt_next_commodity,
      sttCodAmount: formatInputMoney(data.stt_cod_amount),
      sttCodAmountForDetail: data.stt_cod_amount,
      sttCodFee: isPromo
        ? data.list_discount[lengthOfDiscount]?.tariff.cod_fee
        : data.stt_cod_fee,
      sttPadFee: data.stt_pad_fee,
      sttBookedById: data.stt_booked_by_id,
      sttBookedByType: data.stt_booked_by_type,
      sttBookedByCode: data.stt_booked_by_code,
      sttBookedForId: data.stt_booked_for_id,
      sttBookedForType: data.stt_booked_for_type,
      sttBookedForCode: data.stt_booked_for_code,
      sttReasonMapping: new SttReasonMapping({
        reasonCode: data.stt_reason_mapping?.reason_code,
        reasonCreatedAt: data.stt_reason_mapping?.reason_created_at,
        reasonDescription:
          AccountController.accountData.account_type_detail.countryCode.toLowerCase() ===
            "my"
            ? data.stt_reason_mapping?.reason_description_en
            : data.stt_reason_mapping?.reason_description,
        reasonExternalCode: data.stt_reason_mapping?.reason_external_code,
        reasonId: data.stt_reason_mapping?.reason_id,
        reasonTitle: data.stt_reason_mapping?.reason_title,
        reasonUpdatedAt: data.stt_reason_mapping?.reason_updated_at
      }),
      checkoutPaymentMethod: data.checkout_payment_method,
      postalCodeDestination: data.postal_code_destination,
      lastStatusSttReturn: data.last_status_stt_return,
      sttGrossWeight: data.stt_total_gross,
      sttVolumeWeight: data.stt_total_volume,
      sttIsPromo: data.stt_is_promo,
      sttTotalDiscount: data.stt_total_discount,
      sttTotalTariffAfterDiscount: data.stt_total_tariff_after_discount,
      sttReturnFee: data.stt_return_fee,
      isReverseJourney: data.detail_stt_reverse_journey,
      sttReverseJourneySttNo:
        data.detail_stt_reverse_journey?.root_reverse_stt_no ?? "",
      sttReverseJourneyShipmentId:
        data.detail_stt_reverse_journey?.root_reverse_stt_shipment_id ?? "",
      sttReverseJourneyCodHandling: !!data.detail_stt_reverse_journey
        ?.cod_handling,
      sttAttachFilesInitial: data.stt_attach_files,
      sttCommodityDetailInitial: data.stt_commodity_detail,
      sttWeightAttachFiles: data.stt_weight_attach_files || [],
      sttAttachFiles: data.stt_attach_files,
      sttCommodityDetail: data.stt_commodity_detail,
      sttPiecesGrossWeight: data.stt_piece.map(
        (item: any) => item.stt_piece_gross_weight
      ),
      sttPiecesVolumeWeight: data.stt_piece.map(
        (item: any) => item.stt_piece_volume_weight
      ),
      sttIsDfod: data.stt_is_dfod,
      discountFavoritePercentage: data.stt_discount_favorite_percentage,
      listDiscount: data.list_discount?.length
        ? data.list_discount
            .map((el: any) => {
              return new DetailDiscount({
                pdcName: el.config.pdc_name,
                pdcPromoDiscount: el.config.pdc_promo_discount,
                pdcCategory: el.config.pdc_category,
                pdcEndDate: el.config.pdc_end_date,
                pdcDiscountAmount: el.tariff.discount_amount
              });
            })
            ?.filter(
              (item: DetailDiscount) =>
                item.pdcCategory === "tactical" ||
                item.pdcCategory === "campaign"
            )
        : [],
      defaultDimensions: data.stt_piece.map(
        (item: any) => new DefaultDimensions({
          lengthOfDimension: item.stt_piece_length,
          widthOfDimension: item.stt_piece_width,
          heightOfDimension: item.stt_piece_height
        })
      ),
      sttBookedByCountry: data.stt_booked_by_country,
      refundRemarks: data.refund_remarks || "",
      sttRecipientEmail : data.stt_recipient_email || "",
      sttKtpImage : data.stt_ktp_image,
      sttTaxImage : data.stt_tax_image,
      goodsNames: data.goods_names
    });
  }

  public convertPayloadData(res: AxiosResponse<any>): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      sttNumber: data.data.stt[0].stt_no,
      isDiscount: data.data.stt[0].is_discount,
      totalDiscount: data.data.stt[0].total_discount,
      totalAfterDiscount: data.data.stt[0].total_after_discount,
      totalBeforeDiscount: data.data.stt[0].total_before_discount,
      sttId: data.data.stt[0].stt_id,
      chargeableWeight: data.data.stt[0].chargeable_weight
    });
  }

  public convertDataSttManualValidationFromApi(
    result: AxiosResponse
  ): SttManualValidation {
    const { data } = result;
    return new SttManualValidation(
      data.data.is_allow_booking,
      data.data.error_message
    );
  }

  public convertDataShipmentPrefixFromApi(
    result: AxiosResponse
  ): ShipmentPrefix[] {
    const { data } = result;
    return data.data.map((item: any) => {
      return new ShipmentPrefix({
        prefix: item.prefix,
        name: `${item.name} (${item.prefix})`
      });
    });
  }

  public convertDataCBPManifest(result: AxiosResponse): CBPPickupManifest {
    const { data } = result;
    return new CBPPickupManifest({
      posName: data.summary.pos_name,
      posOrigin: data.summary.pos_origin,
      pickupDate: data.summary.pickup_date,
      clientName: `${data.summary.client_code} - ${data.summary.client_name}`,
      clientOrigin: data.summary.client_origin,
      totalSTT: data.summary.total_stt,
      totalPieces: data.summary.total_pieces,
      totalGrossWeight: data.summary.total_gross_weight,
      data: data.data?.length ?
        data.data.map(
          (item: any) =>
            new CBPPickup({
              sttId: item.stt_id,
              sttNo: item.stt_no,
              sttBilledTo: item.stt_billed_to,
              sttShipmentId: item.stt_shipment_id,
              sttRecipientAddress: item.stt_recipient_address,
              sttOriginCity: item.stt_origin_city,
              sttDestinationCity: item.stt_destination_city,
              sttLastStatusId: item.stt_last_status_id,
              sttCreatedName: item.stt_created_name,
              sttCreatedAt: item.stt_created_at,
              sttUpdatedAt: item.stt_updated_at,
              sttClientId: item.stt_client_id,
              sttChargeAbleWeight: item.stt_chargeable_weight,
              sttProductType: item.stt_product_type,
              sttCommodityCode: item.stt_commodity_code,
              sttCommodityName: item.stt_commodity_name,
              sttTotalPiece: item.stt_total_piece,
              sttPosId: item.stt_pos_id,
              sttIsCod: item.stt_is_cod,
              sttIsDfod: item.stt_is_dfod,
              sttIsPad: item.stt_is_pad,
              sttCounter: item.stt_counter,
              sttBookedName: item.stt_booked_name,
              sttGrossWeight: item.stt_gross_weight
            })
        ) : []
    })
  }
}
