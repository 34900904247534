import { ClientApiRepository } from "@/app/infrastructures/repositories/api/ClientApiRepository";
import {
  Actor,
  ClientDetail,
  ClientEntities,
  ClientMappingList,
  ClientParentData,
  ClientPartnerData,
  RequestApiClientMappingList
} from "@/domain/entities/Client";
import { injectable } from "tsyringe";
import { EditClientManagementRequestInterface } from "@/data/payload/contracts/ClientManagementRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

@injectable()
export class ClientPresenter {
  private repository: ClientApiRepository;

  constructor(repository: ClientApiRepository) {
    this.repository = repository;
  }

  public getListClient(
    page: number,
    limit: number,
    search: string,
    status: string,
    clientParentId?: number
  ): Promise<ClientEntities> {
    return this.repository.getList(page, limit, search, status, clientParentId);
  }

  public getListClientPartner(
    page: number,
    limit: number,
    search: string,
    partnerType: string,
    type?: string,
    isBranch?: boolean,
    isParent?: boolean,
    orderBy?: string,
    sortBy?: string
  ): Promise<ClientPartnerData[]> {
    return this.repository.getListClientPartner(
      page,
      limit,
      search,
      partnerType,
      type,
      isBranch,
      isParent,
      orderBy,
      sortBy
    );
  }

  getClientMappingList(
    params: RequestApiClientMappingList
  ): Promise<ClientMappingList[]> {
    return this.repository.getClientMappingList(params);
  }

  getClientParentList(): Promise<ClientParentData[]> {
    return this.repository.getClientParentList();
  }

  public getListOfActor(search: string): Promise<Actor[]> {
    return this.repository.getListOfActor(search);
  }

  public retryUploadClientRate(
    payload: EditClientManagementRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.retryUploadClientRate(payload);
  }

  getClientDetail(clientId: number): Promise<ClientDetail> {
    return this.repository.getClientDetail(clientId);
  }

  getRTCListClientParent(
    hubId: number,
    transportType: string
  ): Promise<ClientParentData[]> {
    return this.repository.getRTCListClientParent(hubId, transportType);
  }
}
