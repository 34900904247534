/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionCargoTracking = {
    permission_cargo_tracking_enable: new Rox.Flag(false)
};

export function initFlagsPermissionCargoTracking() {
  // register flags with namespaces for better grouping
  Rox.register(
    "featureCargoTracking",
    FlagsPermissionCargoTracking
  );
}