import {
  GetScheduleListApiRequest,
  TrackingCargoByBookingRequestIdNgenApiRequest,
  TrackingCargoHistoryNgenApiRequest
} from "@/data/payload/api/NGenApiRequest";
import {
  GetScheduleListRequestInterface,
  PrintAWBRequestInterface,
  TrackingByBookingRequestIdInterface,
  TrackingRequestInterface
} from "@/data/payload/contracts/NGenRequest";
import { NGenMapper } from "@/data/persistences/mappers/NGenMapper";
import { NGenRepositoryInterface } from "@/data/persistences/repositories/contracts/NGenRepositoryInterface";
import { FlightSchedule, AwbDetailData, TrackingBookingRequestIdData } from "@/domain/entities/NGen";
import { Endpoints } from "../../misc/Endpoints";
import ApiServiceNGen from "../../services/ApiServiceNGen";

export class NGenApiRepository implements NGenRepositoryInterface {
  private service: ApiServiceNGen;
  private mapper: NGenMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiServiceNGen,
    mapper: NGenMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getFlightSchedule(
    payload: GetScheduleListRequestInterface
  ): Promise<FlightSchedule> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.getScheduleListNGen(),
      {},
      payload as GetScheduleListApiRequest
    );
    return this.mapper.convertFlightScheduleDataFromApi(resp);
  }

  async getTrackingCargoHistoryNgen(
    payload: TrackingRequestInterface
  ): Promise<AwbDetailData> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.getTrackingCargoHistoryNgen(),
      {},
      payload as TrackingCargoHistoryNgenApiRequest
    );

    return this.mapper.convertCargoHistoryFromNgen(resp);
  }

  async getTrackingByBookingRequestIdNgen(
    payload: TrackingByBookingRequestIdInterface
  ): Promise<TrackingBookingRequestIdData[]> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.getTrackingByBookingRequestIdNgen(),
      {},
      payload as TrackingCargoByBookingRequestIdNgenApiRequest
    );

    return this.mapper.convertCargoHistoryByBookingRequestIdFromNgen(resp);
  }

  async printAWBLionCargo(payload: PrintAWBRequestInterface) {
    const resp = await this.service.invokeWithoutHeader(
      "post",
      this.endpoints.printAWBLionCargo(),
      {},
      payload as PrintAWBRequestInterface
    );

    return this.mapper.convertPrintAWBLionCargoData(resp);
  }
}
