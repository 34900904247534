import { VuexModule, Module, Action, getModule } from "vuex-module-decorators";
import { FirebaseComponent } from "@/app/infrastructures/dependencies/modules/FirebaseComponent";
import store from "@/store";
import { FirebasePresenter } from "@/app/ui/presenters/FirebasePresenter";
import { container } from "tsyringe";
FirebaseComponent.init();

@Module({ namespaced: true, dynamic: true, store, name: "firebase" })
class FirebaseStore extends VuexModule {
  public isLoading = false;

  @Action
  public async getTokenFirebase(): Promise<string> {
    const presenter = container.resolve(FirebasePresenter);
    return await presenter.getTokenFirebase();
  }

  @Action
  public async deleteTokenFirebase(): Promise<string> {
    const presenter = container.resolve(FirebasePresenter);
    return await presenter.deleteTokenFirebase();
  }

  @Action
  public onMessageFirebase(callback: Function) {
    const presenter = container.resolve(FirebasePresenter);
    presenter.onMessageFirebase(callback);
  }
}

export const FirebaseController = getModule(FirebaseStore);
