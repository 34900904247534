import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { CodReconcileRepositoryInterface } from "@/data/persistences/repositories/contracts/CodReconcileRepositoryInterface";
import { CodReconcileMapper } from "@/data/persistences/mappers/CodReconcileMapper";
import {
  CodDashboardDetailCourier,
  CourierDetail,
  CourierDetailApiRequest,
  CourierDetailList,
  CourierDetailPendingReconcile,
  CourierSummary,
  DashboardDeliveryCourierDetail,
} from "@/domain/entities/CodReconcile";

export class CodReconcileApiRepository
  implements CodReconcileRepositoryInterface {
  private service: ApiService;
  private mapper: CodReconcileMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: CodReconcileMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async getCourierDetailSummary(
    params: CourierDetailApiRequest
  ): Promise<CodDashboardDetailCourier> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getDetailCourierSummary(params)
    );

    return this.mapper.convertDetailCourierPartnerCodDashboardFromApi(resp);
  }

  async getCourierSummaryDetailV2(
    params: CourierDetailApiRequest
  ): Promise<CourierSummary> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getCourierSummaryDetailV2(params)
    );

    return this.mapper.convertCourierSummaryDetailFromApiV2(resp);
  }

  async getCourierDetail(
    params: CourierDetailApiRequest
  ): Promise<CourierDetailList> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getDetailCourierCodV2(params)
    );

    return this.mapper.convertDetailCourierCodFromApi(resp);
  }

  async getCourierDetailV2(
    params: CourierDetailApiRequest
  ): Promise<CourierDetail[]> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getDetailCourierCodV2(params)
    );

    return this.mapper.convertDetailCourierCodFromApiV2(resp);
  }

  async getDetailCourierPendingReconcile(
    params: CourierDetailApiRequest
  ): Promise<CourierDetailPendingReconcile[]> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getDetailCourierPendingReconcile(params)
    );

    return this.mapper.convertDetailCourierPendingReconcileFromApi(resp);
  }

  async getDashboardDeliveryCourierDetail(
    phone: string
  ): Promise<DashboardDeliveryCourierDetail> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getDetailCourierByPhone(phone)
    );

    return this.mapper.convertDashboardDeliveryCourierDetailFromApi(resp);
  }
}
