import { AxiosResponse } from "axios";
import {
  ProgressiveCommissionList,
  ProgressiveCommissionListData,
  ProgressiveCommissionListTier,
  ProgressiveCommissionDetail,
  ProgressiveCommissionMilestone
} from "@/domain/entities/ProgressiveCommissionConfiguration";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";

export class ProgressiveCommissionConfigurationMapper {
  public convertProgressiveCommissionListFromApi(
    res: AxiosResponse<any>
  ): ProgressiveCommissionList {
    const { data } = res;

    return new ProgressiveCommissionList(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      data.data
        ? data.data.map((data: any) => {
            return new ProgressiveCommissionListData({
              cpcId: data.cpc_id,
              cpcName: data.cpc_name,
              cpcStartDate: data.cpc_start_date,
              cpcEndDate: data.cpc_end_date,
              cpcAppliedTo: data.cpc_applied_to,
              cpcTermCondition: data.cpc_term_condition,
              cpcBookingSource: data.cpc_booking_source,
              cpcShipmentPrefix: data.cpc_shipment_prefix,
              cpcReference: data.cpc_reference,
              cpcCreatedAt: data.cpc_created_at,
              cpcCreatedBy: data.cpc_created_by,
              cpcUpdatedAt: data.cpc_updated_at,
              cpcUpdatedBy: data.cpc_updated_by,
              cpcDetail: data.cpc_detail
                ? data.cpc_detail.map((item: any) => {
                    return new ProgressiveCommissionListTier({
                      cpcdId: item.cpcd_id,
                      cpcdMilestoneId: item.cpcd_milestone_id,
                      cpcdMilestoneName: item.cpcd_milestone_name,
                      cpcdMilestoneLimit: item.cpcd_milestone_limit,
                      cpcdCommissionAdditional: item.cpcd_commission_additional,
                      cpcdCpcId: item.cpcd_cpc_id
                    });
                  })
                : [],
              cpcCreatedName: data.cpc_created_name,
              cpcStatus: data.cpc_status
            });
          })
        : []
    );
  }

  public convertProgressiveCommissionDetailFromApi(
    res: AxiosResponse<any>
  ): ProgressiveCommissionDetail {
    const {
      data: { data }
    } = res;

    return new ProgressiveCommissionDetail({
      programId: data.cpc_id,
      startDate: data.cpc_start_date,
      endDate: data.cpc_end_date,
      programApplied:
        data.cpc_applied_to === "pos"
          ? "ALL POS"
          : data.cpc_applied_to.toUpperCase(),
      programUsedFor: data.cpc_booking_source.split(","),
      programPrefix: data.cpc_shipment_prefix.split(","),
      parameterCalculation: data.cpc_reference.split(","),
      mileStones: data.cpc_detail.map((item: any) => {
        return new ProgressiveCommissionMilestone({
          id: item.cpcd_milestone_id,
          limit: item.cpcd_milestone_limit,
          commission: item.cpcd_commission_additional
        });
      }),
      termCondition: data.cpc_term_condition,
      createdAt: data.cpc_created_at,
      createdBy: data.cpc_created_name,
      updatedAt: data.cpc_updated_at,
      updatedBy: data.cpc_updated_name,
      status: data.cpc_status.toLowerCase(),
      termAndCondition: data.cpc_term_condition
    });
  }

  public convertSubmitDataFromApi(result: AxiosResponse): ResponsePayloadV2 {
    const { data } = result;
    return new ResponsePayloadV2({
      success: data.success,
      message: data.message
    });
  }
}
