import { FlagsPermissionMiddleware } from "@/feature-flags/flags-middleware";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const MiddlewareFormMappingCode = () =>
  import(
    /* webpackChunkName: "Middleware MiddlewareFormMappingCode" */ "@/app/ui/views/middleware/mapping-code/form.vue"
  );

  const MiddlewareFormMappingToken = () =>
  import(
    /* webpackChunkName: "Middleware MiddlewareFormMappingToken" */ "@/app/ui/views/middleware/mapping-token/form.vue"
  );

const MiddlewareDetailMappingCode = () =>
  import(
    /* webpackChunkName: "Middleware MiddlewareDetailMappingCode" */ "@/app/ui/views/middleware/mapping-code/detail.vue"
  );

const MiddlewareDetailMappingToken = () =>
  import(
    /* webpackChunkName: "Middleware MiddlewareDetailMappingToken" */ "@/app/ui/views/middleware/mapping-token/detail.vue"
  );

export default [
  {
    path: "/admin/middleware/mapping-code",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Middleware",
      permission: FlagsPermissionMiddleware.permission_middleware
    },
    children: [
      {
        path: "add",
        name: "add-middleware-mapping-code",
        component: MiddlewareFormMappingCode,
        meta: {
          header: true,
          title: "Buat Mapping Code",
          name: "Buat Mapping Code",
          formType: "add",
          before: "/admin/middleware/mapping-code"
        }
      }
    ]
  },
  {
    path: "/admin/middleware/mapping-token",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Middleware",
      permission: FlagsPermissionMiddleware.permission_middleware
    },
    children: [
      {
        path: "add",
        name: "add-middleware-mapping-token",
        component: MiddlewareFormMappingToken,
        meta: {
          header: true,
          title: "Buat Mapping Token",
          name: "Buat Mapping Token",
          formType: "add",
          before: "/admin/middleware/mapping-token"
        }
      }
    ]
  },
  {
    path: "/admin/middleware/mapping-code",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Middleware",
      permission: FlagsPermissionMiddleware.permission_middleware
    },
    children: [
      {
        path: ":id",
        name: "detail-middleware-mapping-code",
        component: MiddlewareDetailMappingCode,
        meta: {
          header: true,
          before: "/admin/middleware/mapping-code",
        }
      }
    ]
  },
  {
    path: "/admin/middleware/mapping-code",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Middleware",
      permission: FlagsPermissionMiddleware.permission_middleware
    },
    children: [
      {
        path: "edit/:id",
        name: "edit-middleware-mapping-code",
        component: MiddlewareFormMappingCode,
        meta: {
          header: true,
          title: "Ubah Mapping Code",
          name: "Ubah Mapping Code",
          formType: "edit",
          before: "/admin/middleware/mapping-code/:id",
        }
      }
    ]
  },
  {
    path: "/admin/middleware/mapping-token",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Middleware",
      permission: FlagsPermissionMiddleware.permission_middleware
    },
    children: [
      {
        path: "edit/:id",
        name: "edit-middleware-mapping-token",
        component: MiddlewareFormMappingToken,
        meta: {
          header: true,
          title: "Ubah Mapping Token",
          name: "Ubah Mapping Token",
          formType: "edit",
          before: "/admin/middleware/mapping-token/:id",
        }
      }
    ]
  },
  {
    path: "/admin/middleware/mapping-token",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Middleware",
      permission: FlagsPermissionMiddleware.permission_middleware
    },
    children: [
      {
        path: ":id",
        name: "detail-middleware-mapping-token",
        component: MiddlewareDetailMappingToken,
        meta: {
          header: true,
          before: "/admin/middleware/mapping-token",
        }
      }
    ]
  },
];
