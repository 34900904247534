import { QueryParamsEntities } from "./MainApp";

export class DepositAmount {
  status = "";
  collectedPieces = 0;
  collected = 0;
  pending = 0;
  pendingPieces = 0;

  constructor(fields?: Partial<DepositAmount>) {
    Object.assign(this, fields);
  }
}

export class SttInDelivery {
  status = "";
  count = 0;
  pieces = 0;

  constructor(fields?: Partial<SttInDelivery>) {
    Object.assign(this, fields);
  }
}

export class CourierSummary {
  codAmountDeposit = new DepositAmount();
  sttDeposit: DepositAmount[] = [];
  sttInDelivery: SttInDelivery[] = [];

  constructor(fields?: Partial<CourierSummary>) {
    Object.assign(this, fields);
  }
}

export class CourierDetail {
  sttCodAmount = 0;
  sttCollectionStatus = "";
  sttRecipientName = "";
  sttRecipientPhone = "";
  sttNo = "";
  sttStatus = "";
  sttReason = "";
  sttPaymentMethod = "";
  sttDeliveryDate = "";

  constructor(fields?: Partial<CourierDetail>) {
    Object.assign(this, fields);
  }
}

export class CourierDetailList {
  data: CourierDetail[] = [];

  constructor(fields?: Partial<CourierDetailList>) {
    Object.assign(this, fields);
  }
}

export class CodDashboardDetailCourier {
  courierPhone = "";
  courierName = "";
  courierId = 0;
  courierPartnerName = "";
  courierPartnerPhone = "";
  courierPartnerContactPhone = "";
  totalStt = 0;
  totalSttPod = 0;
  totalCollected = 0;
  totalQrisCollected = 0;
  totalOutstanding = 0;
  totalSttDexCollected = 0;
  totalSttDexOutstanding = 0;
  totalSttCodrejCollected = 0;
  totalSttCodrejOutstanding = 0;
  totalSttDel = 0;
  performanceSameDay = "";
  percentageSameDay = "";
  performanceDifferentDay = "";
  percentageDifferentDay = "";
  constructor(fields?: Partial<CodDashboardDetailCourier>) {
    Object.assign(this, fields);
  }
}

export class BankDetail {
  bankId = "";
  bankName = "";
  bankAccount = "";
  bankAccountName = "";
  constructor(fields?: Partial<BankDetail>) {
    Object.assign(this, fields);
  }
}

export class DashboardDeliveryCourierDetail {
  id = 0;
  courierId = 0;
  fullname = "";
  phoneNumber = "";
  partnerType = "";
  partnerName = "";
  announcementCourierType = "";
  courierType = "";
  vehicleType = "";
  vehiclePlate = "";
  courierStatus = "";
  courierIsCod = false;
  bank = new BankDetail();
  constructor(fields?: Partial<DashboardDeliveryCourierDetail>) {
    Object.assign(this, fields);
  }
}

export class CourierDetailApiRequest {
  type = "";
  driverPhone = "";
  filterDate = "";
  status = "";
  picPhone = "";
  isAllType = true;

  constructor(fields?: Partial<CourierDetailApiRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class CourierDetailPendingReconcile {
  dateGroup = "";
  codAmount = 0;
  sttAmount = 0;
  sttPieces = 0;

  constructor(fields?: Partial<CourierDetailPendingReconcile>) {
    Object.assign(this, fields);
  }
}
