import { BulkBookingCommissionApiRepository } from "@/app/infrastructures/repositories/api/BulkBookingCommissionApiRepository";
import { BulkBookingCommissionRequestInterface } from "@/data/payload/contracts/BulkBookingCommissionRequest";
import {
  BulkBookingCommissionDetail,
  BulkBookingCommissionEntities
} from "@/domain/entities/BulkBookingCommission";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { injectable } from "tsyringe";

@injectable()
export class BulkBookingCommissionPresenter {
  private repository: BulkBookingCommissionApiRepository;

  constructor(repo: BulkBookingCommissionApiRepository) {
    this.repository = repo;
  }

  createBulkBookingCommission(
    payload: BulkBookingCommissionRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.createBulkBookingCommission(payload);
  }

  public getBulkBookingCommissionList(
    page: number,
    limit: number,
    search: string,
    status: string
  ): Promise<BulkBookingCommissionEntities> {
    return this.repository.getBulkBookingCommissionList(
      page,
      limit,
      search,
      status
    );
  }

  public getBulkBookingCommissionDetail(
    id: number
  ): Promise<BulkBookingCommissionDetail> {
    return this.repository.getBulkBookingCommissionDetail(id);
  }
}
