import { Pagination } from "./Pagination";

export class ExchangeRateEntities {
  pagination: Pagination;
  data: ExchangeRateData[];
  constructor(pagination: Pagination, data: ExchangeRateData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ExchangeRateData {
  id: number;
  exchangeRateFrom: string;
  exchangeRateTo: string;
  exchangeRate: any;
  status: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  constructor(
    id: number,
    exchangeRateFrom: string,
    exchangeRateTo: string,
    exchangeRate: any,
    status: string,
    startDate: string,
    endDate: string,
    createdAt: string,
    updatedAt: string,
    createdBy: string,
    updatedBy: string
  ) {
    this.id = id;
    this.exchangeRateFrom = exchangeRateFrom;
    this.exchangeRateTo = exchangeRateTo;
    this.exchangeRate = exchangeRate;
    this.status = status;
    this.startDate = startDate;
    this.endDate = endDate;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
  }
}
