import { AccountTypeDetail } from "@/domain/entities/Account";
const rolePermissionForClient = (account: AccountTypeDetail, roles: any) => {
  const condition: any = {
    removeRoles: ["shipment_stt_manual_enable"]
  };

  if (!account.isDO) {
    return roles;
  } else {
    return roles.filter((item: any) => {
      if (!condition["removeRoles"].includes(item.name)) {
        return roles;
      }
    });
  }
};
export default rolePermissionForClient;
