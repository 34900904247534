import {
  EditAnnouncementApiRequest,
  RequestListAnnouncement
} from "@/data/payload/api/AnnouncementApiRequest";
import {
  ChangeAnnouncementRequestInterface,
  EditAnnouncementRequestInterface
} from "@/data/payload/contracts/AnnouncementRequest";
import { AnnouncementMapper } from "@/data/persistences/mappers/AnnouncementMapper";
import { AnnouncementRepositoryInterface } from "@/data/persistences/repositories/contracts/AnnouncementRepositoryInterface";
import {
  AnnouncementData,
  AnnouncementEntities
} from "@/domain/entities/Announcement";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class AnnouncementApiRepository
  implements AnnouncementRepositoryInterface {
  private service: ApiService;
  private mapper: AnnouncementMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: AnnouncementMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getList(
    params: RequestListAnnouncement
  ): Promise<AnnouncementEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListAnnouncement(params),
      {}
    );
    return this.mapper.convertAnnouncementFromApi(resp);
  }

  public async getDetail(id: number): Promise<AnnouncementData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getAnnouncementDetail(id),
      {}
    );
    return this.mapper.convertAnnouncementDetailFromApi(resp);
  }

  public async editDetail(
    id: number,
    payload: EditAnnouncementRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePostWithFormData(
      "PATCH",
      this.endpoints.editAnnouncement(id),
      undefined,
      payload as EditAnnouncementApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async addAnnouncement(
    payload: ChangeAnnouncementRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invokePostWithFormData(
      "post",
      this.endpoints.addAnnouncement(),
      {},
      payload
    );
    return this.mapper.convertChangeDataFromApi(response);
  }

  public async deleteAnnouncement(id: number): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "delete",
      this.endpoints.deleteAnnouncement(id),
      {}
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }
}
