export class ConfigPriceDetail {
  heavyweightSurchargeId = 0;
  heavyweightSurchargeCommodityInclude: any = "";
  heavyweightSurchargeCreatedAt = "";
  heavyweightSurchargeCreatedBy = "";
  heavyweightSurchargeUpdatedAt = "";
  heavyweightSurchargeUpdatedBy = "";
  heavyweightSurchargeStatus = "";
  constructor(fields?: Partial<ConfigPriceDetail>) {
    Object.assign(this, fields);
  }
}
export class ConfigPriceData {
  id = 0;
  configurablePriceName = "";
  configurablePriceType = "";
  configurablePriceDescription = "";
  configurablePriceStatus = "";
  configurablePriceCreatedAt = "";
  configurablePriceCreatedBy = "";
  configurablePriceUpdatedAt = "";
  configurablePriceUpdatedBy = "";
  configurablePriceDetail: ConfigPriceDetail = new ConfigPriceDetail();
  constructor(fields?: Partial<ConfigPriceData>) {
    Object.assign(this, fields);
  }
}
