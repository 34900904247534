/* eslint-disable @typescript-eslint/camelcase */

import { Pagination } from "@/domain/entities/Pagination";
import {
  BankAccountEntities,
  BankAccountData
} from "@/domain/entities/BankAccount";
import { AxiosResponse } from "axios";
import {
  BalanceData,
  InternalBankAccountData,
  InternalBankAccountList,
  InternalBankAccountDetail,
  TransactionList,
  TransactionListData,
  TopupManualDetail,
  TransactionHistoryEntities,
  TransactionHistoryData
} from "@/domain/entities/Balance";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { ReportCheckData } from "@/domain/entities/Report";
import parsingLinkGcpToAws from "@/app/infrastructures/misc/common-library/ParsingLinkGcpToAws";

export class BalanceMapper {
  public convertBankAccountFromApi(res: AxiosResponse): BankAccountEntities {
    const { data } = res;
    const bankAccounts: BankAccountData[] = [];
    if (data.data.length === 0) {
      return new BankAccountEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        bankAccounts.push(
          new BankAccountData(
            item.bank_account_id,
            item.bank_account_label,
            item.bank_account_bank_name,
            item.bank_account_bank_logo,
            item.bank_account_account_number,
            item.bank_account_account_name,
            item.bank_account_account_type,
            item.bank_account_account_email,
            item.bank_account_payment_procedure,
            item.bank_account_actor_id,
            item.bank_account_actor_type,
            item.bank_account_created_at,
            item.bank_account_updated_at
          )
        );
      });
    }
    return new BankAccountEntities(
      new Pagination(data.meta.page, data.meta.limit),
      bankAccounts
    );
  }

  public convertBalanceDataFromApi(res: AxiosResponse): BalanceData {
    const { data } = res;
    return new BalanceData({
      walletId: data.data.wallet_id,
      walletActorType: data.data.wallet_actor_type,
      walletActorId: data.data.wallet_actor_id,
      walletBalance:
        data.data.wallet_balance ||
        data.data.balance.balance_total_current_amount,
      walletBalanceCod: data.data.wallet_balance_cod,
      walletCreatedAt: data.data.wallet_created_at,
      walletUpdatedAt:
        data.data.wallet_updated_at || data.data.wallet_created_at,
      walletPoint: data.data.point?.point_total_current_amount
    });
  }

  public convertTransactionListFromApi(apiVersion: string, res: AxiosResponse): TransactionList {
    const { data } = res;
    const transactionList: TransactionListData[] = [];
    data.data.forEach((item: any) => {
      let payload;
      if (apiVersion === "v1") {
        payload = new TransactionListData(
          item.pg_trx_id,
          item.pg_trx_no,
          item.pg_trx_type,
          item.pg_trx_activity,
          item.pg_trx_amount,
          item.pg_trx_actor_id,
          item.pg_trx_actor_type,
          item.pg_trx_beneficiary_name,
          item.pg_trx_beneficiary_account,
          item.pg_trx_beneficiary_bank,
          item.pg_trx_session_id,
          item.pg_trx_ref_no,
          item.pg_trx_stt_no,
          item.pg_trx_status,
          item.pg_trx_va,
          item.pg_trx_created_at,
          item.pg_trx_note,
          item.pg_trx_va_partner_name,
          item.pg_trx_last_balance
        );
      } else {
        payload = new TransactionListData(
          item.tn_id,
          item.tn_no,
          item.tn_type,
          item.tn_activity,
          item.tn_amount,
          item.tn_actor_id,
          item.tn_actor_type,
          item.tn_beneficiary_name,
          item.tn_beneficiary_account,
          item.tn_beneficiary_bank,
          item.tn_session_id,
          item.tn_ref_no,
          item.tn_stt_no,
          item.tn_status,
          item.tn_va,
          item.tn_created_at,
          item.tn_note,
          item.tn_va_partner_name,
          item.tn_last_balance
        )
      }
      transactionList.push(payload);
    });
    return new TransactionList(
      new Pagination(data.meta.page, data.meta.limit),
      transactionList
    );
  }

  public convertInternalBankAccountFromApi(
    res: AxiosResponse
  ): InternalBankAccountList {
    const { data } = res;
    const bankAccounts: InternalBankAccountData[] = [];
    if (data.data.length === 0) {
      return new InternalBankAccountList([]);
    } else {
      for (const bankAccount of Object.keys(data.data)) {
        for (const bankAccountDetail of data.data[bankAccount]) {
          bankAccounts.push(
            new InternalBankAccountData(
              bankAccountDetail.internal_bank_account_id,
              bankAccountDetail.internal_bank_account_name,
              bankAccountDetail.internal_bank_account_account_name,
              bankAccountDetail.internal_bank_account_account_number
            )
          );
        }
      }
    }
    return new InternalBankAccountList(bankAccounts);
  }

  public convertInternalBankAccountDetailFromApi(
    res: AxiosResponse
  ): InternalBankAccountDetail {
    const {
      data: { data }
    } = res;
    if (data === null) {
      return new InternalBankAccountDetail(0, "", "", "", "", "");
    } else {
      return new InternalBankAccountDetail(
        data.internal_bank_account_id,
        data.internal_bank_account_name,
        data.internal_bank_account_logo,
        data.internal_bank_account_account_name,
        data.internal_bank_account_account_number,
        data.internal_bank_account_intruction
      );
    }
  }

  public convertTopupManualDetailFromApi(
    res: AxiosResponse
  ): TopupManualDetail {
    const {
      data: { data }
    } = res;
    return new TopupManualDetail(
      data.tum_id,
      data.tuml_genesis_transaction_id,
      data.tum_sender_bank_name,
      data.tum_sender_bank_account_name,
      data.tum_sender_bank_account_number,
      data.tum_recepient_bank_name,
      data.tum_recepient_bank_account_name,
      data.tum_recepient_bank_account_number,
      data.tum_amount,
      data.tum_status,
      data.tum_reject_reason,
      parsingLinkGcpToAws(data.tum_file_of_proof),
      data.tum_actor_id,
      data.tum_actor_type,
      data.tum_actor_name,
      data.tum_actor_code,
      data.tum_created_at,
      data.tum_created_by,
      data.tum_created_name,
      data.tum_updated_at || "",
      data.tum_updated_by,
      data.tum_verification_by,
      data.tum_verification_name,
      data.tum_verification_at || "",
      data.tum_approval_by,
      data.tum_approval_name,
      data.tum_approval_at || ""
    );
  }

  public convertTransactionHistoryFromApi(
    res: AxiosResponse
  ): TransactionHistoryEntities {
    const { data } = res;
    const transactionHistory: TransactionHistoryData[] = [];
    if (data.data.length === 0) {
      return new TransactionHistoryEntities(new Pagination(1, 10), []);
    } else {
      data.data.map((item: any) => {
        transactionHistory.push(
          new TransactionHistoryData(
            item.tum_id,
            item.genesis_transaction_id,
            item.tum_amount,
            item.tum_sender_bank_name,
            item.tum_sender_bank_account_name,
            item.tum_sender_bank_account_number,
            item.tum_created_at,
            item.tum_created_name,
            item.tum_status,
            item.tum_reject_reason
          )
        );
      });
    }
    return new TransactionHistoryEntities(
      new Pagination(data.meta.page, data.meta.limit),
      transactionHistory
    );
  }
  public convertAddTopupManualFromApi(res: AxiosResponse): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: data.data
    });
  }
  public convertCheckDataFromApi(res: AxiosResponse<any>): ReportCheckData {
    const { data } = res;
    return new ReportCheckData({
      code: data.code,
      message: data.message,
      success: data.success,
      data: data.data
    });
  }
  public convertWithdrawBalanceFromApi(res: AxiosResponse): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
