import { ConfigFakeDexIndicationMapper } from "@/data/persistences/mappers/ConfigFakeDexIndicationMapper";
import { ConfigFakeDexIndicationEndpoint } from "../../endpoints/horde/ConfigFakeDexIndicationEndpoint";
import ApiService from "../../services/ApiService";
import { ConfigFakeDexIndicationRepositoryInterface } from "@/data/persistences/repositories/contracts/ConfigFakeDexIndicationRepositoryInterface";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { EditConfigFakeDexIndicationRequest } from "@/data/payload/api/ConfigFakeDexIndicationRequest";

export class ConfigFakeDexIndicationApiRepository
  implements ConfigFakeDexIndicationRepositoryInterface {
  private service: ApiService;
  private mapper: ConfigFakeDexIndicationMapper;
  private endpoints: ConfigFakeDexIndicationEndpoint;

  constructor(
    service: ApiService,
    mapper: ConfigFakeDexIndicationMapper,
    endpoints: ConfigFakeDexIndicationEndpoint
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  public async editConfig(payload: EditConfigFakeDexIndicationRequest, id: any): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "PATCH",
      this.endpoints.editConfig(id),
      {},
      payload
    );
    return this.mapper.convertEditConfigFromApi(resp);
  }

  public async getList(params: ApiRequestList): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getList(params),
      {}
    );
    return this.mapper.convertListDataFromApi(resp);
  }

  public async getDetail(id: any): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getDetail(id),
      {}
    );
    return this.mapper.convertDetailDataFromApi(resp);
  }
}
