export class PodDexData {
  deliveryId = 0;
  sttNo = "";
  sttNoRefExternal = "";
  totalPiece = 0;
  chargeableWeight = 0;
  productName = "";
  lastStatus = "";
  commodityName = "";
  codAmount = 0;
  deliveryDate = "";
  courierName = "";
  courierPhoneNumber = "";
  deliveredBy = "";
  deliveredByType = "";
  sttDeliveryAttempt = "";
  recipientName = "";
  recipientAddress = "";
  recipientPhoneNumber = "";
  proofFile: Array<string> = [];
  sttIsCod = false;
  sttIsDfod = false;
  paymentMethod = "";
  constructor(fields?: Partial<PodDexData>) {
    Object.assign(this, fields);
  }
}

export class ReasonData {
  reasonId = 0;
  reasonCode = "";
  reasonExternalCode = "";
  reasonDescription = "";
  reasonStatus = "";
  reasonAbleGenerateNewSTT = false;
  statusCode = "";
  constructor(fields?: Partial<ReasonData>) {
    Object.assign(this, fields);
  }
}

export class RecipientRelation {
  value = '';
  name = '';
  constructor(fields?: Partial<RecipientRelation>) {
    Object.assign(this, fields);
  }
}

export class ListRecipientRelation {
  data: RecipientRelation[] = [];
  constructor(fields?: Partial<ListRecipientRelation>) {
    Object.assign(this, fields);
  } 
}

export class CheckPaymentStatus {
  success: boolean;
  code: number;
  message: string;
  constructor(success: boolean, code: number, message: string) {
    this.success = success;
    this.code = code;
    this.message = message;
  }
}
