import { FlagsPermissionDexAssessment } from "@/feature-flags/flags-permission-dex-assessment";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const DexAssessmentList = () =>
  import(
    /* webpackChunkName: "DexAssessmentList" */ "@/app/ui/views/dex-assessment/index.vue"
  );
const DexAssessmentDetail = () =>
  import(
    /* webpackChunkName: "DexAssessmentDetail" */ "@/app/ui/views/dex-assessment/component/detail.vue"
  );

const ListTicketDex = () =>
  import("@/app/ui/views/dex-assessment/list-ticket-dex/index.vue");
const Dashboard = () =>
  import("@/app/ui/views/dex-assessment/dashboard/index.vue");
const DexAssessmentTab = () =>
  import(
    "@/app/ui/views/dex-assessment/dashboard/dex-assessment-tab/index.vue"
  );
const DexDisputeL2Tab = () =>
  import(
    "@/app/ui/views/dex-assessment/dashboard/dex-dispute-l2-tab/index.vue"
  );
const PerformaAgentTab = () =>
  import(
    "@/app/ui/views/dex-assessment/dashboard/performa-agent-tab/index.vue"
  );

const DashboardConsole = () =>
  import("@/app/ui/views/dex-assessment/dashboard-consolidator/index.vue");

export default [
  {
    path: "/dex-assessment-l1",
    component: Layout,
    redirect: "/dex-assessment-l1",
    name: "Customer Service L1",
    meta: {
      icon: "customer-service",
      children: false,
      title: "Customer Service L1",
      name: "Customer Service L1",
      permission:
        FlagsPermissionDexAssessment.permission_dex_assessment_customer_service_l1_enable
    },
    children: [
      {
        path: "/dex-assessment-l1",
        name: "Customer Service L1",
        component: DexAssessmentList,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/dex-assessment-l2",
    component: Layout,
    redirect: "/dex-assessment-l2",
    name: "Customer Service L2",
    meta: {
      icon: "customer-service",
      children: false,
      title: "Customer Service L2",
      name: "Customer Service L2",
      permission:
        FlagsPermissionDexAssessment.permission_dex_assessment_customer_service_l2_enable
    },
    children: [
      {
        path: "/dex-assessment-l2",
        name: "Customer Service L2",
        component: DexAssessmentList,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/dex-assessment-l1/detail",
    redirect: "/dex-assessment-l1",
    component: Layout,
    name: "dex-assessment-detail-l1",
    meta: {
      title: "Customer Service L1 | Detail",
      hidden: true,
      parent: "Customer Service L1",
      permission:
        FlagsPermissionDexAssessment.permission_dex_assessment_customer_service_l1_enable
    },
    children: [
      {
        path: ":id",
        name: "dex-assessment-detail-l1",
        component: DexAssessmentDetail,
        meta: {
          header: true,
          title: "Customer Service L1 | Detail"
        }
      }
    ]
  },
  {
    path: "/dex-assessment-l2/detail",
    redirect: "/dex-assessment-l2",
    component: Layout,
    name: "dex-assessment-detail-l2",
    meta: {
      title: "Customer Service L2 | Detail",
      hidden: true,
      parent: "Customer Service L2",
      permission:
        FlagsPermissionDexAssessment.permission_dex_assessment_customer_service_l2_enable
    },
    children: [
      {
        path: ":id",
        name: "dex-assessment-detail-l2",
        component: DexAssessmentDetail,
        meta: {
          header: true,
          title: "Customer Service L2 | Detail"
        }
      }
    ]
  },
  {
    path: "/dex-assessment",
    component: Layout,
    redirect: "/dex-assessment",
    name: "Dex Asesmen Admin",
    meta: {
      icon: "dex-assessment",
      children: true,
      title: "Dex Asesmen",
      name: "Dex Asesmen",
      permission: [
        FlagsPermissionDexAssessment.permission_dex_assessment_admin_enable,
        FlagsPermissionDexAssessment.permission_dex_assessment_admin_dashboard_enable
      ]
    },
    children: [
      {
        path: "dex-assessment-dashboard",
        name: "dex-assessment-dashboard-list",
        redirect: "/dex-assessment/dex-assessment-dashboard/dex-assessment",
        component: Dashboard,
        meta: {
          header: true,
          name: "Dashboard",
          customName: "Dashboard DEX Asesmen",
          title: "Dashboard",
          icon: "polar-area-chart",
          permission:
            FlagsPermissionDexAssessment.permission_dex_assessment_admin_dashboard_enable
        },
        children: [
          {
            path: "dex-assessment",
            name: "dex-assessment-tab",
            title: "DEX Asesmen",
            component: DexAssessmentTab
          },
          {
            path: "dex-dispute-l2",
            name: "dex-dispute-l2-tab",
            title: "DEX Sanggah L2",
            component: DexDisputeL2Tab
          },
          {
            path: "performa-agent",
            name: "performa-agent-tab",
            title: "Performa Agen",
            component: PerformaAgentTab
          }
        ]
      },
      {
        path: "list-ticket-dex",
        name: "list-ticket-dex-list",
        component: ListTicketDex,
        meta: {
          header: true,
          name: "Daftar Tiket DEX",
          title: "Daftar Tiket DEX",
          icon: "home",
          permission:
            FlagsPermissionDexAssessment.permission_dex_assessment_admin_enable
        }
      }
    ]
  },
  {
    path: "/dex-assessment/list-ticket-dex",
    redirect: "/dex-assessment",
    component: Layout,
    name: "list-ticket-dex-detail",
    meta: {
      title: "Daftar Tiket DEX | Detail",
      hidden: true,
      parent: "Daftar Tiket DEX",
      permission:
        FlagsPermissionDexAssessment.permission_dex_assessment_admin_enable
    },
    children: [
      {
        path: ":id",
        name: "list-ticket-dex-detail",
        component: DexAssessmentDetail,
        meta: {
          header: true,
          title: "Daftar Tiket DEX | Detail"
        }
      }
    ]
  },

  // console
  {
    path: "",
    component: Layout,
    redirect: "/dashboard-console",
    name: "Dex Asesmen Console",
    meta: {
      icon: "dex-assessment",
      children: true,
      title: "Dex Asesmen",
      name: "Dex Asesmen",
      permission: [
        FlagsPermissionDexAssessment.permission_dex_assessment_dashboard_consolidator_enable,
        FlagsPermissionDexAssessment.permission_dex_assessment_customer_consolidator_enable
      ]
    },
    children: [
      {
        path: "dex-assessment-dashboard-console",
        name: "dashboard-list",
        component: DashboardConsole,
        meta: {
          header: true,
          name: "Dashboard",
          title: "Dashboard",
          icon: "polar-area-chart",
          permission:
            FlagsPermissionDexAssessment.permission_dex_assessment_customer_consolidator_enable
        }
      },
      {
        path: "dex-assessments",
        name: "Dex Asesmen",
        component: DexAssessmentList,
        meta: {
          header: true,
          name: "Dex Asesmen",
          title: "Dex Asesmen",
          icon: "dex-assessment",
          permission:
            FlagsPermissionDexAssessment.permission_dex_assessment_customer_consolidator_enable
        }
      }
    ]
  },
  {
    path: "/dex-assessment/detail",
    redirect: "/dex-assessments", // for back to parent
    component: Layout,
    name: "dex-assessment-detail-console",
    meta: {
      title: "DEX Assessmen | Detail",
      hidden: true,
      parent: "DEX Assessmen",
      permission:
        FlagsPermissionDexAssessment.permission_dex_assessment_customer_consolidator_enable
    },
    children: [
      {
        path: ":id",
        name: "dex-assessment-detail-console",
        component: DexAssessmentDetail,
        meta: {
          header: true,
          title: "DEX Assessmen | Detail"
        }
      }
    ]
  }
];
