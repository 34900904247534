import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import {
  StiDestEntities,
  StiDestData,
  StiDestManifestData,
  StiDestSttList,
  StiDestSttValidaton,
  StiDestSttDetail,
  StiDestPieceDetail,
  StiDestSttResult,
  SttPiece,
  ResponseUpdate,
  StiDestDetail,
  STIDestReverseScan,
  ReverseScan,
  ListSTIDestTemporary,
  STIDestTemporary,
  StiDestSummary,
  PriorityDelSummary,
  ListNeedToSTIDestDel,
  NeedToSTIDestDel,
  SummaryNeedToSTIDestDel
} from "@/domain/entities/StiDest";
import { Pagination, PaginationV2 } from "@/domain/entities/Pagination";

export class StiDestMapper {
  public convertStiDestDataFromApi(res: AxiosResponse<any>): StiDestEntities {
    const { data } = res;

    const stidest: StiDestData[] = [];
    if (data.data.length === 0) {
      return new StiDestEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        stidest.push(
          new StiDestData(
            item.sti_dest_id,
            item.sti_dest_cargo_no,
            item.sti_dest_cargo_type,
            item.sti_dest_arrival_city_code,
            item.sti_dest_arrival_city_name,
            item.sti_dest_total_stt,
            item.sti_dest_total_piece,
            item.sti_dest_total_gross_weight,
            item.sti_dest_total_volume_weight,
            item.sti_dest_created_at,
            item.sti_dest_created_name,
            item.sti_dest_status_return_pod || 0,
            item.sti_dest_status_return_rts || 0,
            item.sti_dest_status_return_hal || 0,
            item.sti_dest_misroute || 0,
            item.sti_dest_transit || 0
          )
        );
      });
    }
    return new StiDestEntities(
      new Pagination(data.meta.page, data.meta.limit),
      stidest
    );
  }

  public convertDetailStiDestDataFromApi(
    result: AxiosResponse<any>
  ): StiDestDetail {
    const {
      data: { data }
    } = result;
    return new StiDestDetail({
      stiDestId: data.sti_dest_id,
      stiDestPartnerName: data.sti_dest_partner_name,
      consolidatorName: data.sti_dest_partner_name,
      originConsolidator: data.sti_dest_origin_city_name,
      destinationConsolidator: data.sti_dest_city_name,
      originCityCode: data.sti_dest_origin_city_code,
      destinationCityCode: data.sti_dest_city_code,
      stiDestDate: data.sti_dest_created_at,
      stiDestCargoNo: data.sti_dest_cargo_no,
      stiDestCustomVehicleNo: data.sti_dest_custom_vehicle_no,
      stiDestCargoType: data.sti_dest_cargo_type,
      stiDestTotalTransit: data.sti_dest_total_transit || 0,
      stiDestTotalMisroute: data.sti_dest_total_misroute || 0,
      stiDestTotalStatusReturnPOD: data.sti_dest_total_status_return_pod || 0,
      stiDestTotalStatusReturnRTS: data.sti_dest_total_status_return_rts || 0,
      stiDestTotalStatusReturnHal: data.sti_dest_total_status_return_hal || 0,
      stt: data.stt.map(
        (item: any) =>
          new StiDestSttResult({
            sttId: item.stt_id,
            sttNo: item.stt_no,
            sttProductType: item.stt_product_type,
            sttTotalPiece: item.stt_total_piece,
            sttDestinationCityId: item.stt_destination_city_id,
            sttDestinationCityName: item.stt_destination_city_name,
            sttWoodPacking: "",
            sttCommodityCode: item.stt_commodity_code,
            sttCommodityName: item.stt_commodity_name,
            sttGrossWeight: item.stt_gross_weight,
            sttVolumeWeight: item.stt_volume_weight,
            sttChargeableWeight: item.stt_chargeable_weight,
            sttCodAmount: 0,
            sttBagNo: item.bag_no,
            sttBagVendorNo: item.bag_vendor_no,
            sttPiece: item.piece.map(
              (item: any) =>
                new SttPiece({
                  sttPieceId: item.stt_piece_id,
                  sttPieceGrossWeight: item.stt_piece_gross_weight,
                  sttPieceVolumeWeight: item.stt_piece_volume_weight,
                  sttPieceNo: item.stt_piece_no
                })
            ),
            origin: item.stt_origin_city_id,
            destination: item.stt_destination_city_id,
            isMisroute: item.is_misroute || 0,
            isTransit: item.is_transit || 0,
            statusReturn: item.status_return || "",
            sttNoRefExternal: item.ref_no || item.stt_no_ref_external || item.stt_shipment_id || item.booking_id || ""
          })
      ),
      totalGrossWeight: data.sti_dest_total_gross_weight,
      totalPiece: data.sti_dest_total_pieces,
      totalStt: data.sti_dest_total_stt,
      totalVolumeWeight: data.sti_dest_total_volume_weight,
      sttCreatedName: data.sti_dest_created_name
    });
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: new ResponseUpdate(
        data.data.sti_dest_id,
        data.data.total_success,
        data.data.total_failed,
        data.data.stt_failed
      )
    });
  }

  public convertStiDestSttListFromApi(res: AxiosResponse<any>): StiDestSttList {
    const {
      data: { data }
    } = res;
    const stiDestSttList: StiDestSttValidaton[] = [];
    if (data === null) {
      return new StiDestSttList([]);
    } else {
      data.map((item: any) => {
        stiDestSttList.push(
          new StiDestSttValidaton({
            isAllowUpdateStatus: item.is_allow_update_status,
            errorMessage: item.error_message,
            stt: new StiDestSttDetail({
              isOneBagScan: item.is_one_bag_scan,
              bagNo: item.stt.bag_no,
              bagVendorNo: item.stt.bag_vendor_no,
              cargoNo: item.stt.cargo_no,
              isTransit: item.stt.is_transit,
              isMissroute: item.stt.is_missroute,
              sttId: item.stt.stt_id,
              sttNo: item.stt.stt_no,
              sttElexysNumber: !item.stt.stt_elexys_no
                ? "-"
                : item.stt.stt_elexys_no,
              sttProductType: item.stt.stt_product_type,
              sttTotalPiece: item.stt.stt_total_piece,
              sttDestinationCityId: item.stt.stt_destination_city_id,
              sttDestinationCityName: item.stt.stt_destination_city_name,
              sttWoodPacking: item.stt.stt_wood_packing,
              sttCommodityCode: item.stt.stt_commodity_code,
              sttCommodityName: item.stt.stt_commodity_name,
              sttGrossWeight: item.stt.stt_gross_weight,
              sttVolumeWeight: item.stt.stt_volume_weight,
              sttChargeableWeight: item.stt.stt_chargeable_weight,
              sttCodAmount: item.stt.stt_cod_amount,
              sttLastStatusId: item.stt.stt_last_status_id,
              posName: item.stt.pos_name,
              bookedAt: item.stt.booked_at,
              piece: item.stt.piece
                ? item.stt.piece?.map(
                  (item: any) =>
                    new StiDestPieceDetail(
                      item.stt_piece_id,
                      item.stt_piece_gross_weight,
                      item.stt_piece_volume_weight,
                      item.stt_piece_no,
                      item.stt_piece_last_status_id
                    )
                )
                : []
            }),
            isPaid: item.is_paid,
            lastStatus: item?.last_status
          })
        );
      });
      return new StiDestSttList(stiDestSttList);
    }
  }

  public convertStiDestManifestDataFromApi(
    res: AxiosResponse<any>
  ): StiDestManifestData {
    const {
      data: { data }
    } = res;
    if (data.piece === null) {
      return new StiDestManifestData(
        0,
        "",
        "",
        "",
        "",
        "",
        "",
        0,
        0,
        0,
        0,
        "",
        []
      );
    } else {
      return new StiDestManifestData(
        data.sti_dest_partner_id,
        data.sti_dest_partner_name,
        data.sti_dest_city_name,
        data.sti_dest_city_code,
        data.sti_dest_cargo_no,
        data.sti_dest_custom_vehicle_no,
        data.sti_dest_cargo_type,
        data.sti_dest_total_stt,
        data.sti_dest_total_pieces,
        data.sti_dest_total_gross_weight,
        data.sti_dest_total_volume_weight,
        data.sti_dest_created_at,
        data.stt.map(
          (item: any) =>
            new StiDestSttResult({
              sttId: item.stt_id,
              sttNo: item.stt_no,
              sttElexysNumber: item.stt_elexys_no,
              sttProductType: item.stt_product_type,
              sttTotalPiece: item.stt_total_piece,
              sttDestinationCityId: item.stt_destination_city_id,
              sttDestinationCityName: item.stt_destination_city_name,
              sttWoodPacking: item.stt_wood_packing,
              sttCommodityCode: item.stt_commodity_code,
              sttCommodityName: item.stt_commodity_name,
              sttGrossWeight: item.stt_gross_weight,
              sttVolumeWeight: item.stt_volume_weight,
              sttChargeableWeight: item.stt_chargeable_weight,
              sttCodAmount: item.stt_cod_amount,
              sttBagNo: item.bag_no,
              sttBagVendorNo: item.bag_vendor_no,
              sttPiece: item.piece.map((item: any) => {
                new SttPiece({
                  sttPieceId: item.stt_piece_id,
                  sttPieceGrossWeight: item.stt_piece_gross_weight,
                  sttPieceVolumeWeight: item.stt_piece_volume_weight,
                  sttPieceNo: item.stt_piece_no
                });
              }),
              origin: item.stt_origin_city_id,
              destination: item.stt_destination_city_id
            })
        )
      );
    }
  }

  public convertReverseScanStiDest(res: AxiosResponse<any>): STIDestReverseScan {
    const {
      data
    } = res;
    return data.data?.stt?.stt_no ? new STIDestReverseScan({
      stt: new ReverseScan({
        bagNo: data.data.stt.bag_no || "",
        bagVendorNo: data.data.stt.bag_vendor_no || "",
        cargoNo: data.data.stt.cargo_no || "",
        isTransit: data.data.stt.is_transit || false,
        isMissroute: data.data.stt.is_missroute || data.data.stt.is_misroute || false,
        flag: data.data.stt.flag || "",
        statusReturn: data.data.stt.status_return || "",
        sttId: data.data.stt.stt_id || 0,
        sttNo: data.data.stt.stt_no || "",
        sttNoRefExternal: data.data.stt.ref_no || data.data.stt.stt_shipment_id || data.data.stt.booking_id || "",
        sttDestinationCityId: data.data.stt.stt_destination_city_id || "",
        sttDestinationCityName: data.data.stt.stt_destination_city_name || "",
        sttProductType: data.data.stt.stt_product_type || "",
        sttCommodityCode: data.data.stt.stt_commodity_code || "",
        sttCommodityName: data.data.stt.stt_commodity_name || "",
        sttTotalPiece: data.data.stt.stt_total_piece || 0,
        sttLastStatusId: data.data.stt.stt_last_status_id || "",
        sttBookedAt: data.data.stt.booked_at || "",
        sttBookedName: data.data.stt.pos_name || "",
        sttPieces: data.data.stt.piece || [],
        sttOriginCityId: data.data.stt.stt_origin_city_id || "",
        sttOriginCityName: data.data.stt.stt_origin_city_name || "",
        deadlineReturn: data.data.stt.deadline_return || "",
        sttGrossWeight: data.data.stt.stt_gross_weight || 0,
        sttAssessmentStatus: data.data.stt.stt_assessment_status
      })
    }) : new STIDestReverseScan({
      bag: data.data.bag?.length ? data.data.bag.map((key: any) => new ReverseScan({
        bagNo: key.bag_no || "",
        bagVendorNo: key.bag_vendor_no || "",
        cargoNo: key.cargo_no || "",
        isTransit: key.is_transit || false,
        isMissroute: key.is_missroute || false,
        flag: key.flag || "",
        statusReturn: key.status_return || "",
        sttId: key.stt_id || "",
        sttNo: key.stt_no || "",
        sttNoRefExternal: key.ref_no || key.stt_shipment_id || key.booking_id || "",
        sttDestinationCityId: key.stt_destination_city_id || "",
        sttDestinationCityName: key.stt_destination_city_name || "",
        sttProductType: key.stt_product_type || "",
        sttCommodityCode: key.stt_commodity_code || "",
        sttCommodityName: key.stt_commodity_name || "",
        sttTotalPiece: key.stt_total_piece || 0,
        sttLastStatusId: key.stt_last_status_id || "",
        sttBookedAt: key.booked_at || "",
        sttBookedName: key.pos_name || "",
        sttPieces: key.piece || [],
        sttOriginCityId: key.stt_origin_city_id || "",
        sttOriginCityName: key.stt_origin_city_name || "",
        deadlineReturn: key.deadline_return || "",
        sttGrossWeight: key.stt_gross_weight || 0,
        sttAssessmentStatus: key.stt_assessment_status
      })) : [],
    })
  }

  public convertListTemporaryScanStiDest(res: AxiosResponse<any>): ListSTIDestTemporary {
    const { data } = res;
    return new ListSTIDestTemporary({
      data: data.data?.length ? data.data.map((key: any) => new STIDestTemporary({
        sdtId: key.sdt_id,
        sdtAccountId: key.sdt_account_id,
        sdtIsActive: key.sdt_is_active,
        sdtSttNo: key.sdt_stt_no,
        sdtProduct: key.sdt_product,
        sdtOrigin: key.sdt_origin,
        sdtDestination: key.sdt_destination,
        sdtDeadlineReturn: key.sdt_deadline_return,
        sdtBagNo: key.sdt_meta.bag_no,
        sdtIsMisroute: key.sdt_meta.is_misroute,
        sdtIsTransit: key.sdt_meta.is_transit,
        sdtStatusReturn: key.sdt_meta.status_return,
        sdtGrossWeight: key.sdt_meta.gross_weight || 0,
        sdtFlag: key.sdt_meta.flag,
        sdtPieces: key.sdt_meta.pieces,
        sdtRefNo: key.sdt_meta.ref_no,
        sdtCreatedAt: key.sdt_created_at,
        sdtUpdatedAt: key.sdt_updated_at,
        pieces: key.sdt_meta.pieces,
        sttAssessmentStatus: key.sdt_meta.stt_assessment_status
      })) : []
    })
  }

  public convertStiDestSummary(result: AxiosResponse<any>): StiDestSummary {
    const {
      data: { data }
    } = result;
    return new StiDestSummary({
      totalCargoPlane: data.total_cargo_plane,
      totalCargoTruck: data.total_cargo_truck,
      totalCargoShip: data.total_cargo_ship,
      totalSttNeedStidest: data.total_stt_need_stidest,
      totalDelNow: data.total_del_now,
      totalDelNowIntracity: data.total_del_now_intracity,
      totalDelNowIntercity: data.total_del_now_intercity,
      totalDelOverdue: data.total_del_overdue,
      totalDelOverdueIntracity: data.total_del_overdue_intracity,
      totalDelOverdueIntercity: data.total_del_overdue_intercity
    });
  }

  public convertPriorityDelSummary(
    result: AxiosResponse<any>
  ): PriorityDelSummary {
    const {
      data: { data }
    } = result;
    return new PriorityDelSummary({
      totalPod: data.total_pod,
      totalRts: data.total_rts,
      totalHal: data.total_hal
    });
  }

  public convertListSTIDestNeedToDel(res: AxiosResponse<any>): ListNeedToSTIDestDel {
    const { data } = res;
    return new ListNeedToSTIDestDel({
      pagination: new PaginationV2({ page: data.meta.page, limit: data.meta.limit, totalData: data.meta.total_records }),
      data: data.data?.length ? data.data.map((key: any) => new NeedToSTIDestDel({
        sdId: key.sd_id,
        cargoNo: key.cargo_no,
        cargoType: key.cargo_type,
        sttNo: key.stt_no,
        refNo: key.ref_no,
        sttLastStatusId: key.stt_last_status_id,
        sttProductType: key.stt_product_type,
        sttTotalPiece: key.stt_total_piece,
        sttGrossWeight: key.stt_gross_weight,
        sttOriginCityId: key.stt_origin_city_id,
        sttUpdatedAt: key.stt_updated_at,
        sttUpdatedBy: key.stt_updated_name, // stt_updated_name
        deadline: key.deadline
      })) : []
    })
  }

  public convertSummarySTIDestNeedToDel(res: AxiosResponse<any>): SummaryNeedToSTIDestDel {
    const { data } = res;
    return new SummaryNeedToSTIDestDel({
      totalDelNow: data.data.total_del_now || 0,
      totalDelOverdue: data.data.total_del_overdue || 0
    })
  }
}
