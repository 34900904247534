import { ChangeRequestInterface } from "../contracts/NotificationRequest";

/* eslint-disable @typescript-eslint/camelcase */
export class ReadNotificationApiRequest implements ChangeRequestInterface {
  private announcement_id: number;

  constructor(announcement_id: number) {
    this.announcement_id = announcement_id;
  }

  public toJSON() {
    return JSON.stringify({
      announcement_id: this.announcement_id
    });
  }
}
