/* eslint-disable @typescript-eslint/camelcase */
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { HeavyWeightSurchargeRepositoryInterface } from "@/data/persistences/repositories/contracts/HeavyWeightSurchargeRepositoryInterface";
import { HeavyWeightSurchargeMapper } from "@/data/persistences/mappers/HeavyWeightSurchargeMapper";
import { HeavyWeightSurchargeData } from "@/domain/entities/HeavyWeightSurcharge";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { EditHeavyWeightSurchargeRequestInterface } from "@/data/payload/contracts/HeavyWeightSurcharge";
import { EditHeavyWeightSurchargeApiRequest } from "@/data/payload/api/HeavyWeightSurchargeApiRequest";

export class HeavyWeightSurchargeApiRepository
  implements HeavyWeightSurchargeRepositoryInterface {
  private service: ApiService;
  private mapper: HeavyWeightSurchargeMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: HeavyWeightSurchargeMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getDetailHeavyWeightSurcharge(
    id: number
  ): Promise<HeavyWeightSurchargeData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailHeavyWeightSurcharge(id),
      {}
    );
    return this.mapper.convertDetailHeavyWeightSurchargeFromApi(resp);
  }

  public async editHeavyWeightSurcharge(
    payload: EditHeavyWeightSurchargeRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivate(
      "put",
      this.endpoints.editConfigurablePrice("v2"),
      undefined,
      payload as EditHeavyWeightSurchargeApiRequest
    );
    return this.mapper.convertPayloadData(resp);
  }
}
