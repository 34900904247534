import { FlagsPermissionShopify } from "@/feature-flags/flags-shopify";
import { getTokenMiddleware } from "../Cookies";
import { getToken } from "../Cookies";

const tokenizationMiddleware = () => {
    if (FlagsPermissionShopify.form_enable.isEnabled()) {
        return getToken()
    }

    return getTokenMiddleware() || process.env.VUE_APP_MIDDLEWARE_BEARER_TOKEN;
};
export default tokenizationMiddleware;
