import { IncomingOutgoingApiRepository } from "@/app/infrastructures/repositories/api/IncomingOutgoingApiRepository";
import { RequestDownloadIncomingOutgoing } from "@/domain/entities/IncomingOutgoing";
import { injectable } from "tsyringe";

@injectable()
export class IncomingOutgoingPresenter {
  private repository: IncomingOutgoingApiRepository;
  constructor(repository: IncomingOutgoingApiRepository) {
    this.repository = repository;
  }

  public getDownload(payload: RequestDownloadIncomingOutgoing) {
    return this.repository.getDownload(payload);
  }
}
