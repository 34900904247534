import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { BagOrSttNumber } from "@/data/payload/api/CargoApiRequest";
import {
  ScanReadyToCargoInterface,
  UpdateReadyToCargoInterface
} from "@/data/payload/contracts/ReadyToCargoRequest";
import { CargoDetailStt } from "./Cargo";
import { QueryParamsEntities } from "./MainApp";
import { FlightDetail } from "./NGen";
import { Pagination } from "./Pagination";
import { flags } from "@/feature-flags";

export class LogReadyToCargo {
  id = "";
  bagOrSttNo = "";
  createdBy = "";
  createdAt = "";
  status = "";
  originCityCode = "";
  originCityName = "";
  destinationCityCode = "";
  destinationCityName = "";
  description = "";
  constructor(fields?: Partial<LogReadyToCargo>) {
    Object.assign(this, fields);
  }
}

export class LogReadyToCargoListEntities {
  pagination = new Pagination(1, 20);
  data = [new LogReadyToCargo()];

  constructor(fields?: Partial<LogReadyToCargoListEntities>) {
    Object.assign(this, fields);
  }
}

export class RequestLogReadyToCargoList {
  page = 1;
  limit = 20;
  bagOrSttNo = "";
  status = "sedang-diproses,gagal-diproses";
  sortBy = "log_rtc_created_at";
  orderBy = "DESC";
  hubId = 0;

  constructor(fields?: Partial<RequestLogReadyToCargoList>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}


export class ReadyToCargo {
  id = 0;
  noReadyToCargo = "";
  totalBagging = 0;
  totalWeight = 0;
  totalPieces = 0;
  cargoType = "";
  minimumWeight = 0;
  maximumWeight = 0;
  originCode = "";
  originName = "";
  destinationCode = "";
  destinationName = "";
  status = "";
  partnerId = 0;
  partnerType = "";
  partnerCode = "";
  createdAt = "";
  createdName = "";
  updatedAt = "";
  updateName = "";
  hubName = "";
  detail: CargoDetailStt[] = [];
  flightsData = [] as FlightDetail[];
  commodityCode = "";
  commodityName = "";
  productType = "";
  haveConfigCargoFlight = false;
  thresholdTime = 0;
  cutOffTime = "";
  isOpenTooltip = false;
  clientParentName = "";
  constructor(fields?: Partial<ReadyToCargo>) {
    Object.assign(this, fields);
  }
  get convertThresholdTime () {
    if(this.thresholdTime === 0) {
      return flags.cargo_config_flight_treshold_default_value.getValue();
    }
    return this.thresholdTime;
  }

  get isMoreThanCutOffTime() {
    if (!this.cutOffTime && this.cargoType === 'plane') {
      return true;
    }
    return false;
  }
}

export class ReadyToCargoListEntities {
  pagination = new Pagination(1, 20);
  data = [new ReadyToCargo()];

  constructor(fields?: Partial<ReadyToCargoListEntities>) {
    Object.assign(this, fields);
  }
}

export class RequestReadyToCargoList {
  page = 1;
  limit = 20;
  search = "";
  destinationCode = "";
  status = "";
  sortBy = "rtc_id";
  orderBy = "ASC";
  isTotalData = true;
  hubId = 0;
  transportType = "";
  clientParentId = 0;

  constructor(fields?: Partial<RequestReadyToCargoList>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
export class ScanReadyToCargoRequest implements ScanReadyToCargoInterface {
  bagOrSttNo = new BagOrSttNumber("", "");

  constructor(fields?: Partial<ScanReadyToCargoRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify(
      ConvertObjectCamelToSnakeCase({
        bagOrStt: this.bagOrSttNo
      })
    );
  }
}

export class ResponseScanReadyToCargo {
  isAllowUpdateStatus = false;
  errorMessage = "";
  sttNo = "";
  bagNo = "";
  destinationCity = "";
  bagOrStt = [] as BagOrSttReadyToCargo[];

  constructor(fields?: Partial<ResponseScanReadyToCargo>) {
    Object.assign(this, fields);
  }
}

export class BagOrSttReadyToCargo {
  bagNo = "";
  bagVendorNo = "";
  sttId = 0;
  sttNo = "";
  sttTotalPiece = 0;
  grossWeight = 0;
  volumeWeight = 0;
  productType = "";
  commodityName = "";
  destinationCity = "";
  isPaid = false;
  action = "";
  sttNoRefExternal?= "";
  sttDetails = [] as BagOrSttReadyToCargo[];
  sttAssesmentStatus = "";
  constructor(fields?: Partial<BagOrSttReadyToCargo>) {
    Object.assign(this, fields);
  }
}

export class UpdateReadyToCargoRequest implements UpdateReadyToCargoInterface {
  bagOrSttNo = [new BagOrSttNumber("", "")];
  hubId = 0;
  hubName = "";
  hubOriginCity = "";
  hubDistrictCode = "";

  constructor(fields?: Partial<UpdateReadyToCargoRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify(
      ConvertObjectCamelToSnakeCase({
        bagOrStt: this.bagOrSttNo,
        hubId: this.hubId,
        hubName: this.hubName,
        hubOriginCity: this.hubOriginCity,
        hubDistrictCode: this.hubDistrictCode
      })
    );
  }
}

export class ResponseUpdateReadyToCargo {
  totalSttSuccess = 0;
  totalSttFailed = 0;
  listSttFailed = [] as {
    sttNo: "";
    sttElexysNo: "";
    error: "";
  }[];

  constructor(fields?: Partial<ResponseUpdateReadyToCargo>) {
    Object.assign(this, fields);
  }
}
