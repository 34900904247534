import { PRICING_COMMODITY } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const DetailCommodity = () =>
  import(
    /* webpackChunkName: "Commodity" */ "@/app/ui/views/pricing/commodity/component/detail.vue"
  );
const EditCommodity = () =>
  import(
    /* webpackChunkName: "Commodity" */ "@/app/ui/views/pricing/commodity/component/edit.vue"
  );

export default [
  {
    path: "/master-data/commodity",
    component: Layout,
    name: "detail-commodity",
    meta: {
      icon: "commodity",
      title: "Komoditas | Detail",
      children: false,
      hidden: true,
      parent: "Komoditas",
      permission: PRICING_COMMODITY.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-commodity",
        component: DetailCommodity,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/master-data/commodity",
    component: Layout,
    name: "edit-commodity",
    meta: {
      icon: "commodity",
      children: false,
      hidden: true,
      parent: "Komoditas",
      permission: PRICING_COMMODITY.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-commodity",
        component: EditCommodity,
        meta: {
          header: true,
          title: "Komoditas | Ubah",
          name: "Ubah",
          before: "/master-data/commodity/:id"
        }
      }
    ]
  }
];
