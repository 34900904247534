/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionShortland = {
    shortland_enable: new Rox.Flag(false)
};

export function initFlagsPermissionShortland() {
    // register flags with namespaces for better grouping
    Rox.register("featureShortland", FlagsPermissionShortland);
}
