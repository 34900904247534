import { FlagsPermissionPaidUnpaid } from "@/feature-flags/flags-paid-unpaid";
const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const STTPaidUnpaidList = () =>
  import(
    /* webpackChunkName: "STTPaidUnpaid" */ `@/app/ui/views/shipment/stt-paid-unpaid/index.vue`
  );

export default [
  {
    path: "/stt-paid-unpaid",
    component: Layout,
    redirect: "/stt-paid-unpaid",
    name: "stt-paid-unpaid",
    meta: {
      header: true,
      icon: "stt-paid-unpaid",
      title: "STT Paid/Unpaid",
      name: "STT Paid/Unpaid",
      permission: FlagsPermissionPaidUnpaid.dashboard_stt_payment_status_enable
    },
    children: [
      {
        path: "/stt-paid-unpaid",
        name: "stt-paid-unpaid",
        component: STTPaidUnpaidList,
        meta: {
          header: true
        },
        permission: FlagsPermissionPaidUnpaid.dashboard_stt_payment_status_view
      }
    ]
  }
];
