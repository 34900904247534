import { MainAppController } from "../MainAppController";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";
import {
    INTERNET_ERROR,
    CLIENT_ERROR,
    SPECIAL_ERROR,
} from "@/app/infrastructures/misc/Constants";
import { AccountController } from "../AccountController";

interface Error {
    errorFor: string;
    errorSpecs: any;
    errorSystem: any;
}

interface Funcs {
    destination: Function,
    embargo: Function,
    guard: Function
}

interface ParamsForm {
    product: string;
    originCity: string;
    destinationCity: string;
    originDistrict: string;
    destinationDistrict: string;
}

const accountIsNonForeign = !AccountController.accountData.accountIsForeign;

const errorMessage: any = {
    "book": accountIsNonForeign ? "Booking Gagal !" : "Booking Failed !",
    "estimation": "Cek Estimasi Harga Gagal !"
}

const errorEntitas = (title: string, message: string, type: string, onCloseError?: Function) => {
    MainAppController.showErrorMessage(
        new ErrorMessageEntities({
          title: title,
          message: message,
          type: type,
          onClose: onCloseError
        })
    );
}

const setFuncs = (
    setErrorDestination: Function, 
    setErrorEmbargo: Function, 
    setGuardBookingSubmit: Function,
    checkErrorSpesification: any
) => {
    setErrorDestination(checkErrorSpesification?.errorDestination || false);
    setErrorEmbargo(checkErrorSpesification?.isErrorEmbargo || false);
    setGuardBookingSubmit(false)
}

const convertErrorToEnglish = (err: any) => {
    return accountIsNonForeign ? err.id : err.en
}

export const responseFailedBookingManual = (
    error: Error,
    funcs: Funcs,
    onCloseError?: Function
) => {
    const checkErrorSpesification = error.errorSpecs[error.errorSystem.response.data.message.en];
    const title = checkErrorSpesification ? checkErrorSpesification.headerError : errorMessage[error.errorFor];
    const message = checkErrorSpesification ? checkErrorSpesification.errorMsg : convertErrorToEnglish(error.errorSystem.response.data.message);
    const type = checkErrorSpesification ? SPECIAL_ERROR : CLIENT_ERROR;
    
    errorEntitas(title, message, type, onCloseError);
    setFuncs(funcs.destination, funcs.embargo, funcs.guard, checkErrorSpesification);
}

export const isQrisPayment = (enablePaymentQris: boolean, isNotCash: boolean) => {
    return enablePaymentQris && isNotCash;
}

export const setErrorInternet = (bookAgain: Function, payload: any) => {
    MainAppController.showErrorMessage(
        new ErrorMessageEntities({
          type: INTERNET_ERROR,
          onTryAgain: () => bookAgain(payload)
        })
    );
}

export const SLAFullfilled = (params: ParamsForm) => {
    return params.product &&
        params.originCity &&
        params.destinationCity &&
        params.originDistrict &&
        params.destinationDistrict;
}
