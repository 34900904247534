import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import { Pagination, PaginationV2 } from "@/domain/entities/Pagination";
import {
  StiSCEntities,
  StiSCData,
  SttPiecesStiScDetail,
  PieceDetail,
  ValidationScanSTTData,
  StiScManifestData,
  StiScSTTManifestDetail,
  StiScSTTPieceManifestDetail,
  ResponseSTISCSession,
  STISCSession,
  StiScProcessData,
  ResponseSTISCCreateSession,
  ResponseFinishSessionSTISC
} from "@/domain/entities/StiSc";

export class StiSCMapper {
  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: data.data,
      stiScId: data.data.sti_sc_id
    });
  }

  public convertStiSCDataFromApi(res: AxiosResponse<any>): StiSCEntities {
    const { data } = res;

    const stiSC: StiSCData[] = [];
    if (data.data.length === 0) {
      return new StiSCEntities(
        new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
        []
      );
    } else {
      data.data.map((item: any) => {
        stiSC.push(
          new StiSCData(
            item.sti_sc_id,
            item.sti_sc_partner_id,
            item.sti_sc_partner_code,
            item.sti_sc_partner_name,
            item.sti_sc_total_stt,
            item.sti_sc_total_gross_weight,
            item.sti_sc_total_volume_weight,
            item.sti_sc_total_pieces,
            item.sti_sc_created_at,
            item.sti_sc_created_by,
            item.sti_sc_created_name,
            item.sti_sc_updated_at
          )
        );
      });
    }
    return new StiSCEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      stiSC
    );
  }

  public convertSttPiecesStiScDetailFromApi(
    res: AxiosResponse<any>
  ): SttPiecesStiScDetail {
    const {
      data: { data }
    } = res;
    if (!data.is_allow_update_status || data.stt === null) {
      return new SttPiecesStiScDetail(
        data.is_allow_update_status,
        data.error_message,
        "",
        "",
        [],
        "",
        0,
        0,
        0,
        "",
        ""
      );
    } else {
      return new SttPiecesStiScDetail(
        data.is_allow_update_status,
        data.error_message,
        data.stt[0].stt_no,
        data.stt[0].stt_elexys_no === undefined
          ? "-"
          : data.stt[0].stt_elexys_no,
        data.stt[0].piece.map((item: any) => {
          return new PieceDetail(
            data.stt[0].stt_no,
            data.stt[0].stt_elexys_no === undefined
              ? "-"
              : data.stt[0].stt_elexys_no,
            `${data.stt[0].stt_no}-${item.stt_piece_no}`,
            item.stt_piece_no,
            `${data.stt[0].stt_destination_city_id}-${data.stt[0].stt_destination_city_name}`,
            data.stt[0].stt_product_type,
            data.stt[0].stt_wood_packing === "Yes" ? "Ya" : "Tidak",
            data.stt[0].stt_commodity_name,
            "",
            data.stt[0].stt_cod_amount,
            item.stt_piece_gross_weight,
            item.stt_piece_volume_weight,
            data.stt[0].booked_at,
            data.stt[0].stt_last_status_id,
            data.stt[0].pos_name
          );
        }),
        data.stt[0].stt_product_type,
        data.stt[0].stt_total_piece,
        data.stt[0].stt_gross_weight,
        data.stt[0].stt_volume_weight,
        data.stt[0].stt_origin_city_id,
        data.stt[0].stt_destination_city_id,
        data.is_paid
      );
    }
  }

  public convertStiScManifestDataFromApi(
    res: AxiosResponse<any>
  ): StiScManifestData {
    const {
      data: { data }
    } = res;
    if (data.piece === null) {
      return new StiScManifestData(0, "", "", "", 0, 0, 0, 0, "", []);
    } else {
      return new StiScManifestData(
        data.sti_partner_id,
        data.sti_partner_name,
        data.sti_city_name,
        data.sti_city_code,
        data.sti_total_pieces,
        data.sti_total_stt,
        data.sti_total_gross_weight,
        data.sti_total_volume_weight,
        data.sti_created_at,
        data.stt.map(
          (item: any) =>
            new StiScSTTManifestDetail(
              item.stt_id,
              item.stt_no,
              item.stt_elexys_no === undefined ? "-" : item.stt_elexys_no,
              item.stt_product_type,
              item.stt_total_piece,
              item.stt_destination_city_id,
              item.stt_commodity_code,
              item.stt_gross_weight,
              item.stt_volume_weight,
              item.stt_chargeable_weight,
              item.stt_cod_amount,
              item.stt_last_status_id,
              item.piece.map(
                (item: any) =>
                  new StiScSTTPieceManifestDetail(
                    item.stt_piece_id,
                    item.stt_piece_gross_weight,
                    item.stt_piece_volume_weight,
                    item.stt_piece_no,
                    item.stt_piece_last_status_id
                  )
              ),
              item.stt_origin_city_id,
              item.stt_origin_city_name,
              item.stt_destination_city_name
            )
        )
      );
    }
  }

  public convertValidationScanSTTApi(
    res: AxiosResponse<any>
  ): ValidationScanSTTData {
    const { data } = res;
    return new ValidationScanSTTData(
      data.data.is_allow_update_status,
      data.data.error_message
    );
  }

  public convertSessionSTISCFromApi(res: AxiosResponse<any>): ResponseSTISCSession {
    const { data } = res;
    return new ResponseSTISCSession({
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.limit,
        totalData: data.meta.total_records
      }),
      data: new STISCSession({
        manifestId: data.data.manifest_id,
        totalSTT: data.data.total_stt,
        totalPieces: data.data.total_pieces,
        totalVolumeWeight: data.data.total_volume_weight,
        totalGrossWeight: data.data.total_gross_weight,
        stiSCList: data.data.sti_sc_list?.length ?
          data.data.sti_sc_list.map((item: any) =>
            new StiScProcessData({
              sttNumber: item.stt_no,
              product: item.product_type,
              totalPieces: item.total_pieces,
              grossWeight: item.total_gross_weight,
              volumeWeight: item.total_volume_weight,
              destination: item.destination_city_code,
              status: item.status
            })
          ) : []
      })
    })
  }

  public convertCreateSessionSTISCFromApi(res: AxiosResponse<any>): ResponseSTISCCreateSession {
    const { data } = res;
    return new ResponseSTISCCreateSession({
        manifestId: data.data.manifest_id,
      })
    }

  public convertStiScProcessDataFromApi(res: AxiosResponse<any>): StiScProcessData {
    const { data } = res;
    return data.data?.stt_no ? new StiScProcessData({
      message: data.message,
      sttNumber: data.data.stt_no,
      product: data.data.product_type,
      totalPieces: data.data.total_pieces,
      grossWeight: data.data.total_gross_weight,
      volumeWeight: data.data.total_volume_weight,
      destination: data.data.destination_city_code,
      status: data.data.status,
    }) : new StiScProcessData()
  }

  public convertStiScFinishDataFromApi(res: AxiosResponse<any>): ResponseFinishSessionSTISC {
    const { data } = res;
    return new ResponseFinishSessionSTISC({
      stiScId: data.data.sti_sc_id
    });
  }
}
