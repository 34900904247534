import { Pagination } from "./Pagination";

export class MessagingServiceConfigurationList {
  pagination: Pagination;
  data: MessagingServiceConfigurationListData[];
  constructor(
    pagination: Pagination,
    data: MessagingServiceConfigurationListData[]
  ) {
    this.data = data;
    this.pagination = pagination;
  }
}

export class MessagingServiceConfigurationListData {
  msgConfId = 0;
  msgConfCode = "";
  msgConfName = "";
  msgConfType = "";
  msgConfSttLastStatus = "";
  msgConfPacketType = "";
  msgConfTarget = "";
  msgConfStatus = "";
  msgConfBookedFor = "";
  msgConfProduct = "";
  msgConfOriginCity = "";
  msgConfOriginCityExclude = "";
  msgConfDestinationCity = "";
  msgConfDestinationCityExclude = "";
  msgConfPosId = 0;
  msgConfPosName = "";
  msgConfPosIdExclude = 0;
  msgConfPosNameExclude = "";
  msgConfClientId = 0;
  msgConfClientName = "";
  msgConfClientIdExclude = 0;
  msgConfClientNameExclude = "";
  msgConfClientParentId = 0;
  msgConfClientParentName = "";
  msgConfClientParentIdExclude = 0;
  msgConfClientParentNameExclude = "";
  msgConfSchedulePeriodeStart = "";
  msgConfSchedulePeriodeEnd = "";
  msgConfMessageText = "";
  msgConfCreatedAt = "";
  msgConfCreatedBy = "";
  msgConfUpdatedAt = "";
  msgConfUpdatedBy = "";
  msgConfIntracity = false;
  msgConfIntercity = false;
  msgConfSla = "";
  constructor(fields?: Partial<MessagingServiceConfigurationListData>) {
    Object.assign(this, fields);
  }
}

export class MessagingServiceConfigurationForm {
  configId: any = "";
  messageTitle = "LIONPARCEL";
  messageType = "";
  eventStatus = "";
  packageType = "";
  target: Array<string> = [];
  appliedTo: Array<string> = [];
  product: Array<string> = [];
  originCities: Array<any> = [];
  originCitiesExclude: Array<any> = [];
  destinationCities: Array<any> = [];
  destinationCitiesExclude: Array<any> = [];
  posPartners: Array<any> = [];
  posPartnersExclude: Array<any> = [];
  clientBranches: Array<any> = [];
  clientBranchesExclude: Array<any> = [];
  clientParent: Array<any> = [];
  clientParentExclude: Array<any> = [];
  startDate = new Date(new Date().setDate(new Date().getDate() + 1));
  endDate = new Date(new Date().setDate(new Date().getDate() + 2));
  messageDetail = "";
  templateName = "";
  msgConfIntracity = true;
  msgConfIntercity = true;
  msgConfOnSla = true;
  msgConfOverSla = true;
  constructor(fields?: Partial<MessagingServiceConfigurationForm>) {
    Object.assign(this, fields);
  }
}

export class TagValue {
  tag = "";
  value = "";
  keyStatus = "";
  keyType = "";
  content = "";

  constructor(fields?: Partial<TagValue>) {
    Object.assign(this, fields);
  }
}

export class MessageDictionary {
  tagDefinition: TagValue[] = [];
  tagFormat: TagValue[] = [];

  constructor(fields?: Partial<MessageDictionary>) {
    Object.assign(this, fields);
  }
}

export class TemplateWhatsappPremium {
  templateId = 0;
  templateName = "";
  templateContent = "";
  templateStatus = "";

  constructor(fields?: Partial<TemplateWhatsappPremium>) {
    Object.assign(this, fields);
  }
}
