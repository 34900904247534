import Axios, { AxiosInstance, Method, AxiosResponse } from "axios";
import qs from "qs";
import { injectable } from "tsyringe";
import { ApiServiceInterface } from "@/data/infrastructures/ApiServiceInterface";
import { getTokenMiddleware, removeAuthCredentialMiddleware } from "../misc/Cookies";
import baseUrlMiddleware from "@/app/infrastructures/misc/common-library/BaseUrlMiddleware";
import tokenizationMiddleware from "@/app/infrastructures/misc/common-library/TokenizationMiddleware";


@injectable()
export default class ApiServiceMiddleware implements ApiServiceInterface {
  public client: AxiosInstance;
  public BASE_URL_MIDDLEWARE = getTokenMiddleware() 
    ? process.env.VUE_APP_MIDDLEWARE_API_URL
    : process.env.VUE_APP_MIDDLEWARE_SHIPMENT_API_URL;
  public BEARER_TOKEN_MIDDLEWARE = tokenizationMiddleware()

  constructor() {
    this.client = Axios.create({
      baseURL: baseUrlMiddleware(),
      timeout: 50000
    });
    if (process.env.NODE_ENV || this.BASE_URL_MIDDLEWARE) {
      this.client.interceptors.response.use(
        resp => {
          return resp;
        },
        error => {
          if (error.response.status === 401) {
            removeAuthCredentialMiddleware();
          }
          return Promise.reject(error);
        }
      );
    }
  }

  public async invoke(
    method: Method = "get",
    url = "",
    params: any = {},
    payload: any = null,
    headers: Map<string, string> = new Map()
  ): Promise<AxiosResponse<any>> {
    // content-type application/json
    this.client.defaults.headers["Content-Type"] = "application/json";
    // set custom header if any
    headers.forEach((value: string, key: string) => {
      this.client.defaults.headers.common[key] = value;
    });

    // set auth
    this.client.defaults.headers.Authorization = `Bearer ${this.BEARER_TOKEN_MIDDLEWARE}`;
    return await this.client.request({
      url,
      params,
      paramsSerializer: par => qs.stringify(par, { encode: false }),
      data: payload ? payload.toJSON() : null,
      method
    });
  }
}
