/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionHandover = {
    handover_enable: new Rox.Flag(false),
    handover_create: new Rox.Flag(false),
    handover_detail_view: new Rox.Flag(false),
};

export function initFlagsPermissionHandover() {
    // register flags with namespaces for better grouping
    Rox.register("featureHandover", FlagsPermissionHandover);
}
