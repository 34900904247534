import { STIMapper } from "@/data/persistences/mappers/STIMapper";
import { STIRepositoryInterface } from "@/data/persistences/repositories/contracts/STIRepositoryInterface";
import {
  DetailSttPiecesSTI,
  STIEntities,
  STIListBookedEntities,
  STIReverseScanDetail,
  STIReverseScanGenerate,
  STIReverseScanTemporary,
  STISTTDueEntities,
  STISTTDueSummary,
  StiManifestData,
} from "@/domain/entities/STI";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { StiRequestInterface } from "@/data/payload/contracts/StiApiRequest";
import {
  GenerateStiRequest,
  STIReverseScanTemporaryListRequest,
  GenerateSTIReverseScanRequest,
  STIReverseScanManifestRequest,
  STISTTDueSummaryListRequest,
  STISTTDueListRequest,
} from "@/data/payload/api/StiApiRequest";
import { downloadFile } from "../../misc/Utils";
import { StiListRequest } from "@/data/payload/api/StiApiRequest";

export class STIApiRepository implements STIRepositoryInterface {
  private service: ApiService;
  private mapper: STIMapper;
  private endpoints: Endpoints;

  constructor(service: ApiService, mapper: STIMapper, endpoints: Endpoints) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async stiProcessReverse(
    payload: STIReverseScanManifestRequest
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.postManifestSTIReverseScan(),
      undefined,
      payload
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async generateSti(
    payload: StiRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.generateSti(),
      undefined,
      payload as GenerateStiRequest
    );
    return this.mapper.convertResponsePayload(resp);
  }

  public async getDetailSttPieces(
    sttNumber: string
  ): Promise<DetailSttPiecesSTI> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailSttPiecesSTI(sttNumber),
      {}
    );
    return this.mapper.convertSttPiecesStiDetailFromApi(resp);
  }

  public async getDetailSttPiecesLilo(
    bagNo: string
  ): Promise<DetailSttPiecesSTI> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailSttPiecesSTILilo(bagNo),
      {}
    );
    return this.mapper.convertSttPiecesStiDetailFromApi(resp);
  }

  public async getListSTI(
    params: StiListRequest,
    version = "v1"
  ): Promise<STIEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSTIList(params, version),
      {}
    );
    return this.mapper.convertSTIDataFromApi(resp);
  }

  public async validationScanSTTPiece(
    sttPiece: string
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.validationScanSTISTTPiece(sttPiece),
      {}
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async getStiManifestData(id: number, version = "v1"): Promise<StiManifestData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getStiManifestData(id, version),
      {}
    );
    return this.mapper.convertStiManifestDataFromApi(resp);
  }

  public async downloadSti(
    id: number,
    version = "v1",
    noSti = false
  ): Promise<any> {
    return downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.downloadManifest(
        `sti/excel/${id}?need_to_sti=${noSti}`,
        version
      )}`,
      `STI_Manifest_${id}`,
      ".xlsx",
      true
    );
  }

  async getListSTIReverseScanTemporary(
    params: STIReverseScanTemporaryListRequest
  ): Promise<STIReverseScanTemporary[]> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getSTIReverseScanTemporaryList(params),
      undefined
    );

    return this.mapper.convertStiReverseScanTemporaryListFromApi(resp);
  }

  async generateSTIReverseScan(
    payload: GenerateSTIReverseScanRequest
  ): Promise<STIReverseScanGenerate> {
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.generateSTIReverseScan(),
      undefined,
      payload
    );

    return this.mapper.convertStiReverseScanGenerateFromApi(resp);
  }

  async getDetailSTIReverseScan(id: number): Promise<STIReverseScanDetail> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getSTIReverseScanDetail(id)
    );

    return this.mapper.convertStiReverseScanDetailFromApi(resp);
  }

  async getSTISTTDueSummary(
    params: STISTTDueSummaryListRequest
  ): Promise<STISTTDueSummary> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getSTISTTDueSummary(params)
    );

    return this.mapper.convertStiSTTDueSummaryFromApi(resp);
  }

  async getBookedList(): Promise<STIListBookedEntities> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getSTIBookedList()
    );

    return this.mapper.convertListBookedFromApi(resp);
  }

  async getSTTDueList(
    params: STISTTDueListRequest
  ): Promise<STISTTDueEntities> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getSTISTTDueList(params)
    );

    return this.mapper.convertSTTDueListFromApi(resp);
  }

  public async downloadSttDue(params: STISTTDueListRequest): Promise<any> {
    return downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.downloadSTISTTDue(params)}`,
      `STT belum di STI`,
      ".xlsx",
      true
    );
  }
}
