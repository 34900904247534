import { IncomingOutgoingMapper } from "@/data/persistences/mappers/IncomingOutgoingMapper";
import { IncomingOutgoingRepositoryInterface } from "@/data/persistences/repositories/contracts/IncomingOutgoingRepositoryInterface";
import { RequestDownloadIncomingOutgoing } from "@/domain/entities/IncomingOutgoing";
import { Endpoints } from "../../misc/Endpoints";
import { downloadFile } from "../../misc/Utils";
import ApiService from "../../services/ApiService";

export class IncomingOutgoingApiRepository
  implements IncomingOutgoingRepositoryInterface {
  private service: ApiService;
  private mapper: IncomingOutgoingMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: IncomingOutgoingMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getDownload(
    params: RequestDownloadIncomingOutgoing
  ): Promise<any> {
    return downloadFile(
      `${Endpoints.baseUrl}/${this.endpoints.getDownloadIncomingOutgoing(
        params
      )}`,
      `${params.type}_${params.startDate}-${params.endDate}`,
      ".xlsx",
      true
    );
  }
}
