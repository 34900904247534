import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { PayloadCreateBagging, PayloadUpdateReadyToCargo } from "@/domain/entities/Bagging";
import { QueryParamsEntities } from "@/domain/entities/MainApp";
import {
  BaggingToReadyToCargoRequestInterface,
  ChangeBaggingRequestInterface,
  UpdateBaggingInterface
} from "../contracts/BaggingRequest";
import { getHubData } from "@/app/infrastructures/misc/Cookies";

/* eslint-disable @typescript-eslint/camelcase */
export class CreateApiRequest implements ChangeBaggingRequestInterface {
  payload: PayloadCreateBagging = new PayloadCreateBagging();
  constructor(fields?: Partial<PayloadCreateBagging>) {
    Object.assign(this.payload, fields);
  }
  public toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this.payload));
  }
}

export class UpdateBaggingRequest implements UpdateBaggingInterface {
  private bagId: number;
  private customGrossWeight: number;
  private customLength: number;
  private customWidth: number;
  private customHeight: number;
  private bagSttNo: Array<any>;
  private partnerId?: number;
  constructor(
    bagId: number,
    customGrossWeight: number,
    customLength: number,
    customWidth: number,
    customHeight: number,
    bagSttNo: Array<any>,
    partnerId?: number
  ) {
    this.bagId = bagId;
    this.customGrossWeight = customGrossWeight;
    this.customLength = customLength;
    this.customWidth = customWidth;
    this.partnerId = partnerId;
    (this.customHeight = customHeight), (this.bagSttNo = bagSttNo);
  }
  public toJSON(): string {
    return JSON.stringify({
      bag_id: this.bagId,
      custom_gross_weight: this.customGrossWeight,
      custom_length: this.customLength,
      custom_width: this.customWidth,
      custom_height: this.customHeight,
      bag_stt_no: this.bagSttNo,
      partner_id: this.partnerId,
      hub_id: getHubData()?.hubId,
      hub_name: getHubData()?.hubName,
      hub_origin_city: getHubData()?.originCity,
      hub_district_code: getHubData()?.hubDistrictCode
    });
  }
}

export class BaggingListRequest {
  page = 1;
  limit = 10;
  startDate = "";
  endDate = "";
  search = "";
  partnerId = 0;
  hubId = 0;
  constructor(fields?: Partial<BaggingListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class BaggingToReadyToCargoRequest implements BaggingToReadyToCargoRequestInterface {
  payload : PayloadUpdateReadyToCargo = new PayloadUpdateReadyToCargo();
  constructor(fields?: Partial<PayloadUpdateReadyToCargo>) {
    Object.assign(this.payload, fields);
  }
  public toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this.payload));
  }
}
