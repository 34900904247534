import { FlagsPermissionCodBalance } from "@/feature-flags/flags-permission-cod-balance";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const CodIncome = () =>
  import(
    /* webpackChunkName: "CodIncome" */ "@/app/ui/views/cod-income/index.vue"
  );

export default [
  {
    path: "/cod-income",
    component: Layout,
    name: "cod-income",
    redirect: "/cod-income",
    meta: {
      icon: "empty",
      children: false,
      title: "Penghasilan COD",
      name: "Penghasilan COD",
      hidden: true,
      isFreeze: true,
      permission: FlagsPermissionCodBalance.permission_cod_balance_enable
    },
    children: [
      {
        path: "/cod-income",
        name: "cod-income",
        component: CodIncome,
        meta: {
          header: true
        }
      }
    ]
  }
];
