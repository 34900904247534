import { AxiosResponse } from "axios";
import { PaginationV2 } from "@/domain/entities/Pagination";
import { ListPickup, ListPickupList } from "@/domain/entities/ListPickup";
import {
  CreatePickupSuccessResponse,
  ListPickupDetail,
  ListStt,
} from "@/domain/entities/ListPickupDetail";
import { PickupCorporateAddress } from "@/domain/entities/PickupSchedule";

export class ListPickupMapper {
  public convertListDataFromApi(res: AxiosResponse): ListPickupList {
    const { data } = res;

    return new ListPickupList({
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.per_page,
        totalData: data.meta.total_records,
      }),
      data: data.data?.length
        ? data.data.map(
            (key: any) =>
              new ListPickup({
                listPickupShipmentId: key.shipment_id,
                listPickupClientRef: key.client_ref,
                listPickupPickupSchedule: key.pickup_schedule,
                listPickupTransportType: key.transport_type,
                listPickupEstTotalKoli: key.estimate_total_koli,
                listPickupActualTotalKoli: key.actual_total_koli,
                listPickupPickupType: key.pickup_type,
                listPickupServiceCodes: key.service_code,
                listPickupEstTotalShipment: key.estimate_total_shipment,
                listPickupLatestStatus: key.latest_status,
              })
          )
        : [],
    });
  }

  public convertDetailDataFromApi(res: AxiosResponse<any>): ListPickupDetail {
    const {
      data: { data },
    } = res;

    const listStt: ListStt[] = [];
    if (data.list_stt !== null && data.list_stt.length !== 0) {
      data.list_stt.map((item: any) => {
        listStt.push(
          new ListStt({
            bookingDate: item.booking_date,
            grossWeight: item.gross_weight,
            serviceCode: item.service_code,
            sttNo: item.stt_no,
            totalKoli: item.total_koli,
          })
        );
      });
    }

    return new ListPickupDetail({
      the3LcPickup: data["3lc_pickup"],
      actualTotalKoli: data.actual_total_koli,
      actualTotalShipment: data.actual_total_shipment,
      actualTotalTonase: data.actual_total_tonase,
      clientId: data.client_id,
      clientParentId: data.client_parent_id,
      clientRef: data.client_ref,
      courierName: data.courier_name,
      courierNote: data.courier_note,
      courierPhone: data.courier_phone,
      cancelNote: data.cancel_note,
      createdAt: data.created_at,
      createdBy: data.created_by,
      dropOffProof: data.drop_off_proof,
      estimateTotalKoli: data.estimate_total_koli,
      estimateTotalShipment: data.estimate_total_shipment,
      estimateTotalTonase: data.estimate_total_tonase,
      geoloc: data.geoloc,
      latestStatus: data.latest_status,
      listStt: listStt,
      origin: data.origin,
      pickupAddress: data.pickup_address,
      pickupDate: data.pickup_date,
      pickupProof: data.pickup_proof,
      pickupTime: data.pickup_time,
      pickupType: data.pickup_type,
      plateNo: data.plate_no,
      serviceCode: data.service_code,
      shipmentId: data.shipment_id,
      transportType: data.transport_type,
      updatedAt: data.updated_at,
      updatedBy: data.updated_by,
      timezone: data.timezone,
      pickupCorporateAddress: new PickupCorporateAddress({
        pcaId: data.pickup_corporate_address?.pca_id ?? 0,
        pcaName: data.pickup_corporate_address?.pca_name ?? "",
        pcaLatlon: data.pickup_corporate_address?.pca_latlon ?? "",
        pcaIsDefault: data.pickup_corporate_address?.pca_is_default ?? false,
        pcaPhoneNumber: data.pickup_corporate_address?.pca_phone_number ?? "",
        pcaAddress: data.pickup_corporate_address?.pca_address ?? "",
        pcaPicName: data.pickup_corporate_address?.pca_pic_name ?? "",
        pcaPostalCode: data.pickup_corporate_address?.pca_postal_code ?? "",
        pcaDistrictId: data.pickup_corporate_address?.pca_district_id ?? 0,
        pcaDistrictName:
          data.pickup_corporate_address?.pca_district?.name ?? "",
      }),
    });
  }

  public convertResponseSuccessCreatePickupFromApi(
    res: AxiosResponse<any>
  ): CreatePickupSuccessResponse {
    const {
      data: { data },
    } = res;

    return new CreatePickupSuccessResponse({
      shipmentId: data.shipment_id,
    });
  }
}
