import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import { Pagination } from "@/domain/entities/Pagination";
import {
  PickupManifestEntities,
  PickupManifestData,
  Drivers,
  STTPiecesDetail,
  PrintDataList,
  DetailSTT,
  SttListChecklistEntities,
  SttListChecklistData
} from "@/domain/entities/PickupManifest";

export class PickupManifestMapper {
  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      pickupManifestId: data.data.pickup_manifest_id,
      data: data.data
    });
  }

  public convertDriverChangeDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertPickupManifestDataFromApi(
    res: AxiosResponse<any>
  ): PickupManifestEntities {
    const { data } = res;

    const pickupManifest: PickupManifestData[] = [];
    if (data.data.length === 0) {
      return new PickupManifestEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        pickupManifest.push(
          new PickupManifestData(
            item.pickup_manifest_id,
            item.pickup_manifest_partner_name,
            item.pickup_manifest_driver_name,
            item.pickup_manifest_vehicle_police_number,
            item.pickup_manifest_total_stt,
            item.pickup_manifest_total_piece,
            item.pickup_manifest_total_gross_weight,
            item.pickup_manifest_total_volume_weight,
            item.pickup_manifest_destination_partner_name,
            item.pickup_manifest_destination_vendor_code,
            item.pickup_manifest_pickup_at,
            item.pickup_manifest_created_at,
            item.pickup_manifest_updated_at,
            item.pickup_manifest_driver_phone,
            item.pickup_manifest_created_name,
            item.pickup_manifest_code
          )
        );
      });
    }
    return new PickupManifestEntities(
      new Pagination(data.meta.page, data.meta.limit),
      pickupManifest
    );
  }

  public convertDriversFromApi(result: AxiosResponse): Drivers[] {
    try {
      const {
        data: { data }
      } = result;

      return data.map(
        (item: any) =>
          new Drivers(
            item.driver_id,
            item.driver_name,
            item.driver_phone_number,
            `${item.driver_name} (${item.driver_phone_number})`
          )
      );
    } catch (e) {
      return [];
    }
  }

  public convertSttPiecesDetailFromApi(res: AxiosResponse): STTPiecesDetail {
    const {
      data: { data }
    } = res;

    return new STTPiecesDetail(
      data.stt_no,
      data.stt_piece,
      `${data.destination_city_code} - ${data.destination_city_name}`,
      data.product_type,
      data.is_woodpacking ? "Yes" : "No",
      data.commodity,
      data.cod_amount,
      `${data.stt_piece_no}/${data.total_pieces}`,
      data.gross_weight,
      data.volume_weight,
      data.stt_piece_no
    );
  }

  public convertPrintDataListFromApi(result: AxiosResponse): PrintDataList {
    const {
      data: { data }
    } = result;

    return new PrintDataList(
      data.pickup_manifest_id,
      data.pickup_manifest_partner_id,
      data.pickup_manifest_partner_code,
      data.pickup_manifest_partner_name,
      data.pickup_manifest_origin_city_code,
      data.pickup_manifest_origin_city_name,
      data.pickup_manifest_driver_id,
      data.pickup_manifest_driver_name,
      data.pickup_manifest_vehicle_id,
      data.pickup_manifest_vehicle_police_number,
      data.pickup_manifest_total_stt,
      data.pickup_manifest_total_piece,
      data.pickup_manifest_total_gross_weight,
      data.pickup_manifest_total_volume_weight,
      data.pickup_manifest_destination_partner_id,
      data.pickup_manifest_destination_partner_code,
      data.pickup_manifest_destination_partner_name,
      data.pickup_manifest_vendor_code,
      data.pickup_manifest_product_type,
      data.pickup_manifest_pickup_at,
      data.pickup_manifest_created_by,
      data.pickup_manifest_created_name,
      data.pickup_manifest_created_at,
      data.stt,
      data.pickup_manifest_driver_phone,
      data.pickup_manifest_code
    );
  }

  public convertDetailSttFromApi(res: AxiosResponse): DetailSTT {
    const {
      data: { data }
    } = res;
    if (!data.is_allow_update_status || data.stt === null) {
      return new DetailSTT(
        "",
        false,
        data.error_message,
        [],
        "",
        0,
        0,
        0,
        "",
        "",
        "",
        "",
        false
      );
    }
    return new DetailSTT(
      data.stt.stt_no,
      data.is_allow_update_status,
      data.error_message,
      data.stt.piece.map(
        (item: any) =>
          new STTPiecesDetail(
            data.stt.stt_no,
            `${data.stt.stt_no}-${item.stt_piece_no}`,
            `${data.stt.stt_destination_city_id} - ${data.stt.stt_destination_city_name}`,
            data.stt.stt_product_type,
            data.stt.stt_wood_packing === "Yes" ? "Ya" : "Tidak",
            data.stt.stt_commodity_name,
            data.stt.stt_cod_amount,
            `${item.stt_piece_no}/${data.stt.piece.length}`,
            item.stt_piece_gross_weight,
            item.stt_piece_volume_weight,
            item.stt_piece_no
          )
      ),
      data.stt.stt_product_type,
      data.stt.stt_total_piece,
      data.stt.stt_gross_weight,
      data.stt.stt_volume_weight,
      data.stt.booked_at,
      data.stt.stt_origin_city_id,
      data.stt.stt_destination_city_id,
      data.stt.stt_elexys_no || "",
      data.is_paid
    );
  }

  public convertSttListChecklistFromApi(
    res: AxiosResponse<any>
  ): SttListChecklistEntities {
    const { data } = res;

    const sttListChecklist: SttListChecklistData[] = [];
    if (!data.data) {
      return new SttListChecklistEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        sttListChecklist.push(
          new SttListChecklistData(
            item.stt_no,
            item.elexys_stt_no,
            item.product_type,
            item.total_pieces,
            item.gross_weight,
            item.volume_weight,
            item.chargeable_weight,
            item.created_at,
            item.stt_no_ref_external
          )
        );
      });
    }
    return new SttListChecklistEntities(
      new Pagination(data.meta.page, data.meta.limit),
      sttListChecklist
    );
  }
}
