import { injectable } from "tsyringe";
import { TrackDeliveryApiRepository } from "@/app/infrastructures/repositories/api/TrackDeliveryApiRepository";
import { TrackDeliveryEntities } from "@/domain/entities/TrackDelivery";
import { TrackDeliveryListRequest } from "@/data/payload/api/TrackDeliveryApiRequest";

@injectable()
export class TrackDeliveryPresenter {
  private repository: TrackDeliveryApiRepository;

  constructor(repo: TrackDeliveryApiRepository) {
    this.repository = repo;
  }

  getDataTrackList(
    path: string,
    filter: TrackDeliveryListRequest
  ): Promise<TrackDeliveryEntities> {
    return this.repository.getDataTrackList(path, filter);
  }
}
