/* eslint-disable @typescript-eslint/camelcase */
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { UniversalPrintRepositoryInterface } from "@/data/persistences/repositories/contracts/UniversalPrintRepositoryInterface";
import { BookingMapper } from "@/data/persistences/mappers/BookingMapper";
import { PrintDataEntitas } from "@/domain/entities/Booking";

export class UniversalPrintApiRepository
  implements UniversalPrintRepositoryInterface {
  private service: ApiService;
  private mapper: BookingMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: BookingMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getDetailSttExternal(
    sttNo: string,
    clientId: string
  ): Promise<PrintDataEntitas> {
    const resp = await this.service.invokeWoAuth(
      "get",
      this.endpoints.getDetailSttExternal(sttNo, clientId),
      {}
    );
    return this.mapper.convertPrintDataDataFromApi(resp);
  }
}
