import moment from "moment";
const getDayName = (date: string) => {
  const comparisonDate = moment(date);
  const currentDate = moment();
  if (currentDate.isSame(comparisonDate, "day")) {
    return "Hari ini";
  }
  if (currentDate.subtract(1, "days").isSame(comparisonDate, "day")) {
    return "Kemarin";
  }

  return comparisonDate.format("DD-MM-YYYY");
};
export default getDayName;
