import { BalanceLimitApiRepository } from "@/app/infrastructures/repositories/api/BalanceLimitApiRepository";
import {
  BalanceEntities,
  BalanceDetailData
} from "@/domain/entities/BalanceLimit";
import { injectable } from "tsyringe";
import { EditBalanceRequestInterface } from "@/data/payload/contracts/BalanceLimitRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

@injectable()
export class BalanceLimitPresenter {
  private repository: BalanceLimitApiRepository;

  constructor(repo: BalanceLimitApiRepository) {
    this.repository = repo;
  }

  public getBalanceList(page: number, limit: number): Promise<BalanceEntities> {
    return this.repository.getBalanceList(page, limit);
  }

  public getBalanceDetail(id: number): Promise<BalanceDetailData> {
    return this.repository.getDetailBalance(id);
  }

  public editBalance(
    payload: EditBalanceRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editBalance(payload);
  }
}
