/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsSnKGamification = {
    s_and_k_gamification: new Rox.RoxString(""),
};

export function initFlagsSnKGamification() {
  // register flags with namespaces for better grouping
  Rox.register(
    "snkGamification",
    FlagsSnKGamification
  );
}
