/* eslint-disable @typescript-eslint/camelcase */
import { TariffApiRepository } from "@/app/infrastructures/repositories/api/TariffApiRepository";
import { CheckTariffRequest } from "@/data/payload/api/TariffApiRequest";
import { TariffEntities } from "@/domain/entities/Tariff";
import { injectable } from "tsyringe";

@injectable()
export class TariffPresenter {
  private repository: TariffApiRepository;

  constructor(repository: TariffApiRepository) {
    this.repository = repository;
  }

  public getListTariff(params: CheckTariffRequest, cache: boolean): Promise<TariffEntities> {
    return this.repository.getList(params, cache);
  }

  public downloadTariff(
    commodity_id: string | number,
    product_type: string
  ): Promise<any> {
    return this.repository.downloadTariff(commodity_id, product_type);
  }
}
