import { DependencyContainer } from "tsyringe";
import ApiService from "../services/ApiService";
import ApiServiceAlgo from "../services/ApiServiceAlgo";

export class RootModule {
  public static init(container: DependencyContainer) {
    container.register<ApiService>(ApiService, { useClass: ApiService });
    container.register<ApiServiceAlgo>(ApiServiceAlgo, {
      useClass: ApiServiceAlgo
    });
  }
}
