import {
  ForgotPasswordApiRequest,
  ResetPasswordApiRequest,
  LoginApiRequest,
  LogoutApiRequest,
  EditPasswordApiRequest,
  VerificationAccountRequest
} from "@/data/payload/api/AuthRequest";
import {
  ForgotPasswordRequestInterface,
  ResetPasswordRequestInterface,
  LoginRequestInterface,
  LogoutRequestInterface,
  EditPasswordRequestInterface,
  VerificationAccountRequestInterface
} from "@/data/payload/contracts/AuthRequest";
import ApiService from "@/app/infrastructures/services/ApiService";
import { AuthDataMapper } from "@/data/persistences/mappers/AuthDataMapper";
import { AuthRepositoryInterface } from "@/data/persistences/repositories/contracts/AuthRepositoryInterface";
import {
  AuthEntities,
  TokenVerificationAccountEntities
} from "@/domain/entities/Auth";
import { Endpoints } from "@/app/infrastructures/misc/Endpoints";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Utils";

export class AuthApiRepository implements AuthRepositoryInterface {
  private service: ApiService;
  private mapper: AuthDataMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: AuthDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async login(payload: LoginRequestInterface): Promise<AuthEntities> {
    const version = IS_SENDER_ACCOUNT ? "v1" : "v2";

    const resp = await this.service.invoke(
      "post",
      this.endpoints.login(version),
      undefined,
      payload as LoginApiRequest
    );
    return this.mapper.convertAuthDataFromApi(resp);
  }

  public async forgotPassword(
    payload: ForgotPasswordRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "post",
      this.endpoints.forgotPassword(),
      {},
      payload as ForgotPasswordApiRequest
    );
    return this.mapper.convertForgotPasswordDataFromApi(response);
  }

  public async resetPassword(
    payload: ResetPasswordRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "patch",
      this.endpoints.resetPassword(),
      {},
      payload as ResetPasswordApiRequest
    );
    return this.mapper.convertResetPasswordDataFromApi(response);
  }

  public async editPassword(
    payload: EditPasswordRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "patch",
      this.endpoints.editPassword(),
      {},
      payload as EditPasswordApiRequest
    );
    return this.mapper.convertEditPasswordDataFromApi(response);
  }

  public async logout(
    payload: LogoutRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "post",
      this.endpoints.logout(),
      {},
      payload as LogoutApiRequest
    );
    return this.mapper.convertResetPasswordDataFromApi(response);
  }

  public async verificationAccount(
    payload: VerificationAccountRequestInterface
  ): Promise<TokenVerificationAccountEntities> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.verificationAccount(),
      {},
      payload as VerificationAccountRequest
    );
    return this.mapper.convertVerificationAcountDataFromApi(resp);
  }
}
