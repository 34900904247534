const formatNumber = (
  value: number | string,
  params?: { prefix?: string; suffix?: string; reset?: boolean }
): string | number => {
  const parseString = (): number => {
    const removeChar = String(value).replace(/[^0-9$.,]/g, "");
    return Number(removeChar.replace(/\./g, "").replace(",", "."));
  };
  const parseNumber = (): string => {
    const formatter = new Intl.NumberFormat("id-ID", {
      minimumFractionDigits: String(value).includes(".") ? 2 : 0,
      maximumFractionDigits: 2
    });
    const formattedValue = formatter.format(Number(value));

    if (params?.prefix && params?.suffix) {
      return `${params.prefix} ${formattedValue} ${params.suffix}`;
    } else if (params?.prefix) {
      return `${params.prefix} ${formattedValue}`;
    } else if (params?.suffix) {
      return `${formattedValue} ${params.suffix}`;
    }
    return formattedValue;
  };

  if (typeof value === "number") {
    return parseNumber();
  } else if (typeof value === "string") {
    if (params?.reset) {
      return parseString();
    }
    return formatNumber(parseString(), params);
  }
  return value;
};

export default formatNumber;
