import { ROUTE_EMBARGO } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const DetailEmbargo = () =>
  import(
    /* webpackChunkName: "Embargo" */ "@/app/ui/views/route/embargo/component/detail.vue"
  );
const AddEmbargo = () =>
  import(
    /* webpackChunkName: "Embargo" */ "@/app/ui/views/route/embargo/component/add.vue"
  );
const EditEmbargo = () =>
  import(
    /* webpackChunkName: "Embargo" */ "@/app/ui/views/route/embargo/component/edit.vue"
  );

export default [
  {
    path: "/network/embargo",
    component: Layout,
    name: "embargo-add",
    meta: {
      icon: "route",
      children: false,
      hidden: true,
      parent: "Embargo",
      permission: ROUTE_EMBARGO.CREATE
    },
    children: [
      {
        path: "add",
        name: "embargo-add",
        component: AddEmbargo,
        meta: {
          header: true,
          title: "Embargo | Create",
          name: "Buat Embargo",
          before: "/network/embargo"
        }
      }
    ]
  },
  {
    path: "/network/embargo",
    component: Layout,
    name: "detail-embargo",
    meta: {
      hidden: true,
      parent: "Embargo",
      permission: ROUTE_EMBARGO.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-embargo",
        component: DetailEmbargo,
        meta: {
          header: true,
          title: "Embargo | Detail"
        }
      }
    ]
  },
  {
    path: "/network/embargo",
    component: Layout,
    name: "edit-embargo",
    meta: {
      icon: "route",
      children: false,
      hidden: true,
      parent: "Embargo",
      permission: ROUTE_EMBARGO.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-embargo",
        component: EditEmbargo,
        meta: {
          header: true,
          title: "Embargo | Edit",
          name: "Edit",
          before: "/network/embargo/:id"
        }
      }
    ]
  }
];
