import { UniversalApiRepository } from "@/app/infrastructures/repositories/api/UniversalApiRepository";
import { ValidateRebuttalDexRequest } from "@/data/payload/contracts/UniversalRequest";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { injectable } from "tsyringe";

@injectable()
export class UniversalPresenter {
  private repository: UniversalApiRepository;

  constructor(repository: UniversalApiRepository) {
    this.repository = repository;
  }

  public validateRebuttalDex(
    payload: ValidateRebuttalDexRequest
  ): Promise<ResponsePayloadV2> {
    return this.repository.validateRebuttalDex(payload);
  }
}
