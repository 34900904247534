import { QueryParamsEntities } from "@/domain/entities/MainApp";
import { ChangeStiDestRequestInterface } from "../contracts/StiDestRequest";
import { getHubData } from "@/app/infrastructures/misc/Cookies";
/* eslint-disable @typescript-eslint/camelcase */
export class GenerateStiDestRequest implements ChangeStiDestRequestInterface {
  private readonly sttNumbers: Array<any>;

  constructor(sttNumbers: Array<any>) {
    this.sttNumbers = sttNumbers;
  }

  public toJSON(): string {
    return JSON.stringify({
      stt_bag: this.sttNumbers.map((item: any) => {
        return {
          stt_no: item.sttNo,
          bag_code: item.bagNo,
          cargo_no: item.cargoNo,
          is_one_bag_scan: item.isOneBagScan
        };
      }),
      hub_id: getHubData()?.hubId,
      hub_name: getHubData()?.hubName,
      hub_origin_city: getHubData()?.originCity,
      hub_district_code: getHubData()?.hubDistrictCode
    });
  }
}

export class StiDestListRequest {
  page = 1;
  limit = 10;
  startDate = "";
  endDate = "";
  search = "";
  partnerId = 0;
  version = "v1";
  constructor(fields?: Partial<StiDestListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class StiDestReverseScanRequest implements ChangeStiDestRequestInterface {
    hubId = 0;
    bagOrSttNo = "";

  constructor(fields?: Partial<StiDestReverseScanRequest>) {
    Object.assign(this, fields);
  }
  public toJSON(): string {
    return JSON.stringify({
      bag_or_stt_no: this.bagOrSttNo,
      hub_id: getHubData()?.hubId || 0,
      hub_name: getHubData()?.hubName || "",
      hub_origin_city: getHubData()?.originCity || "",
      hub_district_code: getHubData()?.hubDistrictCode || ""
    });
  }

}

export class StiDestReverseScanManifestRequest {
  toJSON(): string {
    const payload = {
      hub_id: getHubData()?.hubId || 0
    };
    return JSON.stringify(payload);
  };

}
