import { TopupApprovalApiRepository } from "@/app/infrastructures/repositories/api/TopupApprovalApiRepository";
import { TopupApprovalRequestInterface } from "@/data/payload/contracts/TopupApprovalRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  ActorData,
  TopupApprovalData,
  TopupApprovalListEntities
} from "@/domain/entities/TopupApproval";
import { injectable } from "tsyringe";

@injectable()
export class TopupApprovalPresenter {
  private repository: TopupApprovalApiRepository;

  constructor(repository: TopupApprovalApiRepository) {
    this.repository = repository;
  }

  getListTopupApproval(
    page: number,
    limit: number,
    search: string,
    partnerName: string,
    status: string,
    bankName: string,
    verifiedBy: string
  ): Promise<TopupApprovalListEntities> {
    return this.repository.getListTopupApproval(
      page,
      limit,
      search,
      partnerName,
      status,
      bankName,
      verifiedBy
    );
  }

  public getActorVerifiedList(): Promise<ActorData[]> {
    return this.repository.getActorVerifiedList();
  }

  public getTopupApprovalDetail(id: number): Promise<TopupApprovalData> {
    return this.repository.getDetail(id);
  }

  approveTopupApproval(
    payload: TopupApprovalRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.approveTopupApproval(payload);
  }

  rejectTopupApproval(
    payload: TopupApprovalRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.rejectTopupApproval(payload);
  }
}
