import { injectable } from "tsyringe";
import { ClaimDetailData, ClaimEntities } from "@/domain/entities/Claim";
import { ClaimListRequest } from "@/data/payload/api/ClaimRequest";
import { ClaimApiRepository } from "@/app/infrastructures/repositories/api/ClaimApiRepository";
import { ClaimRequestInterface } from "@/data/payload/contracts/ClaimRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

@injectable()
export class ClaimPresenter {
  private repository: ClaimApiRepository;

  constructor(repo: ClaimApiRepository) {
    this.repository = repo;
  }

  public getDetailClaim(sttNo: string): Promise<ClaimDetailData> {
    return this.repository.getDetailClaim(sttNo);
  }
  public getDataClaimList(
    path: string,
    params: ClaimListRequest
  ): Promise<ClaimEntities> {
    return this.repository.getDataClaimList(path, params);
  }

  createClaim(params: ClaimRequestInterface): Promise<ResponsePayload> {
    return this.repository.createClaim(params);
  }
}
