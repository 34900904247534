import { QueryParamsEntities } from "./MainApp";
import { Pagination, PaginationV2 } from "./Pagination";

export class ResponsePayload {
  success = false;
  message = "";
  sttNumber = "";
  sttId = 0;
  pickupManifestId = 0;
  data: any = null;
  baggingId = "";
  stiScId = 0;
  isDiscount = false;
  totalDiscount = 0;
  totalAfterDiscount = 0;
  totalBeforeDiscount = 0;
  chargeableWeight = 0;
  constructor(fields?: Partial<ResponsePayload>) {
    Object.assign(this, fields);
  }
}

export class Entities {
  success: boolean;
  code: number;
  message: string;
  pagination: Pagination;
  data: Array<any>;
  constructor(
    success: boolean,
    code: number,
    message: string,
    pagination: Pagination,
    data: Array<any>
  ) {
    this.success = success;
    this.code = code;
    this.message = message;
    this.pagination = pagination;
    this.data = data;
  }
}

export class ResponseSttDetail {
  isAllowUpdateStatus: boolean;
  errorMessage: string;
  isPaid?: boolean;
  data: any;
  constructor(
    isAllowUpdateStatus: boolean,
    errorMessage: string,
    data: any,
    isPaid?: boolean
  ) {
    this.isAllowUpdateStatus = isAllowUpdateStatus;
    this.errorMessage = errorMessage;
    this.isPaid = isPaid;
    this.data = data;
  }
}

export class ResponseBagSttDetail {
  isAllowUpdateStatus: boolean;
  errorMessage: string;
  bagOrStt: any;

  constructor(
    isAllowUpdateStatus: boolean,
    errorMessage: string,
    bagOrStt: any
  ) {
    this.isAllowUpdateStatus = isAllowUpdateStatus;
    this.errorMessage = errorMessage;
    this.bagOrStt = bagOrStt;
  }
}

export class RequestList {
  cache = false;
  isTotalData = true;
  page = 1;
  limit = 20;
  sortBy = "";
  orderBy = "";

  constructor(fields?: Partial<RequestList>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class ResponsePayloadV2 {
  loading = false;
  success = false;
  code = 0;
  message = "";
  pagination = new PaginationV2();
  data: any = [];

  constructor(fields?: Partial<ResponsePayloadV2>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
