import { PUSAT_RESOLUSI } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const PusatResolusiList = () =>
  import(
    /* webpackChunkName: "PusatResolusi" */ "@/app/ui/views/pusat-resolusi/index.vue"
  );
const PusatResolusiCreate = () =>
  import(
    /* webpackChunkName: "PusatResolusi" */ "@/app/ui/views/pusat-resolusi/components/create/index.vue"
  );
const PusatResolusiDetail = () =>
  import(
    /* webpackChunkName: "PusatResolusi" */ "@/app/ui/views/pusat-resolusi/components/detail/index.vue"
  );

export default [
  {
    path: "/pusat-resolusi",
    component: Layout,
    name: "pusat-resolusi",
    redirect: "/pusat-resolusi",
    meta: {
      icon: "pusat-resolusi",
      children: false,
      title: "Pusat Resolusi",
      name: "Pusat Resolusi",
      gtmEventName: "pusat_resolusi_menu",
      gtmEventCustoms: {},
      gtmEventOption: ["userId", "userType", "timestamp"],
      permission: PUSAT_RESOLUSI.ENABLE
    },
    children: [
      {
        path: "/pusat-resolusi",
        name: "pusat-resolusi-list",
        component: PusatResolusiList,
        meta: {
          header: true
        }
      },
      {
        path: "create",
        name: "pusat-resolusi-create",
        component: PusatResolusiCreate,
        meta: {
          header: true,
          title: "Pusat Resolusi | Create ",
          name: "Pusat Resolusi"
        }
      }
    ]
  },
  {
    path: "/pusat-resolusi",
    redirect: "/pusat-resolusi",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Pusat Resolusi",
      permission: PUSAT_RESOLUSI.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "pusat-resolusi-detail",
        component: PusatResolusiDetail,
        meta: {
          header: true,
          title: "Pusat Resolusi | Detail",
          before: "/pusat-resolusi"
        }
      }
    ]
  }
];
