
import { prop, Vue } from "vue-class-component";
class Props {
  id = prop<string>({
    default: "",
    type: String
  });
  title = prop<string>({
    default: "",
    type: String
  });
  custom = prop<string>({
    default: "w-full bg-red-lp-200",
    type: String
  });
  icon = prop<string>({
    default: "",
    type: String
  });
  iconLeft = prop<string>({
    default: "",
    type: String
  });
  onClick = prop<Function>({
    default: null,
    type: Function
  });
  isLoading = prop<boolean>({
    default: false,
    type: Boolean
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  fontBold = prop<boolean>({
    default: false,
    type: Boolean
  });
}

export default class ButtonFill extends Vue.with(Props) {}
