import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { TotalSTTPaidUnread } from "@/domain/entities/STTPaidUnpaid";
import { AxiosResponse } from "axios";

export class STTPaidUnpaidMapper {
  public convertTotalSTTPaidUnreadFromApi(
    result: AxiosResponse
  ): TotalSTTPaidUnread {
    const { data } = result;
    return new TotalSTTPaidUnread({
      totalUnread: data.data.total_unread || 0,
      lastOpenAt: data.data.last_open_at || ""
    });
  }

  public convertLastOpenSTTPaidUnreadFromApi(
    result: AxiosResponse
  ): ResponsePayloadV2 {
    const { data } = result;
    return new ResponsePayloadV2({
      success: data.success,
      code: data.code,
      message: data.message,
      data: data.data
    });
  }
}
