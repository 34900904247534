import { injectable } from "tsyringe";
import { CourierManagementApiRepository } from "@/app/infrastructures/repositories/api/CourierManagementApiRepository";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import {
  ApiRequestCourierManagementCreate,
  ApiRequestCourierManagementList,
  ApiRequestCourierManagementListHistory,
  ApiRequestCourierVehicleList
} from "@/data/payload/api/CourierManagementApiRequest";
import { UpdateStatusCourierRequestInterface } from "@/data/payload/contracts/CourierManagementRequest";

@injectable()
export class CourierManagementPresenter {
  private repository: CourierManagementApiRepository;

  constructor(repository: CourierManagementApiRepository) {
    this.repository = repository;
  }

  public getList(
    params: ApiRequestCourierManagementList
  ): Promise<ResponsePayloadV2> {
    return this.repository.getList(params);
  }

  public getListApproval(
    params: ApiRequestCourierManagementList
  ): Promise<ResponsePayloadV2> {
    return this.repository.getListApproval(params);
  }

  public getListHistoryCourier(
    params: ApiRequestCourierManagementListHistory
  ): Promise<ResponsePayloadV2> {
    return this.repository.getListHistoryCourier(params);
  }

  public downloadFile(
    params: ApiRequestCourierManagementList
  ): Promise<ResponsePayloadV2> {
    return this.repository.downloadFile(params);
  }

  public getDetail(id: number): Promise<ResponsePayloadV2> {
    return this.repository.getDetail(id);
  }

  public createCourier(
    payload: ApiRequestCourierManagementCreate
  ): Promise<boolean> {
    return this.repository.createCourier(payload);
  }
  public editCourier(
    payload: ApiRequestCourierManagementCreate
  ): Promise<boolean> {
    return this.repository.editCourier(payload);
  }
  public approveRejectCourier(
    payload: UpdateStatusCourierRequestInterface
  ): Promise<boolean> {
    return this.repository.approveRejectCourier(payload);
  }
  public getListVehicle(
    params: ApiRequestCourierVehicleList
  ): Promise<ResponsePayloadV2> {
    return this.repository.getListVehicle(params);
  }
}
