/* eslint-disable */
import { TrackingMapper } from "@/data/persistences/mappers/TrackingMapper";
import { TrackingRepositoryInterface } from "@/data/persistences/repositories/contracts/TrackingRepositoryInterface";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { FlagsPermissionSTTTracking } from "@/feature-flags/flags-stt-tracking";

export class TrackingApiRepository implements TrackingRepositoryInterface {
  private service: ApiService;
  private mapper: TrackingMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: TrackingMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async getTrackingStt(sttNo: string, cache: boolean) {
    const version = FlagsPermissionSTTTracking.flag_revamp_tracking.isEnabled() ? "v2" : "v1";
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getTrackingStt(sttNo, cache, version),
      {}
    );
    return this.mapper.convertTrackingSttDataFromApi(resp);
  }

  async getTrackingCargo(cargoNo: string) {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getTrackingCargo(cargoNo),
      {}
    );

    return this.mapper.convertTrackingCargoDataFromApi(resp);
  }
}
