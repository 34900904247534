import { AccountController } from "@/app/ui/controllers/AccountController";
import UAParser from "ua-parser-js";
import { v4 as uuidv4 } from "uuid";

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

export const dataLayer = (
  eventName: string,
  events: any,
  options: string[]
) => {
  if (!window.google_tag_manager) {
    return;
  }

  const ua = new UAParser();
  const gtmId: any = process.env.VUE_APP_GTM_ID?.toString();
  const gtm = window.google_tag_manager[gtmId];
  const distincId = uuidv4();

  const defaultOptions = [
    {
      name: "browserVersion",
      value: `${ua.getBrowser().name} v${ua.getBrowser().version}`
    },
    {
      name: "osVersion",
      value: `${ua.getOS().name} v${ua.getOS().version}`
    },
    {
      name: "deviceType",
      value: getDeviceType()
    },
    {
      name: "userType",
      value: AccountController.accountData.account_type_detail.type
        ? AccountController.accountData.account_type_detail.type
        : AccountController.accountData.account_type
    },
    {
      name: "userId",
      value: AccountController.accountData.account_id
    },
    {
      name: "username",
      value: AccountController.accountData.username
    },
    {
      name: "timestamp",
      value: new Date().getTime()
    },
    {
      name: "distincId",
      value: distincId
    }
  ];

  const optionsFiltered = defaultOptions.filter((item: any) =>
    options.includes(item.name)
  );
  const optionEvent: any = {};
  for (const val of optionsFiltered) {
    optionEvent[val.name] = val.value;
  }

  const eventPass = {
    event: eventName,
    data: {
      ...optionEvent,
      ...events
    }
  };

  // reset before push data
  window.dataLayer.push(() => {
    gtm.dataLayer.reset();
  });

  window.dataLayer.push({
    ...eventPass
  });
};
