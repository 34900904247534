import { ALLOWED, CLAIM } from "@/app/infrastructures/misc/RolePermission";
import { FlagsPermissionShopify } from "@/feature-flags/flags-shopify";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const Integration = () =>
  import(/* webpackChunkName: "Integration" */ "@/app/ui/views/integration/index.vue");

export default [
  {
    path: "/integration",
    component: Layout,
    redirect: "/integration",
    name: "integration",
    meta: {
      icon: "integration",
      spesific: false,
      children: false,
      title: "Integration",
      name: "Integration",
      permission: FlagsPermissionShopify.form_enable
    },
    children: [
      {
        path: "/integration",
        name: "integration",
        component: Integration,
        meta: {
          header: false
        }
      }
    ]
  }
];
