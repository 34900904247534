import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Vehicle, Vehicles } from "@/domain/entities/Vehicle";
import { AxiosResponse } from "axios";

export class VehicleDataMapper {
  public convertVehiclesDataFromApi(res: AxiosResponse<any>): Vehicles {
    const { data } = res;
    const vehicles: Vehicle[] = [];
    if (data.data.length === 0) {
      return new Vehicles([]);
    } else {
      data.data.map((item: any) => {
        return vehicles.push(
          new Vehicle(
            item.vehicle_id,
            item.vehicle_license_plate,
            item.vehicle_pos_id
          )
        );
      });
    }
    return new Vehicles(vehicles);
  }
  public convertPayloadData(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
