import { ALLOWED } from "@/app/infrastructures/misc/RolePermission";
const UniversalFlagDex = () =>
  import(
    /* webpackChunkName: "UniversalFlagDex" */ "@/app/ui/views/universal-flag-dex/index.vue"
  );

export default [
  {
    path: "/flag-dex",
    name: "universal-flag-dex",
    component: UniversalFlagDex,
    meta: {
      hidden: true,
      noAuth: true,
      permission: ALLOWED
    }
  }
];
