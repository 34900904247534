
import { Vue, prop, Options } from "vue-class-component";

class Props {
  modelValue = prop<boolean>({
    type: Boolean,
    default: false
  });
}

@Options({
  emits: ["update:modelValue", "update"]
})
export default class SttPiecesSuccessNotification extends Vue.with(Props) {
  onUpdate(e: any) {
    this.$emit("update", e);
    this.$emit("update:modelValue", false);
  }
}
