import { injectable } from "tsyringe";
import { RefundConfigurationApiRepository } from "@/app/infrastructures/repositories/api/RefundConfigurationApiRepository";
import { RefundConfigurationData } from "@/domain/entities/RefundConfiguration";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { RefundConfigurationRequestInterface } from "@/data/payload/contracts/RefundConfiguration";

@injectable()
export class RefundConfigurationPresenter {
  private repository: RefundConfigurationApiRepository;

  constructor(repo: RefundConfigurationApiRepository) {
    this.repository = repo;
  }

  public getDetailRefundConfiguration(
    id: number
  ): Promise<RefundConfigurationData> {
    return this.repository.getDetailRefundConfiguration(id);
  }

  public editRefundConfiguration(
    payload: RefundConfigurationRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editRefundConfiguration(payload);
  }
}
