import {
  SubconsoleEntities,
  SubconsoleData
} from "@/domain/entities/Subconsole";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";
import {
  Handover,
  HandoverList,
  HandoverSttResult,
  SttPiece,
  SttDetailData,
  PieceDetail,
  ResponseUpdate,
  PartnerVendorList,
  HandoverDetail
} from "@/domain/entities/Handover";
import {
  ResponsePayload,
  ResponseSttDetail
} from "@/domain/entities/ResponsePayload";

export class HandoverMapper {
  public convertListSubconsoleDataFromApi(
    res: AxiosResponse<any>
  ): SubconsoleEntities {
    const { data } = res;
    const subconsole: SubconsoleData[] = [];
    if (data.data.length === 0) {
      return new SubconsoleEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        subconsole.push(
          new SubconsoleData(
            item.id,
            item.name,
            item.address,
            item.balance,
            item.code,
            item.lat,
            item.long,
            item.type,
            item.created_at
          )
        );
      });
    }
    return new SubconsoleEntities(
      new Pagination(data.meta.page, data.meta.limit),
      subconsole
    );
  }

  public convertHandovertListFromApi(res: AxiosResponse<any>) {
    const { data } = res;
    const handovers: Handover[] = [];
    if (data.data.length === 0 || data.data === null) {
      return new HandoverList(new Pagination(1, 10), []);
    } else {
      data.data.map((item: any) => {
        handovers.push(
          new Handover(
            item.handover_id,
            item.handover_destination_partner_name,
            item.handover_vendor_code,
            item.handover_total_stt,
            item.handover_total_piece,
            item.handover_total_gross_weight,
            item.handover_total_volume_weight,
            item.handover_created_at,
            item.handover_created_name
          )
        );
      });
    }
    return new HandoverList(
      new Pagination(data.meta.page, data.meta.limit),
      handovers
    );
  }

  public convertSttDetailDataFromApi(
    res: AxiosResponse<any>
  ): ResponseSttDetail {
    const {
      data: { data }
    } = res;
    if (!data.is_allow_update_status)
      return new ResponseSttDetail(false, data.error_message, [], false);
    else
      return new ResponseSttDetail(
        data.is_allow_update_status,
        data.error_message,
        data.bag_stt !== null || data.bag_stt?.length ?
        data.bag_stt.map((key: any)=> new SttDetailData(
          key.stt_id,
          key.stt_no,
          key.stt_product_type,
          key.stt_total_piece,
          key.stt_origin_city_id,
          key.stt_destination_city_id,
          `${key.stt_destination_city_id} - ${key.stt_destination_city_name}`,
          key.stt_wood_packing.toLowerCase() === "yes" ? "Ya" : "Tidak",
          key.stt_commodity_code,
          key.stt_commodity_name,
          key.stt_gross_weight,
          key.stt_volume_weight,
          key.stt_chargeable_weight,
          key.stt_cod_amount,
          key.stt_last_status_id,
          key.booked_at,
          key.piece.length > 0
            ? key.piece.map(
                (item: any) =>
                  new PieceDetail(
                    item.stt_piece_id,
                    item.stt_piece_gross_weight,
                    item.stt_piece_volume_weight,
                    `${key.stt_no}-${item.stt_piece_no}`,
                    item.stt_piece_last_status_id,
                    `${item.stt_piece_no}/${key.stt_total_piece}`
                  )
              )
            : [],
          key.stt_elexys_no
        ))
        :
        new SttDetailData(
          data.stt.stt_id,
          data.stt.stt_no,
          data.stt.stt_product_type,
          data.stt.stt_total_piece,
          data.stt.stt_origin_city_id,
          data.stt.stt_destination_city_id,
          `${data.stt.stt_destination_city_id} - ${data.stt.stt_destination_city_name}`,
          data.stt.stt_wood_packing.toLowerCase() === "yes" ? "Ya" : "Tidak",
          data.stt.stt_commodity_code,
          data.stt.stt_commodity_name,
          data.stt.stt_gross_weight,
          data.stt.stt_volume_weight,
          data.stt.stt_chargeable_weight,
          data.stt.stt_cod_amount,
          data.stt.stt_last_status_id,
          data.stt.booked_at,
          data.stt.piece.length > 0
            ? data.stt.piece.map(
                (item: any) =>
                  new PieceDetail(
                    item.stt_piece_id,
                    item.stt_piece_gross_weight,
                    item.stt_piece_volume_weight,
                    `${data.stt.stt_no}-${item.stt_piece_no}`,
                    item.stt_piece_last_status_id,
                    `${item.stt_piece_no}/${data.stt.stt_total_piece}`
                  )
              )
            : [],
          data.stt.stt_elexys_no
        ),
        data.is_paid
      );
  }

  public convertHandoverManifestFromApi(
    res: AxiosResponse<any>
  ): HandoverDetail {
    const {
      data: { data }
    } = res;
    return new HandoverDetail({
      handoverManifestId: data.handover_id,
      handoverManifestTotalStt: data.handover_total_stt,
      handoverTotalPiece: data.handover_total_piece,
      handoverManifestTotalGrossWeight: data.handover_total_gross_weight,
      handoverManifestTotalVolumeWeight: data.handover_total_volume_weight,
      handoverPartnerName: data.handover_partner_name,
      handoverDestinationPartnerName:
        data.handover_destination_partner_name || data.handover_vendor_code,
      handoverVendorCode: data.handover_vendor_code,
      handoverArrivalCitycode: data.handover_arrival_city_code,
      handoverArrivalCityName: data.handover_arrival_city_name,
      handoverOriginCityCode: data.handover_origin_city_code,
      handoverOriginCityName: data.handover_origin_city_name,
      handoverCreatedBy: data.handover_created_by_name,
      handoverCreatedAt: data.handover_created_at,
      stt: data.stt.length
        ? data.stt.map(
            (item: any) =>
              new HandoverSttResult(
                item.stt_id,
                item.stt_no,
                item.stt_product_type,
                item.stt_total_piece,
                item.stt_destination_city_id,
                item.stt_destination_city_name,
                item.stt_wood_packing,
                item.stt_commodity_code,
                item.stt_commodity_name,
                item.stt_gross_weight,
                item.stt_volume_weight,
                item.stt_chargeable_weight,
                item.stt_cod_amount,
                item.piece.map(
                  (val: any) =>
                    new SttPiece(
                      val.stt_piece_id,
                      val.stt_piece_gross_weight,
                      val.stt_piece_volume_weight,
                      val.stt_piece_no
                    )
                ),
                item.stt_origin_city_id,
                item.stt_origin_city_name,
                item.stt_elexys_no
              )
          )
        : []
    });
  }

  public convertUpdateDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: new ResponseUpdate(
        data.data.handover_id,
        data.data.total_stt_success,
        data.data.total_stt_failed,
        data.data.stt_failed
      )
    });
  }

  public convertPartnerVendorListDataFromApi(
    res: AxiosResponse<any>
  ): PartnerVendorList[] {
    const {
      data: { data }
    } = res;
    if (data.length === 0) return [];
    else
      return data.map(
        (item: any) =>
          new PartnerVendorList(
            item.code,
            item.partner_id,
            item.partner_name,
            item.type,
            item.city_code,
            item.vendor_name
          )
      );
  }
}
