import { TopupFeeConfigurationRepositoryInteface } from "@/data/persistences/repositories/contracts/TopupFeeConfigurationRepositoryInterface";
import { RequestTopupFeeConfigurationList, TopupFeeConfigurationList } from "@/domain/entities/TopupFeeConfiguration";
import ApiService from "../../services/ApiService";
import { TopupFeeConfigurationMapper } from "@/data/persistences/mappers/TopupFeeConfigurationMapper";
import { Endpoints } from "../../misc/Endpoints";
import { TopupFeeConfigRequest } from "@/data/payload/api/TopupFeeConfigRequest";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";

export class TopupFeeConfigurationApiRepository implements TopupFeeConfigurationRepositoryInteface {
    private service: ApiService;
    private mapper: TopupFeeConfigurationMapper;
    private endpoints: Endpoints;
    constructor(
        service: ApiService,
        mapper: TopupFeeConfigurationMapper,
        endpoints: Endpoints
    ) {
        this.service = service;
        this.mapper = mapper;
        this.endpoints = endpoints;
    }
    
    public async getActiveTopupFeeConfig(id: any): Promise<ResponsePayloadV2> {
        const resp = await this.service.invoke("GET", this.endpoints.getActiveTopupFeeConfig(id), {});
        return this.mapper.convertDetailDataFromApi(resp);
    }

    public async updateConfig(id: any, payload: TopupFeeConfigRequest): Promise<ResponsePayloadV2> {
        const resp = await this.service.invoke("PATCH", this.endpoints.detailTopupFeeConfig(id), {}, payload);
        return this.mapper.convertSubmitConfigFromApi(resp);
    }

    public async getDetailTopupFeeConfiguration(id: any): Promise<ResponsePayloadV2> {
        const resp = await this.service.invoke("GET", this.endpoints.detailTopupFeeConfig(id), {});
        return this.mapper.convertDetailDataFromApi(resp);
    }

    public async saveConfig(payload: TopupFeeConfigRequest): Promise<ResponsePayloadV2> {
        const resp = await this.service.invoke("POST", this.endpoints.saveTopupFeeConfig(), {}, payload);
        return this.mapper.convertSubmitConfigFromApi(resp);
    }
    public async getTopupFeeConfigurationList(params: RequestTopupFeeConfigurationList): Promise<TopupFeeConfigurationList> {
        const resp = await this.service.invoke("GET",
            this.endpoints.getTopupFeeConfigList(params),
            {}
        )
        return this.mapper.convertListDataFromApi(resp);
    }

}