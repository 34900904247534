import { CustomProcessMapper } from "@/data/persistences/mappers/CustomProcessMapper";
import { CustomProcessRepositoryInterface } from "@/data/persistences/repositories/contracts/CustomProcessRepositoryInterface";
import {
  PatchReverseDestinationApiRequest,
  RequestListCustomProcess,
  UpdateStatusApiRequest
} from "@/data/payload/api/CustomProcessApiRequest";
import { UpdateStatusRequestInterface } from "@/data/payload/contracts/CustomProcessRequest";
import {
  CustomProcessSttData,
  CustomProcessEntities,
  DetailCustomProcess,
  ResponseUpdate,
  CustomProcessReasonList
} from "@/domain/entities/CustomProcess";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";

export class CustomProcessApiRepository
  implements CustomProcessRepositoryInterface {
  private service: ApiService;
  private mapper: CustomProcessMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: CustomProcessMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  public async patchReverseDestination(id: any, payload: UpdateStatusRequestInterface): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "patch",
      this.endpoints.patchReverseDestinationStt(id),
      undefined,
      payload as PatchReverseDestinationApiRequest
    )
    return this.mapper.convertPatchReverseDestination(resp);
  }

  public async getDetailCustomProcess(
    id: number
  ): Promise<DetailCustomProcess> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailCustomProcess(id),
      {}
    );
    return this.mapper.convertCustomProcessDetailFromApi(resp);
  }
  async getListCustomProcess(
    params: RequestListCustomProcess
  ): Promise<CustomProcessEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getCustomProcessList(params),
      {}
    );
    return this.mapper.convertCustomProcessListDataFromApi(resp);
  }

  async updateStatus(
    payload: UpdateStatusRequestInterface
  ): Promise<ResponseUpdate> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.updateStatusCustomProcess(),
      undefined,
      payload as UpdateStatusApiRequest
    );
    return this.mapper.convertUpdateStatusFromApi(resp);
  }

  async getCustomProcessSttDetail(
    sttNo: string,
    customProcessStatus: string,
    partnerId?: number
  ): Promise<CustomProcessSttData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getCustomProcessSttDetail(
        sttNo,
        customProcessStatus,
        partnerId
      ),
      {}
    );
    return this.mapper.convertSttDataFromApi(resp);
  }

  public async getCustomProcessReasonList(): Promise<CustomProcessReasonList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getReasonCustomProcess(),
      {}
    );
    return this.mapper.convertCustomProcessReasonFromApi(resp);
  }
}
