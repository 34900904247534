import { Pagination } from "./Pagination";

export class PosFavoriteSubscriberEntities {
  pagination: Pagination;
  data: PosFavoriteSubscriberListData[];
  constructor(pagination: Pagination, data: PosFavoriteSubscriberListData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class PosFavoriteSubscriberListData {
  customerId: number;
  fullname: string;
  phoneNumber: string;
  discount: number;
  spending: number;
  constructor(
    customerId: number,
    fullname: string,
    phoneNumber: string,
    discount: number,
    spending: number
  ) {
    this.customerId = customerId;
    this.fullname = fullname;
    this.phoneNumber = phoneNumber;
    this.discount = discount;
    this.spending = spending;
  }
}

export class PosFavoriteSubscriberOutstandingEntities {
  pagination: Pagination;
  data: PosFavoriteSubscriberOutstandingListData[];
  constructor(
    pagination: Pagination,
    data: PosFavoriteSubscriberOutstandingListData[]
  ) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class PosFavoriteSubscriberOutstandingListData {
  invoiceId: string;
  createdAt: string;
  totalInvoice: number;
  statusInvoice: string;
  totalStt: number;
  customerName: string;
  customerAddress: string;
  customerPhoneNumber: string;
  constructor(
    invoiceId: string,
    createdAt: string,
    totalInvoice: number,
    statusInvoice: string,
    totalStt: number,
    customerName: string,
    customerAddress: string,
    customerPhoneNumber: string
  ) {
    this.invoiceId = invoiceId;
    this.createdAt = createdAt;
    this.totalInvoice = totalInvoice;
    this.statusInvoice = statusInvoice;
    this.totalStt = totalStt;
    this.customerName = customerName;
    this.customerAddress = customerAddress;
    this.customerPhoneNumber = customerPhoneNumber;
  }
}

export class PosFavouriteDefaultDiscount {
  defaultDiscount: number;
  lastUpdateDate: string;
  updatedBy: string;

  constructor(
    defaultDiscount: number,
    lastUpdateDate: string,
    updatedBy: string
  ) {
    this.defaultDiscount = defaultDiscount;
    this.lastUpdateDate = lastUpdateDate;
    this.updatedBy = updatedBy;
  }
}
