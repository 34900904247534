import { UrgentDeliveryApiRepository } from "@/app/infrastructures/repositories/api/UrgentDeliveryRepository";
import {
  UrgentDeliveryListEntities,
  RequestUrgentDeliveryListApi,
  UrgentDeliverySTTStatusListEntities,
  UrgentDeliveryDetailData,
  RequestUrgentDeliveryListApiDownload
} from "@/domain/entities/UrgentDelivery";

import { injectable } from "tsyringe";

@injectable()
export class UrgentDeliveryPresenter {
  private repository: UrgentDeliveryApiRepository;

  constructor(repository: UrgentDeliveryApiRepository) {
    this.repository = repository;
  }

  public getUrgentDeliveryList(
    params: RequestUrgentDeliveryListApi
  ): Promise<UrgentDeliveryListEntities> {
    return this.repository.getUrgentDeliveryList(params);
  }

  public getUrgentDeliverySTTStatusList(): Promise<UrgentDeliverySTTStatusListEntities> {
    return this.repository.getSTTStatusUrgentDeliveryList();
  }
  public getUrgentDeliveryDetail(id: number): Promise<UrgentDeliveryDetailData> {
    return this.repository.getUrgentDeliveryDetail(id);
  }

  public getUrgentDeliverySTTDownload(
    params: RequestUrgentDeliveryListApiDownload
  ) {
    return this.repository.getUrgentDeliverySTTDownload(params);
  }
}
