import { PartnerSubconsoleApiRepository } from "@/app/infrastructures/repositories/api/PartnerSubconsoleApiRepository";
import { injectable } from "tsyringe";
import { PartnerSubconsoleEntities } from "@/domain/entities/PartnerSubconsole";

@injectable()
export class PartnerSubconsolePresenter {
  private repository: PartnerSubconsoleApiRepository;

  constructor(repository: PartnerSubconsoleApiRepository) {
    this.repository = repository;
  }

  public getPartnerSubconsoleList(
    search: string,
    page: number,
    limit: number,
    useLocation: boolean,
    isDefaultLocation: boolean,
    cityCode: string,
    districtCode: string,
    status: string,
    contractStatus: string
  ): Promise<PartnerSubconsoleEntities> {
    return this.repository.getPartnerSubconsoleList(
      search,
      page,
      limit,
      useLocation,
      isDefaultLocation,
      cityCode,
      districtCode,
      status,
      contractStatus
    );
  }
}
