import {
  PRICING_COMMODITY,
  PRICING_PRODUCT
} from "@/app/infrastructures/misc/RolePermission";

import CommodityModules from "./commodity";
import ProductModules from "./product";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const Product = () =>
  import(
    /* webpackChunkName: "Product" */ "@/app/ui/views/pricing/product/index.vue"
  );
const Commodity = () =>
  import(
    /* webpackChunkName: "Commodity" */ "@/app/ui/views/pricing/commodity/index.vue"
  );

export default [
  {
    path: "/master-data",
    component: Layout,
    name: "master-data",
    redirect: "/master-data/product",
    meta: {
      icon: "master-data",
      children: true,
      name: "Master Data",
      permission: [PRICING_PRODUCT.ENABLE, PRICING_COMMODITY.ENABLE]
    },
    children: [
      {
        path: "product",
        name: "product",
        component: Product,
        meta: {
          header: true,
          icon: "product",
          title: "Product",
          name: "Product",
          permission: PRICING_PRODUCT.ENABLE
        }
      },
      {
        path: "commodity",
        name: "commodity",
        component: Commodity,
        meta: {
          header: true,
          icon: "commodity",
          title: "Komoditas",
          name: "Komoditas",
          permission: PRICING_COMMODITY.ENABLE
        }
      }
    ]
  },
  ...CommodityModules,
  ...ProductModules
];
