/* eslint-disable @typescript-eslint/camelcase */

import { ConfigPriceData } from "./ConfigPrice";

export class ConfigurableInsurance {
  insuranceId = 0;
  insuranceConfigurablePriceId = 0;
  insuranceVendorName = "";
  insuranceIsApiUrl = "";
  insuranceApiUrl = "";
  insuranceApiToken = "";
  insuranceTypes: Insurance[] = [];

  constructor(fields?: Partial<ConfigurableInsurance>) {
    Object.assign(this, fields);
  }
}
export class DetailInsurance {
  configPrice: ConfigPriceData = new ConfigPriceData();
  configurableInsurance: ConfigurableInsurance = new ConfigurableInsurance();

  constructor(fields?: Partial<DetailInsurance>) {
    Object.assign(this, fields);
  }
}

export class Insurance {
  id = 0;
  name = "";
  type = "";
  priceGoodsMin = 0;
  priceGoodsMax = 0;
  pricePremiMin = 0;
  pricePremiMax = 0;
  priceGoodsMinMY = 0;
  priceGoodsMaxMY = 0;
  pricePremiMinMY = 0;
  pricePremiMaxMY = 0;
  cost = 0;
  commisionPos = 0;

  constructor(fields?: Partial<Insurance>) {
    Object.assign(this, fields);
  }
}
