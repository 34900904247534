import { ROUTE_LEG } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const DetailLeg = () =>
  import(
    /* webpackChunkName: "Leg" */ "@/app/ui/views/route/leg-management/component/detail-leg.vue"
  );
const EditLeg = () =>
  import(
    /* webpackChunkName: "Leg" */ "@/app/ui/views/route/leg-management/component/edit.vue"
  );

export default [
  {
    path: "/network/leg-management",
    component: Layout,
    name: "detail-leg-management",
    meta: {
      hidden: true,
      parent: "Leg Management",
      permission: ROUTE_LEG.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-leg-management",
        component: DetailLeg,
        meta: {
          header: true,
          title: "Leg Management | Detail"
        }
      }
    ]
  },
  {
    path: "/network/leg-management",
    component: Layout,
    name: "edit-leg",
    meta: {
      icon: "leg-management",
      children: false,
      hidden: true,
      parent: "Leg Management",
      permission: ROUTE_LEG.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-leg",
        component: EditLeg,
        meta: {
          header: true,
          title: "Leg Management | Edit",
          name: "Edit",
          before: "/network/leg-management/:id"
        }
      }
    ]
  }
];
