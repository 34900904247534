const dateToTime = (date: any) => {
  const format = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09"];
  date = new Date(date);
  return `${
    date.getHours().toString().length === 1
      ? format[date.getHours()]
      : date.getHours()
  }:${
    date.getMinutes().toString().length === 1
      ? format[date.getMinutes()]
      : date.getMinutes()
  }`;
};
export default dateToTime;
