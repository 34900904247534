import { BeneficiaryRepositoryInterface } from "@/data/persistences/repositories/contracts/BeneficiaryRepositoryInterface";
import {
  BeneficiaryEntities,
  ValidateBankAccount
} from "@/domain/entities/Beneficiary";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { BeneficiaryMapper } from "@/data/persistences/mappers/BeneficiaryMapper";
import { BankAccountRequestInterface } from "@/data/payload/contracts/BeneficiaryRequest";
import { ValidateBankAccountApiRequest } from "@/data/payload/api/BeneficiaryApiRequest";

export class BeneficiaryApiRepository
  implements BeneficiaryRepositoryInterface {
  private service: ApiService;
  private mapper: BeneficiaryMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: BeneficiaryMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getBeneficiaryAccList(
    page: number,
    limit: number,
    bankAccType: string,
    actortype: string,
    actorId: number
  ): Promise<BeneficiaryEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getBeneficiaryAccList(
        page,
        limit,
        bankAccType,
        actortype,
        actorId
      ),
      {}
    );

    return this.mapper.convertBeneficiaryDataFromApi(resp);
  }

  public async validateBankAccount(
    payload: BankAccountRequestInterface
  ): Promise<ValidateBankAccount> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.validateBankAccount(),
      {},
      payload as ValidateBankAccountApiRequest
    );
    return this.mapper.convertValidateBankAccountFromApi(resp);
  }
}
