/* eslint-disable @typescript-eslint/camelcase */
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { NotificationsAll } from "@/domain/entities/BalanceMinusPenalty";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { BalanceMinusPenaltyRepositoryInterface } from "@/data/persistences/repositories/contracts/BalanceMinusPenaltyRepositoryInterface";
import { BalanceMinusPenaltyMapper } from "@/data/persistences/mappers/BalanceMinusPenaltyMapper";
import { BalanceMinusPenaltyRequestInterface } from "@/data/payload/contracts/BalanceMinusPenaltyRequest";
import { BalanceMinusPenaltyApiRequest } from "@/data/payload/api/BalanceMinusPenaltyApiRequest";

export class BalanceMinusPenaltyApiRepository
  implements BalanceMinusPenaltyRepositoryInterface {
  private service: ApiService;
  private mapper: BalanceMinusPenaltyMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: BalanceMinusPenaltyMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getList(params: ApiRequestList): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getBalanceMinusPenalty(params),
      {}
    );
    return this.mapper.convertListDataFromApi(resp);
  }

  public async getAllNotifications(): Promise<NotificationsAll[]> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getAllNotifications(),
      {}
    );
    return this.mapper.convertGetAllNotificationsDataFromApi(resp);
  }

  public async getDetail(id: number): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getBalanceMinusPenaltyDetail(id),
      {}
    );
    return this.mapper.convertDetailDataFromApi(resp);
  }

  public async edit(
    payload: BalanceMinusPenaltyRequestInterface
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "PATCH",
      this.endpoints.getBalanceMinusPenaltyDetail(
        (payload as BalanceMinusPenaltyApiRequest).payload.id
      ),
      undefined,
      payload as BalanceMinusPenaltyApiRequest
    );
    return this.mapper.convertFormDataFromApi(resp);
  }

  public async getBalancePenaltyNotification(): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getBalancePenaltyNotification(),
      {}
    );
    return this.mapper.convertBalancePenaltyNotificationnDataFromApi(resp);
  }
}
