import {
  FINANCE_BALANCE_LIMIT,
  FINANCE_MANUAL_DEDUCT_ADD_SALDO,
  FINANCE_BALANCE_TRANSACTION,
  LAST_BALANCE,
  BALANCE_MINUS_PENALTY
} from "@/app/infrastructures/misc/RolePermission";

import BalanceLimitRulesModules from "./balance-limit-rules";
import DeductSaldoModules from "./deduct-saldo";
import BalanceMinusPenaltyModules from "./balance-minus-penalty";
import TopupFeeConfigModules from "./top-up-fee";
import CashbackConfigurationModules from "./cashback-configuration";
import { FlagsPermissionTopUpApproval } from "@/feature-flags/flags-permission-topup-approval";
import { FlagsPermissionTopUpVerification } from "@/feature-flags/flags-permission-topup-verification";
import { FlagsPermissionTopUpAndCashback } from "@/feature-flags/flags-permission-topup-and-cashback";
import { FlagsPermissionBalanceTransaction } from "@/feature-flags/flags-permission-balance-transaction";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const TopupVerification = () =>
  import(
    /* webpackChunkName: "Balance" */ "@/app/ui/views/finance/topup-verification/index.vue"
  );
const Balance = () =>
  import(
    /* webpackChunkName: "BalanceLimit" */ "@/app/ui/views/finance/balance-limit/index.vue"
  );
import(
  /* webpackChunkName: "BalanceLimit" */ "@/app/ui/views/finance/balance-limit/component/edit.vue"
);

const ManualDeductAddSaldo = () =>
  import(
    /* webpackChunkName: "DeductManual" */ "@/app/ui/views/finance/manual-deduct-add-saldo/index.vue"
  );
const TopupApproval = () =>
  import(
    /* webpackChunkName: "TopupApproval" */ "@/app/ui/views/finance/topup-approval/index.vue"
  );
const LastBalance = () =>
  import(
    /* webpackChunkName: "LastBalance" */ "@/app/ui/views/finance/last-balance/index.vue"
  );
const LastBalancePos = () =>
  import(
    /* webpackChunkName: "LastBalance" */ "@/app/ui/views/finance/last-balance/pos/index.vue"
  );
const LastBalanceClient = () =>
  import(
    /* webpackChunkName: "LastBalance" */ "@/app/ui/views/finance/last-balance/client/index.vue"
  );
const LastBalanceClientCod = () =>
  import(
    /* webpackChunkName: "LastBalance" */ "@/app/ui/views/finance/last-balance/client-cod/index.vue"
  );

const BalanceTransaction = () =>
  import(
    /* webpackChunkName: "BalanceTrx" */ "@/app/ui/views/finance/balance-transaction/index.vue"
  );
const BalanceNettTransaction = () =>
  import(
    /* webpackChunkName: "BalanceTrx" */ "@/app/ui/views/finance/balance-nett-transaction/index.vue"
  );
const BalanceMinusPenaltyList = () =>
  import(
    /* webpackChunkName: "BalanceMinus" */ "@/app/ui/views/finance/balance-minus-penalty/index.vue"
  );

const TopUpAndCashback= () =>
  import(
    /* webpackChunkName: "TopUpAndCashback" */ "@/app/ui/views/finance/top-up-and-cashback/index.vue"
  );

const TopUpFee= () =>
  import(
    /* webpackChunkName: "TopUpFee" */ "@/app/ui/views/finance/top-up-and-cashback/top-up-fee/list/index.vue"
  );

const Cashback= () =>
  import(
    /* webpackChunkName: "Cashback" */ "@/app/ui/views/finance/top-up-and-cashback/cashback/list/index.vue"
  );

export default [
  {
    path: "/finance",
    component: Layout,
    name: "finance",
    redirect: "/finance/balance",
    meta: {
      icon: "finance",
      children: true,
      title: "Finance",
      name: "Finance",
      permission: [
        FINANCE_BALANCE_LIMIT.ENABLE,
        FlagsPermissionTopUpVerification.permission_topup_verification_enable,
        FlagsPermissionTopUpApproval.permission_topup_manual_approval_enable,
        LAST_BALANCE.ENABLE
      ]
    },
    children: [
      {
        path: "topup-verification",
        name: "topup-verification",
        component: TopupVerification,
        meta: {
          header: true,
          isFreeze: true,
          icon: "topup-verification",
          title: "Top Up Verification",
          name: "Top Up Verification",
          permission:
            FlagsPermissionTopUpVerification.permission_topup_verification_list_view
        }
      },
      {
        path: "topup-approval",
        name: "topup-approval",
        component: TopupApproval,
        meta: {
          header: true,
          isFreeze: true,
          icon: "topup-approval",
          title: "Top Up Approval",
          name: "Top Up Approval",
          permission:
            FlagsPermissionTopUpApproval.permission_topup_manual_approval_list_view
        }
      },
      {
        path: "manual-deduct",
        name: "manual-deduct",
        component: ManualDeductAddSaldo,
        meta: {
          header: false,
          isFreeze: true,
          icon: "manual-deduct",
          title: "Manual Credit Debit",
          name: "Manual Credit Debit",
          permission: FINANCE_MANUAL_DEDUCT_ADD_SALDO.ENABLE
        }
      },
      {
        path: "balance-limit",
        name: "balance-limit",
        component: Balance,
        meta: {
          header: true,
          icon: "balance",
          title: "Balance Limit Rules",
          name: "Balance Limit Rules",
          permission: FINANCE_BALANCE_LIMIT.ENABLE
        }
      },
      {
        path: "last-balance",
        name: "last-balance",
        component: LastBalance,
        redirect: "/finance/last-balance/balance-pos",
        meta: {
          icon: "saldo-terakhir",
          title: "Saldo Terakhir",
          name: "Saldo Terakhir",
          header: true,
          permission: [
            LAST_BALANCE.VIEW_POS,
            LAST_BALANCE.VIEW_CLIENT,
            LAST_BALANCE.VIEW_CLIENT_COD,
            LAST_BALANCE.ENABLE
          ]
        },
        children: [
          {
            path: "balance-pos",
            name: "balance-pos",
            component: LastBalancePos
          },
          {
            path: "balance-client",
            name: "balance-client",
            component: LastBalanceClient
          },
          {
            path: "balance-cod-client",
            name: "balance-cod-client",
            component: LastBalanceClientCod
          }
        ]
      },
      {
        path: "balance-transaction",
        name: "balance-transaction",
        component: BalanceTransaction,
        meta: {
          header: true,
          icon: "balance-transaction",
          title: "Transaksi Saldo",
          name: "Transaksi Saldo",
          permission:
            FlagsPermissionBalanceTransaction.finance_balance_transaction_enable
        }
      },
      {
        path: "balance-nett-transaction",
        name: "balance-nett-transaction",
        component: BalanceNettTransaction,
        meta: {
          header: true,
          icon: "balance-transaction",
          title: "Transaksi Saldo Nett",
          name: "Transaksi Saldo Nett",
          permission:
            FlagsPermissionBalanceTransaction.finance_balance_transaction_nett_enable
        }
      },
      {
        path: "balance-minus-penalty",
        name: "balance-minus-penalty",
        component: BalanceMinusPenaltyList,
        meta: {
          header: true,
          icon: "balance-minus-penalty",
          title: "Denda Saldo Minus",
          name: "Denda Saldo Minus",
          permission: BALANCE_MINUS_PENALTY.ENABLE
        }
      },
      {
        path: "top-up-and-cashback",
        name: "top-up-and-cashback",
        component: TopUpAndCashback,
        redirect: "/finance/top-up-and-cashback/top-up-fee",
        meta: {
          icon: "top-up-and-cashback",
          title: "Top Up & Cashback",
          name: "Top Up & Cashback",
          header: true,
          permission: [
            FlagsPermissionTopUpAndCashback.permission_finance_cashback_enable,
            FlagsPermissionTopUpAndCashback.permission_finance_top_up_fee_enable
          ]
        },
        children: [
          {
            path: "top-up-fee",
            name: "top-up-fee",
            component: TopUpFee
          },
          {
            path: "cashback",
            name: "cashback",
            component: Cashback
          },
        ]
      },
    ]
  },
  ...BalanceLimitRulesModules,
  ...DeductSaldoModules,
  ...BalanceMinusPenaltyModules,
  ...CashbackConfigurationModules,
  ...TopupFeeConfigModules
];
