import { TariffData, DetailDiscount } from "@/domain/entities/Tariff";
import { Pagination } from "@/domain/entities/Pagination";
import { Entities, ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class TariffDataMapper {
  public convertTariffFromApi(result: AxiosResponse<any>): Entities {
    const { data } = result;
    return new Entities(
      data.success,
      data.code,
      data.message,
      new Pagination(1, 10),
      data.data.length === 0
        ? []
        : data.data.map((item: any) => {
            return new TariffData({
              cityOriginId: item.city_origin_id,
              cityDestinationId: item.city_destination_id,
              districtOriginId: item.district_origin_id,
              districtDestinationId: item.district_destination_id,
              productType: item.product_type,
              commodityName: item.commodity_name,
              commodityNameEn: item.commodity_name_en || "-",
              commodityId: item.commodity_id,
              commodityCode: item.commodity_code,
              accountRefId: item.account_ref_id,
              accountRefName: item.account_ref_name,
              originDistrictRate: item.origin_district_rate,
              destinationDistrictRate: item.destination_district_rate,
              baseRate: item.base_rate,
              commodityRate: item.commodity_rate,
              heavyWeightSurcharge: item.heavy_weight_surcharge,
              documentSurcharge: item.document_surcharge,
              publishRate: item.publish_rate,
              shippingSurchargeRate: item.shipping_surcharge_rate,
              grossWeight: item.gross_wight,
              length: item.length,
              width: item.width,
              height: item.height,
              chargeableWeight: item.chargeable_weight,
              volumeWeight: item.volume_weight,
              totalTariff: item.total_tariff,
              sla: item.estimasi_sla,
              isEmbargo: item.is_embargo,
              isPromo: item.is_promo,
              totalTariffAfterDiscount: item.total_tariff_after_discount,
              publishRateAfterDiscount: item.publish_rate_after_discount,
              heavyWeightSurchargeAfterDiscount:
                item.heavy_weight_surcharge_after_discount,
              documentSurchargeAfterDiscount:
                item.document_surcharge_after_discount,
              shippingSurchargeRateAfterDiscount:
                item.shipping_surcharge_rate_after_discount,
              originDistrictRateAfterDiscount:
                item.origin_district_rate_after_discount,
              destinationDistrictRateAfterDiscount:
                item.destination_district_rate_after_discount,
              commoditySurchargeAfterDiscount:
                item.commodity_surcharge_after_discount,
              woodpackingRatesAfterDiscount:
                item.woodpacking_rates_after_discount,
              insuranceRatesAfterDiscount: item.insurance_rates_after_discount,
              codFeeAfterDiscount: item.cod_fee_after_discount,
              discountType: item.discount_type,
              discount: item.discount,
              maximalPromo: item.maximal_promo,
              parameterCalculation: item.parameter_calculation,
              totalDiscount: item.total_discount,
              isCod: item.is_cod,
              codFee: item.cod_fee,
              codReturnFee: item.cod_return_fee,
              dfodFee: item.dfod_fee,
              listDiscount: item.list_discount?.length ? item.list_discount.map((el: any) => {
                return new DetailDiscount({
                  pdcId: el.config.pdc_id,
                  pdcName: el.config.pdc_name,
                  pdcPromoDiscount: el.config.pdc_promo_discount,
                  pdcCategory: el.config.pdc_category,
                  pdcEndDate: el.config.pdc_end_date,
                  pdcQuantifier: el.config.pdc_parameter_calculation
                })
              }) : []
            });
          })
    );
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
