import { injectable } from "tsyringe";
import { ListSttApiRepository } from "@/app/infrastructures/repositories/api/ListSttApiRepository";
import { BagListEntities, ListSttEntities } from "@/domain/entities/ListStt";

@injectable()
export class ListSttPresenter {
  private repository: ListSttApiRepository;

  constructor(repo: ListSttApiRepository) {
    this.repository = repo;
  }

  public async getSttList(
    page: number,
    limit: number,
    destinationCityId: number,
    userType: string,
    bookedBy: string,
    fromDate: string,
    endDate: string,
    sttNumber: string,
    bookedType: string
  ): Promise<ListSttEntities> {
    return await this.repository.getListStt(
      page,
      limit,
      destinationCityId,
      userType,
      bookedBy,
      fromDate,
      endDate,
      sttNumber,
      bookedType
    );
  }
  public async getBagList(bagNumber: string): Promise<BagListEntities> {
    return await this.repository.getListBag(bagNumber);
  }
}
