/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionTopUpManual = {
  permission_topup_manual_enable: new Rox.Flag(false),
  permission_topup_manual_history_view_list: new Rox.Flag(false),
  permission_topup_manual_request: new Rox.Flag(false)
};

export function initFlagsPermissionTopUpManual() {
  // register flags with namespaces for better grouping
  Rox.register("featureTopUpManual", FlagsPermissionTopUpManual);
}
