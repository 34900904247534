import { CommoditySurchargeMapper } from "@/data/persistences/mappers/CommoditySurchargeMapper";
import { CommoditySurchargeRepositoryInteface } from "@/data/persistences/repositories/contracts/CommoditySurchargeRepositoryInterface";
import {
  CommoditySurcharge,
  CommoditySurchargeList
} from "@/domain/entities/CommoditySurcharge";
import {
  AddCommoditySurchargeApiRequest,
  EditCommoditySurchargeApiRequest
} from "@/data/payload/api/CommoditySurchargeApiRequest";
import {
  EditCommoditySurchargeRequestInterface
} from "@/data/payload/contracts/CommoditySurchargeRequest";
import { ResponsePayload, ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class CommoditySurchargeApiRepository
  implements CommoditySurchargeRepositoryInteface {
  private service: ApiService;
  private mapper: CommoditySurchargeMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: CommoditySurchargeMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getCommoditySurchargeList(
    search: string,
    status: string,
    page: number,
    limit: number
  ): Promise<CommoditySurchargeList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getCommoditySurchargeList(search, status, page, limit),
      {}
    );
    return this.mapper.convertCommodityDataFromApi(resp);
  }

  // public async getDetailCommodity(id: number): Promise<CommoditySurcharge> {
  public async getDetailCommoditySurcharge(
    id: number
  ): Promise<CommoditySurcharge> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailCommoditySurcharge(id),
      {}
    );
    return this.mapper.convertCommodityDetailDataFromApi(resp);
  }

  public async editCommoditySurcharge(
    payload: EditCommoditySurchargeRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivate(
      "put",
      this.endpoints.addCommoditySurcharge(),
      undefined,
      payload as EditCommoditySurchargeApiRequest
    );
    return this.mapper.convertPayloadData(resp);
  }

  public async formSurcharge(params: {
    method: any,
    payload: AddCommoditySurchargeApiRequest
  }): Promise<ResponsePayloadV2> {
    const resp = await this.service.invokePrivate(
      params.method,
      this.endpoints.addCommoditySurcharge(),
      undefined,
      params.payload as AddCommoditySurchargeApiRequest
    );
    return this.mapper.convertPayloadDataForm(resp);
  }
}
