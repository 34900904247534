import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { LastBalanceRepositoryInterface } from "@/data/persistences/repositories/contracts/LastBalanceRepositoryInterface";
import { LastBalanceMapper } from "@/data/persistences/mappers/LastBalanceMapper";
import { LastBalanceEntities } from "@/domain/entities/LastBalance";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";
import { RequestListLastBalance } from "@/data/payload/api/LastBalanceApiRequest";
import { BalanceData } from "@/domain/entities/Balance";
import { RequestListClientManagement } from "@/data/payload/api/ClientManagementApiRequest";

export class LastBalanceApiRepository
  implements LastBalanceRepositoryInterface {
  private service: ApiService;
  private mapper: LastBalanceMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: LastBalanceMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getListPartner(
    params: RequestListPartner
  ): Promise<LastBalanceEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getPartnerList(params),
      {}
    );
    return this.mapper.convertListPartnerLastBalanceFromApi(resp);
  }

  public async getListClientManagement(
    params: RequestListClientManagement
  ): Promise<LastBalanceEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.clientManagementGeneral(params),
      {}
    );
    return this.mapper.convertListClientManagementLastBalanceFromApi(resp);
  }

  public async getListLastBalance(
    params: RequestListLastBalance
  ): Promise<BalanceData[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListLastBalance(params),
      {}
    );
    return this.mapper.convertLastBalanceDataFromApi(resp);
  }
}
