import { RequestReadyToCargoList, RequestLogReadyToCargoList } from "@/domain/entities/ReadyToCargo";

export class ReadyToCargoEndpoint {
  getReadyToCargoList(params: RequestReadyToCargoList): string {
    return `/hydra/v1/ready-to-cargo?${params.toQueryString()}`;
  }

  getDetailReadyToCargo(id: number): string {
    return `/hydra/v1/ready-to-cargo/detail/${id}`;
  }

  scanReadyToCargo(): string {
    return `/hydra/v1/ready-to-cargo/scan`;
  }

  updateReadyToCargo(): string {
    return `/hydra/v1/ready-to-cargo`;
  }

  getLogReadyToCargoList(params: RequestLogReadyToCargoList): string {
    return `/hydra/v1/log-rtc?${params.toQueryString()}`;
  }
}
