/* eslint-disable @typescript-eslint/camelcase */
import { ConsolidatorMapper } from "@/data/persistences/mappers/ConsolidatorMapper";
import { ConsolidatorRepositoryInterface } from "@/data/persistences/repositories/contracts/ConsolidatorRepositoryInterface";
import { ConsolidatorEntities } from "@/domain/entities/Consolidator";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class ConsolidatorApiRepository
  implements ConsolidatorRepositoryInterface {
  private service: ApiService;
  private mapper: ConsolidatorMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: ConsolidatorMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  async getListConsolidator(
    page: number,
    limit: number,
    search: string,
    contract_status: string,
    cityCode: string,
    status: string
  ): Promise<ConsolidatorEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getPartnerConsolidatorList(
        page,
        limit,
        search,
        contract_status,
        cityCode,
        status
      ),
      {}
    );
    return this.mapper.convertConsolidatorDataFromApi(resp);
  }
}
