/* eslint-disable @typescript-eslint/camelcase */
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Utils";
import {
  ForgotPasswordRequestInterface,
  ResetPasswordRequestInterface,
  LoginRequestInterface,
  LogoutRequestInterface,
  VerificationAccountRequestInterface
} from "@/data/payload/contracts/AuthRequest";

export class LoginApiRequest implements LoginRequestInterface {
  private email: string;
  private password: string;

  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }

  public toJSON() {
    const email = this.email;
    const password = this.password;

    if (IS_SENDER_ACCOUNT) {
      return JSON.stringify({
        phone_number: "+62" + email,
        password
      });
    }
    return JSON.stringify({
      email,
      password
    });
  }
}

export class ForgotPasswordApiRequest
  implements ForgotPasswordRequestInterface {
  private email: string;

  constructor(email: string) {
    this.email = email;
  }

  public toJSON() {
    const email = this.email;

    return JSON.stringify({ email });
  }
}

export class ResetPasswordApiRequest implements ResetPasswordRequestInterface {
  private email: string;
  private password: string;
  private token: string;
  constructor(email: string, password: string, token: string) {
    this.email = email;
    this.password = password;
    this.token = token;
  }

  public toJSON() {
    const email = this.email;
    const password = this.password;
    const token = this.token;

    return JSON.stringify({
      email,
      password,
      token
    });
  }
}

export class EditPasswordApiRequest implements ResetPasswordRequestInterface {
  private oldPassword: string;
  private newPassword: string;
  constructor(oldPassword: string, newPassword: string) {
    this.oldPassword = oldPassword;
    this.newPassword = newPassword;
  }

  public toJSON() {
    const old_password = this.oldPassword;
    const new_password = this.newPassword;
    return JSON.stringify({
      old_password,
      new_password
    });
  }
}

export class LogoutApiRequest implements LogoutRequestInterface {
  public toJSON() {
    return JSON.stringify({});
  }
}

export class VerificationAccountRequest
  implements VerificationAccountRequestInterface {
  emailOrUsername: string;
  question: string;
  answer: string;
  constructor(emailOrUsername: string, question: string, answer: string) {
    this.emailOrUsername = emailOrUsername;
    this.question = question;
    this.answer = answer;
  }

  public toJSON(): string {
    return JSON.stringify({
      email_or_username: this.emailOrUsername,
      question: this.question,
      answer: this.answer
    });
  }
}
