import { injectable } from "tsyringe";
import { BookingApiRepository } from "@/app/infrastructures/repositories/api/BookingApiRepository";
import {
  CustomerListEntities,
  EstimationPrice,
  EstimationCodRetail,
  ShipmentIdList,
  CustomerExist,
  ProductDelivery,
  InsuranceTypeDelivery,
  EstimationServiceLevelAgreement,
  PosterProduct
} from "@/domain/entities/Booking";
import {
  AddBookingRequestInterface,
  GetEstimationPriceInterface,
  EditBookingRequestInterface,
} from "@/data/payload/contracts/BookingRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  RequestEstimateSlaDetail,
  RequestEstimationServiceLevelAgreement,
  SubmitPosterProductApiRequest,
  UploadHydraFileRequest,
  DeleteHydraFileRequest
} from "@/data/payload/api/BookingRequest";

@injectable()
export class BookingPresenter {
  private repository: BookingApiRepository;

  constructor(repo: BookingApiRepository) {
    this.repository = repo;
  }

  public async getCustomerList(search: string, cache: boolean): Promise<CustomerListEntities> {
    return await this.repository.getListCustomer(search, cache);
  }

  public async addCustomer(
    payload: AddBookingRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addCustomer(payload);
  }

  public async addManualBooking(
    payload: AddBookingRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addBookingManual(payload);
  }

  public getEstimationPrice(
    payload: GetEstimationPriceInterface
  ): Promise<EstimationPrice> {
    return this.repository.getEstimationPrice(payload);
  }

  public getEstimationPriceCodRetail(
    payload: GetEstimationPriceInterface
  ): Promise<EstimationCodRetail> {
    return this.repository.getEstimationPriceCodRetail(payload);
  }

  public async generatePrintData(stt: number, accountType: string) {
    return await this.repository.getPrintData(stt, accountType);
  }

  public async getShipmentIdList(
    id: string,
    limit: number,
    page: number
  ): Promise<ShipmentIdList> {
    return await this.repository.getListShipmentId(id, limit, page);
  }

  public async cancelBooking(
    payload: AddBookingRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.cancelBooking(payload);
  }

  public editBooking(
    id: number,
    payload: EditBookingRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editBooking(id, payload);
  }

  public getExistCustomer(
    name: string,
    phone: string,
    address: string
  ): Promise<CustomerExist> {
    return this.repository.getExistCustomer(name, phone, address);
  }

  public getProductsDelivery(
    search: string,
    page: number,
    limit: number,
    status: string
  ): Promise<ProductDelivery> {
    return this.repository.getProductsDelivery(search, page, limit, status);
  }

  public getInsuranceTypesDelivery(
    insuranceConfig: number
  ): Promise<InsuranceTypeDelivery> {
    return this.repository.getInsuranceTypesDelivery(insuranceConfig);
  }

  public async addBookingCorporate(
    payload: AddBookingRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addBookingCorporate(payload);
  }

  public async validateBookinbPhone(
    payload: AddBookingRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.ValidateBookingPhone(payload);
  }

  public getEstimationServiceLevel(
    params: RequestEstimationServiceLevelAgreement
  ): Promise<EstimationServiceLevelAgreement> {
    return this.repository.getEstimationServiceLevelAgreement(params);
  }

  public getEstimateSlaDetail(
    params: RequestEstimateSlaDetail
  ): Promise<EstimationServiceLevelAgreement> {
    return this.repository.getEstimateSlaDetail(params);
  }

  postToGetPosterProduct(
    payload: SubmitPosterProductApiRequest
  ): Promise<PosterProduct[]> {
    return this.repository.postToGetPosterProduct(payload);
  }

  public uploadHydraFile(
    payload: UploadHydraFileRequest
  ): Promise<ResponsePayload> {
    return this.repository.uploadHydraFile(payload);
  }

  public deleteHydraFile(
    payload: DeleteHydraFileRequest
  ): Promise<ResponsePayload> {
    return this.repository.deleteHydraFile(payload);
  }
}
