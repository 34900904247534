/* eslint-disable @typescript-eslint/camelcase */
import { injectable } from "tsyringe";
import { UniversalPrintApiRepository } from "@/app/infrastructures/repositories/api/UniversalPrintApiRepository";
import { PrintDataEntitas } from "@/domain/entities/Booking";

@injectable()
export class UniversalPrintPresenter {
  private repository: UniversalPrintApiRepository;

  constructor(repository: UniversalPrintApiRepository) {
    this.repository = repository;
  }

  public getDetailSttExternal(
    sttNo: string,
    clientId: string
  ): Promise<PrintDataEntitas> {
    return this.repository.getDetailSttExternal(sttNo, clientId);
  }
}
