import { Pagination } from "@/domain/entities/Pagination";

export class ListSttEntities {
  pagination: Pagination;
  data: ListSttData[];
  constructor(pagination: Pagination, data: ListSttData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ListSttData {
  sttId = 0;
  sttNo = "";
  sttNoRefExternal = "";
  sttTotalPiece = 0;
  sttDestinationDistrictName = "";
  sttDestinationCity = "";
  sttDestinationCityCode = "";
  sttChargeableWeight = 0;
  sttProductType = "";
  sttVolumeWeight = 0;
  sttSenderName = "";
  sttRecipientName = "";
  sttRecipientAddress = "";
  sttIsInsurance = false;
  sttInsuranceName = "";
  sttIsCod = false;
  sttCodFee = 0;
  sttTotalAmount = 0;
  sttTotalSurcharge = 0;
  sttShippingSurchargeRate = 0;
  sttTotalInsurance = 0;
  sttGoodsEstimationPrice = 0;
  createdAt = "";
  updatedAt = "";
  sttShippingSurchageRate = "";
  sttGoodsEstimatePrice = 0;
  sttRecipientPhone = 0;
  sttBmTaxRate = 0;
  sttPpnTaxRate = 0;
  sttPphTaxRate = 0;
  sttTotalGoodTax = 0;
  sttCommodityType = 0;
  sttBookedName = 0;
  sttBookedBy = 0;
  sttLastStatusId = 0;
  sttLastStatusIdDescription = 0;
  sttIsGoodTax = 0;
  sttDocumentSurchargeRate = 0;
  sttCommoditySurchargeRate = 0;
  sttHeavyweightSurchargeRate = 0;
  sttWoodpackingRate = 0;
  sttCodAmount = 0;
  sttPublishRate = 0;
  sttOriginDistrictRate = 0;
  sttDestinationDistrictRate = 0;
  sttShipmentId = "";
  sttClientId: any = null;
  sttIsPromo = false;
  sttTotalAmountAfterDiscount = 0;
  sttTotalAmountBeforeDiscount = 0;
  sttTotalDiscount = 0;
  sttForwardRate? = "";
  sttElexysNo? = "";
  constructor(fields?: Partial<ListSttData>) {
    Object.assign(this, fields);
  }
}

export class BagListEntities {
  data: BagListData[];
  constructor(data: BagListData[]) {
    this.data = data;
  }
}
export class BagListData {
  bagId = 0;
  bagCode = "";
  bagPartnerId = 0;
  bagPartnerCode = "";
  bagPartnerName = "";
  bagOriginCityCode = "";
  bagOriginCityName = "";
  bagOriginTransitCityCode = "";
  bagOriginTransitCityName = "";
  bagDestinationCityCode = "";
  bagDestinationCityName = "";
  bagTotalStt = 0;
  bagTotalGrossWeight = 0;
  bagTotalVolumeWeight = 0;
  bagTotalChargeableWeight = 0;
  bagTotalPiece = 0;
  bagStatusUpdatedAt = "";
  bagCreatedAt = "";
  bagCreatedBy = 0;
  bagCreatedName = "";
  bagUpdatedAt = "";
  bagUpdatedName = "";
  bagCustomGrossWeight = 0;
  bagCustomVolumeWeight = 0;
  bagCustomLength = 0;
  bagCustomWidth = 0;
  bagCustomHeight = 0;
  bagProductType = "";
  bagHubId = 0;
  bagHubName = "";
  bagCommodityGroup = "";
  constructor(fields?: Partial<BagListData>) {
    Object.assign(this, fields);
  }
}
