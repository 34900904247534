import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";
import { PosMapper } from "@/data/persistences/mappers/PosMapper";
import { PosRepositoryInterface } from "@/data/persistences/repositories/contracts/PosRepositoryInterface";
import { PosEntities } from "@/domain/entities/Pos";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class PosApiRepository implements PosRepositoryInterface {
  private service: ApiService;
  private mapper: PosMapper;
  private endpoints: Endpoints;

  constructor(service: ApiService, mapper: PosMapper, endpoints: Endpoints) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getListPos(params: RequestListPartner): Promise<PosEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListPos(params),
      {}
    );
    return this.mapper.convertListPosDataFromApi(resp);
  }
}
