import { formatPrice } from "@/app/infrastructures/misc/Utils";
import { QueryParamsEntities } from "./MainApp";
import { PaginationV2 } from "./Pagination";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";

export class TopupFeeConfiguration {
    topupFeeId: any = "";
    topupFeeAccountType = "";
    topupFee = 0;
    topupFeeStatus = "";
    topupFeeIsAllPos = false;
    topupFeePartners: Array<any> = [];
    topupFeeCreatedAt = "";
    topupFeeCreatedBy = "";
    topupFeeUpdatedAt = "";
    topupFeeUpdatedBy = "";

    constructor(fields?: Partial<TopupFeeConfiguration>) {
        Object.assign(this, fields);
    }

    get formatCreatedAt() {
        return formatDate(this.topupFeeCreatedAt);
    }

    get formatUpdatedAt() {
        return formatDate(this.topupFeeUpdatedAt);
    }

    get formatTopupFee() {
        return formatPrice(this.topupFee);
    }
}

export class RequestTopupFeeConfigurationList {
    page = 1;
    limit = 20;
    constructor(fields?: Partial<RequestTopupFeeConfigurationList>) {
        Object.assign(this, fields);
    }
    toQueryString(): string {
        return new QueryParamsEntities(this).queryString;
    }
}

export class TopupFeeConfigurationList {
    pagination = new PaginationV2();
    data = [new TopupFeeConfiguration()];
    constructor(fields?: Partial<TopupFeeConfigurationList>) {
        Object.assign(this, fields);
    }
}

