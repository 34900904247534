import { Pagination } from "@/domain/entities/Pagination";

export class TrackDeliveryEntities {
  pagination: Pagination;
  data: TrackDeliveryData[];
  constructor(pagination: Pagination, data: TrackDeliveryData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class TrackDeliveryData {
  sttId = 0;
  noResi = "";
  claimNo = "";
  shipmentId = "";
  product = "";
  chargeableWeight = 0;
  totalAmount = 0;
  consigneName = "";
  consigneAddress = "";
  status = "";
  isCod = false;
  isClaimAble = false;
  isClaimed = false;
  statusPerformance = "";
  isPromoRetail = false;
  totalDiscount = 0;
  totalTariffAfterDiscount = 0;
  totalTariffBeforeDiscount = 0;

  constructor(fields?: Partial<TrackDeliveryData>) {
    Object.assign(this, fields);
  }
}
