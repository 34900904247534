import { CLAIM } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const Claim = () =>
  import(/* webpackChunkName: "Claim" */ "@/app/ui/views/claim/index.vue");
const DetailClaim = () =>
  import(
    /* webpackChunkName: "Claim" */ "@/app/ui/views/claim/detail/detail.vue"
  );

export default [
  {
    path: "/klaim",
    component: Layout,
    redirect: "/klaim",
    name: "klaim",
    meta: {
      icon: "claim",
      children: false,
      title: "Klaim",
      name: "Klaim",
      permission: CLAIM.ENABLE
    },
    children: [
      {
        path: "/klaim",
        name: "klaim",
        component: Claim,
        meta: {
          header: false
        }
      }
    ]
  },
  {
    path: "/klaim",
    component: Layout,
    name: "detail-klaim",
    meta: {
      hidden: true,
      parent: "Klaim"
    },
    children: [
      {
        path: ":id",
        name: "detail-klaim",
        component: DetailClaim,
        meta: {
          title: "Klaim | Detail",
          header: true,
          permission: CLAIM.DETAIL
        }
      }
    ]
  }
];
