import { QueryParamsEntities } from "./MainApp";

export class TotalSTTPaidUnread {
  totalUnread = 0;
  lastOpenAt = "";

  constructor(fields?: Partial<TotalSTTPaidUnread>) {
    Object.assign(this, fields);
  }
}

export class RequestSttPaidUnread {
  cache = false; // cache api
  constructor(fields?: Partial<RequestSttPaidUnread>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
