import { injectable } from "tsyringe";
import { ShipmentIdList } from "@/domain/entities/Booking";
import { MiddlewareApiRepository } from "@/app/infrastructures/repositories/api/MiddlewareApiRepository";
import { MiddlewareList, MiddlewareDetail } from "@/domain/entities/Middleware";
import { RequestMiddlewareList, PayloadFormMiddleware } from "@/data/payload/api/MiddlewareApiRequest";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { AuthEntities } from "@/domain/entities/Auth";
@injectable()
export class MiddlewarePresenter {
  private repository: MiddlewareApiRepository;

  constructor(repo: MiddlewareApiRepository) {
    this.repository = repo;
  }

  public async getShipmentDetailMiddleWare(
    ticketCode: string
  ): Promise<ShipmentIdList> {
    return await this.repository.getShipmentDetailMiddleWare(ticketCode);
  }

  public async getListMiddleware(params: {
    type: string
    queries: RequestMiddlewareList
  }): Promise<MiddlewareList> {
    return await this.repository.getListMiddleware(params);
  }

  public formMiddleware(params: {
    method: any,
    type: string,
    payload: PayloadFormMiddleware
  }): Promise<ResponsePayloadV2> {
    return this.repository.formMiddleware(params);
  }

  public getDetailMiddleware(params: {
    type: string,
    id: number
  }): Promise<MiddlewareDetail> {
    return this.repository.getDetailMiddleware(params);
  }

  public getTokenMiddleware(): Promise<AuthEntities> {
    return this.repository.getTokenMiddleware();
  }
}
