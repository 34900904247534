import { DependencyContainer } from "tsyringe";
import { AuthPresenter } from "@/app/ui/presenters/AuthPresenter";
import { AuthApiRepository } from "../repositories/api/AuthApiRepository";
import { BulkPresenter } from "@/app/ui/presenters/BulkPresenter";
import { BulkApiRepository } from "../repositories/api/BulkApiRepository";
import { AccountPresenter } from "@/app/ui/presenters/AccountPresenter";
import { AccountApiRepository } from "../repositories/api/AccountApiRepository";
import { LocationPresenter } from "@/app/ui/presenters/LocationPresenter";
import { LocationApiRepository } from "../repositories/api/LocationApiRepository";
import { UserPresenter } from "@/app/ui/presenters/UserPresenter";
import { UserApiRepository } from "../repositories/api/UserApiRepository";
import { NotificationPresenter } from "@/app/ui/presenters/NotificationPresenter";
import { NotificationApiRepository } from "../repositories/api/NotificationApiRepository";
import { VendorPresenter } from "@/app/ui/presenters/VendorPresenter";
import { VendorApiRepository } from "../repositories/api/VendorApiRepository";
import { EmbargoPresenter } from "@/app/ui/presenters/EmbargoPresenter";
import { EmbargoApiRepository } from "../repositories/api/EmbargoApiRepository";
import { LegPresenter } from "@/app/ui/presenters/LegPresenter";
import { LegApiRepository } from "../repositories/api/LegApiRepository";
import { RoutePresenter } from "@/app/ui/presenters/RoutePresenter";
import { RouteApiRepository } from "../repositories/api/RouteApiRepository";
import { TransportPresenter } from "@/app/ui/presenters/TransportPresenter";
import { TransportApiRepository } from "@/app/infrastructures/repositories/api/TransportApiRepository";
import { BalanceLimitPresenter } from "@/app/ui/presenters/BalanceLimitPresenter";
import { BalanceLimitApiRepository } from "@/app/infrastructures/repositories/api/BalanceLimitApiRepository";
import { CommodityPresenter } from "@/app/ui/presenters/CommodityPresenter";
import { CommodityApiRepository } from "../repositories/api/CommodityApiRepository";
import { LocationSearchPresenter } from "@/app/ui/presenters/LocationSearchPresenter";
import { LocationSearchApiRepository } from "../repositories/api/LocationSearchApiRepository";
import { ConfigPricePresenter } from "@/app/ui/presenters/ConfigPricePresenter";
import { ConfigPriceApiRepository } from "../repositories/api/ConfigPriceApiRepository";
import { ConfigPriceWoodpackingPresenter } from "@/app/ui/presenters/ConfigPriceWoodpackingPresenter";
import { ConfigPriceWoodpackingApiRepository } from "../repositories/api/ConfigWoodpackingApiRepository";
import { HeavyWeightSurchargePresenter } from "@/app/ui/presenters/HeavyWeightSurchargePresenter";
import { HeavyWeightSurchargeApiRepository } from "@/app/infrastructures/repositories/api/HeavyWeightSurchargeApiRepository";
import { InsurancePresenter } from "@/app/ui/presenters/InsurancePresenter";
import { InsuranceApiRepository } from "../repositories/api/InsuranceApiRepository";
import { CommoditySurchargePresenter } from "@/app/ui/presenters/CommoditySurchargePresenter";
import { CommoditySurchargeApiRepository } from "../repositories/api/CommoditySurchargeApiRepository";
import { BaseRatesPresenter } from "@/app/ui/presenters/BaseRatesPresenter";
import { BaseRatesApiRepository } from "@/app/infrastructures/repositories/api/BaseRatesApiRepository";
import { ExchangeRatePresenter } from "@/app/ui/presenters/ExchangeRatePresenter";
import { ExchangeRateApiRepository } from "@/app/infrastructures/repositories/api/ExchangeRateRepositoryApi";
import { GoodsTaxPresenter } from "@/app/ui/presenters/GoodsTaxPresenter";
import { GoodsTaxApiRepository } from "../repositories/api/GoodsTaxApiRepository";
import { ClientApiRepository } from "../repositories/api/ClientApiRepository";
import { ClientPresenter } from "@/app/ui/presenters/ClientPresenter";
import { ClientRegistrationPresenter } from "@/app/ui/presenters/ClientRegistrationPresenter";
import { ClientRegistrationApiRepository } from "@/app/infrastructures/repositories/api/ClientRegistrationApiRepository";
import { AnnouncementPresenter } from "@/app/ui/presenters/AnnouncementPresenter";
import { AnnouncementApiRepository } from "../repositories/api/AnnouncementApiRepository";
import { ClientManagementPresenter } from "@/app/ui/presenters/ClientManagementPresenter";
import { ClientManagementApiRepository } from "@/app/infrastructures/repositories/api/ClientManagementApiRepository";
import { ManualSTTPresenter } from "@/app/ui/presenters/ManualSTTPresenter";
import { ManualSTTApiRepository } from "../repositories/api/ManualSTTApiRepository";
import { TariffApiRepository } from "../repositories/api/TariffApiRepository";
import { TariffPresenter } from "@/app/ui/presenters/TariffPresenter";
import { BookingApiRepository } from "../repositories/api/BookingApiRepository";
import { BookingPresenter } from "@/app/ui/presenters/BookingPresenter";
import { ShipmentBookingApiRepository } from "@/app/infrastructures/repositories/api/ShipmentBookingApiRepository";
import { ShipmentBookingPresenter } from "@/app/ui/presenters/ShipmentBookingPresenter";
import { ListSttApiRepository } from "@/app/infrastructures/repositories/api/ListSttApiRepository";
import { ListSttPresenter } from "@/app/ui/presenters/ListSttPresenter";
import { CnManifestApiRepository } from "@/app/infrastructures/repositories/api/CnManifestApiRepository";
import { CnManifestPresenter } from "@/app/ui/presenters/CnManifestPresenter";
import { PickupManifestApiRepository } from "@/app/infrastructures/repositories/api/PickupManifestApiRepository";
import { PickupManifestPresenter } from "@/app/ui/presenters/PickupManifestPresenter";
import { VehiclePresenter } from "@/app/ui/presenters/VehiclePresenter";
import { VehicleApiRepository } from "../repositories/api/VehicleApiRepository";
import { StiSCPresenter } from "@/app/ui/presenters/StiSCPresenter";
import { StiSCApiRepository } from "../repositories/api/StiSCApiRepository";
import { STIPresenter } from "@/app/ui/presenters/STIPresenter";
import { STIApiRepository } from "../repositories/api/STIApiRepository";
import { BaggingPresenter } from "@/app/ui/presenters/BaggingPresenter";
import { BaggingApiRepository } from "../repositories/api/BaggingApiRepository";
import { StiDestPresenter } from "@/app/ui/presenters/StiDestPresenter";
import { StiDestApiRepository } from "../repositories/api/StiDestApiRepository";
import { StiDestScPresenter } from "@/app/ui/presenters/StiDestScPresenter";
import { StiDestScApiRepository } from "../repositories/api/StiDestScApiRepository";
import { HandoverPresenter } from "@/app/ui/presenters/HandoverPresenter";
import { HandoverApiRepository } from "../repositories/api/HandoverApiRepository";
import { ShortlandPresenter } from "@/app/ui/presenters/ShortlandPresenter";
import { ShortlandApiRepository } from "../repositories/api/ShortlandApiRepository";
import { PodDexPresenter } from "@/app/ui/presenters/PodDexPresenter";
import { PodDexApiRepository } from "../repositories/api/PodDexApiRepository";
import { DeliveryManifestPresenter } from "@/app/ui/presenters/DeliveryManifestPresenter";
import { DeliveryManifestApiRepository } from "../repositories/api/DeliveryManifestApiRepository";
import { PosPresenter } from "@/app/ui/presenters/PosPresenter";
import { PosApiRepository } from "../repositories/api/PosApiRepository";
import { ConsolidatorPresenter } from "@/app/ui/presenters/ConsolidatorPresenter";
import { ConsolidatorApiRepository } from "../repositories/api/ConsolidatorApiRepository";
import { PartnerSubconsolePresenter } from "@/app/ui/presenters/PartnerSubconsolePresenter";
import { PartnerSubconsoleApiRepository } from "../repositories/api/PartnerSubconsoleApiRepository";
import { PartnerPresenter } from "@/app/ui/presenters/PartnerPresenter";
import { PartnerApiRepository } from "@/app/infrastructures/repositories/api/PartnerApiRepository";
import { CustomProcessPresenter } from "@/app/ui/presenters/CustomProcessPresenter";
import { CustomProcessApiRepository } from "@/app/infrastructures/repositories/api/CustomProcessApiRepository";
import { PosClientPresenter } from "@/app/ui/presenters/PosClientPresenter";
import { PosClientApiRepository } from "../repositories/api/PosClientApiRepository";
import { BulkBookingPresenter } from "@/app/ui/presenters/BulkBookingPresenter";
import { BulkBookingApiRepository } from "../repositories/api/BulkBookingApiRepository";
import { AdjustmentPenaltyPresenter } from "@/app/ui/presenters/AdjustmentPenaltyPresenter";
import { AdjustmentPenaltyApiRepository } from "../repositories/api/AdjustmentPenaltyApiRepository";
import { RefundConfigurationPresenter } from "@/app/ui/presenters/RefundConfigurationPresenter";
import { RefundConfigurationApiRepository } from "../repositories/api/RefundConfigurationApiRepository";
import { AuthAlgoPresenter } from "@/app/ui/presenters/AuthAlgoPresenter";
import { AuthAlgoApiRepository } from "../repositories/api/AuthAlgoApiRepository";
import { PosFavoritePresenter } from "@/app/ui/presenters/PosFavoritePresenter";
import { PosFavoriteApiRepository } from "../repositories/api/PosFavoriteApiRepository";
import { BalanceApiRepository } from "@/app/infrastructures/repositories/api/BalanceApiRepository";
import { BalancePresenter } from "@/app/ui/presenters/BalancePresenter";
import { CodIncomePresenter } from "@/app/ui/presenters/CodIncomePresenter";
import { CodIncomeApiRepository } from "../repositories/api/CodIncomeApiRepository";
import { TrackingPresenter } from "@/app/ui/presenters/TrackingPresenter";
import { TrackingApiRepository } from "../repositories/api/TrackingApiRepository";
import { CargoPresenter } from "@/app/ui/presenters/CargoPresenter";
import { CargoApiRepository } from "../repositories/api/CargoApiRepository";
import { BeneficiaryApiRepository } from "../repositories/api/BeneficiaryApiRepository";
import { BeneficiaryPresenter } from "@/app/ui/presenters/BeneficiaryPresenter";
import { BulkBookingCommissionApiRepository } from "../repositories/api/BulkBookingCommissionApiRepository";
import { BulkBookingCommissionPresenter } from "@/app/ui/presenters/BulkBookingCommissionPresenter";
import { BookingCommissionPresenter } from "@/app/ui/presenters/BookingCommissionPresenter";
import { BookingCommissionApiRepository } from "../repositories/api/BookingCommissionApiRepository";
import { ManualDeductAddSaldoPresenter } from "@/app/ui/presenters/ManualDeductAddSaldoPresenter";
import { ManualDeductAddSaldoRepository } from "../repositories/api/ManualDeductAddSaldoRepositoryInterface";
import { TopUpVerificationPresenter } from "@/app/ui/presenters/TopUpVerificationPresenter";
import { TopUpVerificationApiRepository } from "../repositories/api/TopUpVerificationApiRepository";
import { TopupApprovalPresenter } from "@/app/ui/presenters/TopupApprovalPresenter";
import { TopupApprovalApiRepository } from "../repositories/api/TopupApprovalApiRepository";
import { BulkDownloadPresenter } from "@/app/ui/presenters/BulkDownloadPresenter";
import { BulkDownloadApiRepository } from "../repositories/api/BulkDownloadApiRepository";
import { DeliveryTieringPolicyPresenter } from "@/app/ui/presenters/DeliveryTieringPolicyPresenter";
import { DeliveryTieringPolicyApiRepository } from "../repositories/api/DeliveryTieringPolicyApiRepository";
import { UpdateDeliveryApiRepository } from "@/app/infrastructures/repositories/api/UpdateDeliveryApiRepository";
import { UpdateDeliveryPresenter } from "@/app/ui/presenters/UpdateDeliveryPresenter";
import { MessagingServiceConfigurationApiRepository } from "@/app/infrastructures/repositories/api/MessagingServiceConfigurationApiRepository";
import { MessagingServiceConfigurationPresenter } from "@/app/ui/presenters/MessagingServiceConfigurationPresenter";
import { ReportApiRepository } from "@/app/infrastructures/repositories/api/ReportApiRepository";
import { ReportPresenter } from "@/app/ui/presenters/ReportPresenter";
import { NGenPresenter } from "@/app/ui/presenters/NGenPresenter";
import { NGenApiRepository } from "../repositories/api/NGenApiRepository";
import { AuthNGenPresenter } from "@/app/ui/presenters/AuthNGenPresenter";
import { AuthNGenApiRepository } from "../repositories/api/AuthNGenApiRepository";
import { IntegrationMonitoringPresenter } from "@/app/ui/presenters/IntegrationMonitoringPresenter";
import { IntegrationMonitoringApiRepository } from "../repositories/api/IntegrationMonitoringApiRepository";
import { MiddlewareApiRepository } from "@/app/infrastructures/repositories/api/MiddlewareApiRepository";
import { MiddlewarePresenter } from "@/app/ui/presenters/MiddlewarePresenter";
import { InstantBookingPresenter } from "@/app/ui/presenters/InstantBookingPresenter";
import { InstantBookingApiRepository } from "../repositories/api/InstantBookingApiRepository";
import { UniversalPrintPresenter } from "@/app/ui/presenters/UniversalPrintPresenter";
import { UniversalPrintApiRepository } from "@/app/infrastructures/repositories/api/UniversalPrintApiRepository";
import { DashboardPresenter } from "@/app/ui/presenters/DashboardPresenter";
import { DashboardApiRepository } from "../repositories/api/DashboardApiRepository";
import { EmailPresenter } from "@/app/ui/presenters/EmailPresenter";
import { EmailApiRepository } from "@/app/infrastructures/repositories/api/EmailApiRepository";
import { BalanceTransactionPresenter } from "@/app/ui/presenters/BalanceTransactionPresenter";
import { BalanceTransactionApiRepository } from "@/app/infrastructures/repositories/api/BalanceTransactionApiRepository";
import { LastBalancePresenter } from "@/app/ui/presenters/LastBalancePresenter";
import { LastBalanceApiRepository } from "@/app/infrastructures/repositories/api/LastBalanceApiRepository";
import { ForwardBookingCommissionPresenter } from "@/app/ui/presenters/ForwardBookingCommissionPresenter";
import { ForwardBookingCommissionApiRepository } from "@/app/infrastructures/repositories/api/ForwardBookingCommissionApiRepository";
import { PaymentPresenter } from "@/app/ui/presenters/PaymentPresenter";
import { PaymentApiRepository } from "@/app/infrastructures/repositories/api/PaymentApiRepository";
import { IncomingOutgoingPresenter } from "@/app/ui/presenters/IncomingOutgoingPresenter";
import { IncomingOutgoingApiRepository } from "../repositories/api/IncomingOutgoingApiRepository";
import { ProgressiveCommissionConfigurationPresenter } from "@/app/ui/presenters/ProgressiveCommissionConfigurationPresenter";
import { ProgressiveCommissionConfigurationApiRepository } from "@/app/infrastructures/repositories/api/ProgressiveCommissionConfigurationApiRepository";
import { STTPaidUnpaidPresenter } from "@/app/ui/presenters/STTPaidUnpaidPresenter";
import { STTPaidUnpaidApiRepository } from "../repositories/api/STTPaidUnpaidApiRepository";
import { UniversalPresenter } from "@/app/ui/presenters/UniversalPresenter";
import { UniversalApiRepository } from "../repositories/api/UniversalApiRepository";
import { ProgressiveCommissionDashboardPresenter } from "@/app/ui/presenters/ProgressiveCommissionDashboardPresenter";
import { ProgressiveCommissionDashboardApiRepository } from "../repositories/api/ProgressiveCommissionDashboardApiRepository";
import { BaggingGroupingPresenter } from "@/app/ui/presenters/BaggingGroupingPresenter";
import { BaggingGroupingApiRepository } from "../repositories/api/BaggingGroupingApiRepository";
import { ClaimPresenter } from "@/app/ui/presenters/ClaimPresenter";
import { ClaimApiRepository } from "../repositories/api/ClaimApiRepository";
import { TrackDeliveryPresenter } from "@/app/ui/presenters/TrackDeliveryPresenter";
import { TrackDeliveryApiRepository } from "../repositories/api/TrackDeliveryApiRepository";
import { SaldoPoinPresenter } from "@/app/ui/presenters/SaldoPoinPresenter";
import { SaldoPoinApiRepository } from "../repositories/api/SaldoPoinApiRepository";
import { CargoConfigurationPresenter } from "@/app/ui/presenters/CargoConfigurationPresenter";
import { CargoConfigurationApiRepository } from "@/app/infrastructures/repositories/api/CargoConfigurationApiRepository";
import { ServiceDelayPresenter } from "@/app/ui/presenters/ServiceDelayPresenter";
import { ServiceDelayApiRepository } from "../repositories/api/ServiceDelayApiRepository";
import { ConfigPriceCodPresenter } from "@/app/ui/presenters/ConfigPriceCodPresenter";
import { ConfigPriceCodApiRepository } from "../repositories/api/ConfigPriceCodApiRepository";
import { CourierManagementPresenter } from "@/app/ui/presenters/CourierManagementPresenter";
import { CourierManagementApiRepository } from "../repositories/api/CourierManagementApiRepository";
import { CodConfigurationPresenter } from "@/app/ui/presenters/CodConfigurationPresenter";
import { CodConfigurationApiRepository } from "../repositories/api/CodConfigurationApiRepository";
import { BalanceMinusPenaltyPresenter } from "@/app/ui/presenters/BalanceMinusPenaltyPresenter";
import { BalanceMinusPenaltyApiRepository } from "../repositories/api/BalanceMinusPenaltyApiRepository";
import { HoldBalanceHistoryPresenter } from "@/app/ui/presenters/HoldBalanceHistoryPresenter";
import { HoldBalanceHistoryApiRepository } from "../repositories/api/HoldBalanceHistoryApiRepository";
import { ConfigFakeDexIndicationPresenter } from "@/app/ui/presenters/ConfigFakeDexIndicationPresenter";
import { ConfigFakeDexIndicationApiRepository } from "../repositories/api/ConfigFakeDexIndicationApiRepository";
import { DexAssessmentPresenter } from "@/app/ui/presenters/DexAssessmentPresenter";
import { DexAssessmentApiRepository } from "../repositories/api/DexAssessmentApiRepository";
import { UrgentDeliveryPresenter } from "@/app/ui/presenters/UrgentDeliveryPresenter";
import { UrgentDeliveryApiRepository } from "../repositories/api/UrgentDeliveryRepository";
import { UrgentDeliveryManagementPresenter } from "@/app/ui/presenters/UrgentDeliveryManagementPresenter";
import { UrgentDeliveryManagementApiRepository } from "../repositories/api/UrgentDeliveryManagementApiRepository";
import { InternationalDocumentPresenter } from "@/app/ui/presenters/InternationalDocumentPresenter";
import { InternationalDocumentApiRepository } from "../repositories/api/InternationalDocumentApiRepository";
import { CustomerManagementPresenter } from "@/app/ui/presenters/CustomerManagementPresenter";
import { CustomerManagementApiRepository } from "../repositories/api/CustomerManagementApiRepository";
import { ListPickupPresenter } from "@/app/ui/presenters/ListPickupPresenter";
import { ListPickupApiRepository } from "../repositories/api/ListPickupApiRepository";
import { CodReconcilePresenter } from "@/app/ui/presenters/CodReconcilePresenter";
import { CodReconcileApiRepository } from "../repositories/api/CodReconcileApiRepository";
import { CollectibleCardPresenter } from "@/app/ui/presenters/CollectibleCardPresenter";
import { CollectibleCardApiRepository } from "@/app/infrastructures/repositories/api/CollectibleCardApiRepository";

export class PresenterModule {
  public static init(container: DependencyContainer) {
    container.register<AuthPresenter>(AuthPresenter, {
      useFactory: d => {
        return new AuthPresenter(d.resolve(AuthApiRepository));
      }
    });
    container.register<BulkPresenter>(BulkPresenter, {
      useFactory: d => {
        return new BulkPresenter(d.resolve(BulkApiRepository));
      }
    });
    container.register<AccountPresenter>(AccountPresenter, {
      useFactory: d => {
        return new AccountPresenter(d.resolve(AccountApiRepository));
      }
    });
    container.register<LocationPresenter>(LocationPresenter, {
      useFactory: d => {
        return new LocationPresenter(d.resolve(LocationApiRepository));
      }
    });
    container.register<NotificationPresenter>(NotificationPresenter, {
      useFactory: d => {
        return new NotificationPresenter(d.resolve(NotificationApiRepository));
      }
    });
    container.register<UserPresenter>(UserPresenter, {
      useFactory: d => {
        return new UserPresenter(d.resolve(UserApiRepository));
      }
    });
    container.register<VendorPresenter>(VendorPresenter, {
      useFactory: d => {
        return new VendorPresenter(d.resolve(VendorApiRepository));
      }
    });
    container.register<CommodityPresenter>(CommodityPresenter, {
      useFactory: d => {
        return new CommodityPresenter(d.resolve(CommodityApiRepository));
      }
    });
    container.register<EmbargoPresenter>(EmbargoPresenter, {
      useFactory: d => {
        return new EmbargoPresenter(d.resolve(EmbargoApiRepository));
      }
    });
    container.register<LocationSearchPresenter>(LocationSearchPresenter, {
      useFactory: d => {
        return new LocationSearchPresenter(
          d.resolve(LocationSearchApiRepository)
        );
      }
    });
    container.register<LegPresenter>(LegPresenter, {
      useFactory: d => {
        return new LegPresenter(d.resolve(LegApiRepository));
      }
    });
    container.register<RoutePresenter>(RoutePresenter, {
      useFactory: d => {
        return new RoutePresenter(d.resolve(RouteApiRepository));
      }
    });
    container.register<TransportPresenter>(TransportPresenter, {
      useFactory: d => {
        return new TransportPresenter(d.resolve(TransportApiRepository));
      }
    });
    container.register<BalanceLimitPresenter>(BalanceLimitPresenter, {
      useFactory: d => {
        return new BalanceLimitPresenter(d.resolve(BalanceLimitApiRepository));
      }
    });
    container.register<ConfigPricePresenter>(ConfigPricePresenter, {
      useFactory: d => {
        return new ConfigPricePresenter(d.resolve(ConfigPriceApiRepository));
      }
    });
    container.register<ConfigPriceWoodpackingPresenter>(
      ConfigPriceWoodpackingPresenter,
      {
        useFactory: d => {
          return new ConfigPriceWoodpackingPresenter(
            d.resolve(ConfigPriceWoodpackingApiRepository)
          );
        }
      }
    );
    container.register<HeavyWeightSurchargePresenter>(
      HeavyWeightSurchargePresenter,
      {
        useFactory: d => {
          return new HeavyWeightSurchargePresenter(
            d.resolve(HeavyWeightSurchargeApiRepository)
          );
        }
      }
    );
    container.register<InsurancePresenter>(InsurancePresenter, {
      useFactory: d => {
        return new InsurancePresenter(d.resolve(InsuranceApiRepository));
      }
    });
    container.register<CommoditySurchargePresenter>(
      CommoditySurchargePresenter,
      {
        useFactory: d => {
          return new CommoditySurchargePresenter(
            d.resolve(CommoditySurchargeApiRepository)
          );
        }
      }
    );
    container.register<BaseRatesPresenter>(BaseRatesPresenter, {
      useFactory: d => {
        return new BaseRatesPresenter(d.resolve(BaseRatesApiRepository));
      }
    });
    container.register<ExchangeRatePresenter>(ExchangeRatePresenter, {
      useFactory: d => {
        return new ExchangeRatePresenter(d.resolve(ExchangeRateApiRepository));
      }
    });
    container.register<GoodsTaxPresenter>(GoodsTaxPresenter, {
      useFactory: d => {
        return new GoodsTaxPresenter(d.resolve(GoodsTaxApiRepository));
      }
    });
    container.register<ClientPresenter>(ClientPresenter, {
      useFactory: d => {
        return new ClientPresenter(d.resolve(ClientApiRepository));
      }
    });
    container.register<ClientRegistrationPresenter>(
      ClientRegistrationPresenter,
      {
        useFactory: d => {
          return new ClientRegistrationPresenter(
            d.resolve(ClientRegistrationApiRepository)
          );
        }
      }
    );
    container.register<AnnouncementPresenter>(AnnouncementPresenter, {
      useFactory: d => {
        return new AnnouncementPresenter(d.resolve(AnnouncementApiRepository));
      }
    });
    container.register<ClientManagementPresenter>(ClientManagementPresenter, {
      useFactory: d => {
        return new ClientManagementPresenter(
          d.resolve(ClientManagementApiRepository)
        );
      }
    });
    container.register<ManualSTTPresenter>(ManualSTTPresenter, {
      useFactory: d => {
        return new ManualSTTPresenter(d.resolve(ManualSTTApiRepository));
      }
    });
    container.register<TariffPresenter>(TariffPresenter, {
      useFactory: d => {
        return new TariffPresenter(d.resolve(TariffApiRepository));
      }
    });
    container.register<BookingPresenter>(BookingPresenter, {
      useFactory: d => {
        return new BookingPresenter(d.resolve(BookingApiRepository));
      }
    });
    container.register<ShipmentBookingPresenter>(ShipmentBookingPresenter, {
      useFactory: d => {
        return new ShipmentBookingPresenter(
          d.resolve(ShipmentBookingApiRepository)
        );
      }
    });
    container.register<ListSttPresenter>(ListSttPresenter, {
      useFactory: d => {
        return new ListSttPresenter(d.resolve(ListSttApiRepository));
      }
    });
    container.register<CnManifestPresenter>(CnManifestPresenter, {
      useFactory: d => {
        return new CnManifestPresenter(d.resolve(CnManifestApiRepository));
      }
    });
    container.register<PickupManifestPresenter>(PickupManifestPresenter, {
      useFactory: d => {
        return new PickupManifestPresenter(
          d.resolve(PickupManifestApiRepository)
        );
      }
    });
    container.register<CnManifestPresenter>(CnManifestPresenter, {
      useFactory: d => {
        return new CnManifestPresenter(d.resolve(CnManifestApiRepository));
      }
    });
    container.register<VehiclePresenter>(VehiclePresenter, {
      useFactory: d => {
        return new VehiclePresenter(d.resolve(VehicleApiRepository));
      }
    });
    container.register<StiSCPresenter>(StiSCPresenter, {
      useFactory: d => {
        return new StiSCPresenter(d.resolve(StiSCApiRepository));
      }
    });
    container.register<STIPresenter>(STIPresenter, {
      useFactory: d => {
        return new STIPresenter(d.resolve(STIApiRepository));
      }
    });
    container.register<BaggingPresenter>(BaggingPresenter, {
      useFactory: d => {
        return new BaggingPresenter(d.resolve(BaggingApiRepository));
      }
    });
    container.register<StiDestPresenter>(StiDestPresenter, {
      useFactory: d => {
        return new StiDestPresenter(d.resolve(StiDestApiRepository));
      }
    });
    container.register<StiDestScPresenter>(StiDestScPresenter, {
      useFactory: d => {
        return new StiDestScPresenter(d.resolve(StiDestScApiRepository));
      }
    });
    container.register<HandoverPresenter>(HandoverPresenter, {
      useFactory: d => {
        return new HandoverPresenter(d.resolve(HandoverApiRepository));
      }
    });
    container.register<ShortlandPresenter>(ShortlandPresenter, {
      useFactory: d => {
        return new ShortlandPresenter(d.resolve(ShortlandApiRepository));
      }
    });
    container.register<DeliveryManifestPresenter>(DeliveryManifestPresenter, {
      useFactory: d => {
        return new DeliveryManifestPresenter(
          d.resolve(DeliveryManifestApiRepository)
        );
      }
    });
    container.register<PodDexPresenter>(PodDexPresenter, {
      useFactory: d => {
        return new PodDexPresenter(d.resolve(PodDexApiRepository));
      }
    });
    container.register<PosPresenter>(PosPresenter, {
      useFactory: d => {
        return new PosPresenter(d.resolve(PosApiRepository));
      }
    });
    container.register<ConsolidatorPresenter>(ConsolidatorPresenter, {
      useFactory: d => {
        return new ConsolidatorPresenter(d.resolve(ConsolidatorApiRepository));
      }
    });
    container.register<PartnerSubconsolePresenter>(PartnerSubconsolePresenter, {
      useFactory: d => {
        return new PartnerSubconsolePresenter(
          d.resolve(PartnerSubconsoleApiRepository)
        );
      }
    });
    container.register<PartnerPresenter>(PartnerPresenter, {
      useFactory: d => {
        return new PartnerPresenter(d.resolve(PartnerApiRepository));
      }
    });
    container.register<PosClientPresenter>(PosClientPresenter, {
      useFactory: d => {
        return new PosClientPresenter(d.resolve(PosClientApiRepository));
      }
    });
    container.register<CustomProcessPresenter>(CustomProcessPresenter, {
      useFactory: d => {
        return new CustomProcessPresenter(
          d.resolve(CustomProcessApiRepository)
        );
      }
    });
    container.register<BulkBookingPresenter>(BulkBookingPresenter, {
      useFactory: d => {
        return new BulkBookingPresenter(d.resolve(BulkBookingApiRepository));
      }
    });
    container.register<AdjustmentPenaltyPresenter>(AdjustmentPenaltyPresenter, {
      useFactory: d => {
        return new AdjustmentPenaltyPresenter(
          d.resolve(AdjustmentPenaltyApiRepository)
        );
      }
    });
    container.register<RefundConfigurationPresenter>(
      RefundConfigurationPresenter,
      {
        useFactory: d => {
          return new RefundConfigurationPresenter(
            d.resolve(RefundConfigurationApiRepository)
          );
        }
      }
    );
    container.register<BalancePresenter>(BalancePresenter, {
      useFactory: d => {
        return new BalancePresenter(d.resolve(BalanceApiRepository));
      }
    });
    container.register<CodIncomePresenter>(CodIncomePresenter, {
      useFactory: d => {
        return new CodIncomePresenter(d.resolve(CodIncomeApiRepository));
      }
    });
    container.register<TrackingPresenter>(TrackingPresenter, {
      useFactory: d => {
        return new TrackingPresenter(d.resolve(TrackingApiRepository));
      }
    });
    container.register<CargoPresenter>(CargoPresenter, {
      useFactory: d => {
        return new CargoPresenter(d.resolve(CargoApiRepository));
      }
    });
    container.register<BeneficiaryPresenter>(BeneficiaryPresenter, {
      useFactory: d => {
        return new BeneficiaryPresenter(d.resolve(BeneficiaryApiRepository));
      }
    });
    container.register<ManualDeductAddSaldoPresenter>(
      ManualDeductAddSaldoPresenter,
      {
        useFactory: d => {
          return new ManualDeductAddSaldoPresenter(
            d.resolve(ManualDeductAddSaldoRepository)
          );
        }
      }
    );
    container.register<BulkBookingCommissionPresenter>(
      BulkBookingCommissionPresenter,
      {
        useFactory: d => {
          return new BulkBookingCommissionPresenter(
            d.resolve(BulkBookingCommissionApiRepository)
          );
        }
      }
    );
    container.register<BookingCommissionPresenter>(BookingCommissionPresenter, {
      useFactory: d => {
        return new BookingCommissionPresenter(
          d.resolve(BookingCommissionApiRepository)
        );
      }
    });
    container.register<TopUpVerificationPresenter>(TopUpVerificationPresenter, {
      useFactory: d => {
        return new TopUpVerificationPresenter(
          d.resolve(TopUpVerificationApiRepository)
        );
      }
    });
    container.register<TopupApprovalPresenter>(TopupApprovalPresenter, {
      useFactory: d => {
        return new TopupApprovalPresenter(
          d.resolve(TopupApprovalApiRepository)
        );
      }
    });
    container.register<BulkDownloadPresenter>(BulkDownloadPresenter, {
      useFactory: d => {
        return new BulkDownloadPresenter(d.resolve(BulkDownloadApiRepository));
      }
    });
    container.register<DeliveryTieringPolicyPresenter>(
      DeliveryTieringPolicyPresenter,
      {
        useFactory: d => {
          return new DeliveryTieringPolicyPresenter(
            d.resolve(DeliveryTieringPolicyApiRepository)
          );
        }
      }
    );
    container.register<UpdateDeliveryPresenter>(UpdateDeliveryPresenter, {
      useFactory: d => {
        return new UpdateDeliveryPresenter(
          d.resolve(UpdateDeliveryApiRepository)
        );
      }
    });
    container.register<MessagingServiceConfigurationPresenter>(
      MessagingServiceConfigurationPresenter,
      {
        useFactory: d => {
          return new MessagingServiceConfigurationPresenter(
            d.resolve(MessagingServiceConfigurationApiRepository)
          );
        }
      }
    );
    container.register<ReportPresenter>(ReportPresenter, {
      useFactory: d => {
        return new ReportPresenter(d.resolve(ReportApiRepository));
      }
    });
    container.register<IntegrationMonitoringPresenter>(
      IntegrationMonitoringPresenter,
      {
        useFactory: d => {
          return new IntegrationMonitoringPresenter(
            d.resolve(IntegrationMonitoringApiRepository)
          );
        }
      }
    );
    container.register<InstantBookingPresenter>(InstantBookingPresenter, {
      useFactory: d => {
        return new InstantBookingPresenter(
          d.resolve(InstantBookingApiRepository)
        );
      }
    });
    container.register<DashboardPresenter>(DashboardPresenter, {
      useFactory: d => {
        return new DashboardPresenter(d.resolve(DashboardApiRepository));
      }
    });
    container.register<EmailPresenter>(EmailPresenter, {
      useFactory: d => {
        return new EmailPresenter(d.resolve(EmailApiRepository));
      }
    });
    container.register<BalanceTransactionPresenter>(
      BalanceTransactionPresenter,
      {
        useFactory: d => {
          return new BalanceTransactionPresenter(
            d.resolve(BalanceTransactionApiRepository)
          );
        }
      }
    );
    container.register<LastBalancePresenter>(LastBalancePresenter, {
      useFactory: d => {
        return new LastBalancePresenter(d.resolve(LastBalanceApiRepository));
      }
    });
    container.register<ForwardBookingCommissionPresenter>(
      ForwardBookingCommissionPresenter,
      {
        useFactory: d => {
          return new ForwardBookingCommissionPresenter(
            d.resolve(ForwardBookingCommissionApiRepository)
          );
        }
      }
    );
    container.register<IncomingOutgoingPresenter>(IncomingOutgoingPresenter, {
      useFactory: d => {
        return new IncomingOutgoingPresenter(
          d.resolve(IncomingOutgoingApiRepository)
        );
      }
    });
    container.register<ProgressiveCommissionConfigurationPresenter>(
      ProgressiveCommissionConfigurationPresenter,
      {
        useFactory: d => {
          return new ProgressiveCommissionConfigurationPresenter(
            d.resolve(ProgressiveCommissionConfigurationApiRepository)
          );
        }
      }
    );

    container.register<BaggingGroupingPresenter>(BaggingGroupingPresenter, {
      useFactory: d => {
        return new BaggingGroupingPresenter(
          d.resolve(BaggingGroupingApiRepository)
        );
      }
    });

    container.register<ClaimPresenter>(ClaimPresenter, {
      useFactory: d => {
        return new ClaimPresenter(d.resolve(ClaimApiRepository));
      }
    });

    container.register<ServiceDelayPresenter>(ServiceDelayPresenter, {
      useFactory: d => {
        return new ServiceDelayPresenter(d.resolve(ServiceDelayApiRepository));
      }
    });

    container.register<ConfigPriceCodPresenter>(ConfigPriceCodPresenter, {
      useFactory: d => {
        return new ConfigPriceCodPresenter(
          d.resolve(ConfigPriceCodApiRepository)
        );
      }
    });

    container.register<CourierManagementPresenter>(CourierManagementPresenter, {
      useFactory: d => {
        return new CourierManagementPresenter(
          d.resolve(CourierManagementApiRepository)
        );
      }
    });

    container.register<CodConfigurationPresenter>(CodConfigurationPresenter, {
      useFactory: d => {
        return new CodConfigurationPresenter(
          d.resolve(CodConfigurationApiRepository)
        );
      }
    });

    container.register<ConfigFakeDexIndicationPresenter>(
      ConfigFakeDexIndicationPresenter,
      {
        useFactory: d => {
          return new ConfigFakeDexIndicationPresenter(
            d.resolve(ConfigFakeDexIndicationApiRepository)
          );
        }
      }
    );

    container.register<DexAssessmentPresenter>(DexAssessmentPresenter, {
      useFactory: d => {
        return new DexAssessmentPresenter(
          d.resolve(DexAssessmentApiRepository)
        );
      }
    });

    // saldo & poin sender dashboard
    container.register<SaldoPoinPresenter>(SaldoPoinPresenter, {
      useFactory: d => {
        return new SaldoPoinPresenter(d.resolve(SaldoPoinApiRepository));
      }
    });

    container.register<TrackDeliveryPresenter>(TrackDeliveryPresenter, {
      useFactory: d => {
        return new TrackDeliveryPresenter(
          d.resolve(TrackDeliveryApiRepository)
        );
      }
    });

    // algo
    container.register<AuthAlgoPresenter>(AuthAlgoPresenter, {
      useFactory: d => {
        return new AuthAlgoPresenter(d.resolve(AuthAlgoApiRepository));
      }
    });
    container.register<PosFavoritePresenter>(PosFavoritePresenter, {
      useFactory: d => {
        return new PosFavoritePresenter(d.resolve(PosFavoriteApiRepository));
      }
    });

    // ngen
    container.register<AuthNGenPresenter>(AuthNGenPresenter, {
      useFactory: d => {
        return new AuthNGenPresenter(d.resolve(AuthNGenApiRepository));
      }
    });
    container.register<NGenPresenter>(NGenPresenter, {
      useFactory: d => {
        return new NGenPresenter(d.resolve(NGenApiRepository));
      }
    });

    // middleware
    container.register<MiddlewarePresenter>(MiddlewarePresenter, {
      useFactory: d => {
        return new MiddlewarePresenter(d.resolve(MiddlewareApiRepository));
      }
    });

    // external
    container.register<UniversalPrintPresenter>(UniversalPrintPresenter, {
      useFactory: d => {
        return new UniversalPrintPresenter(
          d.resolve(UniversalPrintApiRepository)
        );
      }
    });

    container.register<PaymentPresenter>(PaymentPresenter, {
      useFactory: d => {
        return new PaymentPresenter(d.resolve(PaymentApiRepository));
      }
    });

    container.register<STTPaidUnpaidPresenter>(STTPaidUnpaidPresenter, {
      useFactory: d => {
        return new STTPaidUnpaidPresenter(
          d.resolve(STTPaidUnpaidApiRepository)
        );
      }
    });

    container.register<ProgressiveCommissionDashboardPresenter>(
      ProgressiveCommissionDashboardPresenter,
      {
        useFactory: d => {
          return new ProgressiveCommissionDashboardPresenter(
            d.resolve(ProgressiveCommissionDashboardApiRepository)
          );
        }
      }
    );

    container.register<HoldBalanceHistoryPresenter>(
      HoldBalanceHistoryPresenter,
      {
        useFactory: d => {
          return new HoldBalanceHistoryPresenter(
            d.resolve(HoldBalanceHistoryApiRepository)
          );
        }
      }
    );

    // balance minus penalty
    container.register<BalanceMinusPenaltyPresenter>(
      BalanceMinusPenaltyPresenter,
      {
        useFactory: d => {
          return new BalanceMinusPenaltyPresenter(
            d.resolve(BalanceMinusPenaltyApiRepository)
          );
        }
      }
    );

    // universal
    container.register<UniversalPresenter>(UniversalPresenter, {
      useFactory: d => {
        return new UniversalPresenter(d.resolve(UniversalApiRepository));
      }
    });

    container.register<CargoConfigurationPresenter>(
      CargoConfigurationPresenter,
      {
        useFactory: d => {
          return new CargoConfigurationPresenter(
            d.resolve(CargoConfigurationApiRepository)
          );
        }
      }
    );

    container.register<UrgentDeliveryPresenter>(
      UrgentDeliveryPresenter,
      {
        useFactory: d => {
          return new UrgentDeliveryPresenter(
            d.resolve(UrgentDeliveryApiRepository)
          );
        }
      }
    );

    container.register<UrgentDeliveryManagementPresenter>(
      UrgentDeliveryManagementPresenter,
      {
        useFactory: d => {
          return new UrgentDeliveryManagementPresenter(
            d.resolve(UrgentDeliveryManagementApiRepository)
          );
        }
      }
    );

    container.register<InternationalDocumentPresenter>(
      InternationalDocumentPresenter,
      {
        useFactory: d => {
          return new InternationalDocumentPresenter(
            d.resolve(InternationalDocumentApiRepository)
          )
        }
      }
    );

    container.register<CustomerManagementPresenter>(
      CustomerManagementPresenter,
      {
        useFactory: d => {
          return new CustomerManagementPresenter(
            d.resolve(CustomerManagementApiRepository)
          )
        }
      }
    );

    container.register<ListPickupPresenter>(
      ListPickupPresenter,
      {
        useFactory: d => {
          return new ListPickupPresenter(
            d.resolve(ListPickupApiRepository)
          );
        }
      }
    );
    container.register<CodReconcilePresenter>(CodReconcilePresenter, {
      useFactory: d => {
        return new CodReconcilePresenter(
          d.resolve(CodReconcileApiRepository)
        )
      }
    })

    container.register<CollectibleCardPresenter>(CollectibleCardPresenter, {
      useFactory: d => {
        return new CollectibleCardPresenter(
          d.resolve(CollectibleCardApiRepository)
        );
      }
    });
  }
}
