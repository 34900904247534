import { Pagination } from "@/domain/entities/Pagination";

export class ShortlandEntities {
  pagination: Pagination;
  data: ShortlandData[];

  constructor(pagination: Pagination, data: ShortlandData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ShortlandData {
  cargoNo: string;
  cargoType: string;
  sttNo: string;
  sttPiecesNo: string;
  sttId: number;
  sttPiecesId: number;
  arrivalCityCode: string;
  arrivalCityName: string;
  commodityCode: string;
  commodityName: string;
  sttLastStatus: string;
  grossWeight: number;
  volumeWeight: number;
  sttTotalPiece: number;
  sttNoElexys?: string;
  constructor(
    cargoNo: string,
    cargoType: string,
    sttNo: string,
    sttPiecesNo: string,
    sttId: number,
    sttPiecesId: number,
    arrivalCityCode: string,
    arrivalCityName: string,
    commodityCode: string,
    commodityName: string,
    sttLastStatus: string,
    grossWeight: number,
    volumeWeight: number,
    sttTotalPiece: number,
    sttNoElexys?: string
  ) {
    this.cargoNo = cargoNo;
    this.cargoType = cargoType;
    this.sttNo = sttNo;
    this.sttPiecesNo = sttPiecesNo;
    this.sttId = sttId;
    this.sttPiecesId = sttPiecesId;
    this.arrivalCityCode = arrivalCityCode;
    this.arrivalCityName = arrivalCityName;
    this.commodityCode = commodityCode;
    this.commodityName = commodityName;
    this.sttLastStatus = sttLastStatus;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.sttTotalPiece = sttTotalPiece;
    this.sttNoElexys = sttNoElexys;
  }
}
