import { Pagination } from "@/domain/entities/Pagination";
import { CityData, DistrictData } from "@/domain/entities/Location";

export class PosEntities {
  pagination: Pagination;
  data: PosData[];

  constructor(pagination: Pagination, data: PosData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class PosData {
  id: number;
  name: string;
  address: string;
  balance: number;
  code: string;
  lat: number;
  long: number;
  posType: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  parentId: number;
  parentName: string;
  parentType: string;
  contractEnd: string;
  contractStatus: string;
  isBanned: boolean;
  isRegistry: boolean;
  partnerLocation: PosPartnerLocation;
  constructor(
    id: number,
    name: string,
    address: string,
    balance: number,
    code: string,
    lat: number,
    long: number,
    posType: string,
    type: string,
    createdAt: string,
    updatedAt: string,
    parentId: number,
    parentName: string,
    parentType: string,
    contractEnd: string,
    contractStatus: string,
    isBanned: boolean,
    isRegistry: boolean,
    partnerLocation: PosPartnerLocation
  ) {
    this.id = id;
    this.name = name;
    this.address = address;
    this.balance = balance;
    this.code = code;
    this.lat = lat;
    this.long = long;
    this.posType = posType;
    this.type = type;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.parentId = parentId;
    this.parentName = parentName;
    this.parentType = parentType;
    this.contractEnd = contractEnd;
    this.contractStatus = contractStatus;
    this.isBanned = isBanned;
    this.isRegistry = isRegistry;
    this.partnerLocation = partnerLocation;
  }
}

export class PosPartnerLocation {
  id: number;
  partnerId: number;
  districtCode: string;
  cityCode: string;
  createdAt: string;
  updatedAt: string;
  city: CityData;
  district: DistrictData;
  constructor(
    id: number,
    partnerId: number,
    districtCode: string,
    cityCode: string,
    createdAt: string,
    updatedAt: string,
    city: CityData,
    district: DistrictData
  ) {
    this.id = id;
    this.partnerId = partnerId;
    this.districtCode = districtCode;
    this.cityCode = cityCode;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.city = city;
    this.district = district;
  }
}

export class PosParam {
  posParentId = 0;

  constructor(fields?: Partial<PosParam>) {
    Object.assign(this, fields);
  }
}
