import { FlagPermissionUrgentDeliveryManagement } from "@/feature-flags/flag-permission-urgent-management";
const Layout = () =>
    import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const UrgentDeliveryManagement = () =>
    import(
    /* webpackChunkName: "PermintaanKirimanManagement" */ `@/app/ui/views/urgent-delivery-management/index.vue`
    );
const UrgentDeliveryManagementPOD = () =>
    import(
        /* webpackChunkName: "PermintaanKirimanManagement" */ `@/app/ui/views/urgent-delivery-management/components/urgent-pod/index.vue`
    );
const UrgentDeliveryManagementRTS = () =>
    import(
            /* webpackChunkName: "PermintaanKirimanManagement" */ `@/app/ui/views/urgent-delivery-management/components/urgent-rts/index.vue`
    );
const UrgentDeliveryManagementHAL = () =>
    import(
                /* webpackChunkName: "PermintaanKirimanManagement" */ `@/app/ui/views/urgent-delivery-management/components/urgent-hal/index.vue`
    );
const UrgentDeliveryManagementTakenByRecipient = () =>
    import(
                    /* webpackChunkName: "PermintaanKirimanManagement" */ `@/app/ui/views/urgent-delivery-management/components/urgent-taken-by-recipient/index.vue`
    );
const DetailTakenByRecipient = () =>
    import(
                    /* webpackChunkName: "PermintaanKirimanManagement" */ `@/app/ui/views/urgent-delivery-management/components/urgent-taken-by-recipient/component/detail.vue`
    );

export default [
    {
        path: "/urgent-delivery-management",
        component: Layout,
        name: "urgent-delivery-management",
        meta: {
            header: true,
            icon: "ticket-submission",
            title: "Manajemen Kiriman Penting",
            name: "Manajemen Kiriman Penting",
            permission: [
                FlagPermissionUrgentDeliveryManagement.permission_urgent_delivery_management_hal_enable,
                FlagPermissionUrgentDeliveryManagement.permission_urgent_delivery_management_pod_enable,
                FlagPermissionUrgentDeliveryManagement.permission_urgent_delivery_management_rts_enable,
            ]

        },
        children: [
            {
                path: "/urgent-delivery-management",
                redirect: "/urgent-delivery-management/urgent-pod",
                name: "urgent-delivery-management",
                component: UrgentDeliveryManagement,
                meta: {
                    header: true,
                    icon: "ticket-submission",
                },
                children: [
                    {
                        path: "urgent-pod",
                        name: "urgent-pod",
                        component: UrgentDeliveryManagementPOD,
                        meta: {
                            header: true
                        }
                    },
                    {
                        path: "urgent-rts",
                        name: "urgent-rts",
                        component: UrgentDeliveryManagementRTS,
                        meta: {
                            header: true
                        }
                    },
                    {
                        path: "urgent-hal",
                        name: "urgent-hal",
                        component: UrgentDeliveryManagementHAL,
                        meta: {
                            header: true
                        }
                    },
                    {
                        path: "taken-by-recipient",
                        name: "taken-by-recipient",
                        component: UrgentDeliveryManagementTakenByRecipient,
                        meta: {
                            header: true
                        }
                    }
                ]
            }
        ]
    },
    {
        path: "/urgent-delivery-management/taken-by-recipient",
        redirect: "/urgent-delivery-management/taken-by-recipient",
        component: Layout,
        name: "detail-taken-by-recipient",
        meta: {
            title: "Manajemen Kiriman Penting | Detail Manajemen Kiriman Penting",
            "parent-path": "/urgent-delivery-management",
            hidden: true,
            parent: "Manajemen Kiriman Penting",
            permission: [
                FlagPermissionUrgentDeliveryManagement.permission_urgent_delivery_management_hal_enable,
                FlagPermissionUrgentDeliveryManagement.permission_urgent_delivery_management_pod_enable,
                FlagPermissionUrgentDeliveryManagement.permission_urgent_delivery_management_rts_enable,
            ]
        },
        children: [
            {
                path: ":id",
                name: "detail-taken-by-recipient",
                component: DetailTakenByRecipient,
                meta: {
                    header: true,
                    customPath: true,
                    title: "",
                    name: "Diambil Penerima",
                    before: "/urgent-delivery-management/taken-by-recipient"
                }
            }
        ]
    },
];
