import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { CashbackConfigRequestInterface } from "../contracts/CashbackConfigRequest";

export class CashbackConfigRequest implements CashbackConfigRequestInterface {
    ccbAccountType = "";
    ccbType = ""
    ccbIsAllPos = false;
    ccbPartnerIds: Array<number> = [];
    ccbStartDate = "";
    ccbEndDate = "";
    ccbTiers: Array<any> = []
    constructor(fields?: Partial<CashbackConfigRequest>) {
        Object.assign(this, fields);
    }
    toJSON(): string {
        return JSON.stringify(ConvertObjectCamelToSnakeCase(this))
    }
}
