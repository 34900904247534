/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionDelivery = {
    update_del_enable: new Rox.Flag(false),
    update_del_create: new Rox.Flag(false),
};

export function initFlagsPermissionDelivery() {
    // register flags with namespaces for better grouping
    Rox.register("featureDelivery", FlagsPermissionDelivery);
}
