import { Pagination } from "@/domain/entities/Pagination";

export class StiDestScEntities {
  pagination: Pagination;
  data: StiDestScData[];

  constructor(pagination: Pagination, data: StiDestScData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class STTToBeGenerate {
  sttNumber: string;
  bagNumber: string;
  sttPieces: DetailPieces[];
  product: string;
  commodity: string;
  totalPieces: number;
  grossWeight: number;
  volumeWeight: number;
  origin: string;
  destination: string;
  isTransit: string;
  isMisroute: string;
  isStiDest: string;
  sttElexysNumber?: string;
  cargoNumber?: string;
  sttNoRef?: string;

  constructor(
    sttNumber: string,
    bagNumber: string,
    sttPieces: DetailPieces[],
    product: string,
    commodity: string,
    totalPieces: number,
    grossWeight: number,
    volumeWeight: number,
    origin: string,
    destination: string,
    isTransit: string,
    isMisroute: string,
    isStiDest: string,
    sttElexysNumber?: string,
    cargoNumber?: string,
    sttNoRef?: string
  ) {
    this.sttNumber = sttNumber;
    this.bagNumber = bagNumber;
    this.sttPieces = sttPieces;
    this.sttElexysNumber = sttElexysNumber;
    this.cargoNumber = cargoNumber;
    this.product = product;
    this.commodity = commodity;
    this.totalPieces = totalPieces;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.origin = origin;
    this.destination = destination;
    this.isTransit = isTransit;
    this.isMisroute = isMisroute;
    this.isStiDest = isStiDest;
    this.sttNoRef = sttNoRef;
  }
}

export class StiDestScData {
  stiDestScId: number;
  stiDestScCargoNo: string;
  stiDestScCargoType: string;
  stiDestScVehicleNo: string;
  stiDestScArrivalCityCode: string;
  stiDestScArrivalCityName: string;
  stiDestScTotalStt: number;
  stiDestScTotalPiece: number;
  stiDestScTotalGrossWeight: number;
  stiDestScTotalVolumeWeight: number;
  stiDestScCreatedAt: string;
  stiDestScCreatedBy: string;

  constructor(
    stiDestScId: number,
    stiDestScCargoNo: string,
    stiDestScCargoType: string,
    stiDestScVehicleNo: string,
    stiDestScArrivalCityCode: string,
    stiDestScArrivalCityName: string,
    stiDestScTotalStt: number,
    stiDestScTotalPiece: number,
    stiDestScTotalGrossWeight: number,
    stiDestScTotalVolumeWeight: number,
    stiDestScCreatedAt: string,
    stiDestScCreatedBy: string
  ) {
    this.stiDestScId = stiDestScId;
    this.stiDestScCargoNo = stiDestScCargoNo;
    this.stiDestScCargoType = stiDestScCargoType;
    this.stiDestScVehicleNo = stiDestScVehicleNo;
    this.stiDestScArrivalCityCode = stiDestScArrivalCityCode;
    this.stiDestScArrivalCityName = stiDestScArrivalCityName;
    this.stiDestScTotalStt = stiDestScTotalStt;
    this.stiDestScTotalPiece = stiDestScTotalPiece;
    this.stiDestScTotalGrossWeight = stiDestScTotalGrossWeight;
    this.stiDestScTotalVolumeWeight = stiDestScTotalVolumeWeight;
    this.stiDestScCreatedAt = stiDestScCreatedAt;
    this.stiDestScCreatedBy = stiDestScCreatedBy;
  }
}

export class StiDestDetail {
  no: number;
  bagNo: string;
  sttNo: string;
  sttPieceId: number;
  sttPiece: STTPiece[];
  product: string;
  totalPiece: number;
  grossWeight: number;
  volumeWeight: number;
  lastStatus: string;
  note: string;
  commodity: string;
  sttElexysNo?: string;
  originCityCode?: string;
  originCityName?: string;
  destinationCityCode?: string;
  destinationCityName?: string;

  constructor(
    no: number,
    bagNo: string,
    sttNo: string,
    sttPieceId: number,
    sttPiece: STTPiece[],
    product: string,
    totalPiece: number,
    grossWeight: number,
    volumeWeight: number,
    lastStatus: string,
    note: string,
    commodity: string,
    sttElexysNo?: string,
    originCityCode?: string,
    originCityName?: string,
    destinationCityCode?: string,
    destinationCityName?: string
  ) {
    this.no = no;
    this.bagNo = bagNo;
    this.sttNo = sttNo;
    this.sttPieceId = sttPieceId;
    this.sttPiece = sttPiece;
    this.product = product;
    this.totalPiece = totalPiece;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.lastStatus = lastStatus;
    this.note = note;
    this.commodity = commodity;
    this.sttElexysNo = sttElexysNo;
    this.originCityCode = originCityCode;
    this.originCityName = originCityName;
    this.destinationCityCode = destinationCityCode;
    this.destinationCityName = destinationCityName;
  }
}

export class STTPiece {
  sttPieceGrossWeight: number;
  sttPieceId: number;
  sttPieceLastStatusId: string;
  sttPieceNo: number;
  sttPieceVolumeWeight: number;
  constructor(
    sttPieceGrossWeight: number,
    sttPieceId: number,
    sttPieceLastStatusId: string,
    sttPieceNo: number,
    sttPieceVolumeWeight: number
  ) {
    this.sttPieceGrossWeight = sttPieceGrossWeight;
    this.sttPieceId = sttPieceId;
    this.sttPieceLastStatusId = sttPieceLastStatusId;
    this.sttPieceNo = sttPieceNo;
    this.sttPieceVolumeWeight = sttPieceVolumeWeight;
  }
}

export class PrintDataList {
  stiDestPartnerId: number;
  stiDestPartnerName: string;
  stiDestCityName: string;
  stiDestCityCode: string;
  stiDestTotalPieces: number;
  stiDestTotalStt: number;
  stiDestTotalGrossWeight: number;
  stiDestTotalVolumeWeight: number;
  stiDestCreatedAt: string;
  cargoType: string;
  stiDestCargoNo: string;
  stiDestDetail: StiDestDetail[];
  constructor(
    stiDestPartnerId: number,
    stiDestPartnerName: string,
    stiDestCityName: string,
    stiDestCityCode: string,
    stiDestTotalPieces: number,
    stiDestTotalStt: number,
    stiDestTotalGrossWeight: number,
    stiDestTotalVolumeWeight: number,
    stiDestCreatedAt: string,
    cargoType: string,
    stiDestCargoNo: string,
    stiDestDetail: StiDestDetail[]
  ) {
    this.stiDestPartnerId = stiDestPartnerId;
    this.stiDestPartnerName = stiDestPartnerName;
    this.stiDestCityName = stiDestCityName;
    this.stiDestCityCode = stiDestCityCode;
    this.stiDestTotalPieces = stiDestTotalPieces;
    this.stiDestTotalStt = stiDestTotalStt;
    this.stiDestTotalGrossWeight = stiDestTotalGrossWeight;
    this.stiDestTotalVolumeWeight = stiDestTotalVolumeWeight;
    this.stiDestCreatedAt = stiDestCreatedAt;
    this.cargoType = cargoType;
    this.stiDestCargoNo = stiDestCargoNo;
    this.stiDestDetail = stiDestDetail;
  }
}

export class DetailSttDestScInformation {
  isAllowUpdateStatus: boolean;
  errorMessage: string;
  isPaid?: boolean;
  sttNumber: string;
  bagNumber: string;
  cargoNumber: string;
  status: string;
  piece: DetailPieces[];
  product: string;
  commodity: string;
  totalPieces: number;
  grossWeight: number;
  volumeWeight: number;
  origin: string;
  destination: string;
  sttElexysNumber?: string;
  constructor(
    isAllowUpdateStatus: boolean,
    errorMessage: string,
    sttNumber: string,
    bagNumber: string,
    cargoNumber: string,
    status: string,
    piece: DetailPieces[],
    product: string,
    commodity: string,
    totalPieces: number,
    grossWeight: number,
    volumeWeight: number,
    origin: string,
    destination: string,
    isPaid?: boolean,
    sttElexysNumber?: string
  ) {
    this.status = status;
    this.bagNumber = bagNumber;
    this.cargoNumber = cargoNumber;
    this.sttNumber = sttNumber;
    this.isAllowUpdateStatus = isAllowUpdateStatus;
    this.errorMessage = errorMessage;
    this.isPaid = isPaid;
    this.piece = piece;
    this.sttElexysNumber = sttElexysNumber;
    this.product = product;
    this.commodity = commodity;
    this.totalPieces = totalPieces;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.origin = origin;
    this.destination = destination;
  }
}

export class DetailPieces {
  sttNumber: string;
  sttPieceNumber: string;
  pieceNumber: number;
  destinationCity: string;
  product: string;
  woodPacking: string;
  commodity: string;
  codAmount: string;
  grossWeight: number;
  volumeWeight: number;
  constructor(
    sttNumber: string,
    sttPieceNumber: string,
    pieceNumber: number,
    cityDestination: string,
    product: string,
    woodPacking: string,
    commodity: string,
    codAmount: string,
    grossWeight: number,
    volumeWeight: number
  ) {
    this.sttNumber = sttNumber;
    this.sttPieceNumber = sttPieceNumber;
    this.pieceNumber = pieceNumber;
    this.destinationCity = cityDestination;
    this.product = product;
    this.woodPacking = woodPacking;
    this.commodity = commodity;
    this.codAmount = codAmount;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
  }
}
