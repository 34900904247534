import { OptionsClass } from "./MainApp";

export class HoldBalanceHistoryDetail {
  hbhActorId = 0;
  hbhActorType = "";
  hbhPartnerId = 0;
  hbhSttId = 0;
  hbhSttNo = "";
  hbhBookingAt = "";
  hbhBookingShipmentFee = 0;
  hbhBookingReturnFee = 0;
  hbhBookingCodFee = 0;
  hbhTotalFee = 0;
  hbhSenderName = "";
  hbhSenderPhone = "";
  hbhStatus = "";

  constructor(fields?: Partial<HoldBalanceHistoryDetail>) {
    Object.assign(this, fields);
  }
}

export class HoldBalanceEntity {
  totalHoldBalanceHistory = 0;
  dataHoldBalanceHistory = [] as HoldBalanceHistoryDetail[];

  constructor(fields?: Partial<HoldBalanceEntity>) {
    Object.assign(this, fields);
  }
}

export const STATUS_LIST_DATA = [
  new OptionsClass({
    name: "Semua Status",
    value: ""
  }),
  new OptionsClass({
    name: "Saldo Tertahan",
    value: "hold_balance",
    key: "inactive"
  }),
  new OptionsClass({
    name: "Saldo Dikembalikan",
    value: "return_balance",
    key: "active"
  })
];

export const COD_TYPE_DATA = [
  new OptionsClass({
    name: "Semua Tipe STT",
    value: "all"
  }),
  new OptionsClass({
    name: "COD Retail",
    value: "retail"
  }),
  new OptionsClass({
    name: "COD Customer App",
    value: "customer_app"
  }),
  new OptionsClass({
    name: "COD Corporate",
    value: "corporate"
  }),
  new OptionsClass({
    name: "DFOD Retail",
    value: "dfod_retail"
  }),
  new OptionsClass({
    name: "DFOD Customer App",
    value: "dfod_customer_app"
  }),
]
