import { FlagsPermissionTopUpAndCashback } from "@/feature-flags/flags-permission-topup-and-cashback";
const DetailTopupFeeConfig = () =>
  import(
    /* webpackChunkName: "DetailTopupFeeConfig" */ "@/app/ui/views/finance/top-up-and-cashback/top-up-fee/detail/index.vue"
  );
const EditTopupFeeConfig = () =>
  import(
    /* webpackChunkName: "EditTopupFeeConfig" */ "@/app/ui/views/finance/top-up-and-cashback/top-up-fee/edit/index.vue"
  );

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const CreateTopupFeeConfig = () =>
  import(
    /* webpackChunkName: "CreateTopupFeeConfig" */ "@/app/ui/views/finance/top-up-and-cashback/top-up-fee/create/index.vue"
  );

export default [
  {
    path: "/finance/top-up-and-cashback/top-up-fee",
    component: Layout,
    name: "create-top-up-fee",
    meta: {
      hidden: true,
      parent: "Top Up & Cashback",
      permission: FlagsPermissionTopUpAndCashback.permission_finance_top_up_fee_enable
    },
    children: [
      {
        path: "create",
        name: "create-top-up-fee",
        component: CreateTopupFeeConfig,
        meta: {
          header: true,
          title: "Top Up & Cashback | Buat Konfigurasi Biaya Top Up",
          name: "Buat Konfigurasi Biaya Top Up",
          before: "/finance/top-up-and-cashback/top-up-fee"
        }
      }
    ]
  },
  {
    path: "/finance/top-up-and-cashback/top-up-fee",
    name: "detail-top-up-fee",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Top Up & Cashback",
      permission: FlagsPermissionTopUpAndCashback.permission_finance_top_up_fee_enable
    },
    children: [
      {
        path: ":id",
        name: "detail-top-up-fee",
        component: DetailTopupFeeConfig,
        meta: {
          title: "Detail Top Up Fee",
          name: "Detail Konfigurasi Biaya Top Up",
          header: true,
          customPath: true
        }
      }
    ]
  },
  {
    path: "/finance/top-up-and-cashback/top-up-fee",
    component: Layout,
    name: "edit-top-up-fee",
    meta: {
      hidden: true,
      parent: "Top Up & Cashback"
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-top-up-fee",
        component: EditTopupFeeConfig,
        meta: {
          header: true,
          title: "Ubah Konfigurasi Biaya Top Up",
          pathBefore: "Detail Top Up Fee",
          name: "Ubah Konfigurasi Biaya Top Up",
          before: "/finance/top-up-and-cashback/top-up-fee/:id",
          customPath: true,
          permission: FlagsPermissionTopUpAndCashback.permission_finance_top_up_fee_enable
        }
      }
    ]
  }
];
