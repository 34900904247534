import { BOOKING_COMMISSION } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const BookingCommissionDetail = () =>
  import(
    /* webpackChunkName: "CommissionConfig" */ "@/app/ui/views/commission-configuration/booking-commission/booking-commission-detail/index.vue"
  );
const BookingCommissionAdd = () =>
  import(
    /* webpackChunkName: "CommissionConfig" */ "@/app/ui/views/commission-configuration/booking-commission/booking-commission-form/booking-commission-add.vue"
  );
const BookingCommissionEdit = () =>
  import(
    /* webpackChunkName: "CommissionConfig" */ "@/app/ui/views/commission-configuration/booking-commission/booking-commission-form/booking-commission-edit.vue"
  );

export default [
  {
    path: "/tarif/commission-configuration/booking",
    component: Layout,
    name: "booking-commission-detail",
    meta: {
      title: "Booking Commission | Detail",
      hidden: true,
      parent: "Konfigurasi Komisi",
      permission: BOOKING_COMMISSION.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "booking-commission-detail",
        component: BookingCommissionDetail,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/tarif/commission-configuration/booking",
    component: Layout,
    name: "create-booking-commission",
    meta: {
      hidden: true,
      parent: "Konfigurasi Komisi",
      permission: BOOKING_COMMISSION.CREATE
    },
    children: [
      {
        path: "create",
        name: "create-booking-commission",
        component: BookingCommissionAdd,
        meta: {
          header: true,
          title: "Booking Commission | Create ",
          name: "Buat Komisi Booking",
          before: "/configuration-commission/booking"
        }
      }
    ]
  },
  {
    path: "/tarif/commission-configuration/booking",
    component: Layout,
    name: "edit-booking-commission",
    meta: {
      hidden: true,
      parent: "Konfigurasi Komisi",
      permission: BOOKING_COMMISSION.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-booking-commission",
        component: BookingCommissionEdit,
        meta: {
          header: true,
          title: "Booking Commission | Edit ",
          name: "Ubah Komisi Booking",
          before: "/tarif/commission-configuration/booking/:id"
        }
      }
    ]
  }
];
