import { TopUpVerificationRepositoryInterface } from "@/data/persistences/repositories/contracts/TopUpVerificationRepositoryInterface";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { TopUpVerificationMapper } from "@/data/persistences/mappers/TopUpVerificationMapper";
import {
  TopUpVerificationDetail,
  TopUpVerificationEntities
} from "@/domain/entities/TopupVerification";
import { TopUpVerificationRequest } from "@/data/payload/contracts/TopUpVerificationRequest";
import {
  VerifyTopUpVerification,
  RejectTopUpVerification
} from "@/data/payload/api/TopUpVerificationApiRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class TopUpVerificationApiRepository
  implements TopUpVerificationRepositoryInterface {
  private service: ApiService;
  private mapper: TopUpVerificationMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: TopUpVerificationMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getTopUpVerificationDetail(
    id: number
  ): Promise<TopUpVerificationDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getTopUpVerificationDetail(id),
      {}
    );
    return this.mapper.convertDataTopUpVerificationDetailFromApi(resp);
  }

  public async getTopUpVerificationList(
    page: number,
    limit: number,
    search: string,
    partner: string,
    status: string,
    bank: string,
    verifiedBy: string
  ): Promise<TopUpVerificationEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getTopUpVerificationList(
        page,
        limit,
        search,
        partner,
        status,
        bank,
        verifiedBy
      ),
      {}
    );
    return this.mapper.convertDataTopUpVerificationListFromApi(resp);
  }

  public async getListBankName(): Promise<string[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListInternalBankAccount(),
      {}
    );
    return this.mapper.convertListBankNameFromApi(resp);
  }

  public async getListVerifier(): Promise<Array<any>> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListVerifier(),
      {}
    );
    return this.mapper.convertListVerifierFromApi(resp);
  }

  public async verifyTopUpVerification(
    payload: TopUpVerificationRequest
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivate(
      "POST",
      this.endpoints.verifyTopUpVerification(),
      undefined,
      payload as VerifyTopUpVerification
    );
    return this.mapper.convertPostDataFromApi(resp);
  }

  public async rejectTopUpVerification(
    payload: TopUpVerificationRequest
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivate(
      "POST",
      this.endpoints.rejectTopUpVerification(),
      undefined,
      payload as RejectTopUpVerification
    );
    return this.mapper.convertPostDataFromApi(resp);
  }
}
