import { BalanceMapper } from "@/data/persistences/mappers/BalanceMapper";
import { BalanceRepositoryInterface } from "@/data/persistences/repositories/contracts/BalanceRepositoryInterface";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { BankAccountEntities } from "@/domain/entities/BankAccount";
import {
  BalanceData,
  BalanceReportMutationApiRequest,
  InternalBankAccountDetail,
  InternalBankAccountList,
  TopupManualDetail,
  TransactionHistoryEntities,
  TransactionList
} from "@/domain/entities/Balance";
import {
  AddBalanceRequestInterface,
  WithdrawBalanceRequestInterface
} from "@/data/payload/contracts/BalanceRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  AddTopupManualApiRequest,
  GetListBalanceApiRequest,
  WithdrawBalanceApiRequest
} from "@/data/payload/api/BalanceRequest";
import { ReportCheckData } from "@/domain/entities/Report";

export class BalanceApiRepository implements BalanceRepositoryInterface {
  private service: ApiService;
  private mapper: BalanceMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: BalanceMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  async getBalance(cache: boolean): Promise<BalanceData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getBalance(cache),
      {}
    );
    return this.mapper.convertBalanceDataFromApi(resp);
  }

  public async getListBankAccount(
    page: number,
    limit: number,
    type: string
  ): Promise<BankAccountEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListBankAccount(page, limit, type),
      {}
    );
    return this.mapper.convertBankAccountFromApi(resp);
  }

  public async getTransactionList(
    params: GetListBalanceApiRequest,
    apiVersion: string
  ): Promise<TransactionList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListTransaction(
        params,
        apiVersion
      ),
      {}
    );
    return this.mapper.convertTransactionListFromApi(apiVersion, resp);
  }

  public async getListInternalBankAccount(): Promise<InternalBankAccountList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListInternalBankAccount(),
      {}
    );
    return this.mapper.convertInternalBankAccountFromApi(resp);
  }

  public async getDetailInternalBankAccount(
    id: number
  ): Promise<InternalBankAccountDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailInternalBankAccount(id),
      {}
    );
    return this.mapper.convertInternalBankAccountDetailFromApi(resp);
  }

  public async getDetailTopupManual(id: number): Promise<TopupManualDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailTopupManual(id),
      {}
    );
    return this.mapper.convertTopupManualDetailFromApi(resp);
  }

  public async getListTransactionHistory(
    page: number,
    limit: number,
    status: string,
    startDate: string,
    endDate: string
  ): Promise<TransactionHistoryEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getTransactionHistoryList(
        page,
        limit,
        status,
        startDate,
        endDate
      ),
      {}
    );
    return this.mapper.convertTransactionHistoryFromApi(resp);
  }

  public async addTopupManual(
    payload: AddBalanceRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePostWithFormData(
      "post",
      this.endpoints.addTopupManual(),
      {},
      payload as AddTopupManualApiRequest
    );
    return this.mapper.convertAddTopupManualFromApi(resp);
  }

  public async withdrawBalance(
    payload: WithdrawBalanceRequestInterface,
    version: string
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.withdrawBalance(version),
      {},
      payload as WithdrawBalanceApiRequest
    );
    return this.mapper.convertWithdrawBalanceFromApi(resp);
  }

  async getBalanceClientPartner(params: any): Promise<BalanceData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getBalanceClientPartner(params),
      {}
    );
    return this.mapper.convertBalanceDataFromApi(resp);
  }

  async getDownloadBalanceReportMutation(
    params: BalanceReportMutationApiRequest
  ): Promise<ReportCheckData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDownloadBalanceReportMutation(params),
      {}
    );
    return this.mapper.convertCheckDataFromApi(resp);
  }
}
