import { RequestHistoryPoinSaldoList } from "@/data/payload/api/SaldoPoinApiRequest";
import { SaldoPoinMapper } from "@/data/persistences/mappers/SaldoPoinMapper";
import { SaldoPoinRepositoryInterface } from "@/data/persistences/repositories/contracts/SaldoPoinRepositoryInterface";
import {
  HistoryPoinEntities,
  HistorySaldoEntities
} from "@/domain/entities/SaldoPoin";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class SaldoPoinApiRepository implements SaldoPoinRepositoryInterface {
  private service: ApiService;
  private mapper: SaldoPoinMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: SaldoPoinMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  public async getListHistorySaldo(
    params: RequestHistoryPoinSaldoList
  ): Promise<HistorySaldoEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListHistorySaldo(params),
      {}
    );
    return this.mapper.convertHistorySaldoListDataFromApi(resp);
  }
  public async getListHistoryPoint(
    params: RequestHistoryPoinSaldoList
  ): Promise<HistoryPoinEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListHistoryPoint(params),
      {}
    );
    return this.mapper.convertHistoryPoinListDataFromApi(resp);
  }
}
