import { RequestBaggingGroupingList } from "@/data/payload/api/BaggingGroupingApiRequest";
import { BaggingGroupingMapper } from "@/data/persistences/mappers/BaggingGroupingMapper";
import { BaggingGroupingRepositoryInterface } from "@/data/persistences/repositories/contracts/BaggingGroupingRepositoryInteface";
import { BaggingGroupingListEntities } from "@/domain/entities/BaggingGrouping";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class BaggingGroupingApiRepository
  implements BaggingGroupingRepositoryInterface {
  private service: ApiService;
  private mapper: BaggingGroupingMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: BaggingGroupingMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  public async getBaggingGroupingList(
    params: RequestBaggingGroupingList
  ): Promise<BaggingGroupingListEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getBaggingGroupingList(params),
      {}
    );
    return this.mapper.convertBaggingGroupingListFromApi(resp);
  }
}
