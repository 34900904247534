/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionManagementCustomer = {
    management_customer_enable: new Rox.Flag(false),
    management_customer_create: new Rox.Flag(false),
    management_customer_edit: new Rox.Flag(false),
    management_customer_detail: new Rox.Flag(false)
  };
  
  export function initFlagsPermissionManagementCustomer() {
    // register flags with namespaces for better grouping
    Rox.register("featureMgtCustomer", FlagsPermissionManagementCustomer);
  }