/* eslint-disable */
//reference https://router.vuejs.org/guide/advanced/lazy-loading.html
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import {
  getExpired,
  getHubData,
  getToken,
  removeAuthCredential
} from "@/app/infrastructures/misc/Cookies";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Constants";
import { ALLOWED } from "@/app/infrastructures/misc/RolePermission";
import moment from "moment";
import { AccountController } from "../controllers/AccountController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";

import AuthenticationModules from "./modules/authentication";
import ShipmentModules from "./modules/shipment";
import DashboardModules from "./modules/dashboard";
import UserManagementModules from "./modules/user-management";
import AdminModules from "./modules/admin";
import AccountModules from "./modules/account";
import BulkActionModules from "./modules/bulk-action";
import Report from "./modules/report";
import CourierManagement from "./modules/courier-management";
import NetworkModules from "./modules/network";
import FinanceModules from "./modules/finance";
import MasterDataModules from "./modules/master-data";
import ClientModules from "./modules/clients";
import NotificationModules from "./modules/notification";
import SingleMenuModules from "./modules/single-menu";
import SingleMenuCheckTariff from "./modules/single-menu/check-tariff";
import PosFavouriteModules from "./modules/pos-favorite";
import Balance from "./modules/balance";
import CodIncome from "./modules/cod-income";
import BeneficiaryAccountModules from "./modules/beneficiary-account-management";
import Tariff from "./modules/tariff";
import PartnerManagementModules from "./modules/partner-management";
import UniversalPrint from "./modules/universal-print";
import PusatResolusi from "./modules/pusat-resolusi";
import IncomingOutgoingModules from "./modules/incoming-outgoing";
import UrgentDelivery from "./modules/urgent-delivery";
import STTPaidUnpaidModules from "./modules/stt-paid-unpaid";
import UniversalFlagDex from "./modules/universal-flag-dex";
import ProgressiveCommissionDashboard from "./modules/progressive-commission-dashboard";
import Claim from "./modules/claim";
import Integration from "./modules/integration";
import DexAssessment from "./modules/dex-assessment";
import CMSBanner from "./modules/cms-banner";
import PortalSender from "./modules/portal-sender";
import TrackDelivery from "./modules/track-delivery";
import CustomerService from "./modules/customer-service";
import PickupModules from "./modules/pickup/index"
import { CargoController } from "../controllers/CargoController";
import PosBranch from "./modules/pos-branch";
import jwtDecode from "jwt-decode";
import { AuthController } from "../controllers/AuthController";
import { flags } from "@/feature-flags";
import ManagementCustomerModules from "./modules/management-customer";

//Based Layout Genesis CMS
const Layout = () => import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

//Page Not Found
const NotFound = () => import(/* webpackChunkName: "NotFound" */ "@/app/ui/views/not-found/index.vue");

let routes: Array<RouteRecordRaw>;

if (!IS_SENDER_ACCOUNT) {
  routes = [
    ...AuthenticationModules,
    ...DashboardModules,
    ...SingleMenuCheckTariff,
    ...Tariff,
    ...MasterDataModules,
    ...NetworkModules,
    ...FinanceModules,
    ...CustomerService,
    ...ShipmentModules,
    ...PickupModules,
    ...TrackDelivery,
    ...UrgentDelivery,
    ...STTPaidUnpaidModules,
    ...IncomingOutgoingModules,
    ...SingleMenuModules,
    ...PosFavouriteModules,
    ...ClientModules,
    ...PartnerManagementModules,
    ...AdminModules,
    ...Report,
    ...CourierManagement,
    ...CMSBanner,
    ...UserManagementModules,
    ...ManagementCustomerModules,
    ...PosBranch,
    ...PusatResolusi,
    ...BulkActionModules,
    ...AccountModules,
    ...NotificationModules,
    ...Balance,
    ...CodIncome,
    ...BeneficiaryAccountModules,
    ...UniversalPrint,
    ...UniversalFlagDex,
    ...ProgressiveCommissionDashboard,
    ...Claim,
    ...Integration,
    ...DexAssessment,
    // keep this as a last one to catch all error route
    {
      path: "/:catchAll(.*)",
      component: Layout,
      name: "NotFound",
      meta: {
        hidden: true,
        permission: ALLOWED
      },
      children: [
        {
          path: "/:catchAll(.*)",
          name: "NotFound",
          component: NotFound,
          meta: {
            header: false
          }
        }
      ]
    }
  ];
} else {
  routes = [
    ...AuthenticationModules,
    ...PortalSender,
    ...AccountModules,
    // keep this as a last one to catch all error route
    {
      path: "/:catchAll(.*)",
      component: Layout,
      name: "NotFound",
      meta: {
        hidden: true,
        permission: ALLOWED,
        noAuth: true
      },
      children: [
        {
          path: "/:catchAll(.*)",
          name: "NotFound",
          component: NotFound,
          meta: {
            header: false
          }
        }
      ]
    }
  ];
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// service-worker router
let swRegistration: any = null;
document.addEventListener("swRegistered", (e: any) => {
  swRegistration = e.detail;
});

//reference : https://github.com/vuejs/vue-router/issues/914
router.beforeEach(async (to: any, from: any, next: any) => {
  MainAppController.showLoading();
  document.title = "Genesis";
  if (to.meta.title != null) {
    document.title = "Genesis - " + to.meta.title;
  }
  if (IS_SENDER_ACCOUNT) {
    document.title = "Lion Parcel";
    if (to.meta.title != null) {
      document.title = "Lion Parcel - " + to.meta.title;
    }
  }

  function checkAuth() {
    const accessToken = getToken();
    const expiredToken = getExpired();
    const formatExpireToken = moment(expiredToken).format("DD");
    const currentdate = new Date();
    const datetime = currentdate.getDate();
    return accessToken || datetime < Number(formatExpireToken);
  }

  const isAuth = checkAuth();
  const isConsoleLogin: any = getToken() ? jwtDecode(getToken()) : '';
  if (!isAuth && !to.meta.noAuth) {
    removeAuthCredential();
    next({
      name: "login"
    });
    return;
  }
  if (to.name === "login" && isAuth && getHubData()?.hubName) {
    next("dashboard");
    return;
  }

  // check update service worker on navigation
  if (swRegistration && typeof swRegistration.update === "function") {
    try {
      swRegistration.update();
    } catch (error) {
      console.error("Failed to update service worker registration:", error);
    }
  } else {
    console.error("swRegistration is not valid or update method is not available.");
  }

  if (isAuth) {
    // temp hidden for several type
    let accountType = "";
    let accountTypeDetail = "";
    if (!AccountController.accountData.account_id) {
      await AccountController._getData();
      accountType = AccountController.accountData.account_type;
      accountTypeDetail =
        AccountController.accountData.account_type_detail.type;
      const isHideFeature = to.meta.hideFeatureFor
        ? to.meta.hideFeatureFor.includes(accountType) ||
          to.meta.hideFeatureFor.includes(accountTypeDetail)
        : false;

      if (isHideFeature) {
        next("/not-found");
        return;
      }
    }

    const defaultPath = flags.flag_default_path_genesis.getValue()
    if (from.name === undefined && to.name === "dashboard" && defaultPath !== "/dashboard") {
      next(defaultPath);
      return;
    }

    // validation account before entering create booking and create pup
    if (to.meta.validateAccount) {
      await AccountController.getValidationAccount();
      if (!AccountController.validationAccount) {
        next("/not-found");
        return;
      }
    }

    // role permission check, check if using feature flag permission, else check using role permission (old)
    try {
      let checkPermission = false;
      if (Array.isArray(to.meta.permission)) {
        to.meta.permission.forEach((item:any) => {
          if (typeof item === "string") {
            checkPermission = checkRolePermission(item) || checkPermission;
          } else {
            checkPermission = item.isEnabled() || checkPermission;
          }
        });
      } else {
        if (typeof to.meta.permission === "string") {
          checkPermission = checkRolePermission(to.meta.permission);
        } else {
          checkPermission = to.meta.permission.isEnabled();
        }
      }
      if (!checkPermission) {
        next("/not-found");
        return;
      }
    } catch {
      if (!checkRolePermission(to.meta.permission)) {
        next("/not-found");
        return;
      }
    }

    if (isConsoleLogin.partner_type === "console" && getHubData()?.hubId === undefined && to.name !== "login") {
      next({name: "login"});
      document.title = "Genesis";
      await AuthController.checkConsoleAccount(isConsoleLogin);
      return;
    }
  }
  next();
});
router.afterEach((to, from) => {
  if (
    AccountController.accountData.account_type_detail.type === "console" ||
    AccountController.accountData.account_type_detail.type === "sub-console"
  ) {
    CargoController.handleGetLastReadCargo(CargoController.lastReadCargoSession.end <= new Date().getTime(), !CargoController.lastReadCargoSession.start);
  }
  MainAppController.closeLoading();
});

export default router;
