import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { AdjustmentPenaltyData } from "@/domain/entities/AdjustmentPenalty";
import { AdjustmentPenaltyRepositoryInterface } from "@/data/persistences/repositories/contracts/AdjustmentPenaltyRepositoryInterface";
import { AdjustmentPenaltyMapper } from "@/data/persistences/mappers/AdjustmentPenaltyMapper";
import { UpdateAdjustmentPenaltyRequestInterface } from "@/data/payload/contracts/AdjustmentPenaltyRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AdjustmentPenaltyApiRequest } from "@/data/payload/api/AdjustmentPenaltyApiRequest";

export class AdjustmentPenaltyApiRepository
  implements AdjustmentPenaltyRepositoryInterface {
  private service: ApiService;
  private mapper: AdjustmentPenaltyMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: AdjustmentPenaltyMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getDetailAdjustmentPenalty(
    id: number
  ): Promise<AdjustmentPenaltyData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailAdjustmentPenalty(id),
      {}
    );

    return this.mapper.convertDetailAdjusmentDetail(resp);
  }

  public async updateAdjustmentPenalty(
    payload: UpdateAdjustmentPenaltyRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivate(
      "put",
      this.endpoints.editConfigurablePrice(),
      undefined,
      payload as AdjustmentPenaltyApiRequest
    );

    return this.mapper.convertPayloadData(resp);
  }
}
