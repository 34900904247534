import {
  ANNOUNCEMENT,
  INTEGRATION_MONITORING,
  MESSAGE_CONFIG
} from "@/app/infrastructures/misc/RolePermission";

import AnnouncementModules from "./announcement";
import MessageConfigModules from "./messaging-service-configuration";
import MiddlewareModules from "./middleware";

import { FlagsPermissionMiddleware } from "@/feature-flags/flags-middleware";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

const Announcement = () =>
  import(
    /* webpackChunkName: "Announcement" */ "@/app/ui/views/announcement/index.vue"
  );
const MessagingServiceConfigList = () =>
  import(
    /* webpackChunkName: "MessagingServiceConfig" */ "@/app/ui/views/messaging-service-configuration/index.vue"
  );
const IntegrationMonitoring = () =>
  import(
    /* webpackChunkName: "IntegrationMonitoring" */ "@/app/ui/views/integration-monitoring/index.vue"
  );

const LogStt = () =>
  import(
    /* webpackChunkName: "IntegrationMonitoring" */ "@/app/ui/views/integration-monitoring/log-stt/index.vue"
  );

const ElexysFailedBooking = () =>
  import(
    /* webpackChunkName: "IntegrationMonitoring" */ "@/app/ui/views/integration-monitoring/elexys-failed-booking/index.vue"
  );

const Middleware = () =>
  import(
    /* webpackChunkName: "Middleware" */ "@/app/ui/views/middleware/index.vue"
  );

const MappingCode = () =>
  import(
    /* webpackChunkName: "Middleware" */ "@/app/ui/views/middleware/mapping-code/index.vue"
  );

const MappingToken = () =>
  import(
    /* webpackChunkName: "Middleware" */ "@/app/ui/views/middleware/mapping-token/index.vue"
  );

export default [
  {
    path: "/admin",
    component: Layout,
    name: "admin",
    redirect: "/admin/announcement",
    meta: {
      icon: "admin",
      children: true,
      title: "Admin",
      name: "Admin",
      permission: [ANNOUNCEMENT.ENABLE, MESSAGE_CONFIG.ENABLE]
    },
    children: [
      {
        path: "announcement",
        name: "announcement",
        component: Announcement,
        meta: {
          header: true,
          icon: "announcement",
          title: "Pengumuman",
          name: "Pengumuman",
          permission: ANNOUNCEMENT.ENABLE
        }
      },
      {
        path: "messaging-service-configuration",
        component: MessagingServiceConfigList,
        name: "messaging-service-configuration",
        meta: {
          icon: "messaging-service-configuration",
          title: "Konfigurasi Pesan",
          name: "Konfigurasi Pesan",
          sideName: "Konfigurasi Pesan",
          header: true,
          isFreeze: true,
          permission: MESSAGE_CONFIG.ENABLE
        }
      },
      {
        path: "integration-monitoring",
        name: "integration-monitoring",
        redirect: "/admin/integration-monitoring/elexys-failed-booking",
        component: IntegrationMonitoring,
        meta: {
          icon: "integration-monitoring",
          title: "Integration Monitoring",
          name: "Integration Monitoring",
          isFreeze: true,
          header: true,
          permission: INTEGRATION_MONITORING.ENABLE
        },
        children: [
          {
            path: "elexys-failed-booking",
            name: "elexys-failed-booking",
            component: ElexysFailedBooking
          },
          {
            path: "log-stt",
            name: "log-stt",
            component: LogStt
          }
        ]
      },
      {
        path: "middleware",
        name: "middleware",
        component: Middleware,
        redirect: "/admin/middleware/mapping-code",
        meta: {
          icon: "middleware",
          title: "Middleware",
          name: "Middleware",
          header: true,
          permission: FlagsPermissionMiddleware.permission_middleware
        },
        children: [
          {
            path: "mapping-code",
            name: "mapping-code",
            component: MappingCode
          },
          {
            path: "mapping-token",
            name: "mapping-token",
            component: MappingToken
          }
        ]
      },
    ]
  },
  ...AnnouncementModules,
  ...MessageConfigModules,
  ...MiddlewareModules
];
