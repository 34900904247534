import { CityData } from "./Location";
import { Pagination } from "./Pagination";

export class GoodsTax {
  id: number;
  goodsTaxOriginCityName: string;
  goodsTaxCommodityName: string;
  goodsTaxCommodityCode: string;
  goodsTaxDeminimus: number;
  goodsTaxCoverCharge: number;
  goodsTaxPPN: number;
  goodsTaxPPH: number;
  goodsTaxStartDate: string;
  goodsTaxEndDate: string;
  goodsTaxStatus: string;
  goodsTaxCreatedAt?: string;
  goodsTaxCreatedName?: string;
  goodsTaxUpdatedAt?: string;
  goodsTaxUpdatedName?: string;
  goodsTaxOriginCity?: CityData[];

  constructor(
    id: number,
    goodsTaxOriginCityName: string,
    goodsTaxCommodityName: string,
    goodsTaxCommodityCode: string,
    goodsTaxDeminimus: number,
    goodsTaxCoverCharge: number,
    goodsTaxPPN: number,
    goodsTaxPPH: number,
    goodsTaxStartDate: string,
    goodsTaxEndDate: string,
    goodsTaxStatus: string,
    goodsTaxCreatedAt?: string,
    goodsTaxCreatedName?: string,
    goodsTaxUpdatedAt?: string,
    goodsTaxUpdatedName?: string,
    goodsTaxOriginCity?: CityData[]
  ) {
    this.id = id;
    this.goodsTaxOriginCityName = goodsTaxOriginCityName;
    this.goodsTaxCommodityName = goodsTaxCommodityName;
    this.goodsTaxCommodityCode = goodsTaxCommodityCode;
    this.goodsTaxDeminimus = goodsTaxDeminimus;
    this.goodsTaxCoverCharge = goodsTaxCoverCharge;
    this.goodsTaxPPN = goodsTaxPPN;
    this.goodsTaxPPH = goodsTaxPPH;
    this.goodsTaxStartDate = goodsTaxStartDate;
    this.goodsTaxEndDate = goodsTaxEndDate;
    this.goodsTaxStatus = goodsTaxStatus;
    this.goodsTaxCreatedAt = goodsTaxCreatedAt;
    this.goodsTaxCreatedName = goodsTaxCreatedName;
    this.goodsTaxUpdatedAt = goodsTaxUpdatedAt;
    this.goodsTaxUpdatedName = goodsTaxUpdatedName;
    this.goodsTaxOriginCity = goodsTaxOriginCity;
  }
}

export class GoodsTaxList {
  pagination: Pagination;
  data: GoodsTax[];
  constructor(pagination: Pagination, data: GoodsTax[]) {
    this.pagination = pagination;
    this.data = data;
  }
}
