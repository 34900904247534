/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPromoDiscount = {
  disable_reference_weight_and_applied_to: new Rox.Flag(true),
};

export function initFlagsPromoDiscount() {
  // register flags with namespaces for better grouping
  Rox.register("featurePromoDiscount", FlagsPromoDiscount);
}
