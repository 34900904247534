import { Pagination } from "./Pagination";

export class ElexysFailedBookingData {
  id = 0;
  suffix = 0;
  sttElexysNumber = "";
  elexysRequest = "";
  failedReasons = "";
  latestRetryStatus = "";
  firstFailedAt = "";
  updatedAt = "";

  constructor(fields?: Partial<ElexysFailedBookingData>) {
    Object.assign(this, fields);
  }
}

export class ElexysFailedBookingEntities {
  pagination = new Pagination(1, 10);
  data = [new ElexysFailedBookingData()];

  constructor(fields?: Partial<ElexysFailedBookingEntities>) {
    Object.assign(this, fields);
  }
}

export class RequestApiElexysFailedBookingList {
  page = 1;
  limit = 10;
  search = "";
  sortby = "";
  orderby = "";
  suffix = "";
  retrystatus = "";

  constructor(fields?: Partial<RequestApiElexysFailedBookingList>) {
    Object.assign(this, fields);
  }
}

export class AdvancedFilterElexysFailedBooking {
  suffix = [];
  status = [];

  constructor(fields?: Partial<AdvancedFilterElexysFailedBooking>) {
    Object.assign(this, fields);
  }
}

export class RequestDownloadElexysFailedBooking {
  firstFailedStart = "";
  firstFailedEnd = "";

  constructor(fields?: Partial<RequestDownloadElexysFailedBooking>) {
    Object.assign(this, fields);
  }
}

export class LogSttData {
  sttNo: string;
  partnerlogHydra: LogSttColumns[];
  partnerlogGober: LogSttColumns[];

  constructor(
    sttNo: string,
    partnerlogHydra: LogSttColumns[],
    partnerlogGober: LogSttColumns[]
  ) {
    this.sttNo = sttNo;
    this.partnerlogHydra = partnerlogHydra;
    this.partnerlogGober = partnerlogGober;
  }
}

export class LogSttColumns {
  id: number;
  action: string;
  refId: string;
  request: string;
  response: string;
  constructor(
    id: number,
    action: string,
    refId: string,
    request: string,
    response: string
  ) {
    this.id = id;
    this.action = action;
    this.refId = refId;
    this.request = request;
    this.response = response;
  }
}
