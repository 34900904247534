import { PROGRESSIVE_COMMISSION_DASHBOARD } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const ProgressiveCommissionDashboard = () =>
  import(
    /* webpackChunkName: "ProgressiveCommission" */ "@/app/ui/views/progressive-commission-dashboard/index.vue"
  );

export default [
  {
    path: "/bonus-komisi",
    component: Layout,
    name: "bonus-komisi",
    redirect: "/bonus-komisi",
    meta: {
      icon: "empty",
      children: false,
      title: "Bonus Komisi",
      name: "Bonus Komisi",
      hidden: true,
      permission: PROGRESSIVE_COMMISSION_DASHBOARD.ENABLE
    },
    children: [
      {
        path: "/bonus-komisi",
        name: "bonus-komisi",
        component: ProgressiveCommissionDashboard,
        meta: {
          header: true
        }
      }
    ]
  }
];
