import {
  formatDate,
  formatDateWithoutTime,
} from "@/app/infrastructures/misc/Utils";
import { QueryParamsEntities } from "./MainApp";
import { Pagination } from "./Pagination";
import moment from "moment";

export class CashbackConfigEntities {
  pagination: Pagination;
  data: CashbackConfigListData[];
  constructor(pagination: Pagination, data: CashbackConfigListData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class CashbackConfigListData {
  ccbId = "";
  ccbAccountType = "";
  ccbStartDate = "";
  ccbEndDate = "";
  ccbStatus = "";
  ccbCreatedAt = "";
  ccbCreatedBy = "";
  ccbUpdatedAt = "";
  ccbUpdatedBy = "";
  constructor(fields?: Partial<CashbackConfigListData>) {
    Object.assign(this, fields);
  }
}

export class CashbackConfigDetailData {
  ccbId = "";
  ccbAccountType = "";
  ccbType = "";
  ccbStartDate = "";
  ccbEndDate = "";
  ccbStatus = "";
  ccbCreatedAt = "";
  ccbCreatedBy = "";
  ccbUpdatedAt = "";
  ccbUpdatedBy = "";
  ccbIsAllPos = false;
  ccbPartners: Array<any> = [];
  ccbTiers: Array<any> = [];
  constructor(fields?: Partial<CashbackConfigDetailData>) {
    Object.assign(this, fields);
  }

  get formatStartDate() {
    return moment(this.ccbStartDate).format("DD MMMM YYYY")
  }

  get formatEndDate() {
    return moment(this.ccbEndDate).format("DD MMMM YYYY")
  }

  get formatCreatedAt() {
    return formatDate(this.ccbCreatedAt);
  }

  get formatUpdatedAt() {
    return formatDate(this.ccbUpdatedAt);
  }
}

export class CashbackListRequest {
  page = 1;
  limit = 20;
  constructor(fields?: Partial<CashbackListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
