import { PROGRESSIVE_COMMISSION_CONFIG } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

const ProgressiveCommissionConfigurationDetail = () =>
  import(
    /* webpackChunkName: "ProgressiveCommissionConfig" */ "@/app/ui/views/progressive-commission-configuration/progressive-commission-configuration-detail/index.vue"
  );
const ProgressiveCommissionConfigurationForm = () =>
  import(
    /* webpackChunkName: "ProgressiveCommissionConfig" */ "@/app/ui/views/progressive-commission-configuration/progressive-commission-configuration-form/index.vue"
  );

export default [
  {
    path: "/tarif/commission-configuration/progressive-commisson",
    component: Layout,
    name: "progressive-commission-configuration-detail",
    meta: {
      hidden: true,
      parent: "Konfigurasi Bonus Komisi",
      permission: PROGRESSIVE_COMMISSION_CONFIG.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "progressive-commission-configuration-detail",
        component: ProgressiveCommissionConfigurationDetail,
        meta: {
          header: true,
          title: "Konfigurasi Bonus Komisi | Detail"
        }
      }
    ]
  },
  {
    path: "/tarif/commission-configuration/progressive-commisson",
    component: Layout,
    name: "progressive-commission-form-add",
    meta: {
      hidden: true,
      parent: "Konfigurasi Bonus Komisi",
      permission: PROGRESSIVE_COMMISSION_CONFIG.CREATE
    },
    children: [
      {
        path: "add",
        name: "progressive-commission-form-add",
        component: ProgressiveCommissionConfigurationForm,
        meta: {
          header: true,
          title: "Konfigurasi Bonus Komisi | Add",
          name: "Buat Program",
          formType: "add",
          before: "/tarif/commission-configuration/progressive-commisson"
        }
      }
    ]
  },
  {
    path: "/tarif/commission-configuration/progressive-commisson",
    component: Layout,
    name: "progressive-commission-form-edit",
    meta: {
      hidden: true,
      parent: "Konfigurasi Bonus Komisi",
      permission: PROGRESSIVE_COMMISSION_CONFIG.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "progressive-commission-form-edit",
        component: ProgressiveCommissionConfigurationForm,
        meta: {
          header: true,
          title: "Konfigurasi Bonus Komisi | Edit",
          name: "Ubah Konfigurasi Bonus Komisi",
          formType: "edit",
          before: "/tarif/commission-configuration/progressive-commisson/:id"
        }
      }
    ]
  }
];
