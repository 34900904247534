import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";
import {
  LastBalanceData,
  LastBalanceEntities
} from "@/domain/entities/LastBalance";
import { BalanceData } from "@/domain/entities/Balance";

export class LastBalanceMapper {
  public convertListPartnerLastBalanceFromApi(
    res: AxiosResponse
  ): LastBalanceEntities {
    const { data } = res;
    return new LastBalanceEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      data.data.length > 0
        ? data.data.map((item: any) => {
            return new LastBalanceData({
              idClientPartner: item.id,
              codeClientPartner: item.code,
              nameClientPartner: item.name,
              currency: item.country.currency,
              contractStatus: item.contract_status
            });
          })
        : []
    );
  }

  public convertListClientManagementLastBalanceFromApi(
    res: AxiosResponse
  ): LastBalanceEntities {
    const { data } = res;
    return new LastBalanceEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      data.data.length > 0
        ? data.data.map((item: any) => {
            return new LastBalanceData({
              idClientPartner: item.id,
              codeClientPartner: item.code,
              nameClientPartner: item.company_name
            });
          })
        : []
    );
  }

  public convertLastBalanceDataFromApi(res: AxiosResponse): BalanceData[] {
    const { data } = res;
    return data.data.map((item: any) => {
      return new BalanceData({
        walletId: item.wallet_id,
        walletActorType: item.wallet_actor_type,
        walletActorId: item.wallet_actor_id,
        walletBalance: item.wallet_balance,
        walletBalanceCod: item.wallet_balance_cod,
        walletCreatedAt: item.wallet_created_at,
        walletUpdatedAt: item.wallet_updated_at || item.wallet_created_at
      });
    });
  }
}
