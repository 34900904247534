import { Pagination } from "@/domain/entities/Pagination";
import {
  TransportData,
  TransportEntities,
  VendorData
} from "@/domain/entities/Transport";
import { AxiosResponse } from "axios";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class TransportDataMapper {
  public convertTransportDataFromApi(
    res: AxiosResponse<any>
  ): TransportEntities {
    const { data } = res;

    const transport: TransportData[] = [];
    if (data.data.length === 0) {
      return new TransportEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        transport.push(
          new TransportData(
            item.transport_id,
            item.transport_code,
            item.transport_type,
            item.transport_vendor_id,
            item.transport_vendor_name,
            item.transport_vendor_code,
            item.transport_status,
            item.transport_created_at,
            item.transport_updated_at,
            item.transport_created_by,
            item.transport_updated_by
          )
        );
      });
    }
    return new TransportEntities(
      new Pagination(data.meta.page, data.meta.limit),
      transport
    );
  }

  public convertDetailTransportDataFromApi(
    res: AxiosResponse<any>
  ): TransportData {
    const { data } = res;
    return new TransportData(
      data.data.transport_id,
      data.data.transport_code,
      data.data.transport_type,
      data.data.transport_vendor_id,
      data.data.transport_vendor_name,
      data.data.transport_vendor_code,
      data.data.transport_status,
      data.data.transport_created_at,
      data.data.transport_updated_at,
      data.data.transport_created_name,
      data.data.transport_updated_name
    );
  }

  public convertPayloadData(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertVendorStatusFromApi(res: AxiosResponse<any>): VendorData {
    const { data } = res;
    return new VendorData(data.data.status);
  }
}
