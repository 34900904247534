/* eslint-disable @typescript-eslint/camelcase */

import { Pagination } from "./Pagination";

export class VendorEntities {
  pagination: Pagination;
  data: VendorData[];
  constructor(pagination: Pagination, data: VendorData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class VendorData {
  id = 0;
  code = "";
  name = "";
  email = "";
  phone_number = "";
  address = "";
  status = "";
  contract_end = "";
  contract_start = "";
  tax_number = "";
  created_at = "";
  updated_at = "";
  created_by = "";
  updated_by = "";
  constructor(fields?: Partial<VendorData>) {
    Object.assign(this, fields);
  }
}

export class DeliveryVendor {
  deliveryVendorId = 0;
  deliveryVendorName = "";
  constructor(fields?: Partial<DeliveryVendor>) {
    Object.assign(this, fields);
  }
}
