export class HeavyWeightSurchargeData {
  id = 0;
  configurablePriceName = "";
  configurablePriceType = "";
  configurablePriceDescription = "";
  configurablePriceStatus = "";
  configurablePriceCreatedAt = "";
  configurablePriceCreatedBy = "";
  configurablePriceUpdatedAt = "";
  configurablePriceUpdatedBy = "";
  configurableHeavyWeight: any = "";
  heavyweightSurchargeType = "";
  heavyweightSurchargeAdditionalCost = [];
  constructor(fields?: Partial<HeavyWeightSurchargeData>) {
    Object.assign(this, fields);
  }
}

export class HeavyWeightSurchargeAdditionalCost {
  id = 0;
  weightMax = 0;
  weightMin = 0;
  additionalCostPercentage = 0;
  additionalCostFlat = 0;
  isError = false;
  constructor(fields?: Partial<HeavyWeightSurchargeAdditionalCost>) {
    Object.assign(this, fields);
  }
}

export class HeavyWeightSurchargeDetailMinimum {
  heavyWeightMinimum = 0;
  minimumExist = false;
  constructor(fields?: Partial<HeavyWeightSurchargeDetailMinimum>) {
    Object.assign(this, fields);
  }
}
