const dateDiffHoursMinute = (
  startDate: Date | string,
  endDate: Date | string
) => {
  startDate = new Date(startDate);
  endDate = new Date(endDate);
  const diff = endDate.getTime() - startDate.getTime();
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor(diff / 60000) - hours * 60;
  return `${hours} Jam ${minutes} Menit`;
};
export default dateDiffHoursMinute;
