import { SERVICE_DELAY } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

const Form = () =>
  import(
    /* webpackChunkName: "ServiceDelay" */ "@/app/ui/views/route/service-delay/form/index.vue"
  );
const Detail = () =>
  import(
    /* webpackChunkName: "ServiceDelay" */ "@/app/ui/views/route/service-delay/detail/index.vue"
  );

export default [
  {
    path: "/network/service-delay",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Area Keterlambatan"
    },
    children: [
      {
        path: "add",
        name: "service-delay-form-add",
        component: Form,
        meta: {
          header: true,
          title: "Area Keterlambatan | Add",
          name: "Buat Area Keterlambatan Baru",
          before: "/service-delay",
          permission: SERVICE_DELAY.CREATE
        }
      }
    ]
  },
  {
    path: "/network/service-delay",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Area Keterlambatan"
    },
    children: [
      {
        path: ":id",
        name: "service-delay-detail",
        component: Detail,
        meta: {
          header: true,
          title: "Area Keterlambatan | Detail",
          permission: SERVICE_DELAY.DETAIL
        }
      }
    ]
  },
  {
    path: "/network/service-delay",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Area Keterlambatan"
    },
    children: [
      {
        path: ":id/edit",
        name: "service-delay-form-edit",
        component: Form,
        meta: {
          header: true,
          title: "Area Keterlambatan | Edit",
          name: "Edit Area Keterlambatan",
          before: "/network/service-delay/:id",
          permission: SERVICE_DELAY.EDIT
        }
      }
    ]
  }
];
