import { HubPresenter } from "@/app/ui/presenters/HubPresenter";
import { HubMapper } from "@/data/persistences/mappers/HubMapper";
import { container } from "tsyringe";
import { HubEndpoint } from "../../endpoints/horde/HubEndpoint";
import { HubApiRepository } from "../../repositories/api/HubApiRepository";
import ApiService from "../../services/ApiService";

export class HubComponent {
  public static init() {
    container.register<HubApiRepository>(HubApiRepository, {
      useFactory: d => {
        return new HubApiRepository(
          d.resolve(ApiService),
          d.resolve(HubMapper),
          d.resolve(HubEndpoint)
        );
      }
    });
    container.register<HubMapper>(HubMapper, {
      useClass: HubMapper
    });
    container.register<HubPresenter>(HubPresenter, {
      useFactory: d => {
        return new HubPresenter(d.resolve(HubApiRepository));
      }
    });
  }
}
