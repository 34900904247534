import {
  ALLOWED,
  TRACK_DELIVERY
} from "@/app/infrastructures/misc/RolePermission";
import { FlagsDashboardCod } from "@/feature-flags/dashboard/cod";
import { FlagsPermissionDashboard } from "@/feature-flags/flags-dashboard";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const Dashboard = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/app/ui/views/dashboard/index.vue"
  );
const DashboardDetail = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/app/ui/views/dashboard/component/detail/index.vue"
  );
const DashboardDetailCourier = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/app/ui/views/dashboard/cod-dashboard/detail.vue"
  );
const PerformanceDelivery = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/app/ui/views/track-delivery/delivery-tab/index.vue"
  );

export default [
  {
    path: "/dashboard",
    component: Layout,
    name: "dashboard",
    meta: {
      icon: "home",
      children: false,
      title: "Dashboard",
      name: "Dashboard",
      gtmEventName: "dashboard_menu",
      gtmEventCustoms: {},
      gtmEventOption: ["userId", "userType", "timestamp"],
      permission: FlagsPermissionDashboard.dashboard_enable
    },
    children: [
      {
        path: "",
        name: "dashboard",
        component: Dashboard,
        meta: {
          header: false
        }
      },
      {
        path: "detail",
        name: "dashboard-detail",
        component: DashboardDetail,
        meta: {
          header: false,
          hidden: true
        }
      }
    ]
  },
  {
    path: "/dashboard",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Dashboard",
      permission: FlagsDashboardCod.permission_detail_cod_dashboard
    },
    children: [
      {
        path: ":id/detail-courier",
        name: "Detail Courier",
        component: DashboardDetailCourier,
        meta: {
          header: true,
          title: "COD Dashboard | Detail Courier",
          name: "Detail Courier",
          before: "/dashboard"
        }
      }
    ]
  },
  {
    path: "/dashboard",
    component: Layout,
    name: "performa-pengiriman",
    meta: {
      hidden: true,
      parent: "Halaman Utama"
    },
    children: [
      {
        path: ":id",
        name: "performa-pengiriman",
        component: PerformanceDelivery,
        meta: {
          title: "Performa Pengiriman",
          customPath: true,
          header: true,
          isAuth: true,
          permission: TRACK_DELIVERY.ENABLE
        }
      }
    ]
  }
];
