import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import { CityData, DistrictData } from "./Location";

export class ServiceDelayDetail {
  id = 0;
  name = "";
  cityData = new CityData();
  cityCode = "";
  districts: string | DistrictsForm[] = "";
  districtData: DistrictData[] | any = [];
  allDistrict = false;
  remarks = "";
  startDate = "";
  endDate = "";
  statusData = "";
  status = false;
  createdAt = "";
  createdBy = "";
  updatedAt = "";
  updatedBy = "";
  constructor(fields?: Partial<ServiceDelayDetail>) {
    Object.assign(this, fields);
  }
  formatDate?(date: string): string {
    return date ? formatDate(date) : "";
  }
}

export class ServiceDelayErrorDetail {
  errorName = true;
  errorCityCode = true;
  districts = "";
  districtData: DistrictData[] = [];
  removeDistrictData: DistrictData[] = [];
  errorDistrict = true;
  errorRemarks = true;
  errorStartDate = true;
  errorEndDate = false;
  constructor(fields?: Partial<ServiceDelayErrorDetail>) {
    Object.assign(this, fields);
  }
}

export class ServiceDelayFormResponse {
  district = new DistrictData();
  error = "";
  constructor(fields?: Partial<ServiceDelayFormResponse>) {
    Object.assign(this, fields);
  }
}

export class DistrictsForm {
  sddId = 0;
  district = new DistrictData();
  status = "";
  constructor(fields?: Partial<DistrictsForm>) {
    Object.assign(this, fields);
  }
}
