import { PosClient, PosClientList } from "@/domain/entities/PosClient";
import { AxiosResponse } from "axios";

export class PosClientMapper {
  public converPosClientFromApi(result: AxiosResponse<any>): PosClientList {
    const { data } = result;
    return new PosClientList(
      data.data.length > 0
        ? data.data.map((item: any) => {
            return new PosClient(
              item.id,
              item.name,
              item.code,
              item.type,
              item.status,
              item.is_banned
            );
          })
        : []
    );
  }
}
