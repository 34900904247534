import { FlagsPermissionSaldo } from "@/feature-flags/flags-permission-saldo";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const Balance = () =>
  import(
    /* webpackChunkName: "Balance" */ "@/app/ui/views/balance/balance-mutation/index.vue"
  );
const BalanceNett = () =>
  import(
    /* webpackChunkName: "Balance" */ "@/app/ui/views/balance/balance-nett/index.vue"
  );
const BalanceLayout = () =>
  import(/* webpackChunkName: "Balance" */ "@/app/ui/views/balance/index.vue");
const HoldBalanceHistory = () =>
  import(
    /* webpackChunkName: "Balance" */ "@/app/ui/views/balance/hold-balance-history/index.vue"
  );

export default [
  {
    path: "/balance",
    component: Layout,
    name: "balance",
    meta: {
      icon: "empty",
      name: "Saldo",
      header: true,
      children: false,
      hidden: true,
      isFreeze: true,
      permission: FlagsPermissionSaldo.permission_saldo_enable
    },
    children: [
      {
        path: "",
        name: "balance-layout",
        component: BalanceLayout,
        meta: {
          name: "Saldo",
          header: true,
          children: false,
          hidden: true,
          isFreeze: true
        },
        children: [
          {
            path: "balance-mutation",
            name: "balance-mutation",
            component: Balance,
            meta: {
              title: "Mutasi Saldo",
              name: "Saldo",
              permission: FlagsPermissionSaldo.permission_saldo_balance_mutation
            }
          },
          {
            path: "balance-mutation-nett",
            name: "balance-mutation-nett",
            component: BalanceNett,
            meta: {
              title: "Mutasi Saldo Nett",
              name: "Saldo",
              permission:
                FlagsPermissionSaldo.permission_saldo_balance_mutation_nett
            }
          },
          {
            path: "hold-balance-history",
            name: "hold-balance-history",
            component: HoldBalanceHistory,
            meta: {
              title: "Saldo Tertahan",
              name: "Saldo",
              permission:
                FlagsPermissionSaldo.permission_saldo_hold_balance_history
            }
          }
        ]
      }
    ]
  }
];
