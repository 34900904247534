import { CodReconcileApiRepository } from "@/app/infrastructures/repositories/api/CodReconcileApiRepository";
import {
  CourierDetail,
  CodDashboardDetailCourier,
  CourierDetailApiRequest,
  CourierDetailList,
  CourierDetailPendingReconcile,
  CourierSummary,
  DashboardDeliveryCourierDetail,
} from "@/domain/entities/CodReconcile";
import { injectable } from "tsyringe";

@injectable()
export class CodReconcilePresenter {
  private repository: CodReconcileApiRepository;

  constructor(repo: CodReconcileApiRepository) {
    this.repository = repo;
  }

  async getCourierDetailSummary(
    params: CourierDetailApiRequest
  ): Promise<CodDashboardDetailCourier> {
    return this.repository.getCourierDetailSummary(params);
  }

  getCourierSummaryDetailV2(
    params: CourierDetailApiRequest
  ): Promise<CourierSummary> {
    return this.repository.getCourierSummaryDetailV2(params);
  }

  getCourierDetail(
    params: CourierDetailApiRequest
  ): Promise<CourierDetailList> {
    return this.repository.getCourierDetail(params);
  }

  getCourierDetailV2(
    params: CourierDetailApiRequest
  ): Promise<CourierDetail[]> {
    return this.repository.getCourierDetailV2(params);
  }

  getDetailCourierPendingReconcile(
    params: CourierDetailApiRequest
  ): Promise<CourierDetailPendingReconcile[]> {
    return this.repository.getDetailCourierPendingReconcile(params);
  }

  getDashboardDeliveryCourierDetail(
    phone: string 
  ): Promise<DashboardDeliveryCourierDetail> {
    return this.repository.getDashboardDeliveryCourierDetail(phone);
  }
}
