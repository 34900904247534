import { CARGO, READY_TO_CARGO } from "@/app/infrastructures/misc/RolePermission";
import { flags } from "@/feature-flags";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

// Cargo
const CargoDetail = () =>
  import(
    /* webpackChunkName: "Cargo" */ "@/app/ui/views/cargo/component/detail.vue"
  );
const CargoCreate = () =>
  import(
    /* webpackChunkName: "Cargo" */ "@/app/ui/views/cargo/booking-cargo/index.vue"
  );
const CargoEdit = () =>
  import(
    /* webpackChunkName: "Cargo" */ "@/app/ui/views/cargo/component/edit.vue"
  );

  const ReadyToCargoCreate = () =>
  import(
    /* webpackChunkName: "RTCargo" */ "@/app/ui/views/cargo/ready-to-cargo/create/index.vue"
  );

export default [
  {
    path: "/cargo",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Booking Kargo",
      permission: [CARGO.DETAIL, flags.permission_single_menu_cargo_enable]
    },
    children: [
      {
        path: ":id",
        name: "detail-cargo",
        component: CargoDetail,
        meta: {
          header: true,
          title: "Booking Kargo | Detail"
        }
      }
    ]
  },
  {
    path: "/cargo",
    component: Layout,
    name: "create-cargo",
    meta: {
      hidden: true,
      parent: "Booking Kargo",
      permission: [CARGO.CREATE, flags.permission_single_menu_cargo_enable]
    },
    children: [
      {
        path: "create",
        name: "create-cargo",
        component: CargoCreate,
        meta: {
          header: true,
          title: "Booking Kargo | Create ",
          name: "Booking Kargo",
          before: "/cargo"
        }
      }
    ]
  },
  {
    path: "/cargo",
    component: Layout,
    name: "edit-cargo",
    meta: {
      hidden: true,
      parent: "Booking Kargo",
      permission: [CARGO.EDIT, flags.permission_single_menu_cargo_enable]
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-cargo",
        component: CargoEdit,
        meta: {
          header: true,
          title: "Booking Kargo | Edit ",
          name: "Edit Booking Kargo"
        }
      }
    ]
  },
  {
    path: "/siap-kargo",
    component: Layout,
    name: "siap-kargo-create",
    meta: {
      hidden: true,
      parent: "Siap DiKargo",
      permission: READY_TO_CARGO.LIST
    },
    children: [
      {
        path: "create",
        name: "siap-kargo-create",
        component: ReadyToCargoCreate,
        meta: {
          header: true,
          title: "Kargo - Siap di Kargo - Tambah Data ",
          name: "Tambah Data",
          before: "/siap-kargo"
        }
      }
    ]
  },
];
