import { ClientPartnerOptions } from "./CnManifest";

export class BankDetail {
  bankId = "";
  bankName = "";
  bankAccount = "";
  bankAccountName = "";
  constructor(fields?: Partial<BankDetail>) {
    Object.assign(this, fields);
  }
}

export class CourierManagementDetail {
  id = 0;
  courierId = 0;
  fullname = "";
  phoneNumber = "";
  partnerType = "";
  announcementCourierType = "";
  courierType = "";
  vehicleType = "";
  vehiclePlate = "";
  partner = new ClientPartnerOptions();
  courierStatus = "";
  courierIsCod = false;
  bank = new BankDetail();
  constructor(fields?: Partial<CourierManagementDetail>) {
    Object.assign(this, fields);
  }
}

export class CourierManagementListApprovalHistory {
  id = 0;
  type = 0;
  status = 0;
  posName = "";
  createdAt = "";
  constructor(fields?: Partial<CourierManagementListApprovalHistory>) {
    Object.assign(this, fields);
  }
}
