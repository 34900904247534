/* eslint-disable @typescript-eslint/camelcase */
import { toFormDataUtils } from "@/app/infrastructures/misc/Utils";
import {
  AddBalanceRequestInterface,
  WithdrawBalanceRequestInterface
} from "../contracts/BalanceRequest";
import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class AddTopupManualApiRequest implements AddBalanceRequestInterface {
  amount: number;
  sender_bank_name: string;
  sender_bank_account_name: string;
  sender_bank_account_number: string;
  recepient_bank_name: string;
  recepient_bank_account_name: string;
  recepient_bank_account_number: string;
  attachment: any;
  constructor(
    amount: number,
    sender_bank_name: string,
    sender_bank_account_name: string,
    sender_bank_account_number: string,
    recepient_bank_name: string,
    recepient_bank_account_name: string,
    recepient_bank_account_number: string,
    attachment: any
  ) {
    this.amount = amount;
    this.sender_bank_name = sender_bank_name;
    this.sender_bank_account_name = sender_bank_account_name;
    this.sender_bank_account_number = sender_bank_account_number;
    this.recepient_bank_name = recepient_bank_name;
    this.recepient_bank_account_name = recepient_bank_account_name;
    this.recepient_bank_account_number = recepient_bank_account_number;
    this.attachment = attachment;
  }

  toFormData(): FormData {
    return toFormDataUtils({
      amount: this.amount,
      sender_bank_name: this.sender_bank_name,
      sender_bank_account_name: this.sender_bank_account_name,
      sender_bank_account_number: this.sender_bank_account_number,
      recepient_bank_name: this.recepient_bank_name,
      recepient_bank_account_name: this.recepient_bank_account_name,
      recepient_bank_account_number: this.recepient_bank_account_number,
      attachment: this.attachment
    });
  }
}

export class WithdrawBalanceApiRequest
  implements WithdrawBalanceRequestInterface {
  amount = 0;

  constructor(amount: number) {
    this.amount = amount;
  }

  toJSON(): string {
    return JSON.stringify({
      amount: this.amount
    });
  }
}

export class GetListBalanceApiRequest {
  cache = false;
  page = 1;
  limit = 20;
  search = "";
  startDate = "";
  endDate = "";
  type = "";
  constructor(fields?: Partial<GetListBalanceApiRequest>) {
    Object.assign(this, fields);
  }
  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
