/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { MiddlewarePresenter } from "@/app/ui/presenters/MiddlewarePresenter";
import { RequestMiddlewareList, PayloadFormMiddleware } from "@/data/payload/api/MiddlewareApiRequest";
import { MiddlewareDetail } from "@/domain/entities/Middleware";
import { AuthEntities} from "@/domain/entities/Auth";
import { setCredentialMiddleware } from "@/app/infrastructures/misc/Cookies";
@Module({ namespaced: true, dynamic: true, store, name: "middleware" })
class MiddlewareStore extends VuexModule {
    @Action
    public getListMiddleware(params: {
      type: string
      queries: RequestMiddlewareList
    }) {
      const presenter = container.resolve(MiddlewarePresenter);
      return presenter.getListMiddleware(params);
    }

    @Action
    public async formMiddleware(params: {
      method: any,
      type: string,
      payload: PayloadFormMiddleware
    }) {
      const presenter = container.resolve(MiddlewarePresenter);
      return presenter.formMiddleware(params);
    }

    @Action
    public getDetailMiddleware(params: {
      type: string,
      id: number
    }): Promise<MiddlewareDetail> {
      const presenter = container.resolve(MiddlewarePresenter);
      return presenter.getDetailMiddleware(params);
    }

    @Action
    public async getTokenMiddleware() {
      const presenter = container.resolve(MiddlewarePresenter);
      return presenter.getTokenMiddleware()
      .then((res: AuthEntities) => {
        setCredentialMiddleware({ token: res.token})
      })
    }
}

export const MiddlewareController = getModule(MiddlewareStore);
