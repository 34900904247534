import { Pagination } from "./Pagination";

export class TopupApprovalListEntities {
  data: TopupApprovalData[];
  pagination: Pagination;
  constructor(pagination: Pagination, data: TopupApprovalData[]) {
    this.data = data;
    this.pagination = pagination;
  }
}

export class TopupApprovalData {
  tumId = 0;
  tumSenderBankName = "";
  tumSenderBankAccountName = "";
  tumSenderBankAccountNumber = "";
  tumRecepientBankName = "";
  tumRecepientBankAccountName = "";
  tumRecepientBankAccountNumber = "";
  tumAmount = 0;
  tumVerifiedAmount = 0;
  tumApprovedAmount = 0;
  tumStatus = "";
  tumRejectReason = "";
  tumFileOfProof = "";
  tumActorId = 0;
  tumActorType = "";
  tumActorName = "";
  tumActorCode = "";
  tumCreatedAt = "";
  tumCreatedBy = 0;
  tumCreatedName = "";
  tumUpdatedAt = "";
  tumUpdatedBy = "";
  tumVerificationBy = "";
  tumVerificationName = "";
  tumVerificationAt = "";
  tumApprovalBy = "";
  tumApprovalName = "";
  tumApprovalAt = "";
  genesisTransactionId = "";
  constructor(fields?: Partial<TopupApprovalData>) {
    Object.assign(this, fields);
  }
}

export class ActorData {
  actorId: number;
  actorName: string;
  constructor(actorId: number, actorName: string) {
    this.actorId = actorId;
    this.actorName = actorName;
  }
}
