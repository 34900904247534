/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionCodReconcile = {
  permission_cod_reconcile_enable: new Rox.Flag(false),
  permission_cod_reconcile_view_detail: new Rox.Flag(false),
  permission_cod_reconcile_deposit: new Rox.Flag(false),
  permission_cod_reconcile_pic_enable: new Rox.Flag(false),
  permission_cod_reconcile_pic_view_detail: new Rox.Flag(false),
  permission_cod_reconcile_pic_deposit: new Rox.Flag(false),
  permission_cod_reconcile_report_enable: new Rox.Flag(false)
};

export function initFlagsPermissionCodReconcile() {
  // register flags with namespaces for better grouping
  Rox.register("featureCodReconcile", FlagsPermissionCodReconcile);
}
