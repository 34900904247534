import { PartnerSubconsoleMapper } from "@/data/persistences/mappers/PartnerSubconsoleMapper";
import { PartnerSubconsoleRepositoryInterface } from "@/data/persistences/repositories/contracts/PartnerSubconsoleRepositoryInterface";
import { PartnerSubconsoleEntities } from "@/domain/entities/PartnerSubconsole";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class PartnerSubconsoleApiRepository
  implements PartnerSubconsoleRepositoryInterface {
  private service: ApiService;
  private mapper: PartnerSubconsoleMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: PartnerSubconsoleMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getPartnerSubconsoleList(
    search: string,
    page: number,
    limit: number,
    useLocation: boolean,
    isDefaultLocation: boolean,
    cityCode: string,
    districtCode: string,
    status: string,
    contractStatus: string
  ): Promise<PartnerSubconsoleEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getPartnerSubconsoleList(
        search,
        page,
        limit,
        useLocation,
        isDefaultLocation,
        cityCode,
        districtCode,
        status,
        contractStatus
      ),
      {}
    );
    return this.mapper.convertPartnerSubconsoleDataFromApi(resp);
  }
}
