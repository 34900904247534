/* eslint-disable @typescript-eslint/camelcase */
import { AxiosResponse } from "axios";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  CargoConfigEntities,
  CargoConfigListData,
  CargoConfiguration,
  ClientBranch,
  CutOffTimeConfigCargo
} from "@/domain/entities/CargoConfiguration";
import { Pagination } from "@/domain/entities/Pagination";
import {
  SchedulePlane,
  SchedulePlaneCargo,
  SchedulePlaneCargoDetail
} from "@/domain/entities/CargoConfiguration";
import { CityData } from "@/domain/entities/Location";
import { Commodity } from "@/domain/entities/Commodity";
import moment from "moment";
import { VendorData } from "@/domain/entities/Vendor";
import { OptionsClass } from "@/domain/entities/MainApp";
import { dayOfWeekList, optionFlightBreak, optionFlightBreakTransit } from "@/app/infrastructures/misc/Constants";
import { formatTimeNumber } from "@/app/infrastructures/misc/Utils";
import { flags } from "@/feature-flags"

export class CargoConfigurationMapper {
  public convertChangeDataFromApi(result: AxiosResponse): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertListDataFromApi(result: AxiosResponse): CargoConfigEntities {
    const { data } = result;
    const cargoConfigListData: CargoConfigListData[] = [];
    if (data.data.length > 0) {
      data.data.forEach((item: any) => {
        return cargoConfigListData.push(
          new CargoConfigListData({
            id: item.cargo_configuration_id,
            configName: item.cargo_configuration_name,
            configCode: item.cargo_configuration_code,
            configTransport: item.cargo_configuration_transport,
            configStatus: item.cargo_configuration_status,
            clientName: item.cargo_configuration_client_parent_name || "-",
            clientId: item.cargo_configuration_client_parent_id
          })
        );
      });
    }

    const pagination = new Pagination(
      data.meta.page,
      data.meta.limit,
      data.meta.total_records
    );

    return new CargoConfigEntities(pagination, cargoConfigListData);
  }

  public convertDetailDataFromApi(result: AxiosResponse): CargoConfiguration {
    const {
      data: { data }
    } = result;
    const dataConfig = data.cargo_configuration;
    const dataCommodities = JSON.parse(dataConfig.cc_comodities_code);
    const dataProductType = JSON.parse(dataConfig.cc_product_type);
    const tresholdDefaultValue = flags.cargo_config_flight_treshold_default_value.getValue();
    const formatTime = new CutOffTimeConfigCargo().formatTime;
    return new CargoConfiguration({
      id: dataConfig.cc_id,
      originCity: new CityData({
        code: dataConfig.cc_origin,
        name: dataConfig.cc_origin_name
      }),
      destinationCity: new CityData({
        code: dataConfig.cc_destination,
        name: dataConfig.cc_destination_name
      }),
      transportationType: dataConfig.cc_mode_transport.match(
        /TRUCK|TRAIN|SHIP|PLANE/gi
      )
        ? new OptionsClass({
            name: new CargoConfiguration().getTransportationName(
              dataConfig.cc_mode_transport.toUpperCase()
            ),
            value: dataConfig.cc_mode_transport.toUpperCase()
          })
        : new OptionsClass({
            name: dataConfig.cc_mode_transport,
            value: new CargoConfiguration().getTransportationKey(
              dataConfig.cc_mode_transport
            )
          }),
      minimumWeight: dataConfig.cc_minimum_weight?.toString().replace(".", ",") || 0,
      maximumWeight: dataConfig.cc_maximum_weight?.toString().replace(".", ",") || 0,
      minimumVolume: dataConfig.cc_minimum_volume?.toString().replace(".", ",") || 0,
      maximumVolume: dataConfig.cc_maximum_volume?.toString().replace(".", ",") || 0,
      vendor: dataConfig.cc_vendor_code
        ? new VendorData({
            code: dataConfig.cc_vendor_code,
            name: dataConfig.cc_vendor_name
          })
        : "",
      clientParentId: dataConfig.cc_client_parent_id,
      clientParentName: dataConfig.cc_client_parent_name || "-",
      clientBranch: dataConfig.cc_client_branch ? dataConfig.cc_client_branch.map((branch: any) => new ClientBranch({
        clientBranchId: branch.cc_client_branch_id,
        clientBranchName: branch.cc_client_branch_name
      })) : [],
      status: dataConfig.cc_status.toLowerCase() === "active",
      createdBy: dataConfig.cc_created_name,
      updatedBy: dataConfig.cc_updated_name,
      createdAt: dataConfig.cc_created_at,
      updatedAt: dataConfig.cc_updated_at,
      commodities: dataCommodities.map((commodity: any) => {
        return new Commodity({
          commodity_name: commodity.Name,
          commodity_code: commodity.Code
        });
      }),
      products: dataProductType,
      schedulePlane: dataConfig.cargo_flight
        ? dataConfig.cargo_flight.map(
            (flight: any) =>
              new SchedulePlaneCargo({
                timeStart: moment(flight.cargo_flight_start_time).toISOString(),
                timeEnd: formatTimeNumber(flight.cargo_flight_end_time),
                schedule: [
                  new SchedulePlaneCargoDetail({
                    flightDay: dayOfWeekList.find(
                      (item: OptionsClass) =>
                        item.value === flight.cargo_flight_day
                    ),
                    flightNumber: flight.cargo_flight_number,
                    flightDepartureDate: moment(
                      flight.cargo_flight_departure_time
                    ).toISOString(),
                    flightArrivalDate: moment(
                      flight.cargo_flight_arrival_time
                    ).toISOString(),
                    flightArrivalHourOnly: flight.cargo_flight_arrival_hour,
                    flightDepartureHourOnly: flight.cargo_flight_departure_hour,
                    flightAirportOrigin: flight.cargo_flight_origin,
                    flightAirportDestination: flight.cargo_flight_destination,
                    flightDayTransit: flight.cargo_flight_day_transit,
                    flightTreshold: flight.cargo_flight_treshold ? flight.cargo_flight_treshold : tresholdDefaultValue
                  }),
                  ...flight.cargo_flight_transit.map(
                    (flightTransit: any) =>
                      new SchedulePlaneCargoDetail({
                        flightDay: { name: "-", value: "-", id: 9 },
                        flightNumber: flightTransit.cargo_flight_number,
                        flightDepartureDate: moment(
                          flightTransit.cargo_flight_departure_time
                        ).toISOString(),
                        flightArrivalDate: moment(
                          flightTransit.cargo_flight_arrival_time
                        ).toISOString(),
                        flightArrivalHourOnly:
                          flightTransit.cargo_flight_arrival_hour,
                        flightDepartureHourOnly:
                          flightTransit.cargo_flight_departure_hour,
                        flightAirportOrigin: flightTransit.cargo_flight_origin,
                        flightAirportDestination:
                          flightTransit.cargo_flight_destination,
                        flightDayTransit: flightTransit.cargo_flight_day_transit,
                        flightTreshold: flightTransit.cargo_flight_treshold ? flightTransit.cargo_flight_treshold : tresholdDefaultValue
                      })
                  )
                ]
              })
          )
        : [],
      schedulePlaneForm: dataConfig.cargo_flight
        ? dataConfig.cargo_flight.map(
            (flight: any, index: number) =>
              new SchedulePlane({
                idParent: index,
                ctId: dataConfig.cargo_configuration_cut_off_time?.length
                  ? dataConfig.cargo_configuration_cut_off_time.find(
                      (e: any) => {
                        return (
                          e.ct_flight_day === flight.cargo_flight_day &&
                          e.ct_time ===
                            formatTimeNumber(flight.cargo_flight_end_time)
                        );
                      }
                    )?.ct_id
                  : 0,
                endTime: moment(flight.cargo_flight_end_time).toISOString(),
                flightNo: flight.cargo_flight_number,
                flightDay: dayOfWeekList.find(
                  (item: OptionsClass) => item.value === flight.cargo_flight_day
                ),
                departureDate: moment(
                  flight.cargo_flight_departure_time
                ).toISOString(),
                arrivalDate: moment(
                  flight.cargo_flight_arrival_time
                ).toISOString(),
                departureTime:
                  new Date().toString().slice(0, 16) +
                  `${flight.cargo_flight_departure_hour} GMT+0700 (Western Indonesia Time)`,
                arrivalTime:
                  new Date().toString().slice(0, 16) +
                  `${flight.cargo_flight_arrival_hour} GMT+0700 (Western Indonesia Time)`,
                isTransit: false,
                orderTransit: 1,
                airportOrigin: flight.cargo_flight_origin,
                airportDestination: flight.cargo_flight_destination,
                dayTransit: optionFlightBreak.find((e: any) => e.value === flight.cargo_flight_day_transit),
                flightTreshold: flight.cargo_flight_treshold ? flight.cargo_flight_treshold : tresholdDefaultValue,
                transit: flight.cargo_flight_transit.map(
                  (flightTransit: any, indexTransit: number) =>
                    new SchedulePlane({
                      flightDay: dayOfWeekList.find(
                        (item: OptionsClass) =>
                          item.value === flightTransit.cargo_flight_day
                      ),
                      flightNo: flightTransit.cargo_flight_number,
                      endTime: moment(
                        flightTransit.cargo_flight_end_time
                      ).format("HH:mm"),
                      departureDate: moment(
                        flightTransit.cargo_flight_departure_time
                      ).toISOString(),
                      arrivalDate: moment(
                        flightTransit.cargo_flight_arrival_time
                      ).toISOString(),
                      departureTime:
                        new Date().toString().slice(0, 16) +
                        `${flightTransit.cargo_flight_departure_hour} GMT+0700 (Western Indonesia Time)`,
                      arrivalTime:
                        new Date().toString().slice(0, 16) +
                        `${flightTransit.cargo_flight_arrival_hour} GMT+0700 (Western Indonesia Time)`,
                      isTransit: true,
                      orderTransit: indexTransit + 2,
                      idParent: index,
                      airportOrigin: flightTransit.cargo_flight_origin,
                      airportDestination:
                        flightTransit.cargo_flight_destination,
                      dayTransit: optionFlightBreakTransit.find((e: any) => e.value === flightTransit.cargo_flight_day_transit),
                      flightTreshold: flightTransit.cargo_flight_treshold ? flightTransit.cargo_flight_treshold : tresholdDefaultValue,
                    })
                ),
                key: index
              })
          )
        : [],
      cargoConfigurationCutOffTime: dataConfig.cargo_configuration_cut_off_time
        ?.length
        ? dataConfig.cargo_configuration_cut_off_time
            .filter((e: any) => !!e.ct_status.match(/^active/gi))
            .map((cutOff: any, index: number) => {
              return new CutOffTimeConfigCargo({
                ctStatus: cutOff.ct_status,
                ctId: cutOff.ct_id,
                ctCcId: cutOff.ct_cc_id,
                ctTime: formatTime && formatTime(cutOff.ct_time),
                ctFlightNumber: cutOff.ct_flight_number,
                ctFlightDay: cutOff.ct_flight_day,
                ctFlight: cutOff.ct_flight,
                key: index
              });
            })
        : [new CutOffTimeConfigCargo()],
      maximumPieces: dataConfig.cc_max_piece_limit
    });
  }

  public convertPayloadData(res: AxiosResponse<any>): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertListCargoRecomendationDataFromApi(
    result: AxiosResponse
  ): CargoConfiguration[] {
    const {
      data: { data }
    } = result;

    if (data.cargo_configuration.length) {
      return data.cargo_configuration.map((dataConfig: any) => {
        const dataCommodities = JSON.parse(dataConfig.cc_comodities_code);
        const dataProductType = JSON.parse(dataConfig.cc_product_type);

        return new CargoConfiguration({
          id: dataConfig.cc_id,
          originCity: new CityData({
            code: dataConfig.cc_origin,
            name: dataConfig.cc_origin_name
          }),
          destinationCity: new CityData({
            code: dataConfig.cc_destination,
            name: dataConfig.cc_destination_name
          }),
          transportationType: dataConfig.cc_mode_transport.match(
            /TRUCK|TRAIN|SHIP|PLANE/gi
          )
            ? new OptionsClass({
                name: new CargoConfiguration().getTransportationName(
                  dataConfig.cc_mode_transport.toUpperCase()
                ),
                value: dataConfig.cc_mode_transport.toLowerCase()
              })
            : new OptionsClass({
                name: dataConfig.cc_mode_transport,
                value: new CargoConfiguration().getTransportationKey(
                  dataConfig.cc_mode_transport
                )
              }),
          minimumWeight: dataConfig.cc_minimum_weight,
          maximumWeight: dataConfig.cc_maximum_weight,
          minimumVolume: dataConfig.cc_minimum_volume,
          maximumVolume: dataConfig.cc_maximum_volume,
          vendor: dataConfig.cc_vendor_code
            ? new VendorData({
                code: dataConfig.cc_vendor_code,
                name: dataConfig.cc_vendor_name
              })
            : "",
          status: dataConfig.cc_status.toLowerCase() === "active",
          createdBy: dataConfig.cc_created_name,
          updatedBy: dataConfig.cc_updated_name,
          createdAt: dataConfig.cc_created_at,
          updatedAt: dataConfig.cc_updated_at,
          commodities: dataCommodities.map(
            (commodity: any) =>
              new Commodity({
                commodity_name: commodity.Name,
                commodity_code: commodity.Code
              })
          ),
          products: dataProductType,
          schedulePlane: dataConfig.cargo_flight.length
            ? dataConfig.cargo_flight.map(
                (flight: any) =>
                  new SchedulePlaneCargo({
                    timeStart: moment(
                      flight.cargo_flight_start_time
                    ).toISOString(),
                    timeEnd: moment(flight.cargo_flight_end_time).toISOString(),
                    schedule: [
                      new SchedulePlaneCargoDetail({
                        flightDay: dayOfWeekList.find(
                          (item: OptionsClass) =>
                            item.value === flight.cargo_flight_day
                        ),
                        flightNumber: flight.cargo_flight_number,
                        flightDepartureDate: moment(
                          flight.cargo_flight_departure_time
                        ).toISOString(),
                        flightArrivalDate: moment(
                          flight.cargo_flight_arrival_time
                        ).toISOString()
                      }),
                      ...flight.cargo_flight_transit.map(
                        (flightTransit: any) =>
                          new SchedulePlaneCargoDetail({
                            flightDay: dayOfWeekList.find(
                              (item: OptionsClass) =>
                                item.value === flightTransit.cargo_flight_day
                            ),
                            flightNumber: flightTransit.cargo_flight_number,
                            flightDepartureDate: moment(
                              flightTransit.cargo_flight_departure_time
                            ).toISOString(),
                            flightArrivalDate: moment(
                              flightTransit.cargo_flight_arrival_time
                            ).toISOString()
                          })
                      )
                    ]
                  })
              )
            : [],
          schedulePlaneForm: dataConfig.cargo_flight.length
            ? dataConfig.cargo_flight.map(
                (flight: any, index: number) =>
                  new SchedulePlane({
                    idParent: index,
                    endTime: moment(flight.cargo_flight_end_time).toISOString(),
                    flightNo: flight.cargo_flight_number,
                    flightDay: dayOfWeekList.find(
                      (item: OptionsClass) =>
                        item.value === flight.cargo_flight_day
                    ),
                    departureDate: moment(
                      flight.cargo_flight_departure_time
                    ).toISOString(),
                    arrivalDate: moment(
                      flight.cargo_flight_arrival_time
                    ).toISOString(),
                    departureTime: moment(
                      flight.cargo_flight_departure_time
                    ).toISOString(),
                    arrivalTime: moment(
                      flight.cargo_flight_arrival_time
                    ).toISOString(),
                    isTransit: false,
                    orderTransit: 1,
                    transit: flight.cargo_flight_transit.map(
                      (flightTransit: any, indexTransit: number) =>
                        new SchedulePlane({
                          flightDay: dayOfWeekList.find(
                            (item: OptionsClass) =>
                              item.value === flightTransit.cargo_flight_day
                          ),
                          flightNo: flightTransit.cargo_flight_number,
                          endTime: moment(
                            flightTransit.cargo_flight_end_time
                          ).format("HH:mm"),
                          departureDate: moment(
                            flightTransit.cargo_flight_departure_time
                          ).toISOString(),
                          arrivalDate: moment(
                            flightTransit.cargo_flight_arrival_time
                          ).toISOString(),
                          departureTime: moment(
                            flightTransit.cargo_flight_departure_time
                          ).toISOString(),
                          arrivalTime: moment(
                            flightTransit.cargo_flight_arrival_time
                          ).toISOString(),
                          isTransit: true,
                          orderTransit: indexTransit + 2,
                          idParent: index
                        })
                    )
                  })
              )
            : []
        });
      });
    }
    return [];
  }
}
