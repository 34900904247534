import { Pagination, PaginationV2 } from "@/domain/entities/Pagination";
import { QueryParamsEntities } from "./MainApp";

export class StiSCEntities {
  pagination: Pagination;
  data: StiSCData[];

  constructor(pagination: Pagination, data: StiSCData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class RequestListSTISC {
  page? = 1
  limit? = 20;
  startDate? = "";
  endDate? = "";
  isTotalData = true;
  isStoScPurposes = true;

  constructor(fields?: Partial<RequestListSTISC>) {
    Object.assign(this, fields);
  }

  
  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class StiSCData {
  stiSCId: number;
  stiSCPartnerId: number;
  stiSCPartnerCode: string;
  stiSCPartnerName: string;
  stiSCTotalStt: number;
  stiSCTotalGrossWeight: number;
  stiSCTotalVolumeWeight: number;
  stiSCTotalPieces: number;
  stiSCCreatedAt: string;
  stiSCCreatedBy: number;
  stiSCCreatedName: string;
  stiSCUpdatedAt: string;
  constructor(
    stiSCId: number,
    stiSCPartnerId: number,
    stiSCPartnerCode: string,
    stiSCPartnerName: string,
    stiSCTotalStt: number,
    stiSCTotalGrossWeight: number,
    stiSCTotalVolumeWeight: number,
    stiSCTotalPieces: number,
    stiSCCreatedAt: string,
    stiSCCreatedBy: number,
    stiSCCreatedName: string,
    stiSCUpdatedAt: string,
  ) {
    this.stiSCId = stiSCId;
    this.stiSCPartnerId = stiSCPartnerId;
    this.stiSCPartnerCode = stiSCPartnerCode;
    this.stiSCPartnerName = stiSCPartnerName;
    this.stiSCTotalStt = stiSCTotalStt;
    this.stiSCTotalGrossWeight = stiSCTotalGrossWeight;
    this.stiSCTotalVolumeWeight = stiSCTotalVolumeWeight;
    this.stiSCTotalPieces = stiSCTotalPieces;
    this.stiSCCreatedAt = stiSCCreatedAt;
    this.stiSCCreatedBy = stiSCCreatedBy;
    this.stiSCCreatedName = stiSCCreatedName;
    this.stiSCUpdatedAt = stiSCUpdatedAt;
  }
}

export class SttPiecesStiScDetail {
  isAllowUpdateStatus: boolean;
  errorMessage: string;
  sttNumber: string;
  sttElexysNumber?: string;
  piece: PieceDetail[];
  product: string;
  totalPieces: number;
  grossWeight: number;
  volumeWeight: number;
  origin: string;
  destination: string;
  isPaid?: boolean;
  constructor(
    isAllowUpdateStatus: boolean,
    errorMessage: string,
    sttNumber: string,
    sttElexysNumber: string,
    piece: PieceDetail[],
    product: string,
    totalPieces: number,
    grossWeight: number,
    volumeWeight: number,
    origin: string,
    destination: string,
    isPaid?: boolean
  ) {
    this.sttNumber = sttNumber;
    this.sttElexysNumber = sttElexysNumber;
    this.isAllowUpdateStatus = isAllowUpdateStatus;
    this.errorMessage = errorMessage;
    this.piece = piece;
    this.product = product;
    this.totalPieces = totalPieces;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.origin = origin;
    this.destination = destination;
    this.isPaid = isPaid;
  }
}

export class STTToBeUpdate {
  sttNumber: string;
  sttElexysNumber?: string;
  sttPieces: PieceDetail[];
  product: string;
  totalPieces: number;
  grossWeight: number;
  volumeWeight: number;
  origin: string;
  destination: string;

  constructor(
    sttNumber: string,
    sttElexysNumber: string,
    sttPieces: PieceDetail[],
    product: string,
    totalPieces: number,
    grossWeight: number,
    volumeWeight: number,
    origin: string,
    destination: string
  ) {
    this.sttNumber = sttNumber;
    this.sttElexysNumber = sttElexysNumber;
    this.sttPieces = sttPieces;
    this.product = product;
    this.totalPieces = totalPieces;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.origin = origin;
    this.destination = destination;
  }
}

export class StiScProcessData {
  sttNumber = "";
  product = "";
  totalPieces = 0;
  grossWeight = 0;
  volumeWeight = 0;
  destination = "";
  status = "";
  message = "";
  noRefExternal = "";
  constructor(fields?: Partial<StiScProcessData>) {
    Object.assign(this, fields);
  }
}

export class SttPiecesDetail {
  grossWeight = 0;
  volumeWeight = 0;
  constructor(fields?: Partial<SttPiecesDetail>) {
    Object.assign(this, fields);
  }
}

export class PieceDetail {
  sttNumber: string;
  sttElexys?: string;
  sttPieceNumber: string;
  pieceNumber: number;
  destinationCity: string;
  product: string;
  woodPacking: string;
  commodity: string;
  pOrPl: string;
  codAmount: string;
  grossWeight: number;
  volumeWeight: number;
  bookingDate: string;
  lastStatus: string;
  posName: string;
  constructor(
    sttNumber: string,
    sttElexys: string,
    sttPieceNumber: string,
    pieceNumber: number,
    cityDestination: string,
    product: string,
    woodpacking: string,
    commodity: string,
    pOrPl: string,
    codAmount: string,
    grossWeight: number,
    volumeWeight: number,
    bookingDate: string,
    lastStatus: string,
    posName: string
  ) {
    this.sttNumber = sttNumber;
    this.sttElexys = sttElexys;
    this.sttPieceNumber = sttPieceNumber;
    this.pieceNumber = pieceNumber;
    this.destinationCity = cityDestination;
    this.product = product;
    this.woodPacking = woodpacking;
    this.commodity = commodity;
    this.pOrPl = pOrPl;
    this.codAmount = codAmount;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.bookingDate = bookingDate;
    this.lastStatus = lastStatus;
    this.posName = posName;
  }
}

export class ValidationScanSTTData {
  isAllowUpdateStatus: boolean;
  errorMessage: string;

  constructor(isAllowUpdateStatus: boolean, errorMessage: string) {
    this.isAllowUpdateStatus = isAllowUpdateStatus;
    this.errorMessage = errorMessage;
  }
}

export class StiScManifestData {
  stiPartnerId: number;
  stiPartnerName: string;
  stiCityName: string;
  stiCityCode: string;
  stiTotalPieces: number;
  stiTotalStt: number;
  stiTotalGrossWeight: number;
  stiTotalVolumeWeight: number;
  stiCreatedAt: string;
  stt: StiScSTTManifestDetail[];
  constructor(
    stiPartnerId: number,
    stiPartnerName: string,
    stiCityName: string,
    stiCityCode: string,
    stiTotalPieces: number,
    stiTotalStt: number,
    stiTotalGrossWeight: number,
    stiTotalVolumeWeight: number,
    stiCreatedAt: string,
    stt: StiScSTTManifestDetail[]
  ) {
    this.stiPartnerId = stiPartnerId;
    this.stiPartnerName = stiPartnerName;
    this.stiCityName = stiCityName;
    this.stiCityCode = stiCityCode;
    this.stiTotalPieces = stiTotalPieces;
    this.stiTotalStt = stiTotalStt;
    this.stiTotalGrossWeight = stiTotalGrossWeight;
    this.stiTotalVolumeWeight = stiTotalVolumeWeight;
    this.stiCreatedAt = stiCreatedAt;
    this.stt = stt;
  }
}

export class StiScSTTManifestDetail {
  sttId: number;
  sttNo: string;
  sttNoElexys?: string;
  sttProductType: string;
  sttTotalPiece: number;
  sttDestinationCityId: string;
  sttCommodityCode: string;
  sttGrossWeight: number;
  sttVolumeWeight: number;
  sttChargeableWeight: number;
  sttCodAmount: number;
  sttLastStatusId: string;
  piece: StiScSTTPieceManifestDetail[];
  sttOriginCityId: string;
  sttOriginCityName: string;
  sttDestinationCityName: string;
  constructor(
    sttId: number,
    sttNo: string,
    sttNoElexys: string,
    sttProductType: string,
    sttTotalPiece: number,
    sttDestinationCityId: string,
    sttCommodityCode: string,
    sttGrossWeight: number,
    sttVolumeWeight: number,
    sttChargeableWeight: number,
    sttCodAmount: number,
    sttLastStatusId: string,
    piece: StiScSTTPieceManifestDetail[],
    sttOriginCityId: string,
    sttOriginCityName: string,
    sttDestinationCityName: string
  ) {
    this.sttId = sttId;
    this.sttNo = sttNo;
    this.sttNoElexys = sttNoElexys;
    this.sttProductType = sttProductType;
    this.sttTotalPiece = sttTotalPiece;
    this.sttDestinationCityId = sttDestinationCityId;
    this.sttCommodityCode = sttCommodityCode;
    this.sttGrossWeight = sttGrossWeight;
    this.sttVolumeWeight = sttVolumeWeight;
    this.sttChargeableWeight = sttChargeableWeight;
    this.sttCodAmount = sttCodAmount;
    this.sttLastStatusId = sttLastStatusId;
    this.piece = piece;
    this.sttOriginCityId = sttOriginCityId;
    this.sttOriginCityName = sttOriginCityName;
    this.sttDestinationCityName = sttDestinationCityName;
  }
}

export class StiScSTTPieceManifestDetail {
  sttPieceId: number;
  sttPieceGrossWeight: number;
  sttPieceVolumeWeight: number;
  sttPieceNo: number;
  sttPieceLastStatusId: string;
  constructor(
    sttPieceId: number,
    sttPieceGrossWeight: number,
    sttPieceVolumeWeight: number,
    sttPieceNo: number,
    sttPieceLastStatusId: string
  ) {
    this.sttPieceId = sttPieceId;
    this.sttPieceGrossWeight = sttPieceGrossWeight;
    this.sttPieceVolumeWeight = sttPieceVolumeWeight;
    this.sttPieceNo = sttPieceNo;
    this.sttPieceLastStatusId = sttPieceLastStatusId;
  }
}

export class ResponseSTISCCreateSession {
  manifestId = 0;
  manifestNo = "";
  constructor(fields?: Partial<ResponseSTISCCreateSession>) {
    Object.assign(this, fields);
  }
}
export class STISCSession {
  manifestId = 0;
  totalSTT = 0;
  totalGrossWeight = 0;
  totalPieces = 0;
  totalVolumeWeight = 0;
  stiSCList: StiScProcessData[] = []
  constructor(fields?: Partial<STISCSession>) {
    Object.assign(this, fields);
  }
}
export class ResponseSTISCSession {
  pagination = new PaginationV2();
  data = new STISCSession();
  constructor(fields?: Partial<ResponseSTISCSession>) {
    Object.assign(this, fields);
  }

}

export class RequestListSTISCSession {
  page = 1;
  limit = 20;
  isTotalData = true;
  constructor(fields?: Partial<RequestListSTISCSession>) {
    Object.assign(this, fields);
  }
  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class PayloadProcessUpdateSTISC {
  sttNo = "";
  manifestId = 0;
  constructor(fields?: Partial<PayloadProcessUpdateSTISC>) {
    Object.assign(this, fields);
  }
}

export class PayloadFinishSessionSTISC {
  manifestId = 0;
  constructor(fields?: Partial<PayloadFinishSessionSTISC>) {
    Object.assign(this, fields);
  }
}

export class ResponseFinishSessionSTISC {
  stiScId = 0;
  constructor(fields?: Partial<ResponseFinishSessionSTISC>) {
    Object.assign(this, fields);
  }
}
