import { ListPickupApiRepository } from "@/app/infrastructures/repositories/api/ListPickupApiRepository";
import {
  ListPickupList,
  RequestListPickupList,
} from "@/domain/entities/ListPickup";
import { injectable } from "tsyringe";
import { PickupFormApiRequest } from "@/data/payload/api/PickupApiRequest";
import {
  CancelPickupApiRequest,
  CreatePickupSuccessResponse,
  ListPickupDetail,
} from "@/domain/entities/ListPickupDetail";

@injectable()
export class ListPickupPresenter {
  private repository: ListPickupApiRepository;
  constructor(repo: ListPickupApiRepository) {
    this.repository = repo;
  }

  public getListPickupList(
    params: RequestListPickupList
  ): Promise<ListPickupList> {
    return this.repository.getListPickupList(params);
  }

  public getListPickupDetail(id: string): Promise<ListPickupDetail> {
    return this.repository.getListPickupDetail(id);
  }

  public cancelPickup(payload: CancelPickupApiRequest): Promise<boolean> {
    return this.repository.cancelPickup(payload);
  }

  public createPickup(
    payload: PickupFormApiRequest
  ): Promise<CreatePickupSuccessResponse> {
    return this.repository.createPickup(payload);
  }
}
