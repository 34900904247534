import {
  LocationSearchEntities,
  LocationSearchData
} from "@/domain/entities/LocationSearch";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";

export class LocationSearchMapper {
  public convertLocationSearchFromApi(
    res: AxiosResponse<any>
  ): LocationSearchEntities {
    const { data } = res;

    const locationSearchList: LocationSearchData[] = [] || null;
    if (data.data === []) {
      [];
    } else {
      data.data.forEach((item: any) => {
        locationSearchList.push(
          new LocationSearchData(
            item.id,
            item.code,
            item.ursa_code,
            item.name,
            item.type
          )
        );
      });
    }
    const pagination = new Pagination(data.meta.page, data.meta.limit);
    return new LocationSearchEntities(pagination, locationSearchList);
  }
}
