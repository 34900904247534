const formatInputMoneyWithDecimal = (val: any, maxDecimal?: number) => {
  val = val.toString();
  const splitVal = val.split(",");

  val = val
    .replace(/[^0-9,]+/g, "")
    .replace(/^,$/g, "")
    .replace(/,+/g, ",")
    .replace(/,(\d+),/g, ",$1");

  const lastMaxDecimal =
    maxDecimal && maxDecimal - 1
      ? `,${Array(maxDecimal - 1)
          .fill("0")
          .join("")}`
      : "";
  const splitValMaxDecimal =
    maxDecimal && splitVal[1]?.match(/0/g)?.length >= maxDecimal
      ? `${splitVal[0]}${lastMaxDecimal}`
      : val;

  val = !parseInt(splitVal[1])
    ? splitValMaxDecimal
    : val.replace(/,(\d+)/g, ".$1");
  const splitFirst = `,${val.split(",")[1]}`;
  const formatMoney = `${parseFloat(val.split(",")[0]).toLocaleString("id", {
    maximumFractionDigits: maxDecimal
  })}${val.split(",")[1] !== undefined ? splitFirst : ""}`;
  const validFormatMoney = !formatMoney.includes("NaN") ? formatMoney : val;
  return val ? validFormatMoney : val;
};
export default formatInputMoneyWithDecimal;
