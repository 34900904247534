import { Pagination } from "@/domain/entities/Pagination";
import { LegData, LegEntities, TransportData } from "@/domain/entities/Leg";
import { AxiosResponse } from "axios";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class LegDataMapper {
  public convertLegDataFromApi(res: AxiosResponse<any>): LegEntities {
    const { data } = res;

    const leg: LegData[] = [];
    if (data.data.length === 0) {
      return new LegEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        leg.push(
          new LegData({
            id: item.leg_id,
            legName: item.leg_name,
            legCode: item.leg_code,
            legOrigin: item.leg_origin_id,
            legDestination: item.leg_destination_id,
            legType: item.leg_type,
            status: item.leg_status,
            minSla: item.leg_min_sla,
            maxSla: item.leg_max_sla,
            createdAt: item.leg_created_at,
            updatedAt: item.leg_updated_at,
            createdBy: item.leg_created_by,
            updatedBy: item.leg_updated_by,
            createdName: item.leg_created_name,
            updatedName: item.leg_updated_name,
            vendorName: item.vendor_name,
            transport: item.transport_type,
            transportId: item.transport_id
          })
        );
      });
    }
    return new LegEntities(
      new Pagination(data.meta.page, data.meta.limit),
      leg
    );
  }

  public convertDetailLegDataFromApi(res: AxiosResponse<any>): LegData {
    const { data } = res;
    return new LegData({
      id: data.data.leg_id,
      legName: data.data.leg_name,
      legCode: data.data.leg_code,
      legOrigin: data.data.leg_origin_id,
      legDestination: data.data.leg_destination_id,
      legType: data.data.leg_type,
      status: data.data.leg_status,
      minSla: data.data.leg_min_sla,
      maxSla: data.data.leg_max_sla,
      createdAt: data.data.leg_created_at,
      updatedAt: data.data.leg_updated_at,
      createdBy: data.data.leg_created_by,
      updatedBy: data.data.leg_updated_by,
      createdName: data.data.leg_created_name,
      updatedName: data.data.leg_updated_name,
      vendorName: data.data.vendor_name,
      transport: data.data.transport_type,
      transportId: data.data.transport_id
    });
  }

  public convertPayloadData(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertTransportStatusFromApi(res: AxiosResponse<any>): TransportData {
    const { data } = res;
    return new TransportData(data.data.transport_status);
  }

  public convertRouteLegDataFromApi(res: AxiosResponse<any>): LegData[] {
    const {
      data: { data }
    } = res;

    return data.map((item: any) => {
      return new LegData({
        id: item.leg_id,
        legCode: item.leg_code,
        legOrigin: item.leg_origin_id,
        legDestination: item.leg_destination_id,
        legType: item.leg_type,
        status:
          item.leg_status === "active" && item.transport_status === "active"
            ? "active"
            : "inactive",
        transport: item.transport_type,
        transportId: item.transport_id
      });
    });
  }
}
