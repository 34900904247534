import { RequestListBooking } from "@/data/payload/api/BookingRequest";
import { CnManifestMapper } from "@/data/persistences/mappers/CnManifestMapper";
import { CnManifestRepositoryInterface } from "@/data/persistences/repositories/contracts/CnManifestRepositoryInterface";
import {
  ClientPartnerOptions,
  CnManifestEntities,
  DestinationCityOptions,
  SttNumbersOptions
} from "@/domain/entities/CnManifest";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class CnManifestApiRepository implements CnManifestRepositoryInterface {
  private service: ApiService;
  private mapper: CnManifestMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: CnManifestMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getListClientPartnerOptions(
    page: number,
    limit: number,
    search: string,
    type: string,
    isParent: boolean
  ): Promise<ClientPartnerOptions[]> {
    let resp: any = "";
    if (type === "client") {
      resp = await this.service.invoke(
        "get",
        this.endpoints.getClientOptions(page, limit, search, isParent),
        {}
      );
    }
    if (type === "console" || type === "sub-console" || type === "pos") {
      resp = await this.service.invoke(
        "get",
        this.endpoints.getPartnerOptions(page, limit, search, type),
        {}
      );
    }
    if (type === "customer") {
      resp = await this.service.invoke(
        "get",
        this.endpoints.getCustomerOptions(page, limit, search),
        {}
      );
    }
    return this.mapper.convertClientPartnerOptionsFromApi(resp, type);
  }

  public async getListDestinationCityOptions(
    page: number,
    limit: number,
    search: string
  ): Promise<DestinationCityOptions[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListCity(search, "", page, limit),
      {}
    );
    return this.mapper.convertDestinationCityOptionsFromApi(resp);
  }

  public async getListSttNumbersOptions(
    params: RequestListBooking
  ): Promise<SttNumbersOptions[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getBookingList(params),
      {}
    );
    return this.mapper.convertSttNumbersFromApi(resp);
  }

  public async getListCnManifest(
    page: number,
    limit: number,
    userType: string,
    bookedBy: number,
    destinationCityId: number,
    fromDate: string,
    endDate: string,
    sttNumbers: string,
    bookedType: string
  ): Promise<CnManifestEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListStt(
        page,
        limit,
        destinationCityId,
        userType,
        bookedBy,
        fromDate,
        endDate,
        sttNumbers,
        bookedType
      ),
      {}
    );
    return this.mapper.convertCnManifestFromApi(resp);
  }

  public async exportSttManifests(
    userType: string,
    bookedBy: number,
    destinationCityId: number,
    fromDate: string,
    endDate: string,
    sttNumbers: string,
    bookedType: string
  ): Promise<string> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.exportSttManifests(
        destinationCityId,
        userType,
        bookedBy,
        fromDate,
        endDate,
        sttNumbers,
        bookedType
      ),
      {}
    );
    return this.mapper.convertExportSttFromApi(resp);
  }
}
