export const configPrice: { [x: string]: any } = {
  IDR: {
    locales: "id",
    prefix: "Rp"
  },
  MYR: {
    locales: "my",
    prefix: "RM"
  }
};
