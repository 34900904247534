/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionDexAssessment = {
  permission_dex_assessment_customer_service_l1_enable: new Rox.Flag(false),
  permission_dex_assessment_customer_service_l2_enable: new Rox.Flag(false),
  permission_dex_assessment_customer_consolidator_enable: new Rox.Flag(false),
  permission_dex_assessment_admin_enable: new Rox.Flag(false),
  permission_dex_assessment_admin_dashboard_enable: new Rox.Flag(false),
  permission_dex_assessment_dashboard_consolidator_enable: new Rox.Flag(false)
};

export function initFlagsPermissionDexAssessment() {
  // register flags with namespaces for better grouping
  Rox.register("featureDexAssessment", FlagsPermissionDexAssessment);
}
