import { BalanceTransactionRepositoryInterface } from "@/data/persistences/repositories/contracts/BalanceTransactionRepositoryInterface";
import {
  BalanceTransactionEntities,
  RequestListBalanceTransaction,
  TransactionTypeGober
} from "@/domain/entities/BalanceTransaction";
import { BalanceTransactionMapper } from "@/data/persistences/mappers/BalanceTransactionMapper";

import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class BalanceTransactionApiRepository
  implements BalanceTransactionRepositoryInterface {
  private service: ApiService;
  private mapper: BalanceTransactionMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: BalanceTransactionMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getListBalanceTransaction(
    params: RequestListBalanceTransaction
  ): Promise<BalanceTransactionEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListBalanceTransaction(params),
      {}
    );
    return this.mapper.convertBalanceTransactionFromApi(params.apiVersion, resp);
  }

  public async getBalanceTransactionType(apiVersion: string): Promise<TransactionTypeGober> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getBalanceTransactionType(apiVersion),
      {}
    );
    return this.mapper.convertBalanceTransactionTypeFromApi(resp);
  }
}
