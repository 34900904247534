import { initializeApp, FirebaseApp } from "firebase/app";
import {
  Messaging,
  getMessaging,
  getToken,
  deleteToken,
  onMessage
} from "firebase/messaging";
import { injectable } from "tsyringe";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_CONFIG_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_CONFIG_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_CONFIG_MEASUREMENT_ID
};
@injectable()
export default class FirebaseService {
  public firebaseApp: FirebaseApp;
  public firebaseMessaging: Messaging;
  constructor() {
    this.firebaseApp = initializeApp(firebaseConfig);
    this.firebaseMessaging = getMessaging(this.firebaseApp);
  }
  public getTokenFirebase(): Promise<any> {
    return getToken(this.firebaseMessaging);
  }
  public deleteTokenFirebase(): Promise<any> {
    return deleteToken(this.firebaseMessaging);
  }
  public onMessageFirebase(callback: Function) {
    onMessage(this.firebaseMessaging, payload => {
      callback(payload);
    });
  }
}
