import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import { PaginationV2 } from "@/domain/entities/Pagination";
import { DetailCodConfigurationRetail } from "@/domain/entities/CodConfiguration";

export class CodConfigurationMapper {
  public convertListDataFromApi(result: AxiosResponse<any>): ResponsePayloadV2 {
    const { data } = result;

    return new ResponsePayloadV2({
      success: data.success,
      message: data.message,
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.limit,
        totalData: data.meta.total_records
      }),
      data: data.data.length
        ? data.data.map(
            (item: any) =>
              new DetailCodConfigurationRetail({
                ccoId: item.cco_id,
                ccoName: item.cco_name,
                ccoStatus: item.cco_status,
                ccoCreatedAt: item.cco_created_at,
                ccoCreatedBy: item.cco_created_by,
                ccoUpdatedAt: item.cco_updated_at,
                ccoUpdatedBy: item.cco_updated_by,
                ccoProductType: item.cco_product_type,
                ccoMinPrice: item.cco_min_price,
                ccoMaxPrice: item.cco_max_price,
                ccoIsInsurance: item.cco_is_insurance,
              })
          )
        : []
    });
  }

  public convertDetailCodConfigurationRetailFromApi(
    res: AxiosResponse<any>
  ): DetailCodConfigurationRetail {
    const { data } = res;
    return new DetailCodConfigurationRetail({
      ccoId: data.data.cco_id,
      ccoName: data.data.cco_name,
      ccoDescription: data.data.cco_description,
      ccoProductType: data.data.cco_product_type,
      ccoMinPrice: data.data.cco_min_price,
      ccoMaxPrice: data.data.cco_max_price,
      ccoMinCodValue: data.data.cco_min_cod_value,
      ccoCodType: data.data.cco_cod_type,
      ccoIsInsurance: data.data.cco_is_insurance,
      ccoCityCode: data.data.cco_city_code.toUpperCase(),
      ccoCityName: data.data.cco_city_name.toUpperCase(),
      ccoPercentageCod: data.data.cco_percentage_cod,
      ccoOriginCommissionPercentage: data.data.cco_origin_commission_percentage,
      ccoDestinationCommissionPercentage:
        data.data.cco_destination_commission_percentage,
      ccoOriginMinCommission: data.data.cco_origin_min_commission ?? 0,
      ccoDestinationMinCommission: data.data.cco_destination_min_commission ?? 0,
      ccoIsDfod: data.data.cco_is_dfod,
      ccoStatus: data.data.cco_status,
      ccoCreatedAt: data.data.cco_created_at,
      ccoCreatedBy: data.data.cco_created_by,
      ccoUpdatedAt: data.data.cco_updated_at,
      ccoUpdatedBy: data.data.cco_updated_by
    });
  }

  public convertEditCodConfigurationDataFromApi(
    result: AxiosResponse
  ): ResponsePayloadV2 {
    const { data } = result;
    return new ResponsePayloadV2({
      success: data.success,
      message: data.message
    });
  }
}
