import { Pagination } from "./Pagination";

export class TariffEntities {
  pagination: Pagination;
  data: TariffData[];
  constructor(pagination: Pagination, data: TariffData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class TariffData {
  cityOriginId = "";
  cityDestinationId = "";
  districtOriginId = "";
  districtDestinationId = "";
  productType = "";
  commodityName = "";
  commodityNameEn = "";
  commodityId = "";
  commodityCode = "";
  accountRefId = "";
  accountRefName = "";
  originDistrictRate = 0;
  destinationDistrictRate = 0;
  baseRate = 0;
  commodityRate = 0;
  heavyWeightSurcharge = 0;
  documentSurcharge = 0;
  publishRate = 0;
  shippingSurchargeRate = 0;
  grossWeight = 0;
  length = 0;
  width = 0;
  height = 0;
  chargeableWeight = 0;
  volumeWeight = 0;
  totalTariff = 0;
  sla = "";
  isEmbargo = false;
  isPromo = false;
  totalTariffAfterDiscount = 0;
  publishRateAfterDiscount = 0;
  heavyWeightSurchargeAfterDiscount = 0;
  documentSurchargeAfterDiscount = 0;
  shippingSurchargeRateAfterDiscount = 0;
  originDistrictRateAfterDiscount = 0;
  destinationDistrictRateAfterDiscount = 0;
  commoditySurchargeAfterDiscount = 0;
  woodpackingRatesAfterDiscount = 0;
  insuranceRatesAfterDiscount = 0;
  codFeeAfterDiscount = 0;
  discountType = "";
  discount = 0;
  maximalPromo = 0;
  parameterCalculation = "";
  totalDiscount = 0;
  isCod = false;
  codFee = 0;
  dfodFee = 0;
  codReturnFee = 0;
  listDiscount: any = [];
  constructor(fields?: Partial<TariffData>) {
    Object.assign(this, fields);
  }
}
export class DetailDiscount {
  pdcId = 0;
  pdcName = "";
  pdcPromoDiscount = 10;
  pdcCategory = "";
  pdcEndDate = "";
  pdcDiscountAmount = 0;
  pdcQuantifier = "";

  constructor(fields?: Partial<DetailDiscount>) {
    Object.assign(this, fields);
  }
}
