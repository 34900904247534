/* eslint-disable @typescript-eslint/camelcase */
import { STTPaidUnpaidApiRepository } from "@/app/infrastructures/repositories/api/STTPaidUnpaidApiRepository";
import { injectable } from "tsyringe";
import { RequestSttPaidUnread, TotalSTTPaidUnread } from "@/domain/entities/STTPaidUnpaid";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { RequestListBooking } from "@/data/payload/api/BookingRequest";

@injectable()
export class STTPaidUnpaidPresenter {
  private repository: STTPaidUnpaidApiRepository;

  constructor(repository: STTPaidUnpaidApiRepository) {
    this.repository = repository;
  }

  public getTotalSTTPaidUnread(params: RequestSttPaidUnread): Promise<TotalSTTPaidUnread> {
    return this.repository.getTotalSTTPaidUnread(params);
  }

  public setLastOpenTotalSTTPaidUnread(): Promise<ResponsePayloadV2> {
    return this.repository.setLastOpenSttPaidUnread();
  }

  public getDownloadStt(
    params: RequestListBooking
  ): Promise<ResponsePayloadV2> {
    return this.repository.getDownloadStt(params);
  }
}
