import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { CargoConfigurationRepositoryInterface } from "@/data/persistences/repositories/contracts/CargoConfigurationRepositoryInterface";
import { CargoConfigurationMapper } from "@/data/persistences/mappers/CargoConfigurationMapper";
import {
  CargoConfigEntities,
  CargoConfiguration
} from "@/domain/entities/CargoConfiguration";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  CargoConfigListRequest,
  EditCargoConfigurationRequest,
  AddCargoConfigurationRequest
} from "@/data/payload/api/CargoConfigurationApiRequest";
import { CargoConfigurationRequestInterface } from "@/data/payload/contracts/CargoConfigurationRequest";
import { CargoRecomendationsListRequest } from "@/data/payload/api/CargoApiRequest";

export class CargoConfigurationApiRepository
  implements CargoConfigurationRepositoryInterface {
  private service: ApiService;
  private mapper: CargoConfigurationMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: CargoConfigurationMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getList(
    params: CargoConfigListRequest
  ): Promise<CargoConfigEntities> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getCargoConfigList(params)
    );

    return this.mapper.convertListDataFromApi(res);
  }

  public async getDetail(id: number): Promise<CargoConfiguration> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailCargoConfiguration(id),
      {}
    );
    return this.mapper.convertDetailDataFromApi(resp);
  }

  public async edit(
    payload: CargoConfigurationRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "put",
      this.endpoints.editCargoConfiguration(),
      undefined,
      payload as EditCargoConfigurationRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async deleteCargoConfiguration(id: number): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "delete",
      this.endpoints.deleteCargoConfiguration(id),
      undefined
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async addCargoConfiguration(
    payload: CargoConfigurationRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.addCargoConfiguration(),
      undefined,
      payload as AddCargoConfigurationRequest
    );
    return this.mapper.convertPayloadData(resp);
  }

  public async getListCargoRecomendation(
    params: CargoRecomendationsListRequest
  ): Promise<CargoConfiguration[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getCargoRecomendations(params),
      {}
    );
    return this.mapper.convertListCargoRecomendationDataFromApi(resp);
  }
}
