import {
  BeneficiaryData,
  BeneficiaryEntities,
  ValidateBankAccount
} from "@/domain/entities/Beneficiary";
import { AxiosResponse } from "axios";
import parsingLinkGcpToAws from "@/app/infrastructures/misc/common-library/ParsingLinkGcpToAws";

export class BeneficiaryMapper {
  public convertBeneficiaryDataFromApi(
    res: AxiosResponse<any>
  ): BeneficiaryEntities {
    const { data } = res;

    const beneficiaries: BeneficiaryData[] = [];
    if (data.data.length === 0) {
      return new BeneficiaryEntities([]);
    } else {
      data.data.map((item: any) => {
        beneficiaries.push(
          new BeneficiaryData(
            item.bank_account_id,
            item.bank_account_label,
            item.bank_account_bank_name,
            parsingLinkGcpToAws(item.bank_account_bank_logo),
            item.bank_account_account_number,
            item.bank_account_account_name,
            item.bank_account_account_type,
            item.bank_account_account_email,
            item.bank_account_payment_procedure,
            item.bank_account_actor_id,
            item.bank_account_actor_type,
            item.bank_account_created_at,
            item.bank_account_updated_at,
            item.partner_beneficiary_is_registry
          )
        );
      });
    }
    return new BeneficiaryEntities(beneficiaries);
  }

  public convertValidateBankAccountFromApi(
    res: AxiosResponse
  ): ValidateBankAccount {
    const {
      data: { data }
    } = res;

    if (data) {
      return new ValidateBankAccount(
        data.account_name,
        data.account_no,
        data.bank_name,
        data.is_allow_to_use
      );
    }
    return new ValidateBankAccount("", "", "", false);
  }
}
