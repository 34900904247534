import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  ActorData,
  TopupApprovalData,
  TopupApprovalListEntities
} from "@/domain/entities/TopupApproval";
import { AxiosResponse } from "axios";
import parsingLinkGcpToAws from "@/app/infrastructures/misc/common-library/ParsingLinkGcpToAws";

export class TopupApprovalMapper {
  public convertTopupApprovalListFromApi(
    res: AxiosResponse
  ): TopupApprovalListEntities {
    const { data } = res;
    const topupApprovalList: TopupApprovalData[] = [];
    if (data.data === null || data.data.length === 0) {
      return new TopupApprovalListEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      for (const topupApproval of data.data) {
        topupApprovalList.push(
          new TopupApprovalData({
            tumId: topupApproval.tum_id,
            tumSenderBankName: topupApproval.tum_sender_bank_name,
            tumSenderBankAccountName:
              topupApproval.tum_sender_bank_account_name,
            tumSenderBankAccountNumber:
              topupApproval.tum_sender_bank_account_number,
            tumRecepientBankName: topupApproval.tum_recepient_bank_name,
            tumRecepientBankAccountName:
              topupApproval.tum_recepient_bank_account_name,
            tumRecepientBankAccountNumber:
              topupApproval.tum_recepient_bank_account_number,
            tumAmount: topupApproval.tum_amount,
            tumVerifiedAmount: topupApproval.tum_verified_amount,
            tumApprovedAmount: topupApproval.tum_approved_amount,
            tumStatus: topupApproval.tum_status,
            tumRejectReason: topupApproval.tum_reject_reason,
            tumFileOfProof: parsingLinkGcpToAws(
              topupApproval.tum_file_of_proof
            ),
            tumActorId: topupApproval.tum_actor_id,
            tumActorType: topupApproval.tum_actor_type,
            tumActorName: topupApproval.tum_actor_name,
            tumActorCode: topupApproval.tum_actor_code,
            tumCreatedAt: topupApproval.tum_created_at,
            tumCreatedBy: topupApproval.tum_created_by,
            tumCreatedName: topupApproval.tum_created_name,
            tumUpdatedAt: topupApproval.tum_updated_at || "",
            tumUpdatedBy: topupApproval.tum_updated_by,
            tumVerificationBy: topupApproval.tum_verification_by,
            tumVerificationName: topupApproval.tum_verification_name,
            tumVerificationAt: topupApproval.tum_verification_at || "",
            tumApprovalBy: topupApproval.tum_approval_by,
            tumApprovalName: topupApproval.tum_approval_name,
            tumApprovalAt: topupApproval.tum_approval_at || "",
            genesisTransactionId: topupApproval.genesis_transaction_id
          })
        );
      }
      return new TopupApprovalListEntities(
        new Pagination(data.meta.page, data.meta.limit),
        topupApprovalList
      );
    }
  }
  public convertDataActorVerifiedListFromApi(
    result: AxiosResponse
  ): ActorData[] {
    const {
      data: { data }
    } = result;
    if (!data.length) return [];
    else
      return data.map(
        (item: any) => new ActorData(item.actor_id, item.actor_name)
      );
  }

  public convertDataDetailTopUpApprovalFromApi(
    result: AxiosResponse
  ): TopupApprovalData {
    const {
      data: { data }
    } = result;

    return new TopupApprovalData({
      tumId: data.tum_id,
      tumSenderBankName: data.tum_sender_bank_name,
      tumSenderBankAccountName: data.tum_sender_bank_account_name,
      tumSenderBankAccountNumber: data.tum_sender_bank_account_number,
      tumRecepientBankName: data.tum_recepient_bank_name,
      tumRecepientBankAccountName: data.tum_recepient_bank_account_name,
      tumRecepientBankAccountNumber: data.tum_recepient_bank_account_number,
      tumAmount: data.tum_amount,
      tumVerifiedAmount: data.tum_verified_amount,
      tumApprovedAmount: data.tum_approved_amount,
      tumStatus: data.tum_status,
      tumRejectReason: data.tum_reject_reason,
      tumFileOfProof: parsingLinkGcpToAws(data.tum_file_of_proof),
      tumActorId: data.tum_actor_id,
      tumActorType: data.tum_actor_type,
      tumActorName: data.tum_actor_name,
      tumActorCode: data.tum_actor_code,
      tumCreatedAt: data.tum_created_at,
      tumCreatedBy: data.tum_created_by,
      tumCreatedName: data.tum_created_name,
      tumUpdatedAt: data.tum_updated_at || "",
      tumUpdatedBy: data.tum_updated_by,
      tumVerificationBy: data.tum_verification_by,
      tumVerificationName: data.tum_verification_name,
      tumVerificationAt: data.tum_verification_at || "",
      tumApprovalBy: data.tum_approval_by,
      tumApprovalName: data.tum_approval_name,
      tumApprovalAt: data.tum_approval_at || "",
      genesisTransactionId: data.tuml_genesis_transaction_id
    });
  }

  public convertApproveRejectTopupApprovalFromApi(
    res: AxiosResponse
  ): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: data.data
    });
  }
}
