import { Pagination } from "@/domain/entities/Pagination";
import {
  HistoryPoinData,
  HistoryPoinEntities,
  HistorySaldoData,
  HistorySaldoEntities
} from "@/domain/entities/SaldoPoin";
import { AxiosResponse } from "axios";

export class SaldoPoinMapper {
  public convertHistoryPoinListDataFromApi(
    res: AxiosResponse<any>
  ): HistoryPoinEntities {
    const { data } = res;
    return !data.data.length
      ? new HistoryPoinEntities({
          pagination: new Pagination(data.meta.page, data.meta.limit),
          data: []
        })
      : new HistoryPoinEntities({
          pagination: new Pagination(data.meta.page, data.meta.limit),
          data: data.data.map(
            (key: any) =>
              new HistoryPoinData({
                id: key.point_history_id,
                invoiceNumber: key.invoice_id,
                activity: key.balance_type.toLowerCase().replace("_", " "),
                amount: key.delta,
                transactionDate: key.created_at,
                note: key.note,
                expiredDate: key.expired_at
              })
          )
        });
  }

  public convertHistorySaldoListDataFromApi(
    res: AxiosResponse<any>
  ): HistorySaldoEntities {
    const { data } = res;
    return !data.data.length
      ? new HistorySaldoEntities({
          pagination: new Pagination(data.meta.page, data.meta.limit),
          data: []
        })
      : new HistorySaldoEntities({
          pagination: new Pagination(data.meta.page, data.meta.limit),
          data: data.data.map(
            (key: any) =>
              new HistorySaldoData({
                id: key.detail.balance_id,
                shipmentId: key.detail.shipment_id,
                saldoType: key.history_type.toLowerCase().replace("_", " "),
                activity: key.detail.balance_type_id
                  .toLowerCase()
                  .replace("_", " "),
                amount: key.detail.delta,
                transactionDate: key.detail.created_at,
                note: key.detail.note,
                accountBank: key.detail.bank_id,
                accountName: key.detail.bank_account_owner,
                accountNumber: key.detail.bank_account_number,
                refNumber: key.detail.reference_no,
                status: key.detail.balance_status_id
                  .toLowerCase()
                  .replace("_", " ")
              })
          )
        });
  }
}
