import { ROUTE_LOCATION_COVERAGE } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const DetailCountry = () =>
  import(
    /* webpackChunkName: "Location" */ "@/app/ui/views/route/location/component/country/detail.vue"
  );
const EditCountry = () =>
  import(
    /* webpackChunkName: "Location" */ "@/app/ui/views/route/location/component/country/edit.vue"
  );
const DetailCity = () =>
  import(
    /* webpackChunkName: "Location" */ "@/app/ui/views/route/location/component/city/detail-city.vue"
  );
const EditCity = () =>
  import(
    /* webpackChunkName: "Location" */ "@/app/ui/views/route/location/component/city/edit.vue"
  );
const DetailDistrict = () =>
  import(
    /* webpackChunkName: "Location" */ "@/app/ui/views/route/location/component/district/detail-district.vue"
  );
const EditDistrict = () =>
  import(
    /* webpackChunkName: "Location" */ "@/app/ui/views/route/location/component/district/edit.vue"
  );

export default [
  {
    path: "/network/location",
    component: Layout,
    name: "country",
    meta: {
      hidden: true,
      parent: "Location",
      permission: ROUTE_LOCATION_COVERAGE.DETAIL
    },
    children: [
      {
        path: "country/:id",
        name: "detail-location-country",
        component: DetailCountry,
        meta: {
          header: true,
          title: "Country | Detail"
        }
      }
    ]
  },
  {
    path: "/network/location",
    component: Layout,
    name: "edit-location-country",
    meta: {
      icon: "route",
      children: false,
      hidden: true,
      parent: "Location",
      permission: ROUTE_LOCATION_COVERAGE.EDIT
    },
    children: [
      {
        path: "/network/location/country/edit/:id",
        name: "edit-location-country",
        component: EditCountry,
        meta: {
          header: true,
          title: "Country | Edit",
          name: "Edit",
          before: "/network/location/country/:id"
        }
      }
    ]
  },
  {
    path: "/network/location",
    component: Layout,
    name: "city",
    meta: {
      hidden: true,
      parent: "Location",
      permission: ROUTE_LOCATION_COVERAGE.DETAIL
    },
    children: [
      {
        path: "city/:id",
        name: "detail-location-city",
        component: DetailCity,
        meta: {
          header: true,
          title: "City | Detail"
        }
      }
    ]
  },
  {
    path: "/network/location",
    component: Layout,
    name: "edit-location-city",
    meta: {
      icon: "route",
      children: false,
      hidden: true,
      parent: "Location",
      permission: ROUTE_LOCATION_COVERAGE.EDIT
    },
    children: [
      {
        path: "/network/location/city/edit/:id",
        name: "edit-location-city",
        component: EditCity,
        meta: {
          header: true,
          title: "City | Edit",
          name: "Edit",
          before: "/network/location/city/:id"
        }
      }
    ]
  },
  {
    path: "/network/location",
    component: Layout,
    name: "district",
    meta: {
      hidden: true,
      parent: "Location",
      permission: ROUTE_LOCATION_COVERAGE.DETAIL
    },
    children: [
      {
        path: "district/:id",
        name: "detail-location-district",
        component: DetailDistrict,
        meta: {
          header: true,
          title: "District | Detail"
        }
      }
    ]
  },
  {
    path: "/network/location",
    component: Layout,
    name: "edit-location-district",
    meta: {
      icon: "route",
      children: false,
      hidden: true,
      parent: "Location",
      permission: ROUTE_LOCATION_COVERAGE.EDIT
    },
    children: [
      {
        path: "/network/location/district/edit/:id",
        name: "edit-location-district",
        component: EditDistrict,
        meta: {
          header: true,
          title: "District | Edit",
          name: "Edit",
          before: "/network/location/district/:id"
        }
      }
    ]
  }
];
