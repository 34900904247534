import { WoodpackingAvailableCities, WoodpackingData } from "@/domain/entities/ConfigWoodpacking";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class ConfigPriceWoodpackingDataMapper {
  public convertDetailConfigPriceWoodpackingFromApi(
    result: AxiosResponse<any>
  ): WoodpackingData {
    const {
      data: { data }
    } = result;

    return new WoodpackingData({
      id: data.configurable_price_id,
      name: data.configurable_price_name,
      description: data.configurable_price_description,
      type: data.configurable_woodpacking.woodpacking_additional_cost.type,
      additionalCost:
        data.configurable_woodpacking.woodpacking_additional_cost
          .additional_cost,
      status: data.configurable_price_status,
      woodpackingAvailableCities: data.woodpacking_available_cities?.length ? data.woodpacking_available_cities.map((key: any) =>
        new WoodpackingAvailableCities({
          cityCode: key.city_code?.toUpperCase(),
          cityName: key.city_name
        })
      ) : [],
      createdAt: data.configurable_price_created_at,
      updatedAt: data.configurable_price_updated_at,
      createdBy: data.configurable_price_created_by,
      updatedBy: data.configurable_price_updated_by
    });
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
