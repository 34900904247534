import { RouteApiRepository } from "@/app/infrastructures/repositories/api/RouteApiRepository";
import { RouteModifyRequestInterface } from "@/data/payload/contracts/RouteRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { RouteData, RouteEntities } from "@/domain/entities/Route";
import { injectable } from "tsyringe";

@injectable()
export class RoutePresenter {
  private repository: RouteApiRepository;

  constructor(repository: RouteApiRepository) {
    this.repository = repository;
  }

  public getListEmbargo(
    page: number,
    limit: number,
    status: string,
    originId: string,
    destinationId: string,
    sortBy: string
  ): Promise<RouteEntities> {
    return this.repository.getList(
      page,
      limit,
      status,
      originId,
      destinationId,
      sortBy
    );
  }

  public getDetailRoute(id: number): Promise<RouteData> {
    return this.repository.getDetail(id);
  }

  public edit(payload: RouteModifyRequestInterface): Promise<ResponsePayload> {
    return this.repository.edit(payload);
  }
}
