import { CnManifestApiRepository } from "@/app/infrastructures/repositories/api/CnManifestApiRepository";
import { RequestListBooking } from "@/data/payload/api/BookingRequest";
import {
  ClientPartnerOptions,
  DestinationCityOptions,
  CnManifestEntities,
  SttNumbersOptions
} from "@/domain/entities/CnManifest";
import { injectable } from "tsyringe";

@injectable()
export class CnManifestPresenter {
  private repository: CnManifestApiRepository;

  constructor(repository: CnManifestApiRepository) {
    this.repository = repository;
  }

  public getListClientPartnerOptions(
    page: number,
    limit: number,
    search: string,
    type: string,
    isParent: boolean
  ): Promise<ClientPartnerOptions[]> {
    return this.repository.getListClientPartnerOptions(
      page,
      limit,
      search,
      type,
      isParent
    );
  }

  public getListDestinationCityOptions(
    page: number,
    limit: number,
    search: string
  ): Promise<DestinationCityOptions[]> {
    return this.repository.getListDestinationCityOptions(page, limit, search);
  }

  public getListSttNumbersOptions(
    params: RequestListBooking
  ): Promise<SttNumbersOptions[]> {
    return this.repository.getListSttNumbersOptions(params);
  }

  public getListCnManifest(
    page: number,
    limit: number,
    userType: string,
    bookedBy: number,
    destinationCityId: number,
    fromDate: string,
    endDate: string,
    sttNumbers: string,
    bookedType: string
  ): Promise<CnManifestEntities> {
    return this.repository.getListCnManifest(
      page,
      limit,
      userType,
      bookedBy,
      destinationCityId,
      fromDate,
      endDate,
      sttNumbers,
      bookedType
    );
  }

  public exportSttManifests(
    userType: string,
    bookedBy: number,
    destinationCityId: number,
    fromDate: string,
    endDate: string,
    sttNumbers: string,
    bookedType: string
  ): Promise<string> {
    return this.repository.exportSttManifests(
      userType,
      bookedBy,
      destinationCityId,
      fromDate,
      endDate,
      sttNumbers,
      bookedType
    );
  }
}
