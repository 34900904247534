export const playNotification = (type = "success") => {
  let audio = null;
  switch (type) {
    case "success":
      audio = new Audio(
        require("@/app/ui/assets/audio/success_notification.wav")
      );
      break;
    case "error":
      audio = new Audio(
        require("@/app/ui/assets/audio/error_notification.wav")
      );
      break;
    case "success_cargo":
      audio = new Audio(
        require("@/app/ui/assets/audio/success_notif_cargo.wav")
      );
      break;
    case "failed_cargo":
      audio = new Audio(
        require("@/app/ui/assets/audio/failed_notif_cargo.wav")
      );
      break;
    case "pod":
      audio = new Audio(
        require("@/app/ui/assets/audio/pod_notification.mp3")
      );
      break;
    case "rts":
      audio = new Audio(
        require("@/app/ui/assets/audio/rts_notification.mp3")
      );
      break;
    case "hal":
      audio = new Audio(
        require("@/app/ui/assets/audio/hal_notification.mp3")
      );
      break;
    case "urgent_delivery":
      audio = new Audio(
        require("@/app/ui/assets/audio/urgent_delivery_notification.mp3")
      );
      break;
    case "bongkar":
      audio = new Audio(
        require("@/app/ui/assets/audio/bongkar_notification.mp3")
      );
      break;
    case "misroute":
      audio = new Audio(
        require("@/app/ui/assets/audio/misroute_notification.mp3")
      );
      break;
    case "dangerous_goods":
      audio = new Audio(
        require("@/app/ui/assets/audio/dangerous_goods_notification.mp3")
      );
      break;
    default:
      break;
  }

  if (!audio) return;
  audio.volume = 1;
  audio.play();
  audio.remove();
};
