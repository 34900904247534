import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  BagOrSttReadyToCargo,
  ReadyToCargo,
  RequestLogReadyToCargoList,
  RequestReadyToCargoList,
  ResponseScanReadyToCargo,
  ResponseUpdateReadyToCargo,
  ScanReadyToCargoRequest,
  UpdateReadyToCargoRequest
} from "@/domain/entities/ReadyToCargo";
import { ReadyToCargoPresenter } from "../presenters/ReadyToCargoPresenter";
import { container } from "tsyringe";
import { ReadyToCargoComponent } from "@/app/infrastructures/dependencies/modules/ReadyToCargoComponent";
import { MainAppController } from "./MainAppController";
import {
  convertDecimalWithComma,
  parsingErrorResponse,
  storeDatatoLocalStorage
} from "@/app/infrastructures/misc/Utils";
import router from "../router";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { BagOrSttNumber, FlightPlanForECargo } from "@/data/payload/api/CargoApiRequest";
import { CargoController } from "./CargoController";
import { OptionsClass } from "@/domain/entities/MainApp";
import { CargoInputData, DestinationCityData, DimensionData, OriginCityData } from "@/domain/entities/Cargo";
import { getHubData } from "@/app/infrastructures/misc/Cookies";
import { FlightDetail } from "@/domain/entities/NGen";
ReadyToCargoComponent.init();

export interface ReadyToCargoState {
  readyToCargoDetail: ReadyToCargo;
}
@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "ready-to-cargo"
})
class ReadyToCargoStore extends VuexModule implements ReadyToCargoState {
  public readyToCargoDetail: ReadyToCargo = JSON.parse(
    localStorage.getItem("rtc-detail") || "{}"
  );
  public bagSttDetail = new ResponseScanReadyToCargo();
  public sourceSttFromLocalStorage: BagOrSttReadyToCargo[] = JSON.parse(
    localStorage.getItem("stt-data") || "[]"
  );
  public bagResult: {
    bagNo: string;
    stt: BagOrSttReadyToCargo[];
  }[] = JSON.parse(localStorage.getItem("bag-data") || "[]");
  public flowBooking: string = JSON.parse(
    localStorage.getItem("flow-booking") || "{}"
  );
  public eventName: string = JSON.parse(
    localStorage.getItem("event-name") || "{}"
  );
  
  public detailFlightsData: FlightDetail[] = JSON.parse(
    localStorage.getItem("rtc-flights-data") || "[]"
  ); 

  public calculateFlightDate: FlightPlanForECargo[] = JSON.parse(
    localStorage.getItem("calculate-flight-date") || "[]"
  );

  get sttResultFinal() {
    const tempData: BagOrSttReadyToCargo[] = [];
    if (this.sourceSttFromLocalStorage) {
      this.sourceSttFromLocalStorage.forEach((e: BagOrSttReadyToCargo) => {
        const existingIdx = tempData.findIndex(
          (a: BagOrSttReadyToCargo) => a.bagNo === e.bagNo
        );
        if (e.bagNo && !e.sttDetails.length) {
          if (existingIdx > -1) {
            tempData[existingIdx]["sttDetails"].push(e);
          } else {
            tempData.push({ ...e, sttDetails: [e] });
          }
          return;
        }
        tempData.push(e);
      });
    }
    return tempData;
  }

  get totalSttPieces() {
    // STTs with the same bagNo counted as one, STTs without bagNo counted per totalPieces
    return this.sttResultFinal
      .map((key: BagOrSttReadyToCargo) =>
        key.bagNo && key.sttDetails.length ? 1 : key.sttTotalPiece
      )
      .reduce((a, b) => a + b, 0);
  }

  get totalGrossWeight() {
    const total = convertDecimalWithComma(
      this.sourceSttFromLocalStorage
        .map((key: BagOrSttReadyToCargo) => key.grossWeight)
        .reduce((a, b) => {
          return a + b;
        }, 0),
      2
    );
    return total;
  }

  get totalVolumeWeight() {
    const total = convertDecimalWithComma(
      this.sourceSttFromLocalStorage
        .map((key: BagOrSttReadyToCargo) => key.volumeWeight)
        .reduce((a, b) => {
          return a + b;
        }, 0),
      2
    );
    return total;
  }

  @Action
  public getReadyToCargoList(params: RequestReadyToCargoList) {
    params.hubId = +getHubData()?.hubId;
    const presenter = container.resolve(ReadyToCargoPresenter);
    return presenter.getReadyToCargoList(params);
  }

  @Action
  public getLogReadyToCargoList(params: RequestLogReadyToCargoList) {
    params.hubId = +getHubData()?.hubId;
    const presenter = container.resolve(ReadyToCargoPresenter);
    return presenter.getLogReadyToCargoList(params);
  }

  @Action
  public getDetailReadyToCargo(id: number, flow?: string) {
    MainAppController.showLoading();
    const presenter = container.resolve(ReadyToCargoPresenter);
    return presenter
      .getDetailReadyToCargo(id)
      .then((key: ReadyToCargo) => {
        CargoController.setDestinationCity(
          new OptionsClass({
            code: key.destinationCode,
            label: `${key.destinationCode} - ${key.destinationName}`,
            name: key.destinationName
          })
        );
        this.setReadyToCargoDetail(key);
        const dimension = {
          length: 10,
          width: 20,
          height: 30
        };
        if (flow == "rtc-edit") {
          dimension.length = 30;
          dimension.height = 10;
        }
        Object.assign(CargoController.form, {
          cargoProduct: "Express",
          actualTotalCargoGrossWeight: key.totalWeight.toString(),
          actualDimension: new DimensionData({
            length: dimension.length,
            width: 20,
            height: dimension.height
          }),
          actualTotalCargoVolumeWeight: key.totalPieces.toString(),
          cargoType: key.cargoType,
          cargoTypeStatus: key.cargoType.match(/plane/gi) ? "auto" : "manual",
          cargoCommodityName:
            CargoController.form.cargoCommodityName || key.commodityName,
          cargoCommodityCode:
            CargoController.form.cargoCommodityCode || key.commodityCode,
          actualTotalSTTPieces: key.totalPieces,
          destinationCityCode: this.readyToCargoDetail.destinationCode,
          destinationCityName: this.readyToCargoDetail.destinationName,
          flightsData: this.detailFlightsData
        });

        // save data to cargo-input-data localstorage when open rtc proccess or rtc edit
        const cargoInputForm: CargoInputData = JSON.parse(
          localStorage.getItem("cargo-input-data") || "{}"
        );
        const newCargoInputData = new CargoInputData({
          id: key.id,
          dimension: new DimensionData({
            length: dimension.length,
            width: 20,
            height: dimension.height
          }),
          totalActualCargoGrossWeight: key.totalWeight,
          calcuateActualCargoGrossWeight: key.totalWeight, // explanation https://github.com/Lionparcel/medusa/pull/5753/files#r1285584448
          totalActualCargoVolumeWeight: key.totalPieces,
          totalActualCargoColi: key.totalPieces,
          destinationCityData: new DestinationCityData({
            name: this.readyToCargoDetail.destinationName,
            code: this.readyToCargoDetail.destinationCode,
            label: `${this.readyToCargoDetail.destinationCode} - ${this.readyToCargoDetail.destinationName}`
          }),
          originCityData: new OriginCityData({
            code: key.originCode,
            name: key.originName
          }),
          nog: "",
          cargoComodity: new OptionsClass({
            name: cargoInputForm.cargoComodity?.name,
            code: cargoInputForm.cargoComodity?.code
          }),
          cargoProduct: "Express",
          vehicleNumber: "",
          cargoNumber: "",
          estTotalCargoVolumeWeight: 0,
          estTotalCargoGrossWeight: 0,
          flightData: this.detailFlightsData
        });
        storeDatatoLocalStorage("cargo-input-data", newCargoInputData);
        const cargoData = {
          type: key.cargoType,
          status: key.cargoType == "plane" ? "auto" : "manual"
        };
        CargoController.setCargoType(cargoData);
        router.push("/cargo/create");
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            err,
            "Gagal Mendapatkan Detail Siap di Kargo",
            () => {
              this.getDetailReadyToCargo(id);
              MainAppController.closeErrorMessage();
            }
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  async setCargoData() {
    const tempData: any = [];
    if (this.readyToCargoDetail.detail) {
      this.readyToCargoDetail.detail.forEach((e: any) => {
        const existingIdx = tempData.findIndex(
          (a: any) => a.baggingNumber === e.baggingNumber
        );
        if (e.baggingNumber && !e.sttDetails) {
          if (existingIdx > -1) {
            tempData[existingIdx]["sttDetails"].push(e);
          } else {
            tempData.push({ ...e, sttDetails: [e] });
          }
          return;
        }
        tempData.push(e);
      });
    }
    storeDatatoLocalStorage("cargo", this.readyToCargoDetail.detail);
    CargoController.setSTTResultFinal(tempData);
    CargoController.setSourceFromLocalStorage(this.readyToCargoDetail.detail);
  }

  @Action
  public scanReadyToCargo(payload: {
    bagOrSttNumber: ScanReadyToCargoRequest;
    callback: Function;
  }) {
    MainAppController.showLoading();
    const presenter = container.resolve(ReadyToCargoPresenter);
    return presenter
      .scanReadyToCargo(payload.bagOrSttNumber)
      .then((res: ResponseScanReadyToCargo) => {
        this.setBagSttDetail(res);
        payload.callback(res);
        return true;
      })
      .catch(error => {
        playNotification("error");
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Tambah No. STT Gagal !", () => {
            MainAppController.closeErrorMessage();
            this.scanReadyToCargo(payload);
          })
        );
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public updateReadyToCargo() {
    const payloadBagNoOnly = this.bagResult.map(
      (item: any) => new BagOrSttNumber(item.bagNo, "")
    );
    const payloadSttNoOnly = this.sourceSttFromLocalStorage
      .filter(
        (item: any) =>
          !this.bagResult.find((bag: any) =>
            JSON.stringify(bag).includes(item.sttNo)
          )
      )
      .map((item: any) => new BagOrSttNumber("", item.sttNo));
    const payload = new UpdateReadyToCargoRequest({
      bagOrSttNo: [...payloadSttNoOnly, ...payloadBagNoOnly],
      hubId: getHubData()?.hubId,
      hubName: getHubData()?.hubName,
      hubDistrictCode: getHubData()?.hubDistrictCode,
      hubOriginCity: getHubData()?.originCity
    });
    MainAppController.showLoading();
    const presenter = container.resolve(ReadyToCargoPresenter);
    return presenter
      .updateReadyToCargo(payload)
      .then((res: ResponseUpdateReadyToCargo) => {
        return res;
      })
      .catch(error => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Pembuatan Gagal !", () => {
            MainAppController.closeErrorMessage();
            this.updateReadyToCargo();
          })
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Mutation
  public setReadyToCargoDetail(obj: ReadyToCargo) {
    storeDatatoLocalStorage("rtc-detail", obj);
    this.readyToCargoDetail = obj;
  }

  @Mutation
  public setCalculateFlightDate(data: FlightPlanForECargo[]) {
    storeDatatoLocalStorage("calculate-flight-date", data);
    this.calculateFlightDate = data;
  }

  @Mutation
  public setDetailFlightData(data: FlightDetail[]) {
    storeDatatoLocalStorage("rtc-flights-data", data);
    this.detailFlightsData = data;
  }

  @Mutation
  public setFlowBooking(value: string) {
    storeDatatoLocalStorage("flow-booking", value);
    this.flowBooking = value;
  }

  @Mutation
  public setEventName(value: string) {
    storeDatatoLocalStorage("event-name", value);
    this.eventName = value;
  }

  @Mutation
  public setBagSttDetail(data: ResponseScanReadyToCargo) {
    this.bagSttDetail = data;
  }

  @Mutation
  public setSourceFromLocalStorage(data: BagOrSttReadyToCargo[]) {
    this.sourceSttFromLocalStorage = data;
  }

  @Mutation
  public setBagResult(data: any) {
    this.bagResult = data;
  }
}

export const ReadyToCargoController = getModule(ReadyToCargoStore);
