/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsFeatureShipmentC1 = {
    feature_shipment_c1: new Rox.RoxString("C1"),
};

export function initFlagsFeatureShipmentC1() {
  // register flags with namespaces for better grouping
  Rox.register("flags", FlagsFeatureShipmentC1);
}
