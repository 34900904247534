import { Pagination } from "./Pagination";

export class ConsolidatorEntities {
  pagination: Pagination;
    data: ConsolidatorData[];
  constructor(pagination: Pagination, data: ConsolidatorData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ConsolidatorData {
  id: number;
  name: string;
  address: string;
  balance: number;
  code: string;
  lat: number;
  long: number;
  type: string;
  createdAt: string;
  updatedAt: string;
  parentId: number;
  parentName: string;
  parentType: string;
  contractEnd: string;
  contractStatus: string;
  isBanned: boolean;
  isRegistry: boolean;
  partnerLocation?: any;
  partnerLocationList?: any[];
  constructor(
    id: number,
    name: string,
    address: string,
    balance: number,
    code: string,
    lat: number,
    long: number,
    type: string,
    createdAt: string,
    updatedAt: string,
    parentId: number,
    parentName: string,
    parentType: string,
    contractEnd: string,
    contractStatus: string,
    isBanned: boolean,
    isRegistry: boolean,
    partnerLocation?: any,
    partnerLocationList?: any
  ) {
    this.id = id;
    this.name = name;
    this.address = address;
    this.balance = balance;
    this.code = code;
    this.lat = lat;
    this.long = long;
    this.type = type;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.parentId = parentId;
    this.parentName = parentName;
    this.parentType = parentType;
    this.contractEnd = contractEnd;
    this.contractStatus = contractStatus;
    this.isBanned = isBanned;
    this.isRegistry = isRegistry;
    this.partnerLocation = partnerLocation;
    this.partnerLocationList = partnerLocationList;
  }
}
