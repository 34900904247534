import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ClaimDetailData, ClaimEntities } from "@/domain/entities/Claim";
import {
  ClaimListRequest,
  CreateClaimRequest
} from "@/data/payload/api/ClaimRequest";
import { ClaimRepositoryInterface } from "@/data/persistences/repositories/contracts/ClaimRepositoryInterface";
import { ClaimDataMapper } from "@/data/persistences/mappers/ClaimDataMapper";
import { ClaimRequestInterface } from "@/data/payload/contracts/ClaimRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class ClaimApiRepository implements ClaimRepositoryInterface {
  private service: ApiService;
  private mapper: ClaimDataMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: ClaimDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async getDataClaimList(
    path: string,
    params: ClaimListRequest
  ): Promise<ClaimEntities> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getDataClaimList(path, params)
    );

    return this.mapper.convertListFromApi(res);
  }

  async getDetailClaim(sttNo: string): Promise<ClaimDetailData> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getDetailClaim(sttNo)
    );
    return this.mapper.convertDetailClaimFromApi(res);
  }

  async createClaim(payload: ClaimRequestInterface): Promise<ResponsePayload> {
    const res = await this.service.invokePostWithFormData(
      "post",
      this.endpoints.createTrackDeliveryClaim(),
      {},
      payload as CreateClaimRequest
    );
    return this.mapper.convertRequestClaimDataFromApi(res);
  }
}
