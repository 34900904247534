import { injectable } from "tsyringe";
import {
  LoginRequestInterface,
  ResetPasswordRequestInterface,
  ForgotPasswordRequestInterface,
  LogoutRequestInterface,
  EditPasswordRequestInterface,
  VerificationAccountRequestInterface
} from "@/data/payload/contracts/AuthRequest";
import { AuthApiRepository } from "@/app/infrastructures/repositories/api/AuthApiRepository";
import {
  AuthEntities,
  TokenVerificationAccountEntities
} from "@/domain/entities/Auth";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

@injectable()
export class AuthPresenter {
  private repository: AuthApiRepository;

  constructor(repository: AuthApiRepository) {
    this.repository = repository;
  }

  public onLogin(payload: LoginRequestInterface): Promise<AuthEntities> {
    return this.repository.login(payload);
  }

  public forgotPassword(
    payload: ForgotPasswordRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.forgotPassword(payload);
  }

  public resetPassword(
    payload: ResetPasswordRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.resetPassword(payload);
  }

  public editPassword(
    payload: EditPasswordRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editPassword(payload);
  }

  public logout(payload: LogoutRequestInterface): Promise<ResponsePayload> {
    return this.repository.logout(payload);
  }

  public verificationAccount(
    payload: VerificationAccountRequestInterface
  ): Promise<TokenVerificationAccountEntities> {
    return this.repository.verificationAccount(payload);
  }
}
