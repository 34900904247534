import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { EmailRepositoryInterface } from "@/data/persistences/repositories/contracts/EmailRepositoryInterface";
import { EmailMapper } from "@/data/persistences/mappers/EmailMapper";
import { EmailRequestInterface } from "@/data/payload/contracts/EmailRequest";
import { EmailPrintReceiptRequest } from "@/data/payload/api/EmailRequest";

export class EmailApiRepository implements EmailRepositoryInterface {
  private service: ApiService;
  private mapper: EmailMapper;
  private endpoints: Endpoints;

  constructor(service: ApiService, mapper: EmailMapper, endpoints: Endpoints) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async sendPrintReceiptEmail(
    payload: EmailRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePostWithFormData(
      "post",
      this.endpoints.sendPrintReceiptEmail(),
      {},
      payload as EmailPrintReceiptRequest
    );
    return this.mapper.convertPayloadDataFromApi(resp);
  }
}
