/* eslint-disable @typescript-eslint/camelcase */
import { Pagination } from "./Pagination";

export class EmbargoEntities {
  success: boolean;
  code: number;
  message: string;
  meta: Pagination;
  data: EmbargoData[];

  constructor(
    success: boolean,
    code: number,
    message: string,
    meta: Pagination,
    data: EmbargoData[]
  ) {
    this.success = success;
    this.code = code;
    this.message = message;
    this.meta = meta;
    this.data = data;
  }
}

export class EmbargoData {
  id: number;
  embargo_name: string;
  embargo_origin_id: string;
  embargo_destination_id: string;
  embargo_product_type: string;
  embargo_commodity_type: string;
  embargo_max_weight: number;
  embargo_min_weight: number;
  embargo_start_date_time: string;
  embargo_end_datetime: string;
  embargo_created_at: string;
  embargo_created_by: string;
  embargo_updated_at: string;
  embargo_updated_by: string;
  status: string;
  service_type: string;

  constructor(
    embargo_id: number,
    embargo_name: string,
    embargo_origin_id: string,
    embargo_destination_id: string,
    embargo_product_type: string,
    embargo_commodity_type: string,
    embargo_max_weight: number,
    embargo_min_weight: number,
    embargo_start_date_time: string,
    embargo_end_datetime: string,
    embargo_created_at: string,
    embargo_created_by: string,
    embargo_updated_at: string,
    embargo_updated_by: string,
    status: string,
    service_type: string
  ) {
    this.id = embargo_id;
    this.embargo_name = embargo_name;
    this.embargo_origin_id = embargo_origin_id;
    this.embargo_destination_id = embargo_destination_id;
    this.embargo_product_type = embargo_product_type;
    this.embargo_commodity_type = embargo_commodity_type;
    this.embargo_max_weight = embargo_max_weight;
    this.embargo_min_weight = embargo_min_weight;
    this.embargo_start_date_time = embargo_start_date_time;
    this.embargo_end_datetime = embargo_end_datetime;
    this.embargo_created_at = embargo_created_at;
    this.embargo_created_by = embargo_created_by;
    this.embargo_updated_at = embargo_updated_at;
    this.embargo_updated_by = embargo_updated_by;
    this.status = status;
    this.service_type = service_type;
  }
}

export class EmbargoDetail {
  embargo_id: string;
  embargo_name: string;
  max_weight: number;
  start_date_time: string;
  end_date_time: string;
  status: string;
  product_type: string;
  product_type_detail: ProductTypeDetail[];
  origin_id: string;
  origin_detail: OriginDetail[];
  destination_id: string;
  destination_detail: DestinationDetail[];
  commodity_type: string;
  commodity_type_detail: ComodityTypeDetail[];
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  embargo_delivery_type: string;
  service_type: string;

  constructor(
    embargo_id: string,
    embargo_name: string,
    max_weight: number,
    start_date_time: string,
    end_date_time: string,
    status: string,
    product_type: string,
    product_type_detail: ProductTypeDetail[],
    origin_id: string,
    origin_detail: OriginDetail[],
    destination_id: string,
    destination_detail: DestinationDetail[],
    commodity_type: string,
    commodity_type_detail: ComodityTypeDetail[],
    created_at: string,
    updated_at: string,
    created_by: string,
    updated_by: string,
    embargo_delivery_type: string,
    service_type: string
  ) {
    this.embargo_id = embargo_id;
    this.embargo_name = embargo_name;
    this.max_weight = max_weight;
    this.start_date_time = start_date_time;
    this.end_date_time = end_date_time;
    this.status = status;
    this.product_type = product_type;
    this.product_type_detail = product_type_detail;
    this.origin_id = origin_id;
    this.origin_detail = origin_detail;
    this.destination_id = destination_id;
    this.destination_detail = destination_detail;
    this.commodity_type = commodity_type;
    this.commodity_type_detail = commodity_type_detail;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.created_by = created_by;
    this.updated_by = updated_by;
    this.embargo_delivery_type = embargo_delivery_type;
    this.service_type = service_type;
  }
}

export class ProductTypeDetail {
  product_type_id: number;
  product_type_code: string;
  product_type_description: string;

  constructor(
    product_type_id: number,
    product_type_code: string,
    product_type_description: string
  ) {
    this.product_type_id = product_type_id;
    this.product_type_code = product_type_code;
    this.product_type_description = product_type_description;
  }
}

export class OriginDetail {
  id: number;
  name: string;
  code: string;

  constructor(id: number, name: string, code: string) {
    this.id = id;
    this.name = name;
    this.code = code;
  }
}

export class DestinationDetail {
  id: number;
  name: string;
  code: string;

  constructor(id: number, name: string, code: string) {
    this.id = id;
    this.name = name;
    this.code = code;
  }
}

export class ComodityTypeDetail {
  commodity_id: number | string;
  commodity_name: string;
  commodity_code: string;

  constructor(
    commodity_id: number | string,
    commodity_name: string,
    commodity_code: string
  ) {
    this.commodity_id = commodity_id;
    this.commodity_name = commodity_name;
    this.commodity_code = commodity_code;
  }
}

export class CheckEmbargo {
  isEmbargo = false;

  constructor(isEmbargo: boolean) {
    this.isEmbargo = isEmbargo;
  }
}
