/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionTopUpVerification = {
  permission_topup_verification_enable: new Rox.Flag(false),
  permission_topup_verification_list_view: new Rox.Flag(false),
  permission_topup_verification_detail_view: new Rox.Flag(false),
  permission_topup_verification_verify: new Rox.Flag(false),
};

export function initFlagsPermissionTopUpVerification() {
  // register flags with namespaces for better grouping
  Rox.register("featureTopUpVerification", FlagsPermissionTopUpVerification);
}
