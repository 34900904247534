import { ManualSTTApiRepository } from "@/app/infrastructures/repositories/api/ManualSTTApiRepository";
import { ManualSTTEntities } from "@/domain/entities/ManualSTT";
import { injectable } from "tsyringe";
import { AddGenerateManualSTTRequestInterface } from "@/data/payload/contracts/ManualSTTRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { RequestListManualStt } from "@/data/payload/api/ManualSTTApiRequest";

@injectable()
export class ManualSTTPresenter {
  private repository: ManualSTTApiRepository;

  constructor(repo: ManualSTTApiRepository) {
    this.repository = repo;
  }

  public getManualSTTList(
    params: RequestListManualStt
  ): Promise<ManualSTTEntities> {
    return this.repository.getManualSTTList(params);
  }

  public addGenerateManualSTT(
    payload: AddGenerateManualSTTRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addGenerateManualSTT(payload);
  }
}
