import {
  BeneficiaryEntities,
  ValidateBankAccount
} from "@/domain/entities/Beneficiary";
import { injectable } from "tsyringe";
import { BeneficiaryApiRepository } from "@/app/infrastructures/repositories/api/BeneficiaryApiRepository";
import { BankAccountRequestInterface } from "@/data/payload/contracts/BeneficiaryRequest";

@injectable()
export class BeneficiaryPresenter {
  private repository: BeneficiaryApiRepository;
  constructor(repo: BeneficiaryApiRepository) {
    this.repository = repo;
  }

  public getBeneficiaryAccList(
    page: number,
    limit: number,
    bankAccType: string,
    actortype: string,
    actorId: number
  ): Promise<BeneficiaryEntities> {
    return this.repository.getBeneficiaryAccList(
      page,
      limit,
      bankAccType,
      actortype,
      actorId
    );
  }

  public validateBankAccount(
    payload: BankAccountRequestInterface
  ): Promise<ValidateBankAccount> {
    return this.repository.validateBankAccount(payload);
  }
}
