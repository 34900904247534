import { FeatureFlagDetail } from "@/domain/entities/Account";

const getDataLocalStorage = (key: string): any => {
  return localStorage.getItem(key);
};
const storeDatatoLocalStorage = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
};
const removeDataLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
const updateLocalStorageFeatureFlag = (keyName: string) => {
  const flagData = JSON.parse(localStorage.getItem("FeatureFlag") || "[]");

  if (flagData && flagData.length) {
    const result = flagData.findIndex((key: FeatureFlagDetail) =>
      key.keyName.includes(keyName)
    );
    flagData[result].keyValue = false;
  }
  storeDatatoLocalStorage("FeatureFlag", flagData);
};
const clearDataLocalStorage = () => {
  localStorage.clear();
};

export {
  getDataLocalStorage,
  storeDatatoLocalStorage,
  removeDataLocalStorage,
  updateLocalStorageFeatureFlag,
  clearDataLocalStorage
};
