import { LegDataMapper } from "@/data/persistences/mappers/LegDataMapper";
import { LegRepositoryInterface } from "@/data/persistences/repositories/contracts/LegRepositoryInterface";
import { LegEntities, LegData, TransportData } from "@/domain/entities/Leg";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { EditLegRequestInterface } from "@/data/payload/contracts/LegRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { EditLegApiRequest } from "@/data/payload/api/LegApiRequest";
import { RequestListRouteLeg } from "@/data/payload/api/LegApiRequest";

export class LegApiRepository implements LegRepositoryInterface {
  private service: ApiService;
  private mapper: LegDataMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: LegDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getLegList(
    page: number,
    limit: number,
    status: string,
    originId: string,
    destinationId: string,
    sortBy: string
  ): Promise<LegEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListLeg(
        page,
        limit,
        status,
        originId,
        destinationId,
        sortBy
      ),
      {}
    );
    return this.mapper.convertLegDataFromApi(resp);
  }

  public async getDetailLeg(id: number): Promise<LegData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailLeg(id),
      {}
    );
    return this.mapper.convertDetailLegDataFromApi(resp);
  }

  public async editLeg(
    payload: EditLegRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "patch",
      this.endpoints.editLeg(),
      undefined,
      payload as EditLegApiRequest
    );
    return this.mapper.convertPayloadData(resp);
  }

  public async getDetailTransport(id: number): Promise<TransportData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getTransportDetail(id),
      {}
    );
    return this.mapper.convertTransportStatusFromApi(resp);
  }

  public async getListRouteLeg(
    params: RequestListRouteLeg
  ): Promise<LegData[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListRouteLeg(params),
      {}
    );
    return this.mapper.convertRouteLegDataFromApi(resp);
  }
}
