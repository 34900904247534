import { FirebaseRepositoryInterface } from "@/data/persistences/repositories/contracts/FirebaseRepositoryInterface";
import FirebaseService from "@/app/infrastructures/services/FirebaseService";

export class FirebaseApiRepository implements FirebaseRepositoryInterface {
  private service: FirebaseService;

  constructor(service: FirebaseService) {
    this.service = service;
  }

  public async getTokenFirebase(): Promise<string> {
    return await this.service.getTokenFirebase();
  }
  public async deleteTokenFirebase(): Promise<string> {
    return await this.service.deleteTokenFirebase();
  }
  public onMessageFirebase(callback: Function) {
    this.service.onMessageFirebase(callback);
  }
}
