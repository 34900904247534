import {
  ALL_FEATURE_ENABLE,
  ALLOWED
} from "@/app/infrastructures/misc/RolePermission";
import { getUserRolePermission } from "@/app/infrastructures/misc/Cookies";

const checkRolePermission = (permission: string) => {
  // bypass permission
  if (permission === ALLOWED) return true;

  const rolePermission = JSON.parse(getUserRolePermission());
  if (rolePermission === null || !permission) return false;
  if (rolePermission.length === 0) return false;

  // check throughout the array return true if permission exist
  return !!rolePermission.find((role: any) => {
    // enable by default if all feature enable exist
    if (!role.name) return false;
    if (role.name === ALL_FEATURE_ENABLE) return true;
    if (Array.isArray(permission)) {
      return permission.includes(role.name);
    }
    return permission === role.name;
  });
};
export default checkRolePermission;
