import { Pagination } from "@/domain/entities/Pagination";
import {
  BaseRatesEntities,
  BaseRatesData,
  RatesDetail,
  UpdateHistory
} from "@/domain/entities/BaseRates";
import { AxiosResponse } from "axios";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class BaseRatesMapper {
  public convertBaseRatesDataFromApi(res: AxiosResponse): BaseRatesEntities {
    const { data } = res;

    const baseRates: BaseRatesData[] = [];
    data.data.map((item: any) => {
      baseRates.push(
        new BaseRatesData(
          item.rate_version_id,
          item.rate_version_name,
          item.rate_version_start_date,
          item.rate_version_end_date,
          item.rate_version_created_at,
          item.rate_version_updated_at,
          item.rate_version_status,
          item.total_leg_rate
        )
      );
    });
    return new BaseRatesEntities(
      new Pagination(data.meta.page, data.meta.limit),
      baseRates
    );
  }

  public convertDetailRatesDataFromApi(
    result: AxiosResponse<any>
  ): RatesDetail {
    const {
      data: { data }
    } = result;
    return new RatesDetail(
      data.rate_version_id,
      data.rate_version_name,
      data.rate_version_applied_to,
      data.rate_version_total_route,
      data.rate_version_file_upload,
      data.rate_version_status,
      data.rate_version_update_history.length > 0
        ? data.rate_version_update_history.map((item: any) => {
            return new UpdateHistory(
              item.id,
              item.action,
              item.account_name,
              item.version_name,
              item.created_at
            );
          })
        : [],
      data.rate_version_start_date,
      data.rate_version_end_date,
      data.rate_version_created_at,
      data.rate_version_updated_at,
      data.rate_version_created_by,
      data.rate_version_updated_by,
      data.rate_version_currency
    );
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
