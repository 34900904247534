import { CMS_BANNER } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const CMSBannerList = () =>
  import(
    /* webpackChunkName: "CMSBanner" */ `@/app/ui/views/cms-banner/index.vue`
  );
const CMSBannerDetail = () =>
  import(
    /* webpackChunkName: "CMSBanner" */ `@/app/ui/views/cms-banner/detail/index.vue`
  );
const CMSBannerUpdateCreate = () =>
  import(
    /* webpackChunkName: "CMSBanner" */ `@/app/ui/views/cms-banner/update-create/index.vue`
  );

export default [
  {
    path: "/cms-banner",
    component: Layout,
    redirect: "/cms-banner",
    name: "cms-banner",
    meta: {
      header: true,
      icon: "cms-banner",
      title: "Banner Config",
      name: "CMS Banner",
      permission: CMS_BANNER.ENABLE
    },
    children: [
      {
        path: "/cms-banner",
        name: "cms-banner",
        component: CMSBannerList,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/cms-banner",
    component: Layout,
    name: "detail-cms-banner",
    meta: {
      title: "Banner Config | Detail",
      hidden: true,
      parent: "CMS Banner",
      permission: CMS_BANNER.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-cms-banner",
        component: CMSBannerDetail,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/cms-banner",
    component: Layout,
    name: "create-banner",
    meta: {
      icon: "route",
      children: false,
      hidden: true,
      parent: "CMS Banner",
      permission: CMS_BANNER.CREATE
    },
    children: [
      {
        path: "create",
        name: "create-cms-banner",
        component: CMSBannerUpdateCreate,
        meta: {
          header: true,
          title: "CMS Banner | Create",
          name: "Buat Banner",
          before: "/cms-banner"
        }
      }
    ]
  },
  {
    path: "/cms-banner",
    component: Layout,
    name: "edit-cms-banner",
    meta: {
      icon: "route",
      children: false,
      hidden: true,
      parent: "CMS Banner",
      permission: CMS_BANNER.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-cms-banner",
        component: CMSBannerUpdateCreate,
        meta: {
          header: true,
          title: "CMS Banner | Edit",
          name: "Ubah Banner",
          before: "/cms-banner/:id"
        }
      }
    ]
  }
];
