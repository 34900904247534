import { Pagination } from "./Pagination";

export class LastBalanceEntities {
  pagination: Pagination;
  data: LastBalanceData[];
  constructor(pagination: Pagination, data: LastBalanceData[]) {
    this.pagination = pagination;
    this.data = data;
  }

  mappedDataIdToString() {
    return this.data
      .map((item: LastBalanceData) => item.idClientPartner)
      .toString();
  }
}

export class LastBalanceData {
  idClientPartner = 0;
  codeClientPartner = "";
  nameClientPartner = "";
  lastBalance = 0;
  lastTransaction = "-";
  currency: "IDR" | "MYR" = "IDR";
  contractStatus = "";
  constructor(fields?: Partial<LastBalanceData>) {
    Object.assign(this, fields);
  }
}
