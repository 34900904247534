import { Pagination } from "@/domain/entities/Pagination";
import {
  ReportCheckData,
  ReportListDownload,
  ReportListDownloadData,
  LastSyncSttReport
} from "@/domain/entities/Report";
import { AxiosResponse } from "axios";
import parsingLinkGcpToAws from "@/app/infrastructures/misc/common-library/ParsingLinkGcpToAws";

export class ReportMapper {
  public convertCheckDataFromApi(res: AxiosResponse<any>): ReportCheckData {
    const { data } = res;
    return new ReportCheckData({
      code: data.code,
      message: data.message,
      success: data.success,
      data: data.data
    });
  }

  public convertListDownloadReportFromApi(
    res: AxiosResponse<any>
  ): ReportListDownload {
    const { data } = res;
    const reportDownloadList: ReportListDownloadData[] = [];
    data.data?.map((item: any) =>
      reportDownloadList.push(
        new ReportListDownloadData({
          id: item.id,
          url: parsingLinkGcpToAws(item.url),
          type: item.type,
          createdAt: item.created_at,
          status: item.status
        })
      )
    );
    return new ReportListDownload(
      new Pagination(data.meta.page, data.meta.limit),
      reportDownloadList
    );
  }

  public convertLastSyncSttReportFromApi(
    result: AxiosResponse
  ): LastSyncSttReport {
    const {
      data: { data }
    } = result;

    return new LastSyncSttReport({
      lastUpdatedAt: data.last_updated_at
    });
  }
}
