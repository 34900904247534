/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  NotificationData,
  NotificationsEntities,
  UnreadData
} from "@/domain/entities/Notification";
import { NotificationPresenter } from "../presenters/NotificationPresenter";
import { getDayName, groupBy } from "@/app/infrastructures/misc/Utils";
import { ReadNotificationApiRequest } from "@/data/payload/api/NotificationRequest";

export interface NotificationState {
  unreadData: UnreadData;
  notificationData: NotificationData[];
  sidebarNotificationData: NotificationData[];
  primaryNotificationData: NotificationData[];
  detailNotification: NotificationData;
  isLoading: boolean;
  isError: boolean;
  limit: number;
  isOpen: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "Notification" })
class NotificationStore extends VuexModule implements NotificationState {
  public unreadData = new UnreadData(0, []);
  public notificationData: Array<NotificationData> = [];
  public sidebarNotificationData: Array<NotificationData> = [];
  public primaryNotificationData: Array<NotificationData> = [];
  public isLoading = false;
  public isError = false;
  public limit = 3;
  public isOpen = false;
  public detailNotification = new NotificationData(
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0,
    [],
    "",
    false
  );
  public errorCause = "";

  @Mutation
  public setLoading(bool: boolean) {
    this.isLoading = bool;
  }

  @Mutation
  public setError(bool: boolean) {
    this.isError = bool;
  }

  @Mutation
  public setUnreadData(data: UnreadData) {
    this.unreadData = data;
  }

  @Mutation
  public setNotificationData(data: NotificationData[]) {
    this.notificationData = data;
  }

  @Mutation
  public setEmptyNotificationData() {
    this.notificationData = [];
  }

  @Mutation
  public setEmptyNotificationDataSidebar() {
    this.sidebarNotificationData = [];
  }

  @Mutation
  public setSearchNotificationData(data: NotificationData[]) {
    this.notificationData = data;
  }

  @Mutation
  public setSidebarNotificationData(data: NotificationData[]) {
    this.sidebarNotificationData = data;
  }

  @Mutation
  public setPrimaryNotificationData(data: NotificationData[]) {
    this.primaryNotificationData = data;
  }

  @Mutation
  public setDetailNotificationData(data: NotificationData) {
    this.detailNotification = data;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setIsOpen(bool: boolean) {
    this.isOpen = bool;
  }

  @Action
  public _getUnreadData(cache: boolean) {
    this.setLoading(true);
    this.setUnreadData(new UnreadData(0, []));
    const presenter = container.resolve(NotificationPresenter);
    presenter
      .getUnread(true)
      .then((res: UnreadData) => {
        this.setUnreadData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _getListNotification(params: {
    page: number;
    limit: number;
    status: string;
    search: string;
    isPinned: number;
    sort: string;
    cache: boolean;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(NotificationPresenter);
    presenter
      .getAnnouncement(
        params.page,
        params.limit,
        params.status,
        params.search,
        params.isPinned,
        params.sort,
        params.cache
      )
      .then((res: NotificationsEntities) => {
        if (res.notifications.length !== 0) {
          this.setNotificationData(res.notifications);
          this.setError(false);
          this.setErrorCause("");
        } else {
          this.setEmptyNotificationData();
        }
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _getListNotificationSearch(params: {
    page: number;
    limit: number;
    status: string;
    search: string;
    isPinned: number;
    sort: string;
    cache: boolean;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(NotificationPresenter);
    presenter
      .getAnnouncement(
        params.page,
        params.limit,
        params.status,
        params.search,
        params.isPinned,
        params.sort,
        params.cache
      )
      .then((res: NotificationsEntities) => {
        this.setSearchNotificationData(res.notifications);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setEmptyNotificationData();
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _getAnnouncementData(params: {
    page: number;
    limit: number;
    status: string;
    search: string;
    isPinned: number;
    sort: string;
    cache: boolean;
  }) {
    this.setLoading(true);
    this.setPrimaryNotificationData([]);
    const presenter = container.resolve(NotificationPresenter);
    presenter
      .getAnnouncement(
        params.page,
        params.limit,
        params.status,
        params.search,
        params.isPinned,
        params.sort,
        params.cache
      )
      .then((res: NotificationsEntities) => {
        this.setPrimaryNotificationData(res.primaryNotifications);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _getNotificationSidebarData(params: {
    page: number;
    limit: number;
    status: string;
    search: string;
    isPinned: number;
    sort: string;
    cache: boolean;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(NotificationPresenter);
    presenter
      .getAnnouncement(
        params.page,
        params.limit,
        params.status,
        params.search,
        params.isPinned,
        params.sort,
        params.cache
      )
      .then((res: NotificationsEntities) => {
        this.setSidebarNotificationData(res.notifications);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setEmptyNotificationData();
        this.setEmptyNotificationDataSidebar();
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _getDetail(id: number) {
    this.setLoading(true);
    const presenter = container.resolve(NotificationPresenter);
    presenter
      .detailNotification(id)
      .then((res: NotificationData) => {
        this.setDetailNotificationData(res);
        this._onReadNotification(id);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _onReadNotification(announcementId: number) {
    this.setLoading(true);
    const presenter = container.resolve(NotificationPresenter);
    presenter
      .readNotification(new ReadNotificationApiRequest(Number(announcementId)))
      .then(() => {
        this.setEmptyNotificationData();
        this._getUnreadData(true);
        this._getNotificationSidebarData({
          page: 1,
          limit: 10,
          status: "",
          search: "",
          isPinned: 0,
          sort: "",
          cache: true
        });
        this._getListNotification({
          page: 1,
          limit: 10,
          status: "",
          search: "",
          isPinned: 0,
          sort: "",
          cache: true
        });
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _setIsOpen(payload: any) {
    this.setIsOpen(payload);
  }

  get groupAllNotification() {
    if (this.notificationData.length > 0) {
      const readNotification: Array<any> = this.notificationData.map(
        (item: any) => ({
          ...item,
          filter: getDayName(item.releasedAt)
        })
      );
      return groupBy(readNotification, "filter");
    }
    return false;
  }

  get groupNotificationSidebar() {
    const readNotification: Array<any> = this.sidebarNotificationData.map(
      (item: any) => ({
        ...item,
        filter: getDayName(item.releasedAt)
      })
    );
    return groupBy(readNotification.splice(0, 10), "filter");
  }

  get mainNotifications() {
    const readNotification: Array<any> = this.notificationData.map(
      (item: any) => ({
        ...item,
        filter: getDayName(item.releasedAt)
      })
    );
    const data: Array<any> = [];
    readNotification.map(item => item.pinned && data.push(item));
    return data.splice(0, 5);
  }

  get getIsOpen() {
    return this.isOpen
  }
}

export const NotificationController = getModule(NotificationStore);
