import { LocationSearchEntities } from "@/domain/entities/LocationSearch";
import { LocationSearchApiRepository } from "@/app/infrastructures/repositories/api/LocationSearchApiRepository";
import { injectable } from "tsyringe";

@injectable()
export class LocationSearchPresenter {
  private repository: LocationSearchApiRepository;

  constructor(repo: LocationSearchApiRepository) {
    this.repository = repo;
  }
  public async getLocationSearchList(
    page: number,
    limit: number,
    search: string
  ): Promise<LocationSearchEntities> {
    return await this.repository.getLocationSearchList(page, limit, search);
  }
}
