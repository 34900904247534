import { BalanceApiRepository } from "@/app/infrastructures/repositories/api/BalanceApiRepository";
import { injectable } from "tsyringe";
import { BankAccountEntities } from "@/domain/entities/BankAccount";
import {
  BalanceData,
  BalanceReportMutationApiRequest,
  InternalBankAccountDetail,
  InternalBankAccountList,
  TopupManualDetail,
  TransactionHistoryEntities,
  TransactionList
} from "@/domain/entities/Balance";
import {
  AddBalanceRequestInterface,
  WithdrawBalanceRequestInterface
} from "@/data/payload/contracts/BalanceRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { GetListBalanceApiRequest } from "@/data/payload/api/BalanceRequest";

@injectable()
export class BalancePresenter {
  private repository: BalanceApiRepository;

  constructor(repo: BalanceApiRepository) {
    this.repository = repo;
  }

  public getListBankAccount(
    page: number,
    limit: number,
    type: string
  ): Promise<BankAccountEntities> {
    return this.repository.getListBankAccount(page, limit, type);
  }

  public getBalance(cache: boolean): Promise<BalanceData> {
    return this.repository.getBalance(cache);
  }

  public getTransactionList(
    params: GetListBalanceApiRequest,
    apiVersion: string
  ): Promise<TransactionList> {
    return this.repository.getTransactionList(
      params,
      apiVersion
    );
  }

  public getListInternalBankAccount(): Promise<InternalBankAccountList> {
    return this.repository.getListInternalBankAccount();
  }

  public getDetailInternalBankAccount(
    id: number
  ): Promise<InternalBankAccountDetail> {
    return this.repository.getDetailInternalBankAccount(id);
  }

  public getDetailTopupManual(id: number): Promise<TopupManualDetail> {
    return this.repository.getDetailTopupManual(id);
  }

  public getTransactionHistoryList(
    page: number,
    limit: number,
    status: string,
    startDate: string,
    endDate: string
  ): Promise<TransactionHistoryEntities> {
    return this.repository.getListTransactionHistory(
      page,
      limit,
      status,
      startDate,
      endDate
    );
  }
  addTopupManual(
    payload: AddBalanceRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addTopupManual(payload);
  }
  withdrawBalance(
    payload: WithdrawBalanceRequestInterface,
    version: string
  ): Promise<ResponsePayload> {
    return this.repository.withdrawBalance(payload, version);
  }
  getBalanceClientPrtner(params: any): Promise<BalanceData> {
    return this.repository.getBalanceClientPartner(params);
  }
  getDownloadBalanceReportMutation(params: BalanceReportMutationApiRequest) {
    return this.repository.getDownloadBalanceReportMutation(params);
  }
}
