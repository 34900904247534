const convertDecimalWithComma = (value: any, long: number) => {
  if (typeof value === "number") {
    return value % 1 === 0
      ? value
      : value
          .toFixed(long)
          .toString()
          .replace(".", ",");
  }
  return value;
};
export default convertDecimalWithComma;
