import { ConfigPriceApiRepository } from "@/app/infrastructures/repositories/api/ConfigPriceApiRepository";
import { Entities } from "@/domain/entities/ResponsePayload";
import { injectable } from "tsyringe";

@injectable()
export class ConfigPricePresenter {
  private repository: ConfigPriceApiRepository;

  constructor(repository: ConfigPriceApiRepository) {
    this.repository = repository;
  }

  public getListConfigPrice(
    page: number,
    search: string,
    status: string
  ): Promise<Entities> {
    return this.repository.getList(page, search, status);
  }
}
