import { Pagination } from "./Pagination";

export class PartnerSubconsoleEntities {
  pagination: Pagination;
  data: PartnerSubconsoleData[];

  constructor(pagination: Pagination, data: PartnerSubconsoleData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class PartnerSubconsoleData {
  partnerSubconsoleId: number;
  partnerSubconsoleName: string;
  partnerSubconsoleCode: string;
  partnerSubconsoleDistrict: string;
  partnerSubconsoleCity: string;
  partnerConsoleName: string;
  partnerSubconsoleEndContract: Date;
  partnerSubconsoleContractStatus: string;
  partnerSubconsoleStatus: boolean;
  constructor(
    partnerSubconsoleId: number,
    partnerSubconsoleName: string,
    partnerSubconsoleCode: string,
    partnerSubconsoleDistrict: string,
    partnerSubconsoleCity: string,
    partnerConsoleName: string,
    partnerSubconsoleEndContract: Date,
    partnerSubconsoleContractStatus: string,
    partnerSubconsoleStatus: boolean
  ) {
    this.partnerSubconsoleId = partnerSubconsoleId;
    this.partnerSubconsoleName = partnerSubconsoleName;
    this.partnerSubconsoleCode = partnerSubconsoleCode;
    this.partnerSubconsoleDistrict = partnerSubconsoleDistrict;
    this.partnerSubconsoleCity = partnerSubconsoleCity;
    this.partnerConsoleName = partnerConsoleName;
    this.partnerSubconsoleEndContract = partnerSubconsoleEndContract;
    this.partnerSubconsoleContractStatus = partnerSubconsoleContractStatus;
    this.partnerSubconsoleStatus = partnerSubconsoleStatus;
  }
}
