import { PRICING_EXCHANGE_RATE } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const ExchangeRateDetail = () =>
  import(
    /* webpackChunkName: "ExchangeRate" */ "@/app/ui/views/pricing/exchange-rate/component/detail.vue"
  );
const ExchangeRateAdd = () =>
  import(
    /* webpackChunkName: "ExchangeRate" */ "@/app/ui/views/pricing/exchange-rate/component/add.vue"
  );
const ExchangeRateEdit = () =>
  import(
    /* webpackChunkName: "ExchangeRate" */ "@/app/ui/views/pricing/exchange-rate/component/edit.vue"
  );

export default [
  {
    path: "/tarif/exchange-rate",
    component: Layout,
    name: "exchange-rate-add",
    meta: {
      icon: "exchange-rate",
      children: false,
      hidden: true,
      parent: "Konversi Mata Uang",
      permission: PRICING_EXCHANGE_RATE.CREATE
    },
    children: [
      {
        path: "/tarif/exchange-rate/add",
        name: "add-exchange-rate",
        component: ExchangeRateAdd,
        meta: {
          header: true,
          title: "Konversi Mata Uang | Buat",
          name: "Buat Konversi Mata Uang",
          before: "/tarif/exchange-rate"
        }
      }
    ]
  },
  {
    path: "/tarif/exchange-rate",
    component: Layout,
    name: "detail-exchange-rate",
    meta: {
      icon: "exchange-rate",
      title: "Konversi Mata Uang | Detail",
      children: false,
      hidden: true,
      parent: "Konversi Mata Uang",
      permission: PRICING_EXCHANGE_RATE.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-exchange-rate",
        component: ExchangeRateDetail,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/tarif/exchange-rate",
    component: Layout,
    name: "edit-exchange-rate",
    meta: {
      icon: "exchange-rate",
      title: "Konversi Mata Uang | Ubah",
      children: false,
      hidden: true,
      parent: "Konversi Mata Uang",
      permission: PRICING_EXCHANGE_RATE.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-exchange-rate",
        component: ExchangeRateEdit,
        meta: {
          header: true,
          name: "Edit",
          before: "/tarif/exchange-rate/:id"
        }
      }
    ]
  }
];
