const toFormDataUtils = (data: any) => {
  const formData = new FormData();
  Object.keys(data).forEach(val => {
    if (data[val] !== null) {
      if (val === "image" && typeof data["image"] === "string") {
        formData.append("image", "");
      } else if (val === "image" && typeof data["image"] !== "string") {
        const file: any = data[val];
        formData.append(val, file, file.name);
      } else if (Array.isArray(data[val])) {
        for (const dataArray of data[val]) {
          formData.append(val, dataArray);
        }
      } else {
        formData.append(val, data[val]);
      }
    } else {
      return null;
    }
  });
  return formData;
};
export default toFormDataUtils;
