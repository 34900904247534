import Axios, { AxiosInstance, AxiosResponse, Method } from "axios";
import qs from "qs";
import { injectable } from "tsyringe";
import { Endpoints } from "../misc/Endpoints";
import { getTokenNGen, removeAuthCredentialNGen } from "../misc/Cookies";
import { ApiServiceInterface } from "@/data/infrastructures/ApiServiceInterface";

@injectable()
export default class ApiServiceNGen implements ApiServiceInterface {
  public client: AxiosInstance;

  constructor() {
    this.client = Axios.create({
      baseURL: Endpoints.baseUrlNGen,
      timeout: 60000
    });
    const url = String(process.env.VUE_APP_NGEN_GENESIS_API_URL_VERSION).match(
      /v2/gi
    )
      ? process.env.VUE_APP_NGEN_GENESIS_API_URL_DEV_V2
      : process.env.VUE_APP_NGEN_GENESIS_API_URL_DEV_V1;

    if (process.env.NODE_ENV || url) {
      this.client.interceptors.response.use(
        resp => {
          return resp;
        },
        error => {
          if (error.response.status === 401) {
            removeAuthCredentialNGen();
          }
          return Promise.reject(error);
        }
      );
    }
  }

  public async invoke(
    method: Method = "get",
    url = "",
    params: any = {},
    payload: any = null,
    headers: Map<string, string> = new Map()
  ): Promise<AxiosResponse<any>> {
    // content-type application/json
    this.client.defaults.headers["Content-Type"] = "application/json";
    // set custom header if any
    headers.forEach((value: string, key: string) => {
      this.client.defaults.headers.common[key] = value;
    });

    // set auth
    this.client.defaults.headers.Authorization = getTokenNGen();
    return await this.client.request({
      url,
      params,
      paramsSerializer: par => qs.stringify(par, { encode: false }),
      data: payload ? payload.toJSON() : null,
      method
    });
  }

  public async invokeWithoutHeader(
    method: Method = "get",
    url = "",
    params: any = {},
    payload: any = null
  ): Promise<AxiosResponse<any>> {
    // content-type application/json
    this.client.defaults.headers["Content-Type"] = "application/json";
    return await this.client.request({
      url,
      params,
      paramsSerializer: par => qs.stringify(par, { encode: false }),
      data: payload ? payload.toJSON() : null,
      method
    });
  }

  public async invokePostWithFormData<T>(
    method: Method = "post",
    url = "",
    params: any = {},
    payload: any = null
  ): Promise<AxiosResponse<any>> {
    // set common header
    return await this.client.request({
      url,
      params,
      paramsSerializer: par => qs.stringify(par, { encode: false }),
      data: payload.toFormData(),
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: getTokenNGen()
      },
      method
    });
  }
}
