import {
  RequestExportFileExcel,
  RequestListHandover,
  RequestPartnerVendorList,
  UpdateApiRequest
} from "@/data/payload/api/HandoverApiRequest";
import { UpdateHandoverRequestInterface } from "@/data/payload/contracts/HandoverRequest";
import { HandoverMapper } from "@/data/persistences/mappers/HandoverMapper";
import { HandoverRepositoryInterface } from "@/data/persistences/repositories/contracts/HandoverRepositoryInterface";
import {
  HandoverList,
  HandoverDetail,
  PartnerVendorList
} from "@/domain/entities/Handover";
import { SubconsoleEntities } from "@/domain/entities/Subconsole";
import {
  ResponsePayload,
  ResponseSttDetail
} from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import {downloadFile} from "@/app/infrastructures/misc/Utils";

export class HandoverApiRepository implements HandoverRepositoryInterface {
  private service: ApiService;
  private mapper: HandoverMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: HandoverMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  public async getDetailHandover(handoverId: number): Promise<HandoverDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailHandover(handoverId),
      {}
    );
    return this.mapper.convertHandoverManifestFromApi(resp);
  }

  public async getHandoverList(
    params: RequestListHandover
  ): Promise<HandoverList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getHandoverList(params),
      {}
    );
    return this.mapper.convertHandovertListFromApi(resp);
  }

  public async getListSubconsole(
    parentId: number
  ): Promise<SubconsoleEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListSubconsole(parentId),
      {}
    );
    return this.mapper.convertListSubconsoleDataFromApi(resp);
  }

  public async getSttDetail(stt: string, bagNo: string, partnerCode: string): Promise<ResponseSttDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSttDetailHandover(stt, bagNo, partnerCode),
      {}
    );
    return this.mapper.convertSttDetailDataFromApi(resp);
  }

  public async updateHandover(
    payload: UpdateHandoverRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "post",
      this.endpoints.updateHandover(),
      {},
      payload as UpdateApiRequest
    );
    return this.mapper.convertUpdateDataFromApi(response);
  }

  public async getPartnerVendorList(
    params: RequestPartnerVendorList
  ): Promise<PartnerVendorList[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getPartnerVendorList(params),
      {}
    );
    return this.mapper.convertPartnerVendorListDataFromApi(resp);
  }

  public async getExcelFile(
      params: RequestExportFileExcel
  ): Promise<any> {
    return downloadFile(
        `${Endpoints.baseUrl}${this.endpoints.getExportFileExcel(params)}`,
        `Handover Manifest - ${params.toIDString()}`,
        ".xlsx",
        true
    );
  }
}
