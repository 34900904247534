import {
  SHIPMENT_STT_MANUAL,
  SHIPMENT_STT_BOOKING,
  SHIPMENT_PRINT_RECEIPT,
  SHIPMENT_CN_MANIFEST,
  SHIPMENT_BULK_PRINT_RESI,
  BULK_BOOKING,
  BULK_BOOKING_CLIENT_TEMPLATE,
  TRACK_DELIVERY
} from "@/app/infrastructures/misc/RolePermission";
import { FlagsPermissionBooking } from "@/feature-flags/flag-booking";

import BookingModules from "./booking";
import OnProcessSttModules from "./on-process-stt";
import { FlagsPermissionSTTTracking } from "../../../../../feature-flags/flags-stt-tracking";
import { FlagsPermissionCargoTracking } from "../../../../../feature-flags/flags-cargo-tracking";
import { FlagsPermissionOnProcessstt } from "@/feature-flags/flags-permission-on-process-stt";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const ManualSTT = () =>
  import(
    /* webpackChunkName: "ManualSTT" */ "@/app/ui/views/shipment/manual-stt/index.vue"
  );
const ShipmentBooking = () =>
  import(
    /* webpackChunkName: "STTBooking" */ "@/app/ui/views/shipment/booking/index.vue"
  );
const TabBooking = () =>
    import(
      /* webpackChunkName: "STTBookingList" */ "@/app/ui/views/shipment/booking/tab-booking/tab-list-booking.vue"
    );
const TabBookingClientCBP = () =>
      import(
        /* webpackChunkName: "STTBookingClientCBP" */ "@/app/ui/views/shipment/booking/tab-booking/tab-list-booking-client-cbp.vue"
      );
const OnProcessStt = () =>
  import(
    /* webpackChunkName: "OnProcessSTT" */ "@/app/ui/views/shipment/on-process-stt/index.vue"
  );
const BulkBooking = () =>
  import(
    /* webpackChunkName: "BulkBooking" */ "@/app/ui/views/shipment/bulk-booking/index.vue"
  );
const BulkBookingTabs = () =>
  import(
    /* webpackChunkName: "BulkBooking" */ "@/app/ui/views/shipment/bulk-booking/bulk-booking-tab/index.vue"
  );
const ClientBookingTabs = () =>
  import(
    /* webpackChunkName: "BulkBooking" */ "@/app/ui/views/shipment/bulk-booking/client-booking-tab/index.vue"
  );
const ClientBookingDetail = () =>
  import(
    /* webpackChunkName: "ClientBulkBooking" */ "@/app/ui/views/shipment/bulk-booking/client-booking-tab/detail.vue"
  );
const ClientBookingEdit = () =>
  import(
    /* webpackChunkName: "ClientBulkBooking" */ "@/app/ui/views/shipment/bulk-booking/client-booking-tab/edit.vue"
  );
const ClientBookingCreate = () =>
  import(
    /* webpackChunkName: "ClientBulkBooking" */ "@/app/ui/views/shipment/bulk-booking/client-booking-tab/create.vue"
  );
const BulkPrintResi = () =>
  import(
    /* webpackChunkName: "BulkPrint" */ "@/app/ui/views/shipment/bulk-print-resi/index.vue"
  );
const CNManifest = () =>
  import(
    /* webpackChunkName: "CNManifest" */ "@/app/ui/views/shipment/cn-manifest/index.vue"
  );
const PrintReceipt = () =>
  import(
    /* webpackChunkName: "PrintResi" */ "@/app/ui/views/shipment/print-receipt/index.vue"
  );

// Tracking stt & cargo
const TrackingSttCargoTabs = () =>
  import(
    /* webpackChunkName: "Tracking" */ "@/app/ui/views/shipment/tracking-stt-cargo/index.vue"
  );
const TrackingStt = () =>
  import(
    /* webpackChunkName: "Tracking" */ "@/app/ui/views/shipment/tracking-stt-cargo/tracking-stt-tab/index.vue"
  );
const TrackingCargo = () =>
  import(
    /* webpackChunkName: "Tracking" */ "@/app/ui/views/shipment/tracking-stt-cargo/tracking-cargo-tab/index.vue"
  );
const TrackDelivery = () =>
  import(
    /* webpackChunkName: "TrackDelivery" */ "@/app/ui/views/track-delivery/index.vue"
  );
const DeliveryTab = () =>
  import(
    /* webpackChunkName: "TrackDelivery" */ "@/app/ui/views/track-delivery/delivery-tab/index.vue"
  );
const NeedToSTI = () =>
    import(
        /* webpackChunkName: "STI" */ "@/app/ui/views/out-going-shipment/sti/reverse-scan/sti-need-to-sti-reverse-scan.vue"
        );

export default [
  {
    path: "/shipment",
    component: Layout,
    name: "shipment",
    meta: {
      icon: "shipment-menu",
      children: true,
      header: true,
      title: "Shipment",
      name: "Shipment",
      gtmEventName: "shipment_menu_clicked",
      gtmEventCustoms: {},
      gtmEventOption: ["userType", "timestamp", "userId"],
      permission: [
        FlagsPermissionBooking.permission_booking_enable,
        SHIPMENT_STT_BOOKING.ENABLE,
        FlagsPermissionOnProcessstt.on_process_stt_enable,
        FlagsPermissionCargoTracking.permission_cargo_tracking_enable,
        FlagsPermissionSTTTracking.permission_stt_tracking_enable,
        BULK_BOOKING.ENABLE,
        SHIPMENT_BULK_PRINT_RESI.ENABLE,
        SHIPMENT_CN_MANIFEST.ENABLE,
        SHIPMENT_PRINT_RECEIPT.ENABLE
      ]
    },
    children: [
      {
        path: "booking",
        name: "booking",
        component: ShipmentBooking,
        redirect: "/shipment/booking/booking-list",
        meta: {
          header: true,
          icon: "booking",
          title: "Shipment - Booking",
          name: "Booking",
          gtmEventName: "booking_submenu_clicked",
          gtmEventCustoms: {},
          gtmEventOption: ["userType", "timestamp", "userId"],
          permission: FlagsPermissionBooking.permission_booking_view_list
        },
        children: [
          {
            path: "booking-list",
            name: "booking-list",
            component: TabBooking,
            meta: {
              tab: "booking-list",
              permission: FlagsPermissionBooking.permission_booking_view_list
            }
          },
          {
            path: "booking-client-cbp-list",
            name: "booking-client-cbp-list",
            component: TabBookingClientCBP,
            meta: {
              tab: "booking-client-cbp-list",
              // permission: FlagsPermissionBooking.permission_booking_view_list
            }
          }
        ]
      },
      {
        path: "need-to-sti",
        name: "need-to-sti-booking",
        component: NeedToSTI,
        meta: {
          header: true,
          title: "",
          name: "Daftar harus STI"
        }
      },
      {
        path: "bulks",
        name: "bulks",
        redirect: BULK_BOOKING.ENABLE
          ? "/shipment/bulks/bulk-book"
          : "/shipment/bulks/klien-book",
        component: BulkBooking,
        meta: {
          header: true,
          icon: "bulk-booking",
          title: "Shipment - Bulk Booking",
          name: "Bulk Booking",
          isFreeze: true,
          gtmEventName: "bulk_booking_submenu_clicked",
          gtmEventCustoms: {},
          gtmEventOption: ["userType", "timestamp", "userId"],
          permission: [BULK_BOOKING.ENABLE, BULK_BOOKING_CLIENT_TEMPLATE.ENABLE]
        },
        children: [
          {
            path: "bulk-book",
            name: "bulk-book",
            component: BulkBookingTabs,
            meta: {
              tab: "bulk-book",
              permission: BULK_BOOKING.ENABLE
            }
          },
          {
            path: "klien-book",
            name: "klien-book",
            component: ClientBookingTabs,
            meta: {
              tab: "klien-book",
              permission: BULK_BOOKING_CLIENT_TEMPLATE.ENABLE
            }
          },
          {
            path: "klien-book/create-klien-booking",
            name: "klien-book-create",
            component: ClientBookingCreate,
            meta: {
              tab: "klien-book",
              permission: BULK_BOOKING_CLIENT_TEMPLATE.CREATE
            }
          },
          {
            path: "klien-book/:id",
            name: "klien-book-detail",
            component: ClientBookingDetail,
            meta: {
              tab: "klien-book",
              permission: BULK_BOOKING_CLIENT_TEMPLATE.DETAIL
            }
          },
          {
            path: "klien-book/:id/edit",
            name: "klien-book-detail-edit",
            component: ClientBookingEdit,
            meta: {
              tab: "klien-book",
              permission: BULK_BOOKING_CLIENT_TEMPLATE.EDIT
            }
          }
        ]
      },
      {
        path: "tracking",
        name: "tracking",
        redirect: "/shipment/tracking/stt",
        component: TrackingSttCargoTabs,
        meta: {
          header: true,
          icon: "tracking",
          title: "Shipment - Tracking",
          name: "Tracking",
          hideFeatureFor: "branch",
          permission: FlagsPermissionSTTTracking.permission_stt_tracking_enable || FlagsPermissionCargoTracking.permission_cargo_tracking_enable,
        },
        children: [
          {
            path: "stt",
            name: "tracking-stt",
            component: TrackingStt,
            meta: {
              isAuth: true
            }
          },
          {
            path: "cargo",
            name: "tracking-cargo",
            component: TrackingCargo
          }
        ]
      },
      {
        path: "lacak-pengiriman",
        name: "lacak-pengiriman",
        redirect: "/shipment/lacak-pengiriman/lacak-stt",
        component: TrackDelivery,
        meta: {
          header: true,
          icon: "track-delivery",
          title: "Pengiriman",
          name: "Pengiriman",
          permission: TRACK_DELIVERY.ENABLE,
          isAuth: true
        },
        children: [
          {
            path: "lacak-stt",
            name: "lacak-stt",
            component: TrackingStt,
            meta: {
              permission: TRACK_DELIVERY.LIST
            }
          },
          {
            path: "pengiriman-aktif",
            name: "pengiriman-aktif",
            component: DeliveryTab,
            meta: {
              permission: TRACK_DELIVERY.LIST
            }
          },
          {
            path: "riwayat-pengiriman",
            name: "riwayat-pengiriman",
            component: DeliveryTab,
            meta: {
              permission: TRACK_DELIVERY.LIST
            }
          }
        ]
      },
      {
        path: "manual-stt",
        name: "manual-stt",
        component: ManualSTT,
        meta: {
          header: true,
          icon: "manual-stt",
          title: "Shipment - Manual STT",
          name: "Manual STT",
          permission: FlagsPermissionBooking.permission_booking_enable
        }
      },
      {
        path: "on-process-stt",
        name: "on-process-stt",
        component: OnProcessStt,
        meta: {
          header: true,
          icon: "on-process-stt",
          title: "Shipment - On Process STT",
          name: "On Process STT",
          isFreeze: true,
          gtmEventName: "on_process_STT_menu",
          gtmEventCustoms: {},
          gtmEventOption: ["userId", "userType", "timestamp"],
          permission: FlagsPermissionOnProcessstt.on_process_stt_enable
        }
      },
      {
        path: "cn-manifest",
        name: "cn-manifest",
        component: CNManifest,
        meta: {
          header: true,
          icon: "cn-manifest",
          title: "CN Manifest",
          name: "CN Manifest",
          isFreeze: true,
          permission: SHIPMENT_CN_MANIFEST.ENABLE
        }
      },
      {
        path: "bulk-print",
        name: "bulk-print",
        component: BulkPrintResi,
        meta: {
          header: true,
          icon: "bulk-print-stt",
          title: "Bulk Print STT",
          name: "Bulk Print STT",
          isFreeze: true,
          permission: SHIPMENT_BULK_PRINT_RESI.ENABLE
        }
      },
      {
        path: "print-receipt",
        name: "print-receipt",
        component: PrintReceipt,
        meta: {
          header: true,
          icon: "print-receipt",
          title: "Cetak Tagihan",
          name: "Cetak Tagihan",
          gtmEventName: "cetak_tagihan_submenu_clicked",
          gtmEventCustoms: {},
          gtmEventOption: ["userId", "userType", "timestamp"],
          isFreeze: true,
          permission: SHIPMENT_PRINT_RECEIPT.ENABLE
        }
      }
    ]
  },
  ...BookingModules,
  ...OnProcessSttModules
];
