/* eslint-disable @typescript-eslint/camelcase */
export class PosClient {
  id: number;
  name: string;
  code: string;
  type: string;
  status: string;
  isBanned: boolean;

  constructor(
    id: number,
    name: string,
    code: string,
    type: string,
    status: string,
    isBanned: boolean
  ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.type = type;
    this.status = status;
    this.isBanned = isBanned;
  }
}

export class PosClientList {
  list: PosClient[];

  constructor(list: PosClient[]) {
    this.list = list;
  }
}
