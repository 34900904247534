import {
    RequestInternationalDocumentList
} from "@/domain/entities/InternationalDocument";

export class InternationalDocumentEndpoints {
    getInternationalDocumentList(params: RequestInternationalDocumentList) {
        return `/horde/v1/international-document-configuration?${params.toQueryString()}`;
    }

    getInternationalDocumentDetail(params: {
        origin: number,
        dest: number
    }) {
        return `/horde/v1/international-document-configuration/detail?destination_city_id=${params.dest}&origin_city_id=${params.origin}`;
    }
}

