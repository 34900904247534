/* eslint-disable @typescript-eslint/camelcase */
import { ShipmentBookingMapper } from "@/data/persistences/mappers/ShipmentBookingMapper";
import { ShipmentBookingRepositoryInterface } from "@/data/persistences/repositories/contracts/ShipmentBookingRepositoryInterface";
import {
  CBPPickupListEntities,
  CBPPickupManifest,
  ShipmentBookingDetail,
  ShipmentBookingEntities,
  ShipmentPrefix,
  SttManualValidation
} from "@/domain/entities/ShipmentBooking";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { AddShipmentBookingRequestInterface } from "@/data/payload/contracts/ShipmentBookingRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  AddShipmentBooking,
  ShipmentInstantBooking
} from "@/data/payload/api/ShipmentBookingRequest";
import { AxiosResponse } from "axios";
import { RequestListOnProcessStt } from "@/data/payload/api/OnProcessApiRequest";
import { RequestListBooking } from "@/data/payload/api/BookingRequest";
import { isUrlUpdateVersion } from "../../misc/Utils";

export class ShipmentBookingApiRepository
  implements ShipmentBookingRepositoryInterface {
  private service: ApiService;
  private mapper: ShipmentBookingMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: ShipmentBookingMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  public async getManifestCBP(params: RequestListBooking): Promise<CBPPickupManifest> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getManifestCBP(params),
      {}
    );
    return this.mapper.convertDataCBPManifest(resp);
  }

  public async getBookingList(
    params: RequestListBooking
  ): Promise<ShipmentBookingEntities> {
    const resp = await this.service.invoke(
      "get",
      params.from === "payment" && isUrlUpdateVersion("paid-unpaid") === "v2"
        ? this.endpoints.getListSTTPaidUnpaidV2(params)
        : this.endpoints.getBookingList(params),
      {}
    );
    return this.mapper.convertShipmentBookingFromApi(resp);
  }

  public async getBookingListCBP(params: RequestListBooking): Promise<CBPPickupListEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getBookingListCBP(params),
      {}
    )
    return this.mapper.convertCBPPickupListEntities(resp);
  }

  public async getOnProcessSttList(
    params: RequestListOnProcessStt
  ): Promise<ShipmentBookingEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getOnProcessSttList(params),
      {}
    );
    return this.mapper.convertShipmentBookingFromApi(resp);
  }

  public async getBookingDetail(
    id: number,
    isWithEligibility: boolean,
    isAuth: boolean
  ): Promise<ShipmentBookingDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getBookingDetail(id, isWithEligibility, isAuth),
      {}
    );
    const mapper =
      process.env.VUE_APP_MIGRATE_FROM_ELEXYS === "true"
        ? (resp: AxiosResponse) =>
            this.mapper.convertDetailShipmentBookingFromApiElexys(resp)
        : (resp: AxiosResponse) =>
            this.mapper.convertDetailShipmentBookingFromApi(resp);
    return mapper(resp);
  }

  async addBookingShipment(
    payload: AddShipmentBookingRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.addShipmentBooking(),
      undefined,
      payload as AddShipmentBooking
    );
    return this.mapper.convertPayloadData(resp);
  }

  async addInstantBooking(
    payload: AddShipmentBookingRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.addShipmentBooking(2),
      undefined,
      payload as ShipmentInstantBooking
    );
    return this.mapper.convertPayloadData(resp);
  }

  async validateSttManual(
    clientId: number,
    sttManual: string,
    bookingType: string,
    productType: string
  ): Promise<SttManualValidation> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.validateSttManual(
        clientId,
        sttManual,
        bookingType,
        productType
      ),
      {}
    );
    return this.mapper.convertDataSttManualValidationFromApi(resp);
  }

  async getListShipmentPrefix(): Promise<ShipmentPrefix[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListShipmentPrefix(),
      {}
    );
    return this.mapper.convertDataShipmentPrefixFromApi(resp);
  }
}
