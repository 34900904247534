
import { Vue, Options, prop } from "vue-class-component";

class Props {
  idButtonSubmit = prop<string>({
    default: "",
    type: String
  });
  idButtonCancel = prop<string>({
    default: "",
    type: String
  });
  message = prop<string>({
    default: "",
    type: String
  });
  onClose = prop<Function>({
    default: null,
    type: Function
  });
  onSubmit = prop<Function>({
    default: null,
    type: Function
  });
  title = prop<string>({
    default: "",
    type: String
  });
  image = prop<string>({
    default: "",
    type: String
  });
  textSuccess = prop<string>({
    default: "",
    type: String
  });
  textCancel = prop<string>({
    default: "",
    type: String
  });
  isLoading = prop<boolean>({
    default: false,
    type: Boolean
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  customButton = prop<string>({
    default: "px-8",
    type: String
  });
  customWidth = prop<string>({
    default: "380px",
    type: String
  });
  reverseButton = prop<boolean>({
    default: false,
    type: Boolean
  });
  buttonFontWeight = prop<string>({
    default: "",
    type: String
  });
  imageWidth = prop<string>({
    default: "32",
    type: String,
  });
  imageHeight = prop<string>({
    default: "32",
    type: String
  });
}

@Options({
  name: "Modal"
})
export default class extends Vue.with(Props) {
  get hasCustomButtonSlots() {
    return !!this.$slots["custom-button"];
  }

  get isButtonSuccess() {
    if (!this.hasCustomButtonSlots) {
      return this.reverseButton ? !!this.textCancel : !!this.textSuccess;
    }
    return false;
  }
}
