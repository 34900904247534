import UrgentTakenByRecipient from "@/app/ui/views/urgent-delivery-management/components/urgent-taken-by-recipient/index.vue";
import { QueryParamsEntities } from "./MainApp";
import { Pagination, PaginationV2 } from "./Pagination";

export class UrgentDeliveryManagementPODList {
  data: UrgentDeliveryManagementPODData[];
  pagination: Pagination;

  constructor(data: UrgentDeliveryManagementPODData[], pagination: Pagination) {
    this.data = data;
    this.pagination = pagination;
  }

}

export class UrgentDeliveryManagementPODData {
  pdId = 0;
  pdSTTNo = "";
  pdFlag = "";
  sttDeliveryType = "";
  pdReason = "";
  pdDeadlineReturn = "";
  sttLastReturnId = "";
  sttDestinationCity = "";
  sttRecipientName = "";
  sttRecipientPhone = "";
  sttDestinationCityName = "";
  sttDestinationDistrictName = "";
  deliveryDEXAttempt = 0;
  pdCreatedAt = "";
  pdUpdatedAt = "";

  constructor(fields?: Partial<UrgentDeliveryManagementPODData>) {
    Object.assign(this, fields);
  }
}

export class UrgentDeliveryManagementPODListRequest {
  sttNo = "";
  sortBy = "";
  page = 1;
  limit = 20;
  isTotalData = true;

  constructor(fields?: Partial<UrgentDeliveryManagementPODListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class UrgentDeliveryManagementPODListRequestDownload {
  sttNo = "";
  sortBy = "";

  constructor(fields?: Partial<UrgentDeliveryManagementPODListRequestDownload>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class UrgentDeliveryManagementRTSList {
  data: UrgentDeliveryManagementRTSData[];
  pagination: Pagination;

  constructor(data: UrgentDeliveryManagementRTSData[], pagination: Pagination) {
    this.data = data;
    this.pagination = pagination;
  }

}

export class UrgentDeliveryManagementRTSData {
  pdId = 0;
  pdSTTNo = "";
  pdFlag = "";
  sttDeliveryType = "";
  pdCaseType = "";
  pdReason = "";
  pdDeadlineReturn = "";
  sttLastStatusId = "";
  sttOriginCityId = "";
  sttOriginCityName = "";
  sttDestinationCityId = "";
  sttDestinationCityName = "";
  pdCreatedAt = "";
  pdUpdatedAt = "";

  constructor(fields?: Partial<UrgentDeliveryManagementRTSData>) {
    Object.assign(this, fields);
  }
}

export class UrgentDeliveryManagementRTSListRequest {
  sttNo = "";
  sortBy = "";
  caseType = "";
  page = 1;
  limit = 20;
  isTotalData = true;

  constructor(fields?: Partial<UrgentDeliveryManagementRTSListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class UrgentDeliveryManagementRTSListRequestDownload {
  sttNo = "";
  sortBy = "";
  caseType = "";

  constructor(fields?: Partial<UrgentDeliveryManagementRTSListRequestDownload>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class UrgentDeliveryManagementHALList {
  data: UrgentDeliveryManagementHALData[];
  pagination: Pagination;

  constructor(data: UrgentDeliveryManagementHALData[], pagination: Pagination) {
    this.data = data;
    this.pagination = pagination;
  }

}

export class UrgentDeliveryManagementTakenByRecipientList {
  data: UrgentDeliveryManagementTakenByRecipient[] = [];
  pagination: PaginationV2 = new PaginationV2();
  constructor(fields? : Partial<UrgentDeliveryManagementTakenByRecipientList>) {
    Object.assign(this, fields);
  }
}

export class UrgentDeliveryManagementHALData {
  pdId = 0;
  pdSTTNo = "";
  pdFlag = "";
  sttDeliveryType = "";
  pdCaseType = "";
  pdReason = "";
  pdDeadlineReturn = "";
  sttLastStatusId = "";
  sttOriginCityId = "";
  sttOriginCityName = "";
  sttDestinationCityId = "";
  sttDestinationCityName = "";
  pdCreatedAt = "";
  pdUpdatedAt = "";
  createdBy = "";

  constructor(fields?: Partial<UrgentDeliveryManagementHALData>) {
    Object.assign(this, fields);
  }
}

export class UrgentDeliveryManagementTakenByRecipient {
  pdId = 0;
  pdSttNo = "";
  sttId = 0;
  sttProductType = "";
  sttIsCod = false;
  sttIsDfod = false;
  sttLastStatusId = "";
  sttDestinationCity = "";
  sttRecipientName = "";
  sttRecipientPhone = "";
  sttDestinationCityName = "";
  sttDestinationDistrictName = "";
  pdCreatedAt = "";
  pdUpdatedAt = "";
  constructor(fields?: Partial<UrgentDeliveryManagementTakenByRecipient>) {
    Object.assign(this, fields);
  }
}

export class UrgentDeliveryManagementHALListRequest {
  sttNo = "";
  sortBy = "";
  page = 1;
  limit = 20;
  isTotalData = true;

  constructor(fields?: Partial<UrgentDeliveryManagementHALListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class UrgentDeliveryManagementHALListRequestDownload {
  sttNo = "";
  sortBy = "";

  constructor(fields?: Partial<UrgentDeliveryManagementHALListRequestDownload>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
