import { configPrice } from "./modules/Price";

const formatPrice = (
  price: number,
  prefixPositive = "",
  currency: "IDR" | "MYR" = "IDR"
) => {
  if (!price) return `${configPrice[currency]?.prefix}0`;

  const result = Math.abs(price).toLocaleString(
    configPrice[currency]?.locales ?? "id"
  );
  return `${price < 0 ? "- " : prefixPositive}${
    configPrice[currency]?.prefix
  }${result}`;
};
export default formatPrice;
