import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import { PaginationV2 } from "@/domain/entities/Pagination";
import {
  NotificationsAll,
  BalanceMinusPenaltyDetail,
  EMBARGO_COD_TYPE_LIST,
  BalancePenaltyNotification
} from "@/domain/entities/BalanceMinusPenalty";
import formatNumber from "@/app/infrastructures/misc/common-library/FormatNumber";
import formatDecimal from "@/app/infrastructures/misc/common-library/FormatDecimal";
import { CONFIRM_LIST_DATA, OptionsClass } from "@/domain/entities/MainApp";

export class BalanceMinusPenaltyMapper {
  public convertListDataFromApi(result: AxiosResponse<any>): ResponsePayloadV2 {
    const { data } = result;

    return new ResponsePayloadV2({
      success: data.success,
      message: data.message,
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.limit,
        totalData: data.meta.total_records
      }),
      data: data.data.length
        ? data.data.map(
            (item: any) =>
              new BalanceMinusPenaltyDetail({
                id: item.id,
                name: item.name,
                description: item.description,
                appliedTo: item.applied_to,
                partnerType: item.partner_type,
                status: item.status,
                statusData: !!item.status.match(/^active$/gi)
              })
          )
        : []
    });
  }

  public convertDetailDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = result;
    const dayOffValue: { [x: string]: string } = {
      true: "yes",
      false: "no"
    };
    const dayOffData = CONFIRM_LIST_DATA.find(
      (item: OptionsClass) => dayOffValue[data.include_day_off] === item.value
    );
    const embargoData = EMBARGO_COD_TYPE_LIST.filter((item: OptionsClass) =>
      data.embargo_cod_type.includes(item.value)
    );

    const [hours, minutes] = data.cut_off_time.split(":");
    const date = new Date();
    date.setHours(+hours, +minutes, 0, 0);

    return new ResponsePayloadV2({
      data: new BalanceMinusPenaltyDetail({
        id: data.id,
        name: data.name,
        description: data.description,
        appliedTo: data.applied_to,
        partnerType: data.partner_type,
        percentagePenalty: formatDecimal(String(data.percentage_penalty)),
        percentageAfterPenalty: formatDecimal(
          String(data.percentage_after_penalty)
        ),
        cutOffTime: date,
        maxMinusBalance: formatNumber(data.max_minus_balance),
        includeDayOff: data.include_day_off,
        includeDayOffData: dayOffData,
        embargoCodType: data.embargo_cod_type,
        embargoCodTypeData: embargoData,
        status: data.status,
        statusData: !!data.status.match(/^active$/gi),
        createdAt: data.created_at,
        createdBy: data.created_name,
        updatedAt: data.updated_at,
        updatedBy: data.updated_name
      })
    });
  }

  public convertGetAllNotificationsDataFromApi(
    result: AxiosResponse<any>
  ): NotificationsAll[] {
    const { data } = result.data;
    return data.length
      ? data.map(
          (item: any) =>
            new NotificationsAll({
              id: item.np_id,
              title: item.np_title,
              status: item.np_type,
              dueDate: item.np_due_date,
              message: item.np_message_first
            })
        )
      : [];
  }

  public convertFormDataFromApi(result: AxiosResponse<any>): ResponsePayloadV2 {
    const { data } = result;

    return new ResponsePayloadV2({
      data
    });
  }

  public convertBalancePenaltyNotificationnDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = result;

    return new ResponsePayloadV2({
      data: new BalancePenaltyNotification({
        npId: data.np_id,
        npTitle: data.np_title,
        npMessageFirst: data.np_message_first,
        npMessageSecond: data.np_message_second,
        npDueDate: data.np_due_date,
        npType: data.np_type
      })
    });
  }
}
