import { FlagsPermissionShopify } from "@/feature-flags/flags-shopify";
import { getTokenMiddleware } from "../Cookies";

const baseUrlMiddleware = () => {
    if (FlagsPermissionShopify.form_enable.isEnabled()) {
        return process.env.VUE_APP_MIDDLEWARE_API_URL
    }

    return getTokenMiddleware() 
        ? process.env.VUE_APP_MIDDLEWARE_API_URL
        : process.env.VUE_APP_MIDDLEWARE_SHIPMENT_API_URL
};
export default baseUrlMiddleware;
