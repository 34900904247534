import { injectable } from "tsyringe";
import { CollectibleCardApiRepository } from "@/app/infrastructures/repositories/api/CollectibleCardApiRepository";
import { CollectibleItemCard, CollectibleItemEntities } from "@/domain/entities/CollectibleCard";

@injectable()
export class CollectibleCardPresenter {
  private repository: CollectibleCardApiRepository;

  constructor(repo: CollectibleCardApiRepository) {
    this.repository = repo;
  }

  public getCollectibleItem(): Promise<CollectibleItemEntities> {
    return this.repository.getCollectibleItem();
  }

  public getDetailCollectibleItem(
    imageId: number
  ): Promise<CollectibleItemCard> {
    return this.repository.getDetailCollectibleItem(imageId);
  }
}
