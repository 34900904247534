import { FlagsPermissionShipmentRates } from "@/feature-flags/flags-permission-check-tariff";

const Layout = () =>
    import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

const CekTarif = () =>
    import(
      /* webpackChunkName: "CekTarif" */ "@/app/ui/views/shipment/rates-check/index.vue"
    );

export default [
    {
        path: "/shipment-rates",
        component: Layout,
        name: "shipment-rates",
        meta: {
            header: true,
            icon: "rates-check",
            title: "Tariff - Shipment Rates",
            name: "Cek Tarif",
            isFreeze: true,
            permission: FlagsPermissionShipmentRates.permission_shipment_rates_my_enable
        },
        children: [
            {
                path: "/shipment-rates",
                name: "Cek Tarif",
                component: CekTarif,
                meta: {
                    header: true
                }
            }
        ]
    }
];
