import { Pagination } from "./Pagination";

export class BaggingGroupingData {
  id = 0;
  destinationId = "";
  destinationName = "";
  regionId = "";
  regionName = "";
  status = false;

  constructor(fields?: Partial<BaggingGroupingData>) {
    Object.assign(this, fields);
  }
}

export class BaggingGroupingListEntities {
  pagination = new Pagination(1, 20);
  data = [new BaggingGroupingData()];

  constructor(fields?: Partial<BaggingGroupingListEntities>) {
    Object.assign(this, fields);
  }
}
