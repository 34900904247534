import { MessagingServiceConfigurationMapper } from "@/data/persistences/mappers/MessagingServiceConfigurationMapper";
import { MessagingServiceConfigurationRepositoryInterface } from "@/data/persistences/repositories/contracts/MessagingServiceConfigurationRepositoryInterface";
import {
  MessageDictionary,
  MessagingServiceConfigurationList,
  MessagingServiceConfigurationListData,
  TemplateWhatsappPremium
} from "@/domain/entities/MessagingServiceConfiguration";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import {
  AddMessagingServiceConfigurationRequest,
  MessagingServiceConfigurationRequest
} from "@/data/payload/contracts/MessagingServiceConfigurationRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  AddMessagingServiceConfigurationApiRequest,
  EditMessagingServiceConfigurationApiRequest,
  RequestMessagingServiceConfigurationList
} from "@/data/payload/api/MessagingServiceConfigurationApiRequest";

export class MessagingServiceConfigurationApiRepository
  implements MessagingServiceConfigurationRepositoryInterface {
  private service: ApiService;
  private mapper: MessagingServiceConfigurationMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: MessagingServiceConfigurationMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getMessagingServiceConfigurationList(
    params: RequestMessagingServiceConfigurationList
  ): Promise<MessagingServiceConfigurationList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getMessagingServiceConfigurationList(params),
      {}
    );
    return this.mapper.convertMessagingServiceConfigurationListFromApi(resp);
  }

  public async getMessagingServiceConfigurationDetail(
    id: number
  ): Promise<MessagingServiceConfigurationListData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getMessagingServiceConfigurationDetail(id),
      {}
    );
    return this.mapper.convertMessagingServiceConfigurationDetailFromApi(resp);
  }

  public async createMessagingConfig(
    payload: AddMessagingServiceConfigurationRequest
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.createMessagingConfig(),
      undefined,
      payload as AddMessagingServiceConfigurationApiRequest
    );

    return this.mapper.convertPayloadData(resp);
  }

  public async getMessageDictionary(): Promise<MessageDictionary> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getMessagingDictionary(),
      {}
    );
    return this.mapper.convertMessageDictionaryDataFromApi(resp);
  }

  public async patchMessagingConfig(
    payload: MessagingServiceConfigurationRequest
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "patch",
      this.endpoints.createMessagingConfig(),
      undefined,
      payload as EditMessagingServiceConfigurationApiRequest
    );

    return this.mapper.convertPayloadData(resp);
  }

  public async getTemplateWhatsappPremium(
    params: any
  ): Promise<TemplateWhatsappPremium[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.templateWhatsappPremiumApi(params),
      {}
    );
    return this.mapper.convertTemplateWhatsappPremiumDataFromApi(resp);
  }
}
