import {
  formatDate,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import { QueryParamsEntities } from "./MainApp";
import { PaginationV2 } from "./Pagination";

export class RequestUrgentDeliveryListApi {
  page = 1;
  limit = 20;
  sttNo = "";
  startDate = "";
  endDate = "";
  requestType = "";
  lastStatus = "";
  orderBy = "";
  sortBy = "";

  constructor(fields?: Partial<RequestUrgentDeliveryListApi>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class RequestUrgentDeliveryListApiDownload {
  sttNo = "";
  startDate = "";
  endDate = "";
  requestType = "";
  lastStatus = "";
  orderBy = "";
  sortBy = "";

  constructor(fields?: Partial<RequestUrgentDeliveryListApiDownload>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class UrgentDeliveryData {
  rpdId = 0;
  rpdPriorityDeliveryId = 0;
  rpdSttNo = "";
  rpdRequestType = "";
  rpdReason = "";
  rpdDeadlineReturn = "";
  sttLastStatusId = "";
  sttDestinationCity = "";
  rpdCreatedAt = "";
  rpdCreatedBy = "";
  rpdUpdatedAt = "";
  rpdUpdatedBy = "";
  
  constructor(fields?: Partial<UrgentDeliveryData>) {
    Object.assign(this, fields);
  }

  get formatDeadline() {
    return this.rpdDeadlineReturn === "-" ? "-" : formatDateWithoutTime(this.rpdDeadlineReturn);
  }

  get formatCreatedAt() {
    return formatDate(this.rpdCreatedAt);
  }

  get formatUpdatedAt() {
    return formatDate(this.rpdUpdatedAt);
  }
}

export class UrgentDeliveryDetailData {
  rpdId = 0;
  rpdPriorityDeliveryId = 0;
  rpdSttNo = "";
  rpdRequestType = "";
  rpdReason = "";
  rpdDeadlineReturn = "";
  sttLastStatusId = "";
  sttDestinationCity = "";
  rpdNotes = "";
  rpdProofPhoto = [];
  rpdCreatedAt = "";
  rpdCreatedBy = "";
  rpdUpdatedAt = "";
  rpdUpdatedBy = "";
  
  constructor(fields?: Partial<UrgentDeliveryDetailData>) {
    Object.assign(this, fields);
  }

  get formatDeadline() {
    return this.rpdDeadlineReturn === "-" ? "-" : formatDateWithoutTime(this.rpdDeadlineReturn);
  }

  get formatCreatedAt() {
    return formatDate(this.rpdCreatedAt);
  }

  get formatUpdatedAt() {
    return formatDate(this.rpdUpdatedAt);
  }
}

export class UrgentDeliverySTTStatusData {
  name = "";
  
  constructor(fields?: Partial<UrgentDeliverySTTStatusData>) {
    Object.assign(this, fields);
  }
}

export class UrgentDeliveryListEntities {
  pagination = new PaginationV2();
  data = [] as UrgentDeliveryData[];

  constructor(fields?: Partial<UrgentDeliveryListEntities>) {
    Object.assign(this, fields);
  }
}

export class UrgentDeliveryApiList {
  request = new RequestUrgentDeliveryListApi();
  response = new UrgentDeliveryListEntities();

  constructor(fields?: Partial<UrgentDeliveryApiList>) {
    Object.assign(this, fields);
  }
}

export class UrgentDeliverySTTStatusListEntities {
  data = [] as UrgentDeliverySTTStatusData[];

  constructor(fields?: Partial<UrgentDeliverySTTStatusListEntities>) {
    Object.assign(this, fields);
  }
}

export class UrgentDeliveryDetail {
  rpdId = 0;
  rpdPriorityDelivery_id = 0 ;
  rpdSttNo = "" ;
  rpdRequestType = "";
  rpdReason = "";
  rpdDeadlineReturn = "";
  sttLastStatusId = "";
  sttDestinationCity = "";
  rpdNotes = "";
  rpdProofPhoto = [];
  rpd_created_at = "";
  rpd_updated_at = "";

  constructor(fields?: Partial<UrgentDeliveryDetail>) {
    Object.assign(this, fields);
  }

}
