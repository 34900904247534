import { ALLOWED } from "@/app/infrastructures/misc/RolePermission";

const LoginPage = () =>
  import(/* webpackChunkName: "Login" */ "@/app/ui/views/login/index.vue");
const ResetPassword = () =>
  import(
    /* webpackChunkName: "ResetPassword" */ "@/app/ui/views/reset-password/index.vue"
  );
const ForgotPassword = () =>
  import(
    /* webpackChunkName: "ForgotPassword" */ "@/app/ui/views/forgot-password/index.vue"
  );
const ForgotPasswordExpired = () =>
  import(
    /* webpackChunkName: "ForgotPassword" */ "@/app/ui/views/forgot-password/component/expired.vue"
  );
const VerificationAccount = () =>
  import(
    /* webpackChunkName: "ResetPassword" */ "@/app/ui/views/force-change-password/index.vue"
  );

export default [
  {
    path: "/",
    name: "login",
    component: LoginPage,
    meta: {
      hidden: true,
      noAuth: true,
      permission: ALLOWED
    }
  },
  {
    path: "/account/forgot-password",
    name: "Forgot Password",
    component: ForgotPassword,
    meta: {
      title: "Forgot Password",
      hidden: true,
      noAuth: true,
      permission: ALLOWED
    }
  },
  {
    path: "/account/forgot-password/expired",
    name: "Verification Expired",
    component: ForgotPasswordExpired,
    meta: {
      title: "Verification Expired",
      hidden: true,
      noAuth: true,
      permission: ALLOWED
    }
  },
  {
    path: "/account/reset-password",
    name: "Reset Password",
    component: ResetPassword,
    meta: {
      title: "Reset Password",
      hidden: true,
      noAuth: true,
      permission: ALLOWED
    }
  },
  {
    path: "/account/verification-account",
    name: "Verification Account",
    component: VerificationAccount,
    meta: {
      title: "Verifikasi Akun",
      hidden: true,
      noAuth: true,
      permission: ALLOWED
    }
  }
];
