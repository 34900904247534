import {
  VuexModule,
  Module,
  getModule,
  Mutation,
  Action
} from "vuex-module-decorators";
import store from "@/store";
import { PAYMENT_STATE } from "@/app/ui/components/dialog-payment/payment-props";
import { container } from "tsyringe";
import { PaymentPresenter } from "../presenters/PaymentPresenter";
import {
  RequestListPaymentMethod,
  ValidatePayment
} from "@/data/payload/api/PaymentApiRequest";
import {
  CheckInquiry,
  PaymentMethod,
  PaymentResponses
} from "@/domain/entities/Payment";
import { formatPrice } from "@/app/infrastructures/misc/Utils";

@Module({ namespaced: true, dynamic: true, store, name: "payment" })
class PaymentStore extends VuexModule {
  public dataPayment = new PaymentResponses();
  @Mutation
  public setDataPayment(value: PaymentResponses) {
    this.dataPayment = value;
  }

  dialogPayment = false;
  @Mutation
  toggleDialogPayment(boolean: boolean) {
    this.dialogPayment = boolean;
  }

  paymentMethod = new PaymentMethod();
  @Mutation
  changePaymentMethod(payment: PaymentMethod) {
    this.paymentMethod = payment;
  }

  paymentErrorCause = "";
  @Mutation
  setPaymentErrorCause(value: string) {
    this.paymentErrorCause = value;
  }
  @Action
  generateQr(params: {
    sttNo: string;
    paymentMethod: string;
    billType: string;
  }) {
    this.setBillAmount(0);
    this.setBillAmountAfterDiscount(0);
    this.setPaymentErrorCause("");
    this.changeState(PAYMENT_STATE.LOADING_STATE);
    const presenter = container.resolve(PaymentPresenter);
    const payload = new ValidatePayment(
      params.sttNo,
      params.paymentMethod,
      params.billType
    );
    return presenter
      .validatePayment(payload)
      .then((res: PaymentResponses) => {
        this.changeState(PAYMENT_STATE.ACTIVE_STATE);
        this.setBillAmount(res.amount);
        this.setBillAmountAfterDiscount(res.amountAfterDiscount);
        this.setDataPayment(res);
      })
      .catch(err => {
        if (err.response) {
          if (
            err.response.data.message.id.includes(
              "Amount tidak boleh lebih dari"
            )
          ) {
            this.setPaymentErrorCause("errorAmount");
          }
        }
        this.changeState(PAYMENT_STATE.RELOAD_STATE);
      });
  }

  @Action
  checkInquiry(id: string) {
    const presenter = container.resolve(PaymentPresenter);
    return presenter.checkInquiry(id).then((res: CheckInquiry) => {
      if (res.transactionStatus === "success") {
        this.changeState(PAYMENT_STATE.SUCCESS_STATE);
      }
    });
  }

  paymentState = "";
  @Mutation
  changeState(state: string) {
    this.paymentState = state;
  }

  sttNumber = "";
  @Mutation
  setSttNumber(sttNumber: string) {
    this.sttNumber = sttNumber;
  }

  billAmount = 0;
  @Mutation
  setBillAmount(billAmount: number) {
    this.billAmount = billAmount;
  }
  get formattedBillAmount() {
    if (this.billAmount === 0) {
      return "";
    }
    return formatPrice(this.billAmount);
  }

  billAmountAfterDiscount = 0;
  @Mutation
  setBillAmountAfterDiscount(billAmount: number) {
    this.billAmountAfterDiscount = billAmount;
  }
  get formattedBillAmountAfterDiscount() {
    if (this.billAmountAfterDiscount === 0) {
      return "";
    }
    return formatPrice(this.billAmountAfterDiscount);
  }

  public paymentMethods: PaymentMethod[] = [];
  public isLoadPaymentMethods = false;
  @Mutation
  setPaymentMethods(data: PaymentMethod[]) {
    this.paymentMethods = data;
  }
  @Mutation
  setLoadPaymentMethods(data: boolean) {
    this.isLoadPaymentMethods = data;
  }
  @Action
  getPaymentMethodsAvailable() {
    this.setLoadPaymentMethods(true);
    const presenter = container.resolve(PaymentPresenter);
    presenter
      .getListPaymentMethod(new RequestListPaymentMethod({cache: true}))
      .then((resp: PaymentMethod[]) => {
        this.setPaymentMethods(resp);
      })
      .catch(() => {
        this.setPaymentMethods([]);
      })
      .finally(() => {
        this.setLoadPaymentMethods(false);
      });
  }
}

export const PaymentController = getModule(PaymentStore);
