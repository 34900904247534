import {
  AnnouncementData,
  AnnouncementEntities,
  TargetDetail
} from "@/domain/entities/Announcement";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import parsingLinkGcpToAws from "@/app/infrastructures/misc/common-library/ParsingLinkGcpToAws";

export class AnnouncementMapper {
  public convertAnnouncementFromApi(
    result: AxiosResponse<any>
  ): AnnouncementEntities {
    const { data } = result;
    return new AnnouncementEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      data.data.length > 0
        ? data.data.map((item: any) => {
            return new AnnouncementData(
              item.id,
              item.name,
              item.description,
              item.status,
              parsingLinkGcpToAws(item.attachment),
              parsingLinkGcpToAws(item.banner_image),
              item.created_name,
              item.created_by,
              item.announcement_target.length > 0
                ? item.announcement_target.map((e: any) => {
                    return new TargetDetail(
                      e.user_target,
                      e.id,
                      e.created_at,
                      e.updated_at,
                      e.deleted_at,
                      e.announcement_id
                    );
                  })
                : [],
              item.released_at,
              item.pinned,
              item.created_at,
              item.updated_at,
              item.updated_by
            );
          })
        : []
    );
  }
  public convertAnnouncementDetailFromApi(
    result: AxiosResponse<any>
  ): AnnouncementData {
    const {
      data: { data }
    } = result;
    return new AnnouncementData(
      data.id,
      data.name,
      data.description,
      data.status,
      parsingLinkGcpToAws(data.attachment),
      parsingLinkGcpToAws(data.banner_image),
      data.created_by,
      data.created_by,
      data.announcement_target !== null
        ? data.announcement_target.map((e: any) => {
            return new TargetDetail(e.user_target);
          })
        : [],
      data.released_at,
      data.pinned,
      data.created_at,
      data.update_at,
      data.updated_by
    );
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
