import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { CashbackConfigurationMapper } from "@/data/persistences/mappers/CashbackConfigMapper";
import { CashbackConfigRepositoryInterface } from "@/data/persistences/repositories/contracts/CashbackConfigurationRepositoryInterface";
import { CashbackConfigEntities, CashbackListRequest } from "@/domain/entities/CashbackConfiguration";
import { ResponsePayload, ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { CashbackConfigRequest } from "@/data/payload/api/CashbackApiRequest";

export class CashbackConfigurationApiRepository
  implements CashbackConfigRepositoryInterface {
  private service: ApiService;
  private mapper: CashbackConfigurationMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: CashbackConfigurationMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  public async getActiveCashback(id: any): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke("GET", this.endpoints.getActiveCashback(id));
    return this.mapper.convertDetailDataFromApi(resp);
  }
;
  public async getCashbackConfigList(
    params: CashbackListRequest
  ): Promise<CashbackConfigEntities> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.cashbackConfigList(params)
    );

    return this.mapper.convertListDataFromApi(res);
  }

  public async createCashbackConfig(
    payload: CashbackConfigRequest
  ): Promise<ResponsePayload> {
    const res = await this.service.invoke(
      "POST",
      this.endpoints.cashbackConfigCreate(), {}, payload
    );

    return this.mapper.convertSubmitConfigFromApi(res);
  }

  public async editCashbackConfig(
    payload: CashbackConfigRequest, id: any
  ): Promise<ResponsePayload> {
    const res = await this.service.invoke(
      "PATCH",
      this.endpoints.cashbackConfig(id), {}, payload
    );

    return this.mapper.convertSubmitConfigFromApi(res);
  }

  public async getDetailCashbackConfiguration(id: any): Promise<ResponsePayloadV2>{
    const res = await this.service.invoke(
      "GET",
      this.endpoints.cashbackConfig(id)
    );

    return this.mapper.convertDetailDataFromApi(res);
  }
}
