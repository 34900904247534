import { QueryParamsEntities } from "./MainApp";
import { PaginationV2 } from "./Pagination";
import formatDateNormal from "@/app/infrastructures/misc/common-library/FormatDateNormal";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";

export class Customer {
    customerId = 0;
    customerName = "";
    customerPhoneNumber = "";
    customerAddress = "";
    customerAccountType = "";
    customerRefId = 0;
    customerDistrictId = 0;
    customerDistrictName = "";
    customerZipCode = "";
    customerAddressType = "";
    customerCreatedBy = "";
    customerUpdatedBy = "";
    customerCreatedAt = "";
    customerUpdatedAt = "";
    customerEmail = "";
    constructor(fields?: Partial<Customer>) {
      Object.assign(this, fields);
    }

    get formatCreatedAt() {
      return formatDate(this.customerCreatedAt);
    }

    get formatUpdatedAt() {
      return this.customerUpdatedAt === "0001-01-01T00:00:00Z" || this.customerUpdatedAt === null
        ? formatDate(this.customerCreatedAt)
        : formatDate(this.customerUpdatedAt);
    }

    get updatedByValue() {
      return this.customerUpdatedBy == "0" ? this.customerCreatedBy : this.customerUpdatedBy
    }
}

export class CustomerListEntities {
  pagination = new PaginationV2();
  data: Customer[] = [new Customer()];
  constructor(fields?: Partial<CustomerListEntities>) {
    Object.assign(this, fields);
  }
}

export class RequestCustomerList {
  districtId = "";
  zipCode = "";
  status = "";
  search = "";
  sortBy = "";
  orderBy = "DESC";
  page = 1;
  limit = 20;
  isTotalData = true;
  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
  constructor(fields?: Partial<RequestCustomerList>) {
    Object.assign(this, fields);
  }
}