import {
  ClientPartnerOptions,
  DestinationCityOptions,
  SttNumbersOptions,
  CnManifestEntities,
  CnManifestData
} from "@/domain/entities/CnManifest";
import { AxiosResponse } from "axios";
import { Pagination } from "@/domain/entities/Pagination";

export class CnManifestMapper {
  public convertClientPartnerOptionsFromApi(
    result: AxiosResponse,
    type: string
  ): ClientPartnerOptions[] {
    try {
      const {
        data: { data }
      } = result;
      if (type === "console" || type === "sub-console" || type === "pos") {
        return data
          .filter((item: any) => {
            return item.type === type && item.name !== "";
          })
          .map(
            (item: any) =>
              new ClientPartnerOptions({
                id: item.id,
                name: item.name,
                code: item.code,
                type: item.type
              })
          );
      }
      if (type === "client") {
        return data
          .filter((item: any) => {
            return item.client_name !== "";
          })
          .map(
            (item: any) =>
              new ClientPartnerOptions({
                id: item.client_id,
                name: item.client_name,
                code: item.client_code,
                type: type
              })
          );
      }
      if (type === "customer") {
        return data
          .filter((item: any) => {
            return item.customer_name !== "";
          })
          .map(
            (item: any) =>
              new ClientPartnerOptions({
                id: item.customer_id,
                name: item.customer_name,
                code: item.customer_phone_number,
                type: type
              })
          );
      }
      return [];
    } catch (e) {
      return [];
    }
  }

  public convertDestinationCityOptionsFromApi(
    result: AxiosResponse
  ): DestinationCityOptions[] {
    try {
      const {
        data: { data }
      } = result;
      return data.map(
        (item: any) =>
          new DestinationCityOptions(
            item.id,
            `${item.code} - ${item.name}`,
            item.code
          )
      );
    } catch (e) {
      return [];
    }
  }

  public convertSttNumbersFromApi(result: AxiosResponse): SttNumbersOptions[] {
    try {
      const {
        data: { data }
      } = result;
      return data.map(
        (item: any) => new SttNumbersOptions(item.stt_id, item.stt_no)
      );
    } catch (e) {
      return [];
    }
  }

  public convertCnManifestFromApi(result: AxiosResponse): CnManifestEntities {
    try {
      const { data } = result;
      return new CnManifestEntities(
        new Pagination(data.meta.page, data.meta.limit),
        data.data.map((item: any) => {
          return new CnManifestData({
            sttId: item.stt_id,
            sttNo: item.stt_no,
            sttTotalPiece: item.stt_total_piece,
            sttDestinationCity: item.stt_destination_city,
            sttChargeAbleWeight: item.stt_chargeable_weight,
            sttProductType: item.stt_product_type,
            sttVolumeWeight: item.stt_volume_weight,
            sttRecipientName: item.stt_recipient_name,
            sttRecipientAddress: item.stt_recipient_address,
            sttRecipientPhone: item.stt_recipient_phone,
            sttCommodityName: item.stt_commodity_type,
            sttGoodsEstimatePrice: item.stt_goods_estimate_price,
            sttHsCode: item.stt_hs_code,
            sttTaxNumber: item.stt_tax_number,
            sttTaxStamp: item.stt_bm_tax_rate,
            sttValueAddedTax: item.stt_ppn_tax_rate,
            sttIncomeTax: item.stt_pph_tax_rate,
            sttTotalGoodTax: item.stt_total_good_tax,
            sttTaxImposition: "No",
            sttLastStatus: item.stt_last_status_id,
            sttLastStatusDescription: item.stt_last_status_id_description,
            sttBookedBy: item.stt_booked_name,
            sttCreatedAt: item.stt_created_at,
            sttUpdatedAt: item.stt_updated_at,
            sttTotalAmount: item.stt_total_amount,
            sttBookedByCountry: item.stt_booked_by_country || "ID"

          });
        })
      );
    } catch (e) {
      return new CnManifestEntities(new Pagination(1, 10), []);
    }
  }

  public convertExportSttFromApi(result: AxiosResponse): string {
    try {
      const { data } = result;
      return data;
    } catch (e) {
      return "";
    }
  }
}
