/* eslint-disable */
import { createStore } from "vuex-extensions";
import { Store } from "vuex";
import { config } from "vuex-module-decorators";

config.rawError = true;

export interface RootState {
}

export default createStore<RootState>(Store, {});
