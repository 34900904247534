import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";

export class ConfigurableCod {
  ccodId = 0;
  ccodCpid = 0;
  ccodName = "";
  ccodTypeStt = "";
  ccodTypeCalculation = "";
  ccodCodType = "";
  ccodReferences = "";
  ccodFee: string | number = "";
  ccodCreatedAt = "";
  ccodCreatedBy = "";
  ccodUpdatedAt = "";
  ccodUpdatedBy = "";
  isError = false;

  constructor(fields?: Partial<ConfigurableCod>) {
    Object.assign(this, fields);
  }
}

export class ConfigPriceCodDetail {
  configurablePriceId = 0;
  configurablePriceName = "";
  configurablePriceType = "";
  configurablePriceDescription = "";
  isConfigurablePriceStatus = false;
  configurablePriceStatus = "";
  configurablePriceCreatedAt = "";
  configurablePriceCreatedBy = "";
  configurablePriceUpdatedAt = "";
  configurablePriceUpdatedBy = "";
  configurableCod: ConfigurableCod[] = [];

  constructor(fields?: Partial<ConfigPriceCodDetail>) {
    Object.assign(this, fields);
  }

  formatDate?(date: string): string {
    return date ? formatDate(date) : "";
  }
}
