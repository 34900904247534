/* eslint-disable @typescript-eslint/camelcase */
import { TariffDataMapper } from "@/data/persistences/mappers/TariffMapper";
import { TariffRepositoryInterface } from "@/data/persistences/repositories/contracts/TariffRepositoryInterface";
import { TariffEntities } from "@/domain/entities/Tariff";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { downloadFile } from "../../misc/Utils";
import { CheckTariffRequest } from "@/data/payload/api/TariffApiRequest";

export class TariffApiRepository implements TariffRepositoryInterface {
  private service: ApiService;
  private mapper: TariffDataMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: TariffDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getList(params: CheckTariffRequest, cache: boolean): Promise<TariffEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListTariff(params, cache),
      {}
    );
    return this.mapper.convertTariffFromApi(resp);
  }

  public async downloadTariff(
    commodity_id: string | number,
    product_type: string
  ): Promise<any> {
    return downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.downloadTariff(
        commodity_id,
        product_type
      )}`,
      "Download Tariff",
      ".xlsx",
      true
    );
  }
}
