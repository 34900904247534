import { HubComponent } from "@/app/infrastructures/dependencies/modules/HubComponent";
import { HubList, RequestHubList } from "@/domain/entities/Hub";
import store from "@/store";
import { container } from "tsyringe";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { HubPresenter } from "../presenters/HubPresenter";
import { AuthController } from "./AuthController";
HubComponent.init();

export interface HubState {
    hubList: HubList,
    isLoading: boolean,
    isError: boolean,
}
@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: "hub"
})
class HubStore extends VuexModule implements HubState {
    public isLoading = false;
    public isError = false;
    public hubList = new HubList();
    public search = "";
    public filterHub = {
        status: "",
        consolidator:{
            name: "",
            id: 0
        },
        originCity: {
          name: "",
          code: ""
        }
      };

    @Action
    public getHubList(params: RequestHubList) {
        this.setIsLoading(true);
        const presenter = container.resolve(HubPresenter);
        return presenter.getHubList(params)
            .then((res: HubList) => {
                this.setError(false);
                this.setHubList(res);
            }).catch(() => {
                this.setError(true);
                AuthController.logout();
            })
            .finally(() => this.setIsLoading(false));
    }
    @Action
    public async initDataTable() {
        this.setFirstPage();
        this.searchAction("");
        this.resetFilter();
        this.getHubList(
            new RequestHubList({
                page: this.hubList.pagination.page,
                limit: this.hubList.pagination.limit,
                isTotalData: true,
                city: "",
                partnerId: 0,
                status: "",
                search: "",
                sortBy: "updated_at"
            })
        )
    }

    @Action
    public async fetchDataTable() {
        this.getHubList(
            new RequestHubList({
                page: this.hubList.pagination.page,
                limit: this.hubList.pagination.limit,
                isTotalData: true,
                city: this.filterHub.originCity.code,
                partnerId: this.filterHub.consolidator.id,
                status: this.filterHub.status,
                search: this.search,
                sortBy: "updated_at"
            })
        )
    }

    @Action
    public async searchAction(value: string) {
        this.setSearch(value);
        await this.fetchDataTable();
    }

    @Action
    public async clear() {
        await this.searchAction("");
    }

    @Mutation
    public resetFilter() {
        this.filterHub = {
            status: "",
            consolidator:{
                name: "",
                id: 0
            },
            originCity: {
              name: "",
              code: ""
            }
          };
    }

    @Mutation
    public setHubList(param: HubList) {
        this.hubList = param;
    }

    @Mutation
    public setError(param: boolean) {
        this.isError = param;
    }

    @Mutation
    public setIsLoading(param: boolean) {
        this.isLoading = param;
    }
    @Mutation
    private setSearch(value: string) {
        this.search = value;
    }
    @Mutation
    public setFilterHub(value: any) {
        this.filterHub = value;
    }

    @Action
    public setFirstPage() {
        this.hubList.pagination.page = 1;
    }
}

export const HubController = getModule(HubStore);