import { StiSCApiRepository } from "@/app/infrastructures/repositories/api/StiSCApiRepository";
import { injectable } from "tsyringe";
import {
  StiSCEntities,
  SttPiecesStiScDetail,
  ValidationScanSTTData,
  StiScManifestData,
  RequestListSTISCSession,
  ResponseSTISCSession,
  StiScProcessData,
  ResponseSTISCCreateSession,
  RequestListSTISC,
  ResponseFinishSessionSTISC
} from "@/domain/entities/StiSc";
import { ChangeStiScRequestInterface } from "@/data/payload/contracts/StiScRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

@injectable()
export class StiSCPresenter {
  private repository: StiSCApiRepository;

  constructor(repository: StiSCApiRepository) {
    this.repository = repository;
  }

  public getListStiSC(params : RequestListSTISC): Promise<StiSCEntities> {
    return this.repository.getListStiSC(params);
  }

  public getDetailSttPieces(sttNumber: string): Promise<SttPiecesStiScDetail> {
    return this.repository.getDetailSttPieces(sttNumber);
  }

  public async generateStiSc(
    payload: ChangeStiScRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.generateStiSC(payload);
  }

  public async validationScanSTTStiSc(
    sttPiece: string
  ): Promise<ValidationScanSTTData> {
    return this.repository.validationScanSTTStiSc(sttPiece);
  }

  public async getStiSCManifestDetail(id: number): Promise<StiScManifestData> {
    return this.repository.getStiSCManifestDetail(id);
  }

  public async getStiSCSession(param: RequestListSTISCSession): Promise<ResponseSTISCSession> {
    return this.repository.getStiSCSession(param);
  }

  public async processUpdateSTISC(payload: ChangeStiScRequestInterface): Promise<StiScProcessData> {
    return this.repository.processUpdateStiSC(payload);
  }
  public async createStiSCSession(): Promise<ResponseSTISCCreateSession> {
    return this.repository.createStiScSession();
  }
  public async finishStiSCSession(payload: ChangeStiScRequestInterface): Promise<ResponseFinishSessionSTISC> {
    return this.repository.finishStiSCSession(payload);
  }
}
