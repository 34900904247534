/* eslint-disable @typescript-eslint/camelcase */
import { Pagination } from "@/domain/entities/Pagination";

export class UserEntities {
  pagination: Pagination;
  data: UserData[];
  constructor(pagination: Pagination, data: UserData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class QuotaTicketAccount {
  dbtaId = 0;
  dbtaBucketTicket: string | number = "";
  constructor(fields?: Partial<QuotaTicketAccount>) {
    Object.assign(this, fields);
  }
}

export class UserData {
  [x: string]: any;
  account_id = 0;
  username = "";
  account_name = "";
  account_email = "";
  password = "";
  account_phone_number = "";
  status = "";
  created_at = "";
  created_by = 0;
  updated_at = "";
  updated_by = 0;
  first_login = "";
  account_type = "";
  role_id = 0;
  account_role?: any;
  account_partner?: any;
  account_client?: any;
  account_vendor?: any;
  dexBucketTicketAccount = new QuotaTicketAccount();
  constructor(fields?: Partial<UserData>) {
    Object.assign(this, fields);
  }
}

export class UserDetailData {
  id: number;
  username: string;
  name: string;
  email: string;
  phone_number: string;
  status: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
  account_type: string;
  account_hub_code: string;
  role_name: string;
  partner?: any;
  client?: any;
  vendor?: any;
  constructor(
    id: number,
    username: string,
    name: string,
    email: string,
    phone_number: string,
    status: string,
    created_at: string,
    created_by: string,
    updated_at: string,
    updated_by: string,
    account_type: string,
    account_hub_code: string,
    role_name: string,
    partner?: any,
    client?: any,
    vendor?: any
  ) {
    this.id = id;
    this.username = username;
    this.name = name;
    this.email = email;
    this.phone_number = phone_number;
    this.status = status;
    this.created_at = created_at;
    this.created_by = created_by;
    this.updated_at = updated_at;
    this.updated_by = updated_by;
    this.account_type = account_type;
    this.account_hub_code = account_hub_code;
    this.role_name = role_name;
    this.partner = partner;
    this.client = client;
    this.vendor = vendor;
  }
}

export class ReferenceEntities {
  id: string;
  names?: any;
  companyName?: any;
  status?: string;
  constructor(id: string, names?: any, companyName?: any, status?: string) {
    this.id = id;
    this.names = names;
    this.companyName = companyName;
    this.status = status;
  }
}

export class RoleEntities {
  id: number;
  name: string;
  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}
