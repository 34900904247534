import formatInputMoney from "@/app/infrastructures/misc/common-library/FormatInputMoney";

const formatInputNumberOnly = (val: any, ableInputZero = false) => {
  if (/^0/.test(val)) {
    if (ableInputZero) {
      return "0";
    }
    return String(val).replace(/^0/, "");
  } else {
    return formatInputMoney(val.replace(/\D+/g, ""));
  }
};

export default formatInputNumberOnly;
