import { QueryParamsEntities } from "./MainApp";
import { PaginationV2 } from "./Pagination";
import formatDateNormal from "@/app/infrastructures/misc/common-library/FormatDateNormal";


export class Country {
  id = 0;
  code = "";
  name = "";
  status = "";
  currency = "";
  createdBy = 0;
  updatedBy = 0;
  createdAt = "";
  updatedAt = "";
  constructor(
      fields?: Partial<Country>
  ) {
    Object.assign(this, fields);
  }

  get formatCreatedAt() {
    return formatDateNormal(this.createdAt, "DD MMMM YYYY HH:mm");
  }

  get formatUpdatedAt() {
    return formatDateNormal(this.updatedAt, "DD MMMM YYYY HH:mm");
  }
}

export class InternationalDocument {
  idcId = "";
  idcCountryId = "";
  idcIsOtherCommodity = false;
  idcIsReceiverEmail = false;
  idcIsBeforeAndAfterPackingImage = false;
  idcIsKtpImage = false;
  idcIsNpwpImage = false;
  idcStatus = "";
  country: Country = new Country();
  constructor(
      fields?: Partial<InternationalDocument>
  ) {
    Object.assign(this, fields);
  }
}

export class InternationalDocumentListEntities {
  pagination = new PaginationV2();
  data: InternationalDocument[] = [new InternationalDocument()];
  constructor(fields?: Partial<InternationalDocumentListEntities>) {
    Object.assign(this, fields);
  }
}

export class RequestInternationalDocumentList {
  countryCode = "";
  status = "";
  search = "";
  page = 1;
  limit = 20;
  isTotalData = false;
  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
  constructor(fields?: Partial<RequestInternationalDocumentList>) {
    Object.assign(this, fields);
  }
}

export class RequestInternationalDocumentDetail {
  commodity = false;
  email = false;
  afterPacking = false;
  ktp = false;
  npwp = false;

  constructor(fields?: Partial<RequestInternationalDocumentDetail>) {
    Object.assign(this, fields);
  }
}