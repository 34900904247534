import { ALLOWED } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const Account = () =>
  import(/* webpackChunkName: "Account" */ "@/app/ui/views/account/index.vue");

export default [
  {
    path: "/account",
    component: Layout,
    redirect: "/account",
    name: "account",
    meta: {
      icon: "route",
      children: false,
      hidden: true,
      title: "Akun",
      name: "Akun",
      permission: ALLOWED
    },
    children: [
      {
        path: "/account",
        name: "Akun",
        component: Account,
        meta: {
          header: true
        }
      }
    ]
  }
];
