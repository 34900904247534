import { injectable } from "tsyringe";
import { CashbackConfigurationApiRepository } from "@/app/infrastructures/repositories/api/CashbackConfigurationApiRepository";
import { CashbackConfigEntities, CashbackListRequest } from "@/domain/entities/CashbackConfiguration";
import { CashbackConfigRequest } from "@/data/payload/api/CashbackApiRequest";
import { ResponsePayload, ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";

@injectable()
export class CashbackConfigurationPresenter {
  private repository: CashbackConfigurationApiRepository;

  constructor(repository: CashbackConfigurationApiRepository) {
    this.repository = repository;
  }

  public getList(params: CashbackListRequest): Promise<CashbackConfigEntities> {
    return this.repository.getCashbackConfigList(params);
  }
  public getActiveCashback(id: any): Promise<ResponsePayloadV2> {
    return this.repository.getActiveCashback(id);
  }
  public createConfig(payload: CashbackConfigRequest): Promise<ResponsePayload> {
    return this.repository.createCashbackConfig(payload);
  }
  public editConfig(payload: CashbackConfigRequest, id: any): Promise<ResponsePayload> {
    return this.repository.editCashbackConfig(payload, id);
  }
  public getDetail(id: any): Promise<ResponsePayloadV2> {
    return this.repository.getDetailCashbackConfiguration(id);
  }
}
