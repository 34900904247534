import { OptionsClass } from "./MainApp";

export class BalanceMinusPenaltyDetail {
  id = 0;
  name = "";
  description = "";
  appliedTo = "";
  partnerType = "";
  status = "";
  statusData = false;
  percentagePenalty = "";
  percentageAfterPenalty = "";
  cutOffTime = new Date();
  maxMinusBalance: string | number = "";
  includeDayOff = false;
  includeDayOffData = new OptionsClass();
  embargoCodType = "";
  embargoCodTypeData = [] as OptionsClass[];
  createdAt = "";
  createdBy = "";
  updatedAt = "";
  updatedBy = "";
  constructor(fields?: Partial<BalanceMinusPenaltyDetail>) {
    Object.assign(this, fields);
  }
}

export class BalanceMinusPenaltyDetailError {
  [x: string]: boolean;
  description = false;
  status = false;
  statusData = false;
  percentagePenalty = false;
  percentageAfterPenalty = false;
  cutOffTime = false;
  maxMinusBalance = false;
  includeDayOff = false;
  embargoCodType = false;
  constructor(fields?: Partial<BalanceMinusPenaltyDetailError>) {
    Object.assign(this, fields);
  }
}

export class NotificationsAll {
  id = 0;
  title = "";
  status = "";
  dueDate = "";
  message = "";
  constructor(fields?: Partial<NotificationsAll>) {
    Object.assign(this, fields);
  }
}

export const EMBARGO_COD_TYPE_LIST = [
  new OptionsClass({ name: "Origin", value: "origin" }),
  new OptionsClass({ name: "Destination", value: "destination" })
];

export class BalancePenaltyNotification {
  npId = 0;
  npTitle = "";
  npMessageFirst = "";
  npMessageSecond = "";
  npDueDate = "";
  npType = "";
  npStyles = "";

  constructor(fields?: Partial<BalancePenaltyNotification>) {
    Object.assign(this, fields);
  }
}
