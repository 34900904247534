import {
  ClaimEntities,
  ClaimData,
  ClaimDetailData,
  SttPiecesData,
  ClaimHistoryData
} from "@/domain/entities/Claim";
import { AxiosResponse } from "axios";
import { Pagination } from "@/domain/entities/Pagination";
import startCase from "lodash/startCase";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class ClaimDataMapper {
  public convertListFromApi(result: AxiosResponse): ClaimEntities {
    const { data } = result;

    const claimListData: ClaimData[] = [];

    if (data.data.length > 0) {
      data.data.forEach((item: any) => {
        return claimListData.push(
          new ClaimData({
            noResi: item.stt_no,
            shipmentId: item.stt_shipment_id,
            sttId: item.stt_id,
            productType: item.stt_product_type,
            category: startCase(item.stt_claim_category),
            status: startCase(item.stt_claim_status),
            statusDate: item.stt_claim_updated_at
          })
        );
      });
    }

    const pagination = new Pagination(
      data.meta.page,
      data.meta.limit,
      data.meta.total_records
    );

    return new ClaimEntities(pagination, claimListData);
  }
  public convertDetailClaimFromApi(result: AxiosResponse): ClaimDetailData {
    const { data } = result;
    return new ClaimDetailData({
      sttId: data.data.stt_id,
      sttNo: data.data.stt_no,
      sttShipmentId: data.data.stt_shipment_id,
      sttClientId: data.data.stt_client_id,
      sttPosId: data.data.stt_pos_id,
      sttTaxNumber: data.data.stt_tax_number,
      sttGoodsEstimatePrice: data.data.stt_goods_estimate_price,
      sttGoodsStatus: data.data.stt_goods_status,
      sttTotalAmount: data.data.stt_total_amount,
      sttNoRefExternal: data.data.stt_no_ref_external,
      sttSource: data.data.stt_source,
      sttOriginCityId: data.data.stt_origin_city_id,
      sttDestinationId: data.data.stt_destination_city_id,
      sttOriginDistrictId: data.data.stt_origin_district_id,
      sttDestinationDistrictId: data.data.stt_destination_district_id,
      sttSenderName: data.data.stt_sender_name,
      sttSenderPhone: data.data.stt_sender_phone,
      sttSenderAddress: data.data.stt_sender_address,
      sttRecipientName: data.data.stt_recipient_name,
      sttRecipientPhone: data.data.stt_recipient_phone,
      sttRecipientAddress: data.data.stt_recipient_address,
      sttRecipientAddressType: data.data.stt_recipient_address_type,
      sttProductType: data.data.stt_product_type,
      sttOriginDistrictRate: data.data.stt_origin_district_rate,
      sttDestinationDistrictRate: data.data.stt_destination_district_rate,
      sttPublichRate: data.data.stt_publish_rate,
      sttShippingSurchargeRate: data.data.stt_shipping_surcharge_rate,
      sttHeavyweightSurchargeRate: data.data.stt_heavyweight_surcharge_rate,
      sttCommoditySurchargeRate: data.data.stt_commodity_surcharge_rate,
      sttDocumentSurchargeRate: data.data.stt_document_surcharge_rate,
      sttInsuranceRate: data.data.stt_insurance_rate,
      sttBmTaxRate: data.data.stt_bm_tax_rate,
      sttPpnTaxRate: data.data.stt_ppn_tax_rate,
      sttPphTaxRate: data.data.stt_pph_tax_rate,
      sttGrossWeight: data.data.stt_gross_weight,
      sttVolumeWeight: data.data.stt_volume_weight,
      sttChargeableWeight: data.data.stt_chargeable_weight,
      sttCommodityCode: data.data.stt_commodity_code,
      sttInsuranceType: data.data.stt_insurance_type,
      sttTotalPiece: data.data.stt_total_piece,
      sttWarningStatus: data.data.stt_warning_status,
      sttCounter: data.data.stt_counter,
      sttLastStatusId: data.data.stt_last_status_id,
      sttClientSttId: data.data.stt_client_stt_id,
      sttVendorId: data.data.stt_vendor_stt_id,
      sttBilledTo: data.data.stt_billed_to,
      sttCodAmount: data.data.stt_cod_amount,
      sttCodFee: data.data.stt_cod_fee,
      sttIsCod: data.data.stt_is_cod,
      sttIsDo: data.data.stt_is_do,
      sttCreatedAt: data.data.stt_created_at,
      sttCreatedBy: data.data.stt_created_name,
      sttUpdatedAt: data.data.stt_updated_at,
      sttUpdatedBy: data.data.stt_updated_name,
      sttCommodityName: data.data.stt_commodity_name,
      sttOriginCityName: data.data.stt_origin_city_name,
      sttDestinationCityName: data.data.stt_destination_city_name,
      sttOriginDistrictName: data.data.stt_origin_district_name,
      sttDestinationDistrictName: data.data.stt_destination_district_name,
      sttBookedFor: data.data.stt_booked_for_name,
      sttCityRate: data.data.stt_city_rate,
      sttForwardRate: data.data.stt_forward_rate,
      sttPieces: data.data.stt_pieces.map((item: any) => {
        return new SttPiecesData({
          sttPiecesLength: item.stt_piece_length,
          sttPiecesHeigth: item.stt_piece_height,
          sttPiecesWidth: item.stt_piece_width,
          sttPiecesGrossWeight: item.stt_piece_gross_weight,
          sttPiecesVolumeWeight: item.stt_piece_volume_weight,
          sttPiecesNo: item.stt_piece_no,
          sttPiecesLastStatus: item.stt_piece_last_status_id
        });
      }),
      claimHistory: data.data.claim_history.map((items: any) => {
        return new ClaimHistoryData({
          claimStatus: items.status,
          claimCreatedAt: items.history_created_at,
          claimDescription: items.description
        });
      })
    });
  }

  public convertRequestClaimDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
