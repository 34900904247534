import {
    Customer,
    CustomerListEntities,
    RequestCustomerList
} from "@/domain/entities/Customer";
import { CustomerManagementEndpoints } from "../../endpoints/horde/CustomerManagementEndpoints";
import { CustomerManagementRepositoryInterface } from "@/data/persistences/repositories/contracts/CustomerManagementInterface";
import { CustomerMapper } from "@/data/persistences/mappers/CustomerMapper";
import { AddCustomerManagementRequestInterface, EditCustomerManagementRequestInterface } from "@/data/payload/contracts/CustomerManagementResquest";
import ApiService from "../../services/ApiService";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AddCustomerManagementApiRequest , EditCustomerManagementApiRequest} from "@/data/payload/api/CustomerManagementApiRequest";

export class CustomerManagementApiRepository implements CustomerManagementRepositoryInterface {
  private service: ApiService;
  private mapper: CustomerMapper;
  private endpoints: CustomerManagementEndpoints;

  constructor(
    service: ApiService,
    mapper: CustomerMapper,
    endpoints: CustomerManagementEndpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async getCustomerList(
    params: RequestCustomerList
  ): Promise<CustomerListEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getCustomerList(params)
    );
    return this.mapper.convertListDataFromApi(resp);
  }

  async addCustomer(
    payload: AddCustomerManagementRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.addCustomer(),
      undefined,
      payload as AddCustomerManagementApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  async getDetailCustomer(id: number): Promise<Customer> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getCustomerDetail(id),
      {}
    );
    return this.mapper.convertDetailProductDataFromApi(resp);
  }

  async editCustomer(id: number, payload: EditCustomerManagementRequestInterface): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "put",
      this.endpoints.editCustomer(id),
      undefined,
      payload as EditCustomerManagementApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async deleteCustomer(id: number): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "delete",
      this.endpoints.deleteCustomer(id),
      undefined
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }
}