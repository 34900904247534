import { DependencyContainer } from "tsyringe";
import ApiService from "../services/ApiService";
import ApiServiceAlgo from "../services/ApiServiceAlgo";
import { Endpoints } from "@/app/infrastructures/misc/Endpoints";

import { AuthApiRepository } from "../repositories/api/AuthApiRepository";
import { AuthDataMapper } from "@/data/persistences/mappers/AuthDataMapper";
import { BulkApiRepository } from "../repositories/api/BulkApiRepository";
import { BulkDataMapper } from "@/data/persistences/mappers/BulkDataMapper";
import { AccountApiRepository } from "../repositories/api/AccountApiRepository";
import { AccountDataMapper } from "@/data/persistences/mappers/AccountMapper";
import { LocationApiRepository } from "../repositories/api/LocationApiRepository";
import { LocationDataMapper } from "@/data/persistences/mappers/LocationDataMapper";
import { UserApiRepository } from "../repositories/api/UserApiRepository";
import { UserDataMapper } from "@/data/persistences/mappers/UserDataMapper";
import { NotificationApiRepository } from "../repositories/api/NotificationApiRepository";
import { NotificationDataMapper } from "@/data/persistences/mappers/NotificationMapper";
import { VendorApiRepository } from "../repositories/api/VendorApiRepository";
import { VendorDataMapper } from "@/data/persistences/mappers/VendorDataMapper";
import { EmbargoApiRepository } from "../repositories/api/EmbargoApiRepository";
import { EmbargoDataMapper } from "@/data/persistences/mappers/EmbargoMapper";
import { LegApiRepository } from "../repositories/api/LegApiRepository";
import { LegDataMapper } from "@/data/persistences/mappers/LegDataMapper";
import { RouteApiRepository } from "../repositories/api/RouteApiRepository";
import { RouteDataMapper } from "@/data/persistences/mappers/RouteMapper";
import { TransportApiRepository } from "../repositories/api/TransportApiRepository";
import { TransportDataMapper } from "@/data/persistences/mappers/TransportDataMapper";
import { BalanceLimitApiRepository } from "../repositories/api/BalanceLimitApiRepository";
import { BalanceLimitMapper } from "@/data/persistences/mappers/BalanceLimitMapper";
import { CommodityApiRepository } from "../repositories/api/CommodityApiRepository";
import { CommodityMapper } from "@/data/persistences/mappers/CommodityMapper";
import { LocationSearchApiRepository } from "../repositories/api/LocationSearchApiRepository";
import { LocationSearchMapper } from "@/data/persistences/mappers/LocationSearchMapper";
import { ConfigPriceApiRepository } from "../repositories/api/ConfigPriceApiRepository";
import { ConfigPriceDataMapper } from "@/data/persistences/mappers/ConfigPriceMapper";
import { ConfigPriceWoodpackingApiRepository } from "../repositories/api/ConfigWoodpackingApiRepository";
import { ConfigPriceWoodpackingDataMapper } from "@/data/persistences/mappers/ConfigWoodpackingMapper";
import { HeavyWeightSurchargeApiRepository } from "@/app/infrastructures/repositories/api/HeavyWeightSurchargeApiRepository";
import { HeavyWeightSurchargeMapper } from "@/data/persistences/mappers/HeavyWeightSurchargeMapper";
import { InsuranceApiRepository } from "../repositories/api/InsuranceApiRepository";
import { InsuranceMapper } from "@/data/persistences/mappers/InsuranceMapper";
import { CommoditySurchargeApiRepository } from "../repositories/api/CommoditySurchargeApiRepository";
import { CommoditySurchargeMapper } from "@/data/persistences/mappers/CommoditySurchargeMapper";
import { BaseRatesApiRepository } from "@/app/infrastructures/repositories/api/BaseRatesApiRepository";
import { BaseRatesMapper } from "@/data/persistences/mappers/BaseRatesMapper";
import { ExchangeRateApiRepository } from "@/app/infrastructures/repositories/api/ExchangeRateRepositoryApi";
import { ExchangeRateDataMapper } from "@/data/persistences/mappers/ExchangeRateMapper";
import { GoodsTaxApiRepository } from "../repositories/api/GoodsTaxApiRepository";
import { GoodsTaxMapper } from "@/data/persistences/mappers/GoodsTaxMapper";
import { ClientApiRepository } from "../repositories/api/ClientApiRepository";
import { ClientMapper } from "@/data/persistences/mappers/ClientMapper";
import { ClientRegistrationApiRepository } from "@/app/infrastructures/repositories/api/ClientRegistrationApiRepository";
import { ClientRegistrationMapper } from "@/data/persistences/mappers/ClientRegistrationMapper";
import { AnnouncementApiRepository } from "../repositories/api/AnnouncementApiRepository";
import { AnnouncementMapper } from "@/data/persistences/mappers/AnnouncementMapper";
import { ClientManagementApiRepository } from "@/app/infrastructures/repositories/api/ClientManagementApiRepository";
import { ClientManagementMapper } from "@/data/persistences/mappers/ClientManagementMapper";
import { ManualSTTApiRepository } from "@/app/infrastructures/repositories/api/ManualSTTApiRepository";
import { ManualSTTDataMapper } from "@/data/persistences/mappers/ManualSTTMapper";
import { TariffApiRepository } from "../repositories/api/TariffApiRepository";
import { TariffDataMapper } from "@/data/persistences/mappers/TariffMapper";
import { BookingApiRepository } from "../repositories/api/BookingApiRepository";
import { BookingMapper } from "@/data/persistences/mappers/BookingMapper";
import { ShipmentBookingApiRepository } from "@/app/infrastructures/repositories/api/ShipmentBookingApiRepository";
import { ShipmentBookingMapper } from "@/data/persistences/mappers/ShipmentBookingMapper";
import { ListSttApiRepository } from "@/app/infrastructures/repositories/api/ListSttApiRepository";
import { ListSttMapper } from "@/data/persistences/mappers/ListSttMapper";
import { CnManifestApiRepository } from "@/app/infrastructures/repositories/api/CnManifestApiRepository";
import { CnManifestMapper } from "@/data/persistences/mappers/CnManifestMapper";
import { PickupManifestApiRepository } from "@/app/infrastructures/repositories/api/PickupManifestApiRepository";
import { PickupManifestMapper } from "@/data/persistences/mappers/PickupManifestMapper";
import { VehicleApiRepository } from "../repositories/api/VehicleApiRepository";
import { VehicleDataMapper } from "@/data/persistences/mappers/VehicleMapper";
import { StiSCApiRepository } from "../repositories/api/StiSCApiRepository";
import { StiSCMapper } from "@/data/persistences/mappers/StiSCMapper";
import { STIApiRepository } from "../repositories/api/STIApiRepository";
import { STIMapper } from "@/data/persistences/mappers/STIMapper";
import { BaggingApiRepository } from "../repositories/api/BaggingApiRepository";
import { BaggingMapper } from "@/data/persistences/mappers/BaggingMapper";
import { StiDestApiRepository } from "../repositories/api/StiDestApiRepository";
import { StiDestMapper } from "@/data/persistences/mappers/StiDestMapper";
import { StiDestScApiRepository } from "../repositories/api/StiDestScApiRepository";
import { StiDestScMapper } from "@/data/persistences/mappers/StiDestScMapper";
import { HandoverApiRepository } from "../repositories/api/HandoverApiRepository";
import { HandoverMapper } from "@/data/persistences/mappers/HandoverMapper";
import { ShortlandApiRepository } from "../repositories/api/ShortlandApiRepository";
import { ShortlandMapper } from "@/data/persistences/mappers/ShortlandMapper";
import { PodDexApiRepository } from "../repositories/api/PodDexApiRepository";
import { PodDexMapper } from "@/data/persistences/mappers/PodDexMapper";
import { DeliveryManifestApiRepository } from "../repositories/api/DeliveryManifestApiRepository";
import { DeliveryManifestMapper } from "@/data/persistences/mappers/DeliveryManifestMapper";
import { PosApiRepository } from "../repositories/api/PosApiRepository";
import { PosMapper } from "@/data/persistences/mappers/PosMapper";
import { ConsolidatorApiRepository } from "../repositories/api/ConsolidatorApiRepository";
import { ConsolidatorMapper } from "@/data/persistences/mappers/ConsolidatorMapper";
import { PartnerSubconsoleApiRepository } from "../repositories/api/PartnerSubconsoleApiRepository";
import { PartnerSubconsoleMapper } from "@/data/persistences/mappers/PartnerSubconsoleMapper";
import { PartnerApiRepository } from "@/app/infrastructures/repositories/api/PartnerApiRepository";
import { PartnerMapper } from "@/data/persistences/mappers/PartnerMapper";
import { CustomProcessApiRepository } from "@/app/infrastructures/repositories/api/CustomProcessApiRepository";
import { CustomProcessMapper } from "@/data/persistences/mappers/CustomProcessMapper";
import { PosClientApiRepository } from "../repositories/api/PosClientApiRepository";
import { PosClientMapper } from "@/data/persistences/mappers/PosClientMapper";
import { BulkBookingApiRepository } from "../repositories/api/BulkBookingApiRepository";
import { BulkBookingMapper } from "@/data/persistences/mappers/BulkBookingMapper";
import { AdjustmentPenaltyApiRepository } from "../repositories/api/AdjustmentPenaltyApiRepository";
import { AdjustmentPenaltyMapper } from "@/data/persistences/mappers/AdjustmentPenaltyMapper";
import { RefundConfigurationApiRepository } from "../repositories/api/RefundConfigurationApiRepository";
import { RefundConfigurationMapper } from "@/data/persistences/mappers/RefundConfigurationMapper";
import { AuthAlgoApiRepository } from "../repositories/api/AuthAlgoApiRepository";
import { AuthAlgoMapper } from "@/data/persistences/mappers/AuthAlgoMapper";
import { PosFavoriteApiRepository } from "../repositories/api/PosFavoriteApiRepository";
import { PosFavoriteMapper } from "@/data/persistences/mappers/PosFavoriteMapper";
import { BalanceMapper } from "@/data/persistences/mappers/BalanceMapper";
import { BalanceApiRepository } from "@/app/infrastructures/repositories/api/BalanceApiRepository";
import { CodIncomeApiRepository } from "../repositories/api/CodIncomeApiRepository";
import { CodIncomeMapper } from "@/data/persistences/mappers/CodIncomeMapper";
import { TrackingApiRepository } from "../repositories/api/TrackingApiRepository";
import { TrackingMapper } from "@/data/persistences/mappers/TrackingMapper";
import { CargoApiRepository } from "../repositories/api/CargoApiRepository";
import { CargoMapper } from "@/data/persistences/mappers/CargoMapper";
import { BeneficiaryApiRepository } from "../repositories/api/BeneficiaryApiRepository";
import { BeneficiaryMapper } from "@/data/persistences/mappers/BeneficiaryMapper";
import { BulkBookingCommissionApiRepository } from "../repositories/api/BulkBookingCommissionApiRepository";
import { BulkBookingCommissionMapper } from "@/data/persistences/mappers/BulkBookingCommissionMapper";
import { BookingCommissionApiRepository } from "../repositories/api/BookingCommissionApiRepository";
import { BookingCommissionMapper } from "@/data/persistences/mappers/BookingCommissionMapper";
import { ManualDeductAddSaldoRepository } from "../repositories/api/ManualDeductAddSaldoRepositoryInterface";
import { ManualDeductAddSaldoMapper } from "@/data/persistences/mappers/ManualDeductAddSaldoMapper";
import { TopUpVerificationApiRepository } from "../repositories/api/TopUpVerificationApiRepository";
import { TopUpVerificationMapper } from "@/data/persistences/mappers/TopUpVerificationMapper";
import { TopupApprovalApiRepository } from "../repositories/api/TopupApprovalApiRepository";
import { TopupApprovalMapper } from "@/data/persistences/mappers/TopupApprovalMapper";
import { BulkDownloadApiRepository } from "../repositories/api/BulkDownloadApiRepository";
import { BulkDownloadMapper } from "@/data/persistences/mappers/BulkDownloadMapper";
import { DeliveryTieringPolicyApiRepository } from "../repositories/api/DeliveryTieringPolicyApiRepository";
import { DeliveryTieringPolicyMapper } from "@/data/persistences/mappers/DeliveryTieringPolicyMapper";
import { UpdateDeliveryApiRepository } from "@/app/infrastructures/repositories/api/UpdateDeliveryApiRepository";
import { UpdateDeliveryMapper } from "@/data/persistences/mappers/UpdateDeliveryMapper";
import { MessagingServiceConfigurationApiRepository } from "@/app/infrastructures/repositories/api/MessagingServiceConfigurationApiRepository";
import { MessagingServiceConfigurationMapper } from "@/data/persistences/mappers/MessagingServiceConfigurationMapper";
import { ReportApiRepository } from "@/app/infrastructures/repositories/api/ReportApiRepository";
import { ReportMapper } from "@/data/persistences/mappers/ReportMapper";
import { NGenApiRepository } from "../repositories/api/NGenApiRepository";
import { NGenMapper } from "@/data/persistences/mappers/NGenMapper";
import ApiServiceNGen from "../services/ApiServiceNGen";
import { AuthNGenApiRepository } from "../repositories/api/AuthNGenApiRepository";
import { IntegrationMonitoringApiRepository } from "../repositories/api/IntegrationMonitoringApiRepository";
import { IntegrationMonitoringMapper } from "@/data/persistences/mappers/IntegrationMonitoringMapper";
import { MiddlewareApiRepository } from "@/app/infrastructures/repositories/api/MiddlewareApiRepository";
import { MiddlewareMapper } from "@/data/persistences/mappers/MiddlewareMapper";
import ApiServiceMiddleware from "@/app/infrastructures/services/ApiServiceMiddleware";
import { InstantBookingApiRepository } from "../repositories/api/InstantBookingApiRepository";
import { InstantBookingMapper } from "@/data/persistences/mappers/InstantBookingMapper";
import { UniversalPrintApiRepository } from "@/app/infrastructures/repositories/api/UniversalPrintApiRepository";
import { DashboardApiRepository } from "../repositories/api/DashboardApiRepository";
import { DashboardMapper } from "@/data/persistences/mappers/DashboardMapper";
import { EmailApiRepository } from "@/app/infrastructures/repositories/api/EmailApiRepository";
import { EmailMapper } from "@/data/persistences/mappers/EmailMapper";
import { BalanceTransactionApiRepository } from "@/app/infrastructures/repositories/api/BalanceTransactionApiRepository";
import { BalanceTransactionMapper } from "@/data/persistences/mappers/BalanceTransactionMapper";
import { LastBalanceApiRepository } from "@/app/infrastructures/repositories/api/LastBalanceApiRepository";
import { LastBalanceMapper } from "@/data/persistences/mappers/LastBalanceMapper";
import { ForwardBookingCommissionApiRepository } from "@/app/infrastructures/repositories/api/ForwardBookingCommissionApiRepository";
import { ForwardBookingCommissionMapper } from "@/data/persistences/mappers/ForwardBookingCommissionMapper";
import { PaymentApiRepository } from "@/app/infrastructures/repositories/api/PaymentApiRepository";
import { PaymentMapper } from "@/data/persistences/mappers/PaymentMapper";
import { IncomingOutgoingApiRepository } from "../repositories/api/IncomingOutgoingApiRepository";
import { IncomingOutgoingMapper } from "@/data/persistences/mappers/IncomingOutgoingMapper";
import { ProgressiveCommissionConfigurationApiRepository } from "@/app/infrastructures/repositories/api/ProgressiveCommissionConfigurationApiRepository";
import { ProgressiveCommissionConfigurationMapper } from "@/data/persistences/mappers/ProgressiveCommissionConfigurationMapper";
import { STTPaidUnpaidApiRepository } from "../repositories/api/STTPaidUnpaidApiRepository";
import { STTPaidUnpaidMapper } from "@/data/persistences/mappers/STTPaidUnpaid";
import { UniversalApiRepository } from "../repositories/api/UniversalApiRepository";
import { UniversalMapper } from "@/data/persistences/mappers/UniversalMapper";
import { ProgressiveCommissionDashboardApiRepository } from "../repositories/api/ProgressiveCommissionDashboardApiRepository";
import { ProgressiveCommissionDashboardMapper } from "@/data/persistences/mappers/ProgressiveCommissionDashboardMapper";
import { BaggingGroupingApiRepository } from "../repositories/api/BaggingGroupingApiRepository";
import { BaggingGroupingMapper } from "@/data/persistences/mappers/BaggingGroupingMapper";
import { ClaimApiRepository } from "../repositories/api/ClaimApiRepository";
import { ClaimDataMapper } from "@/data/persistences/mappers/ClaimDataMapper";
import { TrackDeliveryApiRepository } from "../repositories/api/TrackDeliveryApiRepository";
import { TrackDeliveryMapper } from "@/data/persistences/mappers/TrackDeliveryMapper";
import { SaldoPoinApiRepository } from "../repositories/api/SaldoPoinApiRepository";
import { SaldoPoinMapper } from "@/data/persistences/mappers/SaldoPoinMapper";
import { CargoConfigurationApiRepository } from "@/app/infrastructures/repositories/api/CargoConfigurationApiRepository";
import { CargoConfigurationMapper } from "@/data/persistences/mappers/CargoConfigurationMapper";
import { ServiceDelayApiRepository } from "../repositories/api/ServiceDelayApiRepository";
import { ServiceDelayMapper } from "@/data/persistences/mappers/ServiceDelayMapper";
import { ConfigPriceCodApiRepository } from "../repositories/api/ConfigPriceCodApiRepository";
import { ConfigPriceCodMapper } from "@/data/persistences/mappers/ConfigPriceCodMapper";
import { CourierManagementApiRepository } from "../repositories/api/CourierManagementApiRepository";
import { CourierManagementMapper } from "@/data/persistences/mappers/CourierManagementMapper";
import { CodConfigurationApiRepository } from "../repositories/api/CodConfigurationApiRepository";
import { CodConfigurationMapper } from "@/data/persistences/mappers/CodConfigurationMapper";
import { BalanceMinusPenaltyApiRepository } from "../repositories/api/BalanceMinusPenaltyApiRepository";
import { BalanceMinusPenaltyMapper } from "@/data/persistences/mappers/BalanceMinusPenaltyMapper";
import { HoldBalanceHistoryApiRepository } from "../repositories/api/HoldBalanceHistoryApiRepository";
import { HoldBalanceHistoryMapper } from "@/data/persistences/mappers/HoldBalanceHistoryMapper";
import { ConfigFakeDexIndicationApiRepository } from "../repositories/api/ConfigFakeDexIndicationApiRepository";
import { ConfigFakeDexIndicationMapper } from "@/data/persistences/mappers/ConfigFakeDexIndicationMapper";
import { ConfigFakeDexIndicationEndpoint } from "../endpoints/horde/ConfigFakeDexIndicationEndpoint";
import { DexAssessmentApiRepository } from "../repositories/api/DexAssessmentApiRepository";
import { DexAssessmentMapper } from "@/data/persistences/mappers/DexAsessmentMapper";
import { DexAssessmentEndpoint } from "../endpoints/horde/DexAssessmentEndpoint";
import { UrgentDeliveryApiRepository } from "../repositories/api/UrgentDeliveryRepository";
import { UrgentDeliveryMapper } from "@/data/persistences/mappers/UrgentDeliveryMapper";
import { UrgentDeliveryEndpoint } from "../endpoints/hydra/UrgentDeliveryEndpoints";
import { UrgentDeliveryManagementApiRepository } from "../repositories/api/UrgentDeliveryManagementApiRepository";
import { UrgentDeliveryManagementMapper } from "@/data/persistences/mappers/UrgentDeliveryManagementMapper";
import { InternationalDocumentApiRepository } from "../repositories/api/InternationalDocumentApiRepository";
import { InternationalDocumentMapper } from "@/data/persistences/mappers/InternationalDocumentMapper";
import { InternationalDocumentEndpoints } from "../endpoints/horde/InternationalDocumentEndpoints";
import { CustomerManagementApiRepository } from "../repositories/api/CustomerManagementApiRepository";
import { CustomerMapper } from "@/data/persistences/mappers/CustomerMapper";
import { CustomerManagementEndpoints } from "../endpoints/horde/CustomerManagementEndpoints";
import { ListPickupApiRepository } from "../repositories/api/ListPickupApiRepository";
import { ListPickupMapper } from "@/data/persistences/mappers/ListPickupMapper";
import { ListPickupEndpoints } from "../endpoints/hydra/ListPickupEndpoints";
import { CodReconcileMapper } from "@/data/persistences/mappers/CodReconcileMapper";
import { CodReconcileApiRepository } from "@/app/infrastructures/repositories/api/CodReconcileApiRepository";
import { CollectibleCardApiRepository } from "@/app/infrastructures/repositories/api/CollectibleCardApiRepository";
import { CollectibleCardMapper } from "@/data/persistences/mappers/CollectibleCardMapper";

export class RepositoryModule {
  public static init(container: DependencyContainer) {
    container.register<AuthApiRepository>(AuthApiRepository, {
      useFactory: d => {
        return new AuthApiRepository(
          d.resolve(ApiService),
          d.resolve(AuthDataMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<BulkApiRepository>(BulkApiRepository, {
      useFactory: d => {
        return new BulkApiRepository(
          d.resolve(ApiService),
          d.resolve(BulkDataMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<AccountApiRepository>(AccountApiRepository, {
      useFactory: d => {
        return new AccountApiRepository(
          d.resolve(ApiService),
          d.resolve(AccountDataMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<LocationApiRepository>(LocationApiRepository, {
      useFactory: d => {
        return new LocationApiRepository(
          d.resolve(ApiService),
          d.resolve(LocationDataMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<NotificationApiRepository>(NotificationApiRepository, {
      useFactory: d => {
        return new NotificationApiRepository(
          d.resolve(ApiService),
          d.resolve(NotificationDataMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<UserApiRepository>(UserApiRepository, {
      useFactory: d => {
        return new UserApiRepository(
          d.resolve(ApiService),
          d.resolve(UserDataMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<VendorApiRepository>(VendorApiRepository, {
      useFactory: d => {
        return new VendorApiRepository(
          d.resolve(ApiService),
          d.resolve(VendorDataMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<CommodityApiRepository>(CommodityApiRepository, {
      useFactory: d => {
        return new CommodityApiRepository(
          d.resolve(ApiService),
          d.resolve(CommodityMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<EmbargoApiRepository>(EmbargoApiRepository, {
      useFactory: d => {
        return new EmbargoApiRepository(
          d.resolve(ApiService),
          d.resolve(EmbargoDataMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<LocationSearchApiRepository>(
      LocationSearchApiRepository,
      {
        useFactory: d => {
          return new LocationSearchApiRepository(
            d.resolve(ApiService),
            d.resolve(LocationSearchMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<LegApiRepository>(LegApiRepository, {
      useFactory: d => {
        return new LegApiRepository(
          d.resolve(ApiService),
          d.resolve(LegDataMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<RouteApiRepository>(RouteApiRepository, {
      useFactory: d => {
        return new RouteApiRepository(
          d.resolve(ApiService),
          d.resolve(RouteDataMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<TransportApiRepository>(TransportApiRepository, {
      useFactory: d => {
        return new TransportApiRepository(
          d.resolve(ApiService),
          d.resolve(TransportDataMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<BalanceLimitApiRepository>(BalanceLimitApiRepository, {
      useFactory: d => {
        return new BalanceLimitApiRepository(
          d.resolve(ApiService),
          d.resolve(BalanceLimitMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<ConfigPriceApiRepository>(ConfigPriceApiRepository, {
      useFactory: d => {
        return new ConfigPriceApiRepository(
          d.resolve(ApiService),
          d.resolve(ConfigPriceDataMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<ConfigPriceWoodpackingApiRepository>(
      ConfigPriceWoodpackingApiRepository,
      {
        useFactory: d => {
          return new ConfigPriceWoodpackingApiRepository(
            d.resolve(ApiService),
            d.resolve(ConfigPriceWoodpackingDataMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<HeavyWeightSurchargeApiRepository>(
      HeavyWeightSurchargeApiRepository,
      {
        useFactory: d => {
          return new HeavyWeightSurchargeApiRepository(
            d.resolve(ApiService),
            d.resolve(HeavyWeightSurchargeMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<InsuranceApiRepository>(InsuranceApiRepository, {
      useFactory: d => {
        return new InsuranceApiRepository(
          d.resolve(ApiService),
          d.resolve(InsuranceMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<CommoditySurchargeApiRepository>(
      CommoditySurchargeApiRepository,
      {
        useFactory: d => {
          return new CommoditySurchargeApiRepository(
            d.resolve(ApiService),
            d.resolve(CommoditySurchargeMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<BaseRatesApiRepository>(BaseRatesApiRepository, {
      useFactory: d => {
        return new BaseRatesApiRepository(
          d.resolve(ApiService),
          d.resolve(BaseRatesMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<ExchangeRateApiRepository>(ExchangeRateApiRepository, {
      useFactory: d => {
        return new ExchangeRateApiRepository(
          d.resolve(ApiService),
          d.resolve(ExchangeRateDataMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<ClientApiRepository>(ClientApiRepository, {
      useFactory: d => {
        return new ClientApiRepository(
          d.resolve(ApiService),
          d.resolve(ClientMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<GoodsTaxApiRepository>(GoodsTaxApiRepository, {
      useFactory: d => {
        return new GoodsTaxApiRepository(
          d.resolve(ApiService),
          d.resolve(GoodsTaxMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<ClientRegistrationApiRepository>(
      ClientRegistrationApiRepository,
      {
        useFactory: d => {
          return new ClientRegistrationApiRepository(
            d.resolve(ApiService),
            d.resolve(ClientRegistrationMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<AnnouncementApiRepository>(AnnouncementApiRepository, {
      useFactory: d => {
        return new AnnouncementApiRepository(
          d.resolve(ApiService),
          d.resolve(AnnouncementMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<ClientManagementApiRepository>(
      ClientManagementApiRepository,
      {
        useFactory: d => {
          return new ClientManagementApiRepository(
            d.resolve(ApiService),
            d.resolve(ClientManagementMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<ManualSTTApiRepository>(ManualSTTApiRepository, {
      useFactory: d => {
        return new ManualSTTApiRepository(
          d.resolve(ApiService),
          d.resolve(ManualSTTDataMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<TariffApiRepository>(TariffApiRepository, {
      useFactory: d => {
        return new TariffApiRepository(
          d.resolve(ApiService),
          d.resolve(TariffDataMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<BookingApiRepository>(BookingApiRepository, {
      useFactory: d => {
        return new BookingApiRepository(
          d.resolve(ApiService),
          d.resolve(BookingMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<ShipmentBookingApiRepository>(
      ShipmentBookingApiRepository,
      {
        useFactory: d => {
          return new ShipmentBookingApiRepository(
            d.resolve(ApiService),
            d.resolve(ShipmentBookingMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<ListSttApiRepository>(ListSttApiRepository, {
      useFactory: d => {
        return new ListSttApiRepository(
          d.resolve(ApiService),
          d.resolve(ListSttMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<CnManifestApiRepository>(CnManifestApiRepository, {
      useFactory: d => {
        return new CnManifestApiRepository(
          d.resolve(ApiService),
          d.resolve(CnManifestMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<VehicleApiRepository>(VehicleApiRepository, {
      useFactory: d => {
        return new VehicleApiRepository(
          d.resolve(ApiService),
          d.resolve(VehicleDataMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<PickupManifestApiRepository>(
      PickupManifestApiRepository,
      {
        useFactory: d => {
          return new PickupManifestApiRepository(
            d.resolve(ApiService),
            d.resolve(PickupManifestMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<StiSCApiRepository>(StiSCApiRepository, {
      useFactory: d => {
        return new StiSCApiRepository(
          d.resolve(ApiService),
          d.resolve(StiSCMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<STIApiRepository>(STIApiRepository, {
      useFactory: d => {
        return new STIApiRepository(
          d.resolve(ApiService),
          d.resolve(STIMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<BaggingApiRepository>(BaggingApiRepository, {
      useFactory: d => {
        return new BaggingApiRepository(
          d.resolve(ApiService),
          d.resolve(BaggingMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<StiDestApiRepository>(StiDestApiRepository, {
      useFactory: d => {
        return new StiDestApiRepository(
          d.resolve(ApiService),
          d.resolve(StiDestMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<StiDestScApiRepository>(StiDestScApiRepository, {
      useFactory: d => {
        return new StiDestScApiRepository(
          d.resolve(ApiService),
          d.resolve(StiDestScMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<HandoverApiRepository>(HandoverApiRepository, {
      useFactory: d => {
        return new HandoverApiRepository(
          d.resolve(ApiService),
          d.resolve(HandoverMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<ShortlandApiRepository>(ShortlandApiRepository, {
      useFactory: d => {
        return new ShortlandApiRepository(
          d.resolve(ApiService),
          d.resolve(ShortlandMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<PodDexApiRepository>(PodDexApiRepository, {
      useFactory: d => {
        return new PodDexApiRepository(
          d.resolve(ApiService),
          d.resolve(PodDexMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<DeliveryManifestApiRepository>(
      DeliveryManifestApiRepository,
      {
        useFactory: d => {
          return new DeliveryManifestApiRepository(
            d.resolve(ApiService),
            d.resolve(DeliveryManifestMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<ConsolidatorApiRepository>(ConsolidatorApiRepository, {
      useFactory: d => {
        return new ConsolidatorApiRepository(
          d.resolve(ApiService),
          d.resolve(ConsolidatorMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<PartnerSubconsoleApiRepository>(
      PartnerSubconsoleApiRepository,
      {
        useFactory: d => {
          return new PartnerSubconsoleApiRepository(
            d.resolve(ApiService),
            d.resolve(PartnerSubconsoleMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<PartnerApiRepository>(PartnerApiRepository, {
      useFactory: d => {
        return new PartnerApiRepository(
          d.resolve(ApiService),
          d.resolve(PartnerMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<PosApiRepository>(PosApiRepository, {
      useFactory: d => {
        return new PosApiRepository(
          d.resolve(ApiService),
          d.resolve(PosMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<PosClientApiRepository>(PosClientApiRepository, {
      useFactory: d => {
        return new PosClientApiRepository(
          d.resolve(ApiService),
          d.resolve(PosClientMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<CustomProcessApiRepository>(CustomProcessApiRepository, {
      useFactory: d => {
        return new CustomProcessApiRepository(
          d.resolve(ApiService),
          d.resolve(CustomProcessMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<BulkBookingApiRepository>(BulkBookingApiRepository, {
      useFactory: d => {
        return new BulkBookingApiRepository(
          d.resolve(ApiService),
          d.resolve(BulkBookingMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<AdjustmentPenaltyApiRepository>(
      AdjustmentPenaltyApiRepository,
      {
        useFactory: d => {
          return new AdjustmentPenaltyApiRepository(
            d.resolve(ApiService),
            d.resolve(AdjustmentPenaltyMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<RefundConfigurationApiRepository>(
      RefundConfigurationApiRepository,
      {
        useFactory: d => {
          return new RefundConfigurationApiRepository(
            d.resolve(ApiService),
            d.resolve(RefundConfigurationMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<BalanceApiRepository>(BalanceApiRepository, {
      useFactory: d => {
        return new BalanceApiRepository(
          d.resolve(ApiService),
          d.resolve(BalanceMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<CodIncomeApiRepository>(CodIncomeApiRepository, {
      useFactory: d => {
        return new CodIncomeApiRepository(
          d.resolve(ApiService),
          d.resolve(CodIncomeMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<TrackingApiRepository>(TrackingApiRepository, {
      useFactory: d => {
        return new TrackingApiRepository(
          d.resolve(ApiService),
          d.resolve(TrackingMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<CargoApiRepository>(CargoApiRepository, {
      useFactory: d => {
        return new CargoApiRepository(
          d.resolve(ApiService),
          d.resolve(CargoMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<BeneficiaryApiRepository>(BeneficiaryApiRepository, {
      useFactory: d => {
        return new BeneficiaryApiRepository(
          d.resolve(ApiService),
          d.resolve(BeneficiaryMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<ManualDeductAddSaldoRepository>(
      ManualDeductAddSaldoRepository,
      {
        useFactory: d => {
          return new ManualDeductAddSaldoRepository(
            d.resolve(ApiService),
            d.resolve(ManualDeductAddSaldoMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<BulkBookingCommissionApiRepository>(
      BulkBookingCommissionApiRepository,
      {
        useFactory: d => {
          return new BulkBookingCommissionApiRepository(
            d.resolve(ApiService),
            d.resolve(BulkBookingCommissionMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<BookingCommissionApiRepository>(
      BookingCommissionApiRepository,
      {
        useFactory: d => {
          return new BookingCommissionApiRepository(
            d.resolve(ApiService),
            d.resolve(BookingCommissionMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<TopUpVerificationApiRepository>(
      TopUpVerificationApiRepository,
      {
        useFactory: d => {
          return new TopUpVerificationApiRepository(
            d.resolve(ApiService),
            d.resolve(TopUpVerificationMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<TopupApprovalApiRepository>(TopupApprovalApiRepository, {
      useFactory: d => {
        return new TopupApprovalApiRepository(
          d.resolve(ApiService),
          d.resolve(TopupApprovalMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<BulkDownloadApiRepository>(BulkDownloadApiRepository, {
      useFactory: d => {
        return new BulkDownloadApiRepository(
          d.resolve(ApiService),
          d.resolve(BulkDownloadMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<DeliveryTieringPolicyApiRepository>(
      DeliveryTieringPolicyApiRepository,
      {
        useFactory: d => {
          return new DeliveryTieringPolicyApiRepository(
            d.resolve(ApiService),
            d.resolve(DeliveryTieringPolicyMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<UpdateDeliveryApiRepository>(
      UpdateDeliveryApiRepository,
      {
        useFactory: d => {
          return new UpdateDeliveryApiRepository(
            d.resolve(ApiService),
            d.resolve(UpdateDeliveryMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<MessagingServiceConfigurationApiRepository>(
      MessagingServiceConfigurationApiRepository,
      {
        useFactory: d => {
          return new MessagingServiceConfigurationApiRepository(
            d.resolve(ApiService),
            d.resolve(MessagingServiceConfigurationMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<ReportApiRepository>(ReportApiRepository, {
      useFactory: d => {
        return new ReportApiRepository(
          d.resolve(ApiService),
          d.resolve(ReportMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<IntegrationMonitoringApiRepository>(
      IntegrationMonitoringApiRepository,
      {
        useFactory: d => {
          return new IntegrationMonitoringApiRepository(
            d.resolve(ApiService),
            d.resolve(IntegrationMonitoringMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<InstantBookingApiRepository>(
      InstantBookingApiRepository,
      {
        useFactory: d => {
          return new InstantBookingApiRepository(
            d.resolve(ApiService),
            d.resolve(InstantBookingMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<DashboardApiRepository>(DashboardApiRepository, {
      useFactory: d => {
        return new DashboardApiRepository(
          d.resolve(ApiService),
          d.resolve(DashboardMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<EmailApiRepository>(EmailApiRepository, {
      useFactory: d => {
        return new EmailApiRepository(
          d.resolve(ApiService),
          d.resolve(EmailMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<BalanceTransactionApiRepository>(
      BalanceTransactionApiRepository,
      {
        useFactory: d => {
          return new BalanceTransactionApiRepository(
            d.resolve(ApiService),
            d.resolve(BalanceTransactionMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<LastBalanceApiRepository>(LastBalanceApiRepository, {
      useFactory: d => {
        return new LastBalanceApiRepository(
          d.resolve(ApiService),
          d.resolve(LastBalanceMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<ForwardBookingCommissionApiRepository>(
      ForwardBookingCommissionApiRepository,
      {
        useFactory: d => {
          return new ForwardBookingCommissionApiRepository(
            d.resolve(ApiService),
            d.resolve(ForwardBookingCommissionMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<IncomingOutgoingApiRepository>(
      IncomingOutgoingApiRepository,
      {
        useFactory: d => {
          return new IncomingOutgoingApiRepository(
            d.resolve(ApiService),
            d.resolve(IncomingOutgoingMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<ProgressiveCommissionConfigurationApiRepository>(
      ProgressiveCommissionConfigurationApiRepository,
      {
        useFactory: d => {
          return new ProgressiveCommissionConfigurationApiRepository(
            d.resolve(ApiService),
            d.resolve(ProgressiveCommissionConfigurationMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );
    container.register<ClaimApiRepository>(ClaimApiRepository, {
      useFactory: d => {
        return new ClaimApiRepository(
          d.resolve(ApiService),
          d.resolve(ClaimDataMapper),
          d.resolve(Endpoints)
        );
      }
    });

    container.register<TrackDeliveryApiRepository>(TrackDeliveryApiRepository, {
      useFactory: d => {
        return new TrackDeliveryApiRepository(
          d.resolve(ApiService),
          d.resolve(TrackDeliveryMapper),
          d.resolve(Endpoints)
        );
      }
    });

    container.register<ServiceDelayApiRepository>(ServiceDelayApiRepository, {
      useFactory: d => {
        return new ServiceDelayApiRepository(
          d.resolve(ApiService),
          d.resolve(ServiceDelayMapper),
          d.resolve(Endpoints)
        );
      }
    });

    container.register<ConfigPriceCodApiRepository>(
      ConfigPriceCodApiRepository,
      {
        useFactory: d => {
          return new ConfigPriceCodApiRepository(
            d.resolve(ApiService),
            d.resolve(ConfigPriceCodMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );

    container.register<CodConfigurationApiRepository>(
      CodConfigurationApiRepository,
      {
        useFactory: d => {
          return new CodConfigurationApiRepository(
            d.resolve(ApiService),
            d.resolve(CodConfigurationMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );

    container.register<ConfigFakeDexIndicationApiRepository>(
      ConfigFakeDexIndicationApiRepository,
      {
        useFactory: d => {
          return new ConfigFakeDexIndicationApiRepository(
            d.resolve(ApiService),
            d.resolve(ConfigFakeDexIndicationMapper),
            d.resolve(ConfigFakeDexIndicationEndpoint)
          );
        }
      }
    );

    container.register<DexAssessmentApiRepository>(DexAssessmentApiRepository, {
      useFactory: d => {
        return new DexAssessmentApiRepository(
          d.resolve(ApiService),
          d.resolve(DexAssessmentMapper),
          d.resolve(DexAssessmentEndpoint)
        );
      }
    });

    // saldo & poin sender dashboard
    container.register<SaldoPoinApiRepository>(SaldoPoinApiRepository, {
      useFactory: d => {
        return new SaldoPoinApiRepository(
          d.resolve(ApiService),
          d.resolve(SaldoPoinMapper),
          d.resolve(Endpoints)
        );
      }
    });

    // algo
    container.register<AuthAlgoApiRepository>(AuthAlgoApiRepository, {
      useFactory: d => {
        return new AuthAlgoApiRepository(
          d.resolve(ApiServiceAlgo), // algo
          d.resolve(AuthAlgoMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<PosFavoriteApiRepository>(PosFavoriteApiRepository, {
      useFactory: d => {
        return new PosFavoriteApiRepository(
          d.resolve(ApiServiceAlgo), // algo
          d.resolve(PosFavoriteMapper),
          d.resolve(Endpoints)
        );
      }
    });

    // ngen
    container.register<AuthNGenApiRepository>(AuthNGenApiRepository, {
      useFactory: d => {
        return new AuthNGenApiRepository(
          d.resolve(ApiService),
          d.resolve(NGenMapper),
          d.resolve(Endpoints)
        );
      }
    });
    container.register<NGenApiRepository>(NGenApiRepository, {
      useFactory: d => {
        return new NGenApiRepository(
          d.resolve(ApiServiceNGen),
          d.resolve(NGenMapper),
          d.resolve(Endpoints)
        );
      }
    });

    // middleware
    container.register<MiddlewareApiRepository>(MiddlewareApiRepository, {
      useFactory: d => {
        return new MiddlewareApiRepository(
          d.resolve(ApiServiceMiddleware),
          d.resolve(ApiService),
          d.resolve(MiddlewareMapper),
          d.resolve(Endpoints)
        );
      }
    });

    // external
    container.register<UniversalPrintApiRepository>(
      UniversalPrintApiRepository,
      {
        useFactory: d => {
          return new UniversalPrintApiRepository(
            d.resolve(ApiService),
            d.resolve(BookingMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );

    container.register<PaymentApiRepository>(PaymentApiRepository, {
      useFactory: d => {
        return new PaymentApiRepository(
          d.resolve(ApiService),
          d.resolve(PaymentMapper),
          d.resolve(Endpoints)
        );
      }
    });

    container.register<STTPaidUnpaidApiRepository>(STTPaidUnpaidApiRepository, {
      useFactory: d => {
        return new STTPaidUnpaidApiRepository(
          d.resolve(ApiService),
          d.resolve(STTPaidUnpaidMapper),
          d.resolve(Endpoints)
        );
      }
    });

    container.register<ProgressiveCommissionDashboardApiRepository>(
      ProgressiveCommissionDashboardApiRepository,
      {
        useFactory: d => {
          return new ProgressiveCommissionDashboardApiRepository(
            d.resolve(ApiService),
            d.resolve(ProgressiveCommissionDashboardMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );

    container.register<BaggingGroupingApiRepository>(
      BaggingGroupingApiRepository,
      {
        useFactory: d => {
          return new BaggingGroupingApiRepository(
            d.resolve(ApiService),
            d.resolve(BaggingGroupingMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );

    container.register<CourierManagementApiRepository>(
      CourierManagementApiRepository,
      {
        useFactory: d => {
          return new CourierManagementApiRepository(
            d.resolve(ApiService),
            d.resolve(CourierManagementMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );

    container.register<HoldBalanceHistoryApiRepository>(
      HoldBalanceHistoryApiRepository,
      {
        useFactory: d => {
          return new HoldBalanceHistoryApiRepository(
            d.resolve(ApiService),
            d.resolve(HoldBalanceHistoryMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );

    // balance minus penalty
    container.register<BalanceMinusPenaltyApiRepository>(
      BalanceMinusPenaltyApiRepository,
      {
        useFactory: d => {
          return new BalanceMinusPenaltyApiRepository(
            d.resolve(ApiService),
            d.resolve(BalanceMinusPenaltyMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );

    // universal
    container.register<UniversalApiRepository>(UniversalApiRepository, {
      useFactory: d => {
        return new UniversalApiRepository(
          d.resolve(ApiService),
          d.resolve(UniversalMapper),
          d.resolve(Endpoints)
        );
      }
    });

    container.register<CargoConfigurationApiRepository>(
      CargoConfigurationApiRepository,
      {
        useFactory: d => {
          return new CargoConfigurationApiRepository(
            d.resolve(ApiService),
            d.resolve(CargoConfigurationMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );

    container.register<UrgentDeliveryApiRepository>(
      UrgentDeliveryApiRepository,
      {
        useFactory: d => {
          return new UrgentDeliveryApiRepository(
            d.resolve(ApiService),
            d.resolve(UrgentDeliveryMapper),
            d.resolve(UrgentDeliveryEndpoint)
          )
        }
      }
    );

    container.register<UrgentDeliveryManagementApiRepository>(
      UrgentDeliveryManagementApiRepository,
      {
        useFactory: d => {
          return new UrgentDeliveryManagementApiRepository(
            d.resolve(ApiService),
            d.resolve(UrgentDeliveryManagementMapper),
            d.resolve(Endpoints)
          );
        }
      }
    );

    container.register<InternationalDocumentApiRepository>(
      InternationalDocumentApiRepository,
      {
        useFactory: d => {
          return new InternationalDocumentApiRepository(
            d.resolve(ApiService),
            d.resolve(InternationalDocumentMapper),
            d.resolve(InternationalDocumentEndpoints)
          )
        }
      }
    )

    container.register<CustomerManagementApiRepository>(
      CustomerManagementApiRepository,
      {
        useFactory: d => {
          return new CustomerManagementApiRepository(
            d.resolve(ApiService),
            d.resolve(CustomerMapper),
            d.resolve(CustomerManagementEndpoints)
          )
        }
      });

    container.register<ListPickupApiRepository>(
      ListPickupApiRepository,
      {
        useFactory: d => {
          return new ListPickupApiRepository(
            d.resolve(ApiService),
            d.resolve(ListPickupMapper),
            d.resolve(ListPickupEndpoints)
          );
        }
      }
    );
    
    container.register<CodReconcileApiRepository>(
      CodReconcileApiRepository,
      {
        useFactory: d => {
          return new CodReconcileApiRepository(
            d.resolve(ApiService),
            d.resolve(CodReconcileMapper),
            d.resolve(Endpoints),
          )
        }
      }
    );

    container.register<CollectibleCardApiRepository>(
      CollectibleCardApiRepository,
      {
        useFactory: d => {
          return new CollectibleCardApiRepository(
            d.resolve(ApiService),
            d.resolve(Endpoints),
            d.resolve(CollectibleCardMapper)
          );
        }
      }
    );
  }
}
