import moment from "moment";
import { AccountController } from "@/app/ui/controllers/AccountController";
import leadingZeroes from "@/app/infrastructures/misc/common-library/LeadingZeroes";
import formatDateTrackingHistory from "@/app/infrastructures/misc/common-library/FormatDateTrackingHistory";

const countdownTimer = (params: {
  startDate: string;
  endDate: string;
  format: string;
  callback: Function;
}) => {
  const format = params.format
    .split(":")
    .map(() => "--")
    .join(" : ");
  params.callback(moment(new Date()).format(format));

  const diffStart = new Date().getTime() - new Date(params.startDate).getTime();
  const diffEnd = new Date().getTime() - new Date(params.endDate).getTime();

  if (diffStart > 0 && diffEnd <= 0) {
    // Set the date we're counting down to
    const timezone =
      AccountController.accountData.account_type_detail.partnerLocation?.city
        ?.timezone;
    const countDownDate = new Date(params.endDate).getTime();

    // Update the count down every 1 second
    // reference : https://www.w3schools.com/howto/howto_js_countdown.asp
    const x = setInterval(() => {
      const now = new Date(
        formatDateTrackingHistory(new Date(), timezone, false)
      ).getTime();
      const distance = countDownDate - now;

      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      // If the count down is finished or less than 0, write some text
      if (distance < 0) {
        clearInterval(x);
        return;
      }

      // Display the result
      params.callback(
        leadingZeroes({ value: hours, max: 2 }) +
          " : " +
          leadingZeroes({ value: minutes, max: 2 })
      );
    }, 1000);
  }
};
export default countdownTimer;
