import { injectable } from "tsyringe";
import { BulkApiRepository } from "@/app/infrastructures/repositories/api/BulkApiRepository";
import { BulkDetail, BulkEntities } from "@/domain/entities/Bulk";
import { BulkUploadRequestInterface } from "@/data/payload/contracts/BulkRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { BulkUploadXlsxApiRequest } from "@/data/payload/api/BulkApiRequest";

@injectable()
export class BulkPresenter {
  private repository: BulkApiRepository;

  constructor(repository: BulkApiRepository) {
    this.repository = repository;
  }

  public getListBulk(
    activityName: string,
    archiveName: string,
    status: string,
    page: number,
    limit: number
  ): Promise<BulkEntities> {
    return this.repository.getBulkList(
      activityName,
      archiveName,
      status,
      page,
      limit
    );
  }

  public bulkUpload(
    payload: BulkUploadRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.bulkUpload(payload);
  }

  public bulkCustomerUpload(
    payload: BulkUploadRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.bulkCustomerUpload(payload);
  }

  public getDetailBulk(id: number): Promise<BulkDetail> {
    return this.repository.getDetail(id);
  }

  public getVersionName(search: string) {
    return this.repository.getVersionName(search);
  }

  public addBulkUploadRetailPrice(
    payload: BulkUploadXlsxApiRequest
  ): Promise<ResponsePayload> {
    return this.repository.addBulkUploadRetailPrice(payload);
  }
}
