import { ServiceDelayMapper } from "@/data/persistences/mappers/ServiceDelayMapper";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ServiceDelayRepositoryInterface } from "@/data/persistences/repositories/contracts/ServiceDelayRepositoryInterface";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ServiceDelayRequestInterface } from "@/data/payload/contracts/ServiceDelayRequest";
import { ServiceDelayApiRequest } from "@/data/payload/api/ServiceDelayApiRequest";

export class ServiceDelayApiRepository
  implements ServiceDelayRepositoryInterface {
  private service: ApiService;
  private mapper: ServiceDelayMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: ServiceDelayMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getList(params: ApiRequestList): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getServiceDelayList(params),
      {}
    );
    return this.mapper.convertListDataFromApi(resp);
  }

  public async getDetail(id: number): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getServiceDelayDetail(id),
      {}
    );
    return this.mapper.convertDetailDataFromApi(resp);
  }

  public async create(
    payload: ServiceDelayRequestInterface
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.serviceDelayForm(),
      undefined,
      payload as ServiceDelayApiRequest
    );
    return this.mapper.convertFormDataFromApi(resp);
  }

  public async edit(
    payload: ServiceDelayRequestInterface
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "PATCH",
      this.endpoints.getServiceDelayDetail(
        (payload as ServiceDelayApiRequest).payload.id
      ),
      undefined,
      payload as ServiceDelayApiRequest
    );
    return this.mapper.convertFormDataFromApi(resp);
  }
}
