import { Pagination, PaginationV2 } from "@/domain/entities/Pagination";
import {
  UrgentDeliveryManagementPODData,
  UrgentDeliveryManagementPODList,
  UrgentDeliveryManagementRTSData,
  UrgentDeliveryManagementRTSList,
  UrgentDeliveryManagementHALData,
  UrgentDeliveryManagementHALList,
  UrgentDeliveryManagementTakenByRecipientList,
  UrgentDeliveryManagementTakenByRecipient,
} from "@/domain/entities/UrgentDeliveryManagement";
import { AxiosResponse } from "axios";

export class UrgentDeliveryManagementMapper {
  convertPODListFromApi(resp: AxiosResponse): UrgentDeliveryManagementPODList {
    const {
      data: { data, meta }
    } = resp;

    return new UrgentDeliveryManagementPODList(
      data.map((pod: Record<string, string | number>) => {
        return new UrgentDeliveryManagementPODData({
          pdId: pod.pd_id as number,
          pdSTTNo: pod.pd_stt_no as string,
          pdFlag: pod.pd_flag as string,
          sttDeliveryType: pod.stt_delivery_type as string,
          pdReason: pod.pd_reason as string,
          pdDeadlineReturn: pod.pd_deadline_return as string,
          sttLastReturnId: pod.stt_last_status_id as string,
          sttDestinationCity: pod.stt_destination_city as string,
          sttRecipientName: pod.stt_recipient_name as string,
          sttRecipientPhone: pod.stt_recipient_phone as string,
          sttDestinationCityName: pod.stt_destination_city_name as string,
          sttDestinationDistrictName: pod.stt_destination_district_name as string,
          deliveryDEXAttempt: pod.delivery_dex_attempt as number,
          pdCreatedAt: pod.pd_created_at as string,
          pdUpdatedAt: pod.pd_updated_at as string
        });
      }),
      new Pagination(meta.page, meta.limit, meta.total_records)
    );
  }

  convertRTSListFromApi(resp: AxiosResponse): UrgentDeliveryManagementRTSList {
    const {
      data: { data, meta }
    } = resp;

    return new UrgentDeliveryManagementRTSList(
      data.map((rts: Record<string, string | number>) => {
        return new UrgentDeliveryManagementRTSData({
          pdId: rts.pd_id as number,
          pdSTTNo: rts.pd_stt_no as string,
          pdFlag: rts.pd_flag as string,
          sttDeliveryType: rts.stt_delivery_type as string,
          pdCaseType: rts.pd_case_type as string,
          pdReason: rts.pd_reason as string,
          pdDeadlineReturn: rts.pd_deadline_return as string,
          sttLastStatusId: rts.stt_last_status_id as string,
          sttOriginCityId: rts.stt_origin_city_id as string,
          sttOriginCityName: rts.stt_origin_city_name as string,
          sttDestinationCityId: rts.stt_destination_city_id as string,
          sttDestinationCityName: rts.stt_destination_city_name as string,
          pdCreatedAt: rts.pd_created_at as string,
          pdUpdatedAt: rts.pd_updated_at as string
        });
      }),
      new Pagination(meta.page, meta.limit, meta.total_records)
    );
  }

  convertHALListFromApi(resp: AxiosResponse): UrgentDeliveryManagementHALList {
    const {
      data: { data, meta }
    } = resp;

    return new UrgentDeliveryManagementHALList(
      data.map((hal: Record<string, string | number>) => {
        return new UrgentDeliveryManagementHALData({
          pdId: hal.pd_id as number,
          pdSTTNo: hal.pd_stt_no as string,
          pdFlag: hal.pd_flag as string,
          sttDeliveryType: hal.stt_delivery_type as string,
          pdCaseType: hal.pd_case_type as string,
          pdReason: hal.pd_reason as string,
          pdDeadlineReturn: hal.pd_deadline_return as string,
          sttLastStatusId: hal.stt_last_status_id as string,
          sttOriginCityId: hal.stt_origin_city_id as string,
          sttOriginCityName: hal.stt_origin_city_name as string,
          sttDestinationCityId: hal.stt_destination_city_id as string,
          sttDestinationCityName: hal.stt_destination_city_name as string,
          pdCreatedAt: hal.pd_created_at as string,
          pdUpdatedAt: hal.pd_updated_at as string,
          createdBy: hal.created_by as string,
        });
      }),
      new Pagination(meta.page, meta.limit, meta.total_records)
    );
  }

  convertListTakenByRecipient(resp: AxiosResponse<any>): UrgentDeliveryManagementTakenByRecipientList {
    const { data: { data, meta } } = resp;
    return new UrgentDeliveryManagementTakenByRecipientList({
      pagination: new PaginationV2({ page: meta.page, limit: meta.limit, totalData: meta.total_records }),
      data: !data.length ? [] : data.map((key: any) => new UrgentDeliveryManagementTakenByRecipient({
        pdId: key.pd_id,
        pdSttNo: key.pd_stt_no,
        sttProductType: key.stt_product_type,
        sttId: key.stt_id,
        sttIsCod: key.stt_is_cod,
        sttIsDfod: key.stt_is_dfod,
        sttLastStatusId: key.stt_last_status_id,
        sttDestinationCity: key.stt_destination_city,
        sttRecipientName: key.stt_recipient_name,
        sttRecipientPhone: key.stt_recipient_phone,
        sttDestinationCityName: key.stt_destination_city_name,
        sttDestinationDistrictName: key.stt_destination_district_name,
        pdCreatedAt: key.pd_created_at,
        pdUpdatedAt: key.pd_updated_at,
      }))
    })

  }
}
