/* eslint-disable @typescript-eslint/camelcase */
import { DexAssessmentMapper } from "@/data/persistences/mappers/DexAsessmentMapper";
import { DexAssessmentEndpoint } from "../../endpoints/horde/DexAssessmentEndpoint";
import ApiService from "../../services/ApiService";
import { DexAssessmentRepositoryInterface } from "@/data/persistences/repositories/contracts/DexAssessmentRepositoryInterface";
import {
  DexAssessmentList,
  DexAssessmentSummary,
  RequestDexAssessmentL1,
  RequestDexAssessmentSummary
} from "@/domain/entities/DexAssessment";
import {
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";
import { DexAssesmentRequestInterface } from "@/data/payload/contracts/DexAssessmentRequest";
import { SubmitDexAssessmentRequest } from "@/data/payload/api/DexAssessmentRequest";
import { ApiRequestList } from "@/domain/entities/MainApp";

export class DexAssessmentApiRepository
  implements DexAssessmentRepositoryInterface {
  private service: ApiService;
  private mapper: DexAssessmentMapper;
  private endpoints: DexAssessmentEndpoint;

  constructor(
    service: ApiService,
    mapper: DexAssessmentMapper,
    endpoints: DexAssessmentEndpoint
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  public async getDetailDexAssesment(id: any): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.detailDexAssessment(id),
      {}
    );
    return this.mapper.convertDetailDexAssementFromApi(resp);
  }
  public async submitTicketDexAssesmentL1(
    id: any,
    payload: DexAssesmentRequestInterface
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invokePostWithFormData(
      "patch",
      this.endpoints.submitTicketDexAssessment(id),
      {},
      payload as SubmitDexAssessmentRequest
    );
    return this.mapper.convertSubmitDexAssementFromApi(resp);
  }

  public async getDexAssessmentList(
    params: RequestDexAssessmentL1
  ): Promise<DexAssessmentList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListDexAssessment(params),
      {}
    );
    return this.mapper.convertDexAssessmentListFromApi(resp);
  }
  public async getDexAssessmentSummary(
    params: RequestDexAssessmentSummary
  ): Promise<DexAssessmentSummary> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSummaryDexAssessment(params),
      {}
    );
    return this.mapper.convertDexAssessmentSummaryDataFromApi(resp);
  }
  public async assignDexAssessment(): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.assignDexAssessment(),
      {}
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }
  public async getDownloadReport(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDownloadReport(params),
      {}
    );
    return this.mapper.convertSubmitDexAssementFromApi(resp);
  }
  public async getSummaryTicket(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSummaryTicket(params),
      {}
    );
    return this.mapper.convertSummaryTicketDataFromApi(resp);
  }
  public async getSummaryTotalCs(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSummaryTotalCs(params),
      {}
    );
    return this.mapper.convertSummaryTotalCsFromApi(resp);
  }
  public async getAgentList(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getAgentList(params),
      {}
    );
    return this.mapper.convertSummaryListCsDataFromApi(resp);
  }
  public async getSummaryTicketPercentageDisputeL2(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSummaryTicketPercentageDisputeL2(params),
      {}
    );
    return this.mapper.convertSummaryTicketPercentageDisputeL2(resp);
  }
  public async getSummaryTicketPercentage(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSummaryTicketPercentage(params),
      {}
    );
    return this.mapper.convertSummaryTicketPercentageDataFromApi(resp);
  }
  public async getSummaryTicketBasedRangeTime(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSummaryTicketBasedRangeTime(params)
    );
    return this.mapper.convertSummaryTicketBasedRangeTime(resp);
  }
  public async getSummaryFake(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSummaryFake(params),
      {}
    );
    return this.mapper.convertSummaryFakeDataFromApi(resp);
  }
  public async getSummaryTopDex(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSummaryTopDex(params),
      {}
    );
    return this.mapper.convertSummaryTopDexDataFromApi(resp);
  }
  public async getSummaryBacklog(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSummaryBacklog(params),
      {}
    );
    return this.mapper.convertSummaryBacklogDataFromApi(resp);
  }
  public async getTotalTicketPerDay(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getTotalTicketPerDay(params),
      {}
    );
    return this.mapper.convertTotalTicketPerDayDataFromApi(resp);
  }
  public async getSummaryAttempt(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSummaryAttempt(params),
      {}
    );
    return this.mapper.convertTotalTicketPerDayDataFromApi(resp);
  }
  public async getSummaryByReason(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSummaryByReason(params),
      {}
    );
    return this.mapper.convertSummaryBacklogDataFromApi(resp);
  }
  public async getDownload(params: ApiRequestList): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDownload(params),
      {}
    );
    return this.mapper.convertDownloadDataFromApi(resp);
  }
  public async getBacklogAgent(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getBacklogAgent(params),
      {}
    );
    return this.mapper.convertBacklogAgentDataFromApi(resp);
  }
  public async getDashboardConsole(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDashboardConsole(params),
      {}
    );

    if (params.endpoint.match(/^dex-courier$|^dex-courier-reason/gi)) {
      return this.mapper.convertSummaryTopDexDataFromApi(resp);
    } else if (params.endpoint.match(/download/gi)) {
      return this.mapper.convertDownloadDataFromApi(resp);
    } else {
      return this.mapper.convertSummaryTicketDataFromApi(resp);
    }
  }
}
