/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionPaidUnpaid = {
    dashboard_stt_payment_status_enable: new Rox.Flag(false),
    dashboard_stt_payment_status_view: new Rox.Flag(false)
};

export function initFlagsPermissionPaidUnpaid() {
    // register flags with namespaces for better grouping
    Rox.register("featurePaidUnpaid", FlagsPermissionPaidUnpaid);
}
