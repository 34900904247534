import {
  BalanceTransactionData,
  BalanceTransactionEntities,
  TransactionTypeGober
} from "@/domain/entities/BalanceTransaction";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";

export class BalanceTransactionMapper {
  public convertBalanceTransactionFromApi(
    apiVersion: string,
    result: AxiosResponse<any>
  ): BalanceTransactionEntities {
    const { data } = result;
    return new BalanceTransactionEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.totalRecords),
      data.data.length > 0
        ? data.data.map((item: any) => {
            if (apiVersion === "v1") {
              return new BalanceTransactionData({
                pgTrxId: item.pg_trx_id,
                pgTrxSttNo: item.pg_trx_stt_no,
                pgTrxNo: item.pg_trx_no,
                pgTrxType: item.pg_trx_type,
                pgTrxActivity: item.pg_trx_activity,
                pgTrxAmount: item.pg_trx_amount,
                pgTrxLastBalance: item.pg_trx_last_balance,
                pgTrxLastBalanceCod: item.pg_trx_balance_cod,
                pgTrxActorId: item.pg_trx_actor_id,
                pgTrxActorType: item.pg_trx_actor_type,
                pgTrxBeneficiaryName: item.pg_trx_beneficiary_name,
                pgTrxBeneficiaryAccount: item.pg_trx_beneficiary_account,
                pgTrxBeneficiaryBank: item.pg_trx_beneficiary_bank,
                pgTrxSessionId: item.pg_trx_session_id,
                pgTrxRefNo: item.pg_trx_ref_no,
                pgTrxStatus: item.pg_trx_status,
                pgTrxVa: item.pg_trx_va,
                pgTrxCreatedAt: item.pg_trx_created_at,
                pgTrxNote: item.pg_trx_note,
                pgTrxVaPartnerName: item.pg_trx_va_partner_name
              });
            } else {
              return new BalanceTransactionData({
                pgTrxId: item.tn_id,
                pgTrxSttNo: item.tn_stt_no,
                pgTrxNo: item.tn_no,
                pgTrxType: item.tn_type,
                pgTrxActivity: item.tn_activity,
                pgTrxAmount: item.tn_amount,
                pgTrxLastBalance: item.tn_last_balance,
                pgTrxLastBalanceCod: item.tn_balance_cod,
                pgTrxActorId: item.tn_actor_id,
                pgTrxActorType: item.tn_actor_type,
                pgTrxBeneficiaryName: item.tn_beneficiary_name,
                pgTrxBeneficiaryAccount: item.tn_beneficiary_account,
                pgTrxBeneficiaryBank: item.tn_beneficiary_bank,
                pgTrxSessionId: item.tn_session_id,
                pgTrxRefNo: item.tn_ref_no,
                pgTrxStatus: item.tn_status,
                pgTrxVa: item.tn_va,
                pgTrxCreatedAt: item.tn_created_at,
                pgTrxNote: item.tn_note,
                pgTrxVaPartnerName: item.tn_va_partner_name
              });
            }
          })
        : []
    );
  }

  public convertBalanceTransactionTypeFromApi(
    result: AxiosResponse
  ): TransactionTypeGober {
    const { data } = result;
    return new TransactionTypeGober({
      balanceClient: data.data.client_saldo,
      balanceCodClient: data.data.client_saldo_cod,
      balanceInternal: data.data.internal_saldo,
      balancePos: data.data.pos_saldo
    });
  }
}
