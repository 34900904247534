import {
  DetailInstantBookingData,
  DetailInstantBookingListData
} from "@/domain/entities/InstantBooking";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class InstantBookingMapper {
  public convertDetailInstantBookingFromApi(
    res: AxiosResponse<any>
  ): DetailInstantBookingData {
    const { data } = res;
    return new DetailInstantBookingData({
      ibId: data.data.ib_id,
      ibPosId: data.data.ib_pos_id,
      ibPosName: data.data.ib_pos_name,
      ibOriginCityId: data.data.ib_origin_city_id,
      ibOriginCityName: data.data.ib_origin_city_name,
      ibIsFinish: data.data.ib_is_finish,
      ibCreatedBy: data.data.ib_created_by,
      ibCreatedName: data.data.ib_created_name,
      ibCreatedAt: data.data.ib_created_at,
      bookingSuccess: data.data.booking_success
        ? data.data.booking_success.map(
            (item: any) =>
              new DetailInstantBookingListData({
                ibdId: item.ibd_id,
                ibdInstantBookingId: item.ibd_instant_booking_id,
                ibdShipmentId: item.ibd_shipment_id,
                ibdShipmentPackageId: item.ibd_shipment_package_id,
                ibdSttId: item.ibd_stt_id,
                ibdSttNo: item.ibd_stt_no,
                ibdIsSuccess: item.ibd_is_success,
                ibdErrorReason: item.ibd_error_reason,
                senderName: item.sender_name,
                senderPhone: item.sender_phone,
                recipientName: item.recipient_name,
                recipientPhone: item.recipient_phone,
                recipientAddress: item.recipient_address,
                piece: item.piece,
                chargeableWeight: item.chargeable_weight,
                totalTariff: item.total_tariff
              })
          )
        : [],
      bookingFailed: data.data.booking_failed
        ? data.data.booking_failed.map(
            (item: any) =>
              new DetailInstantBookingListData({
                ibdId: item.ibd_id,
                ibdInstantBookingId: item.ibd_instant_booking_id,
                ibdShipmentId: item.ibd_shipment_id,
                ibdShipmentPackageId: item.ibd_shipment_package_id,
                ibdSttId: item.ibd_stt_id,
                ibdSttNo: item.ibd_stt_no,
                ibdIsSuccess: item.ibd_is_success,
                ibdErrorReason: item.ibd_error_reason,
                senderName: item.sender_name,
                senderPhone: item.sender_phone,
                recipientName: item.recipient_name,
                recipientPhone: item.recipient_phone,
                recipientAddress: item.recipient_address,
                piece: item.piece,
                chargeableWeight: item.chargeable_weight,
                totalTariff: item.total_tariff
              })
          )
        : [],
      totalShipmentId: data.data.total_shipment_id,
      totalBookingSuccess: data.data.total_booking_success,
      totalBookingFailed: data.data.total_booking_failed
    });
  }

  public convertCreateInstantBookingSessionFromApi(
    res: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.content,
      data: data.data
    });
  }

  public convertPayloadData(result: AxiosResponse): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
