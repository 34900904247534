export class AuthAlgoEntities {
  token: string;
  type: string;
  expiredAt: string;
  constructor(token: string, type: string, expiredAt: string) {
    this.token = token;
    this.type = type;
    this.expiredAt = expiredAt;
  }
}
