import { BaggingGroupingApiRepository } from "@/app/infrastructures/repositories/api/BaggingGroupingApiRepository";
import { RequestBaggingGroupingList } from "@/data/payload/api/BaggingGroupingApiRequest";
import { BaggingGroupingListEntities } from "@/domain/entities/BaggingGrouping";
import { injectable } from "tsyringe";

@injectable()
export class BaggingGroupingPresenter {
  private repository: BaggingGroupingApiRepository;

  constructor(repository: BaggingGroupingApiRepository) {
    this.repository = repository;
  }

  public getBaggingGroupingList(
    params: RequestBaggingGroupingList
  ): Promise<BaggingGroupingListEntities> {
    return this.repository.getBaggingGroupingList(params);
  }
}
