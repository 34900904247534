import { TRACK_DELIVERY } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const DetailLacakPengiriman = () =>
  import(
    /* webpackChunkName: "TrackDelivery" */ "@/app/ui/views/shipment/booking/detail-booking/detail.vue"
  );

export default [
  {
    path: "/lacak-pengiriman",
    component: Layout,
    name: "lacak-pengiriman-detail",
    meta: {
      title: "Pengiriman | Detail",
      hidden: true,
      parent: "Pengiriman",
      permission: TRACK_DELIVERY.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "lacak-pengiriman-detail",
        component: DetailLacakPengiriman,
        meta: {
          header: true,
          isAuth: true,
          customPath: "lacak-pengiriman"
        }
      }
    ]
  }
];
