/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionMiddleware = {
    permission_middleware: new Rox.Flag(false),
    permission_middleware_create: new Rox.Flag(false),
    permission_middleware_edit: new Rox.Flag(false)
};

export function initFlagsPermissionMiddleware() {
  // register flags with namespaces for better grouping
  Rox.register("featureAdmin", FlagsPermissionMiddleware);
}
