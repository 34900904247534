import {
  AddDriverRequest,
  UpdatePickupRequest
} from "@/data/payload/api/PickupManifestApiRequest";
import {
  AddDriverRequestInterface,
  UpdatePickupRequestInterface
} from "@/data/payload/contracts/PickupManifestRequest";
import { PickupManifestMapper } from "@/data/persistences/mappers/PickupManifestMapper";
import { PickupManifestRepositoryInterface } from "@/data/persistences/repositories/contracts/PickupManifestRepositoryInterface";
import {
  PickupManifestEntities,
  Drivers,
  STTPiecesDetail,
  PrintDataList,
  DetailSTT,
  SttListChecklistEntities
} from "@/domain/entities/PickupManifest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class PickupManifestApiRepository
  implements PickupManifestRepositoryInterface {
  private service: ApiService;
  private mapper: PickupManifestMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: PickupManifestMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async addDriver(
    payload: AddDriverRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "post",
      this.endpoints.addDriver(),
      {},
      payload as AddDriverRequest
    );
    return this.mapper.convertDriverChangeDataFromApi(response);
  }

  public async getListPickupManifest(
    page: number,
    limit: number,
    startDate: string,
    endDate: string
  ): Promise<PickupManifestEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getPickupManifestList(page, limit, startDate, endDate),
      {}
    );
    return this.mapper.convertPickupManifestDataFromApi(resp);
  }

  public async getDrivers(): Promise<Drivers[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDrivers(),
      {}
    );
    return this.mapper.convertDriversFromApi(resp);
  }

  public async getDetailSTTPieces(sttPiece: string): Promise<STTPiecesDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailSTTPieces(sttPiece),
      {}
    );
    return this.mapper.convertSttPiecesDetailFromApi(resp);
  }

  public async validationScanSTTPiece(
    sttPiece: string
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.validationScanSTTPiece(sttPiece),
      {}
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async getPrintDataList(id: number): Promise<PrintDataList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getPrintDataPickupManifest(id),
      {}
    );
    return this.mapper.convertPrintDataListFromApi(resp);
  }

  public async updatePickup(
    payload: UpdatePickupRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "post",
      this.endpoints.updatePickupManifest(),
      {},
      payload as UpdatePickupRequest
    );
    return this.mapper.convertChangeDataFromApi(response);
  }

  public async getDetailStt(sttNumber: string): Promise<DetailSTT> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailPickupStt(sttNumber),
      {}
    );
    return this.mapper.convertDetailSttFromApi(resp);
  }

  public async getSttListChecklist(
    page: number,
    limit: number,
    status: string,
    date: string,
    sortBy: string,
    productType: string,
    cache: boolean
  ): Promise<SttListChecklistEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSttListSttChecklist(
        page,
        limit,
        status,
        date,
        sortBy,
        productType,
        cache
      ),
      {}
    );
    return this.mapper.convertSttListChecklistFromApi(resp);
  }
}
