import { injectable } from "tsyringe";
import { FirebaseApiRepository } from "@/app/infrastructures/repositories/api/FirebaseApiRepository";

@injectable()
export class FirebasePresenter {
  private repository: FirebaseApiRepository;

  constructor(repository: FirebaseApiRepository) {
    this.repository = repository;
  }

  public getTokenFirebase(): Promise<string> {
    return this.repository.getTokenFirebase();
  }

  public deleteTokenFirebase(): Promise<string> {
    return this.repository.deleteTokenFirebase();
  }

  public onMessageFirebase(callback: Function) {
    return this.repository.onMessageFirebase(callback);
  }
}