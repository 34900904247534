const formatValueNumber = (number: number) => {
  let result = Number.parseFloat(number?.toString())
    .toFixed(2)
    .replace(".", ",");
  if (result.split(",")[1] === "00") {
    result = result.split(",")[0];
  }
  return result;
};

export default formatValueNumber;
