const checkValue = (value: string) => {
  const regexPattern = /,\d{1}$/;
  const regexPatternMinimumValue = /,(0[1-9])$/;
  if (!value.includes(",")) {
    return "withoutComma";
  } else if (regexPattern.test(value)) {
    return "oneValueAfterComma";
  } else if (regexPatternMinimumValue.test(value)) {
    return "minimumValue";
  }
};

export default checkValue;
