import { ReportMapper } from "@/data/persistences/mappers/ReportMapper";
import { ReportRepositoryInterface } from "@/data/persistences/repositories/contracts/ReportRepositoryInterface";
import {
  ReportApiRequest,
  ReportListDownload,
  ReportListDownloadRequest,
  LastSyncSttReport
} from "@/domain/entities/Report";
import { Endpoints } from "../../misc/Endpoints";
import { downloadFile } from "../../misc/Utils";
import ApiService from "../../services/ApiService";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";

export class ReportApiRepository implements ReportRepositoryInterface {
  private service: ApiService;
  private mapper: ReportMapper;
  private endpoints: Endpoints;

  constructor(service: ApiService, mapper: ReportMapper, endpoints: Endpoints) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async getDownloadReport(params: ReportApiRequest): Promise<any> {
    const startDate = params.startDate
      ? new Date(params.startDate)
      : new Date();
    if (startDate.getFullYear() <= new Date().getFullYear() - 2) {
      dataLayer(
        "report_query_year_archived",
        {
          reportTitle: params.title
        },
        ["userId", "userType"]
      );
    }
    if (params.isCheck) {
      const resp = await this.service.invoke(
        "get",
        this.endpoints.getDownloadReport(params),
        {}
      );
      return this.mapper.convertCheckDataFromApi(resp);
    }
    if (params.async) {
      const resp = await this.service.invoke(
        "get",
        this.endpoints.getRequestDownloadReport(params),
        {}
      );
      return this.mapper.convertCheckDataFromApi(resp);
    }
    return downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.getDownloadReport(params)}`,
      `${params.fileName || params.title}_report_${params.startDate}_${
        params.endDate
      }`,
      `.${params.extension}`,
      true
    );
  }

  async getListDownloadReport(
    params: ReportListDownloadRequest
  ): Promise<ReportListDownload> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListDownloadReport(params),
      {}
    );
    return this.mapper.convertListDownloadReportFromApi(resp);
  }

  async getLastSyncSttReport(): Promise<LastSyncSttReport> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getLastSyncSttReport(),
      {}
    );
    return this.mapper.convertLastSyncSttReportFromApi(resp);
  }
}
