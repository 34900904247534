import { NOTIFICATION } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const Notification = () =>
  import(
    /* webpackChunkName: "Notification" */ "@/app/ui/views/notification/index.vue"
  );
const DetailNotification = () =>
  import(
    /* webpackChunkName: "Notification" */ "@/app/ui/views/notification/detail.vue"
  );

export default [
  {
    path: "/notifications",
    component: Layout,
    name: "notifications",
    meta: {
      children: false,
      title: "Notifikasi",
      name: "Notifikasi",
      hidden: true,
      permission: NOTIFICATION.ENABLE
    },
    children: [
      {
        path: "/notifications",
        name: "Notifikasi",
        component: Notification,
        meta: {
          header: true,
          permission: NOTIFICATION.LIST
        }
      },
      {
        path: ":id",
        name: "detail-notification",
        component: DetailNotification,
        meta: {
          header: true,
          title: "Genesis - Notifikasi | Detail",
          permission: NOTIFICATION.DETAIL
        }
      }
    ]
  }
];
