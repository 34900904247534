import { GoodsTaxMapper } from "@/data/persistences/mappers/GoodsTaxMapper";
import { GoodsTaxRepositoryInterface } from "@/data/persistences/repositories/contracts/GoodsTaxRepositoryInteface";
import { GoodsTax, GoodsTaxList } from "@/domain/entities/GoodsTax";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  AddGoodsTaxRequestInterface,
  EditGoodsTaxRequestInterface
} from "@/data/payload/contracts/GoodsTaxRequest";
import {
  AddGoodsTaxApiRequest,
  EditGoodsTaxApiRequest
} from "@/data/payload/api/GoodsTaxApiRequest";
import { CityEntities } from "@/domain/entities/Location";

export class GoodsTaxApiRepository implements GoodsTaxRepositoryInterface {
  private service: ApiService;
  private mapper: GoodsTaxMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: GoodsTaxMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getDetailGoodsTax(id: number): Promise<GoodsTax> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailGoodsTax(id),
      {}
    );
    return this.mapper.convertDetailGoodsTaxFromApi(resp);
  }

  public async getGoodsTaxList(
    cityCode: string,
    status: string,
    page: number,
    limit: number,
    commodityCode: string
  ): Promise<GoodsTaxList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getGoodsTaxList(
        cityCode,
        status,
        page,
        limit,
        commodityCode
      ),
      {}
    );
    return this.mapper.convertGoodsTaxDataFromApi(resp);
  }

  public async addGoodsTax(
    payload: AddGoodsTaxRequestInterface
  ): Promise<ResponsePayload> {
    const res = await this.service.invokePrivate(
      "post",
      this.endpoints.addGoodsTax(),
      undefined,
      payload as AddGoodsTaxApiRequest
    );
    return this.mapper.convertPayloadData(res);
  }
  public async editGoodsTax(
    payload: EditGoodsTaxRequestInterface
  ): Promise<ResponsePayload> {
    const res = await this.service.invokePrivate(
      "patch",
      this.endpoints.editGoodsTax(),
      undefined,
      payload as EditGoodsTaxApiRequest
    );
    return this.mapper.convertPayloadData(res);
  }
  public async getCityList(
    search: string,
    status: string,
    page: number,
    limit: number,
    freeTradeZone: string
  ): Promise<CityEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListCityFTZ(search, status, page, limit, freeTradeZone),
      {}
    );
    return this.mapper.convertCityDataFromApi(resp);
  }
}
