import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { DetailBonusKomisi } from "@/domain/entities/ProgressiveCommissionDashboard";
import { BonusKomisiDetailRequest } from "@/data/payload/api/ProgressiveCommissionDashboardRequest";
import { ProgressiveCommissionDashboardInterface } from "@/data/persistences/repositories/contracts/ProgressiveCommissionDashboardInterface";
import { ProgressiveCommissionDashboardMapper } from "@/data/persistences/mappers/ProgressiveCommissionDashboardMapper";

export class ProgressiveCommissionDashboardApiRepository
  implements ProgressiveCommissionDashboardInterface {
  private service: ApiService;
  private mapper: ProgressiveCommissionDashboardMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: ProgressiveCommissionDashboardMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async getBonusKomisiDetail(
    params: BonusKomisiDetailRequest
  ): Promise<DetailBonusKomisi> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getBonusKomisiDetail(params)
    );

    return this.mapper.convertDetailBonusKomisiFromApi(res);
  }
}
