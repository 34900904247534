import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  BalanceEntities,
  BalanceData,
  BalanceDetailData
} from "@/domain/entities/BalanceLimit";
import { AxiosResponse } from "axios";

export class BalanceLimitMapper {
  public convertBalanceDataFromApi(res: AxiosResponse<any>): BalanceEntities {
    const { data } = res;

    const balanceLimitRules: BalanceData[] = [];
    if (data.data.length === 0) {
      return new BalanceEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        balanceLimitRules.push(
          new BalanceData(
            item.limit_rule_id,
            item.limit_rule_name,
            item.limit_rule_description,
            item.limit_rule_applied_to,
            item.limit_rule_min_balance,
            item.limit_rule_min_withdraw_amount,
            item.limit_rule_status,
            item.meta_data_limit_rule.is_cod_delivery || false
          )
        );
      });
    }
    return new BalanceEntities(
      new Pagination(data.meta.page, data.meta.limit),
      balanceLimitRules
    );
  }

  public convertDetailBalanceDataFromApi(
    res: AxiosResponse<any>
  ): BalanceDetailData {
    const {
      data: { data }
    } = res;
    return new BalanceDetailData(
      data.limit_rule_id,
      data.limit_rule_name,
      data.limit_rule_description,
      data.limit_rule_applied_to,
      data.limit_rule_min_balance,
      data.limit_rule_limit_border,
      data.limit_rule_min_withdraw_amount,
      data.limit_rule_min_balance_currency_myr,
      data.limit_rule_limit_border_currency_myr,
      data.limit_rule_min_withdraw_amount_currency_myr,
      data.limit_rule_status,
      data.limit_rule_created_at,
      data.limit_rule_updated_at,
      data.limit_rule_created_by,
      data.limit_rule_updated_by,
      data.meta_data_limit_rule.is_cod_delivery
    );
  }

  public convertPayloadData(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
