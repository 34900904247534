import {
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import {
  SttNumberBaggingDetail,
  SttNumberDetail,
  SttPieceDetail,
  BaggingEntities,
  BaggingData,
  BaggingDataDetail,
  SttResult,
  SttPiece,
  CreateBaggingResponse,
  BaggingSttList,
  RegionBaggingGrouping
} from "@/domain/entities/Bagging";
import { Pagination } from "@/domain/entities/Pagination";

export class BaggingMapper {
  public convertBaggingDataFromApi(res: AxiosResponse<any>): BaggingEntities {
    const { data } = res;

    const bagging: BaggingData[] = [];
    if (data.data.length === 0) {
      return new BaggingEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        bagging.push(
          new BaggingData({
            bagId: item.bag_id,
            bagCode: item.bag_code,
            bagPartnerId: item.bag_partner_id,
            bagPartnerCode: item.bag_partner_code,
            bagPartnerName: item.bag_partner_name,
            bagOriginCityCode: item.bag_origin_city_code,
            bagOriginCityName: item.bag_origin_city_name,
            bagOriginTransitCityCode: item.bag_origin_transit_city_code,
            bagOriginTransitCityName: item.bag_origin_transit_city_name,
            bagDestinationCityCode: item.bag_destination_city_code,
            bagDestinationCityName: item.bag_destination_city_name,
            bagTotalStt: item.bag_total_stt,
            bagTotalGrossWeight: item.bag_total_gross_weight,
            bagTotalVolumeWeight: item.bag_total_volume_weight,
            bagTotalChargeableWeight: item.bag_total_chargeable_weight,
            bagTotalPiece: item.bag_total_piece,
            bagCreatedAt: item.bag_created_at,
            bagCreatedBy: item.bag_created_by,
            bagCreatedName: item.bag_created_name,
            bagCustomGrossWeight: item.bag_custom_gross_weight,
            bagCustomVolumeWeight: item.bag_custom_volume_weight,
            userType: item.user_type,
            isCargo: item.is_cargo,
            isAlreadyRTC: item.is_rtc,
            isRtcProcess: item.is_rtc_process,
            isCheckboxAlreadyRTC: item.is_rtc
          })
        );
      });
    }
    return new BaggingEntities(
      new Pagination(data.meta.page, data.meta.limit),
      bagging
    );
  }

  public convertBaggingDetailFromApi(
    res: AxiosResponse<any>
  ): BaggingDataDetail {
    const {
      data: { data }
    } = res;
    return new BaggingDataDetail({
      bagNumber: data.bag_number,
      partnerName: data.partner_name,
      originCity: data.origin_city,
      destinationCity: data.destination_city,
      partnerType: data.partner_type,
      baggingDate: data.bagging_date,
      baggingCreatedBy: data.bagging_created_by,
      totalStt: data.total_stt,
      totalGrossWeight: data.total_gross_weight,
      totalBagGrossWeight: data.total_bag_gross_weight,
      totalBagChargeableWeight: data.total_bag_chargeable_weight,
      totalPiece: data.total_piece,
      totalVolumeWeight: data.total_volume_weight,
      totalBagVolumeWeight: data.total_bag_volume_weight,
      bagVendorNo: data.bag_vendor_no,
      bagCustomHeight: data.bag_custom_height,
      bagCustomLength: data.bag_custom_length,
      bagCustomWidth: data.bag_custom_width,
      sttResult: data.stt.map(
        (item: any) =>
          new SttResult({
            sttId: item.stt_id,
            sttNo: item.stt_no,
            sttProductType: item.stt_product_type,
            sttTotalPiece: item.stt_total_piece,
            sttDestinationCityId: item.stt_destination_city_id,
            sttOriginCityId: item.stt_origin_city_id,
            sttDestinationCityName: item.stt_destination_name,
            sttWoodPacking: item.stt_wood_packing,
            sttCommodityCode: item.stt_commodity_code,
            sttCommodityName: item.stt_commodity_name,
            sttGrossWeight: item.stt_gross_weight,
            sttVolumeWeight: item.stt_volume_weight,
            sttChargeableWeight: item.stt_chargeable_weight,
            sttCodAmount: item.stt_cod_amount,
            sttLastStatusId: item.stt_last_status_id,
            bookedAt: item.booked_at,
            sttNoRefExternal: !item.stt_no_ref_external
              ? "-"
              : item.stt_no_ref_external,
            bagVendorNo: item.bag_vendor_no,
            sttPiece: item.piece.map(
              (val: any) =>
                new SttPiece(
                  val.stt_piece_id,
                  val.stt_piece_gross_weight,
                  val.stt_piece_volume_weight,
                  val.stt_piece_no,
                  val.stt_piece_last_status_id
                )
            ),
            paymentStatus: item.stt_payment_status,
            sttNumberElexys: item.stt_elexys_no,
            isDangerousGoods: item.is_dangerous_goods
          })
      ),
      partnerId: data.partner_id || 0,
      isPaid: data.is_paid
    });
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: new CreateBaggingResponse(
        data.data.bag_id,
        data.data.bag_code,
        data.data.total_stt_success,
        data.data.total_stt_failed,
        data.data.stt_failed
      )
    });
  }

  public convertUpdateBaggingRTCFromApi(
    result: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const { data } = result;
    return new ResponsePayloadV2({
      success: data.success,
      message: data.message,
      code: data.code
    });
  }

  public convertSttNumberBaggingDetailFromApi(
    res: AxiosResponse<any>
  ): BaggingSttList {
    const {
      data: { data }
    } = res;
    const baggingSttList: SttNumberBaggingDetail[] = [];
    if (data === null) {
      return new BaggingSttList([]);
    } else {
      data.map((item: any) =>
        baggingSttList.push(
          new SttNumberBaggingDetail({
            isAllowUpdateStatus: item.is_allow_update_status,
            errorMessage: item.error_message,
            regionBaggingGrouping: new RegionBaggingGrouping({
              baggingRegionOriginId: item.bagging_region_partner_id,
              baggingRegionOriginName: item.bagging_region_partner_name,
              baggingRegionDestinationId: item.bagging_region_destination_id,
              baggingRegionDestinationName: item.bagging_region_destination_name
            }),
            stt: new SttNumberDetail({
              sttId: item.stt.stt_id,
              sttNo: item.stt.stt_no,
              sttProductType: item.stt.stt_product_type,
              sttTotalPiece: item.stt.stt_total_piece,
              sttDestinationCityId: item.stt.stt_destination_city_id,
              sttDestinationCityName: item.stt.stt_destination_city_name,
              sttWoodPacking: item.stt.stt_wood_packing,
              sttCommodityCode: item.stt.stt_commodity_code,
              sttCommodityName: item.stt.stt_commodity_name,
              sttCommodityGroupCode: item.stt.stt_commodity_group_code,
              sttCommodityGroupName: item.stt.stt_commodity_group_name,
              sttGrossWeight: item.stt.stt_gross_weight,
              sttVolumeWeight: item.stt.stt_volume_weight,
              sttChargeableWeight: item.stt.stt_chargeable_weight,
              sttCodAmount: item.stt.stt_cod_amount,
              sttLastStatusId: item.stt.stt_last_status_id,
              bookedAt: item.stt.booked_at,
              piece: item.stt.piece
                ? item.stt.piece.map(
                    (el: any) =>
                      new SttPieceDetail(
                        el.stt_piece_id,
                        el.stt_piece_gross_weight,
                        el.stt_piece_volume_weight,
                        el.stt_piece_no,
                        el.stt_piece_last_status_id
                      )
                  )
                : [],
              sttNumberElexys: item.stt.stt_elexys_no || "",
              paymentStatus: item.stt_payment_status,
              bagVendorNo: item.bag_vendor_no,
              sttNoRefExternal: item.stt_no_ref_external,
              isDangerousGoods: item.stt.is_dangerous_goods,
              sttAssessmentStatus: item.stt.stt_assessment_status
            }),
            isPaid: item.is_paid
          })
        )
      );
      return new BaggingSttList(baggingSttList);
    }
  }

  public convertRegionBaggingGroupingData(
    res: AxiosResponse<any>
  ): RegionBaggingGrouping {
    const {
      data: { data }
    } = res;

    return new RegionBaggingGrouping({
      baggingRegionOriginId: data.bagging_region_partner_id,
      baggingRegionOriginName: data.bagging_region_partner_name,
      baggingRegionDestinationId: data.bagging_region_destination_id,
      baggingRegionDestinationName: data.bagging_region_destination_name
    });
  }
}
