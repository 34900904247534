import { AxiosResponse } from "axios";
import {
  DexAssessmentData,
  DexAssessmentList,
  DexAssessmentSummary,
  SummaryBacklog,
  SummaryBacklogDatasets,
  SummaryFake,
  SummaryTicket,
  SummaryTicketPercentageDispute,
  SummaryTicketDexAssesmentBasedRangeTime,
  SummaryTicketPercentage,
  SummaryTopDex,
  SummaryTotalDexPerDay,
  SummaryTotalCs,
  SpeedDexAssessment,
  DoneDexAssessment,
  SummaryListCs,
  BacklogAgentCS,
  DatasetsBacklog,
  assessmentData
} from "@/domain/entities/DexAssessment";
import { Pagination } from "@/domain/entities/Pagination";
import {
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";

const onMappingDetailData = (item: any) => ({
  daPhotoProofAssessment: item.da_photo_proof_assessment
    ?.toLowerCase()
    .includes("invalid"),
  daReasonDexAssessment: item.da_reason_dex_assessment
    ?.toLowerCase()
    .includes("invalid"),
  daLocationDexAssessment: item.da_location_dex_assessment
    ?.toLowerCase()
    .includes("invalid"),
  daSourceDexAssessment: item.da_source_dex_assessment
    ?.toLowerCase()
    .includes("invalid"),
  daPhotoProofAssessmentFromSystem: item.da_photo_proof_assessment
    ?.toLowerCase()
    .includes("system"),
  daReasonDexAssessmentFromSystem: item.da_reason_dex_assessment
    ?.toLowerCase()
    .includes("system"),
  daLocationDexAssessmentFromSystem: item.da_location_dex_assessment
    ?.toLowerCase()
    .includes("system")
});
const onMappingDetailDataNext = (item: any) => ({
  daStatusDisputePartner: item.da_status_dispute_partner,
  daSenderName: item.da_stt_sender_name || "-",
  daSenderPhone: item.da_stt_sender_phone || "-",
  daRecipientName: item.da_stt_recipient_name || "-",
  daRecipientPhone: item.da_stt_recipient_phone || "-",
  daDeliveryCourierName: item.da_delivery_courier_name || "-",
  daDeliveryCourierPhone: item.da_delivery_courier_phone || "-"
});
const onMappingDetailDataNext2 = (item: any) => ({
  daSourceDexAssessmenFromSystemt: item.da_source_dex_assessment
    ?.toLowerCase()
    .includes("system"),
  daRemarksL2: item.da_remarks_l2,
  daRemarksPartner: item.da_remarks_partner,
  daRemarksL2Dispute: item.da_remarks_l2_dispute,
  daProofPhotoDisputePartner: item.da_proof_photo_dispute_partner
    ? item.da_proof_photo_dispute_partner.split(",")
    : [],
  daStatus: item.da_status,
  daDeliveryCaptainCourierName: item.da_delivery_captain_courier_name,
  daDeliveryCaptainCourierPhone: item.da_delivery_captain_courier_phone,
  daDisputeCustomer: item.da_dispute_customer,
  daFinishedPartnerDate: item.da_finished_partner_date,
  daFinishedL2DisputeDate: item.da_finished_l2_dispute_date,
  daFinishedL2Date: item.da_finished_l2_date,
  daTimeLeftPartnerDate: item.da_time_left_partner_date,
  daAssignedPartnerName: item.da_assigned_partner_name,
  daAssignedPartnerPhone: item.da_assigned_partner_phone,
  daNeedToRTS: item.da_need_to_rts,
  daAssignedL2DisputeAccountId: item.da_assigned_l2_dispute_account_id,
  daAssignedL2AccountId: item.da_assigned_l2_account_id,
  daSttRecipientAddress: item.da_stt_recipient_address,
  daDestinationDistrictName: item.da_stt_destination_district_name,
  daCreatedAt: item.da_created_at,
  daAssignedL1Name: item.da_assigned_l1_name,
  daAssignedL1Date: item.da_assigned_l1_date,
  daFinishedL1Date: item.da_finished_l1_date,
  daAssignedL2Name: item.da_assigned_l2_name,
  daAssignedL2Date: item.da_assigned_l2_date,
  daAssignedPartnerDate: item.da_assigned_partner_date,
  daAssignedL2DisputeName: item.da_assigned_l2_dispute_name,
  daAssignedL2DisputeDate: item.da_assigned_l2_dispute_date,
  daStatusAssesBy: item.da_status_assess_by?.match(/^L1$|^L2$/gi)
    ? item.da_status_assess_by
    : "-"
});
export class DexAssessmentMapper {
  public convertDexAssessmentListFromApi(
    res: AxiosResponse<any>
  ): DexAssessmentList {
    const { data } = res;

    return new DexAssessmentList({
      pagination: new Pagination(
        data.meta.page,
        data.meta.limit,
        data.meta.total_records
      ),
      data: data.data?.length
        ? data.data.map(
            (item: any) =>
              new DexAssessmentData({
                ...onMappingDetailData(item),
                ...onMappingDetailDataNext(item),
                ...onMappingDetailDataNext2(item),
                daId: item.da_id,
                daTicketNo: item.da_ticket_no,
                daSttId: item.da_stt_id,
                daSttNo: item.da_stt_no,
                daProductType: item.da_stt_product_type,
                daOriginId: item.da_stt_origin_city_id,
                daOriginName: item.da_stt_origin_city_name,
                daDestinationId: item.da_stt_destination_city_id,
                daDestinationName: item.da_stt_destination_city_name,
                daSttRecipientAddressType:
                  item.da_stt_recipient_address_type === "office"
                    ? "Kantor"
                    : "Rumah",
                daSttSla: item.da_stt_sla,
                daDeliveryProofPhoto: item.da_delivery_proof_photo_dex
                  ? item.da_delivery_proof_photo_dex.split(",")
                  : [],
                daDeliveryReasonCode: item.da_delivery_reason_code,
                daDeliveryReasonCategory: item.da_delivery_reason_category,
                daDeliveryReasonTitle: item.da_delivery_reason_title,
                daDeliveryDexDate: item.da_delivery_dex_date,
                daDeliveryDexDateLocal: item.da_delivery_dex_date_local,
                daDeliveryDexAttempt: item.da_delivery_dex_attempt,
                daDeliveryGeolocationDex: item.da_delivery_geolocation_dex,
                daDeliveryAddressDex: item.da_delivery_address_dex,
                daSourceDex: item.da_source_dex
              })
          )
        : []
    });
  }

  public convertDexAssessmentSummaryDataFromApi(
    res: AxiosResponse<any>
  ): DexAssessmentSummary {
    const { data } = res;
    return new DexAssessmentSummary({
      daTicketTotal: data.data.ticket_total || 0,
      daTicketQuota: data.data.ticket_quota || 0,
      daTicketFinished: data.data.ticket_finished || 0,
      daTicketInvalidToday: data.data.ticket_invalid_today || 0,
      daTicketValidToday: data.data.ticket_valid_today || 0,
      daTicketDisputeToday: data.data.ticket_dispute_today || 0,
      ticketInvalid: data.data.ticket_invalid || 0,
      ticketDisputeAccepted: data.data.ticket_dispute_accepted || 0,
      ticketDisputeApproved: data.data.ticket_dispute_approved || 0,
      ticketDisputeRejected: data.data.ticket_dispute_rejected || 0,
      ticketDisputeRequested: data.data.ticket_dispute_requested || 0
    });
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertSubmitDexAssementFromApi(
    result: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const { data } = result;
    return new ResponsePayloadV2({
      code: data.code,
      success: data.success,
      message: data.message
    });
  }

  public convertDetailDexAssementFromApi(
    result: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const { data } = result;
    return new ResponsePayloadV2({
      code: data.code,
      success: data.success,
      message: data.message,
      data: new DexAssessmentData({
        daId: data.data.da_id,
        daTicketNo: data.data.da_ticket_no,
        daSttId: data.data.da_stt_id,
        daSttNo: data.data.da_stt_no,
        daProductType: data.data.da_stt_product_type,
        daOriginId: data.data.da_stt_origin_city_id,
        daOriginName: data.data.da_stt_origin_city_name,
        daDestinationId: data.data.da_stt_destination_city_id,
        daDestinationName: data.data.da_stt_destination_city_name,
        daDestinationDistrictId: data.data.da_stt_destination_district_id,
        daDestinationDistrictName: data.data.da_stt_destination_district_name,
        daSenderName: data.data.da_stt_sender_name,
        daSenderPhone: data.data.da_stt_sender_phone,
        daRecipientName: data.data.da_stt_recipient_name,
        daRecipientPhone: data.data.da_stt_recipient_phone,
        daSttRecipientAddressType: data.data.da_stt_recipient_address_type,
        daSttRecipientAddress: data.data.da_stt_recipient_address,
        daSttSla: data.data.da_stt_sla?.toLowerCase().includes("on")
          ? "Dalam SLA"
          : "Melewati SLA",
        daDeliveryId: data.data.da_delivery_id,
        daDeliveryCourierName: data.data.da_delivery_courier_name,
        daDeliveryCourierPhone: data.data.da_delivery_courier_phone,
        daDeliveryProofPhoto: data.data.da_delivery_proof_photo_dex
          ? data.data.da_delivery_proof_photo_dex.split(",")
          : [],
        daDeliveryReasonCode: data.data.da_delivery_reason_code,
        daDeliveryReasonCategory: data.data.da_delivery_reason_category,
        daDeliveryReasonTitle: data.data.da_delivery_reason_title,
        daDeliveryDexDate: data.data.da_delivery_dex_date,
        daDeliveryDexDateLocal: data.data.da_delivery_dex_date_local,
        daDeliveryDexAttempt: data.data.da_delivery_dex_attempt,
        daDeliveryGeolocationDex: data.data.da_delivery_geolocation_dex,
        daDeliveryAddressDex: data.data.da_delivery_address_dex,
        daDeliveryCaptainCourierName:
          data.data.da_delivery_captain_courier_name,
        daDeliveryCaptainCourierPhone:
          data.data.da_delivery_captain_courier_phone,
        daDisputeCustomer:
          data.data.da_dispute_customer > 0 || data.data.da_dispute_customer
            ? "Yes"
            : "No",
        daSourceDex: data.data.da_source_dex,
        daPhotoProofAssessment: data.data.da_photo_proof_assessment
          ?.toLowerCase()
          .includes("invalid")
          ? true
          : false,
        daReasonDexAssessment: data.data.da_reason_dex_assessment
          ?.toLowerCase()
          .includes("invalid")
          ? true
          : false,
        daLocationDexAssessment: data.data.da_location_dex_assessment
          ?.toLowerCase()
          .includes("invalid")
          ? true
          : false,
        daSourceDexAssessment: data.data.da_source_dex_assessment
          ?.toLowerCase()
          .includes("invalid")
          ? true
          : false,
        daRemarksL2: data.data.da_remarks_l2 || "",
        daRemarksPartner: data.data.da_remarks_partner || "",
        daRemarksL2Dispute: data.data.da_remarks_l2_dispute || "",
        daNeedToRTS:
          data.data.da_need_to_rts > 0 || data.data.da_need_to_rts
            ? true
            : false,
        daProofPhotoDisputePartner: data.data.da_proof_photo_dispute_partner
          ? data.data.da_proof_photo_dispute_partner.split(",")
          : [],
        daAssignedL1AccountId: data.data.da_assigned_l1_account_id,
        daAssignedL1Name: data.data.da_assigned_l1_name,
        daAssignedL1Date: data.data.da_assigned_l1_date,
        daFinishedL1Date: data.data.da_finished_l1_date,
        daAssignedL2AccountId: data.data.da_assigned_l2_account_id,
        daAssignedL2Name: data.data.da_assigned_l2_name,
        daAssignedL2Date: data.data.da_assigned_l2_date,
        daFinishedL2Date: data.data.da_finished_l2_date,
        daAssignedPartnerId: data.data.da_assigned_partner_id,
        daAssignedPartnerName: data.data.da_assigned_partner_name,
        daAssignedPartnerPhone: data.data.da_assigned_partner_phone,
        daAssignedPartnerDate: data.data.da_assigned_partner_date,
        daFinishedPartnerDate: data.data.da_finished_partner_date || "",
        daTimeLeftPartnerDate: data.data.da_time_left_partner_date,
        daAssignedL2DisputeAccountId:
          data.data.da_assigned_l2_dispute_account_id,
        daAssignedL2DisputeName: data.data.da_assigned_l2_dispute_name,
        daAssignedL2DisputeDate: data.data.da_assigned_l2_dispute_date,
        daFinishedL2DisputeDate: data.data.da_finished_l2_dispute_date,
        daStatusDisputePartner:
          data.data.da_status_dispute_partner !== "accepted"
            ? data.data.da_status_dispute_partner
            : "",
        daStatus: data.data.da_status,
        daCreatedAt: data.data.da_created_at,
        daUpdatedAt: data.data.da_updated_at,
        daMeta: data.data.da_meta,
        daPhotoProofAssessBy: data.data.da_photo_proof_assess_by?.match(
          /^L1$|^L2$|^system$/gi
        )
          ? assessmentData[data.data.da_photo_proof_assess_by?.toLowerCase()]
          : "",
        daReasonDexAssessBy: data.data.da_reason_dex_assess_by?.match(
          /^L1$|^L2$|^system$/gi
        )
          ? assessmentData[data.data.da_reason_dex_assess_by?.toLowerCase()]
          : "",
        daLocationDexAssessBy: data.data.da_location_dex_assess_by?.match(
          /^L1$|^L2$|^system$/gi
        )
          ? assessmentData[data.data.da_location_dex_assess_by?.toLowerCase()]
          : "",
        daSourceDexAssessBy: data.data.da_source_dex_assess_by?.match(
          /^L1$|^L2$|^system$/gi
        )
          ? assessmentData[data.data.da_source_dex_assess_by?.toLowerCase()]
          : "",
        daStatusDexAssessBy: data.data.da_status_assess_by?.match(/^L1$|^L2$/gi)
          ? assessmentData[data.data.da_status_assess_by?.toLowerCase()]
          : ""
      })
    });
  }

  public convertSummaryTicketDataFromApi(
    res: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = res;
    return new ResponsePayloadV2({
      data: new SummaryTicket({
        ticketTotal: data.ticket_total,
        ticketQueue: data.ticket_queue,
        ticketAssigned: data.ticket_assigned,
        ticketDone: data.ticket_done,
        percentageTicketQueue: data.percentage_ticket_queue,
        percentageTicketAssigned: data.percentage_ticket_assigned,
        percentageTicketDone: data.percentage_ticket_done,
        averageQueueTime: Math.round(data.average_queue_time),
        maxQueueTime: Math.round(data.max_queue_time),
        averageDoneTime: Math.round(data.average_done_time),
        maxDoneTime: Math.round(data.max_done_time),
        totalInvalid: data.total_invalid,
        totalRequested: data.total_requested,
        totalRejected: data.total_rejected,
        totalApproved: data.total_approved,
        percentageRequested: data.percentage_requested,
        percentageRejected: data.percentage_rejected,
        percentageApproved: data.percentage_approved,
        sourceDexGenesis: data.source_dex_genesis,
        sourceDexDriverApp: data.source_dex_driver_app,
        percentageSourceDexGenesis: data.percentage_source_dex_genesis,
        percentageSourceDexDriverApp: data.percentage_source_dex_driver_app,
        totalDexOneAttempt: data.total_dex_one_attempt,
        totalDexSecondAttempt: data.total_dex_second_attempt,
        totalDexMoreThanEqThirdAttempt:
          data.total_dex_more_than_eq_third_attempt,
        percentageDexOneAttempt: data.percentage_dex_one_attempt,
        percentageDexSecondAttempt: data.percentage_dex_second_attempt,
        percentageDexMoreThanEqThirdAttempt: data.percentage_dex_more_than_eq_third_attempt,
        dexReasonLabels: data.labels?.length ? data.labels : [],
        dexReasonDataset: data.datasets?.length ? data.datasets.map(
          (dataset: any) =>
            new SummaryBacklogDatasets({
              label: dataset.label,
              data: dataset.data
            })
        ) : [],
        dexReasonTotalDex: data.total_dex,
        totalRts: data.total_rts,
        percentageRts: data.percentage_rts,
        sttIncoming: data.stt_incoming,
        totalDex: data.total_dex,
        totalDexValid: data.total_valid,
        totalDexInvalid: data.total_invalid,
        percentageDex: data.percentage_dex,
        percentageDexValid: data.percentage_valid,
        percentageDexInvalid: data.percentage_invalid
      })
    });
  }
  public convertSummaryTotalCsFromApi(
    res: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = res;
    return new ResponsePayloadV2({
      data: new SummaryTotalCs({
        totalCs: data.total_cs,
        totalCsL1: data.total_cs_l1,
        totalCsL2: data.total_cs_l2,
        fastestDexAssessment: new SpeedDexAssessment({
          csName: data.fastest_dex_assessment.cs_name,
          timeMinute: Math.round(data.fastest_dex_assessment.time_minute)
        }),
        slowestDexAssessment: new SpeedDexAssessment({
          csName: data.slowest_dex_assessment.cs_name,
          timeMinute: Math.round(data.slowest_dex_assessment.time_minute)
        }),
        highestDoneDexAssessment: new DoneDexAssessment({
          csName: data.highest_done_dex_assessment.cs_name,
          total: data.highest_done_dex_assessment.total,
          percentage: data.highest_done_dex_assessment.percentage
        }),
        lowestDoneDexAssessment: new DoneDexAssessment({
          csName: data.lowest_done_dex_assessment.cs_name,
          total: data.lowest_done_dex_assessment.total,
          percentage: data.lowest_done_dex_assessment.percentage
        })
      })
    });
  }
  public convertSummaryTicketPercentageDataFromApi(
    res: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = res;
    return new ResponsePayloadV2({
      data: new SummaryTicketPercentage({
        ticketTotal: data.ticket_total,
        ticketByDriverApp: data.ticket_by_driver_app,
        ticketByGenesis: data.ticket_by_genesis,
        ticketDone: data.ticket_done,
        ticketValid: data.ticket_valid,
        ticketInvalid: data.ticket_invalid,
        percentageTicketByDriverApp: data.percentage_ticket_by_driver_app,
        percentageTicketByGenesis: data.percentage_ticket_by_genesis,
        percentageTicketValid: data.percentage_ticket_valid,
        percentageTicketInvalid: data.percentage_ticket_invalid,
        ticketDisputeCustomer: data.ticket_dispute_customer,
        ticketDisputeByPartner: data.ticket_dispute_by_partner,
        percentageTicketDisputeCustomer:
          data.percentage_ticket_dispute_customer,
        percentageTicketDisputeByPartner:
          data.percentage_ticket_dispute_by_partner
      })
    });
  }
  public convertSummaryTicketPercentageDisputeL2(
    res: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = res;
    return new ResponsePayloadV2({
      data: new SummaryTicketPercentageDispute({
        ticketTotal: data.ticket_total,
        ticketRequested: data.ticket_requested,
        ticketRejected: data.ticket_rejected,
        ticketApproved: data.ticket_approved,
        percentageTicketDisputeApproved: data.percentage_approved,
        percentageTicketDisputeRejected: data.percentage_rejected,
        percentageTicketDisputeRequested: data.percentage_requested
      })
    });
  }
  public convertSummaryTicketBasedRangeTime(
    res: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = res;
    return new ResponsePayloadV2({
      data: new SummaryTicketDexAssesmentBasedRangeTime({
        datasets: data.datasets,
        labels: data.labels
      })
    });
  }
  public convertSummaryFakeDataFromApi(
    res: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = res;
    return new ResponsePayloadV2({
      data: new SummaryFake({
        ticketTotal: data.ticket_total,
        ticketBySourceDex: data.ticket_by_source_dex,
        ticketByReasonDex: data.ticket_by_reason_dex,
        ticketBySourceReasonDex: data.ticket_by_source_reason_dex,
        ticketReasonDexOutsideOpsHour: data.ticket_reason_dex_outside_ops_hour,
        ticketReasonDexClosedAddressHome:
          data.ticket_reason_dex_closed_address_home,
        ticketReasonDexClosedAddressOffice:
          data.ticket_reason_dex_closed_address_office,
        percentageTicketBySourceDex: data.percentage_ticket_by_source_dex,
        percentageTicketByReasonDex: data.percentage_ticket_by_reason_dex,
        percentageTicketBySourceReasonDex:
          data.percentage_ticket_by_source_reason_dex
      })
    });
  }
  public convertSummaryTopDexDataFromApi(
    res: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = res;
    return new ResponsePayloadV2({
      data:
        data && data.length
          ? data.map(
              (item: any) =>
                new SummaryTopDex({
                  partnerId: item.partner_id,
                  courierPhone: item.courier_phone,
                  name: item.name,
                  totalStt: item.total_stt,
                  totalDex: item.total_dex,
                  totalInvalid: item.total_invalid,
                  percentageDex: item.percentage_dex,
                  percentageInvalid: item.percentage_invalid,
                  totalTicket: item.total_ticket,
                  totalDispute: item.total_dispute,
                  totalRequested: item.total_requested,
                  totalApprove: item.total_approved,
                  totalRejected: item.total_rejected,
                  percentageDispute: item.percentage_dispute,
                  percentageRequested: item.percentage_requested,
                  percentageApproved: item.percentage_approved,
                  percentageRejected: item.percentage_rejected,
                  totalDel: item.total_del,
                  totalValid: item.total_valid,
                  percentageValid: item.percentage_valid,
                  reason: item.reason,
                  destination: item.destination
                })
            )
          : []
    });
  }
  public convertSummaryListCsDataFromApi(
    res: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = res;
    return new ResponsePayloadV2({
      data: data.length
        ? data.map(
            (item: any) =>
              new SummaryListCs({
                name: item.name,
                status: item.status,
                ticketQuota: item.ticket_quota,
                ticketAssigned: item.ticket_assigned,
                ticketDone: item.ticket_done,
                percentageDone: item.percentage_done,
                averageDoneMinute: Math.round(item.average_done_minute),
                totalActiveMinute: item.total_active_minute
              })
          )
        : []
    });
  }
  public convertSummaryBacklogDataFromApi(
    res: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = res;
    return new ResponsePayloadV2({
      data: new SummaryBacklog({
        courierName: data.courier_name,
        totalTicket: data.total_ticket,
        labels: data.labels,
        datasets: data.datasets.map(
          (dataset: any) =>
            new SummaryBacklogDatasets({
              label: dataset.label,
              data: dataset.data
            })
        )
      })
    });
  }
  public convertTotalTicketPerDayDataFromApi(
    res: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = res;
    return new ResponsePayloadV2({
      data: new SummaryTotalDexPerDay({
        datasets: data.datasets,
        labels: data.labels
      })
    });
  }
  public convertBacklogAgentDataFromApi(
    res: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = res;
    return new ResponsePayloadV2({
      data: new BacklogAgentCS({
        datasets: data.datasets.map(
          (dataset: any) =>
            new DatasetsBacklog({
              labels: dataset.label,
              data: dataset.data
            })
        ),
        labels: data.labels
      })
    });
  }
  public convertDownloadDataFromApi(
    res: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const { data } = res;
    return new ResponsePayloadV2({
      success: data.success,
      message: data.message
    });
  }
}
