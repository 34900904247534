/* eslint-disable @typescript-eslint/camelcase */
import { PartnerRequestInterface } from "@/data/payload/contracts/PartnerRequest";
import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class UpdatePartnerApiRequest implements PartnerRequestInterface {
  id: number;
  partnerName: string;
  partnerAddress: string;
  partnerContractEnd: string;
  partnerContactJobName: string;
  partnerContactJobTitle: string;
  partnerContactEmail: string;
  partnerContactPhoneNumber: string;
  partnerBankName: string;
  partnerAccountNumber: string;
  partnerAccountName: string;
  partnerBankEmail: string;
  partnerBeneficialAccountLabel: string;
  partnerDefaultDistrict: string;
  partnerLatitude: string;
  partnerLongitude: string;
  partnerPosType?: string;
  partnerPosParentId?: number;
  partnerPhoneNumber?: string;
  partnerTaxNumber?: string;
  parentId?: number;
  partnerParentPartnerType?: string;
  partnerCity?: Array<string>;
  partnerIsCodDelivery?: boolean;
  partnerPosBranchCommission?: number;
  partnerPosReverseJourney: number;
  partnerIsCodBooking?: boolean;
  partnerIsPcu?: boolean;
  partnerPosAttachFiles?: string[];
  partnerPosSaldoDeposit?: number;

  constructor(
    id: number,
    partnerName: string,
    partnerAddress: string,
    partnerContractEnd: string,
    partnerContactJobName: string,
    partnerContactJobTitle: string,
    partnerContactEmail: string,
    partnerContactPhoneNumber: string,
    partnerBankName: string,
    partnerAccountNumber: string,
    partnerAccountName: string,
    partnerBankEmail: string,
    partnerBeneficialAccountLabel: string,
    partnerDefaultDistrict: string,
    partnerLatitude: string,
    partnerLongitude: string,
    partnerPosReverseJourney: number,
    partnerPosType?: string,
    partnerPosParentId?: number,
    partnerPhoneNumber?: string,
    partnerTaxNumber?: string,
    parentId?: number,
    partnerParentPartnerType?: string,
    partnerCity?: Array<string>,
    partnerIsCodDelivery?: boolean,
    partnerPosBranchCommission?: number,
    partnerIsCodBooking?: boolean,
    partnerIsPcu?: boolean,
    partnerPosAttachFiles?: string[],
    partnerPosSaldoDeposit?: number
  ) {
    this.id = id;
    this.partnerName = partnerName;
    this.partnerAddress = partnerAddress;
    this.partnerContractEnd = partnerContractEnd;
    this.partnerContactJobName = partnerContactJobName;
    this.partnerContactJobTitle = partnerContactJobTitle;
    this.partnerContactEmail = partnerContactEmail;
    this.partnerContactPhoneNumber = partnerContactPhoneNumber;
    this.partnerBankName = partnerBankName;
    this.partnerAccountNumber = partnerAccountNumber;
    this.partnerAccountName = partnerAccountName;
    this.partnerBankEmail = partnerBankEmail;
    this.partnerPhoneNumber = partnerPhoneNumber;
    this.partnerTaxNumber = partnerTaxNumber;
    this.parentId = parentId;
    this.partnerParentPartnerType = partnerParentPartnerType;
    this.partnerCity = partnerCity;
    this.partnerBeneficialAccountLabel = partnerBeneficialAccountLabel;
    this.partnerDefaultDistrict = partnerDefaultDistrict;
    this.partnerLatitude = partnerLatitude;
    this.partnerPosType = partnerPosType;
    this.partnerPosParentId = partnerPosParentId;
    this.partnerLongitude = partnerLongitude;
    this.partnerIsCodDelivery = partnerIsCodDelivery;
    this.partnerPosBranchCommission = partnerPosBranchCommission;
    this.partnerPosReverseJourney = partnerPosReverseJourney;
    this.partnerIsCodBooking = partnerIsCodBooking;
    this.partnerIsPcu = partnerIsPcu;
    this.partnerPosAttachFiles = partnerPosAttachFiles;
    this.partnerPosSaldoDeposit = partnerPosSaldoDeposit;
  }
  public toJSON(): string {
    return JSON.stringify({
      id: this.id,
      partner_name: this.partnerName,
      partner_address: this.partnerAddress,
      partner_contract_end: this.partnerContractEnd,
      partner_contact_name: this.partnerContactJobName,
      partner_contact_job_title: this.partnerContactJobTitle,
      partner_contact_email: this.partnerContactEmail,
      partner_contact_phone_number: this.partnerContactPhoneNumber,
      partner_beneficiary_bank_name: this.partnerBankName,
      partner_beneficiary_account_number: this.partnerAccountNumber,
      partner_beneficiary_account_name: this.partnerAccountName,
      partner_beneficiary_email: this.partnerBankEmail,
      partner_beneficial_account_label: this.partnerBeneficialAccountLabel,
      partner_default_district: this.partnerDefaultDistrict,
      partner_phone_number: this.partnerPhoneNumber,
      partner_tax_number: this.partnerTaxNumber,
      parent_id: this.parentId,
      partner_parent_partner_type: this.partnerParentPartnerType,
      partner_latitude: this.partnerLatitude,
      partner_longitude: this.partnerLongitude,
      partner_pos_type: this.partnerPosType,
      partner_pos_parent_id: this.partnerPosParentId,
      partner_is_cod_delivery: this.partnerIsCodDelivery,
      partner_is_cod_booking: this.partnerIsCodBooking,
      partner_is_pcu: this.partnerIsPcu,
      partner_pos_branch_commission: this.partnerPosBranchCommission,
      partner_pos_reverse_journey: this.partnerPosReverseJourney,
      partner_pos_attach_files: this.partnerPosAttachFiles,
      partner_pos_saldo_deposit: this.partnerPosSaldoDeposit
    });
  }
}

export class FreezeUnFreezePartnerApiRequest
  implements PartnerRequestInterface {
  id: number;
  partnerIsBanned: boolean;
  partnerBannedReason: string;

  constructor(
    id: number,
    partnerIsBanned: boolean,
    partnerBannedReason: string
  ) {
    this.id = id;
    this.partnerIsBanned = partnerIsBanned;
    this.partnerBannedReason = partnerBannedReason;
  }

  public toJSON(): string {
    return JSON.stringify({
      id: this.id,
      partner_is_banned: this.partnerIsBanned,
      partner_banned_reason: this.partnerBannedReason
    });
  }
}

export class RequestListPartner {
  page = 1;
  limit = 20;
  status = "";
  isTotalData = false;
  search = "";
  contractStatus = "";
  cityCode = "";
  useLocation = false;
  useDefaultLocation = false;
  isDefaultLocation = false;
  districtCode = false;
  type = "";
  isRegistry = "";
  cityCodeExclude = "";
  version = "v1";
  isWithPosBalance = false;
  posType = "";
  posParentId = 0;
  countryCode = ""

  constructor(fields?: Partial<RequestListPartner>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
