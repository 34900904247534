import { AnnouncementApiRepository } from "@/app/infrastructures/repositories/api/AnnouncementApiRepository";
import {
  AnnouncementData,
  AnnouncementEntities
} from "@/domain/entities/Announcement";
import { injectable } from "tsyringe";
import {
  EditAnnouncementRequestInterface,
  ChangeAnnouncementRequestInterface
} from "@/data/payload/contracts/AnnouncementRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { RequestListAnnouncement } from "@/data/payload/api/AnnouncementApiRequest";

@injectable()
export class AnnouncementPresenter {
  private repository: AnnouncementApiRepository;

  constructor(repository: AnnouncementApiRepository) {
    this.repository = repository;
  }

  public getListAnnouncement(
    params: RequestListAnnouncement
  ): Promise<AnnouncementEntities> {
    return this.repository.getList(params);
  }

  public getDetailAnnouncement(id: number): Promise<AnnouncementData> {
    return this.repository.getDetail(id);
  }

  public editDetailAnnouncement(
    id: number,
    payload: EditAnnouncementRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editDetail(id, payload);
  }

  public addAnnouncement(
    payload: ChangeAnnouncementRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addAnnouncement(payload);
  }

  public deleteAnnouncement(id: number): Promise<ResponsePayload> {
    return this.repository.deleteAnnouncement(id);
  }
}
