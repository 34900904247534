import {
  DetailManualDeductAddSaldoData,
  ManualDeductAddSaldoData,
  ManualDeductAddSaldoEntities
} from "@/domain/entities/ManualDeductAddSaldo";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class ManualDeductAddSaldoMapper {
  public convertDetailManualDeductAddSaldo(
    res: AxiosResponse<any>
  ): DetailManualDeductAddSaldoData {
    const { data } = res;
    return new DetailManualDeductAddSaldoData(
      data.data.pg_trx_id,
      data.data.pg_trx_no,
      data.data.pg_trx_actor_type,
      data.data.pg_trx_partner_name
        ? data.data.pg_trx_partner_name
        : data.data.pg_trx_client_name,
      data.data.pg_trx_type,
      data.data.pg_trx_activity,
      data.data.pg_trx_amount,
      data.data.pg_trx_proof_file,
      data.data.pg_trx_created_at,
      data.data.pg_trx_created_name,
      data.data.pg_trx_note,
      data.data.pg_trx_currency.toUpperCase()
    );
  }

  public convertManualDeductAddSaldoDataFromApi(
    res: AxiosResponse<any>
  ): ManualDeductAddSaldoEntities {
    const { data } = res;
    if (data.data.length === 0) {
      return new ManualDeductAddSaldoEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      return new ManualDeductAddSaldoEntities(
        new Pagination(data.meta.page, data.meta.limit),
        data.data.map(
          (item: any) =>
            new ManualDeductAddSaldoData(
              item.pg_trx_id,
              item.pg_trx_no,
              item.pg_trx_partner_name
                ? item.pg_trx_partner_name
                : item.pg_trx_client_name,
              item.pg_trx_type,
              item.pg_trx_activity,
              item.pg_trx_amount,
              item.pg_trx_proof_file,
              item.pg_trx_created_at,
              item.pg_trx_created_name,
              item.pg_trx_status,
              item.pg_trx_note,
              item.pg_trx_currency.toUpperCase()
            )
        )
      );
    }
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertExportReportManualDeduct(res: AxiosResponse<any>) {
    const { data } = res;
    return data;
  }
}
