const maskBankAccountName = (val: string) => {
  return val
    .split(" ")
    .map(item => {
      const length = item.length;
      const name = [...item];
      name[1] = "*";
      return length > 2
        ? [...item]
            .map((el, index) => (index > 1 && index !== length - 1 ? "*" : el))
            .join("")
        : name;
    })
    .join(" ");
};
export default maskBankAccountName;
