import { CustomerManagementApiRepository } from "@/app/infrastructures/repositories/api/CustomerManagementApiRepository";
import { AddCustomerManagementRequestInterface , EditCustomerManagementRequestInterface} from "@/data/payload/contracts/CustomerManagementResquest";
import {
    Customer,
    CustomerListEntities,
    RequestCustomerList
} from "@/domain/entities/Customer";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { injectable } from "tsyringe";

@injectable()
export class CustomerManagementPresenter {
  private repository: CustomerManagementApiRepository;
  constructor(repo: CustomerManagementApiRepository) {
    this.repository = repo;
  }

  public getCustomerManagementList(
    params: RequestCustomerList
  ): Promise<CustomerListEntities> {
    return this.repository.getCustomerList(params);
  }

  public addCustomer(
    payload: AddCustomerManagementRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addCustomer(payload);
  }

  public getDetailCustomer(
    id: number
  ): Promise<Customer> {
    return this.repository.getDetailCustomer(id);
  }

  public editCustomer(
    id: number,
    payload: EditCustomerManagementRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editCustomer(id, payload);
  }

  public deleteCustomer(
    id: number
  ): Promise<ResponsePayload> {
    return this.repository.deleteCustomer(id);
  }
}