import {
  INTERNET_ERROR,
  INTERNAL_SERVER_ERROR,
  CLIENT_ERROR,
  SPECIAL_ERROR,
  FAILED_ERROR,
  ERROR_TOKEN_NGEN,
} from "@/app/infrastructures/misc/Utils";
import snakeCase from "lodash/snakeCase";
import { AccountEntities } from "./Account";

export class ErrorMessageEntities {
  title: any = "Oops, System Error";
  message: any =
    "Jangan khawatir ini bukan kesalahan Anda. Terjadi kesalahan pada system, silahkan coba lagi nanti";
  image: any = "server-error";
  type: any = INTERNAL_SERVER_ERROR;
  onTryAgain: any = null;
  onClose: any = null;
  buttonSuccessText? = "Oke";
  isSuccess = false;
  isFailed = false;

  constructor(fields?: Partial<ErrorMessageEntities>) {
    Object.assign(this, fields);
    if (fields?.type === INTERNET_ERROR) {
      this.title = "Tidak Terhubung ke Internet";
      this.image = "error-internet";
      this.message = "Pastikan koneksi internet Anda aktif dan muat ulang";
      this.onTryAgain = fields?.onTryAgain;
      this.onClose = fields?.onClose;
      return;
    }
    if (fields?.type === INTERNAL_SERVER_ERROR) {
      this.title = "Terjadi Gangguan Sistem";
      this.image = "error-token-ngen";
      this.message = "Halaman gagal dimuat. Muat ulang halaman ini";
      this.onTryAgain = fields?.onTryAgain;
      this.onClose = fields?.onClose;
      return;
    }
    if (fields?.type === CLIENT_ERROR) {
      this.title = fields?.title;
      this.image = "circle-error";
      this.message = fields?.message;
      this.onTryAgain = fields?.onTryAgain;
      this.onClose = fields?.onClose;
      this.buttonSuccessText =
        fields?.buttonSuccessText || this.buttonSuccessText;
      return;
    }
    if (fields?.type === SPECIAL_ERROR) {
      this.title = fields?.title;
      this.image = "image-modal-warning";
      this.message = fields?.message;
      this.onTryAgain = fields?.onTryAgain;
      this.onClose = fields?.onClose;
      return;
    }
    if (fields?.type === FAILED_ERROR) {
      this.title = fields.title;
      this.image = "image-modal-failed";
      this.message = fields.message;
      this.onTryAgain = fields.onTryAgain;
      this.onClose = fields.onClose;
    }
    if (fields?.type === ERROR_TOKEN_NGEN) {
      this.title = fields.title;
      this.image = "error-token-ngen";
      this.message = fields.message;
      this.onTryAgain = fields.onTryAgain;
      this.onClose = fields.onClose;
    }
  }
}

export class ErrorSpesificationModal {
  headerError = "";
  errorMsg = "";
  statusError = false;
  errorDestination = false;
  isErrorEmbargo = false;

  constructor(fields?: Partial<ErrorSpesificationModal>) {
    Object.assign(this, fields);
  }
}

export class ModalMessageEntities {
  [x: string]: any;
  title: any = "";
  message: any = "";
  image: any = "image-modal-success";
  imageWidth: any = "";
  imageHeight: any = "";
  onSubmit: any = null;
  onClose: any = null;
  textSuccess: any = "";
  textCancel: any = "";
  totalSuccess: any = "";
  totalFailed: any = "";
  customWidth: any = "380px";
  isBulkUpload: any = false;
  customTotalSuccess: any = "";
  customTotalFailed: any = "";
  idButtonSubmit = "";
  idButtonCancel = "";

  constructor(fields?: Partial<ModalMessageEntities>) {
    Object.assign(this, fields);
  }
}

export class QueryParamsEntities {
  queryString = "";
  constructor(fields?: any) {
    if (!fields) {
      return;
    }
    const queries = [];
    for (const [key, value] of Object.entries(fields)) {
      const val: any = value;
      if (val) {
        if (!/\d/.test(key)) {
          queries.push(`${snakeCase(key)}=${encodeURIComponent(val)}`);
        } else {
          queries.push(`${key}=${encodeURIComponent(val)}`);
        }
      }
    }
    this.queryString = queries.join("&");
  }
}

export class QueryParamsEntities2 {
  queryString = "";
  constructor(fields?: any) {
    if (!fields) {
      return;
    }
    const queries = [];
    for (const [key, value] of Object.entries(fields)) {
      const val: any = value;
      queries.push(`${key}=${encodeURIComponent(val)}`);
    }
    this.queryString = queries.join("&");
  }
}

export class OptionsClass {
  name = "";
  value = "";
  code? = "";
  key? = "";
  id? = 0;
  label? = "";
  shcCode? = "";
  shcName? = "";
  constructor(fields?: Partial<OptionsClass>) {
    Object.assign(this, fields);
  }
}

export class BadgeResponse {
  isSuccess = false;
  isFailed = false;
  isNotFound = false;
  isExist = false;
  title = "";
  message = "";
  icon = "";
  style = "";
  textSuccess = "";
  onSubmit: (() => void) | undefined;
  textCancel = "";
  onClose: (() => void) | undefined;
  constructor(fields?: Partial<BadgeResponse>) {
    Object.assign(this, fields);
  }
}

export class HeaderManifestData {
  key = new OptionsClass();
  code = "";
  destinationCity = "";
  cargoType = "";
  partnerName = "";
  departureDate = "";
  arrivalDate = "";
  cargoCommodity = "";
  cargoProduct = "";
  accountData = new AccountEntities();
  createdAt = "";
  createdBy = "";
  totalStt = 0;
  totalPiece = 0;
  totalGrossWeight = 0;
  totalVolumeWeight = 0;
  totalActualCargoGrossWeight = "";
  totalActualCargoVolumeWeight = "";
  qrCodeValue = "";
  posName = "";
  originCity = "";
  vendorName = "";
  driverName = "";
  driverPhone = "";
  vehicleNumber = "";
  updatedAt = "";
  updatedBy = "";
  ursaCode = "";
  isFormatDateUsingTimestamp = false;
  constructor(fields?: Partial<HeaderManifestData>) {
    Object.assign(this, fields);
  }
}
export class ColumnManifestData {
  no = 0;
  sttNo = "";
  totalPiece = 0;
  totalGrossWeight = 0;
  receiver = "";
  codFee = 0;
  productType = "";
  bagNo = "";
  destinationCity = "";
  constructor(fields?: Partial<ColumnManifestData>) {
    Object.assign(this, fields);
  }
}

type headerSection =
  | "key"
  | "accountTypeName"
  | "originCity"
  | "destinationCity"
  | "createdAt"
  | "createdBy"
  | "totalStt"
  | "totalPiece"
  | "totalGrossWeight"
  | "totalVolumeWeight"
  | "sttNo"
  | "bagNo"
  | "partnerName"
  | "departureDate"
  | "arrivalDate"
  | "cargoType"
  | "cargoCommodity"
  | "cargoProduct"
  | "receiver"
  | "codFee"
  | "signature"
  | "posName"
  | "vendorName"
  | "driverName"
  | "driverPhone"
  | "productType"
  | "vehicleNumber"
  | "notes"
  | "ursaCode"
  | "updatedAt"
  | "updatedBy";
export class SectionManifestData {
  headerDetailData = new HeaderManifestData();
  headerSection: headerSection[] = [
    "accountTypeName",
    "originCity",
    "createdAt",
    "totalStt",
    "totalPiece",
    "totalGrossWeight",
    "bagNo",
    "destinationCity",
  ];
  columns: headerSection[] = [
    "sttNo",
    "productType",
    "createdAt",
    "totalPiece",
    "totalGrossWeight",
    "totalVolumeWeight",
    "originCity",
    "destinationCity",
    "notes",
    "bagNo",
  ];
  data: (any | ColumnManifestData)[] = [];
  featureName = "";
  signature = false;
  totalColumnHeader = 2;
  constructor(fields?: Partial<SectionManifestData>) {
    Object.assign(this, fields);
  }
}

export class ApiRequestList {
  [x: string]: any;
  page = 1;
  limit = 20;
  search = "";
  status = "";
  statusData = new OptionsClass({
    name: "Semua Status",
  });
  startDate: string | null = "";
  endDate: string | null = "";
  constructor(fields?: Partial<ApiRequestList>) {
    Object.assign(this, fields);
  }
  toQueryString(): string {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { statusData, ...params } = this;
    return new QueryParamsEntities(params).queryString;
  }
}

export class SubData {
  status = "";
  createdAt = "";
  createdBy = "";
  updatedAt = "";
  updatedBy = "";
  isEditable = false;
  goEdit = () => ({} as any);

  constructor(fields?: Partial<SubData>) {
    Object.assign(this, fields);
  }
}

export const CONFIRM_LIST_DATA = [
  new OptionsClass({
    name: "Ya",
    value: "yes",
  }),
  new OptionsClass({
    name: "Tidak",
    value: "no",
  }),
];

export class NestedCheckboxItem {
  label = "";
  value = false;
  disabled = false;
  hint = "";
  textSize = 12;
  keyName = "";
  labelWeight = "semibold";

  constructor(fields?: Partial<NestedCheckboxItem>) {
    Object.assign(this, fields);
  }
}

export class UploadMultiple {
  index = 0;
  file: any = "";
  constructor(fields?: Partial<UploadMultiple>) {
    Object.assign(this, fields);
  }
}
