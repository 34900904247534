import {
  BULK_DOWNLOAD,
  BULK_UPLOAD
} from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const BulkUpload = () =>
  import(
    /* webpackChunkName: "BulkAction" */ "@/app/ui/views/bulk-action/bulk-upload/index.vue"
  );
const BulkDownload = () =>
  import(
    /* webpackChunkName: "BulkAction" */ "@/app/ui/views/bulk-action/bulk-download/index.vue"
  );
const existingData = () =>
  import(
    /* webpackChunkName: "BulkAction" */ "@/app/ui/views/bulk-action/bulk-download/tab-existing-data.vue"
  );
const templateTab = () =>
  import(
    /* webpackChunkName: "BulkAction" */ "@/app/ui/views/bulk-action/bulk-download/tab-template.vue"
  );

export default [
  {
    path: "/bulk-action",
    component: Layout,
    name: "bulk-action",
    redirect: "/bulk-action/bulk-upload",
    meta: {
      icon: "bulk",
      children: true,
      title: "Bulk Action",
      name: "Bulk Action",
      permission: [BULK_UPLOAD.ENABLE, BULK_DOWNLOAD.ENABLE]
    },
    children: [
      {
        path: "bulk-upload",
        name: "bulk-upload",
        component: BulkUpload,
        meta: {
          header: true,
          icon: "cloud-upload",
          title: "Bulk Upload",
          name: "Upload",
          permission: BULK_UPLOAD.ENABLE
        }
      },
      {
        path: "bulk-download",
        name: "bulk-download",
        redirect: "/bulk-action/bulk-download/existing-data",
        component: BulkDownload,
        meta: {
          header: true,
          icon: "cloud-download",
          title: "Download Bulk Template",
          name: "Download",
          permission: [BULK_UPLOAD.ENABLE, BULK_DOWNLOAD.ENABLE]
        },
        children: [
          {
            path: "existing-data",
            name: "existing-data",
            component: existingData
          },
          {
            path: "template",
            name: "template",
            component: templateTab
          }
        ]
      }
    ]
  }
];
