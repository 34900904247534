/* eslint-disable @typescript-eslint/camelcase */
import {
  AccountEntities,
  AccountTypeDetail,
  DashboardDeliveryPos,
  Hub,
  FeatureFlagDetail,
  ValidationAccountData,
  AccountStatus
} from "@/domain/entities/Account";
import { AxiosResponse } from "axios";
import {
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";

export class AccountDataMapper {
  public convertAccountDataFromApi(
    result: AxiosResponse<any>
  ): AccountEntities {
    const {
      data: { data }
    } = result;

    if (IS_SENDER_ACCOUNT) {
      return new AccountEntities({
        account_id: data.customer_id,
        name: data.fullname,
        role_type: data.role,
        account_type: data.role,
        email: data.email || "-",
        phone_number: data.phone_number.replace("+", ""),
        status: data.customer_status,
        birthdate: data.birthdate,
        customerLevel: data.customer_level,
        account_type_detail: new AccountTypeDetail({
          countryCode: "ID"
        })
      });
    }

    const featureFlag = [...data.nosql_feature_flag, ...data.feature_flag];
    return new AccountEntities({
      account_id: data.account_id,
      username: data.username,
      name: data.name,
      role_type: data.role_type,
      role_permission: data.role_permission,
      account_type: data.account_type.toLowerCase(),
      account_type_detail: data.account_type_detail
        ? new AccountTypeDetail({
            id: data.account_type_detail.id,
            partnerReferralCode: data.account_type_detail.partner_referral_code,
            company_name:
              data.account_type_detail.company_name ||
              data.account_type_detail.name,
            phone: data.account_type_detail.phone,
            address: data.account_type_detail.address,
            balance: data.account_type_detail.balance,
            created_at: data.account_type_detail.created_at,
            updated_at: data.account_type_detail.updated_at,
            type: data.account_type_detail.type,
            isDO: data.account_type_detail.is_do,
            isMixpack: data.account_type_detail.is_mixpack,
            districtCode: data.account_type_detail.partner_location?.district_code,
            cityCode: data.account_type_detail.partner_location?.city_code,
            countryCode:
              data.account_type_detail.partner_location?.country.code || "ID",
            code: data.account_type_detail.code || "",
            partnerExternalCode:
              data.account_type_detail.partner_external_code || "",
            parentId: data.account_type_detail.client_parent_id,
            firstLogin: data.account_type_detail.first_login,
            isCodDelivery: data.account_type_detail.partner_is_cod_delivery,
            partnerLocation: data.account_type_detail.partner_location,
            hub: data.account_type_detail.hub
              ? new Hub(
                  data.account_type_detail.hub?.hub_id,
                  data.account_type_detail.hub?.hub_name,
                  data.account_type_detail.hub?.hub_code,
                  data.account_type_detail.hub?.partner_id
                )
              : new Hub(0, "", "", 0),
            partnerIdSttReturn: data.account_type_detail.partner_id_stt_return,
            posParentId: data.account_type_detail.partner_pos_parent_id,
            partnerIsCodBooking:
              data.account_type_detail.partner_is_cod_booking,
            partnerIsCodDelivery:
              data.account_type_detail.partner_is_cod_delivery,
            partnerIsPcu: data.account_type_detail.partner_is_pcu,
            partnerPosSaldoDeposit: data.account_type_detail.partner_pos_saldo_deposit,
            partnerPosTieringLevel: data.account_type_detail.partner_pos_tiering_level?.toLowerCase(),
            isCod: data.account_type_detail.client_is_cod,
            isDfod: data.account_type_detail.client_is_dfod
          })
        : new AccountTypeDetail(),
      email: data.email,
      phone_number: data.phone_number,
      limit_rule_min_balance: data.limit_rule_min_balance,
      limit_rule_limit_border: data.limit_rule_limit_border,
      account_location: data.account_location,
      account_location_list: data.account_location_list,
      featureFlag: featureFlag
        ? featureFlag.map(
            key =>
              new FeatureFlagDetail({
                keyName: key.key_name,
                keyValue: key.key_value
              })
          )
        : []
    });
  }

  public convertDashboardDeliveryPosDataFromApi(
    result: AxiosResponse<any>
  ): DashboardDeliveryPos {
    const {
      data: { data }
    } = result;
    const pos = data.dashboard_insight_pos;
    const console = data.dashboard_insight_console;
    const subConsole = data.dashboard_insight_sub_console;

    return new DashboardDeliveryPos(
      pos
        ? pos.total_stt_del
        : console
        ? console.total_stt_del
        : subConsole && subConsole.total_stt_del,
      pos && pos.total_stt_dex,
      console && console.total_stt_sti,
      console && console.total_stt_sti_dest,
      console && console.total_stt_cargo_plane,
      console && console.total_stt_cargo_train,
      console && console.total_stt_cargo_truck,
      subConsole && subConsole.total_stt_sti_sc,
      subConsole && subConsole.total_stt_sti_dest_sc
    );
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertValidationAccountDataFromApi(
    result: AxiosResponse<any>
  ): ValidationAccountData {
    const { data } = result;
    return new ValidationAccountData({ isAllow: data.data.is_allow });
  }
  public convertResponsePairTokenFromApi(
    result: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertResponseUnpairTokenFromApi(
    result: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertAccountStatusFromApi(
    result: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = result;

    return new ResponsePayloadV2({
      data: new AccountStatus({
        status: !!data.cal_status.match(/^active$/)
      })
    });
  }

  public convertFormDataFromApi(result: AxiosResponse<any>): ResponsePayloadV2 {
    const {
      data: { data }
    } = result;

    return new ResponsePayloadV2({ data });
  }
}
