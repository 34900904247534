import ApiService from "../../services/ApiService";
import { UrgentDeliveryMapper } from "@/data/persistences/mappers/UrgentDeliveryMapper";
import {
  UrgentDeliveryListEntities,
  RequestUrgentDeliveryListApi,
  UrgentDeliverySTTStatusListEntities,
  UrgentDeliveryDetailData,
  RequestUrgentDeliveryListApiDownload
} from "@/domain/entities/UrgentDelivery";
import { UrgentDeliveryRepositoryInterface } from "@/data/persistences/repositories/contracts/UrgentDeliveryRepositoryInterface";
import { UrgentDeliveryEndpoint } from "../../endpoints/hydra/UrgentDeliveryEndpoints";
import { AxiosResponse } from "axios";
import { downloadFile } from "../../misc/Utils";
import { Endpoints } from "../../misc/Endpoints";

export class UrgentDeliveryApiRepository implements UrgentDeliveryRepositoryInterface {
  private service: ApiService;
  private mapper: UrgentDeliveryMapper;
  private endpoints: UrgentDeliveryEndpoint;

  constructor(
    service: ApiService,
    mapper: UrgentDeliveryMapper,
    endpoints: UrgentDeliveryEndpoint
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getUrgentDeliveryList(
    params: RequestUrgentDeliveryListApi
  ): Promise<UrgentDeliveryListEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getUrgentDeliveryList(params),
      {}
    );
    return this.mapper.convertListDataFromApi(resp as AxiosResponse);
  }

  public async getSTTStatusUrgentDeliveryList(): Promise<UrgentDeliverySTTStatusListEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSTTStatusUrgentDeliveryList(),
      {}
    );

    return this.mapper.convertSTTStatusListDataFromApi(resp as AxiosResponse);
  }

  public async getUrgentDeliveryDetail(id: number): Promise<UrgentDeliveryDetailData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getUrgentDeliveryDetail(id),
      {}
    );

    return this.mapper.convertDetailDataFromApi(resp as AxiosResponse);
  }

  public async getUrgentDeliverySTTDownload(
    params: RequestUrgentDeliveryListApiDownload
  ): Promise<any> {
    return downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.getUrgentDeliverySTTDownload(params)}`,
      "pengiriman-penting",
      ".xlsx",
      true
    );
  }
}
