import { CollectibleCardInterface } from "@/data/persistences/repositories/contracts/CollectibleCardInterface";
import ApiService from "@/app/infrastructures/services/ApiService";
import { CollectibleCardMapper } from "@/data/persistences/mappers/CollectibleCardMapper";
import { Endpoints } from "@/app/infrastructures/misc/Endpoints";
import { CollectibleItemCard, CollectibleItemEntities } from "@/domain/entities/CollectibleCard";

export class CollectibleCardApiRepository implements CollectibleCardInterface {
  private service: ApiService;
  private mapper: CollectibleCardMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    endpoints: Endpoints,
    mapper: CollectibleCardMapper
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async getCollectibleItem(): Promise<CollectibleItemEntities> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getCollectibleCard()
    );

    return this.mapper.convertDataFromApi(res);
  }

  async getDetailCollectibleItem(
    imageId: number
  ): Promise<CollectibleItemCard> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.detailCollectibleCard(imageId)
    );

    return this.mapper.convertDetailCollectibleCardFromApi(resp);
  }
}
