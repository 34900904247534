import { configPrice } from "./modules/Price";

const formatPriceWithoutCurrency = (
  price: number,
  currency: "IDR" | "MYR" = "IDR"
) => {
  if (!price) return "0";
  return price.toLocaleString(configPrice[currency]?.locales);
};
export default formatPriceWithoutCurrency;
