import { OptionsClass, QueryParamsEntities } from "./MainApp";
import { Pagination } from "./Pagination";

export class DexAssessmentData {
  daId: any = 0;
  daTicketNo = "";
  daSttId = 0;
  daSttNo = "";
  daProductType = "";
  daOriginId = "";
  daOriginName = "";
  daDestinationId = "";
  daDestinationName = "";
  daDestinationDistrictId = "";
  daDestinationDistrictName = "";
  daSenderName = "";
  daSenderPhone = "";
  daRecipientName = "";
  daRecipientPhone = "";
  daSttRecipientAddressType = "";
  daSttRecipientAddress = "";
  daSttSla = "";
  daDeliveryId = "";
  daDeliveryCourierName = "";
  daDeliveryCourierPhone = "";
  daDeliveryProofPhoto: any = [];
  daDeliveryReasonCode = "";
  daDeliveryReasonCategory = "";
  daDeliveryReasonTitle = "";
  daDeliveryDexDate = "";
  daDeliveryDexDateLocal = "";
  daDeliveryDexAttempt = 0;
  daDeliveryGeolocationDex = "";
  daDeliveryAddressDex = "";
  daDeliveryCaptainCourierName = "";
  daDeliveryCaptainCourierPhone = "";
  daDisputeCustomer: any = "";
  daSourceDex = "";
  daPhotoProofAssessment: any = "";
  daReasonDexAssessment: any = "";
  daLocationDexAssessment: any = "";
  daSourceDexAssessment: any = "";
  daPhotoProofAssessmentFromSystem: any = "";
  daReasonDexAssessmentFromSystem: any = "";
  daLocationDexAssessmentFromSystem: any = "";
  daSourceDexAssessmenFromSystemt: any = "";
  daRemarksL2 = "";
  daRemarksPartner = "";
  daRemarksL2Dispute = "";
  daNeedToRTS = false;
  daProofPhotoDisputePartner: any = "";
  daAssignedL1AccountId = 0;
  daAssignedL1Name = "";
  daAssignedL1Date = "";
  daFinishedL1Date = "";
  daAssignedL2AccountId = 0;
  daAssignedL2Name = "";
  daAssignedL2Date = "";
  daFinishedL2Date = "";
  daAssignedPartnerId = 0;
  daAssignedPartnerName = "";
  daAssignedPartnerPhone = "";
  daAssignedPartnerDate = "";
  daFinishedPartnerDate = "";
  daTimeLeftPartnerDate = "";
  daAssignedL2DisputeAccountId = 0;
  daAssignedL2DisputeName = "";
  daAssignedL2DisputeDate = "";
  daFinishedL2DisputeDate = "";
  daStatusDisputePartner = "";
  daStatus = "";
  daCreatedAt = "";
  daUpdatedAt = "";
  daMeta = "";
  daStatusAssesBy = "";
  daPhotoProofAssessBy = "";
  daReasonDexAssessBy = "";
  daLocationDexAssessBy = "";
  daSourceDexAssessBy = "";
  daStatusDexAssessBy = "";
  constructor(fields?: Partial<DexAssessmentData>) {
    Object.assign(this, fields);

    Object.keys(this).forEach(key => {
      if (!(this as any)[key]) {
        (this as any)[key] = "";
      }
    });
  }
}

export class DexAssessmentList {
  pagination = new Pagination(1, 20);
  data: DexAssessmentData[] = [];

  constructor(fields?: Partial<DexAssessmentList>) {
    Object.assign(this, fields);
  }
}

export class DexAssessmentSummary {
  daTicketTotal = 0;
  daTicketQuota = 0;
  daTicketFinished = 0;
  daTicketInvalidToday = 0;
  daTicketValidToday = 0;
  daTicketDisputeToday = 0;
  ticketInvalid = 0;
  ticketDisputeRequested = 0;
  ticketDisputeApproved = 0;
  ticketDisputeRejected = 0;
  ticketDisputeAccepted = 0;
  constructor(fields?: Partial<DexAssessmentSummary>) {
    Object.assign(this, fields);
  }
}

export class RequestDexAssessmentL1 {
  page = 1;
  limit = 20;
  q = "";
  startAssignedPartnerDate = "";
  endAssignedPartnerDate = "";
  daStatus = "";
  statusDisputePartner = "";
  statusFollowup = "";
  ticketTypes = "";
  reasonCode = "";
  startDexDate = "";
  endDexDate = "";
  csName = "";
  partnerName = "";

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }

  constructor(fields?: Partial<RequestDexAssessmentL1>) {
    Object.assign(this, fields);
  }
}

export class RequestDexAssessmentSummary {
  startDate = "";
  endDate = "";

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }

  constructor(fields?: Partial<RequestDexAssessmentSummary>) {
    Object.assign(this, fields);
  }
}

export class SubmitDexAssesmentL1 {
  daPhotoProofAssessment = false;
  daReasonDexAssessment = false;
  daLocationDexAssessment = false;
  daSourceDexAssessment = false;
  daRemarksL2 = "";
  daNeedToRts = false;
  daRemarksL2Dispute = "";
  daStatusDisputePartner = "";
  daProofPhotoDisputePartner: any = "";
  daRemarksPartner = "";
  constructor(fields?: Partial<SubmitDexAssesmentL1>) {
    Object.assign(this, fields);
  }
}

export class SummaryTicketDexAssesmentBasedRangeTime {
  labels: Array<string> = [];
  datasets: Array<number> = [];
  constructor(fields?: Partial<SummaryTicketDexAssesmentBasedRangeTime>) {
    Object.assign(this, fields);
  }
}

export class SummaryTicketPercentageDispute {
  ticketTotal = 0;
  ticketRequested = 0;
  ticketRejected = 0;
  ticketApproved = 0;
  percentageTicketDisputeRejected = "";
  percentageTicketDisputeApproved = "";
  percentageTicketDisputeRequested = "";
  constructor(fields?: Partial<SummaryTicketPercentageDispute>) {
    Object.assign(this, fields);
  }
}

export class SectionTotalTicketData {
  key = "";
  title = "";
  tooltip = "";
  value: string | number = 0;
  loading = false;
  color = "";
  percentage = new OptionsClass();
  keyEndProgressBar = "";
  constructor(fields?: Partial<SectionTotalTicketData>) {
    Object.assign(this, fields);
  }
}
export class SummaryTicket {
  ticketTotal = "";
  ticketQueue = "";
  ticketAssigned = "";
  ticketDone = "";
  totalInvalid = "";
  totalRequested = "";
  totalRejected = "";
  totalApproved = "";
  percentageRequested = "";
  percentageRejected = "";
  percentageApproved = "";
  percentageTicketQueue = "";
  percentageTicketAssigned = "";
  percentageTicketDone = "";
  averageQueueTime = 0;
  maxQueueTime = 0;
  averageDoneTime = 0;
  maxDoneTime = 0;
  sourceDexGenesis = 0;
  sourceDexDriverApp = 0;
  percentageSourceDexGenesis = 0;
  percentageSourceDexDriverApp = 0;
  totalDexOneAttempt = 0;
  totalDexSecondAttempt = 0;
  totalDexMoreThanEqThirdAttempt = 0;
  percentageDexOneAttempt = 0;
  percentageDexSecondAttempt = 0;
  percentageDexMoreThanEqThirdAttempt = 0;
  dexReasonLabels: string[] = [];
  dexReasonDataset: SummaryBacklogDatasets[] = [];
  dexReasonTotalDex = 0;
  totalRts = 0;
  percentageRts = 0;
  sttIncoming = 0;
  totalDex = 0;
  totalDexValid = 0;
  totalDexInvalid = 0;
  percentageDex = 0;
  percentageDexValid = 0;
  percentageDexInvalid = 0;

  constructor(fields?: Partial<SummaryTicket>) {
    Object.assign(this, fields);
  }
}

export class SpeedDexAssessment {
  csName = "";
  timeMinute = 0;

  constructor(fields?: Partial<SpeedDexAssessment>) {
    Object.assign(this, fields);
  }
}
export class DoneDexAssessment {
  csName = "";
  total = 0;
  percentage = 0;

  constructor(fields?: Partial<DoneDexAssessment>) {
    Object.assign(this, fields);
  }
}
export class SummaryTotalCs {
  totalCs = 0;
  totalCsL1 = 0;
  totalCsL2 = 0;
  fastestDexAssessment: SpeedDexAssessment = {
    csName: "",
    timeMinute: 0
  };
  slowestDexAssessment: SpeedDexAssessment = {
    csName: "",
    timeMinute: 0
  };
  highestDoneDexAssessment: DoneDexAssessment = {
    csName: "",
    total: 0,
    percentage: 0
  };
  lowestDoneDexAssessment: DoneDexAssessment = {
    csName: "",
    total: 0,
    percentage: 0
  };

  constructor(fields?: Partial<SummaryTotalCs>) {
    Object.assign(this, fields);
  }
}
export class SummaryTicketPercentage {
  ticketTotal = "";
  ticketByDriverApp = "";
  ticketByGenesis = "";
  ticketDone = "";
  ticketValid = "";
  ticketInvalid = "";
  percentageTicketByDriverApp = "";
  percentageTicketByGenesis = "";
  percentageTicketValid = "";
  percentageTicketInvalid = "";
  ticketDisputeCustomer = "";
  ticketDisputeByPartner = "";
  percentageTicketDisputeCustomer = "";
  percentageTicketDisputeByPartner = "";

  constructor(fields?: Partial<SummaryTicketPercentage>) {
    Object.assign(this, fields);
  }
}
export class SummaryFake {
  ticketTotal = "";
  ticketBySourceDex = "";
  ticketByReasonDex = "";
  ticketBySourceReasonDex = "";
  ticketReasonDexOutsideOpsHour = 0;
  ticketReasonDexClosedAddressHome = 0;
  ticketReasonDexClosedAddressOffice = 0;
  percentageTicketBySourceDex = "";
  percentageTicketByReasonDex = "";
  percentageTicketBySourceReasonDex = "";

  constructor(fields?: Partial<SummaryFake>) {
    Object.assign(this, fields);
  }
}
export class SummaryTopDex {
  expand = false;
  partnerId = 0;
  courierPhone = "";
  name = "";
  totalStt = "";
  totalDex = "";
  totalInvalid = "";
  percentageDex = 0;
  percentageInvalid = 0;
  index = 0;
  totalTicket: any = 0;
  totalDispute: any = 0;
  totalRequested: any = 0;
  totalApprove: any = 0;
  totalRejected: any = 0;
  percentageDispute: any = 0;
  percentageRequested: any = 0;
  percentageApproved: any = 0;
  percentageRejected: any = 0;
  courierData: SummaryTopDex[] = [];
  totalDel = 0;
  totalValid = 0;
  percentageValid = 0;
  reason = "";
  destination = "";

  constructor(fields?: Partial<SummaryTopDex>) {
    Object.assign(this, fields);
  }
}
export class SummaryListCs {
  name = "";
  status = "";
  ticketQuota = 0;
  ticketAssigned = 0;
  ticketDone = 0;
  percentageDone = 0;
  averageDoneMinute = 0;
  totalActiveMinute = 0;

  constructor(fields?: Partial<SummaryListCs>) {
    Object.assign(this, fields);
  }
}
export class SummaryBacklog {
  courierName = "";
  totalTicket: any = 0;
  labels: string[] = [];
  datasets: SummaryBacklogDatasets[] = [];

  constructor(fields?: Partial<SummaryBacklog>) {
    Object.assign(this, fields);
  }
}

export class SummaryBacklogDatasets {
  label = "";
  data: number[] = [];

  constructor(fields?: Partial<SummaryBacklogDatasets>) {
    Object.assign(this, fields);
  }
}

export class SummaryTotalDexPerDay {
  labels: Array<string> = [];
  datasets: Array<number> = [];
  constructor(fields?: Partial<SummaryTotalDexPerDay>) {
    Object.assign(this, fields);
  }
}

export class BacklogAgentCS {
  labels: Array<string> = [];
  datasets: DatasetsBacklog[] = [];
  constructor(fields?: Partial<BacklogAgentCS>) {
    Object.assign(this, fields);
  }
}

export class DatasetsBacklog {
  labels = "";
  data: Array<number> = [];
  constructor(fields?: Partial<DatasetsBacklog>) {
    Object.assign(this, fields);
  }
}

export const assessmentData: { [x: string]: any } = {
  l1: "Ditentukan oleh L1",
  l2: "Ditentukan oleh L2",
  system: "Otomatis dari sistem"
};
