import { Pagination } from "./Pagination";

export class BalanceData {
  walletId = 0;
  walletActorType = "";
  walletActorId = 0;
  walletBalance = 0;
  walletBalanceCod = 0;
  walletCreatedAt = "";
  walletUpdatedAt = "";
  walletPoint = 0;
  constructor(fields?: Partial<BalanceData>) {
    Object.assign(this, fields);
  }
}

export class TransactionList {
  pagination: Pagination;
  data: TransactionListData[];
  constructor(pagination: Pagination, data: TransactionListData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class TransactionListData {
  pgTrxId: number;
  pgTrxNo: string;
  pgTrxType: string;
  pgTrxActivity: string;
  pgTrxAmount: number;
  pgTrxActorId: number;
  pgTrxActorType: string;
  pgTrxBeneficiaryName: string;
  pgTrxBeneficiaryAccount: string;
  pgTrxBeneficiaryBank: string;
  pgTrxSessionId: number;
  pgTrxRefNo: string;
  pgTrxSttNo: string;
  pgTrxStatus: string;
  pgTrxVa: string;
  pgTrxCreatedAt: string;
  pgTrxNote: string;
  pgTrxVaPartnerName: string;
  pgTrxLastBalance: number;
  constructor(
    pgTrxId: number,
    pgTrxNo: string,
    pgTrxType: string,
    pgTrxActivity: string,
    pgTrxAmount: number,
    pgTrxActorId: number,
    pgTrxActorType: string,
    pgTrxBeneficiaryName: string,
    pgTrxBeneficiaryAccount: string,
    pgTrxBeneficiaryBank: string,
    pgTrxSessionId: number,
    pgTrxRefNo: string,
    pgTrxSttNo: string,
    pgTrxStatus: string,
    pgTrxVa: string,
    pgTrxCreatedAt: string,
    pgTrxNote: string,
    pgTrxVaPartnerName: string,
    pgTrxLastBalance: number
  ) {
    this.pgTrxId = pgTrxId;
    this.pgTrxNo = pgTrxNo;
    this.pgTrxType = pgTrxType;
    this.pgTrxActivity = pgTrxActivity;
    this.pgTrxAmount = pgTrxAmount;
    this.pgTrxActorId = pgTrxActorId;
    this.pgTrxActorType = pgTrxActorType;
    this.pgTrxBeneficiaryName = pgTrxBeneficiaryName;
    this.pgTrxBeneficiaryAccount = pgTrxBeneficiaryAccount;
    this.pgTrxBeneficiaryBank = pgTrxBeneficiaryBank;
    this.pgTrxSessionId = pgTrxSessionId;
    this.pgTrxRefNo = pgTrxRefNo;
    this.pgTrxSttNo = pgTrxSttNo;
    this.pgTrxStatus = pgTrxStatus;
    this.pgTrxVa = pgTrxVa;
    this.pgTrxCreatedAt = pgTrxCreatedAt;
    this.pgTrxNote = pgTrxNote;
    this.pgTrxVaPartnerName = pgTrxVaPartnerName;
    this.pgTrxLastBalance = pgTrxLastBalance;
  }
}

export class InternalBankAccountList {
  data: InternalBankAccountData[];
  constructor(data: InternalBankAccountData[]) {
    this.data = data;
  }
}

export class InternalBankAccountData {
  internalBankAccountId: number;
  internalBankAccountName: string;
  internalBankAccountAccountName: string;
  internalBankAccountAccountNumber: string;
  constructor(
    internalBankAccountId: number,
    internalBankAccountName: string,
    internalBankAccountAccountName: string,
    internalBankAccountAccountNumber: string
  ) {
    this.internalBankAccountId = internalBankAccountId;
    this.internalBankAccountName = internalBankAccountName;
    this.internalBankAccountAccountName = internalBankAccountAccountName;
    this.internalBankAccountAccountNumber = internalBankAccountAccountNumber;
  }
}

export class InternalBankAccountDetail {
  internalBankAccountId: number;
  internalBankAccountName: string;
  internalBankAccountLogo: string;
  internalBankAccountAccountName: string;
  internalBankAccountAccountNumber: string;
  internalBankAccountIntruction: string;
  constructor(
    internalBankAccountId: number,
    internalBankAccountName: string,
    internalBankAccountLogo: string,
    internalBankAccountAccountName: string,
    internalBankAccountAccountNumber: string,
    internalBankAccountIntruction: string
  ) {
    this.internalBankAccountId = internalBankAccountId;
    this.internalBankAccountName = internalBankAccountName;
    this.internalBankAccountLogo = internalBankAccountLogo;
    this.internalBankAccountAccountName = internalBankAccountAccountName;
    this.internalBankAccountAccountNumber = internalBankAccountAccountNumber;
    this.internalBankAccountIntruction = internalBankAccountIntruction;
  }
}

export class TransactionHistoryEntities {
  pagination: Pagination;
  data: TransactionHistoryData[];
  constructor(pagination: Pagination, data: TransactionHistoryData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class TransactionHistoryData {
  id: number;
  genesisTransactionId: string;
  totalAmount: number;
  bankName: string;
  accountName: string;
  accountNumber: string;
  createdAt: Date;
  createdBy: string;
  status: string;
  rejectedReason: string;

  constructor(
    id: number,
    genesisTransactionId: string,
    totalAmount: number,
    bankName: string,
    accountName: string,
    accountNumber: string,
    createdAt: Date,
    createdBy: string,
    status: string,
    rejectedReason: string
  ) {
    this.id = id;
    this.genesisTransactionId = genesisTransactionId;
    this.totalAmount = totalAmount;
    this.bankName = bankName;
    this.accountName = accountName;
    this.accountNumber = accountNumber;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.status = status;
    this.rejectedReason = rejectedReason;
  }
}
export class TopupManualDetail {
  tumId: number;
  tumlGenesisTransactionId: string;
  tumSenderBankName: string;
  tumSenderBankAccountName: string;
  tumSenderBankAccountNumber: string;
  tumRecepientBankName: string;
  tumRecepientBankAccountName: string;
  tumRecepientBankAccountNumber: string;
  tumAmount: number;
  tumStatus: string;
  tumRejectReason: string;
  tumFileOfProof: string;
  tumActorId: number;
  tumActorType: string;
  tumActorName: string;
  tumActorCode: string;
  tumCreatedAt: string;
  tumCreatedBy: number;
  tumCreatedName: string;
  tumUpdatedAt: string;
  tumUpdatedBy: number;
  tumVerificationBy: number;
  tumVerificationName: string;
  tumVerificationAt: string;
  tumApprovalBy: number;
  tumApprovalName: string;
  tumApprovalAt: string;
  constructor(
    tumId: number,
    tumlGenesisTransactionId: string,
    tumSenderBankName: string,
    tumSenderBankAccountName: string,
    tumSenderBankAccountNumber: string,
    tumRecepientBankName: string,
    tumRecepientBankAccountName: string,
    tumRecepientBankAccountNumber: string,
    tumAmount: number,
    tumStatus: string,
    tumRejectReason: string,
    tumFileOfProof: string,
    tumActorId: number,
    tumActorType: string,
    tumActorName: string,
    tumActorCode: string,
    tumCreatedAt: string,
    tumCreatedBy: number,
    tumCreatedName: string,
    tumUpdatedAt: string,
    tumUpdatedBy: number,
    tumVerificationBy: number,
    tumVerificationName: string,
    tumVerificationAt: string,
    tumApprovalBy: number,
    tumApprovalName: string,
    tumApprovalAt: string
  ) {
    this.tumId = tumId;
    this.tumlGenesisTransactionId = tumlGenesisTransactionId;
    this.tumSenderBankName = tumSenderBankName;
    this.tumSenderBankAccountName = tumSenderBankAccountName;
    this.tumSenderBankAccountNumber = tumSenderBankAccountNumber;
    this.tumRecepientBankName = tumRecepientBankName;
    this.tumRecepientBankAccountName = tumRecepientBankAccountName;
    this.tumRecepientBankAccountNumber = tumRecepientBankAccountNumber;
    this.tumAmount = tumAmount;
    this.tumStatus = tumStatus;
    this.tumRejectReason = tumRejectReason;
    this.tumFileOfProof = tumFileOfProof;
    this.tumActorId = tumActorId;
    this.tumActorType = tumActorType;
    this.tumActorName = tumActorName;
    this.tumActorCode = tumActorCode;
    this.tumCreatedAt = tumCreatedAt;
    this.tumCreatedBy = tumCreatedBy;
    this.tumCreatedName = tumCreatedName;
    this.tumUpdatedAt = tumUpdatedAt;
    this.tumUpdatedBy = tumUpdatedBy;
    this.tumVerificationBy = tumVerificationBy;
    this.tumVerificationName = tumVerificationName;
    this.tumVerificationAt = tumVerificationAt;
    this.tumApprovalBy = tumApprovalBy;
    this.tumApprovalName = tumApprovalName;
    this.tumApprovalAt = tumApprovalAt;
  }
}

export class BalanceReportMutationApiRequest {
  activity = "";
  startDate = "";
  endDate = "";
  transactionType = "";
  actorId = "";
  apiVersion = "";

  constructor(fields?: Partial<BalanceReportMutationApiRequest>) {
    Object.assign(this, fields);
  }
}
