import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import { PaginationV2 } from "@/domain/entities/Pagination";
import {
  ServiceDelayDetail,
  ServiceDelayFormResponse
} from "@/domain/entities/ServiceDelay";
import { DistrictData } from "@/domain/entities/Location";
import { CityData } from "@/domain/entities/Location";

export class ServiceDelayMapper {
  public convertListDataFromApi(result: AxiosResponse<any>): ResponsePayloadV2 {
    const { data } = result;

    return new ResponsePayloadV2({
      success: data.success,
      message: data.message,
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.limit,
        totalData: data.meta.total_records
      }),
      data: data.data.length
        ? data.data.map(
            (item: any) =>
              new ServiceDelayDetail({
                id: item.id,
                name: item.name,
                cityCode: item.city_code,
                allDistrict: item.all_district,
                districts: item.districts
                  ?.map((el: any) => el.name)
                  ?.join(", "),
                districtData: item.districts?.map(
                  (el: any) =>
                    new DistrictData({
                      name: el.name
                    })
                ),
                startDate: item.start_date,
                endDate: item.end_date,
                status: !!item.status.match(/^active$/gi),
                statusData: item.status,
                createdAt: item.created_at,
                createdBy: item.created_by
              })
          )
        : []
    });
  }

  public convertFormDataFromApi(result: AxiosResponse<any>): ResponsePayloadV2 {
    const {
      data: { data }
    } = result;

    return new ResponsePayloadV2({
      data: data?.length
        ? data.map(
            (item: any) =>
              new ServiceDelayFormResponse({
                district: new DistrictData({
                  name: item.district.name,
                  code: item.district.code
                }),
                error: item.error
              })
          )
        : []
    });
  }

  public convertDetailDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = result;

    return new ResponsePayloadV2({
      data: new ServiceDelayDetail({
        id: data.id,
        name: data.name,
        cityCode: data.city_code,
        cityData: new CityData({
          code: data.city_code
        }),
        districts: data.districts?.map((el: any) => el.name)?.join(", "),
        districtData: data.districts?.map(
          (el: any) =>
            new DistrictData({
              sddId: el.sdd_id,
              code: el.code,
              name: el.name,
              status: "ADD"
            })
        ),
        allDistrict: data.all_district,
        remarks: data.remarks,
        startDate: data.start_date,
        endDate: data.end_date,
        status: !!data.status.match(/^active$/gi),
        statusData: data.status,
        createdAt: data.created_at,
        createdBy: data.created_by,
        updatedAt: data.updated_at,
        updatedBy: data.updated_by
      })
    });
  }
}
