import { ManualDeductAddSaldoMapper } from "@/data/persistences/mappers/ManualDeductAddSaldoMapper";
import { ManualDeductAddSaldoInterface } from "@/data/persistences/repositories/contracts/ManualDeductAddSaldoInterface";
import {
  DetailManualDeductAddSaldoData,
  ManualDeductAddSaldoEntities
} from "@/domain/entities/ManualDeductAddSaldo";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { CreateManualDeductAddSaldoRequestInterface } from "@/data/payload/contracts/ManualDeductAddSaldoRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class ManualDeductAddSaldoRepository
  implements ManualDeductAddSaldoInterface {
  private service: ApiService;
  private mapper: ManualDeductAddSaldoMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: ManualDeductAddSaldoMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getDetailManualDeductAddSaldo(
    id: number
  ): Promise<DetailManualDeductAddSaldoData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailManualDeductAddSaldo(id),
      {}
    );
    return this.mapper.convertDetailManualDeductAddSaldo(resp);
  }

  public async createDeductSaldo(
    payload: CreateManualDeductAddSaldoRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invokePrivatePostWithFormData(
      "post",
      this.endpoints.createDeductSaldo(),
      {},
      payload
    );
    return this.mapper.convertChangeDataFromApi(response);
  }

  public async getManualDeductAddSaldoList(
    page: number,
    limit: number,
    search: string,
    actorType: string,
    actorId: number,
    startDate: string,
    endDate: string,
    type: string,
    createdBy: string
  ): Promise<ManualDeductAddSaldoEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getManualDeductAddSaldoList(
        page,
        limit,
        search,
        actorType,
        actorId,
        startDate,
        endDate,
        type,
        createdBy
      ),
      {}
    );

    return this.mapper.convertManualDeductAddSaldoDataFromApi(resp);
  }

  public async getDeductReports(
    adjustmentType: string,
    actorType: string,
    actorId: number,
    startDate: string,
    endDate: string,
    createdBy: number
  ): Promise<any> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDeductReports(
        adjustmentType,
        actorType,
        actorId,
        startDate,
        endDate,
        createdBy
      ),
      {}
    );
    return this.mapper.convertExportReportManualDeduct(resp);
  }
}
