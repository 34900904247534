import {
  InternationalDocumentListEntities,
  RequestInternationalDocumentList,
  RequestInternationalDocumentDetail
} from "@/domain/entities/InternationalDocument";
import { InternationalDocumentEndpoints } from "../../endpoints/horde/InternationalDocumentEndpoints";
import { InternationalDocumentRepositoryInterface } from "@/data/persistences/repositories/contracts/InternationalDocumentInterface";
import { InternationalDocumentMapper } from "@/data/persistences/mappers/InternationalDocumentMapper";
import ApiService from "../../services/ApiService";

export class InternationalDocumentApiRepository implements InternationalDocumentRepositoryInterface {
  private service: ApiService;
  private mapper: InternationalDocumentMapper;
  private endpoints: InternationalDocumentEndpoints;

  constructor(
    service: ApiService,
    mapper: InternationalDocumentMapper,
    endpoints: InternationalDocumentEndpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async getInternationalDocumentList(
    params: RequestInternationalDocumentList
  ): Promise<InternationalDocumentListEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getInternationalDocumentList(params)
    );
    return this.mapper.convertListDataFromApi(resp);
  }

  async getInternationalDocumentDetail(
    params: {
      origin: number,
      dest: number
    }
  ): Promise<RequestInternationalDocumentDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getInternationalDocumentDetail(params)
    );
    return this.mapper.convertDetailDataFromApi(resp);
  }
}