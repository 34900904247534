import { Pagination } from "./Pagination";

export class BalanceEntities {
  pagination: Pagination;
  data: BalanceData[];
  constructor(pagination: Pagination, data: BalanceData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class BalanceData {
  id: number;
  name: string;
  description: string;
  appliedTo: string;
  minBalance: string;
  minWithdraw: number;
  status: string;
  metaCodDelivery: boolean;
  constructor(
    id: number,
    name: string,
    description: string,
    appliedTo: string,
    minBalance: string,
    minWithdraw: number,
    status: string,
    metaCodDelivery: boolean
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.appliedTo = appliedTo;
    this.minBalance = minBalance;
    this.minWithdraw = minWithdraw;
    this.status = status;
    this.metaCodDelivery = metaCodDelivery;
  }
}

export class BalanceDetailData {
  id: number;
  name: string;
  description: string;
  appliedTo: string;
  minBalance: any;
  limit: any;
  minimumWithdrawAmount: any;
  minBalanceMY: any;
  limitMY: any;
  minimumWithdrawAmountMY: any;
  status: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  isCodDelivery?: false;
  constructor(
    id: number,
    name: string,
    description: string,
    appliedTo: string,
    minBalance: any,
    limit: any,
    minimumWithdrawAmount: any,
    minBalanceMY: any,
    limitMY: any,
    minimumWithdrawAmountMY: any,
    status: string,
    createdAt: string,
    updatedAt: string,
    createdBy: string,
    updatedBy: string,
    isCodDelivery?: false
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.appliedTo = appliedTo;
    this.minBalance = minBalance;
    this.limit = limit;
    this.minimumWithdrawAmount = minimumWithdrawAmount;
    this.minBalanceMY = minBalanceMY;
    this.limitMY = limitMY;
    this.minimumWithdrawAmountMY = minimumWithdrawAmountMY;
    this.status = status;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
    this.isCodDelivery = isCodDelivery;
  }
}
