import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";

import store from "@/store";
import { container } from "tsyringe";
import { BalancePresenter } from "@/app/ui/presenters/BalancePresenter";
import { BankAccountEntities } from "@/domain/entities/BankAccount";
import { Pagination } from "@/domain/entities/Pagination";
import {
  BalanceData,
  InternalBankAccountDetail,
  InternalBankAccountList,
  TopupManualDetail,
  TransactionList,
  TransactionHistoryEntities,
  BalanceReportMutationApiRequest
} from "@/domain/entities/Balance";
import {
  AddTopupManualApiRequest,
  GetListBalanceApiRequest,
  WithdrawBalanceApiRequest
} from "@/data/payload/api/BalanceRequest";
import { MainAppController } from "./MainAppController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { ReportController } from "@/app/ui/controllers/ReportController";

export interface BalanceState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "balance"
})
class BalanceStore extends VuexModule implements BalanceState {
  public isLoading = false;
  public isLoadingBalance = false;
  public isError = false;
  public errorCause = "";
  public isSuccessSubmit = false;
  public isLoadingSubmit = false;
  public isErrorSubmit = false;
  public errorCauseSubmit = "";
  public isOpenModalTopup = false;
  public isOpenModalWithdraw = false;
  public showTopupTransactionHistory = false;
  public showTopupUploadForm = false;
  public showModalDetailCashback = false;
  public search = "";
  public status = "";
  public periodeStart: any = null;
  public periodeEnd: any = null;
  public filter = { name: "", value: "" };
  public startDate: any = "";
  public endDate: any = "";
  public firstRequest = true;
  public listBankVirtualAccount: BankAccountEntities = new BankAccountEntities(
    new Pagination(1, 99),
    []
  );
  public listBankManual: InternalBankAccountList = new InternalBankAccountList(
    []
  );
  public detailBankManual: InternalBankAccountDetail = new InternalBankAccountDetail(
    0,
    "",
    "",
    "",
    "",
    ""
  );
  public transactionHistoryList = new TransactionHistoryEntities(
    new Pagination(1, 10),
    []
  );
  public selectedBankVirtualAccount = { name: "", value: 0 };
  public selectedBankManual = { name: "", value: 0 };
  public balanceData = new BalanceData();
  public transactionList = new TransactionList(new Pagination(1, 20), []);
  public formTopupManual = {
    fromBankName: "",
    fromBankAccountName: "",
    fromBankNumber: "",
    toBankName: { name: "", value: 0 },
    toBankAccountName: "",
    toBankNumber: "",
    amount: "",
    attachment: "" as any
  };
  public topupManualIdToDetail = 0;
  public isLoadingDetail = false;
  public isErrorDetail = false;
  public errorCauseDetail = "";
  public isShowRequestTopupManualDetail = false;
  public modalSuccess = false;
  public modalDownloadMutation = false;
  public requestTopupManualDetail: TopupManualDetail = new TopupManualDetail(
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0,
    "",
    "",
    "",
    0,
    "",
    "",
    "",
    "",
    0,
    "",
    "",
    0,
    0,
    "",
    "",
    0,
    "",
    ""
  );

  @Action
  public async getListBankVirtualAccount() {
    const presenter = container.resolve(BalancePresenter);
    presenter
      .getListBankAccount(1, 20, "virtual_account")
      .then((res: BankAccountEntities) => {
        // hide permata va
        res.data = res.data.filter(
          e => !e.name.toLowerCase().includes("permata")
        );

        this.setListBankVirtualAccount(res);
        this.setSelectedBankVirtualAccount({
          name: res.data[0].label,
          value: res.data[0].id
        });
      })
      .catch(() => {
        this.setListBankVirtualAccount(
          new BankAccountEntities(new Pagination(1, 20), [])
        );
      });
  }

  @Action
  public async getListBankManual() {
    const presenter = container.resolve(BalancePresenter);
    presenter
      .getListInternalBankAccount()
      .then(res => {
        this.setListBankManual(res);
        this.setSelectedBankManual({
          name: res.data[0].internalBankAccountName,
          value: res.data[0].internalBankAccountId
        });
        this.getDetailBankManual(res.data[0].internalBankAccountId);
      })
      .catch(() => {
        this.setListBankManual(new InternalBankAccountList([]));
      });
  }

  @Action
  public async getDetailBankManual(id: number) {
    const presenter = container.resolve(BalancePresenter);
    presenter
      .getDetailInternalBankAccount(id)
      .then(res => {
        this.setDetailBankManual(res);
      })
      .catch(() => {
        this.setDetailBankManual(
          new InternalBankAccountDetail(0, "", "", "", "", "")
        );
      });
  }

  @Action
  public async getDetailTopupManual() {
    this.setLoadingDetail(true);
    const presenter = container.resolve(BalancePresenter);
    presenter
      .getDetailTopupManual(this.topupManualIdToDetail)
      .then(res => {
        this.setDetailTopupManual(res);
        this.setErrorDetail(false);
        this.setErrorCauseDetail("");
      })
      .catch(err => {
        this.setErrorDetail(true);
        this.setErrorCauseDetail(err.response ? "server" : "internet");
      })
      .finally(() => this.setLoadingDetail(false));
  }

  @Action
  public async getBalance(cache: boolean) {
    this.setLoadingBalance(true);
    const presenter = container.resolve(BalancePresenter);
    return presenter
      .getBalance(cache)
      .then(res => {
        this.setBalance(res);
        this.setError(false);
        this.setErrorCause("");
        return res;
      })
      .catch(err => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => this.setLoadingBalance(false));
  }

  @Action
  public initTransactionList() {
    this.setError(false);
    this.setErrorCause("");
    this.setSearch("");
    this.setFirstPage();
    this.setFilter({ name: "", value: "" });
    this.setStartDate("");
    this.setEndDate("");
  }

  @Action
  public fetchTransactionList(apiVersion: string) {
    this.setError(false);
    this.setErrorCause("");
    this.getListBalance({
      params: new GetListBalanceApiRequest({
        page: this.transactionList.pagination.page,
        limit: this.transactionList.pagination.limit,
        search: this.search,
        startDate: this.startDate
          ? this.startDate.toISOString().slice(0, 10)
          : "",
        endDate: this.endDate ? this.endDate.toISOString().slice(0, 10) : "",
        type: this.filter.value,
        cache: apiVersion === "v1",
      }),
      apiVersion
    });
  }

  @Action
  public getListBalance(payload: {
    params: GetListBalanceApiRequest;
    apiVersion: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(BalancePresenter);
    presenter
      .getTransactionList(payload.params, payload.apiVersion)
      .then(res => {
        this.setTransactionList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(err => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  public getTransactionHistoryList(params: {
    page: number;
    limit: number;
    status: string;
    startDate: string;
    endDate: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(BalancePresenter);
    presenter
      .getTransactionHistoryList(
        params.page,
        params.limit,
        params.status,
        params.startDate,
        params.endDate
      )
      .then(res => {
        this.setTransactionHistoryList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(err => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  public addTopupManual(params: {
    amount: number;
    senderBankName: string;
    senderBankAccountName: string;
    senderBankAccountNumber: string;
    recepientBankName: string;
    recepientBankAccountName: string;
    recepientBankAccountNumber: string;
    attachment: any;
  }) {
    MainAppController.showLoading();
    const presenter = container.resolve(BalancePresenter);
    presenter
      .addTopupManual(
        new AddTopupManualApiRequest(
          params.amount,
          params.senderBankName,
          params.senderBankAccountName,
          params.senderBankAccountNumber,
          params.recepientBankName,
          params.recepientBankAccountName,
          params.recepientBankAccountNumber,
          params.attachment
        )
      )
      .then(() => {
        this.setSuccessSubmit(true);
        this.setErrorSubmit(false);
        this.setErrorCauseSubmit("");
        this.resetFormTopupManual();
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            err,
            "Persetujuan Gagal !",
            () => {
              MainAppController.closeErrorMessage();
              this.addTopupManual(params);
            },
            () => this.setShowTopupUploadForm(true)
          )
        );
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public fetchTransactionHistoryList() {
    this.setError(false);
    this.setErrorCause("");
    this.setFirstRequest(false);
    this.getTransactionHistoryList({
      page: this.transactionHistoryList.pagination.page,
      limit: this.transactionHistoryList.pagination.limit,
      status: this.status,
      startDate: this.periodeStart
        ? new Date(this.periodeStart).toLocaleDateString("fr-CA")
        : "",
      endDate: this.periodeEnd
        ? new Date(this.periodeEnd).toLocaleDateString("fr-CA")
        : ""
    });
  }

  @Action
  public initDataTableTransactionHistoryList() {
    this.setError(false);
    this.setErrorCause("");
    this.setFirstRequest(true);
  }

  @Mutation
  public setTransactionList(val: TransactionList) {
    this.transactionList = val;
  }

  @Mutation
  public onResetBalance() {
    this.balanceData = new BalanceData();
  }

  @Mutation
  public setBalance(val: BalanceData) {
    this.balanceData = val;
  }

  @Mutation
  public setError(val: boolean) {
    this.isError = val;
  }

  @Mutation
  public setErrorCause(val: string) {
    this.errorCause = val;
  }

  @Mutation
  public setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  public setLoadingBalance(val: boolean) {
    this.isLoadingBalance = val;
  }

  @Mutation
  public setErrorDetail(val: boolean) {
    this.isErrorDetail = val;
  }

  @Mutation
  public setErrorCauseDetail(val: string) {
    this.errorCauseDetail = val;
  }

  @Mutation
  public setLoadingDetail(val: boolean) {
    this.isLoadingDetail = val;
  }

  @Mutation
  public setErrorSubmit(val: boolean) {
    this.isErrorSubmit = val;
  }

  @Mutation
  public setErrorCauseSubmit(val: string) {
    this.errorCauseSubmit = val;
  }

  @Mutation
  public setLoadingSubmit(val: boolean) {
    this.isLoadingSubmit = val;
  }

  @Mutation
  public setSearch(val: string) {
    this.search = val;
  }

  @Mutation
  public setFilter(val: any) {
    this.filter = val;
  }

  @Mutation
  public setStartDate(val: any) {
    this.startDate = val;
  }

  @Mutation
  public setEndDate(val: any) {
    this.endDate = val;
  }

  @Mutation
  public setOpenModalTopup(boolean: boolean) {
    this.isOpenModalTopup = boolean;
  }

  @Mutation
  public setOpenModalWithdraw(boolean: boolean) {
    this.isOpenModalWithdraw = boolean;
  }

  @Mutation
  public setListBankVirtualAccount(value: BankAccountEntities) {
    this.listBankVirtualAccount = value;
  }

  @Mutation
  public setSelectedBankVirtualAccount(item: { name: string; value: number }) {
    this.selectedBankVirtualAccount = { name: item.name, value: item.value };
  }

  @Mutation
  public setShowTopupTransactionHistory(val: boolean) {
    this.showTopupTransactionHistory = val;
  }

  @Mutation
  public setShowTopupUploadForm(val: boolean) {
    this.showTopupUploadForm = val;
  }
  @Mutation
  public setShowModalDetailCashback(val: boolean) {
    this.showModalDetailCashback = val;
  }
  @Mutation
  public setListBankManual(val: InternalBankAccountList) {
    this.listBankManual = val;
  }

  @Mutation
  public setSelectedBankManual(val: { name: string; value: number }) {
    this.selectedBankManual = val;
  }

  @Mutation
  public setDetailBankManual(val: InternalBankAccountDetail) {
    this.detailBankManual = val;
  }

  @Mutation
  public setFirstPage() {
    this.transactionList.pagination.page = 1;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Mutation
  public resetFormTopupManual() {
    this.formTopupManual = {
      fromBankName: "",
      fromBankAccountName: "",
      fromBankNumber: "",
      toBankName: { name: "", value: 0 },
      toBankAccountName: "",
      toBankNumber: "",
      amount: "",
      attachment: "" as any
    };
  }

  @Mutation
  public setSuccessSubmit(val: boolean) {
    this.isSuccessSubmit = val;
  }

  @Mutation
  public setTransactionHistoryList(val: any) {
    this.transactionHistoryList = val;
  }

  @Mutation
  public setShowRequestTopupManualDetail(val: boolean) {
    this.isShowRequestTopupManualDetail = val;
  }

  @Mutation
  public setDetailTopupManual(val: TopupManualDetail) {
    this.requestTopupManualDetail = val;
  }

  @Mutation
  public setPeriodeStart(value: Date | null) {
    this.periodeStart = value;
  }

  @Mutation
  public setPeriodeEnd(value: Date | null) {
    this.periodeEnd = value;
  }

  @Mutation
  public setStatus(val: string) {
    this.status = val;
  }

  @Mutation
  public setTopupManualIdToDetail(val: number) {
    this.topupManualIdToDetail = val;
  }

  @Mutation
  public setModalSuccess(boolean: boolean) {
    this.modalSuccess = boolean;
  }

  @Mutation
  public setModalDownloadMutation(boolean: boolean) {
    this.modalDownloadMutation = boolean;
  }

  @Action
  public withdrawBalance(params: { amount: number, version: string }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BalancePresenter);
    return presenter
      .withdrawBalance(new WithdrawBalanceApiRequest(params.amount), params.version)
      .then(() => {
        return true;
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Permintaan Gagal !")
        );
        return false;
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public async getBalanceClientPrtner(params: any) {
    const presenter = container.resolve(BalancePresenter);
    return presenter
      .getBalanceClientPrtner(params)
      .then(res => {
        return res;
      })
      .catch(() => {
        return new BalanceData();
      });
  }

  @Action
  public async getDownloadBalanceReportMutation(
    params: BalanceReportMutationApiRequest
  ) {
    MainAppController.showLoading();
    const presenter = container.resolve(BalancePresenter);
    return presenter
      .getDownloadBalanceReportMutation(params)
      .then(() => {
        ReportController.onGetListDownloadReport();
        ReportController.setOpenDownloadRequest(true);
        this.setModalDownloadMutation(false);
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Download Mutasi Saldo Gagal!", () => {
            this.getDownloadBalanceReportMutation(params);
            MainAppController.closeErrorMessage();
          })
        );
      })
      .finally(() => MainAppController.closeLoading());
  }
}

export const BalanceController = getModule(BalanceStore);
