import { ConfigPriceDataMapper } from "@/data/persistences/mappers/ConfigPriceMapper";
import { ConfigPriceRepositoryInterface } from "@/data/persistences/repositories/contracts/ConfigPriceRepositoryInterface";
import { Entities } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class ConfigPriceApiRepository
  implements ConfigPriceRepositoryInterface {
  private service: ApiService;
  private mapper: ConfigPriceDataMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: ConfigPriceDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getList(
    page: number,
    search: string,
    status: string
  ): Promise<Entities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListConfigPrice(page, search, status),
      {}
    );
    return this.mapper.convertConfigPriceFromApi(resp);
  }
}
