import { ShortlandApiRepository } from "@/app/infrastructures/repositories/api/ShortlandApiRepository";
import { injectable } from "tsyringe";
import { ShortlandEntities } from "@/domain/entities/Shortland";

@injectable()
export class ShortlandPresenter {
  private repository: ShortlandApiRepository;

  constructor(repository: ShortlandApiRepository) {
    this.repository = repository;
  }

  public getShortlandList(
    page: number,
    limit: number,
    startDate: string,
    endDate: string,
    search: string
  ): Promise<ShortlandEntities> {
    return this.repository.getShortlandList(
      page,
      limit,
      startDate,
      endDate,
      search
    );
  }
}
