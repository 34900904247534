import {
  ConfigPriceData,
  ConfigPriceDetail
} from "@/domain/entities/ConfigPrice";
import { Pagination } from "@/domain/entities/Pagination";
import { Entities, ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class ConfigPriceDataMapper {
  public convertConfigPriceFromApi(result: AxiosResponse<any>): Entities {
    const { data } = result;
    return new Entities(
      data.success,
      data.code,
      data.message,
      new Pagination(data.meta.page, data.meta.limit),
      data.data.length === 0
        ? []
        : data.data.map(
            (item: any) =>
              new ConfigPriceData({
                id: item.configurable_price_id,
                configurablePriceName: item.configurable_price_name,
                configurablePriceType: item.configurable_price_type,
                configurablePriceDescription:
                  item.configurable_price_description,
                configurablePriceStatus: item.configurable_price_status,
                configurablePriceCreatedAt: item.configurable_price_created_at,
                configurablePriceCreatedBy: item.configurable_price_created_by,
                configurablePriceUpdatedAt: item.configurable_price_updated_at,
                configurablePriceUpdatedBy: item.configurable_price_updated_by,
                configurablePriceDetail: !item.configurable_price_detail
                  ? []
                  : item.configurable_price_detail.map((items: any) => {
                      return new ConfigPriceDetail({
                        heavyweightSurchargeId: items.heavy_weight_surcharge_id,
                        heavyweightSurchargeCommodityInclude:
                          items.heavy_weight_surcharge_commodity_include,
                        heavyweightSurchargeCreatedAt:
                          items.heavy_weight_created_at,
                        heavyweightSurchargeCreatedBy:
                          items.heavy_weight_created_by,
                        heavyweightSurchargeUpdatedAt:
                          items.heavy_weight_updated_at,
                        heavyweightSurchargeUpdatedBy:
                          items.heavy_weight_updated_by,
                        heavyweightSurchargeStatus: items.heavy_weight_status
                      });
                    })
              })
          )
    );
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
