import { CodIncomeApiRepository } from "@/app/infrastructures/repositories/api/CodIncomeApiRepository";
import { CodIncomeEntities } from "@/domain/entities/CodIncome";
import { injectable } from "tsyringe";

@injectable()
export class CodIncomePresenter {
  private repository: CodIncomeApiRepository;

  constructor(repo: CodIncomeApiRepository) {
    this.repository = repo;
  }

  public getCodIncomeList(
    page: number,
    limit: number,
    type: string,
    startDate: string,
    endDate: string,
    search: string
  ): Promise<CodIncomeEntities> {
    return this.repository.getCodIncomeList(
      page,
      limit,
      type,
      startDate,
      endDate,
      search
    );
  }

  public getExportCodTransaction(
    page: number,
    type: string,
    startDate: string,
    endDate: string
  ): Promise<string> {
    return this.repository.getExportCodTransaction(
      page,
      type,
      startDate,
      endDate
    );
  }
}
