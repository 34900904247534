import { LegApiRepository } from "@/app/infrastructures/repositories/api/LegApiRepository";
import { LegEntities, LegData, TransportData } from "@/domain/entities/Leg";
import { injectable } from "tsyringe";
import { EditLegRequestInterface } from "@/data/payload/contracts/LegRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { RequestListRouteLeg } from "@/data/payload/api/LegApiRequest";

@injectable()
export class LegPresenter {
  private repository: LegApiRepository;

  constructor(repo: LegApiRepository) {
    this.repository = repo;
  }

  public getLegList(
    page: number,
    limit: number,
    status: string,
    originId: string,
    destinationId: string,
    sortBy: string
  ): Promise<LegEntities> {
    return this.repository.getLegList(
      page,
      limit,
      status,
      originId,
      destinationId,
      sortBy
    );
  }

  public getLegDetail(id: number): Promise<LegData> {
    return this.repository.getDetailLeg(id);
  }

  public editLeg(payload: EditLegRequestInterface): Promise<ResponsePayload> {
    return this.repository.editLeg(payload);
  }

  public getTransportDetail(id: number): Promise<TransportData> {
    return this.repository.getDetailTransport(id);
  }

  public getListRouteLeg(params: RequestListRouteLeg): Promise<LegData[]> {
    return this.repository.getListRouteLeg(params);
  }
}
