import { CityData, DistrictData } from "@/domain/entities/Location";
import { Pagination } from "@/domain/entities/Pagination";
import {
  PosEntities,
  PosData,
  PosPartnerLocation
} from "@/domain/entities/Pos";
import { AxiosResponse } from "axios";

export class PosMapper {
  mappingPosType(type: string) {
    const mapping: any = {
      "branch": "POS Child",
      "pickup": "POS Dipickup",
      "parent": "POS Parent"
    };
    return mapping[type];
  }
  public convertListPosDataFromApi(res: AxiosResponse<any>): PosEntities {
    const { data } = res;
    const posData: PosData[] = [];
    data.data.map((item: any) => {
      posData.push(
        new PosData(
          item.id,
          item.name,
          item.address,
          item.balance,
          item.code,
          item.lat,
          item.long,
          this.mappingPosType(item.pos_type),
          item.type,
          item.created_at,
          item.updated_at,
          item.parent_id,
          item.parent_name,
          item.parent_type,
          item.contract_end,
          item.contract_status,
          item.is_banned,
          item.is_registry,
          new PosPartnerLocation(
            item.partner_location?.id,
            item.partner_location?.partner_id,
            item.partner_location?.district_code,
            item.partner_location?.city_code,
            item.partner_location?.created_at,
            item.partner_location?.updated_at,
            new CityData({
              id: item.partner_location?.city?.id,
              name: item.partner_location?.city?.name,
              isActive: item.partner_location?.city?.is_active,
              code: item.partner_location?.city?.code,
              timezone: item.partner_location?.city?.timezone,
              freeTradeZone: item.partner_location?.city?.free_trade_zone,
              countryId: item.partner_location?.city?.country_id,
              originTransit: "",
              destinationTransit: "",
              createdBy: item.partner_location?.city?.created_by,
              updatedBy: item.partner_location?.city?.updated_by,
              createdAt: item.partner_location?.city?.created_at,
              updateAt: item.partner_location?.city?.updated_at,
              originCodeTransit: item.partner_location?.city?.origin_transit,
              destinationCodeTransit:
                item.partner_location?.city?.destination_transit
            }),
            new DistrictData({
              id: item.partner_location?.district?.id,
              code: item.partner_location?.district?.code,
              name: item.partner_location?.district?.name,
              type: item.partner_location?.district?.type,
              cityId: item.partner_location?.district?.city_id,
              ursaCode: item.partner_location?.district?.ursa_code,
              originZipCode: item.partner_location?.district?.origin_zip_code,
              destinationZipCode:
                item.partner_location?.district?.destination_zip_code,
              vendorCode: item.partner_location?.district?.vendor_code,
              status: item.partner_location?.district?.status,
              isCod: item.partner_location?.district?.is_cod,
              isPtPos: item.partner_location?.district?.is_ptpos,
              createdBy: item.partner_location?.district?.created_by,
              updatedBy: item.partner_location?.district?.updated_by,
              createdAt: item.partner_location?.district?.created_at,
              updateAt: item.partner_location?.district?.updated_at
            })
          )
        )
      );
    });
    return new PosEntities(
      new Pagination(data.meta.page, data.meta.limit),
      posData
    );
  }
}
