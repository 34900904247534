/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionShopify = {
    form_enable: new Rox.Flag(false)
};

export function initFlagsPermissionShopify() {
  // register flags with namespaces for better grouping
  Rox.register("featureShopify", FlagsPermissionShopify);
}
