import { CUSTOM_PROCESS } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

// Custom Process
const UpdateStatus = () =>
  import(
    /* webpackChunkName: "CustomProcess" */ "@/app/ui/views/custom-process/component/update/index.vue"
  );

const DetailStatus = () =>
  import(
    /* webpackChunkName: "CustomProcess" */ "@/app/ui/views/custom-process/component/detail.vue"
  );

export default [
  {
    path: "/custom-status",
    component: Layout,
    name: "update-status",
    meta: {
      hidden: true,
      parent: "Custom Process",
      permission: CUSTOM_PROCESS.CREATE,
      isFreeze: true
    },
    children: [
      {
        path: "update",
        name: "update-status",
        component: UpdateStatus,
        meta: {
          header: true,
          title: "Custom Process | Update Custom Process",
          name: "Update Status",
          before: "/custom-process"
        }
      }
    ]
  },
  {
    path: "/custom-status",
    redirect: "/custom-status",
    component: Layout,
    name: "detail-custom-status",
    meta: {
      hidden: true,
      parent: "Custom Process",
      permission: CUSTOM_PROCESS.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-custom-status",
        component: DetailStatus,
        meta: {
          header: true,
          title: "Custom Process | Detail Custom Process"
        }
      }
    ]
  }
];
