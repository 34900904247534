import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { CodIncomeEntities, CodIncomeData } from "@/domain/entities/CodIncome";
import { AxiosResponse } from "axios";

export class CodIncomeMapper {
  public convertCodIncomeDataFromApi(
    res: AxiosResponse<any>
  ): CodIncomeEntities {
    const { data } = res;

    if (data.data.length === 0) {
      return new CodIncomeEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      return new CodIncomeEntities(
        new Pagination(data.meta.page, data.meta.limit),
        data.data.map(
          (item: any) =>
            new CodIncomeData(
              item.pg_trx_no,
              item.type,
              item.activity,
              item.amount_transaction,
              item.date_transaction,
              item.transaction_made_by,
              item.bank_name,
              item.bank_account_number,
              item.bank_account_name,
              item.genesis_reference_id,
              item.stt_number,
              item.status,
              item.note,
              item.stt_elexys_number,
              item.last_balance_cod
            )
        )
      );
    }
  }

  public convertPayloadData(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertExportData(result: AxiosResponse): string {
    const { data } = result;
    return data;
  }
}
