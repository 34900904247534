import { ApiRequestList } from "@/domain/entities/MainApp";

export class ConfigFakeDexIndicationEndpoint {
  // Landing page
  getList(params: ApiRequestList): string {
    return `horde/v1/dex-fake-dex-configuration?${params.toQueryString()}`;
  }
  getDetail(id: any): string {
    return `horde/v1/dex-fake-dex-configuration/${id}`;
  }
  editConfig(id: any): string {
    return `horde/v1/dex-fake-dex-configuration/${id}`
  }
}
