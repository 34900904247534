import { injectable } from "tsyringe";
import { DexAssessmentApiRepository } from "@/app/infrastructures/repositories/api/DexAssessmentApiRepository";
import {
  DexAssessmentList,
  DexAssessmentSummary,
  RequestDexAssessmentL1,
  RequestDexAssessmentSummary
} from "@/domain/entities/DexAssessment";
import {
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";
import { DexAssesmentRequestInterface } from "@/data/payload/contracts/DexAssessmentRequest";
import { ApiRequestList } from "@/domain/entities/MainApp";

@injectable()
export class DexAssessmentPresenter {
  private repository: DexAssessmentApiRepository;

  constructor(repository: DexAssessmentApiRepository) {
    this.repository = repository;
  }
  public getDexAssessmentList(
    params: RequestDexAssessmentL1
  ): Promise<DexAssessmentList> {
    return this.repository.getDexAssessmentList(params);
  }
  public getDownloadReport(params: ApiRequestList): Promise<DexAssessmentList> {
    return this.repository.getDownloadReport(params);
  }
  public getDexAssessmentSummary(
    params: RequestDexAssessmentSummary
  ): Promise<DexAssessmentSummary> {
    return this.repository.getDexAssessmentSummary(params);
  }
  public getDetailDexAssessment(id: any): Promise<ResponsePayloadV2> {
    return this.repository.getDetailDexAssesment(id);
  }
  public assignDexAssessment(): Promise<ResponsePayload> {
    return this.repository.assignDexAssessment();
  }
  public submitTicketDexAssesmentL1(
    id: any,
    payload: DexAssesmentRequestInterface
  ): Promise<ResponsePayloadV2> {
    return this.repository.submitTicketDexAssesmentL1(id, payload);
  }
  public getSummaryTicket(params: ApiRequestList): Promise<ResponsePayloadV2> {
    return this.repository.getSummaryTicket(params);
  }
  public getSummaryTotalCs(params: ApiRequestList): Promise<ResponsePayloadV2> {
    return this.repository.getSummaryTotalCs(params);
  }
  public getSummaryTicketPercentageDisputeL2(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    return this.repository.getSummaryTicketPercentageDisputeL2(params);
  }
  public getSummaryTicketPercentage(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    return this.repository.getSummaryTicketPercentage(params);
  }
  public getSummaryTicketBasedRangeTime(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    return this.repository.getSummaryTicketBasedRangeTime(params);
  }
  public getSummaryFake(params: ApiRequestList): Promise<ResponsePayloadV2> {
    return this.repository.getSummaryFake(params);
  }
  public getSummaryTopDex(params: ApiRequestList): Promise<ResponsePayloadV2> {
    return this.repository.getSummaryTopDex(params);
  }
  public getSummaryBacklog(params: ApiRequestList): Promise<ResponsePayloadV2> {
    return this.repository.getSummaryBacklog(params);
  }
  public getTotalTicketPerDay(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    return this.repository.getTotalTicketPerDay(params);
  }
  public getSummaryAttempt(params: ApiRequestList): Promise<ResponsePayloadV2> {
    return this.repository.getSummaryAttempt(params);
  }
  public getSummaryByReason(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    return this.repository.getSummaryByReason(params);
  }
  public getDownload(params: ApiRequestList): Promise<ResponsePayloadV2> {
    return this.repository.getDownload(params);
  }
  public getAgentList(params: ApiRequestList): Promise<ResponsePayloadV2> {
    return this.repository.getAgentList(params);
  }
  public getBacklogAgent(params: ApiRequestList): Promise<ResponsePayloadV2> {
    return this.repository.getBacklogAgent(params);
  }
  public getDashboardConsole(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    return this.repository.getDashboardConsole(params);
  }
}
