import { injectable } from "tsyringe";
import { AuthNGenEntities } from "@/domain/entities/NGen";
import { AuthNGenApiRepository } from "@/app/infrastructures/repositories/api/AuthNGenApiRepository";

@injectable()
export class AuthNGenPresenter {
  private repository: AuthNGenApiRepository;

  constructor(repository: AuthNGenApiRepository) {
    this.repository = repository;
  }

  public loginNGen(): Promise<AuthNGenEntities> {
    return this.repository.loginNGen();
  }
}
