import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ProgressiveCommissionConfigurationRepositoryInterface } from "@/data/persistences/repositories/contracts/ProgressiveCommissionConfigurationRepositoryInterface";
import { ProgressiveCommissionConfigurationMapper } from "@/data/persistences/mappers/ProgressiveCommissionConfigurationMapper";
import {
  ProgressiveCommissionList,
  ProgressiveCommissionDetail
} from "@/domain/entities/ProgressiveCommissionConfiguration";
import {
  ProgressiveCommissionListRequest,
  SubmitProgressiveCommissionConfigurationRequest
} from "@/data/payload/api/ProgressiveCommissionConfigurationApiRequest";
import { ProgressiveCommissionConfigurationRequest } from "@/data/payload/contracts/ProgressiveCommissionConfigurationRequest";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";

export class ProgressiveCommissionConfigurationApiRepository
  implements ProgressiveCommissionConfigurationRepositoryInterface {
  private service: ApiService;
  private mapper: ProgressiveCommissionConfigurationMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: ProgressiveCommissionConfigurationMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async getProgressiveCommissionList(
    params: ProgressiveCommissionListRequest
  ): Promise<ProgressiveCommissionList> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getProgressiveCommissionList(params)
    );

    return this.mapper.convertProgressiveCommissionListFromApi(res);
  }

  async getProgressiveCommissionDetail(
    id: number
  ): Promise<ProgressiveCommissionDetail> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getProgressiveCommissionDetail(id)
    );

    return this.mapper.convertProgressiveCommissionDetailFromApi(res);
  }

  async addProgressiveCommissionConfiguration(
    payload: ProgressiveCommissionConfigurationRequest
  ): Promise<ResponsePayloadV2> {
    const res = await this.service.invoke(
      "POST",
      this.endpoints.progressiveCommissionConfigApi(),
      {},
      payload as SubmitProgressiveCommissionConfigurationRequest
    );
    return this.mapper.convertSubmitDataFromApi(res);
  }

  async editProgressiveCommissionConfiguration(
    payload: ProgressiveCommissionConfigurationRequest
  ): Promise<ResponsePayloadV2> {
    const requestPayload = payload as SubmitProgressiveCommissionConfigurationRequest;
    const res = await this.service.invoke(
      "PUT",
      this.endpoints.progressiveCommissionConfigApi(
        requestPayload.id.toString()
      ),
      {},
      requestPayload
    );
    return this.mapper.convertSubmitDataFromApi(res);
  }
}
