import { PRICING_BASE_RATE } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const DetailBaseRates = () =>
  import(
    /* webpackChunkName: "BaseRate" */ "@/app/ui/views/pricing/base-rate/component/detail.vue"
  );
const EditBaseRates = () =>
  import(
    /* webpackChunkName: "BaseRate" */ "@/app/ui/views/pricing/base-rate/component/edit.vue"
  );

export default [
  {
    path: "/tarif/base-rate",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Base Rate",
      permission: PRICING_BASE_RATE.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-retail-version-rates",
        component: DetailBaseRates,
        meta: {
          header: true,
          title: "Base Rate | Detail"
        }
      }
    ]
  },
  {
    path: "/tarif/base-rate",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Base Rate",
      permission: PRICING_BASE_RATE.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-base-rate",
        component: EditBaseRates,
        meta: {
          header: true,
          title: "Base Rate | Edit",
          name: "Edit",
          before: "/tarif/base-rate/:id"
        }
      }
    ]
  }
];
