import { RouteModifyRequestInterface } from "@/data/payload/contracts/RouteRequest";
import { RouteDataMapper } from "@/data/persistences/mappers/RouteMapper";
import { RouteRepositoryInterface } from "@/data/persistences/repositories/contracts/RouteRepositoryInterface";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { RouteData, RouteEntities } from "@/domain/entities/Route";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { EditRouteApiRequest } from "@/data/payload/api/RouteRequest";

export class RouteApiRepository implements RouteRepositoryInterface {
  private service: ApiService;
  private mapper: RouteDataMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: RouteDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getList(
    page: number,
    limit: number,
    status: string,
    originId: string,
    destinationId: string,
    sortBy: string
  ): Promise<RouteEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getRoute(
        page,
        limit,
        status,
        originId,
        destinationId,
        sortBy
      ),
      {}
    );
    return this.mapper.convertRouteFromApi(resp);
  }

  public async getDetail(id: number): Promise<RouteData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailRoute(id),
      {}
    );
    return this.mapper.convertDetailRouteDataFromApi(resp);
  }

  public async edit(
    payload: RouteModifyRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "put",
      this.endpoints.editRoute(),
      undefined,
      payload as EditRouteApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }
}
