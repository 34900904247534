import { FlagsPermissionReport } from "@/feature-flags/flag-report";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const Report = () =>
  import(/* webpackChunkName: "Report" */ "@/app/ui/views/report/index.vue");

export default [
  {
    path: "/reports",
    component: Layout,
    name: "report",
    meta: {
      icon: "report",
      children: false,
      title: "Laporan",
      name: "Laporan",
      permission: FlagsPermissionReport.report_enable
    },
    children: [
      {
        path: "/reports",
        name: "Laporan",
        component: Report,
        meta: {
          header: true
        }
      }
    ]
  }
];
