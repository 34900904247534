import {
  BulkDetail,
  BulkEntities,
  BulkListData,
  Pagination,
  VersionNameData
} from "@/domain/entities/Bulk";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class BulkDataMapper {
  public convertBulkDataFromApi(result: AxiosResponse<any>): BulkEntities {
    const { data } = result;

    const bulkListData: BulkListData[] = [] || null;
    if (data.data === []) {
      [];
    } else {
      data.data.forEach((item: any) => {
        bulkListData.push(
          new BulkListData(
            item.upload_id,
            item.activity_name,
            item.archive_type,
            item.status,
            item.created_by,
            item.created_at,
            item.archive_file,
            item.failed_file,
            item.data_processed_current,
            item.total_data_current
          )
        );
      });
    }

    const pagination = new Pagination(data.meta.page, data.meta.limit);

    return new BulkEntities(data.message, bulkListData, pagination);
  }

  public convertBulkUploadDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertDetailBulkDataFromApi(result: AxiosResponse<any>): BulkDetail {
    const {
      data: { data }
    } = result;

    return new BulkDetail(
      data.id,
      data.activity_name,
      data.archive_type,
      data.status,
      data.created_name,
      data.created_email,
      data.name_version,
      data.periode,
      data.archive_file,
      data.rate_active_date,
      data.archive_file_name,
      data.failed_file,
      data.failed_file_name,
      data.created_at,
      data.data_processed_current,
      data.total_data_current,
      data.client_name
    );
  }

  public convertVersionNameDataFromApi(
    result: AxiosResponse<any>
  ): VersionNameData[] {
    const {
      data: { data }
    } = result;
    return data.length === 0
      ? []
      : data.map((item: any) => {
          return new VersionNameData(
            item.rate_version_id,
            item.rate_version_name,
            item.rate_version_start_date,
            item.rate_version_end_date,
            item.rate_version_created_at
          );
        });
  }
}
