import { BaggingApiRepository } from "@/app/infrastructures/repositories/api/BaggingApiRepository";
import { injectable } from "tsyringe";
import {
  BaggingToReadyToCargoRequestInterface,
  ChangeBaggingRequestInterface,
  UpdateBaggingInterface
} from "@/data/payload/contracts/BaggingRequest";
import {
  BaggingDataDetail,
  BaggingEntities,
  BaggingSttList,
  RegionBaggingGrouping
} from "@/domain/entities/Bagging";
import { ResponsePayload, ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { BaggingListRequest } from "@/data/payload/api/BaggingApiRequest";

@injectable()
export class BaggingPresenter {
  private repository: BaggingApiRepository;

  constructor(repository: BaggingApiRepository) {
    this.repository = repository;
  }

  public getListBagging(params: BaggingListRequest): Promise<BaggingEntities> {
    return this.repository.getListBagging(params);
  }

  public getDetailBagging(id: number): Promise<BaggingDataDetail> {
    return this.repository.getDetailBagging(id);
  }

  public getDetailSttNumber(
    sttNumber: string,
    partnerId?: number
  ): Promise<BaggingSttList> {
    return this.repository.getDetailSttNumber(sttNumber, partnerId);
  }

  public async generateBagging(
    payload: ChangeBaggingRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.createBagging(payload);
  }

  public updateBagging(
    payload: UpdateBaggingInterface
  ): Promise<ResponsePayload> {
    return this.repository.updateBagging(payload);
  }

  public async downloadBagging(id: number): Promise<any> {
    return this.repository.downloadBagging(id);
  }

  public getRegionBaggingGrouping(
    code: string
  ): Promise<RegionBaggingGrouping> {
    return this.repository.getRegionBaggingGrouping(code);
  }

  public updateBaggingToReadyToCargo(payload: BaggingToReadyToCargoRequestInterface): Promise<ResponsePayloadV2> {
    return this.repository.updateBaggingToReadyToCargo(payload);
  }
}
