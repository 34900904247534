import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import { PaginationV2 } from "@/domain/entities/Pagination";
import {
  HoldBalanceEntity,
  HoldBalanceHistoryDetail
} from "@/domain/entities/HoldBalanceHistory";

export class HoldBalanceHistoryMapper {
  public convertListDataFromApi(result: AxiosResponse<any>): ResponsePayloadV2 {
    const { data } = result;

    return new ResponsePayloadV2({
      success: data.success,
      message: data.message,
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.limit,
        totalData: data.meta.total_records
      }),
      data: new HoldBalanceEntity({
        totalHoldBalanceHistory: data.data.total_hold_balance_history,
        dataHoldBalanceHistory: data.data.data_hold_balance_history.length
          ? data.data.data_hold_balance_history.map(
              (item: any) =>
                new HoldBalanceHistoryDetail({
                  hbhActorId: item.hbh_actor_id,
                  hbhActorType: item.hbh_actor_type,
                  hbhPartnerId: item.hbh_partner_id,
                  hbhSttId: item.hbh_stt_id,
                  hbhSttNo: item.hbh_stt_no,
                  hbhBookingAt: item.hbh_booking_at,
                  hbhBookingShipmentFee: item.hbh_booking_shipment_fee,
                  hbhBookingReturnFee: item.hbh_booking_return_fee,
                  hbhBookingCodFee: item.hbh_booking_cod_fee,
                  hbhTotalFee: item.hbh_total_fee,
                  hbhSenderName: item.hbh_sender_name,
                  hbhSenderPhone: item.hbh_sender_phone,
                  hbhStatus: item.hbh_status
                })
            )
          : []
      })
    });
  }
}
