/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionDocInter = {
    document_international_enable: new Rox.Flag(false)
}

export function initFlagsPermissionDocInter() {
  // register flags with namespaces for better grouping
  Rox.register("featureDocInter", FlagsPermissionDocInter);
}