import { injectable } from "tsyringe";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { NotificationsAll } from "@/domain/entities/BalanceMinusPenalty";
import { BalanceMinusPenaltyApiRepository } from "@/app/infrastructures/repositories/api/BalanceMinusPenaltyApiRepository";
import { BalanceMinusPenaltyRequestInterface } from "@/data/payload/contracts/BalanceMinusPenaltyRequest";

@injectable()
export class BalanceMinusPenaltyPresenter {
  private repository: BalanceMinusPenaltyApiRepository;

  constructor(repository: BalanceMinusPenaltyApiRepository) {
    this.repository = repository;
  }

  public getList(params: ApiRequestList): Promise<ResponsePayloadV2> {
    return this.repository.getList(params);
  }

  public getAllNotifications(): Promise<NotificationsAll[]> {
    return this.repository.getAllNotifications();
  }

  public getDetail(id: number): Promise<ResponsePayloadV2> {
    return this.repository.getDetail(id);
  }

  public edit(
    payload: BalanceMinusPenaltyRequestInterface
  ): Promise<ResponsePayloadV2> {
    return this.repository.edit(payload);
  }

  public getBalancePenaltyNotification(): Promise<ResponsePayloadV2> {
    return this.repository.getBalancePenaltyNotification();
  }
}
