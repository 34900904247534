/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsUtilsCargo = {
    new_label_v2: new Rox.Flag(false)
};

export function initFlagsUtilsCargo() {
  // register flags with namespaces for better grouping
  Rox.register("utilsCargo", FlagsUtilsCargo);
}
