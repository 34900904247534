import { injectable } from "tsyringe";
import { NGenApiRepository } from "@/app/infrastructures/repositories/api/NGenApiRepository";
import { AwbDetailData, FlightSchedule, TrackingBookingRequestIdData } from "@/domain/entities/NGen";
import {
  GetScheduleListRequestInterface,
  PrintAWBRequestInterface,
  TrackingByBookingRequestIdInterface,
  TrackingRequestInterface
} from "@/data/payload/contracts/NGenRequest";

@injectable()
export class NGenPresenter {
  private repository: NGenApiRepository;

  constructor(repository: NGenApiRepository) {
    this.repository = repository;
  }

  public getFlightSchedule(
    payload: GetScheduleListRequestInterface
  ): Promise<FlightSchedule> {
    return this.repository.getFlightSchedule(payload);
  }

  getTrackingCargoHistoryNgen(
    payload: TrackingRequestInterface
  ): Promise<AwbDetailData> {
    return this.repository.getTrackingCargoHistoryNgen(payload);
  }

  getTrackingCargoByBookingRequestIdNgen(
    payload: TrackingByBookingRequestIdInterface
  ): Promise<TrackingBookingRequestIdData[]> {
    return this.repository.getTrackingByBookingRequestIdNgen(payload);
  }

  printAWBLionCargo(payload: PrintAWBRequestInterface): Promise<string> {
    return this.repository.printAWBLionCargo(payload);
  }
}
