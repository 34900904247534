import { DTPOL_CONFIG } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

const DeliveryTieringPolicyEdit = () =>
  import(
    /* webpackChunkName: "CommissionConfig" */ "@/app/ui/views/commission-configuration/delivery-tiering-policy/component/edit.vue"
  );
const DeliveryTieringPolicyDetail = () =>
  import(
    /* webpackChunkName: "CommissionConfig" */ "@/app/ui/views/commission-configuration/delivery-tiering-policy/detail/index.vue"
  );

export default [
  {
    path: "/tarif/commission-configuration/delivery-tiering-policy",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Konfigurasi Komisi",
      isFreeze: true,
      permission: DTPOL_CONFIG.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "delivery-tiering-policy-detail",
        component: DeliveryTieringPolicyDetail,
        meta: {
          header: true,
          title: "Delivery Tiering Policy | Detail",
          before: "/tarif/commission-configuration/delivery-tiering-policy"
        }
      }
    ]
  },
  {
    path: "/tarif/commission-configuration/delivery-tiering-policy",
    component: Layout,
    name: "delivery-tiering-policy-edit",
    meta: {
      title: "Delivery Tiering Policy | Edit",
      hidden: true,
      parent: "Konfigurasi Komisi",
      permission: DTPOL_CONFIG.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "delivery-tiering-policy-edit",
        component: DeliveryTieringPolicyEdit,
        meta: {
          header: true,
          title: "Delivery Tiering Policy | Edit",
          name: "Ubah",
          before: "/tarif/commission-configuration/delivery-tiering-policy/:id"
        }
      }
    ]
  }
];
