import { PAYMENT_METHOD } from "@/app/ui/components/dialog-payment/payment-props";
import QRCode from "qrcode";

export class CheckInquiry {
  transactionStatus = "";
  TransactionReason = "";
  constructor(fields?: Partial<CheckInquiry>) {
    Object.assign(this, fields);
  }
}

export class PaymentResponses {
  qrImage = "";
  qrString = "";
  transactionId = "";
  amount = 0;
  amountAfterDiscount = 0;
  isPromo = false;
  transactionGenesisId = "";
  expTime = 300;
  constructor(fields?: Partial<PaymentResponses>) {
    Object.assign(this, fields);
    if (!fields?.qrImage && fields?.qrString) {
      QRCode.toDataURL(fields.qrString, (_, url) => {
        if (url) {
          this.qrImage = url.replace("data:image/png;base64,", "");
        }
      });
    }
  }
}

export class PaymentMethod {
  name = "";
  maxAmount = 5000000;
  status = "active";
  logo = "";
  color = "";
  title = "";
  method = PAYMENT_METHOD.QR_INDONESIAN_STANDARD;
  expTime = 300;

  constructor(fields?: Partial<PaymentMethod>) {
    Object.assign(this, fields);
  }
}
