import { ClientManagementApiRepository } from "@/app/infrastructures/repositories/api/ClientManagementApiRepository";
import {
  ClientManagementEntities,
  DetailClientManagement
} from "@/domain/entities/ClientManagement";
import { injectable } from "tsyringe";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  EditClientManagementRequestInterface,
  RenewRatesRequestInterface,
  ApproveRenewRatesInterface,
  RejectRenewRatesInterface,
  UpdateCurrentRatesRequestInterface
} from "@/data/payload/contracts/ClientManagementRequest";
import { AddRenewRatesClientManagementApiRequestV2, EditRenewRatesClientManagementApiRequestV2 } from "@/data/payload/api/ClientManagementApiRequest";

@injectable()
export class ClientManagementPresenter {
  private repository: ClientManagementApiRepository;

  constructor(repository: ClientManagementApiRepository) {
    this.repository = repository;
  }

  public getListClientManagement(
    page: number,
    limit: number,
    search: string,
    searchCode: string,
    statusRates: string,
    statusRenewal: string,
    type: string,
    isTotalData: boolean
  ): Promise<ClientManagementEntities> {
    return this.repository.getListClientManagement(
      page,
      limit,
      search,
      searchCode,
      statusRates,
      statusRenewal,
      type,
      isTotalData
    );
  }

  public getDetailClientManagement(
    id: number
  ): Promise<DetailClientManagement> {
    return this.repository.getDetailClientManagement(id);
  }

  public editCurrentRatePeriode(
    payload: EditClientManagementRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editCurrentRatePeriode(payload);
  }

  public addRenewRates(
    payload: RenewRatesRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addRenewRates(payload);
  }

  public addRenewRatesV2(
    payload: AddRenewRatesClientManagementApiRequestV2
  ): Promise<ResponsePayload> {
    return this.repository.addRenewRatesV2(payload);
  }

  public updateCurrentRates(
    payload: UpdateCurrentRatesRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.updateCurrentRates(payload);
  }

  public editRenewRate(
    payload: RenewRatesRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editRenewRatePeriode(payload);
  }

  public approveRenewRates(
    payload: ApproveRenewRatesInterface
  ): Promise<ResponsePayload> {
    return this.repository.approveRenewRates(payload);
  }

  public rejectRenewRates(
    payload: RejectRenewRatesInterface
  ): Promise<ResponsePayload> {
    return this.repository.rejectRenewRates(payload);
  }

  public fetchClientManagementApi(
    params: any
  ): Promise<ClientManagementEntities> {
    return this.repository.fetchClientManagementApi(params);
  }

  public freezeUnfreezeClient(
    payload: EditClientManagementRequestInterface,
    type: string
  ): Promise<ResponsePayload> {
    return this.repository.freezeUnfreezeClient(payload, type);
  }

  public getDetailClientManagementV2(
    id: number
  ): Promise<DetailClientManagement> {
    return this.repository.getDetailClientManagementV2(id);
  }

  public editRenewRateV2(
    payload: EditRenewRatesClientManagementApiRequestV2
  ): Promise<ResponsePayload> {
    return this.repository.editRenewRateV2(payload);
  }
}
