import dateToTime from "@/app/infrastructures/misc/common-library/DateToTime";

const dateToDateString = (
  date: any,
  shortMonth = false,
  time = false,
  locale = "id"
) => {
  const format = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09"];
  const month = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember"
  ];
  const monthEnglish = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const shortMonthData = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agu",
    "Sep",
    "Okt",
    "Nov",
    "Des"
  ];
  const shortMonthDataEnglish = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  date = new Date(date);
  const shortMonthName =
    locale === "id"
      ? shortMonthData[date.getMonth()]
      : shortMonthDataEnglish[date.getMonth()];
  const fullMonthName =
    locale === "id" ? month[date.getMonth()] : monthEnglish[date.getMonth()];

  const dateFormat =
    date.getDate().toString().length === 1
      ? format[date.getDate()]
      : date.getDate();
  const monthNameFormat = shortMonth ? shortMonthName : fullMonthName;

  const dateToTimeFormat = `, ${dateToTime(date)}`;
  const timeFormat = time ? dateToTimeFormat : "";

  return date
    ? `${dateFormat} ${monthNameFormat} ${date.getFullYear()}${timeFormat}`
    : "";
};
export default dateToDateString;
