import ChangeCamelToSnakeCase from "@/app/infrastructures/misc/common-library/ChangeCamelToSnakeCase";

interface MyObject {
  [key: string]: any;
}
const ConvertObjectCamelToSnakeCase = (obj: any): any => {
  const newObj: MyObject = {};
  for (const [key, value] of Object.entries(obj)) {
    const isFile = value instanceof (File || Blob);
    const isObject = typeof value === "object" && value;

    if (Array.isArray(value)) {
      newObj[ChangeCamelToSnakeCase(key)] = value.map(v =>
        typeof v === "object" ? ConvertObjectCamelToSnakeCase(v) : v
      );
    } else if (isObject && !isFile) {
      newObj[ChangeCamelToSnakeCase(key)] = ConvertObjectCamelToSnakeCase(
        value
      );
    } else {
      newObj[ChangeCamelToSnakeCase(key)] = value;
    }
  }
  return newObj;
};

export default ConvertObjectCamelToSnakeCase;
