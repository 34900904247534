import { injectable } from "tsyringe";
import {
  AccountEntities,
  DashboardDeliveryPos,
  ValidationAccountData
} from "@/domain/entities/Account";
import { AccountApiRepository } from "@/app/infrastructures/repositories/api/AccountApiRepository";
import { AccountRequestInterface } from "@/data/payload/contracts/AccountRequest";
import {
  DashboardDeliveryRequest,
  PairTokenRequest,
  PostCsAccountApiRequest,
  UnpairTokenRequest
} from "@/data/payload/api/AccountRequest";
import {
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";

@injectable()
export class AccountPresenter {
  private repository: AccountApiRepository;

  constructor(repository: AccountApiRepository) {
    this.repository = repository;
  }

  public getProfile(
    isLocation?: boolean,
    cache?: boolean
  ): Promise<AccountEntities> {
    return this.repository.getProfile(isLocation, cache);
  }

  public getDashboardDelivery(
    params: DashboardDeliveryRequest
  ): Promise<DashboardDeliveryPos> {
    return this.repository.getDashboardDelivery(params);
  }

  public editEmailAccount(payload: AccountRequestInterface) {
    return this.repository.editEmailAccount(payload);
  }

  public getValidationAccount(): Promise<ValidationAccountData> {
    return this.repository.getValidationAccount();
  }
  public pairToken(payload: PairTokenRequest): Promise<ResponsePayload> {
    return this.repository.pairToken(payload);
  }

  public unpairToken(payload: UnpairTokenRequest): Promise<ResponsePayload> {
    return this.repository.unpairToken(payload);
  }

  public getAccountStatus(accountId: number): Promise<ResponsePayloadV2> {
    return this.repository.getAccountStatus(accountId);
  }

  public postCsAccountLog(
    payload: PostCsAccountApiRequest
  ): Promise<ResponsePayloadV2> {
    return this.repository.postCsAccountLog(payload);
  }
}
