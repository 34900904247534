import { POS_BRANCH } from "@/app/infrastructures/misc/RolePermission";
const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const PosBranch = () =>
  import(
    /* webpackChunkName: "PartnerManagement" */ "@/app/ui/views/sales/partner-management/pos/index.vue"
  );
const DetailPartner = () =>
  import(
    /* webpackChunkName: "PartnerManagement" */ "@/app/ui/views/sales/partner-management/detail/index.vue"
  );

export default [
  {
    path: "/pos-cabang",
    component: Layout,
    redirect: "/pos-cabang",
    name: "pos-branch",
    meta: {
      icon: "pos-branch",
      children: false,
      title: "Daftar POS Cabang & Di Pickup",
      name: "Daftar POS Cabang & Di Pickup",
      permission: POS_BRANCH.ENABLE
    },
    children: [
      {
        path: "/pos-cabang",
        name: "pos-branch",
        component: PosBranch,
        meta: {
          header: true,
          permission: POS_BRANCH.LIST
        }
      }
    ]
  },
  {
    path: "/pos-cabang",
    component: Layout,
    name: "detail-pos-branch",
    meta: {
      title: "Daftar POS Cabang & Di Pickup | Detail",
      hidden: true,
      parent: "Daftar POS Cabang & Di Pickup",
      isFreeze: true,
      permission: POS_BRANCH.DETAIL
    },
    children: [
      {
        path: "detail/:id",
        name: "detail-pos-branch",
        component: DetailPartner,
        meta: {
          header: true,
          title: "Detail POS Cabang",
          name: "Detail POS Cabang",
          before: "/pos-cabang"
        }
      }
    ]
  }
];
