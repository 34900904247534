/* eslint-disable @typescript-eslint/camelcase */
import {
  CashbackConfigDetailData,
  CashbackConfigEntities,
  CashbackConfigListData,
} from "@/domain/entities/CashbackConfiguration";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload, ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class CashbackConfigurationMapper {
  public convertListDataFromApi(result: AxiosResponse): CashbackConfigEntities {
    const { data } = result;
    const cashbackConfigListData: CashbackConfigListData[] = [];
    if (data.data.length > 0) {
      data.data.forEach((item: any) => {
        return cashbackConfigListData.push(
          new CashbackConfigListData({
            ccbId: item.ccb_id,
            ccbAccountType: item.ccb_account_type,
            ccbStartDate: item.ccb_start_date,
            ccbEndDate: item.ccb_end_date,
            ccbStatus: item.ccb_status,
            ccbCreatedAt: item.ccb_created_at,
            ccbCreatedBy: item.ccb_created_by,
            ccbUpdatedAt: item.ccb_updated_at,
            ccbUpdatedBy: item.ccb_updated_by,
          })
        );
      });
    }

    const pagination = new Pagination(
      data.meta.page,
      data.meta.limit,
      data.meta.total_records
    );

    return new CashbackConfigEntities(pagination, cashbackConfigListData);
  }

  public convertSubmitConfigFromApi(result: AxiosResponse): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
    });
  }
  public convertDetailDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data },
    } = result;
    return new ResponsePayloadV2({
      success: data.success,
      message: data.message,
      data: new CashbackConfigDetailData({
        ccbId: data.ccb_id,
        ccbAccountType: data.ccb_account_type,
        ccbType: data.ccb_type,
        ccbStartDate: data.ccb_start_date,
        ccbEndDate: data.ccb_end_date,
        ccbStatus: data.ccb_status,
        ccbCreatedAt: data.ccb_created_at,
        ccbCreatedBy: data.ccb_created_by,
        ccbUpdatedAt: data.ccb_updated_at,
        ccbUpdatedBy: data.ccb_updated_by,
        ccbIsAllPos: data.ccb_is_all_pos,
        ccbPartners: data.ccb_partners,
        ccbTiers: data.ccb_tiers.map((item: any) => {
          return {
            no: item.no,
            min_balance: item.min_balance,
            max_balance: item.max_balance,
            percentage: item.percentage.toString().replace(".", ",")
          }
        })
      }),
    });
  }
}
