import { ManualSTTDataMapper } from "@/data/persistences/mappers/ManualSTTMapper";
import { ManualSTTRepositoryInterface } from "@/data/persistences/repositories/contracts/ManualSTTRepositoryInterface";
import { ManualSTTEntities } from "@/domain/entities/ManualSTT";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { AddGenerateManualSTTRequestInterface } from "@/data/payload/contracts/ManualSTTRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  AddGenerateManualSTTApiRequest,
  RequestListManualStt
} from "@/data/payload/api/ManualSTTApiRequest";

export class ManualSTTApiRepository implements ManualSTTRepositoryInterface {
  private service: ApiService;
  private mapper: ManualSTTDataMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: ManualSTTDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getManualSTTList(
    params: RequestListManualStt
  ): Promise<ManualSTTEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListManualSTT(params),
      {}
    );
    return this.mapper.convertManualSTTDataFromApi(resp);
  }

  public async addGenerateManualSTT(
    payload: AddGenerateManualSTTRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.addGenerateManualSTT(),
      undefined,
      payload as AddGenerateManualSTTApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }
}
