import { SHIPMENT_STT_BOOKING } from "@/app/infrastructures/misc/RolePermission";
import { FlagsPermissionBooking } from "@/feature-flags/flag-booking";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const CreateBooking = () =>
  import(
    /* webpackChunkName: "STTBooking" */ "@/app/ui/views/shipment/booking/config-create-booking/index.vue"
  );
const CreateBookingShipment = () =>
  import(
    /* webpackChunkName: "STTBooking" */ "@/app/ui/views/shipment/booking/config-create-booking-shipment/index.vue"
  );
const DetailBooking = () =>
  import(
    /* webpackChunkName: "STTBooking" */ "@/app/ui/views/shipment/booking/detail-booking/detail.vue"
  );
const EditBooking = () =>
  import(
    /* webpackChunkName: "STTBooking" */ "@/app/ui/views/shipment/booking/config-edit-booking/index.vue"
  );
const InstantBookingShipmentV2 = () =>
  import(
    /* webpackChunkName: "InstantBooking" */ "@/app/ui/views/shipment/booking/instant-booking-v2/index.vue"
  );

export default [
  {
    path: "/shipment/booking",
    component: Layout,
    name: "shipment-booking-detail",
    meta: {
      title: "Shipment Booking | Detail",
      hidden: true,
      parent: "Booking",
      permission: FlagsPermissionBooking.permission_booking_view_detail
    },
    children: [
      {
        path: ":id",
        name: "detail-booking",
        component: DetailBooking,
        meta: {
          header: true,
          isAuth: true
        }
      }
    ]
  },
  {
    path: "/shipment/booking",
    component: Layout,
    name: "create-booking-stt",
    meta: {
      icon: "create-booking",
      hidden: true,
      parent: "Booking",
      permission: FlagsPermissionBooking.permission_booking_create_manual
    },
    children: [
      {
        path: "create",
        name: "create-booking-stt",
        component: CreateBooking,
        meta: {
          header: true,
          title: "Shipment Booking | Create ",
          name: "Buat Booking Baru",
          before: "/shipment/booking",
          formType: "manual",
          validateAccount: true
        }
      }
    ]
  },
  {
    path: "/shipment/booking",
    component: Layout,
    name: "create-booking-stt-client",
    meta: {
      icon: "create-booking",
      hidden: true,
      parent: "Booking",
      permission: FlagsPermissionBooking.permission_booking_create_for_corporate
    },
    children: [
      {
        path: "create-client",
        name: "create-booking-stt-client",
        component: CreateBooking,
        meta: {
          header: true,
          title: "Shipment Booking | Create ",
          name: "Buat Booking Baru",
          before: "/shipment/booking",
          formType: "client",
          validateAccount: true
        }
      }
    ]
  },
  {
    path: "/shipment/booking",
    component: Layout,
    name: "create-booking-stt-by-shipment-id",
    meta: {
      icon: "create-booking",
      hidden: true,
      parent: "Booking",
      permission: FlagsPermissionBooking.permission_booking_create_shipment_id
    },
    children: [
      {
        path: "create/by-shipment-id",
        name: "create-booking-stt-by-shipment-id",
        component: CreateBookingShipment,
        meta: {
          header: true,
          title: "Shipment Booking | Apps Booking",
          name: "Booking aplikasi & marketplace",
          before: "/shipment/booking",
          validateAccount: true
        }
      }
    ]
  },
  {
    path: "/shipment/booking",
    component: Layout,
    name: "create-instant-booking-shipment",
    meta: {
      icon: "create-booking",
      hidden: true,
      parent: "Booking",
      permission: FlagsPermissionBooking.permission_instant_booking_create
    },
    children: [
      {
        path: "create/instant-booking-shipment",
        name: "create-instant-booking-shipment",
        component: InstantBookingShipmentV2,
        meta: {
          header: true,
          title: "Shipment Booking | Instant Shipment Booking",
          name: "Booking Instant Shipment",
          before: "/shipment/booking",
          validateAccount: true
        }
      }
    ]
  },
  {
    path: "/shipment/booking",
    component: Layout,
    name: "shipment-booking-edit",
    meta: {
      title: "Shipment Booking | Edit",
      hidden: true,
      parent: "Booking",
      permission: FlagsPermissionBooking.permission_booking_edit
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-booking",
        component: EditBooking,
        meta: {
          header: true,
          title: "Booking | Edit",
          name: "Ubah Booking",
          before: "/shipment/booking/:id",
          isAuth: true
        }
      }
    ]
  }
];
