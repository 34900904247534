import {
  CancelPickupApiRequest,
  CreatePickupSuccessResponse,
  ListPickupDetail,
} from "@/domain/entities/ListPickupDetail";
import { ListPickupEndpoints } from "../../endpoints/hydra/ListPickupEndpoints";
import { ListPickupRepositoryInterface } from "@/data/persistences/repositories/contracts/ListPickupRepositoryInterface";
import {
  ListPickupList,
  RequestListPickupList,
} from "@/domain/entities/ListPickup";
import { ListPickupMapper } from "@/data/persistences/mappers/ListPickupMapper";
import ApiService from "../../services/ApiService";
import { PickupFormApiRequest } from "@/data/payload/api/PickupApiRequest";

export class ListPickupApiRepository implements ListPickupRepositoryInterface {
  private readonly service: ApiService;
  private readonly mapper: ListPickupMapper;
  private readonly endpoints: ListPickupEndpoints;

  constructor(
    service: ApiService,
    mapper: ListPickupMapper,
    endpoints: ListPickupEndpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async getListPickupList(
    params: RequestListPickupList
  ): Promise<ListPickupList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListPickupList(params)
    );
    return this.mapper.convertListDataFromApi(resp);
  }

  public async getListPickupDetail(id: string): Promise<ListPickupDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListPickupDetail(id),
      {}
    );
    return this.mapper.convertDetailDataFromApi(resp);
  }

  async cancelPickup(payload: CancelPickupApiRequest): Promise<boolean> {
    await this.service.invoke(
      "POST",
      this.endpoints.cancelPickup(),
      undefined,
      payload
    );

    return true;
  }

  async createPickup(
    payload: PickupFormApiRequest
  ): Promise<CreatePickupSuccessResponse> {
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.createPickup(),
      undefined,
      payload
    );

    return this.mapper.convertResponseSuccessCreatePickupFromApi(resp);
  }
}
