import { ShipmentIdList } from "@/domain/entities/Booking";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiServiceMiddleware";
import { MiddlewareRepositoryInterface } from "@/data/persistences/repositories/contracts/MiddlewareRepositoryInterface";
import { MiddlewareMapper } from "@/data/persistences/mappers/MiddlewareMapper";
import { MiddlewareList, MiddlewareDetail } from "@/domain/entities/Middleware";
import { RequestMiddlewareList, PayloadFormMiddleware } from "@/data/payload/api/MiddlewareApiRequest";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { AuthEntities } from "@/domain/entities/Auth";
import MainApiService from "@/app/infrastructures/services/ApiService";
export class MiddlewareApiRepository implements MiddlewareRepositoryInterface {
  private service: ApiService;
  public mainService: MainApiService;
  private mapper: MiddlewareMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mainService: MainApiService,
    mapper: MiddlewareMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mainService = mainService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async getShipmentDetailMiddleWare(
    ticketCode: string
  ): Promise<ShipmentIdList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getShipmentDetailMiddleWare(ticketCode),
      {}
    );
    return this.mapper.convertShipmentDetailFromMiddleware(resp);
  }

  async getListMiddleware(params: {
    type: string,
    queries: RequestMiddlewareList
  }): Promise<MiddlewareList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListMiddleware(params.type, params.queries),
      {}
    );
    return this.mapper.convertListFromMiddleware(params.type, resp);
  }

  public async formMiddleware(params: {
    method: any,
    type: string,
    payload: PayloadFormMiddleware
  }): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      params.method,
      this.endpoints.formMiddleware(params.type, params.payload.payload.id),
      {},
      params.payload
    );
    return this.mapper.convertCreateMiddlewareFromApi(resp);
  }

  public async getDetailMiddleware(params: {
    type: string,
    id: number
  }): Promise<MiddlewareDetail> {
    const res = await this.service.invoke(
      "get",
      this.endpoints.getDetailMiddleware(params),
      {}
    );
    return this.mapper.convertDetailMiddlewareFromApi(res);
  }

  public async getTokenMiddleware(): Promise<AuthEntities> {
    const resp = await this.mainService.invoke(
      "get",
      this.endpoints.getTokenMiddleware(),
      {},
    );
    return this.mapper.convertGetTokenMiddlewareFromApi(resp);
  }
}
