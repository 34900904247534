import { ManualDeductAddSaldoRepository } from "@/app/infrastructures/repositories/api/ManualDeductAddSaldoRepositoryInterface";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  DetailManualDeductAddSaldoData,
  ManualDeductAddSaldoEntities
} from "@/domain/entities/ManualDeductAddSaldo";
import { injectable } from "tsyringe";
import { CreateManualDeductAddSaldoRequestInterface } from "@/data/payload/contracts/ManualDeductAddSaldoRequest";

@injectable()
export class ManualDeductAddSaldoPresenter {
  private repository: ManualDeductAddSaldoRepository;

  constructor(repo: ManualDeductAddSaldoRepository) {
    this.repository = repo;
  }

  public async getDetailManualDeductAddSaldo(
    id: number
  ): Promise<DetailManualDeductAddSaldoData> {
    return await this.repository.getDetailManualDeductAddSaldo(id);
  }

  public createDeductSaldo(
    payload: CreateManualDeductAddSaldoRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.createDeductSaldo(payload);
  }

  public getManualDeductAddSaldoList(
    page: number,
    limit: number,
    search: string,
    actorType: string,
    actorId: number,
    startDate: string,
    endDate: string,
    type: string,
    createdBy: string
  ): Promise<ManualDeductAddSaldoEntities> {
    return this.repository.getManualDeductAddSaldoList(
      page,
      limit,
      search,
      actorType,
      actorId,
      startDate,
      endDate,
      type,
      createdBy
    );
  }

  public async getDeductReports(
    adjustmentType: string,
    actorType: string,
    actorId: number,
    startDate: string,
    endDate: string,
    createdBy: number
  ): Promise<DetailManualDeductAddSaldoData> {
    return await this.repository.getDeductReports(
      adjustmentType,
      actorType,
      actorId,
      startDate,
      endDate,
      createdBy
    );
  }
}
