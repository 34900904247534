import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Utils";
import {
  CityData,
  CityEntities,
  CountryEntities,
  CountryData,
  DistrictEntities,
  DistrictData,
  UrsaCodeData,
  DistrictClient,
  RegionEntities,
  RegionData,
  RegionCity,
  ZipCodeEntities,
  ZipCodeData
} from "@/domain/entities/Location";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class LocationDataMapper {
  public convertCityDataFromApi(result: AxiosResponse<any>): CityEntities {
    const { data } = result;

    const cityListData: CityData[] = [] || null;
    if (!data.data.length) {
      [];
    } else {
      data.data.forEach((item: any) => {
        cityListData.push(
          new CityData({
            id: item.id,
            name: item.name,
            isActive: item.is_active,
            code: item.code,
            timezone: item.timezone,
            freeTradeZone: item.free_trade_zone,
            countryId: item.country_id,
            originTransit: item.origin_transit_name,
            destinationTransit: item.destination_transit_name,
            createdBy: item.created_by,
            updatedBy: item.updated_by,
            createdAt: item.created_at,
            updateAt: item.updated_at,
            originCodeTransit: item.origin_transit,
            destinationCodeTransit: item.destination_transit
          })
        );
      });
    }

    const pagination = new Pagination(data.meta.page, data.meta.limit);

    return new CityEntities(pagination, cityListData);
  }

  public convertDetailCityDataFromApi(result: AxiosResponse<any>): CityData {
    const {
      data: { data }
    } = result;

    return new CityData({
      id: data.id,
      name: data.name,
      isActive: data.is_active,
      code: data.code,
      timezone: data.timezone,
      freeTradeZone: data.free_trade_zone,
      countryId: data.country_id,
      originTransit: data.origin_transit_name,
      destinationTransit: data.destination_transit_name,
      createdBy: data.created_by,
      updatedBy: data.updated_by,
      createdAt: data.created_at,
      updateAt: data.updated_at,
      originCodeTransit: data.origin_transit,
      destinationCodeTransit: data.destination_transit,
      minimalSla: data.minimal_sla || "-",
      maximalSla: data.maximal_sla || "-",
      sundayDelivery: data.is_sunday_delivery
    });
  }

  public convertCountryDataFromApi(
    result: AxiosResponse<any>
  ): CountryEntities {
    const { data } = result;

    const countryListData: CountryData[] = [] || null;
    if (!data.data.length) {
      [];
    } else {
      data.data.forEach((item: any) => {
        countryListData.push(
          new CountryData(
            item.id,
            item.code,
            item.name,
            item.currency,
            item.status,
            item.created_at,
            item.created_by,
            item.updated_at,
            item.updated_by
          )
        );
      });
    }

    const pagination = new Pagination(data.meta.page, data.meta.limit);

    return new CountryEntities(pagination, countryListData);
  }

  public convertDetailCountryDataFromApi(
    result: AxiosResponse<any>
  ): CountryData {
    const { data } = result;

    return new CountryData(
      data.data.country_id,
      data.data.country_code,
      data.data.country_name,
      data.data.currency,
      data.data.status_rate,
      data.data.created_by,
      data.data.updated_by,
      data.data.created_at,
      data.data.updated_at
    );
  }

  public convertDistrictDataFromApi(
    result: AxiosResponse<any>
  ): DistrictEntities {
    const { data } = result;

    const districtListData: DistrictData[] = [] || null;
    if (!data.data.length) {
      [];
    } else {
      data.data.forEach((item: any) => {
        districtListData.push(
          new DistrictData({
            id: item.id,
            code: item.code,
            name: item.name,
            type: item.type,
            cityCode: item.city_code,
            cityId: item.city_id,
            cityName: item.city_name,
            ursaCode: item.ursa_code,
            originZipCode: item.origin_zip_code,
            destinationZipCode: item.destination_zip_code,
            destinationListZipCode: item.destination_list_zip_code,
            vendorCode: item.vendor_code,
            status: item.status,
            isCod: item.is_cod,
            isPtPos: item.is_ptpos,
            createdBy: item.created_by,
            updatedBy: item.updated_by,
            createdAt: item.created_at,
            updateAt: item.updated_at,
            isFtz: item.is_ftz,
            timezone: item.timezone
          })
        );
      });
    }

    const pagination = IS_SENDER_ACCOUNT
      ? new Pagination(1, 10)
      : new Pagination(data.meta.page, data.meta.limit);

    return new DistrictEntities({
      pagination: pagination,
      districtData: districtListData
    });
  }

  public convertDetailDistrictDataAccountFromApi(
    result: AxiosResponse<any>
  ): DistrictData {
    const {
      data: { data }
    } = result;

    return new DistrictData({
      id: data.id,
      code: data.code,
      name: data.name,
      type: data.type,
      cityCode: data.city_code,
      cityId: data.city_id,
      cityName: data.city_name,
      ursaCode: data.ursa_code || "-",
      originZipCode: data.origin_zip_code,
      destinationZipCode: data.destination_zip_code,
      vendorCode: data.vendor_code,
      status: data.status,
      isCod: data.is_cod,
      isPtPos: data.is_ptpos,
      createdBy: data.created_by,
      updatedBy: data.updated_by,
      createdAt: data.created_at,
      updateAt: data.update_at,
      isFtz: data.isFtz,
      slaOrigin: data.sla_origin || "-",
      slaDestination: data.sla_destination || "-",
      minSlaOrigin: data.min_sla_origin || "-",
      maxSlaOrigin: data.max_sla_origin || "-",
      minSlaDestination: data.min_sla_destination || "-",
      maxSlaDestination: data.max_sla_destination || "-",
      originListZipCode: data.origin_list_zip_code || [],
      countryCode: data.country_code,
      areaType: data.area_type || "-",
      countryId: data.country_id,
      countryName: data.country_name,
      destinationListZipCode: data.destination_list_zip_code || []
    });
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertRefreshESFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertUrsaCodeDataFromApi(result: AxiosResponse<any>): UrsaCodeData {
    const { data } = result;
    return new UrsaCodeData(data.code, data.message);
  }

  public convertDistrictClientFromApi(result: AxiosResponse<any>): DistrictClient {
    const { data } = result;
    return  new DistrictClient({
      code: data.data.code,
      id: data.data.id,
      isCod: data.data.is_cod,
      name: data.data.name,
      cityName: data.data.city.name,
      cityCode: data.data.city.code,
      status: data.data.status,
      type: data.data.type,
      ursaCode: data.data.ursa_code,
      vendorCode: data.data.vendor_code
    })
  }

  public convertDetailDistrictDataFromApi(
    result: AxiosResponse<any>
  ): DistrictData {
    const {
      data: { data }
    } = result;

    return new DistrictData({
      id: data.id,
      code: data.code,
      name: data.name,
      type: data.type,
      cityCode: data.city.code,
      cityId: data.city.id,
      cityName: data.city.name,
      ursaCode: data.ursa_code,
      originZipCode: data.origin_zip_code,
      destinationZipCode: data.destination_zip_code,
      vendorCode: data.vendor_code,
      status: data.status,
      isCod: data.is_cod,
      isPtPos: data.is_ptpos,
      createdBy: data.created_by,
      updatedBy: data.updated_by,
      createdAt: data.created_at,
      updateAt: data.update_at,
      isFtz: data.isFtz,
      slaOrigin: data.sla_origin || "-",
      slaDestination: data.sla_destination || "-",
      minSlaOrigin: data.min_sla_origin || "-",
      maxSlaOrigin: data.max_sla_origin || "-",
      minSlaDestination: data.min_sla_destination || "-",
      maxSlaDestination: data.max_sla_destination || "-",
      originListZipCode: data.origin_list_zip_code || [],
      destinationListZipCode: data.destination_list_zip_code || [],
      areaType: data.area_type
    });
  }

  public convertListRegionFromApi(result: AxiosResponse<any>): RegionEntities {
    const { data } = result;
    return new RegionEntities({
      pagination: new Pagination(
        data.meta.page,
        data.meta.limit,
        data.meta.total_records
      ),
      regionData: data?.data?.length
        ? data.data.map(
            (item: any) =>
              new RegionData({
                regionId: item.region_id,
                regionCode: item.region_code,
                regionName: item.region_name,
                regionStatus: item.region_status,
                regionCreatedAt: item.region_created_at,
                regionUpdatedAt: item.region_updated_at,
                regionCities: item.region_city?.length
                  ? item.region_city
                      .filter((regionCity: any) =>
                        regionCity.region_city_status.match(/^active/gi)
                      )
                      .map((regionCity: any) => {
                        return new RegionCity({
                          regionCityId: regionCity.region_city_id,
                          regionCityRegionCode:
                            regionCity.region_city_region_code,
                          regionCityCityId: regionCity.region_city_city_id,
                          regionCityStatus: regionCity.region_city_status,
                          regionCityCreatedAt:
                            regionCity.region_city_created_at,
                          regionCityUpdatedAt:
                            regionCity.region_city_updated_at,
                          city: {
                            id: regionCity.city.id,
                            name: regionCity.city.name,
                            isActive: regionCity.city.is_active,
                            isSundayDelivery:
                              regionCity.city.is_sunday_delivery,
                            code: regionCity.city.code,
                            timezone: regionCity.city.timezone,
                            freeTradeZone: regionCity.city.free_trade_zone,
                            countryId: regionCity.city.country_id,
                            originTransit: regionCity.city.origin_transit,
                            destinationTransit:
                              regionCity.city.destination_transit,
                            districtDefault: regionCity.city.district_default,
                            createdBy: regionCity.city.created_by,
                            updatedBy: regionCity.city.updated_by,
                            createdAt: regionCity.city.created_at,
                            updatedAt: regionCity.city.updated_at,
                          },
                        });
                      })
                  : [],
              })
          )
        : [],
    });
  }

  public convertZipCodeDataFromApi(result: AxiosResponse<any>): ZipCodeEntities {
    const { data } = result;

    const zipCodeListData: ZipCodeData[] = [] || null;
    if (!data.data.length) {
      [];
    } else {
      data.data.forEach((item: any) => {
        zipCodeListData.push(
          new ZipCodeData({
            id: item.id,
            code: item.code,
            name: item.name,
            type: item.type,
            cityId: item.city_id,
            cityName: item.city_name,
            cityCode: item.city_code,
            timezone: item.timezone,
            ursaCode: item.ursa_code,
            originZipCode: item.origin_zip_code,
            destinationZipCode: item.destination_zip_code,
            vendorCode: item.vendor_code,
            status: item.status,
            isFtz: item.is_ftz,
            isCod: item.is_cod,
            isPtpos: item.is_ptpos,
            createdAt: item.created_at,
            updateAt: item.updated_at,
            originListZipCode: item.origin_list_zip_code,
            destinationListZipCode: item.destination_list_zip_code,
            areaType: item.area_type,
            searchZipCode: item.search_zip_code,
          })
        );
      });
    }

    const pagination = new Pagination(data.meta.page, data.meta.limit);

    return new ZipCodeEntities(pagination, zipCodeListData);
  }
}
