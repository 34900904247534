import {
  BaggingGroupingData,
  BaggingGroupingListEntities
} from "@/domain/entities/BaggingGrouping";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";

export class BaggingGroupingMapper {
  public convertBaggingGroupingListFromApi(
    res: AxiosResponse<any>
  ): BaggingGroupingListEntities {
    const { data } = res;
    return new BaggingGroupingListEntities({
      pagination: new Pagination(
        data.meta.page,
        data.meta.limit,
        data.meta.total_records
      ),
      data: data.data.length
        ? data.data.map(
            (item: any) =>
              new BaggingGroupingData({
                id: item.bgl_id,
                destinationId: item.bgl_destination_id,
                destinationName: item.bgl_destination_name,
                regionId: item.bgl_region_id,
                regionName: item.bgl_region_name,
                status: item.bgl_status === "active"
              })
          )
        : []
    });
  }
}
