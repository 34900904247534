import { INCOMING_OUTGOING } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const IncomingOutgoingList = () =>
  import("@/app/ui/views/incoming-outgoing/index.vue");

// Cargo
const CargoDetail = () => import("@/app/ui/views/cargo/component/detail.vue");
const CargoEdit = () => import("@/app/ui/views/cargo/component/edit.vue");

// Bagging
const DetailBagging = () =>
  import("@/app/ui/views/out-going-shipment/bagging/component/detail.vue");
const UpdateBagging = () =>
  import(
    "@/app/ui/views/out-going-shipment/bagging/component/update/index.vue"
  );

// STI-DEST
const DetailStiDest = () =>
  import("@/app/ui/views/incoming-process/sti-dest/component/detail.vue");

export default [
  {
    path: "/incoming-outgoing",
    component: Layout,
    redirect: "/incoming-outgoing",
    name: "incoming-outgoing",
    meta: {
      icon: "incoming-outgoing",
      children: false,
      title: "Incoming & Outgoing",
      name: "Incoming & Outgoing",
      permission: INCOMING_OUTGOING.ENABLE
    },
    children: [
      {
        path: "/incoming-outgoing",
        name: "incoming-outgoing",
        component: IncomingOutgoingList,
        meta: {
          header: true
        }
      }
    ]
  },
  // cargo
  {
    path: "/incoming-outgoing/cargo",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Incoming Outgoing - Kargo",
      "parent-path": "/incoming-outgoing",
      permission: INCOMING_OUTGOING.CARGO_DETAIL
    },
    children: [
      {
        path: ":id",
        name: "incoming-outgoing-detail-cargo",
        component: CargoDetail,
        meta: {
          header: true,
          title: "Incoming Outgoing - Kargo | Detail"
        }
      }
    ]
  },
  {
    path: "/incoming-outgoing/cargo",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Incoming Outgoing - Kargo",
      "parent-path": "/incoming-outgoing",
      permission: INCOMING_OUTGOING.CARGO_EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "incoming-outgoing-edit-cargo",
        component: CargoEdit,
        meta: {
          header: true,
          title: "Incoming Outgoing - Kargo | Edit ",
          name: "Edit Kargo"
        }
      }
    ]
  },
  // bagging
  {
    path: "/incoming-outgoing/bagging",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Incoming Outgoing - Bagging",
      "parent-path": "/incoming-outgoing",
      isFreeze: true,
      permission: INCOMING_OUTGOING.BAGGING_DETAIL
    },
    children: [
      {
        path: ":id",
        name: "incoming-outgoing-detail-bagging",
        component: DetailBagging,
        meta: {
          header: true,
          title: "Incoming Outgoing - Bagging | Detail Bagging",
          name: "Detail Bagging"
        }
      }
    ]
  },
  {
    path: "/incoming-outgoing/bagging",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Incoming Outgoing - Bagging",
      "parent-path": "/incoming-outgoing",
      permission: INCOMING_OUTGOING.BAGGING_EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "incoming-outgoing-update-bagging",
        component: UpdateBagging,
        meta: {
          header: true,
          name: "Ubah Bagging",
          title: "Incoming Outgoing - Bagging | Ubah Bagging",
          before: "/incoming-outgoing/bagging/:id"
        }
      }
    ]
  },
  // STI-DEST
  {
    path: "/incoming-outgoing/sti-dest",
    component: Layout,
    meta: {
      hidden: true,
      isFreeze: true,
      parent: "Incoming Outgoing - Station Transit In Destination",
      "parent-path": "/incoming-outgoing",
      permission: INCOMING_OUTGOING.STI_DEST_DETAIL
    },
    children: [
      {
        path: "detail/:id",
        name: "incoming-outgoing-detail-sti-dest",
        component: DetailStiDest,
        meta: {
          header: true,
          title: "Incoming Outgoing - STI-DEST | Detail STI-DEST",
          name: "Detail STI-DEST"
        }
      }
    ]
  }
];
