/* eslint-disable @typescript-eslint/camelcase */
import { RequestTopupFeeConfigurationList } from "@/domain/entities/TopupFeeConfiguration";
import store from "@/store";
import { container } from "tsyringe";
import {
    Action,
    Module,
    VuexModule,
    getModule
} from "vuex-module-decorators";
import { TopupFeeConfigurationPresenter } from "../presenters/TopupFeeConfigurationPresenter";
import { TopupFeeConfigurationComponent } from "@/app/infrastructures/dependencies/modules/TopupFeeConfigurationComponent";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";
import { LastBalancePresenter } from "@/app/ui/presenters/LastBalancePresenter";
import { TopupFeeConfigRequest } from "@/data/payload/api/TopupFeeConfigRequest";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
TopupFeeConfigurationComponent.init();

@Module({ namespaced: true, dynamic: true, store, name: "topupfee-configuration" })
class TopupFeeConfigurationStore extends VuexModule {
    @Action
    public getTopupFeeConfigurationList(params: RequestTopupFeeConfigurationList) {
        const presenter = container.resolve(TopupFeeConfigurationPresenter);
        return presenter.getTopupFeeConfigurationList(params);
    }

    @Action
    public getDetailTopupFeeConfiguration(id: any) {
        const presenter = container.resolve(TopupFeeConfigurationPresenter);
        return presenter.getDetailTopupFeeConfiguration(id);
    }

    @Action
    public getActiveTopupFeeConfig(id: any) {
        const presenter = container.resolve(TopupFeeConfigurationPresenter);
        return presenter.getActiveTopupFeeConfig(id).then((res: ResponsePayloadV2) => res)
            .catch((error: any) => new ResponsePayloadV2());
    }

    @Action
    public getListPartner(params: RequestListPartner) {
        const presenter = container.resolve(LastBalancePresenter)
        return presenter.getListPartner(params)
    }

    @Action
    public saveConfig(params: TopupFeeConfigRequest) {
        const presenter = container.resolve(TopupFeeConfigurationPresenter);
        return presenter.saveConfig(params);
    }

    @Action
    public updateConfig(params: {id: any, payload: TopupFeeConfigRequest}) {
        const presenter = container.resolve(TopupFeeConfigurationPresenter);
        return presenter.updateConfig(params.id, params.payload);
    }
}

export const TopupFeeConfigurationController = getModule(TopupFeeConfigurationStore);