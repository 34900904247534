import { PaginationV2 } from "@/domain/entities/Pagination";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { TopupFeeConfiguration, TopupFeeConfigurationList } from "@/domain/entities/TopupFeeConfiguration";
import { AxiosResponse } from "axios";

export class TopupFeeConfigurationMapper {
    public convertListDataFromApi(res: AxiosResponse<any>): TopupFeeConfigurationList {
        const { data } = res;
        return new TopupFeeConfigurationList({
            pagination: new PaginationV2(
                {
                    page: data.meta.page,
                    limit: data.meta.limit,
                    totalData: data.meta.total_records
                }
            ),
            data: data.data.length ? data.data.map((item: any) => new TopupFeeConfiguration(
                {
                    topupFeeId: item.ctuf_id,
                    topupFeeAccountType: item.ctuf_account_type,
                    topupFee: item.ctuf_top_up_fee,
                    topupFeeStatus: item.ctuf_status,
                    topupFeeUpdatedAt: item.ctuf_updated_at,
                    topupFeeUpdatedBy: item.ctuf_updated_by
                }
            )) : []
        })
    }

    public convertSubmitConfigFromApi(result: AxiosResponse): ResponsePayloadV2 {
        const { data } = result;
        return new ResponsePayloadV2({
            success: data.success,
            code: data.code,
            message: data.message
        });
    }

    public convertDetailDataFromApi(result: AxiosResponse<any>): ResponsePayloadV2 {
        const { data: { data } } = result;
        return new ResponsePayloadV2({
            success: data.success,
            message: data.message,
            data: new TopupFeeConfiguration(
                {
                    topupFeeId: data.ctuf_id,
                    topupFeeAccountType: data.ctuf_account_type,
                    topupFee: data.ctuf_top_up_fee,
                    topupFeeStatus: data.ctuf_status,
                    topupFeeIsAllPos: data.ctuf_is_all_pos,
                    topupFeePartners: data?.ctuf_partners || [],
                    topupFeeCreatedAt: data.ctuf_created_at,
                    topupFeeCreatedBy: data.ctuf_created_by,
                    topupFeeUpdatedAt: data.ctuf_updated_at,
                    topupFeeUpdatedBy: data.ctuf_updated_by,
                }
            )
        })
    }
}