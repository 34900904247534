import { ConfigPriceWoodpackingApiRepository } from "@/app/infrastructures/repositories/api/ConfigWoodpackingApiRepository";
import { injectable } from "tsyringe";
import { WoodpackingData } from "@/domain/entities/ConfigWoodpacking";
import { EditConfigWoodpackingApiRequest } from "@/data/payload/api/ConfigWoodpackingApiRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

@injectable()
export class ConfigPriceWoodpackingPresenter {
  private repository: ConfigPriceWoodpackingApiRepository;

  constructor(repository: ConfigPriceWoodpackingApiRepository) {
    this.repository = repository;
  }

  public getDetailConfigWoodpacking(id: number): Promise<WoodpackingData> {
    return this.repository.getDetail(id);
  }

  public editConfig(
    payload: EditConfigWoodpackingApiRequest
  ): Promise<ResponsePayload> {
    return this.repository.editConfig(payload);
  }
}
