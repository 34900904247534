import { injectable } from "tsyringe";
import { ConsolidatorApiRepository } from "@/app/infrastructures/repositories/api/ConsolidatorApiRepository";
import { ConsolidatorEntities } from "@/domain/entities/Consolidator";

@injectable()
export class ConsolidatorPresenter {
  private repository: ConsolidatorApiRepository;

  constructor(repo: ConsolidatorApiRepository) {
    this.repository = repo;
  }

  public async getConsolidatorList(
    page: number,
    limit: number,
    search: string,
    contractStatus: string,
    cityCode: string,
    status: string
  ): Promise<ConsolidatorEntities> {
    return await this.repository.getListConsolidator(
      page,
      limit,
      search,
      contractStatus,
      cityCode,
      status
    );
  }
}
