import {
  DetailBonusKomisi,
  CommissionTier
} from "@/domain/entities/ProgressiveCommissionDashboard";
import { AxiosResponse } from "axios";

export class ProgressiveCommissionDashboardMapper {
  public convertDetailBonusKomisiFromApi(
    result: AxiosResponse
  ): DetailBonusKomisi {
    const {
      data: { data }
    } = result;

    return new DetailBonusKomisi({
      pcrId: data.pcr_id,
      pcrConfigurableProgressiveCommissionId:
        data.pcr_configurable_progressive_commission_id,
      pcrConfigurableProgressiveCommissionName:
        data.pcr_configurable_progressive_commission_name,
      pcrPeriodStartDate: data.pcr_period_start_date,
      pcrPeriodEndDate: data.pcr_period_end_date,
      pcrPosId: data.pcr_pos_id,
      pcrPosCode: data.pcr_pos_code,
      pcrPosName: data.pcr_pos_name,
      pcrPos3lc: data.pcr_pos_3lc,
      pcrPosAddress: data.pcr_pos_address,
      pcrTotalRevenue: data.pcr_total_revenue,
      pcrTotalAmountCalculated: data.pcr_total_amount_calculated,
      pcrTotalStt: data.pcr_total_stt,
      pcrTotalCommissionAdditional: data.pcr_total_commission_additional,
      pcrCreatedAt: data.pcr_created_at,
      pcrUpdatedAt: data.pcr_updated_at,
      pcrCommissionAdditionalTier: data.pcr_commission_additional_tier.map(
        (item: any) => {
          return new CommissionTier({
            commissionTierConfigDetailId: item.commission_tier_config_detail_id,
            commissionTierId: item.commission_tier_id,
            commissionTierName: item.commission_tier_name,
            commissionTierLimit: item.commission_tier_limit,
            commissionTierLatestAmountCalculated:
              item.commission_tier_latest_amount_calculated,
            commissionTierAmountCalculated:
              item.commission_tier_amount_calculated,
            commissionTierAmount: item.commission_tier_amount
          });
        }
      )
    });
  }
}
