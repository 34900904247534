/* eslint-disable @typescript-eslint/camelcase */
import {
  UserEntities,
  UserData,
  UserDetailData,
  ReferenceEntities,
  RoleEntities,
  QuotaTicketAccount
} from "@/domain/entities/User";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";
import {
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";

export class UserDataMapper {
  public convertUserDataFromApi(result: AxiosResponse<any>): UserEntities {
    const { data } = result;

    const userListData: UserData[] = [] || null;
    if (data.data === []) {
      [];
    } else {
      data.data.forEach((item: any) => {
        userListData.push(
          new UserData({
            account_id: item.account_id,
            username: item.username,
            account_name: item.account_name,
            account_email: item.account_email,
            password: item.password,
            account_phone_number: item.account_phone_number,
            status: item.status,
            created_at: item.created_at,
            created_by: item.created_by,
            updated_at: item.updated_at,
            updated_by: item.updated_by,
            first_login: item.first_login,
            account_type: item.account_type,
            role_id: item.role_id,
            account_role: item.account_role.name,
            account_partner: item.account_partner?.partner,
            account_client: item.account_client?.client,
            account_vendor: item.account_vendor?.vendor,
            dexBucketTicketAccount: new QuotaTicketAccount({
              dbtaId: item.dex_bucket_ticket_account?.dbta_id,
              dbtaBucketTicket: String(
                item.dex_bucket_ticket_account?.dbta_bucket_ticket
              )
            })
          })
        );
      });
    }

    const pagination = new Pagination(
      data.meta.page,
      data.meta.limit,
      data.meta.total_records
    );

    return new UserEntities(pagination, userListData);
  }

  public convertDetailUserDataFromApi(
    result: AxiosResponse<any>
  ): UserDetailData {
    const { data } = result;

    return new UserDetailData(
      data.data.id,
      data.data.username,
      data.data.name,
      data.data.email,
      data.data.phone_number,
      data.data.status,
      data.data.created_at,
      data.data.created_by,
      data.data.updated_at,
      data.data.updated_by,
      data.data.account_type,
      data.data.account_hub_code,
      data.data.role_name,
      data.data?.partner,
      data.data?.client,
      data.data?.vendor
    );
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertReferenceDataFromApi(
    result: AxiosResponse<any>
  ): ReferenceEntities[] {
    const { data } = result;

    const referenceListData: ReferenceEntities[] = [] || null;
    if (data.data === []) {
      [];
    } else {
      data.data.forEach((item: any) => {
        referenceListData.push(
          new ReferenceEntities(
            item.id,
            item?.name,
            item?.company_name,
            item?.status
          )
        );
      });
    }
    return referenceListData;
  }

  public convertRoleDataFromApi(result: AxiosResponse<any>): RoleEntities[] {
    const { data } = result;

    const roleListData: RoleEntities[] = [] || null;
    if (data.data === []) {
      [];
    } else {
      data.data.forEach((item: any) => {
        roleListData.push(new RoleEntities(item.id, item.name));
      });
    }
    return roleListData;
  }

  public convertFormDataFromApi(result: AxiosResponse<any>): ResponsePayloadV2 {
    const {
      data: { data }
    } = result;

    return new ResponsePayloadV2({ data });
  }
}
