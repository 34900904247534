import {
  Actor,
  ClientData,
  ClientDetail,
  ClientEntities,
  ClientMappingList,
  ClientParentData,
  ClientPartnerData
} from "@/domain/entities/Client";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class ClientMapper {
  public convertClientFromApi(result: AxiosResponse<any>): ClientEntities {
    const { data } = result;
    return new ClientEntities(
      new Pagination(1, 10),
      data.data.length > 0
        ? data.data.map((item: any) => {
            return new ClientData(
              item.id,
              item.code,
              item.company_name,
              item.phone,
              item.address,
              item.balance,
              item.created_at,
              item.updated_at,
              item.status
            );
          })
        : []
    );
  }

  public convertClientPartnerFromApi(
    result: AxiosResponse<any>
  ): ClientPartnerData[] {
    const {
      data: { data }
    } = result;
    return data.map(
      (item: any) =>
        new ClientPartnerData(
          item.id,
          item.name,
          item.code,
          item.type,
          item.country?.code,
          item.country?.currency
        )
    );
  }

  public convertClientParentDataFromApi(
    result: AxiosResponse<any>
  ): ClientParentData[] {
    const {
      data: { data }
    } = result;
    return data.map(
      (item: any) =>
        new ClientParentData(
          item.client_parent_id,
          item.client_parent_name,
          item.client_parent_code
        )
    );
  }

  public convertClientParentDataFromApiHydra(
    result: AxiosResponse<any>
  ): ClientParentData[] {
    const {
      data: { data }
    } = result;
    return data.map(
      (item: any) =>
        new ClientParentData(item.client_parent_id, item.client_parent_name, "")
    );
  }

  public convertClientMappingFromApi(
    result: AxiosResponse<any>
  ): ClientMappingList[] {
    const {
      data: { data }
    } = result;
    return data.map(
      (item: any) =>
        new ClientMappingList({
          clientId: item.client_id,
          clientCode: item.client_code,
          clientName: item.client_name,
          clientStatus: item.client_status,
          clientIsDo: item.client_is_do,
          clientIsCod: item.client_is_cod,
          clientIsMixpack: item.client_is_mixpack,
          clientIsBanned: item.client_is_banned,
          clientPartner: item.client_partner,
          clientOriginCity: item.client_origin_city
        })
    );
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertActorsFromApi(result: AxiosResponse<any>): Actor[] {
    const { data } = result;
    return data.data.map(
      (item: any) => new Actor(item.created_id, item.created_name)
    );
  }

  public convertClientDetailFromApi(result: AxiosResponse): ClientDetail {
    const {
      data: { data }
    } = result;

    return new ClientDetail({
      clientAddress: data.client_address,
      clientCityCode: data.client_city_code,
      clientDistrictName: data.client_district_name,
      clientDistrictCode: data.client_district_code,
      clientId: data.client_id,
      clientName: data.client_name,
      clientParentId: data.client_parent_id,
      clientCityName: data.client_city_name,
      clientCode: data.client_code,
      clientPhone: data.client_phone,
      clientParentCode: data.client_parent_code
    });
  }
}
