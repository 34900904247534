/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionDashboard = {
  dashboard_enable: new Rox.Flag(false)
};

export function initFlagsPermissionDashboard() {
  // register flags with namespaces for better grouping
  Rox.register("featureDashboard", FlagsPermissionDashboard);
}
