export class AdjustmentPenaltyData {
  configurablePriceId: number;
  configurablePriceName: string;
  configurablePriceType: string;
  configurablePriceDescription: string;
  configurablePriceStatus: string;
  configurablePriceCreatedAt: string;
  configurablePriceCreatedBy: string;
  configurablePriceUpdatedAt: string;
  configurablePriceUpdatedBy: string;
  configurableSttAdjustment: ConfigurableSttAdjustment;

  constructor(
    configurablePriceId: number,
    configurablePriceName: string,
    configurablePriceType: string,
    configurablePriceDescription: string,
    configurablePriceStatus: string,
    configurablePriceCreatedAt: string,
    configurablePriceCreatedBy: string,
    configurablePriceUpdatedAt: string,
    configurablePriceUpdatedBy: string,
    configurableSttAdjustment: ConfigurableSttAdjustment
  ) {
    this.configurablePriceId = configurablePriceId;
    this.configurablePriceName = configurablePriceName;
    this.configurablePriceType = configurablePriceType;
    this.configurablePriceDescription = configurablePriceDescription;
    this.configurablePriceStatus = configurablePriceStatus;
    this.configurablePriceCreatedAt = configurablePriceCreatedAt;
    this.configurablePriceCreatedBy = configurablePriceCreatedBy;
    this.configurablePriceUpdatedAt = configurablePriceUpdatedAt;
    this.configurablePriceUpdatedBy = configurablePriceUpdatedBy;
    this.configurableSttAdjustment = configurableSttAdjustment;
  }
}

export class ConfigurableSttAdjustment {
  sttAdjustmentId: number;
  sttAdjustmentConfigurableId: number;
  penaltyAdjustedBy: string;
  penaltyApplyFor: string;
  mapPenaltyAdjustedBy: Array<MapPenaltyAdjustedBy>;

  constructor(
    sttAdjustmentId: number,
    sttAdjustmentConfigurableId: number,
    penaltyAdjustedBy: string,
    penaltyApplyFor: string,
    mapPenaltyAdjustedBy: Array<MapPenaltyAdjustedBy>
  ) {
    this.sttAdjustmentId = sttAdjustmentId;
    this.sttAdjustmentConfigurableId = sttAdjustmentConfigurableId;
    this.penaltyAdjustedBy = penaltyAdjustedBy;
    this.penaltyApplyFor = penaltyApplyFor;
    this.mapPenaltyAdjustedBy = mapPenaltyAdjustedBy;
  }
}

export class MapPenaltyAdjustedBy {
  name: string;
  value: string;

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }
}

export class AdjustmentConfigurationPayload {
  sttAdjustmentId: number;
  sttAdjustmentSttAdjustedBy: string;
  sttAdjustmentPenaltyApplyFor: string;

  constructor(
    sttAdjustmentId: number,
    sttAdjustmentSttAdjustedBy: string,
    sttAdjustmentPenaltyApplyFor: string
  ) {
    this.sttAdjustmentId = sttAdjustmentId;
    this.sttAdjustmentSttAdjustedBy = sttAdjustmentSttAdjustedBy;
    this.sttAdjustmentPenaltyApplyFor = sttAdjustmentPenaltyApplyFor;
  }
}
