export const ALL_FEATURE_ENABLE = "all_feature_enable";
export const ALLOWED = "allowed";
export const USER_MANAGEMENT = {
  ENABLE: "user_management_enable",
  CREATE: "user_management_account_create",
  EDIT: "user_management_account_detail_edit",
  DETAIL: "user_management_account_detail_view",
  VIEW: "user_management_list_view",
  CHANGE_ROLE: "user_management_account_change_role",
  CHANGE_EMAIL: "user_management_account_change_email",
  CHANGE_PASSWORD: "user_management_account_change_password"
};
export const BULK_DOWNLOAD = {
  ENABLE: "bulk_download_enable",
  CREATE: "bulk_download_create",
  VIEW: "bulk_download_list_view"
};
export const BULK_UPLOAD = {
  ENABLE: "bulk_upload_enable",
  CREATE: "bulk_upload_create",
  VIEW: "bulk_upload_list_view",
  DETAIL: "bulk_upload_detail_view",
  ROLE: "bulk_upload_type_role",
  ACCOUNT: "bulk_upload_type_account",
  ROUTE_MAPPING: "bulk_upload_type_route_mapping",
  VENDOR: "bulk_upload_type_vendor",
  ROUTE_LEG: "bulk_upload_type_route_leg",
  LEG: "bulk_upload_type_leg",
  ROUTE: "bulk_upload_type_route",
  TRANSPORT_VENDOR: "bulk_upload_type_transport_vendor",
  UPDATE_RETAIL: "bulk_upload_type_update_retail_base_rate",
  CREATE_RETAIL: "bulk_upload_type_create_retail_base_rate",
  COMMODITY_SURCHARGE: "bulk_upload_type_commodity_surcharge",
  COMMODITY: "bulk_upload_type_commodity",
  ADD_MAPPING_LOCATION:
    "bulk_upload_type_add_mapping_location_client_to_lionparcel",
  UPDATE_MAPPING:
    "bulk_upload_type_update_mapping_location_client_to_lionparcel",
  SLA_DISTRICT: "bulk_upload_type_sla_district",
  ESTIMATE_SLA_DISTRICT: "bulk_upload_estimate_sla_district",
  LOCATION_COVERAGE: "bulk_upload_type_location_coverage", // feature is not ready
  RATE: "bulk_upload_type_rate", // feature is not ready
  OPTION_RATE: "bulk_upload_type_option_rate", // feature is not ready
  SLA_CITY: "bulk_upload_type_sla_city",
  UPDATE_ESTIMATE_SLA_CITY: "bulk_upload_update_estimate_sla_city",
  BAGGING_GROUPING_LOCATION: "bulk_upload_bagging_group_location",
  CARGO_CONFIGURATION: "bulk_upload_config_cargo",
  ADD_EMBARGO: "bulk_upload_add_embargo",
  UPDATE_EMBARGO: "bulk_upload_update_embargo",
  ADD_CITY: "bulk_upload_add_city",
  UPDATE_CITY: "bulk_upload_update_city",
  ADD_COUNTRY: "bulk_upload_add_country",
  UPDATE_COUNTRY: "bulk_upload_update_country",
  ADD_DISTRICT: "bulk_upload_add_district",
  UPDATE_DISTRICT: "bulk_upload_update_district",
  AWB_RESERVE: "bulk_upload_awb_reserve",
  REGION: "bulk_upload_upsert_region",
  REGION_CITY: "bulk_upload_upsert_region_city",
  RTC_CITY_GROUP: "bulk_upload_rtc_city_group",
  UPDATE_COMMODITY: "bulk_upload_update_commodity"
};
export const ROUTE_LOCATION_COVERAGE = {
  ENABLE: "route_location_coverage_enable",
  CREATE: "route_location_coverage_create",
  EDIT: "route_location_coverage_detail_edit",
  DETAIL: "route_location_coverage_detail_view",
  VIEW: "route_location_coverage_list_view"
};
export const ROUTE_LEG = {
  ENABLE: "route_leg_management_enable",
  EDIT: "route_leg_management_detail_edit",
  DETAIL: "route_leg_management_detail_view",
  VIEW: "route_leg_management_list_view"
};
export const ROUTE_VENDOR = {
  ENABLE: "route_vendor_enable",
  CREATE: "route_location_coverage_create",
  EDIT: "route_vendor_detail_edit",
  DETAIL: "route_vendor_detail_view",
  VIEW: "route_vendor_list_view"
};
export const ROUTE_ROUTE_MANAGEMENT = {
  ENABLE: "route_mapping_enable",
  CREATE: "route_mapping_create",
  EDIT: "route_mapping_detail_edit",
  DETAIL: "route_mapping_detail_view",
  VIEW: "route_mapping_list_view"
};
export const ROUTE_TRANSPORT = {
  ENABLE: "route_transport_enable",
  CREATE: "route_transport_create",
  EDIT: "route_transport_detail_edit",
  DETAIL: "route_transport_detail_view",
  VIEW: "route_transport_list_view"
};
export const ROUTE_EMBARGO = {
  ENABLE: "route_embargo_enable",
  CREATE: "route_embargo_create",
  EDIT: "route_embargo_detail_edit",
  DETAIL: "route_embargo_detail_view",
  VIEW: "route_embargo_list_view"
};
export const PRICING_PRODUCT = {
  ENABLE: "pricing_product_enable",
  CREATE: "pricing_product_create",
  EDIT: "pricing_product_detail_edit",
  DETAIL: "pricing_product_detail_view",
  VIEW: "pricing_product_list_view"
};
export const PRICING_COMMODITY = {
  ENABLE: "pricing_commodity_enable",
  CREATE: "pricing_commodity_create",
  EDIT: "pricing_commodity_detail_edit",
  DETAIL: "pricing_commodity_detail_view",
  VIEW: "pricing_commodity_list_view"
};
export const PRICING_COMMODITY_SURCHARGE = {
  ENABLE: "pricing_commodity_surcharge_enable",
  CREATE: "pricing_commodity_surcharge_create",
  EDIT: "pricing_commodity_surcharge_detail_edit",
  DETAIL: "pricing_commodity_surcharge_detail_view",
  VIEW: "pricing_commodity_surcharge_list_view"
};
export const PRICING_BASE_RATE = {
  ENABLE: "pricing_base_rate_enable",
  CREATE: "pricing_base_rate_create",
  EDIT: "pricing_base_rate_detail_edit",
  DETAIL: "pricing_base_rate_detail_view",
  VIEW: "pricing_base_rate_list_view"
};
export const PRICING_EXCHANGE_RATE = {
  ENABLE: "pricing_exchange_rate_enable",
  CREATE: "pricing_exchange_rate_create",
  EDIT: "pricing_exchange_rate_detail_edit",
  DETAIL: "pricing_exchange_rate_detail_view",
  VIEW: "pricing_exchange_rate_list_view"
};
export const PRICING_CONFIGURABLE_PRICE = {
  ENABLE: "pricing_configurable_price_enable",
  CREATE: "pricing_configurable_price_create",
  EDIT: "pricing_configurable_price_detail_edit",
  DETAIL: "pricing_configurable_price_detail_view",
  VIEW: "pricing_configurable_price_list_view"
};
export const PRICING_GOODS_TAX = {
  ENABLE: "pricing_goods_tax_enable",
  CREATE: "pricing_goods_tax_create",
  EDIT: "pricing_goods_tax_detail_edit",
  DETAIL: "pricing_goods_tax_detail_view",
  VIEW: "pricing_goods_tax_list_view"
};
export const FINANCE_BALANCE_LIMIT = {
  ENABLE: "finance_balance_limit_rules_enable",
  EDIT: "finance_balance_limit_rules_detail_edit",
  DETAIL: "finance_balance_limit_rules_detail_view",
  VIEW: "finance_balance_limit_rules_list_view",
  CREATE: "finance_balance_limit_rules_create"
};
export const FINANCE_BALANCE_TRANSACTION = {
  ENABLE: "finance_balance_transaction_enable"
};
export const COMMISSION_CONFIG = {
  ENABLE: "commission_config_enable",
  EDIT: "commission_config_detail_edit",
  DETAIL: "commission_config_detail_view",
  VIEW: "commission_config_list_view",
  CREATE: "commission_config_create"
};

export const SHIPMENT_CN_MANIFEST = {
  ENABLE: "shipment_cn_manifest_enable",
  CREATE: "shipment_cn_manifest_create"
};

export const CLIENT = {
  ENABLE: "clients_enable",
  REGISTRATION_LIST: "clients_registration_list",
  REGISTRATION_EDIT: "clients_registration_detail_edit",
  REGISTRATION_DETAIL: "clients_registration_detail_view",
  REGISTRATION_VIEW: "clients_registration_detail_view",
  REGISTRATION_CREATE: "clients_registration_add",
  REGISTRATION_APPROVAL: "clients_registration_detail_approval",
  MANAGEMENT_LIST: "clients_management_list",
  MANAGEMENT_EDIT: "clients_management_detail_edit",
  MANAGEMENT_DETAIL: "clients_management_detail_view",
  MANAGEMENT_VIEW: "clients_management_detail_view",
  MANAGEMENT_CREATE: "clients_management_add",
  MANAGEMENT_APPROVAL: "clients_management_detail_renewal_approval"
};
export const ANNOUNCEMENT = {
  ENABLE: "announcement_enable",
  EDIT: "announcement_detail_edit",
  DETAIL: "announcement_detail_view",
  VIEW: "announcement_list_view",
  CREATE: "announcement_create"
};
export const SHIPMENT_STT_MANUAL = {
  ENABLE: "shipment_stt_manual_enable",
  VIEW: "shipment_stt_manual_list",
  CREATE: "shipment_stt_manual_create",
  DOWNLAOD: "shipment_stt_manual_download"
};
export const SHIPMENT_CEK_TARIF = {
  ENABLE: "check_tariff_enable",
  VIEW: "check_tariff_list_view",
  FILTER: "check_tariff_client_filter_view",
  DETAIL: "check_tariff_detail_view",
  BOOKING: "check_tariff_button_booking_view"
};
export const SHIPMENT_STT_BOOKING = {
  ENABLE: "booking_feature_enable",
  CREATE_MANUAL: "booking_create_manual",
  CREATE_MANUAL_FOR_CORPORATE: "booking_create_for_corporate",
  CREATE_SHIPMENT_ID: "booking_create_shipment_id",
  CREATE_INSTANT_BOOKING: "instant_booking_create",
  DETAIL_INSTANT_BOOKING: "instant_booking_detail",
  FILTER: "advance_filter_client_partner_click",
  VIEW: "booking_view_list",
  DETAIL: "booking_view_detail",
  EDIT: "booking_edit"
};
export const SHIPMENT_BULK_PRINT_RESI = {
  ENABLE: "shipment_bulk_print_resi_enable",
  CREATE: "shipment_bulk_print_resi_create"
};
export const SHIPMENT_PRINT_RECEIPT = {
  ENABLE: "shipment_print_receipt_enable",
  CREATE: "shipment_print_receipt_create"
};
export const OUTGOING_SHIPMENT_PICKUP_MANIFEST = {
  ENABLE: "pickup_manifest_enable",
  VIEW: "pickup_manifest_list_view",
  CREATE: "pickup_manifest_create"
};
export const OUTGOING_SHIPMENT_STI = {
  ENABLE: "sti_enable",
  VIEW: "sti_list_view",
  DETAIL: "sti_detail_view",
  CREATE: "sti_create"
};
export const OUTGOING_SHIPMENT_STI_SC = {
  ENABLE: "sti_sc_enable",
  VIEW: "sti_sc_list_view",
  CREATE: "sti_sc_create"
};
export const OUTGOING_SHIPMENT_STO_SC = {
  ENABLE: "sto_sc_enable",
  VIEW: "sto_sc_list_view"
};
export const OUTGOING_SHIPMENT_BAGGING = {
  ENABLE: "bagging_enable",
  VIEW: "bagging_list_view_enable",
  CREATE: "bagging_create",
  DETAIL: "bagging_detail_view",
  EDIT: "bagging_detail_edit"
};
export const INCOMING_PROCESS_STI_DEST = {
  ENABLE: "sti_dest_enable",
  VIEW: "sti_dest_list_view",
  DETAIL: "sti_dest_detail_view",
  CREATE: "sti_dest_create"
};
export const INCOMING_PROCESS_DELIVERY_MANIFEST = {
  ENABLE: "delivery_enable",
  VIEW: "delivery_list_view",
  CREATE: "delivery_create"
};
export const INCOMING_PROCESS_SHORTLAND = {
  ENABLE: "shortland_enable",
  VIEW: "shortland_list_view"
};
export const INCOMING_PROCESS_STI_DEST_SC = {
  ENABLE: "sti_dest_sc_enable",
  VIEW: "sti_dest_sc_list_view",
  CREATE: "sti_dest_sc_create"
};
export const INCOMING_PROCESS_HANDOVER = {
  ENABLE: "handover_enable",
  VIEW: "handover_list_view",
  CREATE: "handover_create",
  DETAIL: "handover_detail_view"
};
export const INCOMING_PROCESS_POD_DEX = {
  ENABLE: "pod_dex_enable",
  VIEW: "pod_dex_list",
  EDIT: "pod_dex_update"
};
export const PARTNER_MANAGEMENT = {
  ENABLE: "partner_management_enable",
  VIEW: "partner_management_view_list",
  CREATE: "partner_management_create",
  DETAIL: "partner_management_view_detail",
  EDIT: "partner_management_edit"
};
export const CUSTOM_PROCESS = {
  VIEW: "custom_process_view_list",
  CREATE: "custom_process_create",
  DETAIL: "custom_process_view_detail"
};
export const BULK_BOOKING = {
  ENABLE: "bulk_booking_enable",
  VIEW: "bulk_booking_list_view",
  CREATE: "bulk_booking_create",
  DETAIL: "bulk_booking_detail_view"
};

export const SUPPORT_POS_FAVORITE = {
  ENABLE: "support_pos_favourite_enable",
  VIEW_MEMBER: "support_pos_favourite_subscriber_view_list",
  DETAIL: "support_pos_favourite_subscriber_edit_detail",
  EDIT: "support_pos_favourite_subscriber_edit_default_discount",
  VIEW_MEMBER_OUTSTANDING:
    "support_pos_favourite_subscriber_outstanding_view_list"
};
export const STT_TRACKING = {
  ENABLE: "stt_tracking_enable"
};
export const CARGO_TRACKING = {
  ENABLE: "cargo_tracking_enable"
};
export const CARGO = {
  ENABLE: "cargo_enable",
  VIEW: "cargo_list_view",
  CREATE: "cargo_create",
  DETAIL: "cargo_detail_view",
  EDIT: "cargo_detail_edit"
};

export const ON_PROCESS_STT = {
  ENABLE: "on_process_stt_enable",
  VIEW: "on_process_stt_list_view",
  CANCEL: "on_process_stt_detail_cancel",
  DETAIL: "on_process_stt_detail_view",
  EDIT: "on_process_stt_detail_edit"
};
export const BOOKING_COMMISSION = {
  ENABLE: "commission_config_enable",
  VIEW: "commission_config_booking_list_view",
  CREATE: "commission_config_booking_add",
  DETAIL: "commission_config_booking_detail_view",
  EDIT: "commission_config_booking_detail_edit"
};
export const FINANCE_MANUAL_DEDUCT_ADD_SALDO = {
  ENABLE: "deduct_add_saldo_manual_enable",
  VIEW: "deduct_add_saldo_manual_list_view",
  DETAIL: "deduct_add_saldo_manual_detail_view",
  CREATE: "deduct_add_saldo_manual_create"
};
export const ROLE_ADMIN_FINANCE = {
  ADMIN_FINANCE: "admin_finance",
  ADMIN_FINANCE_VERIFY: "admin_finance_verify",
  ADMIN_FINANCE_APPROVAL: "admin_finance_approval"
};
export const DTPOL_CONFIG = {
  ENABLE: "dtpol_config_enable",
  VIEW: "dtpol_config_list_view",
  DETAIL: "dtpol_config_detail_view",
  EDIT: "dtpol_config_detail_edit"
};
export const UPDATE_DELIVERY = {
  ENABLE: "update_del_enable",
  VIEW: "update_del_list_view",
  CREATE: "update_del_create"
};
export const MESSAGE_CONFIG = {
  ENABLE: "message_config_enable",
  CREATE: "message_config_add",
  EDIT: "message_config_edit",
  VIEW: "message_config_list_view",
  DETAIL: "message_config_detail_view"
};
export const REPORT = {
  ENABLE: "report_enable",
  VIEW_STT: "report_view_stt_report_card",
  VIEW_MANUAL_STT: "report_view_manual_stt_report_card",
  VIEW_TRANSACTION: "report_view_transaction_report_card",
  VIEW_FPR: "report_view_fpr_report_card",
  VIEW_MISROUTE: "report_view_misroute_report_card",
  VIEW_SHORTLAND: "report_view_shortland_report_card",
  VIEW_INCOMING_SHIPMENT: "report_view_incoming_shipment_report_card"
};
export const INTEGRATION_MONITORING = {
  ENABLE: "integration_monitoring_enable",
  VIEW: "integration_monitoring_view"
};
export const KEJAR_CUAN = {
  ENABLE: "kejar_cuan_config_enable",
  VIEW: "kejar_cuan_config_list_view",
  DETAIL: "kejar_cuan_config_detail_view",
  EDIT: "kejar_cuan_config_detail_edit",
  CREATE: "kejar_cuan_config_create",
  REPORT: "kejar_cuan_report",
  VIEW_DASHBOARD: "kejar_cuan_dashboard",
  DETAIL_DASHBOARD: "kejar_cuan_dashboard_detail"
};
export const PPOB = {
  ENABLE: "ppob_enable"
};
export const REFERRAL = {
  ENABLE: "referral_enable"
};
export const PUSAT_RESOLUSI = {
  ENABLE: "pusat_resolusi_enable",
  VIEW: "pusat_resolusi_list_view",
  CREATE: "pusat_resolusi_create",
  DETAIL: "pusat_resolusi_detail_view",
  EDIT: "pusat_resolusi_detail_edit"
};

export const LAST_BALANCE = {
  ENABLE: "finance_last_balance_enable",
  VIEW_POS: "finance_last_balance_list_view_pos",
  VIEW_CLIENT: "finance_last_balance_list_view_client",
  VIEW_CLIENT_COD: "finance_last_balance_list_view_client_cod"
};

export const INCOMING_OUTGOING = {
  ENABLE: "internal_outgoing_incoming_enable",
  VIEW: "internal_outgoing_incoming_list",
  CARGO_DETAIL: "internal_outgoing_incoming_cargo_detail_view",
  CARGO_EDIT: "internal_outgoing_incoming_cargo_detail_edit",
  BAGGING_DETAIL: "internal_outgoing_incoming_bagging_detail_view",
  BAGGING_EDIT: "internal_outgoing_incoming_bagging_detail_edit",
  STI_DEST_DETAIL: "internal_outgoing_incoming_sti_dest_detail_view"
};

export const STT_PAID_UNPAID = {
  ENABLE: "dashboard_stt_payment_status_enable",
  VIEW: "dashboard_stt_payment_status_view"
};

export const PROGRESSIVE_COMMISSION_CONFIG = {
  ENABLE: "progressive_commission_configuration_enable",
  LIST: "progressive_commission_configuration_view_list",
  DETAIL: "progressive_commission_configuration_view_detail",
  CREATE: "progressive_commission_configuration_create",
  EDIT: "progressive_commission_configuration_edit"
};

export const BULK_BOOKING_CLIENT_TEMPLATE = {
  ENABLE: "bulk_booking_client_configuration_enable",
  LIST: "bulk_booking_client_configuration_view_list",
  DETAIL: "bulk_booking_client_configuration_view_detail",
  CREATE: "bulk_booking_client_configuration_create",
  EDIT: "bulk_booking_client_configuration_edit"
};

export const PROGRESSIVE_COMMISSION_DASHBOARD = {
  ENABLE: "dashboard_progressive_commission_enable"
};

export const BAGGING_GROUPING = {
  ENABLE: "bagging_grouping_enable",
  LIST: "bagging_grouping_list_view",
  DETAIL: "bagging_grouping_detail_view",
  EDIT: "bagging_grouping_detail_edit"
};

export const CLAIM = {
  ENABLE: "claim_enable",
  LIST: "claim_list_view",
  DETAIL: "claim_detail_view"
};
export const TRACK_DELIVERY = {
  ENABLE: "lacak_pengiriman_enable",
  LIST: "lacak_pengiriman_list_view",
  DETAIL: "lacak_pengiriman_detail_view"
};
export const CARGO_CONFIGURATION = {
  ENABLE: "cargo_configuration_enable",
  LIST: "cargo_configuration_view_list",
  DETAIL: "cargo_configuration_view_detail",
  CREATE: "cargo_configuration_create",
  EDIT: "cargo_configuration_edit"
};

export const CMS_BANNER = {
  ENABLE: "banner_config_enable",
  LIST: "banner_config_list_view",
  CREATE: "banner_config_create",
  DETAIL: "banner_config_detail_view",
  EDIT: "banner_config_update"
};

export const POS_BRANCH = {
  ENABLE: "pos_branch_enable",
  LIST: "pos_branch_list_view",
  DETAIL: "pos_branch_detail_view"
};
export const NOTIFICATION = {
  ENABLE: "notification_enable",
  LIST: "notification_view_list",
  DETAIL: "notification_view_detail"
};

export const READY_TO_CARGO = {
  ENABLE: "ready_to_cargo_enable",
  LIST: "ready_to_cargo_list_view",
  DETAIL: "ready_to_cargo_detail_view"
};

export const PROMO_CONFIGURATION = {
  ENABLE: "promo_discount_enable",
  LIST: "promo_discount_list_view",
  DETAIL: "promo_discount_view_detail",
  CREATE: "promo_discount_create",
  EDIT: "promo_discount_detail_edit",
  DUPLICATE: "promo_discount_detail_duplicate",
  CANCEL: "promo_discount_detail_cancel"
};

export const SERVICE_DELAY = {
  ENABLE: "service_delay_network_enable",
  LIST: "service_delay_network_list_view",
  DETAIL: "service_delay_network_detail_view",
  CREATE: "service_delay_network_create",
  EDIT: "service_delay_network_detail_edit"
};

export const COURIER_MANAGEMENT = {
  ENABLE: "courier_management_enable",
  LIST: "courier_management_list_view",
  DETAIL: "courier_management_detail_view",
  CREATE: "courier_management_create",
  EDIT: "courier_management_detail_edit"
};

export const COD_RETAIL_CONFIGURATION = {
  ENABLE: "configurable_cod_enable",
  LIST: "configurable_cod_view",
  DETAIL: "configurable_cod_detail",
  EDIT: "configurable_cod_edit"
};

export const BALANCE_MINUS_PENALTY = {
  ENABLE: "balance_minus_penalty_enable",
  LIST: "balance_minus_penalty_list_view",
  DETAIL: "balance_minus_penalty_detail_view",
  EDIT: "balance_minus_penalty_detail_edit"
};
