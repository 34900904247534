const formatPhoneNumber = (phone: string) => {
  if (phone.startsWith("0", 0)) {
    const newPhoneNumber = phone.substring(1);
    return "+62" + newPhoneNumber;
  } else if (phone.startsWith("6", 0)) {
    return "+" + phone;
  }
  return phone;
};
export default formatPhoneNumber;
