/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionIncomingProcessSTIDest = {
  permission_sti_dest_enable: new Rox.Flag(false),
  permission_sti_dest_create: new Rox.Flag(false),
  permission_sti_dest_detail: new Rox.Flag(false),
  flag_reverse_scan_sti_dest_enable: new Rox.Flag(false)
};

export function initFlagsPermissionIncomingProcessSTIDest() {
  // register flags with namespaces for better grouping
  Rox.register(
    "featureIncomingProcessSTIDest",
    FlagsPermissionIncomingProcessSTIDest
  );
}
