import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import {
  getTokenNGen,
  setCredentialNGen
} from "@/app/infrastructures/misc/Cookies";
import { MainAppController } from "./MainAppController";
import { AuthNGenPresenter } from "../presenters/AuthNGenPresenter";
import {
  AwbDetailData,
  ErrorData,
  FlightSchedule,
  TrackingBookingRequestIdData
} from "@/domain/entities/NGen";
import { NGenPresenter } from "../presenters/NGenPresenter";
import {
  GetScheduleListApiRequest,
  PrintAWBApiRequest,
  TrackingCargoByBookingRequestIdNgenApiRequest,
  TrackingCargoHistoryNgenApiRequest
} from "@/data/payload/api/NGenApiRequest";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";
import {
  ERROR_TOKEN_NGEN, parsingErrorResponse, storeDatatoLocalStorage
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "./AccountController";
import { TrackingByBookingRequestIdInterface } from "@/data/payload/contracts/NGenRequest";
export interface NGenControllerState {
  isLoading: boolean;
  errorCause: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "ngen"
})
class AuthNGenStore extends VuexModule implements NGenControllerState {
  isLoading = false;
  isError = false;
  errorCause = "";
  errorData = new ErrorData();
  flightScheduleList = new FlightSchedule();
  public errorAwb = JSON.parse(localStorage.getItem("error-awb") || "{}");
  public awbDetailData = new AwbDetailData();
  public isLoadingAwb = false;

  @Action
  public async onLoginNGen(showPopup = true) {
    const presenter = container.resolve(AuthNGenPresenter);
    const accessToken = getTokenNGen();

    if (!accessToken) {
      if (showPopup) {
        MainAppController.showLoading();
        MainAppController.closeErrorMessage();
      } else {
        this.setLoading(true);
        this.setErrorCause("");
      }
      return await presenter
        .loginNGen()
        .then(res => {
          if (!res.token && showPopup) {
            MainAppController.showErrorMessage(
              new ErrorMessageEntities({
                type: ERROR_TOKEN_NGEN,
                message: this.isAccountForeign ? "Please try again or you can choose regular cargo booking to continue." :"Silahkan coba kembali atau Anda bisa memilih booking kargo biasa untuk melanjutkan",
                title: this.isAccountForeign ? "Error System" :"Terjadi Gangguan Sistem",
                onClose: () => MainAppController.closeErrorMessage(),
                onTryAgain: () => this.onLoginNGen(showPopup)
              })
            );
            return false;
          }
          setCredentialNGen({ token: res.token });
          return true;
        })
        .catch(err => {
          if (showPopup) {
            if (err?.code === "ECONNABORTED") {
              this.setErrorCause("timeout");
            } else {
              MainAppController.showErrorMessage(
                parsingErrorResponse(err, this.isAccountForeign ?  "Failed to Connect to NGen!" : "Gagal Menghubungkan ke NGen!", () =>
                  this.onLoginNGen(showPopup)
                )
              );
            }
          } else {
            this.setErrorCause(err.response ? "server" : "internet");
          }
          return false;
        })
        .finally(() => {
          this.setLoading(false);
          MainAppController.closeLoading();
        });
    }
    MainAppController.closeLoading();
    return true;
  }

  get isAccountForeign() {
    return AccountController.accountData.accountIsForeign;
  }

  @Action
  public async onGetScheduleList(params: {
    cityCode: string;
    payload: GetScheduleListApiRequest;
  }) {
    this.setLoading(true);
    this.setErrorCause("");
    this.setFlightScheduleList(new FlightSchedule());
    this.setErrorData(new ErrorData());

    const presenter = container.resolve(NGenPresenter);

    return await presenter
      .getFlightSchedule(params.payload)
      .then((res: FlightSchedule) => {
        return res;
      })
      .catch(err => {
        let tmpError: ErrorData;
        switch (err.response?.data.ErrorCode) {
          case 1001:
          case 1003:
            tmpError = new ErrorData({
              code: 404,
              title: "",
              message:
                "Maaf, jadwal keberangkatan kargo pesawat sudah penuh atau tidak tersedia.",
              type: "not-found",
              icon: "flight-schedule-not-found",
              status: false
            });
            break;
          default:
            tmpError = new ErrorData({
              code: 500,
              title: "Oops, System Error",
              message: "",
              type: "server",
              icon: "server-error",
              status: false
            });
            break;
        }

        this.setErrorData(tmpError);
        return new FlightSchedule();
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  async trackingCargoByBookingRequestIdNgen(params: {
    payload: TrackingCargoByBookingRequestIdNgenApiRequest;
  }) {
    MainAppController.closeErrorMessage();
    this.setLoadingAwb(true);
    const ngenPresenter = container.resolve(NGenPresenter);
    return ngenPresenter
      .getTrackingCargoByBookingRequestIdNgen(params.payload)
      .then((res: TrackingBookingRequestIdData[]) => {
        return res;
      })
      .catch(err => {
        this.setError(true);
        MainAppController.closeLoading();

        return err.response;
      });
  }

  @Action
  async trackingCargoHistoryNgen(params: {
    payload: TrackingCargoHistoryNgenApiRequest;
    callback?: any;
  }) {
    MainAppController.closeErrorMessage();
    this.setLoadingAwb(true);
    const ngenPresenter = container.resolve(NGenPresenter);
    return ngenPresenter
      .getTrackingCargoHistoryNgen(params.payload)
      .then((res: AwbDetailData) => {
        if (res.errorAwb === "AWB does not exist") {
          this.setErrorAwb("not-found");
        } else {
          this.setErrorAwb("");
          this.setError(false);
          this.setAwbDetailData(res);
        }
        this.setLoadingAwb(false);
        return res;
      })
      .catch(err => {
        this.setError(true);
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            { response: undefined },
            "Pencarian Gagal !",
            params.callback
          )
        );
        if (!MainAppController.errorMessageModal) {
          setTimeout(() => {
            this.setErrorAwb("system-error");
            MainAppController.closeLoading();
            this.setLoadingAwb(false);
          }, 60000); // 1 minutes
        } else {
          MainAppController.closeLoading();
        }

        return err.response;
      });
  }

  @Action
  async checkAwbToNgen(params: {
    payload: TrackingCargoHistoryNgenApiRequest;
  }) {
    return container
      .resolve(NGenPresenter)
      .getTrackingCargoHistoryNgen(params.payload);
  }

  @Action
  async printAWBLionCargo(params: { AWBNo: number }) {
    MainAppController.showLoading();
    const ngenPresenter = container.resolve(NGenPresenter);
    return ngenPresenter
      .printAWBLionCargo(new PrintAWBApiRequest(params.AWBNo))
      .then(res => {
        if (res === "AWB does not exist") {
          return MainAppController.showErrorMessage(
            new ErrorMessageEntities({
              type: "client-error",
              message: res,
              title: "Download Gagal !",
              onClose: () => {
                MainAppController.closeErrorMessage();
              }
            })
          );
        } else {
          return window.open(res);
        }
      })
      .catch(err => {
        this.setError(true);
        return err.response;
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Mutation
  setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  setError(val: boolean) {
    this.isError = val;
  }

  @Mutation
  setErrorCause(val: string) {
    this.errorCause = val;
  }

  @Mutation
  setErrorData(data: ErrorData) {
    this.errorData = data;
  }

  @Mutation
  setFlightScheduleList(data: FlightSchedule) {
    this.flightScheduleList = data;
  }

  @Mutation
  public setErrorAwb(val: string) {
    this.errorAwb = val;
    storeDatatoLocalStorage("error-awb", val);
  }

  @Mutation
  public setAwbDetailData(data: AwbDetailData) {
    this.awbDetailData = data;
  }

  @Mutation
  public setLoadingAwb(value: boolean) {
    this.isLoadingAwb = value;
  }
}

export const NGenController = getModule(AuthNGenStore);
