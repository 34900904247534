import { injectable } from "tsyringe";
import { BulkBookingApiRepository } from "@/app/infrastructures/repositories/api/BulkBookingApiRepository";
import {
  BulkBookingEntities,
  BulkBookingResiEntities,
  BulkBookingSuccess,
  ClientBookingEntities,
  ClientBookingTemplateDetail
} from "@/domain/entities/BulkBooking";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { BulkBookingUploadRequestInterface } from "@/data/payload/contracts/BulkBookingRequest";
import {
  RequestBulkBooking,
  EditClientTemplateConfiguration,
  CreateClientTemplateConfiguration
} from "@/data/payload/api/BulkApiRequest";
import { ClientBookingListParams } from "@/data/payload/api/BulkBookingApiRequest";

@injectable()
export class BulkBookingPresenter {
  private repository: BulkBookingApiRepository;

  constructor(repo: BulkBookingApiRepository) {
    this.repository = repo;
  }

  public async getSuccessListBooked(id: number): Promise<BulkBookingSuccess> {
    return await this.repository.getSuccessListBooked(id);
  }

  public async downloadFailedFile(id: number): Promise<void> {
    return await this.repository.downloadFailedFile(id);
  }
  public async getListBulkBooking(
    params: RequestBulkBooking
  ): Promise<BulkBookingEntities> {
    return await this.repository.getListBulkBooking(params);
  }

  public async uploadBulkBooking(
    payload: BulkBookingUploadRequestInterface
  ): Promise<ResponsePayload> {
    return await this.repository.uploadBulkBooking(payload);
  }

  public async getListBulkBookingResi(
    id: number
  ): Promise<BulkBookingResiEntities> {
    return await this.repository.getListBulkBookingResi(id);
  }

  public async getDetailClientBookingTemplate(
    id: number
  ): Promise<ClientBookingTemplateDetail> {
    return await this.repository.getDetailClientBooking(id);
  }

  public async getListClientBookingTemplate(
    params: ClientBookingListParams
  ): Promise<ClientBookingEntities> {
    return await this.repository.getListClientBooking(params);
  }

  public async editClientTemplateConfiguration(
    id: number,
    payload: EditClientTemplateConfiguration
  ): Promise<ResponsePayload> {
    return this.repository.editClientTemplateConfiguration(id, payload);
  }

  public async createClientTemplateConfiguration(
    payload: CreateClientTemplateConfiguration
  ): Promise<ResponsePayload> {
    return this.repository.createClientTemplateConfiguration(payload);
  }
}
