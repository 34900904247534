import { injectable } from "tsyringe";
import { HoldBalanceHistoryApiRepository } from "@/app/infrastructures/repositories/api/HoldBalanceHistoryApiRepository";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";

@injectable()
export class HoldBalanceHistoryPresenter {
  private repository: HoldBalanceHistoryApiRepository;

  constructor(repository: HoldBalanceHistoryApiRepository) {
    this.repository = repository;
  }

  public getList(params: ApiRequestList): Promise<ResponsePayloadV2> {
    return this.repository.getList(params);
  }
}
