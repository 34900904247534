import { Pagination } from "./Pagination";

export class BulkBookingEntities {
  pagination: Pagination;
  data: BulkBookingData[];

  constructor(pagination: Pagination, data: BulkBookingData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class BulkBookingData {
  uploadId: string;
  activityName: string;
  documentType: string;
  createdFor: string;
  createdForCode: string;
  createdAt: Date;
  createdBy: string;
  totalStt: number;
  totalSttSuccess: number;
  status: string;
  archiveFile: string;
  failedFile: string;
  constructor(
    uploadId: string,
    activityName: string,
    documentType: string,
    createdFor: string,
    createdForCode: string,
    createdAt: Date,
    createdBy: string,
    totalStt: number,
    totalSttSuccess: number,
    status: string,
    archiveFile: string,
    failedFile: string
  ) {
    this.uploadId = uploadId;
    this.activityName = activityName;
    this.documentType = documentType;
    this.createdFor = createdFor;
    this.createdForCode = createdForCode;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.totalStt = totalStt;
    this.status = status;
    this.totalSttSuccess = totalSttSuccess;
    this.archiveFile = archiveFile;
    this.failedFile = failedFile;
  }
}

export class BulkBookingSuccess {
  bulkId: number;
  sttSuccess: BookedSttSuccess[];
  constructor(bulkId: number, sttSuccess: BookedSttSuccess[]) {
    this.bulkId = bulkId;
    this.sttSuccess = sttSuccess;
  }
}

export class BookedSttSuccess {
  manualSttNumber: string;
  originDistrictCode: string;
  destinationDistrictCode: string;
  shipperName: string;
  shipperAddress: string;
  shipperPhone: string;
  consigneeName: string;
  consigneeAddress: string;
  consigneePhone: string;
  totalPieces: string;
  totalWeight: string;
  commodityCode: string;
  productType: string;
  insuranceServiceType: string;
  valueOfGoods: number;
  externalNumber: string;
  cod: string;
  woodpacking: string;
  ppn: number;
  pph: number;
  shippingRate: number;
  insuranceRate: number;
  totalSurcharge: number;
  totalAmount: number;
  incomingRate: number;
  constructor(
    manualSttNumber: string,
    originDistrictCode: string,
    destinationDistrictCode: string,
    shipperName: string,
    shipperAddress: string,
    shipperPhone: string,
    consigneeName: string,
    consigneeAddress: string,
    consigneePhone: string,
    totalPieces: string,
    totalWeight: string,
    commodityCode: string,
    productType: string,
    insuranceServiceType: string,
    valueOfGoods: number,
    externalNumber: string,
    cod: string,
    woodpacking: string,
    ppn: number,
    pph: number,
    shippingRate: number,
    insuranceRate: number,
    totalSurcharge: number,
    totalAmount: number,
    incomingRate: number
  ) {
    this.manualSttNumber = manualSttNumber;
    this.originDistrictCode = originDistrictCode;
    this.destinationDistrictCode = destinationDistrictCode;
    this.shipperName = shipperName;
    this.shipperAddress = shipperAddress;
    this.shipperPhone = shipperPhone;
    this.consigneeName = consigneeName;
    this.consigneeAddress = consigneeAddress;
    this.consigneePhone = consigneePhone;
    this.totalPieces = totalPieces;
    this.totalWeight = totalWeight;
    this.commodityCode = commodityCode;
    this.productType = productType;
    this.insuranceServiceType = insuranceServiceType;
    this.valueOfGoods = valueOfGoods;
    this.externalNumber = externalNumber;
    this.cod = cod;
    this.woodpacking = woodpacking;
    this.ppn = ppn;
    this.pph = pph;
    this.shippingRate = shippingRate;
    this.insuranceRate = insuranceRate;
    this.totalSurcharge = totalSurcharge;
    this.totalAmount = totalAmount;
    this.incomingRate = incomingRate;
  }
}

export class BulkBookingResiEntities {
  data: BulkBookingResiData[];
  constructor(data: BulkBookingResiData[]) {
    this.data = data;
  }
}

export class BulkBookingResiData {
  sttNo: string;
  sttId: number;
  constructor(sttNo: string, sttId: number) {
    this.sttNo = sttNo;
    this.sttId = sttId;
  }
}

export class BulkBookingAddForm {
  type = "Bulk Booking";
  activityName = "";
  archiveFile = "";
  clientName = "";
  formatTemplate = "";
  constructor(fields?: Partial<BulkBookingAddForm>) {
    Object.assign(this, fields);
  }
}

export class ClientBookingEntities {
  pagination: Pagination;
  data: ClientBookingListData[];
  constructor(pagination: Pagination, data: ClientBookingListData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ClientBookingListData {
  id = 0;
  accountName = "";
  accountId = 0;
  createdBy = "";
  createdAt = new Date();
  updatedBy = "";
  updatedAt = new Date();
  constructor(fields?: Partial<ClientBookingListData>) {
    Object.assign(this, fields);
  }
}

export class HeaderMapping {
  genesisHeader = "";
  sourceHeader = "";
  activitySelect = false;
  constructor(fields?: Partial<HeaderMapping>) {
    Object.assign(this, fields);
  }
}

export class ClientBookingTemplateDetail {
  id = 0;
  accountRefId = 0;
  accountRefType = "";
  accountRefName = "";
  accountRefCode = "";
  createdAt = new Date();
  createdBy = "";
  updatedAt = new Date();
  updatedBy = "";
  headerMapping = [] as HeaderMapping[];

  constructor(fields?: Partial<ClientBookingTemplateDetail>) {
    Object.assign(this, fields);
  }
}
