export class TrackingCargoData {
  cargoId: number;
  cargoNo: string;
  cargoVehicleNo: string;
  cargoType: string;
  cargoTotalStt: number;
  cargoTotalSttPiece: number;
  cargoOriginCityCode: string;
  cargoOriginCityName: string;
  cargoDestinationCityCode: string;
  cargoDestinationCityName: string;
  cargoOriginDistrictCode: string;
  cargoOriginDistrictName: string;
  cargoDestinationDistrictCode: string;
  cargoDestinationDistrictName: string;
  cargoDestinationDistrictType: string;
  cargoTotalGrossWeight: number;
  cargoTotalVolumeWeight: number;
  cargoTotalCustomGrossWeight: number;
  cargoTotalCustomVolumeWeight: number;
  cargoHistory: CargoHistory[];
  constructor(
    cargoId: number,
    cargoNo: string,
    cargoVehicleNo: string,
    cargoType: string,
    cargoTotalStt: number,
    cargoTotalSttPiece: number,
    cargoOriginCityCode: string,
    cargoOriginCityName: string,
    cargoDestinationCityCode: string,
    cargoDestinationCityName: string,
    cargoOriginDistrictCode: string,
    cargoOriginDistrictName: string,
    cargoDestinationDistrictCode: string,
    cargoDestinationDistrictName: string,
    cargoDestinationDistrictType: string,
    cargoTotalGrossWeight: number,
    cargoTotalVolumeWeight: number,
    cargoTotalCustomGrossWeight: number,
    cargoTotalCustomVolumeWeight: number,
    cargoHistory: CargoHistory[]
  ) {
    this.cargoId = cargoId;
    this.cargoNo = cargoNo;
    this.cargoVehicleNo = cargoVehicleNo;
    this.cargoType = cargoType;
    this.cargoTotalStt = cargoTotalStt;
    this.cargoTotalSttPiece = cargoTotalSttPiece;
    this.cargoOriginCityCode = cargoOriginCityCode;
    this.cargoOriginCityName = cargoOriginCityName;
    this.cargoDestinationCityCode = cargoDestinationCityCode;
    this.cargoDestinationCityName = cargoDestinationCityName;
    this.cargoOriginDistrictCode = cargoOriginDistrictCode;
    this.cargoOriginDistrictName = cargoOriginDistrictName;
    this.cargoDestinationDistrictCode = cargoDestinationDistrictCode;
    this.cargoDestinationDistrictName = cargoDestinationDistrictName;
    this.cargoDestinationDistrictType = cargoDestinationDistrictType;
    this.cargoTotalGrossWeight = cargoTotalGrossWeight;
    this.cargoTotalVolumeWeight = cargoTotalVolumeWeight;
    this.cargoTotalVolumeWeight = cargoTotalVolumeWeight;
    this.cargoTotalVolumeWeight = cargoTotalVolumeWeight;
    this.cargoTotalCustomGrossWeight = cargoTotalCustomGrossWeight;
    this.cargoTotalCustomVolumeWeight = cargoTotalCustomVolumeWeight;
    this.cargoHistory = cargoHistory;
  }
}

export class CargoHistory {
  actorName: string;
  actorType: string;
  statusDate: string;
  status: string;
  statusLabel: string;
  totalPieces: string;
  location: string;
  description: string;
  constructor(
    actorName: string,
    actorType: string,
    statusDate: string,
    status: string,
    statusLabel: string,
    totalPieces: string,
    location: string,
    description: string
  ) {
    this.actorName = actorName;
    this.actorType = actorType;
    this.statusDate = statusDate;
    this.status = status;
    this.statusLabel = statusLabel;
    this.totalPieces = totalPieces;
    this.location = location;
    this.description = description;
  }
}
