export default {
  loading: "Your Request is In Progress",
  receipt: {
    createdAt: "created",
    createdAtV2: "created at",
    estimation: "estimate",
    shipper: "shipper",
    receiver: "receiver",
    destination: "destination",
    home: "home",
    office: "office",
    goodsValue: "good value",
    totalCost: "total cost",
    shippingCost: "shipment",
    shippingCostV2: "Shipment",
    insurance: "insurance",
    totalWeight: "total weight",
    taxBm: "Tax/BM",
    others: "other",
    othersV2: "Other",
    downloadApps:
      "Download Lion Parcel App via Appstore or Playstore and track your package now!",
    toc: "T&C : lionparcel.com/sk",
    goodValue: "good value",
    currencyDisplayGoodValue: "{value} USD",
    goodsPrice: "goods price",
    returnFee: "return fee",
    returnFeeV2: "Return",
    returnCost: "return cost",
    codFee: "COD Fee",
    dfodFee: "DFOD Fee",
    returStatement: "*if return",
    senderPage: "Scissors for shipper",
    terms: "Terms and Conditions Apply.",
    info: "For more information: https://lionparcel.com/sk",
    infoTracking:
      "Want to know the\nstatus of your\npackage? Track it in\nreal-time!",
    infoTrackingPrn: {
      info1: "Want to know the",
      info2: "status of your package? ",
      info3: "Track it in real-time!",
      info4: ""
    },
    scanHere: "scan here"
  },
  invoice: {
    date: "date",
    shipperName: "shipper name",
    shipperPhone: "shipper phone",
    shipperAddress: "shipper address",
    conssigneeName: "conssignee name",
    conssigneePhone: "conssignee phone",
    conssigneeAddress: "conssignee address",
    signatureOfShipper: "signature of shipper",
    itemDescription: "Item Description/ Komoditi",
    unitValue: "Unit Value/Harga Barang",
    qty: "Quantity/Jumlah",
    totalValue: "Total Value/Total Harga Barang",
    weight: "Weight/Berat",
    note:
      "*Note : Publish rate not include tax fee (Ongkos kirim tidak termasuk biaya pajak)",
    term:
      "I declare all the information contained in this invoice to be true and correct, and i am willing to bear\nany cost if the information is incorrect. (Saya menyatakan semua informasi yang berada di invoice\nini adalah benar dan saya bersedia menanggung biaya jika informasi yang saya berikan salah)"
  },
  revamp: {
    booking: {
      client: "Your Client",
      pos: "Your POS",
      serviceAvailable: "currently does not support",
      codOrDfod: "COD or DFOD",
      contactLP: " services, please contact Admin Lion Parcel",
      detailSender: "Sender Details",
      detailRecipient: "Recipient Details",
      detailPackageAndServiceType: "Item Details & Service Type",
      itemWeight: "Item Weight",
      insuranceWarning: "Shipments without insurance can only be claimed up to 10x the shipping fee / maximum to the applicable terms and conditions.",
      deliveryInsurance: "Shipping Insurance",
      detailCommodity: "Commodity Details",
      additionalPhoto: "Additional Photos",
      beforeAndAfterPackingPhoto: "Photos before & after package packing",
      packageBeforePacking: "Package before packing",
      packageAfterPacking: "Package after packing",
      recipientIdPhoto: "Recipient’s ID photo",
      recipientId: "Recipient’s ID",
      recipientTaxIdPhoto: "Recipient’s Tax ID photo"
    }
  }
};
