import { Pagination } from "./Pagination";

export class ManualDeductAddSaldoEntities {
  pagination: Pagination;
  data: ManualDeductAddSaldoData[];

  constructor(pagination: Pagination, data: ManualDeductAddSaldoData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ManualDeductAddSaldoData {
  id: number;
  genesisTransactionId: string;
  clientPartnerName: string;
  type: string;
  activityName: string;
  amountSaldo: number;
  transferProof: string;
  createdAt: Date;
  createdBy: string;
  status: string;
  notes: string;
  currency: string

  constructor(
    id: number,
    genesisTransactionId: string,
    clientPartnerName: string,
    type: string,
    activityName: string,
    amountSaldo: number,
    transferProof: string,
    createdAt: Date,
    createdBy: string,
    status: string,
    notes: string,
    currency: string
  ) {
    this.id = id;
    this.genesisTransactionId = genesisTransactionId;
    this.clientPartnerName = clientPartnerName;
    this.amountSaldo = amountSaldo;
    this.transferProof = transferProof;
    this.activityName = activityName;
    this.type = type;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.status = status;
    this.notes = notes;
    this.currency = currency;
  }
}

export class DetailManualDeductAddSaldoData {
  id: number;
  genesisTransactionId: string;
  accountType: string;
  clientPartnerName: string;
  type: string;
  activityName: string;
  amountSaldo: number;
  transferProof: string;
  createdAt: string;
  createdBy: string;
  notes: string;
  currency: string;

  constructor(
    id: number,
    genesisTransactionId: string,
    accountType: string,
    clientPartnerName: string,
    type: string,
    activityName: string,
    amountSaldo: number,
    transferProof: string,
    createdAt: string,
    createdBy: string,
    notes: string,
    currency: string
  ) {
    this.id = id;
    this.genesisTransactionId = genesisTransactionId;
    this.accountType = accountType;
    this.clientPartnerName = clientPartnerName;
    this.type = type;
    this.activityName = activityName;
    this.amountSaldo = amountSaldo;
    this.transferProof = transferProof;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.notes = notes;
    this.currency = currency;
  }
}
