import { BulkDownloadMapper } from "@/data/persistences/mappers/BulkDownloadMapper";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { BulkDownloadRepositoryInterface } from "@/data/persistences/repositories/contracts/BulkDownloadRepositoryInterface";

export class BulkDownloadApiRepository
  implements BulkDownloadRepositoryInterface {
  private service: ApiService;
  private mapper: BulkDownloadMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: BulkDownloadMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getExistingData(service: string, type: string): Promise<string> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getExistingData(service, type),
      {}
    );
    return this.mapper.convertExistingData(resp);
  }
}
