import { ON_PROCESS_STT } from "@/app/infrastructures/misc/RolePermission";
import { FlagsPermissionOnProcessstt } from "@/feature-flags/flags-permission-on-process-stt";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const DetailBooking = () =>
  import(
    /* webpackChunkName: "STTBooking" */ "@/app/ui/views/shipment/booking/detail-booking/detail.vue"
  );
const EditBooking = () =>
  import(
    /* webpackChunkName: "STTBooking" */ "@/app/ui/views/shipment/booking/config-edit-booking/index.vue"
  );

export default [
  {
    path: "/shipment/on-process-stt",
    component: Layout,
    name: "shipment-on-process-stt-detail",
    meta: {
      title: "Shipment On Process STT | Detail",
      hidden: true,
      parent: "On Process STT",
      permission: FlagsPermissionOnProcessstt.on_process_stt_detail_edit
    },
    children: [
      {
        path: ":id",
        name: "detail-on-process-stt",
        component: DetailBooking,
        meta: {
          header: true,
          customPath: "on-process-stt",
          isAuth: false
        }
      }
    ]
  },
  {
    path: "/shipment/on-process-stt",
    component: Layout,
    name: "shipment-on-process-stt-edit",
    meta: {
      title: "Shipment On Process STT | Edit",
      hidden: true,
      parent: "On Process STT",
      permission: FlagsPermissionOnProcessstt.on_process_stt_detail_edit
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-on-process-stt",
        component: EditBooking,
        meta: {
          header: true,
          title: "On Process STT | Edit",
          name: "Ubah Booking",
          customPath: "on-process-stt",
          isAuth: false
        }
      }
    ]
  }
];
