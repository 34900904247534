/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionPickup = {
    permission_pickup_scheduled_pickup_enable: new Rox.Flag(false),
    permission_pickup_scheduled_pickup_create: new Rox.Flag(false),
    permission_pickup_scheduled_pickup_edit: new Rox.Flag(false),
    permission_pickup_enable: new Rox.Flag(false),
    permission_pickup_list_pickup_create: new Rox.Flag(false),
    permission_pickup_list_pickup_edit: new Rox.Flag(false),
    permission_pickup_list_pickup_cancel: new Rox.Flag(false)
};

export function initFlagsPermissionPickup() {
  Rox.register("featurePickup", FlagsPermissionPickup);
}
