import { PRICING_COMMODITY_SURCHARGE } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const DetailCommoditySurcharge = () =>
  import(
    /* webpackChunkName: "CommoditySurcharge" */ "@/app/ui/views/pricing/commodity-surcharge/component/detail.vue"
  );
const EditCommoditySurcharge = () =>
  import(
    /* webpackChunkName: "CommoditySurcharge" */ "@/app/ui/views/pricing/commodity-surcharge/component/edit.vue"
  );
const FormCommoditySurcharge = () =>
  import(
    /* webpackChunkName: "CommoditySurcharge" */ "@/app/ui/views/pricing/commodity-surcharge/component/form.vue"
  );

export default [
  {
    path: "/tarif/surcharge",
    component: Layout,
    name: "detail-commodity-surcharge",
    meta: {
      icon: "commodity-surcharge",
      children: false,
      hidden: true,
      parent: "Tarif Komoditas",
      permission: PRICING_COMMODITY_SURCHARGE.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-commodity-surcharge",
        component: DetailCommoditySurcharge,
        meta: {
          header: true,
          title: "Tarif Komoditas | Detail"
        }
      }
    ]
  },
  {
    path: "/tarif/surcharge",
    component: Layout,
    name: "commodity-surcharge-add",
    meta: {
      icon: "commodity-surcharge",
      children: false,
      hidden: true,
      parent: "Tarif Komoditas",
      permission: PRICING_COMMODITY_SURCHARGE.CREATE
    },
    children: [
      {
        path: "add",
        name: "commodity-surcharge-add",
        component: FormCommoditySurcharge,
        meta: {
          header: true,
          title: "Buat Tarif Komoditas Baru",
          name: "Buat Tarif Komoditas Baru",
          formType: "add",
          before: "/tarif/surcharge"
        }
      }
    ]
  },
  {
    path: "/tarif/surcharge",
    component: Layout,
    name: "commodity-surcharge-edit",
    meta: {
      icon: "commodity-surcharge",
      children: false,
      hidden: true,
      parent: "Tarif Komoditas",
      permission: PRICING_COMMODITY_SURCHARGE.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-commodity-surcharge",
        component: FormCommoditySurcharge,
        meta: {
          header: true,
          title: "Ubah Tarif Komoditas",
          name: "Ubah",
          formType: "edit",
          before: "/tarif/surcharge/:id"
        }
      }
    ]
  }
];
