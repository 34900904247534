import { PRICING_GOODS_TAX } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const AddGoodsTax = () =>
  import(
    /* webpackChunkName: "GoodsTax" */ "@/app/ui/views/pricing/goods-tax/component/add.vue"
  );
const EditGoodsTax = () =>
  import(
    /* webpackChunkName: "GoodsTax" */ "@/app/ui/views/pricing/goods-tax/component/edit.vue"
  );
const DetailGoodsTax = () =>
  import(
    /* webpackChunkName: "GoodsTax" */ "@/app/ui/views/pricing/goods-tax/component/detail.vue"
  );

export default [
  {
    path: "/tarif/goods-tax",
    component: Layout,
    name: "add-goods-tax",
    meta: {
      icon: "goods-tax",
      children: false,
      hidden: true,
      parent: "Pajak Barang",
      permission: PRICING_GOODS_TAX.CREATE
    },
    children: [
      {
        path: "add",
        name: "add-goods-tax",
        component: AddGoodsTax,
        meta: {
          header: true,
          title: "Pajak Barang | Create",
          name: "Buat Pajak Barang Baru",
          before: "/tarif/goods-tax"
        }
      }
    ]
  },
  {
    path: "/tarif/goods-tax",
    component: Layout,
    name: "edit-goods-tax",
    meta: {
      icon: "goods-tax",
      children: false,
      hidden: true,
      parent: "Pajak Barang",
      permission: PRICING_GOODS_TAX.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-goods-tax",
        component: EditGoodsTax,
        meta: {
          header: true,
          title: "Pajak Barang | Edit",
          name: "Edit",
          before: "/tarif/goods-tax/:id"
        }
      }
    ]
  },
  {
    path: "/tarif/goods-tax",
    redirect: "/tarif/goods-tax",
    component: Layout,
    name: "detail-goods-tax",
    meta: {
      icon: "goods-tax",
      children: false,
      hidden: true,
      parent: "Pajak Barang",
      permission: PRICING_GOODS_TAX.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-goods-tax",
        component: DetailGoodsTax,
        meta: {
          header: true,
          title: "Pajak Barang | Detail"
        }
      }
    ]
  }
];
