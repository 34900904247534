/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionPrintReceipt = {
  shipment_print_receipt_enable: new Rox.Flag(false),
  receipt_version: new Rox.RoxString("v1"),
  permission_campaign_viral_enable: new Rox.Flag(false),
};

export function initFlagsPermissionPrintReceipt() {
  // register flags with namespaces for better grouping
  Rox.register("featurePrintReceipt", FlagsPermissionPrintReceipt);
}
