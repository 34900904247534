/* eslint-disable @typescript-eslint/camelcase */
import { EditLegRequestInterface } from "../contracts/LegRequest";
import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class EditLegApiRequest implements EditLegRequestInterface {
  private leg_id: number;
  private min_sla: number;
  private max_sla: number;
  private status: string;

  constructor(
    leg_id: number,
    min_sla: number,
    max_sla: number,
    status: string
  ) {
    this.leg_id = leg_id;
    this.min_sla = min_sla;
    this.max_sla = max_sla;
    this.status = status;
  }

  public toJSON(): string {
    return JSON.stringify({
      leg_id: this.leg_id,
      min_sla: this.min_sla,
      max_sla: this.max_sla,
      status: this.status
    });
  }
}

export class RequestListRouteLeg {
  cache = false;
  originId = "";
  destinationId = "";
  routeType = "city-city";

  constructor(fields?: Partial<RequestListRouteLeg>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
