import { Pagination } from "./Pagination";

export class TransportEntities {
  pagination: Pagination;
  data: TransportData[];
  constructor(pagination: Pagination, data: TransportData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class TransportData {
  id: number;
  transportCode: string;
  transportType: string;
  vendorId: number;
  vendorName: string;
  vendorCode: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  constructor(
    id: number,
    transportCode: string,
    transportType: string,
    vendorId: number,
    vendorName: string,
    vendorCode: string,
    status: string,
    createdAt: string,
    updatedAt: string,
    createdBy: string,
    updatedBy: string
  ) {
    this.id = id;
    this.transportCode = transportCode;
    this.transportType = transportType;
    this.vendorId = vendorId;
    this.vendorName = vendorName;
    this.vendorCode = vendorCode;
    this.status = status;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
  }
}
export class VendorData {
  status: string;
  constructor(status: string) {
    this.status = status;
  }
}
