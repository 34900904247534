import { BaseRatesApiRepository } from "@/app/infrastructures/repositories/api/BaseRatesApiRepository";
import { ChangeBaseRatesRequestInterface } from "@/data/payload/contracts/BaseRatesRequest";
import { BaseRatesEntities, RatesDetail } from "@/domain/entities/BaseRates";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { injectable } from "tsyringe";

@injectable()
export class BaseRatesPresenter {
  private repository: BaseRatesApiRepository;

  constructor(repo: BaseRatesApiRepository) {
    this.repository = repo;
  }

  public getBaseRatesList(
    search: string,
    status: string,
    page: number,
    limit: number
  ): Promise<BaseRatesEntities> {
    return this.repository.getBaseRatesList(search, status, page, limit);
  }

  public getRatesList(search: string): Promise<BaseRatesEntities> {
    return this.repository.getRatesList(search);
  }

  public getDetailRates(id: number): Promise<RatesDetail> {
    return this.repository.getDetail(id);
  }

  public editBaseRates(
    id: number,
    payload: ChangeBaseRatesRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editBaseRates(id, payload);
  }
}
