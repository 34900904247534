export const STATUS_CLAIM = [
  { name: "Pilih status klaim", value: "" },
  { name: "Diajukan", value: "submitted" },
  { name: "Disetujui", value: "approved" },
  { name: "Ditolak", value: "rejected" },
  { name: "Selesai", value: "finished" }
];
export const CATEGORY_CLAIM = [
  { name: "Pilih kategori", value: "" },
  { name: "Hilang", value: "lost" },
  { name: "Rusak", value: "damaged" },
  { name: "Keterlambatan", value: "mbg" }
];
