import { Pagination } from "./Pagination";

export class BulkBookingCommissionEntities {
  pagination: Pagination;
  data: BulkBookingCommissionData[];

  constructor(pagination: Pagination, data: BulkBookingCommissionData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class BulkBookingCommissionData {
  id: number;
  uploadId: string;
  activityName: string;
  type: string;
  createdAt: Date;
  createdBy: string;
  status: string;
  successFile: string;
  successFileName: string;
  failedFile: string;
  failedFileName: string;

  constructor(
    id: number,
    uploadId: string,
    activityName: string,
    type: string,
    createdAt: Date,
    createdBy: string,
    status: string,
    successFile: string,
    successFileName: string,
    failedFile: string,
    failedFileName: string
  ) {
    this.id = id;
    this.uploadId = uploadId;
    this.activityName = activityName;
    this.type = type;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.status = status;
    this.successFile = successFile;
    this.successFileName = successFileName;
    this.failedFile = failedFile;
    this.failedFileName = failedFileName;
  }
}

export class BulkBookingCommissionDetail {
  commissionId = 0;
  commissionCode = "";
  commissionBookingSource = "";
  commissionFor = "";
  commissionShipmentId = [];
  commissionProduct = "";
  commissionCommodity = "";
  commissionTierBase = "";
  commissionPriceTierReference = "";
  commissionPriceReference = "";
  commissionType = "";
  commissionArchiveType = "";
  commissionActivityName = "";
  commissionDescription = "";
  commissionStatus = "";
  successFile = "";
  successFileName = "";
  failedFile = "";
  failedFileName = "";
  commissionQuantifier = "";
  commissionCreatedAt = new Date();
  commissionCreatedBy = "";
  commissionUpdatedAt = new Date();
  commissionUpdatedBy = "";
  commissionCalculationTier: Array<BulkBookingCommissionCalculationTier> = [];
  constructor(fields?: Partial<BulkBookingCommissionDetail>) {
    Object.assign(this, fields);
  }
}

export class BulkBookingCommissionCalculationTier {
  tier = 0;
  startWeight = "";
  endWeight = "";
  commissionAmount = 0;
  commissionAmountBranch = 0;
  commissionAmountPickup = 0;
  commissionAmountPickupPublishRate = 0;
  constructor(fields?: Partial<BulkBookingCommissionCalculationTier>) {
    Object.assign(this, fields);
  }
}

export class BulkBookingCommissionCreateResponse {
  failedRow: number;
  successRow: number;
  bulkId: number;
  bookingCommissionId: number;
  constructor(
    failedRow: number,
    successRow: number,
    bulkId: number,
    bookingCommissionId: number
  ) {
    this.failedRow = failedRow;
    this.successRow = successRow;
    this.bulkId = bulkId;
    this.bookingCommissionId = bookingCommissionId;
  }
}
