import { CARGO_CONFIGURATION } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

const CargoConfigurationDetail = () =>
  import(
    /* webpackChunkName: "CargoConfiguration" */ "@/app/ui/views/route/cargo-configuration/cargo-configuration-detail/index.vue"
  );
const CargoConfigurationForm = () =>
  import(
    /* webpackChunkName: "CargoConfiguration" */ "@/app/ui/views/route/cargo-configuration/cargo-configuration-form/index.vue"
  );

export default [
  {
    path: "/network/cargo-configuration",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Konfigurasi Kargo"
    },
    children: [
      {
        path: ":id",
        name: "cargo-configuration-detail",
        component: CargoConfigurationDetail,
        meta: {
          header: true,
          title: "Konfigurasi Kargo | Detail",
          permission: CARGO_CONFIGURATION.DETAIL
        }
      }
    ]
  },
  {
    path: "/network/cargo-configuration",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Konfigurasi Kargo"
    },
    children: [
      {
        path: "add",
        name: "cargo-configuration-form-add",
        component: CargoConfigurationForm,
        meta: {
          header: true,
          title: "Konfigurasi kargo | Add",
          name: "Buat Konfig",
          formType: "add",
          before: "/cargo-configuration",
          permission: CARGO_CONFIGURATION.CREATE
        }
      }
    ]
  },
  {
    path: "/network/cargo-configuration",
    component: Layout,
    meta: {
      hidden: true,
      parent: "Konfigurasi Kargo"
    },
    children: [
      {
        path: ":id/edit",
        name: "cargo-configuration-form-edit",
        component: CargoConfigurationForm,
        meta: {
          header: true,
          title: "Konfigurasi kargo | Edit",
          name: "Edit Konfig",
          formType: "edit",
          before: "/cargo-configuration/:id",
          permission: CARGO_CONFIGURATION.EDIT
        }
      }
    ]
  }
];
