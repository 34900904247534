/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionOutgoingShipmentSTI = {
  permission_sti_enable: new Rox.Flag(false),
  permission_sti_create: new Rox.Flag(false),
  permission_sti_detail_view: new Rox.Flag(false),
  flag_reverse_scan_sti_enable: new Rox.Flag(false)
};

export function initFlagsPermissionOutgoingShipmentSTI() {
  // register flags with namespaces for better grouping
  Rox.register(
    "featureOutgoingShipmentSTI",
    FlagsPermissionOutgoingShipmentSTI
  );
}
