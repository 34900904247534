import {
  PartnerSubconsoleEntities,
  PartnerSubconsoleData
} from "@/domain/entities/PartnerSubconsole";
import { AxiosResponse } from "axios";
import { Pagination } from "@/domain/entities/Pagination";

export class PartnerSubconsoleMapper {
  public convertPartnerSubconsoleDataFromApi(
    result: AxiosResponse<any>
  ): PartnerSubconsoleEntities {
    const { data } = result;
    return new PartnerSubconsoleEntities(
      new Pagination(data.meta.page, data.meta.limit),
      data.data.length > 0
        ? data.data.map((item: any) => {
            return new PartnerSubconsoleData(
              item.id,
              item.name,
              item.code,
              item.partner_location?.district.name || "",
              item.partner_location?.city.name || "",
              item.parent_name,
              item.contract_end,
              item.contract_status,
              item.is_banned
            );
          })
        : []
    );
  }
}
