import { ALLOWED } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const BeneficiaryAccountList = () =>
  import(
    /* webpackChunkName: "Balance" */ "@/app/ui/views/beneficiary-account-management/index.vue"
  );

export default [
  {
    path: "/cod-income",
    component: Layout,
    name: "cod-beneficiary-account",
    meta: {
      icon: "empty",
      hidden: true,
      parent: "COD Earning",
      permission: ALLOWED
    },
    children: [
      {
        path: "beneficiary-account",
        name: "beneficiary-account",
        component: BeneficiaryAccountList,
        meta: {
          header: true,
          title: "COD | Rekening Bank",
          name: "Rekening Bank",
          before: "/cod"
        }
      }
    ]
  }
];
