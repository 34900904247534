/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsGamificationRTC = {
  feature_is_gamification: new Rox.Flag(false),
  feature_gamification_message: new Rox.RoxString(""),
  dimension_divider: new Rox.RoxNumber(0),
  config_time_cancel_cargo: new Rox.RoxNumber(2),
  config_interval_auto_refresh_cargo: new Rox.RoxNumber(60)
};

export function initFlagsGamificationRTC() {
  // register flags with namespaces for better grouping
  Rox.register("featureCargo", FlagsGamificationRTC);
}
