export class BeneficiaryEntities {
  data: BeneficiaryData[];
  constructor(data: BeneficiaryData[]) {
    this.data = data;
  }
}

export class BeneficiaryData {
  bankAccountId: number;
  bankAccountLabel: string;
  bankName: string;
  bankAccountLogo: string;
  bankAccountNumber: string;
  bankAccountName: string;
  bankAccountType: string;
  bankAccountEmail: string;
  bankAccountPaymentProcedure: string;
  bankAccountActorId: number;
  bankAccountActorType: string;
  bankAccountCreatedAt: string;
  bankAccountUpdatedAt: any;
  partnerBeneficiaryIsRegistry: boolean;

  constructor(
    bankAccountId: number,
    bankAccountLabel: string,
    bankName: string,
    bankAccountLogo: string,
    bankAccountNumber: string,
    bankAccountName: string,
    bankAccountType: string,
    bankAccountEmail: string,
    bankAccountPaymentProcedure: string,
    bankAccountActorId: number,
    bankAccountActorType: string,
    bankAccountCreatedAt: string,
    bankAccountUpdatedAt: any,
    partnerBeneficiaryIsRegistry: boolean
  ) {
    this.bankAccountId = bankAccountId;
    this.bankAccountLabel = bankAccountLabel;
    this.bankName = bankName;
    this.bankAccountLogo = bankAccountLogo;
    this.bankAccountNumber = bankAccountNumber;
    this.bankAccountName = bankAccountName;
    this.bankAccountType = bankAccountType;
    this.bankAccountEmail = bankAccountEmail;
    this.bankAccountPaymentProcedure = bankAccountPaymentProcedure;
    this.bankAccountActorId = bankAccountActorId;
    this.bankAccountActorType = bankAccountActorType;
    this.bankAccountCreatedAt = bankAccountCreatedAt;
    this.bankAccountUpdatedAt = bankAccountUpdatedAt;
    this.partnerBeneficiaryIsRegistry = partnerBeneficiaryIsRegistry;
  }
}

export class ValidateBankAccount {
  accountName: string;
  accountNo: string;
  bankName: string;
  isAllowToUse: boolean;
  constructor(
    accountName: string,
    accountNo: string,
    bankName: string,
    isAllowToUse: boolean
  ) {
    this.accountName = accountName;
    this.accountNo = accountNo;
    this.bankName = bankName;
    this.isAllowToUse = isAllowToUse;
  }
}
