import { EditDeliveryTieringPolicy } from "@/data/payload/api/DeliveryTieringPolicyApiRequest";
import { DeliveryTieringPolicyRequest } from "@/data/payload/contracts/DeliveryTieringPolicyRequest";
import { DeliveryTieringPolicyMapper } from "@/data/persistences/mappers/DeliveryTieringPolicyMapper";
import { DeliveryTieringPolicyRepositoryInterface } from "@/data/persistences/repositories/contracts/DeliveryTieringPolicyRepositoryInterface";
import {
  DeliveryTieringPolicyData,
  DeliveryTieringPolicyEntities
} from "@/domain/entities/DeliveryTieringPolicy";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class DeliveryTieringPolicyApiRepository
  implements DeliveryTieringPolicyRepositoryInterface {
  private service: ApiService;
  private mapper: DeliveryTieringPolicyMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: DeliveryTieringPolicyMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getDeliveryTieringPolicyList(
    page: number,
    limit: number,
    search: string
  ): Promise<DeliveryTieringPolicyEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDtpoList(page, limit, search),
      {}
    );
    return this.mapper.convertDeliveryTieringPolicyListFromApi(resp);
  }

  public async getDeliveryTieringPolicyDetail(
    id: number
  ): Promise<DeliveryTieringPolicyData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDtpolDetail(id),
      {}
    );
    return this.mapper.convertDeliveryTieringPolicyDetailFromApi(resp);
  }

  public async editDeliveryTieringPolicyDetail(
    payload: DeliveryTieringPolicyRequest
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivate(
      "put",
      this.endpoints.editDtpol(),
      {},
      payload as EditDeliveryTieringPolicy
    );
    return this.mapper.convertDeliveryTieringPolicyEditFromApi(resp);
  }
}
