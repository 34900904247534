const MONTH_NAMES = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember"
];

const getFormattedDate = (
  date: any,
  prefomattedDate: any = false,
  hideYear = false,
  hideTime = false
) => {
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

  if (prefomattedDate) {
    return `${prefomattedDate} ${hours}:${minutes}`;
  }

  if (hideYear) {
    return `${day} ${month} ${hours}:${minutes}`;
  }

  if (hideTime) {
    return `${day} ${month} ${year} `;
  }

  return `${day} ${month} ${year} ${hours}:${minutes}`;
};
export default getFormattedDate;
