export class DetailInstantBookingData {
  ibId = 0;
  ibPosId = 0;
  ibPosName = "";
  ibOriginCityId = "";
  ibOriginCityName = "";
  ibIsFinish = true;
  ibCreatedBy = 0;
  ibCreatedName = "";
  ibCreatedAt = "0001-01-01T00:00:00Z";
  bookingSuccess = [] as DetailInstantBookingListData[];
  bookingFailed = [] as DetailInstantBookingListData[];
  totalShipmentId = 0;
  totalBookingSuccess = 0;
  totalBookingFailed = 0;

  constructor(fields?: Partial<DetailInstantBookingData>) {
    Object.assign(this, fields);
  }
}

export class DetailInstantBookingListData {
  ibdId = 0;
  ibdInstantBookingId = 0;
  ibdShipmentId = "";
  ibdShipmentPackageId = 0;
  ibdSttId = 0;
  ibdSttNo = "";
  ibdIsSuccess = false;
  ibdErrorReason = "";
  senderName = "";
  senderPhone = "";
  recipientName = "";
  recipientPhone = "";
  recipientAddress = "";
  piece = 0;
  chargeableWeight = 1;
  totalTariff = 0;
  constructor(fields?: Partial<DetailInstantBookingListData>) {
    Object.assign(this, fields);
  }
}

export class InstantBookingSession {
  posId = 0;
  posName = "";
  originCityId = "";
  originCityName = "";
  totalShipmentId = 0;
  stt = [] as DetailInstantBookingListData[];
  constructor(fields?: Partial<InstantBookingSession>) {
    Object.assign(this, fields);
  }
}
