/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionShipmentRates = {
    permission_shipment_rates_my_enable: new Rox.Flag(false),
    permission_shipment_rates_enable: new Rox.Flag(false)
};

export function initFlagsPermissionShipmentRates() {
  // register flags with namespaces for better grouping
  Rox.register("featureShipmentRates", FlagsPermissionShipmentRates);
}
