import moment from "moment";

const getDifferenceDate = (date1: string, date2: string) => {
  const x = moment(date1);
  const y = moment(date2);
  const xDate = moment(date1).startOf("day");
  const yDate = moment(date2).startOf("day");
  const duration = moment.duration(y.diff(x));
  const differenceDate = yDate.diff(xDate, "days");

  return {
    years: duration.get("years"),
    months: duration.get("months"),
    days: duration.get("days"),
    date: differenceDate,
    hours: duration.get("hours"),
    minutes: duration.get("minutes")
  };
};
export default getDifferenceDate;
