import { Pagination } from "@/domain/entities/Pagination";
import {
  PosFavoriteSubscriberEntities,
  PosFavoriteSubscriberListData,
  PosFavoriteSubscriberOutstandingEntities,
  PosFavoriteSubscriberOutstandingListData,
  PosFavouriteDefaultDiscount
} from "@/domain/entities/PosFavorite";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import { formatDate } from "@/app/infrastructures/misc/Utils";

export class PosFavoriteMapper {
  public convertListPosFavoriteFromApi(
    res: AxiosResponse<any>
  ): PosFavoriteSubscriberEntities {
    const { data } = res;
    const posFavoriteData: PosFavoriteSubscriberListData[] = [];
    data.data.map((item: any) => {
      posFavoriteData.push(
        new PosFavoriteSubscriberListData(
          item.customer_id,
          item.fullname,
          item.phone_number,
          item.discount,
          item.spending
        )
      );
    });
    return new PosFavoriteSubscriberEntities(
      new Pagination(data.pagination.page, data.pagination.per_page),
      posFavoriteData
    );
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
  public convertListPosFavoriteSubscriberOutstandingFromApi(
    res: AxiosResponse<any>
  ): PosFavoriteSubscriberOutstandingEntities {
    const { data } = res;
    const outstandingData: PosFavoriteSubscriberOutstandingListData[] = [];
    data.data.map((item: any) => {
      outstandingData.push(
        new PosFavoriteSubscriberOutstandingListData(
          item.shipment_id,
          formatDate(item.package_created),
          1,
          item.status.toLowerCase(),
          1,
          item.fullname,
          "-",
          item.phone_number
        )
      );
    });
    return new PosFavoriteSubscriberOutstandingEntities(
      new Pagination(data.pagination.page, data.pagination.per_page),
      outstandingData
    );
  }

  public getDetailDefaultDiscountDataFromApi(
    result: AxiosResponse<any>
  ): PosFavouriteDefaultDiscount {
    const { data } = result;

    return new PosFavouriteDefaultDiscount(
      data.default_discount,
      data.last_updated_date,
      data.updated_by
    );
  }
}
