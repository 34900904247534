import { ReadNotificationApiRequest } from "@/data/payload/api/NotificationRequest";
import { ChangeRequestInterface } from "@/data/payload/contracts/NotificationRequest";
import { NotificationDataMapper } from "@/data/persistences/mappers/NotificationMapper";
import { NotificationRepositoryInterface } from "@/data/persistences/repositories/contracts/NotificationRepositoryInterface";
import {
  NotificationData,
  NotificationsEntities,
  UnreadData
} from "@/domain/entities/Notification";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class NotificationApiRepository
  implements NotificationRepositoryInterface {
  private service: ApiService;
  private mapper: NotificationDataMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: NotificationDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getUnread(cache: boolean): Promise<UnreadData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getUnreadNotification(cache),
      {}
    );
    return this.mapper.convertUnreadDataFromApi(resp);
  }

  public async getAnnouncement(
    page: number,
    limit: number,
    status: string,
    search: string,
    isPinned: number,
    sort: string,
    cache: boolean
  ): Promise<NotificationsEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getNotifications(
        page,
        limit,
        status,
        search,
        isPinned,
        sort,
        cache
      ),
      {}
    );
    return this.mapper.convertNotificationDataFromApi(resp);
  }

  public async getDetail(id: number): Promise<NotificationData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.detailNotification(id),
      {}
    );
    return this.mapper.convertDetailNotificationDataFromApi(resp);
  }

  public async readNotification(
    payload: ChangeRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.readNotification(),
      undefined,
      payload as ReadNotificationApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }
}
