import {
  ErrorMessageEntities,
  ErrorSpesificationModal
} from "@/domain/entities/MainApp";
import {
  CLIENT_ERROR,
  INTERNAL_SERVER_ERROR,
  INTERNET_ERROR
} from "@/app/infrastructures/misc/Constants";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { getToken } from "../Cookies";

export const ERROR_SPESIFICATION = (error: string): ErrorSpesificationModal => {
  switch (error) {
    case "Destination Forward Rate price for selected route is not available":
    case "Origin Forward Rate price for selected route is not available":
    case "Shipping Surcharge and Publish Rate price for selected route is not available":
    case "Harga Shipping Surcharge dan Publish Rate untuk rute yang dipilih tidak tersedia":
    case "Harga Origin Forward Rate  dan REGPACK untuk rute yang dipilih tidak tersedia":
      return new ErrorSpesificationModal({
        headerError: "Harga Tidak Ditemukan !",
        errorMsg: "Kota tujuan yang Anda pilih belum memiliki harga.",
        statusError: true,
        errorDestination: false,
        isErrorEmbargo: false
      });

    case "Unacceptable due to an embargo":
    case "Tidak dapat diproses karena embargo":
      return new ErrorSpesificationModal({
        headerError: "EMBARGO !",
        errorMsg: "Sistem tidak dapat memproses permintaan Anda.",
        statusError: true,
        errorDestination: false,
        isErrorEmbargo: true
      });

    case "Tidak bisa booking. Saldo dibawah minimum balance saldo yang ditentukan":
      return new ErrorSpesificationModal({
        headerError: "Saldo Anda Dibawah Limit !",
        errorMsg:
          "Lakukan pengisian saldo sekarang juga. Pastikan saldo Anda tersedia sebelum melakukan aktivitas pengiriman.",
        statusError: true,
        errorDestination: false,
        isErrorEmbargo: false
      });

    default:
      return new ErrorSpesificationModal({
        headerError: "Permintaan Gagal !",
        errorMsg: error,
        statusError: false,
        errorDestination: false,
        isErrorEmbargo: false
      });
  }
};

const parsingErrorResponse = (
  err: any,
  title?: string,
  onTryAgain?: Function,
  onClose?: Function
): ErrorMessageEntities => {
  const localeAccount = AccountController.accountData.account_type_detail.countryCode.toLocaleLowerCase();
  if (!err.response) {
    return new ErrorMessageEntities({
      type: INTERNET_ERROR,
      onTryAgain: onTryAgain,
      onClose: onClose
    });
  }
  if (err.response.status < 500) {
    return new ErrorMessageEntities({
      type: CLIENT_ERROR,
      message: localeAccount === 'id' && getToken() ? err.response.data?.message?.id : err.response.data?.message?.en,
      title: title,
      onClose: onClose,
      onTryAgain: onTryAgain
    });
  }
  return new ErrorMessageEntities({
    type: INTERNAL_SERVER_ERROR,
    onTryAgain: onTryAgain,
    onClose: onClose
  });
};
export default parsingErrorResponse;
