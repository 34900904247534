/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionSTTTracking = {
  permission_stt_tracking_enable: new Rox.Flag(false),
  flag_revamp_tracking: new Rox.Flag(false)
};

export function initFlagsPermissionSTTTracking() {
  // register flags with namespaces for better grouping
  Rox.register(
    "featureSTTTracking",
    FlagsPermissionSTTTracking
  );
}