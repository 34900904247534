import {
  CourierSummary,
  DepositAmount,
  SttInDelivery,
  CourierDetailList,
  CourierDetail,
  CodDashboardDetailCourier,
  DashboardDeliveryCourierDetail,
  BankDetail,
  CourierDetailPendingReconcile,
} from "@/domain/entities/CodReconcile";
import { paymentMethod } from "@/domain/entities/Dashboard";
import { AxiosResponse } from "axios";

export class CodReconcileMapper {
  convertCourierSummaryDetailFromApiV2(res: AxiosResponse): CourierSummary {
    const { data } = res;

    const codAmountDeposit = data.data.cod_amount_deposit;
    const sttDeposit = data.data.stt_deposit || [];
    const sttInDelivery = data.data.stt_in_delivery || [];

    return new CourierSummary({
      codAmountDeposit: new DepositAmount({
        collected: codAmountDeposit.collected,
        pending: codAmountDeposit.pending,
      }),
      sttDeposit: sttDeposit.map((item: any) => {
        return new DepositAmount({
          status: item.status,
          collected: item.collected,
          collectedPieces: item.collected_pieces,
          pending: item.pending,
          pendingPieces: item.pending_pieces,
        });
      }),
      sttInDelivery: sttInDelivery.map((item: any) => {
        return new SttInDelivery({
          count: item.count,
          pieces: item.pieces,
          status: item.status,
        });
      }),
    });
  }

  convertDetailCourierCodFromApi(res: AxiosResponse): CourierDetailList {
    const { data } = res;

    const list = data.data || [];

    return new CourierDetailList({
      data: list.map((item: any) => {
        return new CourierDetail({
          sttCodAmount: item.stt_cod_amount,
          sttCollectionStatus: item.stt_collection_status,
          sttRecipientName: item.stt_recipient_name,
          sttRecipientPhone: item.stt_recipient_phone,
          sttNo: item.stt_no,
          sttStatus: item.stt_status,
          sttReason: item.stt_reason,
          sttPaymentMethod: paymentMethod[item.stt_payment_method],
          sttDeliveryDate: item.stt_delivery_date
        });
      })
    });
  }

  convertDetailCourierPartnerCodDashboardFromApi(
    res: AxiosResponse
  ): CodDashboardDetailCourier {
    const { data } = res;
    return new CodDashboardDetailCourier({
      courierPhone:
        data.data.delivery_driver_phone || data.data.delivery_pic_phone,
      courierName:
        data.data.delivery_driver_name ||
        data.data.delivery_pic_naitem.courierNamev3me,
      courierId: data.data.delivery_partner_id,
      courierPartnerName: data.data.delivery_partner_name,
      courierPartnerPhone: data.data.delivery_partner_phone,
      courierPartnerContactPhone: data.data.delivery_partner_contact_phone,
      totalStt: data.data.total_stt,
      totalSttPod: data.data.total_stt_pod,
      totalCollected: data.data.total_collected,
      totalQrisCollected: data.data.total_qris_collected,
      totalOutstanding: data.data.total_outstanding,
      totalSttDel: data.data.total_stt_del,
      totalSttCodrejCollected: data.data.total_stt_codrej_collected,
      totalSttCodrejOutstanding: data.data.total_stt_codrej_outstanding,
      totalSttDexCollected: data.data.total_stt_dex_collected,
      totalSttDexOutstanding: data.data.total_stt_dex_outstanding,
      performanceSameDay: data.data.performance_same_day,
      percentageSameDay: data.data.percentage_same_day,
      performanceDifferentDay: data.data.performance_different_day,
      percentageDifferentDay: data.data.percentage_different_day
    });
  }

  convertDetailCourierCodFromApiV2(res: AxiosResponse): CourierDetail[] {
    const {
      data: { data },
    } = res;

    return data?.length
      ? data.map(
          (item: any) =>
            new CourierDetail({
              sttCodAmount: item.stt_cod_amount,
              sttCollectionStatus: item.stt_collection_status,
              sttRecipientName: item.stt_recipient_name,
              sttRecipientPhone: item.stt_recipient_phone,
              sttNo: item.stt_no,
              sttStatus: item.stt_status,
              sttReason: item.stt_reason,
              sttPaymentMethod: item.stt_payment_method,
              sttDeliveryDate: item.stt_delivery_date,
            })
        )
      : [];
  }

  convertDetailCourierPendingReconcileFromApi(
    res: AxiosResponse
  ): CourierDetailPendingReconcile[] {
    const {
      data: { data },
    } = res;

    return data?.length
      ? data.map(
          (item: any) =>
            new CourierDetailPendingReconcile({
              dateGroup: item.date_group,
              codAmount: item.cod_amount,
              sttAmount: item.stt_amount,
              sttPieces: item.stt_pieces,
            })
        )
      : [];
  }

  convertDashboardDeliveryCourierDetailFromApi(
    res: AxiosResponse
  ): DashboardDeliveryCourierDetail {
    const {
      data: { data },
    } = res;

    return new DashboardDeliveryCourierDetail({
      courierId: data.courier_id,
      fullname: data.fullname,
      announcementCourierType: data.announcement_courier_type,
      bank: new BankDetail({
        bankAccount: data.bank.bank_account,
        bankAccountName: data.bank.bank_account_name,
        bankId: data.bank.bank_id,
        bankName: data.bank.bank_name,
      }),
      courierIsCod: data.courier_is_cod,
      courierStatus: data.courier_status,
      courierType: data.courier_type,
      id: data.courier_id,
      partnerName: data.partner_name,
      partnerType: data.partner_type,
      phoneNumber: data.phone_number,
      vehiclePlate: data.vehicle_plate,
      vehicleType: data.vehicle_type,
    });
  }
}
