export const countries = [
  {
    flag: "indonesia",
    code: "+62",
    name: "Indonesia",
    nationalPrefix: "0"
  },
  {
    flag: "saudi_arabia",
    code: "+966",
    name: "Saudi Arabia",
    nationalPrefix: "0"
  },
  {
    flag: "uni_emirate_arab",
    code: "+971",
    name: "Uni Emirat Arab",
    nationalPrefix: "0"
  },
  {
    flag: "australia",
    code: "+61",
    name: "Australia",
    nationalPrefix: "0"
  },
  {
    flag: "jepang",
    code: "+81",
    name: "Jepang",
    nationalPrefix: "0"
  },
  {
    flag: "korea",
    code: "+82",
    name: "Korea Selatan",
    nationalPrefix: "82"
  },
  {
    flag: "brunei",
    code: "+673",
    name: "Brunei",
    nationalPrefix: ""
  },
  {
    flag: "taiwan",
    code: "+886",
    name: "Taiwan",
    nationalPrefix: "0"
  },
  {
    flag: "philippines",
    code: "+63",
    name: "Filiphina",
    nationalPrefix: "0"
  },
  {
    flag: "hongkong",
    code: "+852",
    name: "Hongkong",
    nationalPrefix: ""
  },
  {
    flag: "vietnam",
    code: "+84",
    name: "Vietnam",
    nationalPrefix: "0"
  },
  {
    flag: "thailand",
    code: "+66",
    name: "Thailand",
    nationalPrefix: "0"
  },
  {
    flag: "singapore",
    code: "+65",
    name: "Singapore",
    nationalPrefix: ""
  },
  {
    flag: "malaysia",
    code: "+60",
    name: "Malaysia",
    nationalPrefix: "0"
  },
  {
    flag: "usa",
    code: "+1",
    name: "USA",
    nationalPrefix: "1"
  },
  {
    flag: "germany",
    code: "+49",
    name: "Jerman",
    nationalPrefix: "0"
  },
  {
    flag: "cambodia",
    code: "+855",
    name: "Cambodia",
    nationalPrefix: "0"
  },
  {
    flag: "macau",
    code: "+853",
    name: "Macau",
    nationalPrefix: ""
  },
  {
    flag: "netherland",
    code: "+31",
    name: "Netherland",
    nationalPrefix: "0"
  },
  {
    flag: "france",
    code: "+33",
    name: "Prancis",
    nationalPrefix: "0"
  },
  {
    flag: "uk",
    code: "+44",
    name: "Inggris",
    nationalPrefix: "0"
  },
  {
    flag: "turkey",
    code: "+90",
    name: "Turki",
    nationalPrefix: "9"
  },
  {
    flag: "qatar",
    code: "+974",
    name: "Qatar",
    nationalPrefix: "0"
  },
  {
    flag: "canada",
    code: "+1",
    name: "Kanada",
    nationalPrefix: "1"
  },
  {
    flag: "newzeland",
    code: "+64",
    name: "New Zealand",
    nationalPrefix: "0"
  },
  {
    flag: "india",
    code: "+91",
    name: "India",
    nationalPrefix: "0"
  },
  {
    flag: "timor-leste",
    code: "+670",
    name: "Timor Leste",
    nationalPrefix: "0"
  },
  {
    flag: "china",
    code: "+86",
    name: "China",
    nationalPrefix: "0"
  },
  {
    flag: "italy",
    code: "+39",
    name: "Italy",
    nationalPrefix: "0"
  },
  {
    flag: "south_africa",
    code: "+27",
    name: "South Africa",
    nationalPrefix: "0"
  },
  {
    flag: "bangladesh",
    code: "+880",
    name: "Bangladesh",
    nationalPrefix: "0"
  },
  {
    flag: "oman",
    code: "+968",
    name: "Oman",
    nationalPrefix: "0"
  },
  {
    flag: "kuwait",
    code: "+965",
    name: "Kuwait",
    nationalPrefix: "0"
  },
  {
    flag: "spain",
    code: "+34",
    name: "Spain",
    nationalPrefix: "0"
  },
  {
    flag: "pakistan",
    code: "+92",
    name: "Pakistan",
    nationalPrefix: "0"
  },
  {
    flag: "sri_lanka",
    code: "+94",
    name: "Sri Lanka",
    nationalPrefix: "0"
  },
  {
    flag: "morocco",
    code: "+212",
    name: "Morocco",
    nationalPrefix: "0"
  },
  {
    flag: "egypt",
    code: "+20",
    name: "Egypt",
    nationalPrefix: "0"
  },
  {
    flag: "austria",
    code: "+43",
    name: "Austria",
    nationalPrefix: "0"
  },
  {
    flag: "belgium",
    code: "+32",
    name: "Belgium",
    nationalPrefix: "0"
  },
  {
    flag: "greece",
    code: "+30",
    name: "Greece",
    nationalPrefix: "0"
  },
  {
    flag: "sweden",
    code: "+46",
    name: "Sweden",
    nationalPrefix: "0"
  },
  {
    flag: "kazakhstan",
    code: "+7",
    name: "Kazakhstan",
    nationalPrefix: "0"
  },
  {
    flag: "findland",
    code: "+365",
    name: "Findland",
    nationalPrefix: "0"
  },
  {
    flag: "monaco",
    code: "+377",
    name: "Monaco",
    nationalPrefix: "0"
  },
  {
    flag: "denmark",
    code: "+45",
    name: "Denmark",
    nationalPrefix: "0"
  },
  {
    flag: "mexico",
    code: "+52",
    name: "Mexico",
    nationalPrefix: "0"
  },
  {
    flag: "brazil",
    code: "+55",
    name: "Brazil",
    nationalPrefix: "0"
  },
  {
    flag: "costa_rica",
    code: "+506",
    name: "Costa Rica",
    nationalPrefix: "0"
  },
  {
    flag: "venezuela",
    code: "+58",
    name: "Venezuela",
    nationalPrefix: "0"
  },
  {
    flag: "peru",
    code: "+51",
    name: "Peru",
    nationalPrefix: "0"
  }
];
