import { VendorApiRepository } from "@/app/infrastructures/repositories/api/VendorApiRepository";
import {
  DeliveryVendorRequestInterface,
  EditVendorRequestInterface
} from "@/data/payload/contracts/VendorRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  DeliveryVendor,
  VendorData,
  VendorEntities
} from "@/domain/entities/Vendor";
import { injectable } from "tsyringe";

@injectable()
export class VendorPresenter {
  private repository: VendorApiRepository;

  constructor(repo: VendorApiRepository) {
    this.repository = repo;
  }

  public getVendorList(
    search: string,
    status: string,
    page: number,
    limit: number
  ): Promise<VendorEntities> {
    return this.repository.getVendorList(search, status, page, limit);
  }

  public getVendorDetail(id: number): Promise<VendorData> {
    return this.repository.getDetailVendor(id);
  }

  public editVendor(
    payload: EditVendorRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editUser(payload);
  }

  public getDeliveryVendorList(
    page: number,
    limit: number,
    search: string
  ): Promise<DeliveryVendor[]> {
    return this.repository.getDeliveryVendorList(page, limit, search);
  }

  public addDeliveryVendor(
    payload: DeliveryVendorRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addDeliveryVendor(payload);
  }
}
