/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionConfigQuotaTicket = {
  permission_config_quota_ticket_enable: new Rox.Flag(false)
};

export function initFlagsPermissionFlagsPermissionConfigQuotaTicket() {
  // register flags with namespaces for better grouping
  Rox.register("featureConfigQuotaTicket", FlagsPermissionConfigQuotaTicket);
}
