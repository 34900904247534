/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionOutgoingShipmentRelease = {
  permission_release_enable: new Rox.Flag(false),
  permission_release_detail_view: new Rox.Flag(false),
};

export function initFlagsPermissionOutgoingShipmentRelease() {
  // register flags with namespaces for better grouping
  Rox.register(
    "featureOutgoingShipmentRelease",
    FlagsPermissionOutgoingShipmentRelease
  );
}
