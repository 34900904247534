import {
  BaggingToReadyToCargoRequestInterface,
  ChangeBaggingRequestInterface,
  UpdateBaggingInterface
} from "@/data/payload/contracts/BaggingRequest";
import { BaggingMapper } from "@/data/persistences/mappers/BaggingMapper";
import { BaggingRepositoryInterface } from "@/data/persistences/repositories/contracts/BaggingRepositoryInterface";
import {
  BaggingDataDetail,
  BaggingEntities,
  BaggingSttList,
  RegionBaggingGrouping
} from "@/domain/entities/Bagging";
import { ResponsePayload, ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import {
  BaggingListRequest,
  BaggingToReadyToCargoRequest,
  CreateApiRequest,
  UpdateBaggingRequest
} from "@/data/payload/api/BaggingApiRequest";
import { downloadFile } from "../../misc/Utils";

export class BaggingApiRepository implements BaggingRepositoryInterface {
  private service: ApiService;
  private mapper: BaggingMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: BaggingMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  
  public async updateBaggingToReadyToCargo(payload: BaggingToReadyToCargoRequestInterface): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.updateBaggingToRTC(),
      undefined,
      payload as BaggingToReadyToCargoRequest
    );
    return this.mapper.convertUpdateBaggingRTCFromApi(resp);
  }

  public async getListBagging(
    params: BaggingListRequest
  ): Promise<BaggingEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListBagging(params),
      {}
    );
    return this.mapper.convertBaggingDataFromApi(resp);
  }

  public async getDetailBagging(id: number): Promise<BaggingDataDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailBagging(id),
      {}
    );
    return this.mapper.convertBaggingDetailFromApi(resp);
  }

  public async getDetailSttNumber(
    sttNumber: string,
    partnerId?: number
  ): Promise<BaggingSttList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailSttNumberBagging(sttNumber, partnerId),
      {}
    );
    return this.mapper.convertSttNumberBaggingDetailFromApi(resp);
  }

  public async createBagging(
    payload: ChangeBaggingRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.createBagging(),
      undefined,
      payload as CreateApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async updateBagging(
    payload: UpdateBaggingInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "put",
      this.endpoints.updateBagging(),
      {},
      payload as UpdateBaggingRequest
    );
    return this.mapper.convertChangeDataFromApi(response);
  }

  public async downloadBagging(id: number): Promise<any> {
    return downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.downloadManifest(
        `bag/xlsx?bag_id=${id}`
      )}`,
      `Bagging_Manifest_${id}`,
      ".xlsx",
      true
    );
  }

  public async getRegionBaggingGrouping(
    code: string
  ): Promise<RegionBaggingGrouping> {
    const response = await this.service.invoke(
      "get",
      this.endpoints.getRegionBaggingGrouping(code),
      {}
    );
    return this.mapper.convertRegionBaggingGroupingData(response);
  }
}
