
import { Options, prop, Vue } from "vue-class-component";

class Props {
  id = prop<string>({
    type: String,
    default: ""
  });
  title = prop<string>({
    default: "",
    type: String
  });
  icon = prop<string>({
    default: "",
    type: String
  });
  iconV2 = prop<boolean>({
    default: false,
    type: Boolean
  });
  iconV2Fill = prop<boolean>({
    default: false,
    type: Boolean
  });
  iconLeft = prop<string>({
    default: "",
    type: String
  });
  textSize = prop<number>({
    default: 12,
    type: Number
  });
  loading = prop<boolean>({
    default: false,
    type: Boolean
  });
  disabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  color = prop<string>({
    default: "red-lp-300",
    type: String
  });
  textColor = prop<string>({
    default: "",
    type: String
  });
  iconColor = prop<string>({
    default: "",
    type: String
  });
  flat = prop<boolean>({
    default: false,
    type: Boolean
  });
  outline = prop<boolean>({
    default: false,
    type: Boolean
  });
  rounded = prop<boolean>({
    default: false,
    type: Boolean
  });
  customClass = prop<string>({
    default: "",
    type: String
  });
  small = prop<boolean>({
    default: false,
    type: Boolean
  });
  dense = prop<boolean>({
    default: false,
    type: Boolean
  });
  compact = prop<boolean>({
    default: false,
    type: Boolean
  });
  tight = prop<boolean>({
    default: false,
    type: Boolean
  });
  sizeIcon = prop<string>({
    default: "20px",
    type: String
  });
  fontBold = prop<boolean>({
    default: false,
    type: Boolean
  });
  fontWeight = prop<string>({
    default: "",
    type: String
  });
  colorHover = prop<string>({
    default: "",
    type: String
  });
  textColorHover = prop<string>({
    default: "",
    type: String
  });
  isWidthFull = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  emits: ["click"],
  computed: {
    buttonStyle() {
      if (this.flat) {
        if (this.disabled) {
          return "text-gray-lp-600";
        }
        return `text-${this.textColor}`;
      }
      if (this.outline) {
        if (this.disabled) {
          return "border border-gray-lp-600 bg-white text-gray-lp-600";
        }
        return `border border-${this.color} text-${this.textColor} bg-white`;
      }
      if (this.disabled) {
        return "bg-gray-lp-600 border border-gray-lp-600 text-white";
      }
      return `border border-${this.color} bg-${this.color} text-${this.textColor}`;
    }
  },
  methods: {
    onClick(e: any) {
      if (this.disabled) {
        return;
      }
      this.$emit("click", e);
    }
  }
})
export default class LpButton extends Vue.with(Props) {}
