import { DetailForwardBookingCommission } from "@/domain/entities/ForwardBookingCommission";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ForwardBookingCommissionMapper } from "@/data/persistences/mappers/ForwardBookingCommissionMapper";
import { ForwardBookingCommissionInterface } from "@/data/persistences/repositories/contracts/ForwardBookingCommissionInterface";
import { EditForwardBookingCommissionApiRequest } from "@/data/payload/api/ForwardBookingCommissionApiRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class ForwardBookingCommissionApiRepository
  implements ForwardBookingCommissionInterface {
  private service: ApiService;
  private mapper: ForwardBookingCommissionMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: ForwardBookingCommissionMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getDetailForwardBookingCommission(
    id: any
  ): Promise<DetailForwardBookingCommission> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailForwardBookingCommission(id),
      {}
    );
    return this.mapper.convertDetailForwardBookingCommissionFromApi(resp);
  }

  public async editForwardBookingCommission(
    payload: EditForwardBookingCommissionApiRequest
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "put",
      this.endpoints.editConfigurablePrice(),
      {},
      payload
    );
    return this.mapper.convertPutDataFromApi(resp);
  }
}
