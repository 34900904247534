import {
  BookingCommissionDetail,
  BookingCommissionEntities
} from "@/domain/entities/BookingCommission";
import { BookingCommissionApiRepository } from "@/app/infrastructures/repositories/api/BookingCommissionApiRepository";
import { BookingCommissionRequest } from "@/data/payload/contracts/BookingCommissionRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { RequestBookingCommissionList } from "@/data/payload/api/BookingCommissionApiRequest";

export class BookingCommissionPresenter {
  private repository: BookingCommissionApiRepository;

  constructor(repository: BookingCommissionApiRepository) {
    this.repository = repository;
  }

  public getBookingCommissionList(
    params: RequestBookingCommissionList
  ): Promise<BookingCommissionEntities> {
    return this.repository.getBookingCommissionList(params);
  }

  public getBookingCommissionDetail(
    id: number
  ): Promise<BookingCommissionDetail> {
    return this.repository.getBookingCommissionDetail(id);
  }

  public addBookingCommission(
    payload: BookingCommissionRequest
  ): Promise<ResponsePayload> {
    return this.repository.addBookingCommission(payload);
  }

  public editBookingCommission(
    payload: BookingCommissionRequest
  ): Promise<ResponsePayload> {
    return this.repository.editBookingCommission(payload);
  }
}
