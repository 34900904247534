import { RequestUrgentDeliveryListApi, RequestUrgentDeliveryListApiDownload } from "@/domain/entities/UrgentDelivery";

export class UrgentDeliveryEndpoint {
  getUrgentDeliveryList(params: RequestUrgentDeliveryListApi): string {
    return `/hydra/v1/request-priority-delivery?${params.toQueryString()}`;
  }

  getSTTStatusUrgentDeliveryList(): string {
    return `/hydra/v1/request-priority-delivery/stt-status`;
  }

  getUrgentDeliverySTTDownload(params: RequestUrgentDeliveryListApiDownload) {
    return `/hydra/v1/request-priority-delivery/download?${params.toQueryString()}`;
  }
  getUrgentDeliveryDetail(id: number) {
    return `/hydra/v1/request-priority-delivery/${id}`;
  }
}
