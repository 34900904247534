import { Pagination } from "@/domain/entities/Pagination";

export class PickupManifestEntities {
  pagination: Pagination;
  data: PickupManifestData[];

  constructor(pagination: Pagination, data: PickupManifestData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class PickupManifestData {
  pickupManifestId: number;
  pickupManifestPartnerName: string;
  pickupManifestDriverName: string;
  pickupManifestVehiclePoliceNumber: string;
  pickupManifestTotalStt: number;
  pickupManifestTotalPieces: number;
  pickupManifestTotalGrossWeight: number;
  pickupManifestTotalVolumeWeight: number;
  pickupManifestDestinationPartnerName: string;
  pickupManifestDestinationVendorCode: string;
  pickupManifestPickupAt: string;
  pickupManifestCreatedAt: string;
  pickupManifestUpdatedAt: string;
  pickupManifestDriverPhone: string;
  pickupManifestCreatedName: string;
  pickupManifestCode?: string;
  constructor(
    pickupManifestId: number,
    pickupManifestPartnerName: string,
    pickupManifestDriverName: string,
    pickupManifestVehiclePoliceNumber: string,
    pickupManifestTotalStt: number,
    pickupManifestTotalPieces: number,
    pickupManifestTotalGrossWeight: number,
    pickupManifestTotalVolumeWeight: number,
    pickupManifestDestinationPartnerName: string,
    pickupManifestDestinationVendorCode: string,
    pickupManifestPickupAt: string,
    pickupManifestCreatedAt: string,
    pickupManifestUpdatedAt: string,
    pickupManifestDriverPhone: string,
    pickupManifestCreatedName: string,
    pickupManifestCode?: string
  ) {
    this.pickupManifestId = pickupManifestId;
    this.pickupManifestPartnerName = pickupManifestPartnerName;
    this.pickupManifestDriverName = pickupManifestDriverName;
    this.pickupManifestVehiclePoliceNumber = pickupManifestVehiclePoliceNumber;
    this.pickupManifestTotalStt = pickupManifestTotalStt;
    this.pickupManifestTotalPieces = pickupManifestTotalPieces;
    this.pickupManifestTotalGrossWeight = pickupManifestTotalGrossWeight;
    this.pickupManifestTotalVolumeWeight = pickupManifestTotalVolumeWeight;
    this.pickupManifestDestinationPartnerName = pickupManifestDestinationPartnerName;
    (this.pickupManifestDestinationVendorCode = pickupManifestDestinationVendorCode),
      (this.pickupManifestPickupAt = pickupManifestPickupAt);
    this.pickupManifestCreatedAt = pickupManifestCreatedAt;
    this.pickupManifestUpdatedAt = pickupManifestUpdatedAt;
    this.pickupManifestDriverPhone = pickupManifestDriverPhone;
    this.pickupManifestCreatedName = pickupManifestCreatedName;
    this.pickupManifestCode = pickupManifestCode;
  }
}
export class STTToBePickup {
  sttNumber: string;
  sttPieces: STTPiecesDetail[];
  product: string;
  totalPiece: string;
  grossWeight: number;
  volumeWeight: number;
  createdAt: string;
  origin: string;
  destination: string;
  sttNumberElexys?: string;
  sttRefNo?: string;

  constructor(
    sttNumber: string,
    sttPieces: STTPiecesDetail[],
    product: string,
    totalPiece: string,
    grossWeight: number,
    volumeWeight: number,
    createdAt: string,
    origin: string,
    destination: string,
    sttNumberElexys?: string,
    sttRefNo?: string
  ) {
    this.sttNumber = sttNumber;
    this.sttPieces = sttPieces;
    this.sttNumberElexys = sttNumberElexys;
    this.product = product;
    this.totalPiece = totalPiece;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.createdAt = createdAt;
    this.origin = origin;
    this.destination = destination;
    this.sttRefNo = sttRefNo;
  }
}

export class STTPiecesDetail {
  sttNumber: string;
  sttNumberPieces: string;
  destinationCity: string;
  product: string;
  woodPacking: string;
  commodity: string;
  codAmount: number;
  piecesNumber: string;
  grossWeight: number;
  volumeWeight: number;
  piece: number;
  ppl?: string;
  constructor(
    sttNumber: string,
    sttNumberPieces: string,
    destinationCity: string,
    product: string,
    woodPacking: string,
    commodity: string,
    cod: number,
    piecesNumber: string,
    grossWeight: number,
    volumeWeight: number,
    piece: number,
    ppl?: string
  ) {
    this.sttNumber = sttNumber;
    this.sttNumberPieces = sttNumberPieces;
    this.destinationCity = destinationCity;
    this.product = product;
    this.woodPacking = woodPacking;
    this.commodity = commodity;
    this.codAmount = cod;
    this.piecesNumber = piecesNumber;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.piece = piece;
    this.ppl = ppl;
  }
}

export class Drivers {
  driverId: number;
  driverName: string;
  driverPhoneNumber: string;
  driverInfo: string;

  constructor(
    driverId: number,
    driverName: string,
    driverPhoneNumber: string,
    driverInfo: string
  ) {
    this.driverId = driverId;
    this.driverName = driverName;
    this.driverPhoneNumber = driverPhoneNumber;
    this.driverInfo = driverInfo;
  }
}

export class PrintDataList {
  pickupManifestId: number;
  pickupManifestPartnerId: number;
  pickupManifestPartnerCode: string;
  pickupManifestPartnerName: string;
  pickupManifestOriginCityCode: string;
  pickupManifestOriginCityName: string;
  pickupManifestDriverId: string | number;
  pickupManifestDriverName: string;
  pickupManifestVehicleId: number;
  pickupManifestVehiclePoliceNumber: string;
  pickupManifestTotalStt: number;
  pickupManifestTotalPiece: number;
  pickupManifestTotalGrossWeight: number;
  pickupManifestTotalVolumeWeight: number;
  pickupManifestDestinationPartnerId: number;
  pickupManifestDestinationPartnerCode: string;
  pickupManifestDestinationPartnerName: string;
  pickupManifestVendorCode: string;
  pickupManifestProductType: string;
  pickupManifestPickupAt: string;
  pickupManifestCreatedBy: number;
  pickupManifestCreatedName: string;
  pickupManifestCreatedAt: string;
  pickupManifestDetail: Array<any>;
  pickupManifestDriverPhone: string;
  pickupManifestCode?: string;

  constructor(
    pickupManifestId: number,
    pickupManifestPartnerId: number,
    pickupManifestPartnerCode: string,
    pickupManifestPartnerName: string,
    pickupManifestOriginCityCode: string,
    pickupManifestOriginCityName: string,
    pickupManifestDriverId: string,
    pickupManifestDriverName: string,
    pickupManifestVehicleId: number,
    pickupManifestVehiclePoliceNumber: string,
    pickupManifestTotalStt: number,
    pickupManifestTotalPiece: number,
    pickupManifestTotalGrossWeight: number,
    pickupManifestTotalVolumeWeight: number,
    pickupManifestDestinationPartnerId: number,
    pickupManifestDestinationPartnerCode: string,
    pickupManifestDestinationPartnerName: string,
    pickupManifestVendorCode: string,
    pickupManifestProductType: string,
    pickupManifestPickupAt: string,
    pickupManifestCreatedBy: number,
    pickupManifestCreatedName: string,
    pickupManifestCreatedAt: string,
    pickupManifestDetail: Array<any>,
    pickupManifestDriverPhone: string,
    pickupManifestCode?: string
  ) {
    this.pickupManifestId = pickupManifestId;
    this.pickupManifestPartnerId = pickupManifestPartnerId;
    this.pickupManifestPartnerCode = pickupManifestPartnerCode;
    this.pickupManifestPartnerName = pickupManifestPartnerName;
    this.pickupManifestOriginCityCode = pickupManifestOriginCityCode;
    this.pickupManifestOriginCityName = pickupManifestOriginCityName;
    this.pickupManifestDriverId = pickupManifestDriverId;
    this.pickupManifestDriverName = pickupManifestDriverName;
    this.pickupManifestVehicleId = pickupManifestVehicleId;
    this.pickupManifestVehiclePoliceNumber = pickupManifestVehiclePoliceNumber;
    this.pickupManifestTotalStt = pickupManifestTotalStt;
    this.pickupManifestTotalPiece = pickupManifestTotalPiece;
    this.pickupManifestTotalGrossWeight = pickupManifestTotalGrossWeight;
    this.pickupManifestTotalVolumeWeight = pickupManifestTotalVolumeWeight;
    this.pickupManifestDestinationPartnerId = pickupManifestDestinationPartnerId;
    this.pickupManifestDestinationPartnerCode = pickupManifestDestinationPartnerCode;
    this.pickupManifestDestinationPartnerName = pickupManifestDestinationPartnerName;
    this.pickupManifestVendorCode = pickupManifestVendorCode;
    this.pickupManifestProductType = pickupManifestProductType;
    this.pickupManifestPickupAt = pickupManifestPickupAt;
    this.pickupManifestCreatedBy = pickupManifestCreatedBy;
    this.pickupManifestCreatedName = pickupManifestCreatedName;
    this.pickupManifestCreatedAt = pickupManifestCreatedAt;
    this.pickupManifestDetail = pickupManifestDetail;
    this.pickupManifestDriverPhone = pickupManifestDriverPhone;
    this.pickupManifestCode = pickupManifestCode;
  }
}

export class DetailSTT {
  isAllowUpdateStatus: boolean;
  errorMessage: string;
  sttNumber: string;
  piece: STTPiecesDetail[];
  product: string;
  totalPiece: number;
  grossWeight: number;
  volumeWeight: number;
  createdAt: string;
  origin: string;
  destination: string;
  sttNumberElexys?: string;
  isPaid?: boolean;

  constructor(
    sttNumber: string,
    isAllowUpdateStatus: boolean,
    errorMessage: string,
    piece: STTPiecesDetail[],
    product: string,
    totalPiece: number,
    grossWeight: number,
    volumeWeight: number,
    createdAt: string,
    origin: string,
    destination: string,
    sttNumberElexys?: string,
    isPaid?: boolean
  ) {
    this.sttNumber = sttNumber;
    this.isAllowUpdateStatus = isAllowUpdateStatus;
    this.errorMessage = errorMessage;
    this.piece = piece;
    this.sttNumberElexys = sttNumberElexys;
    this.product = product;
    this.totalPiece = totalPiece;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.createdAt = createdAt;
    this.origin = origin;
    this.destination = destination;
    this.isPaid = isPaid;
  }
}

export class SttListChecklistEntities {
  pagination: Pagination;
  data: SttListChecklistData[];

  constructor(pagination: Pagination, data: SttListChecklistData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class SttListChecklistData {
  sttNumber: string;
  sttNumberElexys: string;
  productType: string;
  totalPieces: number;
  grossWeight: number;
  volumeWeight: number;
  chargeableWeight: number;
  createdAt: string;
  sttNoRefExternal: string;
  constructor(
    sttNumber: string,
    sttNumberElexys: string,
    productType: string,
    totalPieces: number,
    grossWeight: number,
    volumeWeight: number,
    chargeableWeight: number,
    createdAt: string,
    sttNoRefExternal: string
  ) {
    this.sttNumber = sttNumber;
    this.sttNumberElexys = sttNumberElexys;
    this.productType = productType;
    this.totalPieces = totalPieces;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.chargeableWeight = chargeableWeight;
    this.createdAt = createdAt;
    this.sttNoRefExternal = sttNoRefExternal;
  }
}
