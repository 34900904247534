import { RequestRebuttalDex } from "@/data/payload/api/UniversalRequests";
import { ValidateRebuttalDexRequest } from "@/data/payload/contracts/UniversalRequest";
import { UniversalMapper } from "@/data/persistences/mappers/UniversalMapper";
import { UniversalRepositoryInterface } from "@/data/persistences/repositories/contracts/UniversalRepositoryInterface";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class UniversalApiRepository implements UniversalRepositoryInterface {
  private service: ApiService;
  private mapper: UniversalMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: UniversalMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async validateRebuttalDex(
    payload: ValidateRebuttalDexRequest
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.validateRebuttalDex(),
      undefined,
      payload as RequestRebuttalDex
    );
    return this.mapper.convertPayloadRebuttalDexData(resp);
  }
}
