import { MESSAGE_CONFIG } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

const MessagingServiceConfigDetail = () =>
  import(
    /* webpackChunkName: "MessagingServiceConfig" */ "@/app/ui/views/messaging-service-configuration/messaging-service-configuration-detail/index.vue"
  );
const MessagingServiceConfigForm = () =>
  import(
    /* webpackChunkName: "MessagingServiceConfig" */ "@/app/ui/views/messaging-service-configuration/messaging-service-configuration-form/index.vue"
  );

export default [
  {
    path: "/admin/messaging-service-configuration",
    component: Layout,
    name: "messaging-service-configuration-detail",
    meta: {
      hidden: true,
      parent: "Konfigurasi Pesan",
      permission: MESSAGE_CONFIG.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "messaging-service-configuration-detail",
        component: MessagingServiceConfigDetail,
        meta: {
          header: true,
          title: "Konfigurasi Pesan | Detail"
        }
      }
    ]
  },
  {
    path: "/admin/messaging-service-configuration",
    component: Layout,
    name: "messaging-service-configuration-form-add",
    meta: {
      hidden: true,
      parent: "Konfigurasi Pesan",
      permission: MESSAGE_CONFIG.CREATE
    },
    children: [
      {
        path: "add",
        name: "messaging-service-configuration-add",
        component: MessagingServiceConfigForm,
        meta: {
          header: true,
          title: "Konfigurasi Pesan | Add",
          name: "Buat Pesan",
          formType: "add",
          before: "/admin/messaging-service-configuration"
        }
      }
    ]
  },
  {
    path: "/admin/messaging-service-configuration",
    component: Layout,
    name: "messaging-service-configuration-form-edit",
    meta: {
      hidden: true,
      parent: "Konfigurasi Pesan",
      permission: MESSAGE_CONFIG.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "messaging-service-configuration-edit",
        component: MessagingServiceConfigForm,
        meta: {
          header: true,
          title: "Konfigurasi Pesan | Edit",
          name: "Ubah Message",
          formType: "edit",
          before: "/admin/messaging-service-configuration/:id"
        }
      }
    ]
  }
];
