import { injectable } from "tsyringe";
import { AdjustmentPenaltyData } from "@/domain/entities/AdjustmentPenalty";
import { AdjustmentPenaltyApiRepository } from "@/app/infrastructures/repositories/api/AdjustmentPenaltyApiRepository";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { UpdateAdjustmentPenaltyRequestInterface } from "@/data/payload/contracts/AdjustmentPenaltyRequest";

@injectable()
export class AdjustmentPenaltyPresenter {
  private repository: AdjustmentPenaltyApiRepository;
  constructor(repository: AdjustmentPenaltyApiRepository) {
    this.repository = repository;
  }

  public getDetailAdjustmentPenalty(
    id: number
  ): Promise<AdjustmentPenaltyData> {
    return this.repository.getDetailAdjustmentPenalty(id);
  }

  public updateAdjustmentPenalty(
    payload: UpdateAdjustmentPenaltyRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.updateAdjustmentPenalty(payload);
  }
}
