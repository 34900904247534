import { injectable } from "tsyringe";
import { ProgressiveCommissionConfigurationApiRepository } from "@/app/infrastructures/repositories/api/ProgressiveCommissionConfigurationApiRepository";
import {
  ProgressiveCommissionList,
  ProgressiveCommissionDetail
} from "@/domain/entities/ProgressiveCommissionConfiguration";
import { ProgressiveCommissionListRequest } from "@/data/payload/api/ProgressiveCommissionConfigurationApiRequest";
import { ProgressiveCommissionConfigurationRequest } from "@/data/payload/contracts/ProgressiveCommissionConfigurationRequest";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";

@injectable()
export class ProgressiveCommissionConfigurationPresenter {
  private repository: ProgressiveCommissionConfigurationApiRepository;

  constructor(repo: ProgressiveCommissionConfigurationApiRepository) {
    this.repository = repo;
  }

  getProgressiveCommissionList(
    params: ProgressiveCommissionListRequest
  ): Promise<ProgressiveCommissionList> {
    return this.repository.getProgressiveCommissionList(params);
  }

  addProgressiveCommissionConfiguration(
    payload: ProgressiveCommissionConfigurationRequest
  ): Promise<ResponsePayloadV2> {
    return this.repository.addProgressiveCommissionConfiguration(payload);
  }

  editProgressiveCommissionConfiguration(
    payload: ProgressiveCommissionConfigurationRequest
  ): Promise<ResponsePayloadV2> {
    return this.repository.editProgressiveCommissionConfiguration(payload);
  }

  getProgressiveCommissionDetail(
    id: number
  ): Promise<ProgressiveCommissionDetail> {
    return this.repository.getProgressiveCommissionDetail(id);
  }
}
