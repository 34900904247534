import { AxiosResponse } from "axios";
import {
  HeavyWeightSurchargeAdditionalCost,
  HeavyWeightSurchargeData
} from "@/domain/entities/HeavyWeightSurcharge";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class HeavyWeightSurchargeMapper {
  public convertDetailHeavyWeightSurchargeFromApi(
    res: AxiosResponse<any>
  ): HeavyWeightSurchargeData {
    const { data } = res;
    const isBigpack =
      data.data.configurable_heavy_weight.heavy_weight_surcharge_product_include.product_types.indexOf(
        "BIGPACK"
      ) > -1;
    return new HeavyWeightSurchargeData({
      id: data.data.configurable_price_id,
      configurablePriceName: isBigpack
        ? data.data.configurable_price_name + " Bigpack"
        : data.data.configurable_price_name,
      configurablePriceType: data.data.configurable_price_type,
      configurablePriceDescription: data.data.configurable_price_description,
      configurablePriceStatus: data.data.configurable_price_status,
      configurablePriceCreatedAt: data.data.configurable_price_created_at,
      configurablePriceCreatedBy: data.data.configurable_price_created_by,
      configurablePriceUpdatedAt: data.data.configurable_price_updated_at,
      configurablePriceUpdatedBy: data.data.configurable_price_updated_by,
      configurableHeavyWeight: data.data.configurable_heavy_weight,
      heavyweightSurchargeType:
        data.data.configurable_heavy_weight.heavy_weight_surcharge_type,
      heavyweightSurchargeAdditionalCost: data.data.configurable_heavy_weight
        .heavy_weight_surcharge_additional_cost.additional_costs.length
        ? data.data.configurable_heavy_weight.heavy_weight_surcharge_additional_cost.additional_costs.map(
            (item: any) =>
              new HeavyWeightSurchargeAdditionalCost({
                id: item.id,
                weightMax: item.weight_max,
                weightMin: item.weight_min,
                additionalCostFlat: item.additional_costs_flat,
                additionalCostPercentage: item.additional_costs_percentage,
                isError: false
              })
          )
        : []
    });
  }

  public convertPayloadData(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
