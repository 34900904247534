import { CashbackConfigurationPresenter } from "@/app/ui/presenters/CashbackConfigurationPresenter";
import { CashbackConfigurationMapper } from "@/data/persistences/mappers/CashbackConfigMapper";
import { container } from "tsyringe";
import { Endpoints } from "../../misc/Endpoints";
import { CashbackConfigurationApiRepository } from "../../repositories/api/CashbackConfigurationApiRepository";
import ApiService from "../../services/ApiService";

export class CashbackConfigurationComponent {
  public static init() {
    container.register<CashbackConfigurationApiRepository>(
      CashbackConfigurationApiRepository,
      {
        useFactory: (d) => {
          return new CashbackConfigurationApiRepository(
            d.resolve(ApiService),
            d.resolve(CashbackConfigurationMapper),
            d.resolve(Endpoints)
          );
        },
      }
    );
    container.register<CashbackConfigurationMapper>(
      CashbackConfigurationMapper,
      {
        useClass: CashbackConfigurationMapper,
      }
    );
    container.register<CashbackConfigurationPresenter>(
      CashbackConfigurationPresenter,
      {
        useFactory: (d) => {
          return new CashbackConfigurationPresenter(
            d.resolve(CashbackConfigurationApiRepository)
          );
        },
      }
    );
  }
}
