import { PosFavoriteApiRepository } from "@/app/infrastructures/repositories/api/PosFavoriteApiRepository";
import { injectable } from "tsyringe";
import {
  EditMemberDiscountRequestInterface,
  EditDefaultDiscountRequestInterface
} from "@/data/payload/contracts/PosFavoriteRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  PosFavoriteSubscriberEntities,
  PosFavoriteSubscriberOutstandingEntities,
  PosFavouriteDefaultDiscount
} from "@/domain/entities/PosFavorite";

@injectable()
export class PosFavoritePresenter {
  private repository: PosFavoriteApiRepository;

  constructor(repository: PosFavoriteApiRepository) {
    this.repository = repository;
  }

  public getListPosFavoriteSubscriber(
    agentCode: string,
    phoneNumber: string,
    page: number,
    limit: number
  ): Promise<PosFavoriteSubscriberEntities> {
    return this.repository.getListPosFavoriteSubscriber(
      agentCode,
      phoneNumber,
      page,
      limit
    );
  }

  public editMemberDiscount(
    payload: EditMemberDiscountRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editMemberDiscount(payload);
  }

  public getListPosFavoriteSubscriberOutstanding(
    agentCode: string,
    phoneNumber: string,
    page: number,
    limit: number,
    status: string
  ): Promise<PosFavoriteSubscriberOutstandingEntities> {
    return this.repository.getListPosFavoriteSubscriberOutstanding(
      agentCode,
      phoneNumber,
      page,
      limit,
      status
    );
  }

  public editDefaultDiscount(
    payload: EditDefaultDiscountRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editDefaultDiscount(payload);
  }

  public getDetailDefaultDiscount(
    agentCode: string
  ): Promise<PosFavouriteDefaultDiscount> {
    return this.repository.getDetailDefaultDiscount(agentCode);
  }
}
