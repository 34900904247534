import { SaldoPoinApiRepository } from "@/app/infrastructures/repositories/api/SaldoPoinApiRepository";
import { RequestHistoryPoinSaldoList } from "@/data/payload/api/SaldoPoinApiRequest";
import {
  HistoryPoinEntities,
  HistorySaldoEntities
} from "@/domain/entities/SaldoPoin";
import { injectable } from "tsyringe";

@injectable()
export class SaldoPoinPresenter {
  private repo: SaldoPoinApiRepository;

  constructor(repo: SaldoPoinApiRepository) {
    this.repo = repo;
  }
  public getHistoryPoinList(
    params: RequestHistoryPoinSaldoList
  ): Promise<HistoryPoinEntities> {
    return this.repo.getListHistoryPoint(params);
  }

  public getHistorySaldoList(
    params: RequestHistoryPoinSaldoList
  ): Promise<HistorySaldoEntities> {
    return this.repo.getListHistorySaldo(params);
  }
}
