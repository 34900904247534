import { Pagination } from "./Pagination";
import { OptionsClass } from "@/domain/entities/MainApp";
export class CommoditySurchargeList {
  pagination: Pagination;
  data: CommoditySurcharge[];
  constructor(pagination: Pagination, data: CommoditySurcharge[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class CommoditySurcharge {
  id: number;
  commodityId: number;
  commodityName: string;
  type: string;
  amount: number;
  quantifier: string;
  quantifierDefault: string;
  originCityCode: string;
  destinationCityCode: string;
  originCityName: string;
  destinationCityName: string;
  status: string;
  startedAt: string;
  expiredAt: any;
  tierCalculation: TierCalculation[];
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  constructor(
    id: number,
    commodityId: number,
    commodityName: string,
    type: string,
    amount: number,
    quantifier: string,
    quantifierDefault: string,
    originCityCode: string,
    destinationCityCode: string,
    originCityName: string,
    destinationCityName: string,
    status: string,
    startedAt: string,
    expiredAt: any,
    tierCalculation: TierCalculation[],
    createdBy?: string,
    updatedBy?: string,
    createdAt?: string,
    updatedAt?: string
  ) {
    this.id = id;
    this.commodityId = commodityId;
    this.commodityName = commodityName;
    this.type = type;
    this.amount = amount;
    this.quantifier = quantifier;
    this.quantifierDefault = quantifierDefault;
    this.originCityCode = originCityCode;
    this.destinationCityCode = destinationCityCode;
    this.originCityName = originCityName;
    this.destinationCityName = destinationCityName;
    this.status = status;
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.startedAt = startedAt;
    this.expiredAt = expiredAt;
    this.tierCalculation = tierCalculation;
  }
}

export class TierCalculation {
  tier = 0;
  tierStart = "";
  tierStartError = "";
  tierStartMaxLength = "";
  tierEnd = "";
  tierEndError = "";
  tierEndMaxLength = "";
  tierAmount = "";
  tierAmountError = "";
  tierAmountMaxLength = "";

  constructor(fields?: Partial<TierCalculation>) {
    Object.assign(this, fields);
  }
}

export class FormSurcharge {
  id = 0;
  commodity: any = ""
  origin: any = ""
  destination: any = ""
  periodeStart = new Date()
  periodeEnd = new Date()
  periodeStartEdit = new Date
  pastStartDate = new Date
  periodeEndEdit = new Date
  pastEndDate = new Date
  type = ""
  quantifierDefault = ""
  quantifier: any[] = []
  tiers = [new TierCalculation]
  status = false

  constructor(fields?: Partial<FormSurcharge>) {
    Object.assign(this, fields);
  }
}
