import { EditBaseRatesApiRequest } from "@/data/payload/api/BaseRatesApiRequest";
import { ChangeBaseRatesRequestInterface } from "@/data/payload/contracts/BaseRatesRequest";
import { BaseRatesMapper } from "@/data/persistences/mappers/BaseRatesMapper";
import { BaseRatesRepositoryInterface } from "@/data/persistences/repositories/contracts/BaseRatesRepositoryInterface";
import { BaseRatesEntities, RatesDetail } from "@/domain/entities/BaseRates";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class BaseRatesApiRepository implements BaseRatesRepositoryInterface {
  private service: ApiService;
  private mapper: BaseRatesMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: BaseRatesMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getBaseRatesList(
    search: string,
    status: string,
    page: number,
    limit: number
  ): Promise<BaseRatesEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListBaseRates(search, status, page, limit),
      {}
    );
    return this.mapper.convertBaseRatesDataFromApi(resp);
  }

  public async getRatesList(search: string): Promise<BaseRatesEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListRates(search),
      {}
    );
    return this.mapper.convertBaseRatesDataFromApi(resp);
  }

  public async getDetail(id: number): Promise<RatesDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailRates(id),
      {}
    );
    return this.mapper.convertDetailRatesDataFromApi(resp);
  }

  public async editBaseRates(
    id: number,
    payload: ChangeBaseRatesRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invokePrivate(
      "put",
      this.endpoints.editBaseRates(id),
      {},
      payload as EditBaseRatesApiRequest
    );
    return this.mapper.convertChangeDataFromApi(response);
  }
}
