import { ExchangeRateDataMapper } from "@/data/persistences/mappers/ExchangeRateMapper";
import { ExchangeRateRepositoryInterface } from "@/data/persistences/repositories/contracts/ExchangeRateRepositoryInterface";
import {
  ExchangeRateEntities,
  ExchangeRateData
} from "@/domain/entities/ExchangeRate";
import {
  AddExchangeRateRequestInterface,
  EditExchangeRateRequestInterface
} from "@/data/payload/contracts/ExchangeRateRequest";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  AddExchangeRateApiRequest,
  EditExchangeRateApiRequest
} from "@/data/payload/api/ExchangeRateApiRequest";

export class ExchangeRateApiRepository
  implements ExchangeRateRepositoryInterface {
  private service: ApiService;
  private mapper: ExchangeRateDataMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: ExchangeRateDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getList(
    from: string,
    to: string,
    status: string,
    page: number,
    limit: number
  ): Promise<ExchangeRateEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListExchangeRate(from, to, status, page, limit),
      {}
    );
    return this.mapper.convertExchangeRateFromApi(resp);
  }

  public async getDetailExchange(id: number): Promise<ExchangeRateData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailExchangeRate(id),
      {}
    );
    return this.mapper.convertDetailExchangeRateFromApi(resp);
  }

  public async addNewExchangeRate(
    payload: AddExchangeRateRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivate(
      "POST",
      this.endpoints.addNewExchangeRate(),
      undefined,
      payload as AddExchangeRateApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async editExchangeRate(
    payload: EditExchangeRateRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivate(
      "PUT",
      this.endpoints.addNewExchangeRate(),
      undefined,
      payload as EditExchangeRateApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }
}
