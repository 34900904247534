/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionCourierManagement = {
    permission_courier_management_enable: new Rox.Flag(false),
    permission_courier_management_detail_view: new Rox.Flag(false),
    permission_courier_management_detail_edit: new Rox.Flag(false),
    permission_courier_management_create: new Rox.Flag(false),
    permission_courier_management_approval: new Rox.Flag(false),

};

export function initFlagsPermissionCourierManagement() {
  // register flags with namespaces for better grouping
  Rox.register("featureCourierManagement", FlagsPermissionCourierManagement);
}
