import { configDates } from "@/app/infrastructures/misc/common-library/modules/Date";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";

export class PickupCorporateAddress {
    pcaId = 0;
    pcaName = "";
    pcaLatlon = "";
    pcaIsDefault = false;
    pcaPhoneNumber = "";
    pcaAddress = ""
    pcaPicName = ""
    pcaPostalCode = ""
    pcaDistrictId = 0
    pcaDistrictName = ""

    constructor(fields?: Partial<PickupCorporateAddress>) {
        Object.assign(this, fields);
    }
}

export class ListPickupDetail {
    the3LcPickup = "";
    actualTotalKoli = 0;
    actualTotalShipment = 0;
    clientId = "";
    clientParentId = "";
    clientRef = "";
    courierName = "";
    courierNote = "";
    courierPhone = "";
    cancelNote = "";
    createdAt = "";
    createdBy = "";
    dropOffProof: string[] = [];
    estimateTotalKoli = 0;
    estimateTotalShipment = 0;
    estimateTotalTonase = 0;
    actualTotalTonase = 0;
    geoloc = "";
    latestStatus = "";
    listStt: ListStt[] = [];
    origin = "";
    pickupAddress = "";
    pickupDate = "";
    pickupProof: string[] = [];
    pickupTime = "";
    pickupType = "";
    plateNo = "";
    serviceCode = "";
    shipmentId = "";
    transportType = "";
    updatedAt = "";
    updatedBy = "";
    timezone = ""
    pickupCorporateAddress = new PickupCorporateAddress()

    constructor(fields?: Partial<ListPickupDetail>) {
        Object.assign(this, fields);
    }

    get formattedPickupTime() {
        if (this.pickupTime === '') {
            return '';
        }
        return this.pickupTime + " " + configDates(this.timezone).id;
    }

    get formatCreatedAt() {
        return formatDate(this.createdAt);
    }

    get formatUpdatedAt() {
        return formatDate(this.updatedAt);
    }

    get getPlatNo() {
        if (this.latestStatus.toUpperCase() === 'CRRNFD') {
            return '-';
        }
        return this.plateNo;
    }

    get lastStatusGenesis() {
        switch (this.latestStatus.toUpperCase()) {
            case 'CRRNFD':
                return 'Mencari Kurir';
            case 'CRROTW':
                return 'Kurir Ditugaskan';
            case 'CRRPIC':
                return 'Paket di Pick Up';
            case 'CRRDON':
                return 'Selesai';
            case 'CRRCNC':
                return 'Dibatalkan Kurir';
            case 'CSTCNC':
                return 'Dibatalkan Klien';
            case 'ADMCNC':
                return 'Dibatalkan Admin';
            default:
                return this.latestStatus.toUpperCase();
        }
    }

    get lastStatusColor() {
        switch (this.latestStatus.toUpperCase()) {
            case 'CRRNFD':
                return 'yellow';
            case 'CRROTW':
                return 'aqua';
            case 'CRRPIC':
                return 'blue';
            case 'CRRDON':
                return 'green';
            case 'CRRCNC':
            case 'CSTCNC':
            case 'ADMCNC':
                return 'red';
            default:
                return "gray";
        }
    }

    get pickupInProgress() {
        return (this.latestStatus.toUpperCase() == 'CRROTW' || this.latestStatus.toUpperCase() == 'CRRPIC');
    }

    get isCancelled() {
        switch (this.latestStatus.toUpperCase()) {
            case 'CRRCNC':
            case 'CSTCNC':
            case 'ADMCNC':
                return true;
            default:
                return false;
        }
    }

    get isCancelable() {
        return this.latestStatus.toUpperCase() == 'CRRNFD'
    }

    get shouldCourierDataShown() {
        switch (this.latestStatus.toUpperCase()) {
            case 'CRROTW':
            case 'CRRPIC':
            case 'CRRDON':
                return true;
            default:
                return false;
        }
    }

    get shouldPickupProofImageShown() {
        switch (this.latestStatus.toUpperCase()) {
            case 'CRRPIC':
            case 'CRRDON':
                return true;
            default:
                return false;
        }
    }

    get isShipmentDone() {
        return this.latestStatus.toUpperCase() == 'CRRDON'
    }
    

    get isPcaEmpty() {
        return !this.pickupCorporateAddress?.pcaId
    }
}

export class ListStt {
    bookingDate = "";
    grossWeight = 0;
    serviceCode = "";
    sttNo = "";
    totalKoli = 0;

    constructor(fields?: Partial<ListStt>) {
        Object.assign(this, fields);
    }

    get formatedBookingDate() {
        return formatDate(this.bookingDate);
    }
}

export class CancelPickupApiRequest {
    shipmentId = "";

    constructor(fields?: Partial<CancelPickupApiRequest>) {
        Object.assign(this, fields);
    }

    toJSON(): string {
        return JSON.stringify([ConvertObjectCamelToSnakeCase({ ...this })]);
    }
}

export class CreatePickupSuccessResponse {
    shipmentId = '';

    constructor(fields?: Partial<CreatePickupSuccessResponse>) {
        Object.assign(this, fields);
    }
}
