import { BalanceLimitMapper } from "@/data/persistences/mappers/BalanceLimitMapper";
import { BalanceLimitRepositoryInterface } from "@/data/persistences/repositories/contracts/BalanceLimitRepositoryInterface";
import {
  BalanceEntities,
  BalanceDetailData
} from "@/domain/entities/BalanceLimit";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { EditBalanceRequestInterface } from "@/data/payload/contracts/BalanceLimitRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { EditBalanceApiRequest } from "@/data/payload/api/BalanceLimitRequest";

export class BalanceLimitApiRepository
  implements BalanceLimitRepositoryInterface {
  private service: ApiService;
  private mapper: BalanceLimitMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: BalanceLimitMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getBalanceList(
    page: number,
    limit: number
  ): Promise<BalanceEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListBalanceLimit(page, limit),
      {}
    );
    return this.mapper.convertBalanceDataFromApi(resp);
  }

  public async getDetailBalance(id: number): Promise<BalanceDetailData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailBalanceLimit(id),
      {}
    );
    return this.mapper.convertDetailBalanceDataFromApi(resp);
  }

  public async editBalance(
    payload: EditBalanceRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivate(
      "put",
      this.endpoints.editBalanceLimit(),
      undefined,
      payload as EditBalanceApiRequest
    );
    return this.mapper.convertPayloadData(resp);
  }
}
