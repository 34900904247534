import { PaymentRepositoryInterface } from "@/data/persistences/repositories/contracts/PaymentRepositoryInterface";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import {
  RequestListPaymentMethod,
  ValidatePayment
} from "@/data/payload/api/PaymentApiRequest";
import { PaymentMapper } from "@/data/persistences/mappers/PaymentMapper";
import { ValidatePaymentRequestInterface } from "@/data/payload/contracts/PaymentRequest";
import {
  CheckInquiry,
  PaymentMethod,
  PaymentResponses
} from "@/domain/entities/Payment";

export class PaymentApiRepository implements PaymentRepositoryInterface {
  private service: ApiService;
  private mapper: PaymentMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: PaymentMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async validatePayment(
    payload: ValidatePaymentRequestInterface
  ): Promise<PaymentResponses> {
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.validatePayment(),
      undefined,
      payload as ValidatePayment
    );
    return this.mapper.convertPostDataFromApi(resp);
  }

  public async checkInquiry(idTransaction: string): Promise<CheckInquiry> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.checkInquiry(idTransaction),
      {}
    );
    return this.mapper.convertCheckInquiryFromApi(resp);
  }

  public async getListPaymentMethod(
    params: RequestListPaymentMethod
  ): Promise<PaymentMethod[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getPaymentMethod(params),
      {}
    );
    return this.mapper.convertPaymentMethodDataFromApi(resp);
  }
}
