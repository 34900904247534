import { DetailForwardBookingCommission } from "@/domain/entities/ForwardBookingCommission";
import { ForwardBookingCommissionApiRepository } from "@/app/infrastructures/repositories/api/ForwardBookingCommissionApiRepository";
import { EditForwardBookingCommissionApiRequest } from "@/data/payload/api/ForwardBookingCommissionApiRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class ForwardBookingCommissionPresenter {
  private repository: ForwardBookingCommissionApiRepository;

  constructor(repo: ForwardBookingCommissionApiRepository) {
    this.repository = repo;
  }

  public async getDetailForwardBookingCommission(
    id: any
  ): Promise<DetailForwardBookingCommission> {
    return await this.repository.getDetailForwardBookingCommission(id);
  }

  public async editForwardBookingCommission(
    payload: EditForwardBookingCommissionApiRequest
  ): Promise<ResponsePayload> {
    return await this.repository.editForwardBookingCommission(payload);
  }
}
