import {
  PRICING_BASE_RATE,
  SHIPMENT_CEK_TARIF,
  PRICING_EXCHANGE_RATE,
  PRICING_CONFIGURABLE_PRICE,
  PRICING_COMMODITY_SURCHARGE,
  PRICING_GOODS_TAX,
  COMMISSION_CONFIG,
  DTPOL_CONFIG,
  PROMO_CONFIGURATION,
  COD_RETAIL_CONFIGURATION
} from "@/app/infrastructures/misc/RolePermission";

import BaseRateModules from "./base-rate";
import CommoditySurchargeModules from "./commodity-surcharge";
import ExchangeRateModules from "./exchange-rate";
import ConfigurablePriceModules from "./configurable-price";
import GoodsTaxModules from "./goods-tax";
import BookingCommissionModules from "./commission-configuration/booking-commission";
import BulkBookingCommissionModules from "./commission-configuration/bulk-booking-commission";
import DeliveryTieringPolicy from "./commission-configuration/delivery-tiering-policy";
import ProgressiveCommissionConfigurationModules from "../admin/progressive-commission-configuration";
import PromoConfigurationModules from "@/app/ui/router/modules/tariff/promo-configuration";
import CODRetailModules from "@/app/ui/router/modules/tariff/cod-retail";
import { FlagsPermissionShipmentRates } from "@/feature-flags/flags-permission-check-tariff";
const CommoditySurchage = () =>
  import(
    /* webpackChunkName: "CommoditySurcharge" */ "@/app/ui/views/pricing/commodity-surcharge/index.vue"
  );
const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const GoodsTax = () =>
  import(
    /* webpackChunkName: "GoodsTax" */ "@/app/ui/views/pricing/goods-tax/index.vue"
  );

// pages
const CekTarif = () =>
  import(
    /* webpackChunkName: "CekTarif" */ "@/app/ui/views/shipment/rates-check/index.vue"
  );
const BaseRateList = () =>
  import(
    /* webpackChunkName: "BaseRate" */ "@/app/ui/views/pricing/base-rate/index.vue"
  );
const ExchangeRate = () =>
  import(
    /* webpackChunkName: "ExchangeRate" */ "@/app/ui/views/pricing/exchange-rate/index.vue"
  );
const ConfigPrice = () =>
  import(
    /* webpackChunkName: "ConfigPrice" */ "@/app/ui/views/pricing/config-price/index.vue"
  );
const CommissionConfiguration = () =>
  import(
    /* webpackChunkName: "CommissionConfig" */ "@/app/ui/views/commission-configuration/index.vue"
  );
const BookingCommission = () =>
  import(
    /* webpackChunkName: "CommissionConfig" */ "@/app/ui/views/commission-configuration/booking-commission/index.vue"
  );
const BulkBookingCommission = () =>
  import(
    /* webpackChunkName: "CommissionConfig" */ "@/app/ui/views/commission-configuration/bulk-booking-commission/index.vue"
  );
const DeliveryTieringPolicyCommission = () =>
  import(
    /* webpackChunkName: "CommissionConfig" */ "@/app/ui/views/commission-configuration/delivery-tiering-policy/index.vue"
  );
const ProgressiveCommissionConfigurationList = () =>
  import(
    /* webpackChunkName: "ProgressiveCommissionConfig" */ "@/app/ui/views/progressive-commission-configuration/progressive-commission-configuration-list/index.vue"
  );
const PromoConfigurationList = () =>
  import(
    /* webpackChunkName: "PromoConfig" */ "@/app/ui/views/promo-configuration/promo-configuration-list/index.vue"
  );
const CODRetailConfigurationList = () =>
  import(
    /* webpackChunkName: "CODRetailConfig" */ "@/app/ui/views/pricing/cod-retail-configuration/index.vue"
  );

export default [
  {
    path: "/tarif",
    component: Layout,
    name: "tarif",
    meta: {
      icon: "pricing",
      children: true,
      title: "Tarif",
      name: "Tarif",
      gtmEventName: "tariff_menu",
      gtmEventCustoms: {},
      gtmEventOption: ["userId", "userType", "timestamp"],
      permission: [
        FlagsPermissionShipmentRates.permission_shipment_rates_enable,
        PRICING_BASE_RATE.ENABLE,
        PRICING_EXCHANGE_RATE.ENABLE,
        PRICING_CONFIGURABLE_PRICE.ENABLE,
        PRICING_COMMODITY_SURCHARGE.ENABLE,
        PRICING_GOODS_TAX.ENABLE,
        COMMISSION_CONFIG.ENABLE,
        DTPOL_CONFIG.ENABLE
      ]
    },
    children: [
      {
        path: "cek-tarif",
        name: "cek-tarif",
        component: CekTarif,
        meta: {
          header: true,
          icon: "rates-check",
          title: "Tarif - Cek Tarif",
          name: "Cek Tarif",
          gtmEventName: "check_tariff_submenu",
          gtmEventCustoms: {},
          gtmEventOption: ["userId", "userType", "timestamp"],
          isFreeze: true,
          permission: FlagsPermissionShipmentRates.permission_shipment_rates_enable
        }
      },
      {
        path: "base-rate",
        name: "base-rate",
        component: BaseRateList,
        meta: {
          header: true,
          icon: "base-rate",
          title: "Base Rate",
          name: "Base Rate",
          permission: PRICING_BASE_RATE.ENABLE
        }
      },
      {
        path: "exchange-rate",
        name: "exchange-rate",
        component: ExchangeRate,
        meta: {
          header: true,
          icon: "exchange-rate",
          title: "Konversi Mata Uang",
          name: "Konversi Mata Uang",
          permission: PRICING_EXCHANGE_RATE.ENABLE
        }
      },
      {
        path: "configurable-price",
        name: "configurable-price",
        component: ConfigPrice,
        meta: {
          header: true,
          icon: "configurable-price",
          title: "Configurable Price",
          name: "Configurable Price",
          permission: PRICING_CONFIGURABLE_PRICE.ENABLE
        }
      },
      {
        path: "surcharge",
        name: "commodity-surcharge",
        component: CommoditySurchage,
        meta: {
          header: true,
          icon: "commodity-surcharge",
          title: "Tarif Komoditas",
          name: "Tarif Komoditas",
          permission: PRICING_COMMODITY_SURCHARGE.ENABLE
        }
      },
      {
        path: "goods-tax",
        name: "goods-tax",
        component: GoodsTax,
        meta: {
          header: true,
          icon: "goods-tax",
          title: "Pajak Barang",
          name: "Pajak Barang",
          permission: PRICING_GOODS_TAX.ENABLE
        }
      },
      {
        path: "commission-configuration",
        name: "commission-configuration",
        redirect: "/tarif/commission-configuration/booking",
        component: CommissionConfiguration,
        meta: {
          header: true,
          isFreeze: true,
          icon: "commission-configuration",
          children: false,
          title: "Konfigurasi Komisi",
          name: "Konfigurasi Komisi",
          permission: [COMMISSION_CONFIG.ENABLE, DTPOL_CONFIG.ENABLE]
        },
        children: [
          {
            path: "booking",
            name: "commission-configuration-booking",
            component: BookingCommission
          },
          {
            path: "bulk-booking",
            name: "commission-configuration-bulk-booking",
            component: BulkBookingCommission
          },
          {
            path: "delivery-tiering-policy",
            name: "commission-configuration-delivery-tiering-policy",
            component: DeliveryTieringPolicyCommission
          },
          {
            path: "progressive-commisson",
            name: "commission-configuration-progressive-commisson",
            component: ProgressiveCommissionConfigurationList
          }
        ]
      },
      {
        path: "promo-configuration",
        name: "promo-configuration",
        component: PromoConfigurationList,
        meta: {
          header: true,
          icon: "promo-configuration",
          title: "Promo Diskon",
          name: "Promo Diskon",
          permission: PROMO_CONFIGURATION.ENABLE
        }
      },
      {
        path: "konfigurasi-cod-retail",
        name: "konfigurasi-cod-retail",
        component: CODRetailConfigurationList,
        meta: {
          header: true,
          icon: "cod",
          title: "Konfigurasi COD",
          name: "Konfigurasi COD",
          permission: COD_RETAIL_CONFIGURATION.ENABLE
        }
      }
    ]
  },
  ...BaseRateModules,
  ...ExchangeRateModules,
  ...ConfigurablePriceModules,
  ...CommoditySurchargeModules,
  ...GoodsTaxModules,
  ...BookingCommissionModules,
  ...BulkBookingCommissionModules,
  ...DeliveryTieringPolicy,
  ...ProgressiveCommissionConfigurationModules,
  ...PromoConfigurationModules,
  ...CODRetailModules
];
