import {
  AddDeliveryVendorApiRequest,
  EditVendorApiRequest
} from "@/data/payload/api/VendorApiRequest";
import {
  DeliveryVendorRequestInterface,
  EditVendorRequestInterface
} from "@/data/payload/contracts/VendorRequest";
import { VendorDataMapper } from "@/data/persistences/mappers/VendorDataMapper";
import { VendorRepositoryInterface } from "@/data/persistences/repositories/contracts/VendorRepositoryInterface";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  DeliveryVendor,
  VendorData,
  VendorEntities
} from "@/domain/entities/Vendor";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class VendorApiRepository implements VendorRepositoryInterface {
  private service: ApiService;
  private mapper: VendorDataMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: VendorDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async editUser(
    payload: EditVendorRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "put",
      this.endpoints.editVendor(),
      undefined,
      payload as EditVendorApiRequest
    );
    return this.mapper.convertPayloadData(resp);
  }

  public async getVendorList(
    search: string,
    status: string,
    page: number,
    limit: number
  ): Promise<VendorEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListVendor(search, status, page, limit),
      {}
    );
    return this.mapper.convertVendorDataFromApi(resp);
  }

  public async getDetailVendor(id: number): Promise<VendorData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailVendor(id),
      {}
    );
    return this.mapper.convertDetailVendorDataFromApi(resp);
  }

  public async getDeliveryVendorList(
    page: number,
    limit: number,
    search: string
  ): Promise<DeliveryVendor[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListDeliveryVendor(page, limit, search),
      {}
    );
    return this.mapper.convertDeliveryVendorDataFromApi(resp);
  }

  public async addDeliveryVendor(
    payload: DeliveryVendorRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.addDeliveryVendor(),
      undefined,
      payload as AddDeliveryVendorApiRequest
    );
    return this.mapper.convertPayloadData(resp);
  }
}
