import { Pagination } from "./Pagination";

export class MiddlewareData {
    id = 0;
    userName = "";
    clientBranchName = "";
    externalCodeClientBranch = "";
    clientParentCode = "";
    clientBranchCode = "";
    clientPhone = "";
    clientCity = "";
    clientCrossDock = "";
    token = "";

    constructor(fields?: Partial<MiddlewareData>) {
        Object.assign(this, fields);
    }
}

export class MiddlewareList {
    pagination = new Pagination(1, 20);
    data = [new MiddlewareData()];
  
    constructor(fields?: Partial<MiddlewareList>) {
      Object.assign(this, fields);
    }
}

export class MiddlewareDetail {
    id = 0;
    username = "";
    password = "";
    clientCode = "";
    clientCodeGenesis = "";
    clientId = "";
    company = "";
    webhook = "";
    webhookToken = "";
    city = "";
    clientPhone = "";
    isClientCrossdocking = false;
    client3lc = "";
    clientIdGenesis: string | number = "";
    createdAt = "";
    updatedAt = "";
    createdBy = "";
    updatedBy = "";

    constructor(fields?: Partial<MiddlewareDetail>) {
        Object.assign(this, fields);
    }
}
