import { FINANCE_BALANCE_LIMIT } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const BalanceDetail = () =>
  import(
    /* webpackChunkName: "BalanceLimit" */ "@/app/ui/views/finance/balance-limit/component/detail-balance.vue"
  );
const BalanceEdit = () =>
  import(
    /* webpackChunkName: "BalanceLimit" */ "@/app/ui/views/finance/balance-limit/component/edit.vue"
  );
export default [
  {
    path: "/finance/balance-limit",
    component: Layout,
    name: "detail-balance-limit-rules",
    meta: {
      hidden: true,
      parent: "Balance Limit Rules",
      permission: FINANCE_BALANCE_LIMIT.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-balance-limit-rules",
        component: BalanceDetail,
        meta: {
          title: "Balance Limit Rules | Detail",
          header: true
        }
      }
    ]
  },
  {
    path: "/finance/balance-limit",
    component: Layout,
    name: "edit-balance-limit-rules",
    meta: {
      icon: "balance",
      children: false,
      hidden: true,
      parent: "Balance Limit Rules",
      permission: FINANCE_BALANCE_LIMIT.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "edit-balance-limit-rules",
        component: BalanceEdit,
        meta: {
          header: true,
          title: "Balance Limit Rules | Edit",
          name: "Edit",
          before: "/finance/balance-limit/:id"
        }
      }
    ]
  }
];
