import { ConfigPriceWoodpackingDataMapper } from "@/data/persistences/mappers/ConfigWoodpackingMapper";
import { ConfigPriceWoodpackingRepositoryInterface } from "@/data/persistences/repositories/contracts/ConfigWoodpackingRepositoryInterface";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { WoodpackingData } from "@/domain/entities/ConfigWoodpacking";
import { EditConfigWoodpackingRequestInterface } from "@/data/payload/contracts/ConfigWoodpackingRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { EditConfigWoodpackingApiRequest } from "@/data/payload/api/ConfigWoodpackingApiRequest";

export class ConfigPriceWoodpackingApiRepository
  implements ConfigPriceWoodpackingRepositoryInterface {
  private service: ApiService;
  private mapper: ConfigPriceWoodpackingDataMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: ConfigPriceWoodpackingDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getDetail(id: number): Promise<WoodpackingData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailConfigWoodpacking(id),
      {}
    );
    return this.mapper.convertDetailConfigPriceWoodpackingFromApi(resp);
  }

  public async editConfig(
    payload: EditConfigWoodpackingRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivate(
      "put",
      this.endpoints.editConfigWoodpacking(),
      undefined,
      payload as EditConfigWoodpackingApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }
}
