import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import {
  BeneficiaryData,
  BeneficiaryEntities
} from "@/domain/entities/Beneficiary";
import { BeneficiaryPresenter } from "../presenters/BeneficiaryPresenter";
import { ValidateBankAccountApiRequest } from "@/data/payload/api/BeneficiaryApiRequest";
import { ClientRegistrationController } from "./ClientRegistrationController";
import { BankDetail } from "@/domain/entities/ClientRegistration";

export interface BeneficiaryAccountState {
  isLoading: boolean;
  isError: boolean;
  isErrorCause: string;
  openModalSuccess: boolean;
  beneficiaryList: BeneficiaryEntities;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "beneficiary-account"
})
class BeneficiaryAccountStateStore extends VuexModule
  implements BeneficiaryAccountState {
  public isLoading = false;
  public isError = false;
  public isErrorCause = "";
  public openModalSuccess = false;
  public beneficiaryAccountList: Array<any> = [];
  public beneficiaryList = new BeneficiaryEntities([]);
  public isLoadingValidationBank = false;
  public isErrorValidationBank = false;
  public errorCauseValidationBank = "";
  public beneficiaryDetail = new BeneficiaryData(
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0,
    "",
    "",
    "",
    false
  );

  @Action
  public getBeneficiaryAccList(params: {
    page: number;
    limit: number;
    bankAccType: string;
    actortype: string;
    actorId: any;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(BeneficiaryPresenter);
    presenter
      .getBeneficiaryAccList(
        params.page,
        params.limit,
        params.bankAccType,
        params.actortype,
        params.actorId
      )
      .then((res: BeneficiaryEntities) => {
        this.setBeneficiaryList(res);
        this.setError(false);
        this.setErrorCause("");
        if (res.data[0]) {
          this.setBeneficiaryDetail(res.data[0]);
          ClientRegistrationController.setBankDetail(
            new BankDetail({
              bankLabel: res.data[0].bankAccountLabel,
              bankName: res.data[0].bankName,
              accountNo: res.data[0].bankAccountNumber,
              accountName: res.data[0].bankAccountName,
              email: res.data[0].bankAccountEmail,
              logo: res.data[0].bankAccountLogo
            })
          );
        } else {
          this.setBeneficiaryDetail(
            new BeneficiaryData(
              0,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              0,
              "",
              "",
              "",
              false
            )
          );
        }
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
        this.setBeneficiaryDetail(
          new BeneficiaryData(
            0,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            0,
            "",
            "",
            "",
            false
          )
        );
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public createBeneficiaryAccount(params: {
    beneficiaryBankName: string;
    beneficiaryAccountName: string;
    beneficiaryAccountNumber: string;
  }) {
    this.pushBeneficiaryAccount(params);
  }

  @Action
  public deleteBeneficiaryAccount() {
    this.popBeneficiaryAccount();
  }

  @Action
  public validateBankAccount(params: ValidateBankAccountApiRequest) {
    this.setLoadingValidationBank(true);
    this.setIsErrorValidationBank(false);
    this.setErrorCauseValidationBank("");
    const presenter = container.resolve(BeneficiaryPresenter);
    return presenter
      .validateBankAccount(params)
      .then(res => res)
      .catch(err => {
        this.setIsErrorValidationBank(true);
        this.setErrorCauseValidationBank(
          err.response
            ? err.response.status < 500
              ? "client"
              : "server"
            : "internet"
        );
        return err;
      })
      .finally(() => this.setLoadingValidationBank(false));
  }

  @Mutation
  public setBeneficiaryList(data: BeneficiaryEntities) {
    this.beneficiaryList = data;
  }

  @Mutation
  public setBeneficiaryDetail(data: BeneficiaryData) {
    this.beneficiaryDetail = data;
  }

  @Mutation
  public pushBeneficiaryAccount(item: any) {
    this.beneficiaryAccountList.push(item);
  }

  @Mutation
  public popBeneficiaryAccount() {
    this.beneficiaryAccountList.pop();
  }

  @Mutation
  public setOpenModalSuccess(status: boolean) {
    this.openModalSuccess = status;
  }

  @Mutation
  public setLoading(status: boolean) {
    this.isLoading = status;
  }

  @Mutation
  public setError(status: boolean) {
    this.isError = status;
  }

  @Mutation
  public setErrorCause(err: string) {
    this.isErrorCause = err;
  }

  @Mutation
  public setErrorCauseValidationBank(value: string) {
    this.errorCauseValidationBank = value;
  }

  @Mutation
  public setIsErrorValidationBank(err: boolean) {
    this.isErrorValidationBank = err;
  }

  @Mutation
  public setLoadingValidationBank(loading: boolean) {
    this.isLoadingValidationBank = loading;
  }
}

export const BeneficiaryAccountController = getModule(
  BeneficiaryAccountStateStore
);
