/* eslint-disable @typescript-eslint/camelcase */
import { ValidatePaymentRequestInterface } from "../contracts/PaymentRequest";
import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class ValidatePayment implements ValidatePaymentRequestInterface {
  sttNo: string;
  paymentMethod: string;
  billType: string;

  constructor(sttNo: string, paymentMethod: string, billType: string) {
    this.sttNo = sttNo;
    this.paymentMethod = paymentMethod;
    this.billType = billType;
  }

  toJSON(): string {
    return JSON.stringify({
      stt_no: this.sttNo,
      payment_method: this.paymentMethod,
      bill_type: this.billType
    });
  }
}

export class RequestListPaymentMethod {
  cache = false;
  page = 1;
  limit = 10;
  status = "";
  isTotalData = false;

  constructor(fields?: Partial<RequestListPaymentMethod>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
