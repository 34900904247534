/* eslint-disable @typescript-eslint/camelcase */
import {
  CommoditySurcharge,
  CommoditySurchargeList,
  TierCalculation
} from "@/domain/entities/CommoditySurcharge";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload, ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class CommoditySurchargeMapper {
  public convertCommodityDataFromApi(
    res: AxiosResponse<any>
  ): CommoditySurchargeList {
    const { data } = res;
    const commoditySurcharge: CommoditySurcharge[] = [];
    if (data.data.length === 0) {
      return new CommoditySurchargeList(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        commoditySurcharge.push(
          new CommoditySurcharge(
            item.id,
            item.commodity_id,
            item.commodity_name,
            item.type,
            item.amount,
            item.quantifier,
            "",
            item.origin_city_code,
            item.destination_city_code,
            item.origin_city_name,
            item.destination_city_name,
            item.status,
            item.started_at,
            item.expired_at,
            []
          )
        );
      });
    }
    return new CommoditySurchargeList(
      new Pagination(data.meta.page, data.meta.limit),
      commoditySurcharge
    );
  }

  public convertCommodityDetailDataFromApi(
    res: AxiosResponse<any>
  ): CommoditySurcharge {
    const { data } = res;
    const dataTiers = data.data.tier_calculation?.length ? data.data.tier_calculation : [];
    let no = 1;
    const newTiers = [];
    for (const tier of dataTiers) {
      newTiers.push(new TierCalculation({
        tier: no,
        tierStart: tier.tier_start,
        tierEnd: tier.tier_end,
        tierAmount: tier.tier_amount
      }))
      no++;
    }
  
    return new CommoditySurcharge(
      data.data.id,
      data.data.commodity_id,
      data.data.commodity_name,
      data.data.type,
      data.data.amount,
      data.data.quantifier,
      data.data.quantifier,
      data.data.origin_city_code,
      data.data.destination_city_code,
      data.data.origin_city_name,
      data.data.destination_city_name,
      data.data.status,
      data.data.started_at,
      data.data.expired_at,
      newTiers,
      data.data.created_by,
      data.data.updated_by,
      data.data.created_at,
      data.data.updated_at
    );
  }

  public convertPayloadData(res: AxiosResponse<any>): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertPayloadDataForm(result: AxiosResponse): ResponsePayloadV2 {
    const { data } = result;
    return new ResponsePayloadV2({
      success: data.success,
      message: data.message
    });
  }
}
