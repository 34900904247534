import { NGenMapper } from "@/data/persistences/mappers/NGenMapper";
import { AuthNGenRepositoryInterface } from "@/data/persistences/repositories/contracts/AuthNGenRepositoryInterface";
import { AuthNGenEntities } from "@/domain/entities/NGen";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class AuthNGenApiRepository implements AuthNGenRepositoryInterface {
  private service: ApiService;
  private mapper: NGenMapper;
  private endpoints: Endpoints;

  constructor(service: ApiService, mapper: NGenMapper, endpoints: Endpoints) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async loginNGen(): Promise<AuthNGenEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.loginNGen(),
      {}
    );
    return this.mapper.convertAuthDataFromApi(resp);
  }
}
