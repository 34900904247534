import { AxiosResponse } from "axios";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  TopUpVerificationDetail,
  TopUpVerificationEntities,
  TopUpVerificationListData
} from "@/domain/entities/TopupVerification";
import { Pagination } from "@/domain/entities/Pagination";
import parsingLinkGcpToAws from "@/app/infrastructures/misc/common-library/ParsingLinkGcpToAws";

export class TopUpVerificationMapper {
  public convertDataTopUpVerificationDetailFromApi(
    result: AxiosResponse
  ): TopUpVerificationDetail {
    const {
      data: { data }
    } = result;
    return new TopUpVerificationDetail({
      id: data.tum_id,
      genesisTransactionId: data.tuml_genesis_transaction_id,
      senderBankName: data.tum_sender_bank_name,
      senderBankAccountName: data.tum_sender_bank_account_name,
      senderBankAccountNumber: data.tum_sender_bank_account_number,
      recipientBankName: data.tum_recepient_bank_name,
      recipientBankAccountName: data.tum_recepient_bank_account_name,
      recipientBankAccountNumber: data.tum_recepient_bank_account_number,
      amount: data.tum_amount,
      amountVerified: data.tum_verified_amount,
      amountApproved: data.tum_approved_amount,
      status: data.tum_status,
      reasonRejected: data.tum_reject_reason,
      fileOfProof: parsingLinkGcpToAws(data.tum_file_of_proof),
      actorType: data.tum_actor_type,
      actorName: data.tum_actor_name,
      actorCode: data.tum_actor_code,
      createdAt: data.tum_created_at,
      createdBy: data.tum_created_name,
      updatedAt: data.tum_updated_at,
      updatedBy: data.tum_updated_name,
      verifiedAt: data.tum_verification_at,
      verifiedBy: data.tum_verification_name,
      approvedAt: data.tum_approval_at,
      approvedBy: data.tum_approval_name
    });
  }

  public convertPostDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertListBankNameFromApi(res: AxiosResponse): string[] {
    const { data } = res;
    const listBankName = [];
    if (data.data.length === 0) {
      return [];
    } else {
      for (const bankAccount of Object.keys(data.data)) {
        listBankName.push(bankAccount);
      }
    }
    return listBankName;
  }

  public convertListVerifierFromApi(res: AxiosResponse): Array<any> {
    const { data } = res;
    let listVerifier = [];
    if (data.data.length === 0) {
      return [];
    } else {
      listVerifier = data.data.map((item: any) => {
        return {
          actorName: item.actor_name,
          actorId: item.actor_id
        };
      });
    }
    return listVerifier;
  }

  public convertDataTopUpVerificationListFromApi(
    res: AxiosResponse<any>
  ): TopUpVerificationEntities {
    const { data } = res;
    if (data.data.length === 0) {
      return new TopUpVerificationEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      return new TopUpVerificationEntities(
        new Pagination(data.meta.page, data.meta.limit),
        data.data.map((item: any) => {
          return new TopUpVerificationListData({
            id: item.tum_id,
            genesisTransactionId: item.genesis_transaction_id || "-",
            partnerName: item.tum_actor_name,
            status: item.tum_status.replace("_", " "),
            requestAmount: item.tum_amount,
            verificationAmount: item.tum_verified_amount,
            approvalAmount: item.tum_approved_amount,
            bankNameSender: item.tum_sender_bank_name,
            accountNameSender: item.tum_sender_bank_account_name,
            accountNumberSender: item.tum_sender_bank_account_number,
            bankNameReceiver: item.tum_recepient_bank_name,
            accountNameReceiver: item.tum_recepient_bank_account_name,
            accountNumberReceiver: item.tum_recepient_bank_account_number,
            approvalDate: item.tum_approval_at,
            approvalBy: item.tum_approval_name,
            transactionDate: item.tum_created_at,
            transactionBy: item.tum_created_name,
            verificationDate: item.tum_verification_at,
            verificationBy: item.tum_verification_name,
            note: item.tum_reject_reason || "-"
          });
        })
      );
    }
  }
}
