import { FlagsPermissionConfigFakeDexIndication } from "@/feature-flags/flags-permission-config-fake-dex-indication";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

const ConfigFakeDexIndicationDetail = () =>
  import(
    /* webpackChunkName: "ConfigFakeDexIndicationDetail" */ "@/app/ui/views/customer-service/config-fake-dex-indication/detail.vue"
  );
const ConfigFakeDexIndicationEdit = () =>
  import(
    /* webpackChunkName: "ConfigFakeDexIndicationEdit" */ "@/app/ui/views/customer-service/config-fake-dex-indication/edit.vue"
  );

export default [
  {
    path: "/customer-service/config-fake-dex-indication",
    component: Layout,
    name: "config-fake-dex-indication-detail",
    meta: {
      title: "Automasi Indikasi DEX Palsu | Detail",
      hidden: true,
      parent: "Automasi Indikasi DEX Palsu",
      permission:
        FlagsPermissionConfigFakeDexIndication.permission_config_fake_dex_indication_enable
    },
    children: [
      {
        path: ":id",
        name: "config-fake-dex-indication-detail",
        component: ConfigFakeDexIndicationDetail,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/customer-service/config-fake-dex-indication",
    component: Layout,
    name: "config-fake-dex-indication-edit",
    meta: {
      title: "Automasi Indikasi DEX Palsu | Edit",
      hidden: true,
      parent: "Automasi Indikasi DEX Palsu",
      permission:
        FlagsPermissionConfigFakeDexIndication.permission_config_fake_dex_indication_enable
    },
    children: [
      {
        path: ":id/edit",
        name: "config-fake-dex-indication-edit",
        component: ConfigFakeDexIndicationEdit,
        meta: {
          header: true,
          title: "Automasi Indikasi DEX Palsu | Edit",
          name: "Edit Konfigurasi",
          before: "/customer-service/config-fake-dex-indication/:id"
        }
      }
    ]
  }
];
