export class DetailCodConfigurationRetail {
  ccoId = 0;
  ccoName = "";
  ccoDescription = "";
  ccoProductType = "";
  ccoMinPrice = 0;
  ccoMaxPrice = 0;
  ccoMinCodValue = 0;
  ccoCodType = "";
  ccoIsInsurance = false;
  ccoCityCode = "";
  ccoCityName = "";
  ccoPercentageCod = 0;
  ccoOriginCommissionPercentage = 0;
  ccoDestinationCommissionPercentage = 0;
  ccoIsDfod = false;
  ccoOriginMinCommission = 0;
  ccoDestinationMinCommission = 0;
  ccoStatus = "inactive";
  ccoCreatedAt = "";
  ccoCreatedBy = "";
  ccoUpdatedAt = "";
  ccoUpdatedBy = "";

  constructor(fields?: Partial<DetailCodConfigurationRetail>) {
    Object.assign(this, fields);
  }
}
