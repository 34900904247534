/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import {
  VuexModule,
  Module,
  Action,
  getModule,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import {
  CashbackConfigEntities,
  CashbackListRequest,
} from "@/domain/entities/CashbackConfiguration";
import { CashbackConfigurationPresenter } from "../presenters/CashbackConfigurationPresenter";
import { CashbackConfigurationComponent } from "@/app/infrastructures/dependencies/modules/CashbackConfigurationComponent";
import { CashbackConfigRequest } from "@/data/payload/api/CashbackApiRequest";
import DetailCashbackConfiguration from "../views/finance/top-up-and-cashback/cashback/detail/index.vue";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
CashbackConfigurationComponent.init();

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "cashback-configuration",
})
class CashbackConfigurationStore extends VuexModule {
  public firstRequest = true;
  public isLoading = true;
  public errorCause = "";
  public cashbackConfigList: any = new CashbackConfigEntities(
    new Pagination(1, 20),
    []
  );
  public searchPartner: Array<any> = [];
  public isErrorMinTopUp = false;
  public isErrorMaxTopUp = false;
  public isErrorPercentage = false;
  public form: any = {
    ccbAcountType: "Retail",
    ccbType: "Percentage",
    ccbIsAllPoss: false,
    ccbPartnerIds: [],
    ccbStartDate: "",
    ccbEndDate: "",
    ccbStatus: "",
    ccbTiers: [
      {
        no: 1,
        min_balance: 0,
        max_balance: 0,
        percentage: 0,
      },
    ],
  };
  public isErrorValidationTiers = false;
  public activeIndex = 0;

  @Action
  public GetListCashbackConfig(params: CashbackListRequest) {
    const presenter = container.resolve(CashbackConfigurationPresenter);
    return presenter.getList(params);
  }

  @Action
  public getActiveCashback(id: any) {
    const presenter = container.resolve(CashbackConfigurationPresenter);
    return presenter.getActiveCashback(id).then((res: ResponsePayloadV2) => res)
    .catch((error: any) => new ResponsePayloadV2())
  }

  @Action
  public CreateCashbackConfig(payload: CashbackConfigRequest) {
    const presenter = container.resolve(CashbackConfigurationPresenter);
    return presenter.createConfig(payload);
  }
  @Action
  public EditCashbackConfig(params: {payload: CashbackConfigRequest, id: any}) {
    const presenter = container.resolve(CashbackConfigurationPresenter);
    return presenter.editConfig(params.payload, params.id);
  }

  @Action
  public GetDetailCashbackConfig(id: any) {
    const presenter = container.resolve(CashbackConfigurationPresenter);
    return presenter.getDetail(id);
  }

  @Mutation
  public setIsLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  public setErrorCause(val: string) {
    this.errorCause = val;
  }

  @Mutation
  public setValueCity(val: any) {
    this.form.ccbPartnerIds = val;
  }

  @Mutation
  public setIsErrorMinTopUp(val: boolean) {
    this.isErrorMinTopUp = val;
  }
  @Mutation
  public setIsErrorMaxTopUp(val: boolean) {
    this.isErrorMaxTopUp = val;
  }
  @Mutation
  public setIsErrorPercentage(val: boolean) {
    this.isErrorPercentage = val;
  }
  @Mutation
  public setIsErrorValidationTiers(val: boolean) {
    this.isErrorValidationTiers = val;
  }
  @Mutation
  public setActiveIndex(val: number) {
    this.activeIndex = val;
  }

  @Mutation
  public setResetForm() {
    this.form = {
      ccbAcountType: "Retail",
      ccbType: "Percentage",
      ccbIsAllPoss: false,
      ccbPartnerIds: [],
      ccbStartDate: "",
      ccbEndDate: "",
      ccbStatus: "",
      ccbTiers: [
        {
          no: 1,
          min_balance: 0,
          max_balance: 0,
          percentage: 0,
        },
      ],
    };
    this.convertPeriodeEnd = ""
    this.convertPeriodeStart = ""
    this.searchPartner = []
  }

  // Date Picker
  public convertPeriodeStart: any = "";
  public convertPeriodeEnd: any = "";

  @Mutation
  public setPeriodeStart(date: any) {
    this.convertPeriodeStart = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }
  @Mutation
  public setPeriodeEnd(date: any) {
    if (!date) {
      this.convertPeriodeEnd = date;
    }
    this.convertPeriodeEnd = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }

  @Mutation
  public setResetFilter(val: any) {
    this.searchPartner = val
  }

  @Mutation
  public setSearchPartner(val: any) {
    this.searchPartner = val
  }
}

export const CashbackConfigurationController = getModule(
  CashbackConfigurationStore
);
