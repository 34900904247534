export class ConfigurablePrice {
  commissionId = 0;
  commission = "";
  commissionReference = "";
  commissionType = "";
  commissionParameter = "";
  commissionAppliedTo = "";
  commissionPrefix = "";

  constructor(fields?: Partial<ConfigurablePrice>) {
    Object.assign(this, fields);
  }
}
export class DetailForwardBookingCommission {
  id = 0;
  configurablePriceName = "";
  configurablePriceType = "";
  configurablePriceDescription = "";
  configurablePriceStatus = "";
  configurablePriceCreatedAt = "";
  configurablePriceCreatedBy = "";
  configurablePriceUpdatedAt = "";
  configurablePriceUpdatedBy = "";
  configurablePriceForwardBookingCommission = new ConfigurablePrice();
  constructor(fields?: Partial<DetailForwardBookingCommission>) {
    Object.assign(this, fields);
  }
}
