import {
  AuthNGenEntities,
  AwbDetailData,
  AwbDetails,
  CargoHistoryNgen,
  FlightDetail,
  FlightSchedule,
  StockInformation,
  TrackingBookingRequestIdData
} from "@/domain/entities/NGen";
import { AxiosResponse } from "axios";
import { GetScheduleListApiRequest } from "@/data/payload/api/NGenApiRequest";
import moment from "moment";

export class NGenMapper {
  public convertAuthDataFromApi(result: AxiosResponse<any>): AuthNGenEntities {
    const {
      data: { data }
    } = result;
    return new AuthNGenEntities({ token: data.token });
  }

  public convertFlightScheduleDataFromApi(
    result: AxiosResponse<any>
  ): FlightSchedule {
    const { data } = result;

    return new FlightSchedule({
      flightDetails: data.flight_details.map(
        (item: FlightDetail) =>
          new FlightDetail({
            DailyFlightSNo: item.DailyFlightSNo,
            FlightNo: item.FlightNo,
            DepartureDateTime: item.DepartureDateTime,
            ArrivalDateTime: item.ArrivalDateTime,
            Route: item.Route
          })
      )
    });
  }

  public mockFlightScheduleData(
    payload: GetScheduleListApiRequest
  ): FlightSchedule {
    return new FlightSchedule({
      flightDetails: Array(100)
        .fill("")
        .map((_, index) => {
          const departureDateTime = moment(payload.FlightDate, "YYYY-MM-DD")
            .add(6 + index, "hours")
            .format("YYYY-MM-DD HH:mm:ss");
          const arrivalDateTime = moment(
            departureDateTime,
            "YYYY-MM-DD HH:mm:ss"
          )
            .add(Math.floor(1 + Math.random() * 23), "hours")
            .add(Math.floor(Math.random() * 55), "minutes")
            .format("YYYY-MM-DD HH:mm:ss");
          return new FlightDetail({
            DailyFlightSNo: Math.floor(10000 + Math.random() * 90000),
            FlightNo: `JT-${Math.floor(1000 + Math.random() * 9000)}`,
            DepartureDateTime: departureDateTime,
            ArrivalDateTime: arrivalDateTime,
            Route: `${payload.Origin}-${payload.Destination}`
          });
        })
    });
  }

  public convertCargoHistoryFromNgen(res: AxiosResponse): AwbDetailData {
    const { data } = res;
    if (typeof data === "object") {
      return new AwbDetailData({
        errorAwb: "",
        awbDetails: new AwbDetails({
          awbNumber: data?.awb_details[0].awb_number,
          awbDate: data?.awb_details[0].awb_date,
          originCity: data?.awb_details[0].origin_city,
          destinationCity: data?.awb_details[0].destination_city,
          pieces: data?.awb_details[0].pieces,
          chargeableWeight: data?.awb_details[0].chargeable_weight,
          grossWeight: data?.awb_details[0].gross_weight,
          volumeWeight: data?.awb_details[0].volume_weight,
          product: data?.awb_details[0].product,
          commodity: data?.awb_details[0].commodity,
          agent: data?.awb_details[0].agent
        }),
        stockInformation: new StockInformation({
          awbNumber: data?.stock_information[0].awb_number,
          agentIssueDate: data?.stock_information[0].agent_issue_date,
          agentName: data?.stock_information[0].agent_name,
          updatedBy: data?.stock_information[0].updated_by
        }),
        awbTracking: data?.awb_tracking?.length
          ? data.awb_tracking.map(
              (tracking: any) =>
                new CargoHistoryNgen({
                  flightNumber: tracking.flight_number,
                  flightDate: tracking.flight_date,
                  etd: tracking.etd,
                  eta: tracking.eta,
                  origin: tracking.origin,
                  destination: tracking.destination,
                  pieces: tracking.pieces,
                  grossWeight: tracking.gross_weight,
                  volumeWeight: tracking.volume_weight,
                  updatedBy: tracking.updated_by,
                  updatedOn: tracking.updated_on,
                  userAction: tracking.user_action,
                  status: tracking.status
                })
            )
          : []
      });
    }
    return new AwbDetailData({ errorAwb: data });
  }

  public convertCargoHistoryByBookingRequestIdFromNgen(res: AxiosResponse): TrackingBookingRequestIdData[] {
    const { data } = res;
    const cargoHistoryData: any = [] || null;

    data.AWB_Details.map((item: any) => {
      cargoHistoryData.push(
      new TrackingBookingRequestIdData({
        bookingRequestId: item.BookingRequestID,
        AWBNo: item.AWBNO,
        statusMessage: item.StatusMessage
      })
      )
    })

    return cargoHistoryData
  }

  public convertPrintAWBLionCargoData(result: AxiosResponse): string {
    const { data } = result;
    return data;
  }
}
