export class WoodpackingData {
  id = 0;
  name = "";
  description = "";
  type = "";
  additionalCost = 0;
  status = "";
  woodpackingAvailableCities: Array<WoodpackingAvailableCities> = [];
  createdAt = "";
  updatedAt = "";
  createdBy = "";
  updatedBy = "";
  constructor(fields?: Partial<WoodpackingData>) {
    Object.assign(this, fields);
  }
}

export class WoodpackingAvailableCities {
  cityName = "";
  cityCode = "";
  constructor(fields?: Partial<WoodpackingAvailableCities>) {
    Object.assign(this, fields);
  }
}