import { Pagination } from "@/domain/entities/Pagination";

export class LocationSearchEntities {
  pagination: Pagination;
  locationSearchData: Array<LocationSearchData>;
  constructor(
    pagination: Pagination,
    locationSearchData: Array<LocationSearchData>
  ) {
    this.pagination = pagination;
    this.locationSearchData = locationSearchData;
  }
}

export class LocationSearchData {
  id: number;
  code: string;
  ursaCode: string;
  name: string;
  type: string;

  constructor(
    id: number,
    code: string,
    ursaCode: string,
    name: string,
    type: string
  ) {
    this.id = id;
    this.code = code;
    this.ursaCode = ursaCode;
    this.name = name;
    this.type = type;
  }
}
