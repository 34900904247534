import { DeliveryManifestApiRepository } from "@/app/infrastructures/repositories/api/DeliveryManifestApiRepository";
import { injectable } from "tsyringe";
import {
  DeliveryManifestEntities,
  DeliveryManifestPdfData,
  DetailSttDeliveryManifest
} from "@/domain/entities/DeliveryManifest";
import { UpdateDeliveryInterface } from "@/data/payload/contracts/DeliveryRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

@injectable()
export class DeliveryManifestPresenter {
  private repository: DeliveryManifestApiRepository;

  constructor(repository: DeliveryManifestApiRepository) {
    this.repository = repository;
  }

  public updateDelivery(
    payload: UpdateDeliveryInterface
  ): Promise<ResponsePayload> {
    return this.repository.updateDelivery(payload);
  }

  public getDeliveryManifestList(
    page: number,
    limit: number,
    startDate: string,
    endDate: string,
    search: string
  ): Promise<DeliveryManifestEntities> {
    return this.repository.getDeliveryManifest(
      page,
      limit,
      startDate,
      endDate,
      search
    );
  }
  public getDetailDeliveryStt(
    sttOrBagNumber: string
  ): Promise<DetailSttDeliveryManifest[]> {
    return this.repository.getDetailDeliveryStt(sttOrBagNumber);
  }

  public getDeliveryManifestPdfData(
    id: number
  ): Promise<DeliveryManifestPdfData> {
    return this.repository.getDeliveryManifestPdfData(id);
  }
}
