import {
  MessageDictionary,
  MessagingServiceConfigurationList,
  MessagingServiceConfigurationListData,
  TagValue,
  TemplateWhatsappPremium
} from "@/domain/entities/MessagingServiceConfiguration";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class MessagingServiceConfigurationMapper {
  public convertMessagingServiceConfigurationListFromApi(
    res: AxiosResponse
  ): MessagingServiceConfigurationList {
    const { data } = res;
    if (!data.data) {
      return new MessagingServiceConfigurationList(
        new Pagination(
          data.meta.page,
          data.meta.limit,
          data.meta.total_records
        ),
        []
      );
    } else {
      const messagingServiceList = [];
      for (const item of data.data) {
        messagingServiceList.push(
          new MessagingServiceConfigurationListData({
            msgConfId: item.msg_conf_id,
            msgConfCode: item.msg_conf_code,
            msgConfName: item.msg_conf_name,
            msgConfType: item.msg_conf_type,
            msgConfSttLastStatus: item.msg_conf_stt_last_status,
            msgConfPacketType: item.msg_conf_packet_type,
            msgConfTarget: item.msg_conf_target,
            msgConfStatus: item.msg_conf_status,
            msgConfBookedFor: item.msg_conf_booked_for,
            msgConfProduct: item.msg_conf_product,
            msgConfOriginCity: item.msg_conf_origin_city,
            msgConfOriginCityExclude: item.msg_conf_origin_city_exclude,
            msgConfPosId: item.msg_conf_pos_id,
            msgConfPosIdExclude: item.msg_conf_pos_id_exclude,
            msgConfClientId: item.msg_conf_client_id,
            msgConfClientIdExclude: item.msg_conf_client_id_exclude,
            msgConfSchedulePeriodeStart: item.msg_conf_schedule_periode_start,
            msgConfSchedulePeriodeEnd: item.msg_conf_schedule_periode_end,
            msgConfMessageText: item.msg_conf_message_text,
            msgConfCreatedAt: item.msg_conf_created_at,
            msgConfCreatedBy: item.msg_conf_created_by,
            msgConfUpdatedAt: item.msg_conf_updated_at,
            msgConfUpdatedBy: item.msg_conf_updated_by
          })
        );
      }
      return new MessagingServiceConfigurationList(
        new Pagination(
          data.meta.page,
          data.meta.limit,
          data.meta.total_records
        ),
        messagingServiceList
      );
    }
  }

  public convertMessagingServiceConfigurationDetailFromApi(
    res: AxiosResponse
  ): MessagingServiceConfigurationListData {
    const {
      data: { data }
    } = res;
    return new MessagingServiceConfigurationListData({
      msgConfId: data.msg_conf_id,
      msgConfCode: data.msg_conf_code,
      msgConfName: data.msg_conf_name,
      msgConfType: data.msg_conf_type,
      msgConfSttLastStatus: data.msg_conf_stt_last_status,
      msgConfPacketType: data.msg_conf_packet_type,
      msgConfTarget: data.msg_conf_target,
      msgConfStatus: data.msg_conf_status,
      msgConfBookedFor: data.msg_conf_booked_for,
      msgConfProduct: data.msg_conf_product,
      msgConfOriginCity: data.msg_conf_origin_city,
      msgConfOriginCityExclude: data.msg_conf_origin_city_exclude,
      msgConfDestinationCity: data.msg_conf_destination_city,
      msgConfDestinationCityExclude: data.msg_conf_destination_city_exclude,
      msgConfPosId: data.msg_conf_pos_id,
      msgConfPosName: data.msg_conf_pos_name,
      msgConfPosIdExclude: data.msg_conf_pos_id_exclude,
      msgConfPosNameExclude: data.msg_conf_pos_name_exclude,
      msgConfClientId: data.msg_conf_client_id,
      msgConfClientName: data.msg_conf_client_name,
      msgConfClientIdExclude: data.msg_conf_client_id_exclude,
      msgConfClientNameExclude: data.msg_conf_client_name_exclude,
      msgConfClientParentId: data.msg_conf_client_parent_id || "",
      msgConfClientParentName: data.msg_conf_client_parent_name || "",
      msgConfClientParentIdExclude: data.msg_conf_client_parent_id_exclude || "",
      msgConfClientParentNameExclude: data.msg_conf_client_parent_name_exclude || "",
      msgConfSchedulePeriodeStart: data.msg_conf_schedule_periode_start,
      msgConfSchedulePeriodeEnd: data.msg_conf_schedule_periode_end,
      msgConfMessageText: data.msg_conf_message_text,
      msgConfCreatedAt: data.msg_conf_created_at,
      msgConfCreatedBy: data.msg_conf_created_by_name,
      msgConfUpdatedAt: data.msg_conf_updated_at,
      msgConfUpdatedBy: data.msg_conf_updated_by_name,
      msgConfIntracity: data.msg_conf_intracity,
      msgConfIntercity: data.msg_conf_intercity,
      msgConfSla: data.msg_conf_sla
    });
  }

  public convertPayloadData(res: AxiosResponse<any>): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
  public convertMessageDictionaryDataFromApi(
    res: AxiosResponse
  ): MessageDictionary {
    const {
      data: { data }
    } = res;
    const tagDefinitionValue: TagValue[] = data.tag_definition.map(
      (definition: any) => {
        return new TagValue(
          new TagValue({
            tag: definition.tag,
            value: definition.description
          })
        );
      }
    );
    const tagFormatValue: TagValue[] = data.tag_template.map((format: any) => {
      return new TagValue({
        tag: format.title,
        value: format.template,
        keyType: format.cod_type,
        keyStatus: format.stt_status,
        content: format.content
      });
    });
    return new MessageDictionary({
      tagDefinition: tagDefinitionValue,
      tagFormat: tagFormatValue
    });
  }
  public convertTemplateWhatsappPremiumDataFromApi(
    res: AxiosResponse
  ): TemplateWhatsappPremium[] {
    const {
      data: { data }
    } = res;
    return data.map((template: any) => {
      return new TemplateWhatsappPremium({
        templateId: template.mywp_id,
        templateName: template.mywp_template_name,
        templateContent: template.mywp_content,
        templateStatus: template.mywp_status
      });
    });
  }
}
