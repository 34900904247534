import { IntegrationMonitoringMapper } from "@/data/persistences/mappers/IntegrationMonitoringMapper";
import { IntegrationMonitoringRepositoryInterface } from "@/data/persistences/repositories/contracts/IntegrationMonitoringRepositoryInterface";
import {
  ElexysFailedBookingEntities,
  LogSttData,
  RequestApiElexysFailedBookingList,
  RequestDownloadElexysFailedBooking
} from "@/domain/entities/IntegrationMonitoring";
import { Endpoints } from "../../misc/Endpoints";
import { downloadFile } from "../../misc/Utils";
import ApiService from "../../services/ApiService";
import { RetryBookingElexys } from "@/data/payload/api/RetryBookingElexysApiRequest";
import { RetryBookingElexysRequestInterface } from "@/data/payload/contracts/RetryBookingElexysRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
export class IntegrationMonitoringApiRepository
  implements IntegrationMonitoringRepositoryInterface {
  private service: ApiService;
  private mapper: IntegrationMonitoringMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: IntegrationMonitoringMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getElexysFailedBookingList(
    params: RequestApiElexysFailedBookingList
  ): Promise<ElexysFailedBookingEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getElexysFailedBookingList(params),
      {}
    );
    return this.mapper.convertElexysFailedBookingListFromApi(resp);
  }
  public async downloadElexysFailedBooking(
    params: RequestDownloadElexysFailedBooking
  ): Promise<any> {
    return downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.downloadElexysFailedBooking(
        params
      )}`,
      `Failed_Booking_Elexys_${params.firstFailedStart}_${params.firstFailedEnd}`,
      ".csv",
      true
    );
  }

  public async getLogStt(sttNo: string): Promise<LogSttData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getLogStt(sttNo),
      {}
    );
    return this.mapper.convertLogSttDataFromApi(resp);
  }

  public async retryElexysBooking(
    payload: RetryBookingElexysRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.retryBookingElexys(),
      undefined,
      payload as RetryBookingElexys
    );
    return this.mapper.convertResponseBookingElexysFromApi(resp);
  }
}
