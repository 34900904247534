import { QueryParamsEntities } from "./MainApp";
import { Pagination } from "./Pagination";

export class Hub {
    hubId = 0;
    hubName = "";
    hubCode = "";
    hubDistrictCode = "";
    originCity = "";
    consolidator = "";
    hubAddress = "";
    hubDistrict = "";
    hubPhoneNumber = "";
    hubContactPersonName = "";
    hubContactPersonPhoneNumber = "";
    hubContactPersonEmail = "";
    status = "";
    isActive = false;
    hubCreatedAt = "";
    hubCreatedBy = "";
    hubUpdatedAt = "";
    hubUpdatedBy = "";
    constructor(fields?: Partial<Hub>) {
        Object.assign(this, fields);
    }
}


export class HubList {
    pagination = new Pagination(1, 20);
    data: Hub[] = [];

    constructor(fields?: Partial<HubList>) {
        Object.assign(this, fields);
    }
}

export class RequestHubList {
    page = 1;
    limit = 20;
    isTotalData = false;
    partnerId = 0;
    city = "";
    status = "active";
    search = "";
    sortBy = "";

    toQueryString(): string {
        return new QueryParamsEntities(this).queryString;
    }

    constructor(fields?: Partial<RequestHubList>) {
        Object.assign(this, fields);
    }

}