import { Pagination } from "@/domain/entities/Pagination";
import {
  BookingCommissionEntities,
  BookingCommissionData,
  BookingCommissionDetail,
  BookingCommissionCalculationTier
} from "@/domain/entities/BookingCommission";
import { AxiosResponse } from "axios";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class BookingCommissionMapper {
  public convertBokingCommissionDataFromApi(
    res: AxiosResponse<any>
  ): BookingCommissionEntities {
    const { data } = res;
    if (data.data.length === 0) {
      return new BookingCommissionEntities(
        new Pagination(
          data.meta.page,
          data.meta.limit,
          data.meta.total_records
        ),
        []
      );
    } else {
      return new BookingCommissionEntities(
        new Pagination(
          data.meta.page,
          data.meta.limit,
          data.meta.total_records
        ),
        data.data.map(
          (item: any) =>
            new BookingCommissionData(
              item.bc_id,
              item.bc_code,
              item.bc_bulk_id,
              item.bc_tier_base,
              item.bc_tier_price_reference,
              item.bc_commission_reference,
              item.bc_commission_type,
              item.bc_commission_quantifier,
              item.bc_booking_source,
              item.bc_applied_to,
              item.bc_shipment_prefix.replace(/,/g, ", "),
              item.bc_detail_status,
              item.bc_created_by,
              item.bc_created_name,
              item.bc_created_at,
              item.bc_updated_by,
              item.bc_updated_name,
              item.bc_updated_at,
              item.bc_detail_id,
              item.bc_detail_bc_id,
              item.bc_detail_origin_city_id,
              item.bc_detail_origin_city_id === "ALL"
                ? item.bc_detail_origin_city_id
                : `${item.bc_detail_origin_city_id} - ${item.bc_detail_origin_city_name}`,
              item.bc_detail_destination_city_id,
              item.bc_detail_destination_city_id === "ALL"
                ? item.bc_detail_destination_city_id
                : `${item.bc_detail_destination_city_id} - ${item.bc_detail_destination_city_name}`,
              item.bc_detail_product_name,
              item.bc_detail_commodity_code,
              item.bc_detail_commodity_name
            )
        )
      );
    }
  }

  public convertDataBookingCommissionDetailFromApi(
    result: AxiosResponse
  ): BookingCommissionDetail {
    const {
      data: { data }
    } = result;
    return new BookingCommissionDetail({
      commissionId: data.bc_id,
      commissionCode: data.bc_code,
      commissionBookingSource: data.bc_booking_source,
      commissionFor: data.bc_applied_to,
      commissionShipmentId: data.bc_shipment_prefix.filter((item: any) => {
        if (item.prefix !== "" && item.name !== "") {
          return item;
        }
      }),
      commissionOriginCity: data.bc_details
        ? {
            name:
              data.bc_details[0].bc_detail_origin_city_id === "ALL"
                ? data.bc_details[0].bc_detail_origin_city_id
                : `${data.bc_details[0].bc_detail_origin_city_id} - ${data.bc_details[0].bc_detail_origin_city_name}`,
            value: data.bc_details[0].bc_detail_origin_city_id
          }
        : "",
      commissionDestinationCity: data.bc_details
        ? {
            name:
              data.bc_details[0].bc_detail_destination_city_id === "ALL"
                ? data.bc_details[0].bc_detail_destination_city_id
                : `${data.bc_details[0].bc_detail_destination_city_id} - ${data.bc_details[0].bc_detail_destination_city_name}`,
            value: data.bc_details[0].bc_detail_destination_city_id
          }
        : "",
      commissionProduct: data.bc_details
        ? {
            name: data.bc_details[0].bc_detail_product_name
          }
        : "",
      commissionCommodity: data.bc_details
        ? {
            name:
              data.bc_details[0].bc_detail_commodity_code === "ALL"
                ? data.bc_details[0].bc_detail_commodity_code
                : data.bc_details[0].bc_detail_commodity_name,
            code: data.bc_details[0].bc_detail_commodity_code
          }
        : "",
      commissionStatus: data.bc_details
        ? data.bc_details[0].bc_detail_status
        : "",
      commissionTierBase: data.bc_tier_base,
      commissionPriceTierReference: data.bc_tier_price_reference,
      commissionPriceReference: data.bc_commission_reference,
      commissionType: data.bc_commission_type,
      commissionQuantifier: data.bc_commission_quantifier,
      commissionCalculationTier: data.bc_tier_bases.map((item: any) => {
        return new BookingCommissionCalculationTier({
          startAmount: item.bc_tier_base_start.toLocaleString("id"),
          endAmount: item.bc_tier_base_end.toLocaleString("id"),
          commissionAmount: item.bc_tier_base_commission_amount ? item.bc_tier_base_commission_amount.toLocaleString(
            "id"
          ) : "0",
          commissionAmountBranch: item.bc_tier_base_branch_commission_amount ? item.bc_tier_base_branch_commission_amount?.toLocaleString(
            "id"
          ) : "0" ,
          commissionAmountPickup: item.bc_tier_base_pickup_commission_amount ? item.bc_tier_base_pickup_commission_amount?.toLocaleString(
            "id"
          ) : "0",
          commissionAmountPickupPublishRate: item.bc_tier_base_pickup_publish_rate_commission_amount ? item.bc_tier_base_pickup_publish_rate_commission_amount?.toLocaleString(
            "id"
          ) : "0"
        });
      }),
      commissionUpdatedBy: data.bc_updated_name,
      commissionUpdatedAt: data.bc_updated_at,
      commissionCreatedBy: data.bc_created_name,
      commissionCreatedAt: data.bc_created_at
    });
  }

  public convertPostDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
